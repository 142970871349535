import {getDefects} from '../../../../api/Organization'

export const getDefectsData = (
  id,
  userId,
  date,
  status,
  take,
  page,
  setDefects: Function,
  setAPIStatus: Function
) => {
  setAPIStatus((prev: any) => ({...prev, loading: true}))
  getDefects(id, userId, date, status, take, page).then((res: any) => {
    if (res.statusCode === 200) {
      setDefects(res?.data ?? {})
      setAPIStatus((prev: any) => ({...prev, loading: false}))
    } else {
      setDefects([])
      setAPIStatus((prev: any) => ({...prev, loading: false, error: res.message}))
    }
  })
}

export const clearFilteredField = (
  setFilterOptions: Function,
  filterOptions: {
    userId: string
    status: string
    date: string
    take: number
    page: number
  }
) => {
  setFilterOptions({
    userId: '',
    status: '',
    date: '',
    take: 10,
    page: 1,
  })
}
