import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
import {changeTextCapital, changeTextSentenceCase, dateFormat} from '../../../../_metronic/helpers'
import {getAllocatedUsersData, handleAddUsers} from '../users/UserFunction'
import InputErrorBox from '../../../components/InputErrorBox'
import {boolean} from 'yup'

const AddUsers = ({
  userData,
  inputError,
  setInputError,
  projectUserData,
  setProjectUserInputField,
}) => {
  // const [inputError, setInputError] = useState<any>({})

  const [APIStatus, setAPIStatus] = useState({loading: false, eror: null, submitLoading: false})

  const [showAllocatedUsersData, setShowAllocatedUsersData] = useState<number[]>([])

  const [allocateUserData, setAllocateUserData] = useState([
    {
      id: '',
      startDate: '',
      endDate: '',
      role: '',
      allocationPercentage: '',
      user: {
        id: '',
        firstName: '',
      },
      project: {
        id: '',
        name: '',
      },
    },
  ])

  // const [projectUserData, setProjectUserInputField] = useState(initialState)
  // const [userData, setUserData] = useState<any>([])

  const checkAllocatedUsersArrayElement = (element: number) => {
    setShowAllocatedUsersData((prevState) => {
      if (prevState.includes(element)) {
        return []
      } else {
        return [element]
      }
    })
  }

  // useEffect(() => {
  //   let mounted = true
  //   if (mounted) {
  //     getUsers().then((res) => {
  //       if (res.statusCode === 200) {
  //         setUserData(res.data ?? [])
  //         let projectUsers = res.data.map((user) => ({
  //           selected: false,
  //           user: {id: user?.id, firstName: user?.firstName, lastName: user?.lastName},
  //           startDate: projectInputField?.startDate,
  //           endDate: projectInputField?.dueDate,
  //           role: '',
  //           allocationPercentage: 100,
  //           status: 'On Boarded',
  //           assigner: {
  //             id: currentUser?.id,
  //           },
  //         }))

  //         setProjectUserInputField({...projectUserData, projectUsers: projectUsers})
  //       }
  //     })
  //   }
  //   return () => {
  //     mounted = false
  //   }
  // }, [])

  return (
    <>
      <div style={{maxHeight: '350px', overflowX: 'hidden', overflowY: 'scroll'}}>
        <div className='table-responsive'>
          <table className='table table-responsive'>
            <thead style={{position: 'sticky', top: '0', zIndex: '1'}}>
              <tr className='fw-bold'>
                <th style={{width: '10px'}}>{''}</th>
                <th>{changeTextCapital('User Name')}</th>
                <th>{changeTextCapital('Role')}</th>
                <th>{changeTextCapital('Start Date')}</th>
                <th>{changeTextCapital('End Date')}</th>
                <th>{changeTextCapital('Allocation (%)')}</th>
              </tr>
            </thead>
            {userData?.length > 0 ? (
              <tbody className='justify-content-center'>
                {projectUserData?.projectUsers?.map((obj: any, index) => {
                  return (
                    <>
                      <tr className='' key={obj?.id}>
                        <td className='text-center'>
                          <span
                            className={`badge badge-light-primary d-inline-block cursor-pointer mt-4 ${showAllocatedUsersData.includes(
                              index
                            )}`}
                            onClick={(e) => {
                              e.stopPropagation()
                              checkAllocatedUsersArrayElement(index)
                              getAllocatedUsersData(
                                userData[index]?.id,
                                setAllocateUserData,
                                setAPIStatus
                              )
                            }}
                          >
                            {showAllocatedUsersData.includes(index) ? '↑' : '↓'}
                          </span>
                        </td>

                        <td className=''>
                          <div className='d-flex'>
                            <div className='form-check form-check-custom form-check-solid'>
                              <input
                                className='form-check-input me-3'
                                style={{cursor: 'pointer'}}
                                type='checkbox'
                                name='selected'
                                checked={obj.selected}
                                onChange={(event) =>
                                  handleAddUsers(
                                    event.target.checked,
                                    event.target.name,
                                    index,
                                    setProjectUserInputField,
                                    projectUserData.projectUsers,
                                    inputError,
                                    setInputError
                                  )
                                }
                              />
                              <div className='symbol symbol-35px symbol-sqaure me-3'>
                                <span className='symbol-label bg-light-danger text-danger fw-semibold'>
                                  {userData[index]?.firstName[0].toUpperCase()}
                                </span>
                              </div>
                            </div>

                            <div className=''>
                              <div className='d-flex align-items-center'>
                                <a
                                  href='#'
                                  className='fs-5 fw-bold text-gray-900 text-hover-primary mb-2 me-2'
                                >
                                  {userData[index]?.firstName} {userData[index]?.lastName}
                                </a>
                              </div>
                              <div
                                className='fw-semibold text-muted'
                                style={{
                                  maxWidth: '125px',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  cursor: 'pointer',
                                }}
                                title={userData[index]?.email}
                              >
                                {userData[index]?.email}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className=''>
                            <select
                              className='form-select border-radius mt-2'
                              style={{cursor: 'pointer', width: '120px'}}
                              name='role'
                              value={obj?.role}
                              disabled={!obj.selected}
                              onChange={(event) =>
                                handleAddUsers(
                                  event.target.value,
                                  event.target.name,
                                  index,
                                  setProjectUserInputField,
                                  projectUserData.projectUsers,
                                  inputError,
                                  setInputError
                                )
                              }
                            >
                              <option value='' hidden={true}>
                                Select Role
                              </option>
                              <option>Full Stack Developer</option>
                              <option>Frontend Developer</option>
                              <option>Backend Developer</option>
                              <option>Software Tester</option>
                            </select>
                            {<InputErrorBox Message={inputError[`role${index}`]} />}
                          </div>
                        </td>
                        <td>
                          <div className=''>
                            <input
                              className='form-control mt-2'
                              style={{cursor: 'pointer', width: '130px'}}
                              type='date'
                              name='startDate'
                              value={obj?.startDate}
                              disabled={!obj.selected}
                              onChange={(event) =>
                                handleAddUsers(
                                  event.target.value,
                                  event.target.name,
                                  index,
                                  setProjectUserInputField,
                                  projectUserData.projectUsers,
                                  inputError,
                                  setInputError
                                )
                              }
                            />
                            {<InputErrorBox Message={inputError[`startDate${index}`]} />}
                          </div>
                        </td>
                        <td>
                          <div className=''>
                            <input
                              className='form-control mt-2'
                              style={{cursor: 'pointer', width: '130px'}}
                              type='date'
                              name='endDate'
                              value={obj?.endDate}
                              disabled={!obj.selected}
                              onChange={(event) =>
                                handleAddUsers(
                                  event.target.value,
                                  event.target.name,
                                  index,
                                  setProjectUserInputField,
                                  projectUserData.projectUsers,
                                  inputError,
                                  setInputError
                                )
                              }
                            />
                            {<InputErrorBox Message={inputError[`endDate${index}`]} />}
                          </div>
                        </td>
                        <td>
                          <div className=''>
                            <select
                              className='form-select border-radius mt-2'
                              style={{cursor: 'pointer', width: '80px'}}
                              name='allocationPercentage'
                              value={obj?.allocationPercentage}
                              disabled={!obj.selected}
                              onChange={(event) =>
                                handleAddUsers(
                                  event.target.value,
                                  event.target.name,
                                  index,
                                  setProjectUserInputField,
                                  projectUserData.projectUsers,
                                  inputError,
                                  setInputError
                                )
                              }
                            >
                              <option value='' hidden={true}></option>
                              <option>25</option>
                              <option>50</option>
                              <option>75</option>
                              <option>100</option>
                            </select>
                            {/* {<InputErrorBox Message={inputError[`allocationPercentage${index}`]} />} */}
                          </div>
                        </td>
                        {/* <td>
                          <div className=''>
                            <select
                              className='form-select border-radius mt-2'
                              style={{cursor: 'pointer'}}
                              name='status'
                              value={obj?.status}
                              disabled={!obj.selected}
                              onChange={(event) =>
                                handleAddUsers(
                                  event.target.value,
                                  event.target.name,
                                  index,
                                  setProjectUserInputField,
                                  projectUserData?.projectUsers,
                                  inputError,
                                  setInputError
                                )
                              }
                            >
                              <option>On Boarded</option>
                              <option>Active</option>
                              <option>Released</option>
                            </select>
                            {<InputErrorBox Message={inputError[`status${index}`]} />}
                          </div>
                        </td> */}
                      </tr>
                      <tr
                        className=''
                        key={`secondIndex${index}`}
                        hidden={!showAllocatedUsersData.includes(index)}
                      >
                        <td className='' colSpan={12}>
                          <div className='table table-responsive table-row-dashed'>
                            <table className='table table-hover table-row-dashed dataTabl'>
                              <thead className=''>
                                <tr>
                                  <th style={{width: '53px'}}></th>
                                  <th className='fw-bold text-muted' style={{width: '310px'}}>
                                    {changeTextCapital('Project Name')}
                                  </th>
                                  <th className='fw-bold text-muted' style={{width: '180px'}}>
                                    {changeTextCapital('Role')}
                                  </th>
                                  <th className='fw-bold text-muted' style={{width: '198px'}}>
                                    {changeTextCapital('Start Date')}
                                  </th>
                                  <th className='fw-bold text-muted' style={{width: '198px'}}>
                                    {changeTextCapital('End Date')}
                                  </th>
                                  <th className='fw-bold text-muted'>
                                    {changeTextCapital('Allocation (%)')}
                                  </th>
                                </tr>
                              </thead>
                              <tbody className=''>
                                {allocateUserData.length > 0 ? (
                                  allocateUserData.map((data) => {
                                    return (
                                      <>
                                        <tr key={`data${data?.id}`}>
                                          <td>
                                            <div></div>
                                          </td>
                                          <td className='p-3'>
                                            <div>
                                              <div className=''>
                                                <div className='fw-semibold'>
                                                  {changeTextSentenceCase(
                                                    data?.project?.name ?? '-'
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                          <td className='p-3 '>
                                            <span className=''>{data?.role ?? '-'}</span>
                                          </td>
                                          <td className='p-3 '>
                                            <span className=''>
                                              {dateFormat(data?.startDate ?? '-')}
                                            </span>
                                          </td>
                                          <td className='p-3 '>
                                            <span className=''>
                                              {dateFormat(data?.endDate ?? '-')}
                                            </span>
                                          </td>
                                          <td className='p-3 '>
                                            <span className=''>
                                              {data?.allocationPercentage ?? '-'}
                                            </span>
                                          </td>

                                          {/* <td className='p-3 '></td> */}
                                        </tr>
                                      </>
                                    )
                                  })
                                ) : (
                                  <td className='text-center text-gray-600' colSpan={6}>
                                    This user is not assigned to any projects .
                                  </td>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </td>
                      </tr>
                    </>
                  )
                })}
              </tbody>
            ) : (
              <tbody className='justify-content-center'>
                <tr>
                  <td colSpan={7} className='fw-semibold mt-5'>
                    <div className='fw-semibold text-center'>
                      <div className='text-gray-600 fs-3 mb-2'>No users found</div>
                      <div className='text-muted fs-6'>Try to search by username</div>
                    </div>
                    <div className='text-center'>
                      <img
                        src='/media/avatars/5.png'
                        alt='UserNotFound'
                        className='w-70 h-100px h-sm-225px'
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </div>
      <span className='ms-8'>{<InputErrorBox Message={inputError.selected} />}</span>

      <div className='d-flex justify-content-end'>
        {/* <Button
          variant='filled'
          color='green'
          className='mt-10 me-5'
          loading={SubmitAPIStatus.loading}
          onClick={() =>
            handleAddUsersFunctionallty(
              projectId,
              projectUserData,
              setAPIStatus,
              setAPIStatus,
              validateAddUser,
              setInputError,
              setAPIStatus,
              setProjectUser,
              handleNextStep
            )
          }
        >
          Add Selected Users
        </Button> */}
      </div>
    </>
  )
}

export default AddUsers
