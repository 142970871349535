import Chart from 'react-apexcharts'

const GroupPieCharts = () => {
  return (
    <div>
      <div className=''>
        <div className=''>
          <div className='card me-2 me-sm-3 me-md-5 me-lg-5 mb-3 mb-sm-4 mb-md-5 mb-lg-5'>
            <div className='card-header' style={{minHeight: '3rem'}}>
              <div className='card-title d-flex flex-column fw-bold'>Groups</div>
            </div>
            <div className='d-flex justify-content-center'>
              <div className='mb-5'>
                {/* {isNoData ? (
                  <>
                    <div className='fw-bold text-gray-600 text-center mt-7 mb-5'>
                      No Data Available
                    </div>
                    <div className='text-center'>
                      <img
                        src='/media/avatars/19-dark.png'
                        alt='noReacordAvailable'
                        className='w-70 h-80px h-sm-325px'
                      />
                    </div>
                  </>
                ) : ( */}
                <Chart
                  type='donut'
                  redrawOnParentResize='true'
                  // series={[
                  //   Number(allProjectTaskStatusCount.ToDoTasks) || 0,
                  //   Number(allProjectTaskStatusCount.InProgress) || 0,
                  //   Number(allProjectTaskStatusCount.OnHold) || 0,
                  //   Number(allProjectTaskStatusCount.CompletedTasks) || 0,
                  // ]}
                  series={[40, 30, 20, 10, 25]}
                  options={{
                    labels: ['Admin', 'HR', 'IT', 'Sales', 'Facilities'],
                    colors: ['#4DA6FF', '#26E7A6', '#FFD633', '#F1416C', '#744EA4'],
                    noData: {text: 'empty data'},

                    legend: {
                      show: true,
                      // Position legend to the right
                      //  horizontalAlign: 'left', // Align legend text to the left
                      fontSize: '12px',
                      horizontalAlign: 'center',
                      position: 'bottom',
                      fontFamily: 'Arial, sans-serif',
                      markers: {
                        width: 12,
                        height: 12,
                      },
                      labels: {
                        colors: 'primary',
                      },
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    plotOptions: {
                      pie: {
                        dataLabels: {
                          offset: -5,
                        },
                        expandOnClick: false,
                      },
                    },
                    responsive: [
                      {
                        breakpoint: 1000,
                        options: {
                          plotOptions: {
                            pie: {
                              horizontal: false,
                              dataLabels: {
                                offset: -5,
                              },
                            },
                          },
                          legend: {
                            position: 'bottom',
                          },
                          Chart: {
                            position: 'left',
                          },
                        },
                      },
                    ],
                  }}
                ></Chart>
                {/* )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GroupPieCharts
