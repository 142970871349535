import {error} from 'console'
import {getActiveUser, getAdminDatas} from '../../../../api/DashboardAPI'

export const getAdminData = (setAdminData: Function, setAPIStatus: Function) => {
  setAPIStatus((prev: any) => ({...prev, loading: true}))
  getAdminDatas().then((res: any) => {
    if (res.statusCode === 200) {
      setAdminData(res.data)
      setAPIStatus((prev: any) => ({...prev, loading: false}))
    } else {
      setAPIStatus((prev: any) => ({...prev, loading: false, error: res.message}))
    }
  })
}

export const getActiveUsersData = (
  setActiveUsers: Function,
  setAPIStatus: Function,
  setUserDataModal: Function,
  status: string
) => {
  setAPIStatus((prev: any) => ({...prev, loading: true}))
  getActiveUser(status).then((res: any) => {
    if (res.statusCode === 200) {
      setActiveUsers(res.data)
      setUserDataModal(true)
      setAPIStatus((prev: any) => ({...prev, loading: false}))
    } else {
      setAPIStatus((prev: any) => ({...prev, loading: false, error: res.message}))
    }
  })
}
