import {Button, Divider, Group} from '@mantine/core'
import {useContext, useEffect, useState} from 'react'

import {checkRole} from '../../../_metronic/helpers'
import API from '../../../api/APIUrl'
import {EDIT_ROW_COLORS} from '../../../utils/contants'
import {getRecords, handleInputChange} from '../../CommonFunction'
import ActionColumn from '../../components/ActionColumn'
import CustomCheckInput from '../../components/CustomInput/CustomCheckInput'
import CustomTextInput from '../../components/CustomInput/CustomTextInput'
import CustomTable from '../../components/CustomTable'
import InputErrorBox from '../../components/InputErrorBox'
import SubmitCancleButton from '../../components/SubmitCancelButton'
import roleContext from '../../context/roleContext'
import {RoleMasterInitialInput} from '../../data/AdminModuleDefaultData'
import {APIStatusData, SubmitAPIStatusData} from '../../data/OtherDefaultData'
import {
  RoleMasterFeatureInputType,
  RoleMasterFeatureType,
  RoleMasterInput,
  RoleMasterOutput,
} from '../../types/AdminModuleTypes'
import {InputErrorType, RoleContextValue, SubmitAPIStatusType} from '../../types/OtherTypes'
import {
  cancelSubmit,
  getFeatureLevel,
  getFeaturesData,
  getRoleByID,
  getRoleTableHeaderData,
  handleFeatureLevelChange,
  handleSubmit,
  validateForm,
} from './RoleFunction'

const Role = () => {
  let roleState: RoleContextValue = useContext(roleContext)
  let {roleTableHeaderData} = getRoleTableHeaderData(
    !checkRole(['Roles'], roleState.features, 'edit')
  )
  const [editId, setEditId] = useState<string | null>(null)
  const [showForm, setShowForm] = useState<boolean>(false)
  const [inputError, setInputError] = useState<InputErrorType>({})
  const [submitAPIStatus, setSubmitAPIStatus] = useState<SubmitAPIStatusType>(SubmitAPIStatusData)
  const [role, setRole] = useState<RoleMasterOutput>({...APIStatusData, data: []})
  const [features, setFeatures] = useState<RoleMasterFeatureType[]>([])
  const [featureLevels, setFeatureLevels] = useState<
    {
      id: string
      name: string
      permission: {feature: string; permission: {id: string; name: string}[]}[]
    }[]
  >([])
  const [inputFeatures, setInputFeatures] = useState<RoleMasterFeatureInputType>({})
  const [formField, setFormField] = useState<RoleMasterInput>(RoleMasterInitialInput)
  const getTableBody = () => {
    return role?.data?.map((obj) => {
      return (
        <tr
          className={`${editId === obj?.id ?? '-' ? `${EDIT_ROW_COLORS} ` : ''}`}
          key={obj?.id ?? '-'}
        >
          <td className='ms-1 d-flex justify-content-top'>
            <div className='ms-2 fw-bold'>{obj?.name ?? '-'}</div>
          </td>
          <td style={{borderBottom: '1px solid'}}>
            <div className='row flex-wrap flex-grow-0 ms-1'>
              {obj.permission.map((module) => (
                <>
                  <div className='mb-5' style={{width: '14.5rem'}}>
                    <div className='d-flex align-items-start flex-wrap flex-column'>
                      <div className='fw-bold me-2 mb-1'>{module?.feature}</div>{' '}
                      <div className='mb-5' style={{width: '12.5rem'}}>
                        <div className='d-flex align-items-start flex-wrap '>
                          {module.permission.map((permissionName) => (
                            <div className='d-flex flex-column gap-2 ms-2'>
                              <CustomCheckInput
                                key={permissionName.id}
                                label={permissionName?.name}
                                disabled
                                checked={permissionName.id === permissionName.id}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </td>

          <td hidden={!checkRole(['Roles'], roleState.features, 'edit')}>
            <td>
              <ActionColumn
                edit={{
                  onClick: () => {
                    getRoleByID(
                      obj.id,
                      formField,
                      setFormField,
                      setEditId,
                      setInputError,
                      setFeatures,
                      setInputFeatures,
                      setShowForm
                    )
                  },
                }}
              />
            </td>
          </td>
        </tr>
      )
    })
  }

  useEffect(() => {
    let mounted = true
    if (mounted) {
      getFeaturesData(setFeatures, setInputFeatures)
      getFeatureLevel(setFeatureLevels)
      getRecords(API.ROLES, setRole)
    }

    return () => {
      mounted = false
    }
  }, [])

  return (
    <>
      <div className='card my-4' hidden={!showForm}>
        <div className='card-header   py-0'>
          <h4 className='card-title'>{editId !== null ? 'Update' : 'Add'} Role</h4>
        </div>
        <div className='card-body py-4'>
          <div className='row'>
            <div className='fv-row mb-5 col-md-2'>
              <CustomTextInput
                label='User Role Name'
                name='roleName'
                value={formField.roleName.toUpperCase()}
                onChange={(event) =>
                  handleInputChange(event, setFormField, inputError, setInputError)
                }
                id='roleName'
                error={inputError.roleName}
              />
            </div>

            {features.length > 0 ? (
              <div className='fv-row mb-5 col-md-12'>
                <label className='fs-6 fw-semibold mb-2'>Features Access/Permission</label>
                <span className='text-danger ms-1 fs-7 fw-bold'>*</span>
                <div className='d-flex flex-wrap'>
                  {features?.map((obj: RoleMasterFeatureType, index) => (
                    <>
                      <div className='mb-8'>
                        <div className='fw-bold me-2 mb-1'>{obj?.name}</div>{' '}
                        <div className='d-flex'>
                          {obj?.permission?.map((feature) => (
                            <div className='' style={{width: '7.3rem'}}>
                              <CustomCheckInput
                                label={feature?.name}
                                onChange={() =>
                                  handleFeatureLevelChange(
                                    feature.id,
                                    obj.id,
                                    inputFeatures,
                                    setInputFeatures
                                  )
                                }
                                checked={inputFeatures[`${obj.id}`]?.includes(feature?.id)}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                      {/* <hr hidden={features.length === index + 1} /> */}
                    </>
                  ))}
                </div>
                {<InputErrorBox Message={inputError.feature} />}
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        <SubmitCancleButton
          cancle={{
            onClick: () => {
              cancelSubmit(
                setFormField,
                setEditId,
                setInputError,
                setFeatures,
                setInputFeatures,
                setShowForm
              )
            },
          }}
          submit={{
            editid: editId,
            loading: submitAPIStatus.loading,
            onClick: () =>
              handleSubmit(
                formField,
                getRecords,
                setRole,
                setEditId,
                setFormField,
                editId,
                setInputError,
                validateForm,
                setFeatures,
                setInputFeatures,
                inputFeatures,
                setSubmitAPIStatus,
                setShowForm
              ),
          }}
        />
      </div>

      <CustomTable
        data={role}
        setData={setRole}
        url={API.ROLES}
        tableBody={getTableBody}
        tableHeadData={roleTableHeaderData}
        tableLabel='Roles'
        newRecordButton={
          <Button
            type='button'
            className='btn btn-primary btn-sm me-3 mt-3 mb-3'
            onClick={() => setShowForm(true)}
            hidden={showForm || !checkRole(['Roles'], roleState.features, 'edit')}
          >
            Add Roles
          </Button>
        }
      />
    </>
  )
}

export default Role
