import {toast} from 'react-toastify'
import {Message} from '../../../_metronic/helpers'
import {addTenant} from '../../../api/TenantAPI'

export const handleInputChange = (
  event: React.SyntheticEvent,
  setInputField: Function,
  InputField: {
    firstName: string
    lastName: string
    email: string
    companyName: string
    address: string
    country: string
    state: string
    city: string
    phoneNo: string
    pincode: string
    teamSize: string
  }
) => {
  let target = event.target as HTMLInputElement
  setInputField({
    ...InputField,
    [target.name]: target.type === 'checkbox' ? target.checked : target.value,
  })
}

export const handleSubmit = (
  InputField: {
    firstName: string
    lastName: string
    email: string
    companyName: string
    address: string
    country: string
    state: string
    city: string
    phoneNo: string
    
    pincode: string
    teamSize: string
  },

  initialState: {
    firstName: string
    lastName: string
    email: string
    companyName: string
    address: string
    country: string
    state: string
    city: string
    phoneNo: string
    pincode: string
    teamSize: string
  },
  setInputField: Function,

  setInputError: Function,
  validateForm: Function,
  setAPIStatus:Function,
  setSubmitAPIStatus:Function
) => {
  let {cnt, error} = validateForm(InputField)
  setInputError(error)

  if (cnt === 0) {
    setSubmitAPIStatus((prev: any) => ({...prev, loading: true}))
    addTenant(InputField).then((res) => {
      if (res.statusCode === 200) {
        toast.success(res.message)
        setInputField(initialState)
        setInputError('')
        setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
      } else {
        Message(res.message, 'error')
        setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
      }
    })
  }
}
export const validateForm = (InputField: {
  firstName: string
  lastName: string
  email: string
  companyName: string
  address: string
  country: string
  state: string
  city: string
  phoneNo: string
  pincode: string
  teamSize: string
}) => {
  let cnt = 0
  const error: any = {}

  if (!InputField.firstName) {
    cnt = cnt + 1
    error.firstName = 'Please enter a First Name.'
  }else {
    // First Name must be at least 2 characters.
    if (InputField.firstName.length < 2) {
      cnt = cnt + 1
      error.firstName = 'First Name must be at least 2 characters.'
    }

    if (InputField.firstName.length > 35) {
      cnt = cnt + 1
      error.firstName = 'First Name must not exceed 35 characters.'
    }

    const nameRegex = /^[a-zA-Z]+$/
    if (!nameRegex.test(InputField.firstName)) {
      cnt = cnt + 1
      error.firstName = 'Please enter a valid First Name using only alphabetic characters.'
    }
  }


  if (!InputField.lastName) {
    cnt = cnt + 1
    error.lastName = 'Please enter a Last Name.'
  }else {
    if (InputField.lastName.length < 2) {
      cnt = cnt + 1
      error.lastName = 'Last Name must be at least 2 characters.'
    }

    if (InputField.lastName.length > 35) {
      cnt = cnt + 1
      error.lastName = 'Last Name must not exceed 35 characters.'
    }

    const nameRegex = /^[a-zA-Z]+$/
    if (!nameRegex.test(InputField.lastName)) {
      cnt = cnt + 1
      error.lastName = 'Please enter a valid Last Name using only alphabetic characters.'
    }
  }


  if (!InputField.email) {
    cnt = cnt + 1
    error.email = 'Please enter a Email.'
  }else{
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const doubleDotRegex = /\.\./;
    if (!emailRegex.test(InputField.email) || doubleDotRegex.test(InputField.email)) {
      cnt = cnt + 1;
      error.email = 'Please enter a valid email.';
    }
  }

  if (!InputField.companyName) {
    cnt = cnt + 1
    error.companyName = 'Please enter a Company Name.'
  }else {
    if (InputField.companyName.length < 2) {
      cnt = cnt + 1
      error.companyName = 'Company Name must be at least 2 characters.'
    }

    if (InputField.companyName.length > 50) {
      cnt = cnt + 1
      error.companyName = 'Company Name must not exceed 50 characters.'
    }

    const cNameRegex = /^[a-zA-Z0-9\s]+[^\W_]*$/;
    if (!cNameRegex.test(InputField.companyName)) {
      cnt = cnt + 1
      error.companyName = 'Please enter a valid Company Name using only alphanumeric characters with spaces and special characters.'
    }
  }

  if (!InputField.address) {
    cnt = cnt + 1
    error.address = 'Please enter a Address.'
  }
  if (!InputField.country) {
    cnt = cnt + 1
    error.country = 'Please enter a Country.'
  }else if (!/^[a-zA-Z]+$/.test(InputField.country)) {
    cnt = cnt + 1;
    error.country = 'Please enter a valid Country Name using only alphabetic characters.';
} else if (InputField.country.length < 2) {
    cnt = cnt + 1;
    error.country = 'Country Name must be at least 2 characters.';
} else if (InputField.country.length > 50) {
    cnt = cnt + 1;
    error.country = 'Country Name must not exceed 50 characters.';
} 

  if (!InputField.state) {
    cnt = cnt + 1
    error.state = 'Please enter a State.'
  }else if (!/^[a-zA-Z]+$/.test(InputField.state)) {
    cnt = cnt + 1;
    error.state = 'Please enter a valid State Name using only alphabetic characters.';
} else if (InputField.state.length < 2) {
    cnt = cnt + 1;
    error.state = 'State Name must be at least 2 characters.';
} else if (InputField.state.length > 50) {
    cnt = cnt + 1;
    error.state = 'State Name must not exceed 50 characters.';
} 
  if (!InputField.city) {
    cnt = cnt + 1
    error.city = 'Please enter a City.'
  }else if (!/^[a-zA-Z]+$/.test(InputField.city)) {
    cnt = cnt + 1;
    error.city = 'Please enter a valid City Name using only alphabetic characters.';
} else if (InputField.city.length < 2) {
    cnt = cnt + 1;
    error.city = 'City Name must be at least 2 characters.';
} else if (InputField.city.length > 50) {
    cnt = cnt + 1;
    error.city = 'City Name must not exceed 50 characters.';
} 
  if (!InputField.phoneNo) {
    cnt = cnt + 1
    error.phoneNo = 'Please enter a Phone No.'
  }else if (!/^\d+$/.test(InputField.phoneNo)) {
    cnt = cnt + 1;
    error.phoneNo = 'Please enter a valid Mobile Number using only numeric characters.';
} else if (InputField.phoneNo.length !== 10) {
    cnt = cnt + 1;
    error.phoneNo = 'Mobile Number must be exactly 10 digits.';
} 
  if (!InputField.pincode) {
    cnt = cnt + 1
    error.pincode = 'Please enter a Pin Code.'
  }else if (!/^\d+$/.test(InputField.pincode)) {
    cnt = cnt + 1;
    error.pincode = 'Please enter a valid Pin Code using only numeric characters.';
} else if (InputField.pincode.length !== 6) {
    cnt = cnt + 1;
    error.pincode = 'Pin Code must be exactly 6 digits.';
}
  if (!InputField.teamSize) {
    cnt = cnt + 1
    error.teamSize = 'Please enter/select a Team Size.'
  }

  return {error, cnt}
}
