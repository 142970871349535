import React, {useEffect, useState} from 'react'
import {Stepper} from '@mantine/core'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faDiagramProject,
  faProjectDiagram,
  faTasks,
  faUserPlus,
} from '@fortawesome/free-solid-svg-icons'

const ProjectStepper = ({currentStep}) => {
  const [active, setActive] = useState(1)

  useEffect(() => {
    setActive(currentStep + 1)
  }, [currentStep])

  const handleStepClick = (step) => {
    setActive(step)
  }

  return (
    <Stepper active={currentStep} color='green'>
      <Stepper.Step
        icon={<FontAwesomeIcon icon={faProjectDiagram} />}
        description='Create a Project'
      />
      <Stepper.Step
        icon={<FontAwesomeIcon icon={faUserPlus} />}
        description='Add User in Project'
      />
      <Stepper.Step icon={<FontAwesomeIcon icon={faTasks} />} description='Create a Task' />
    </Stepper>
  )
}

export default ProjectStepper
