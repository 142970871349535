import React, {useEffect, useState} from 'react'
import {useAuth} from '../../modules/auth'
import {
  addManualLeaves,
  cancelSubmit,
  getLeaveBalanceById,
  getallUsersLeave,
  handleInputChanges,
  validateFormFields,
} from './LeaveFunctions'
import {changeTextCapital} from '../../../_metronic/helpers'
import {Button} from '@mantine/core'
import {Modal} from 'react-bootstrap'
import Select2 from '../../components/select2'
import InputErrorBox from '../../components/InputErrorBox'
import {EDIT_ROW_COLORS} from '../../../utils/contants'
import {ToastContainer} from 'react-toastify'

const LeaveAccount = ({getLeaveBalances, setBalance}) => {
  const {currentUser} = useAuth()
  const [editId, setEditId] = useState(null)
  const [APIStatus, setAPIStatus] = useState({
    loading: false,
    error: null,
    submitLoading: false,
  })
  const initialState = {
    leaveType: '',
    leaveDate: {
      startDate: '',
      endDate: '',
      type: '',
      approvedBy: {
        id: '',
      },
    },
    sender: {
      id: '',
    },
  }
  const [inputField, setInputField] = useState(initialState)
  const [userBalance, setUserBalance] = useState([
    {
      id: '',
      firstName: '',
      lastName: '',
      leaveBalance: {
        id: '',
        totalLeave: '',
        sickLeave: '',
        earnedLeave: '',
        optionalLeave: '',
        wfh: '',
        unpaidLeave: '',
      },
    },
  ])
  const [reasonModalOpen, setReasonModalOpen] = useState(false)
  const [inputError, setInputError] = useState<any>({})

  const fetchData = () => {
    getLeaveBalances(currentUser, setBalance, setAPIStatus)
  }
  useEffect(() => {
    let mounted = true
    if (mounted) {
      getallUsersLeave(setUserBalance, setAPIStatus)
    }
    return () => {
      mounted = false
    }
  }, [])
  return (
    <>
      <ToastContainer position={'bottom-center'} />

      <Modal
        show={reasonModalOpen}
        onClose={() => setReasonModalOpen(false)}
        animation={false}
        backdrop='static'
        centered
      >
        <div className='card'>
          <div className='modal-header '>
            <h2 className='fw-bolder'>Add Leave</h2>
            <div
              className='btn btn-icon btn-sm btn-active-icon-danger'
              data-kt-users-modal-action='close'
              style={{cursor: 'pointer'}}
              onClick={() => {
                setReasonModalOpen(false)
                cancelSubmit(setInputField, initialState, setEditId, setInputError)
              }}
            >
              <i className='fa-solid fa-xmark fs-1' />
            </div>
          </div>

          <div className='card-body'>
            <div className='fv-row mb-5 col-md-12'>
              <label className='required fs-6 fw-semibold mb-2'> Subject</label>
              <Select2
                name='leaveType'
                data={[
                  {label: 'Optional Leave', value: 'Optional Leave'},
                  {label: 'Paid', value: 'Paid'},
                  {label: 'Sick Leave', value: 'Sick Leave'},
                  {label: 'Unpaid', value: 'Unpaid'},
                  {label: 'Work From Home', value: 'Work From Home'},
                ]}
                value={inputField.leaveType}
                placeholder='Subject'
                onChange={(selectedOption: any) => {
                  setInputField((prevInputField) => ({
                    ...prevInputField,
                    leaveType: selectedOption,
                  }))
                }}
              />
              <InputErrorBox Message={inputError.leaveType} />
            </div>

            <div className='row'>
              <div className='fv-row mb-5 col-md-6'>
                <label className='required fs-6 fw-semibold mb-2'> Start Date</label>
                <input
                  className='form-control mt-2'
                  type='date'
                  value={inputField.leaveDate.startDate}
                  name='startDate'
                  onChange={(event) =>
                    handleInputChanges(event, 'leaveDate', setInputField, inputField)
                  }
                />
                <InputErrorBox Message={inputError.startDate} />
              </div>

              <div className='fv-row mb-5 col-md-6'>
                <label className='required fs-6 fw-semibold mb-2'> End Date</label>
                <input
                  className='form-control mt-2'
                  type='date'
                  value={inputField.leaveDate.endDate}
                  name='endDate'
                  onChange={(event) =>
                    handleInputChanges(event, 'leaveDate', setInputField, inputField)
                  }
                />
                <InputErrorBox Message={inputError.endDate} />
              </div>
            </div>

            <div className=''>
              <div className='fv-row me-1 mb-2 '>
                <label className='required fw-bold fs-6 mb-2'>Type</label>
                <div className='d-flex mt-3'>
                  <div className='form-check mb-2'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='full'
                      name='type'
                      value='full'
                      checked={inputField.leaveDate.type === 'full'}
                      onChange={(event) =>
                        handleInputChanges(event, 'leaveDate', setInputField, inputField)
                      }
                    />

                    <label htmlFor='full' className='fw-bold text-gray-800 d-block me-3'>
                      Full
                    </label>
                  </div>

                  <div className='form-check mb-2'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='type'
                      value='firstHalf'
                      checked={inputField.leaveDate.type === 'firstHalf'}
                      onChange={(event) =>
                        handleInputChanges(event, 'leaveDate', setInputField, inputField)
                      }
                    />
                    <label htmlFor='firstHalf' className=' fw-bold text-gray-800 d-block me-3'>
                      First Half
                    </label>
                  </div>

                  <div className='form-check mb-2'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='type'
                      value='secondHalf'
                      checked={inputField.leaveDate.type === 'secondHalf'}
                      onChange={(event) =>
                        handleInputChanges(event, 'leaveDate', setInputField, inputField)
                      }
                    />
                    <label htmlFor='secondHalf' className=' fw-bold text-gray-800 d-block'>
                      Second Half
                    </label>
                  </div>
                </div>
              </div>
              <InputErrorBox Message={inputError.type} />
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end'>
            <Button
              onClick={() => {
                cancelSubmit(setInputField, initialState, setEditId, setInputError)
              }}
              type='button'
              className='btn btn-secondary me-2 btn-sm font-weight-bold '
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                addManualLeaves(
                  inputField,
                  currentUser,
                  setUserBalance,
                  setEditId,
                  initialState,
                  setInputField,
                  editId,
                  setAPIStatus,
                  setInputError,
                  validateFormFields,
                  setReasonModalOpen,
                  fetchData
                )
              }}
              type='button'
              className='btn btn-primary btn-sm font-weight-bold '
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>

      <div className='card mb-5 mb-xl-8'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Leave Account</span>

            <span className='text-muted mt-1 fw-semibold fs-7'></span>
          </h3>
        </div>

        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-100px'>{changeTextCapital('User Name')}</th>
                  <th className='min-w-130px text-center'>{changeTextCapital('Total Leaves')}</th>
                  <th className='min-w-130px text-center'>{changeTextCapital('Sick Leaves')}</th>
                  <th className='min-w-130px text-center'>{changeTextCapital('Earned Leaves')}</th>
                  <th className='min-w-130px text-center'>
                    {changeTextCapital('optional Leaves')}
                  </th>
                  <th className='min-w-130px text-center'>{changeTextCapital('Work From Home')}</th>
                  <th className='min-w-130px text-center'>{changeTextCapital('Unpaid Leaves')}</th>
                  <th className='min-w-130px text-center'>{changeTextCapital('Action')}</th>
                </tr>
              </thead>
              <tbody>
                {userBalance.map((obj: any) => (
                  <tr
                    className={`${
                      editId === obj?.leaveBalance?.id ?? '-' ? `${EDIT_ROW_COLORS} ` : ''
                    }`}
                    key={obj?.leaveBalance?.id ?? '-'}
                  >
                    <td className='min-w-100px'>
                      {obj?.firstName ?? '-'} {obj?.lastName ?? '-'}
                    </td>
                    <td className='min-w-130px text-center'>
                      {obj?.leaveBalance?.totalLeave ?? '-'}
                    </td>
                    <td className='min-w-130px text-center'>
                      {obj?.leaveBalance?.sickLeave ?? '-'}
                    </td>
                    <td className='min-w-130px text-center'>
                      {obj?.leaveBalance?.earnedLeave ?? '-'}
                    </td>
                    <td className='min-w-130px text-center'>
                      {obj?.leaveBalance?.optionalLeave ?? '-'}
                    </td>
                    <td className='min-w-130px text-center'>{obj?.leaveBalance?.wfh ?? '-'}</td>
                    <td className='min-w-130px text-center'>
                      {obj?.leaveBalance?.unpaidLeave ?? '-'}
                    </td>
                    <td className='min-w-130px text-center'>
                      <Button
                        type='button'
                        className='btn btn-light btn-light-primary btn-sm font-weight-bold '
                        onClick={() => {
                          getLeaveBalanceById(obj?.id, setEditId)
                          setReasonModalOpen(true)
                        }}
                      >
                        <i className='fa-solid fa-plus'></i>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default LeaveAccount
