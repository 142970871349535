import {toast} from 'react-toastify'
import Swal from 'sweetalert2'
import {Message} from '../../../_metronic/helpers'
import {getUsersById} from '../../../api/AdminAPI'
import {
  getPendingRequests,
  handleAttendanceRejects,
  handleAttendancesApproves,
  handleAttendancessApproves,
  handleClearFilterss,
  handleForwardToHRs,
} from '../../../api/AttendanceAPI'
import {AlertProps} from '../../data/AlertDefaults'
import {getHolidaysByDateRange} from '../../../api/HolidayAPI'
import {APIResponse} from '../../types/OtherTypes'

export const cancelSubmit = (
  setInputField: Function,
  InputField: {employeeName: {label: string; value: string}; startDate: string; endDate: string}
) => {
  setInputField(InputField)
}

export const getPendingRequest = (setTableData: Function, setAPIStatus: Function, currentUser) => {
  setAPIStatus((prev: any) => ({...prev, loading: true}))
  getPendingRequests(currentUser).then((res: any) => {
    if (res.statusCode === 200) {
      setTableData(res.data)
      setAPIStatus((prev: any) => ({...prev, loading: false}))
    } else {
      setAPIStatus((prev: any) => ({...prev, loading: false, error: res.message}))
    }
  })
}
export const getUserByIdForAMS = (setUser: Function, setAPIStatus: Function, currentUser) => {
  setAPIStatus((prev: any) => ({...prev, loading: true}))
  getUsersById(currentUser?.id).then((res: any) => {
    if (res.statusCode === 200) {
      setUser(res.data)
      setAPIStatus((prev: any) => ({...prev, loading: false}))
    } else {
      setAPIStatus((prev: any) => ({...prev, loading: false, error: res.message}))
    }
  })
}

export const handleAttendanceApprove = async (id, fetchData, currentUser) => {
  Swal.fire({
    ...AlertProps,
    icon: 'warning',
    title: `Are you sure you want to approved?`,
  }).then(async (result: any) => {
    if (result.isConfirmed) {
      handleAttendancesApproves(id, currentUser).then((res: any) => {
        if (res.statusCode === 200) {
          fetchData()
          toast.success(res.message)
        } else {
          Message(res.message, 'error')
        }
      })
    }
  })
}
export const handleAttendanceApproves = async (id, currentUser, fetchTableData) => {
  Swal.fire({
    ...AlertProps,
    icon: 'warning',
    title: `Are you sure you want to approved?`,
  }).then(async (result: any) => {
    if (result.isConfirmed) {
      handleAttendancessApproves(id, currentUser).then((res: any) => {
        if (res.statusCode === 200) {
          fetchTableData()
          toast.success(res.message)
        } else {
          Message(res.message, 'error')
        }
      })
    }
  })
}

export const handleForwardToHR = async (
  id,
  setSelectedItemId,
  fetchData,
  inputField,
  setInputField,
  initialState,
  setReasonModalOpen,
  currentUser,
  setReasonError,
  setAPIStatus
) => {
  if (!inputField.reason) {
    setReasonError('Please enter a Reason.')
    return
  }
  Swal.fire({
    ...AlertProps,
    icon: 'warning',
    title: `Are you sure you want to forward with HR?`,
  }).then(async (result: any) => {
    if (result.isConfirmed) {
      const data = fetchData()

      handleForwardToHRs(inputField, id, currentUser).then((res) => {
        if (res.statusCode === 200) {
          setAPIStatus((prev: any) => ({...prev, loading: true}))
          fetchData()
          toast.success(res.message)
          setReasonModalOpen(false)
          setInputField(initialState)
        } else {
          Message(res.message, 'error')
        }
        setAPIStatus((prev: any) => ({...prev, loading: false}))
      })
    }
  })
}

export const handleAttendanceReject = async (
  idForReason,
  setIdForReason,
  fetchData,
  inputField,
  setInputField,
  initialState,
  setReasonForDecline,
  currentUser,
  setReasonError
) => {
  if (!inputField.reason) {
    setReasonError('Please enter a Reason.')
    return
  }

  Swal.fire({
    ...AlertProps,
    icon: 'warning',
    title: `Are you sure you want to reject?`,
  }).then(async (result) => {
    if (result.isConfirmed) {
      const data = fetchData()

      handleAttendanceRejects(inputField, idForReason, currentUser).then((res) => {
        if (res.statusCode === 200) {
          fetchData()
          toast.success(res.message)
          setReasonForDecline(false)
          setInputField(initialState)
        } else {
          Message(res.message, 'error')
        }
      })
    }
  })
}
export const truncateString = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...'
  }
  return text
}

export const handleClearFilters = async (
  setInputField,
  initialState,
  setLessDateError,
  currentUser,
  setTableData,
  setDateError,
  setNameError,
  setCheckInError,
  setNoMatchingRecords,
  tab,
  setHolidayData
) => {
  setInputField(initialState)
  setLessDateError('')
  getHolidaysByDateRange(initialState).then((res: APIResponse) => {
    if (res.statusCode) {
      setHolidayData(res.data)
    } else {
      setHolidayData([])
    }
  })
  console.log('tab in attendance', tab)
  handleClearFilterss(currentUser, initialState, tab).then((res) => {
    if (res.statusCode === 200) {
      setTableData([
        {
          result: res?.data?.result ?? res?.data,
          queryData: res?.data?.queryParams ?? null,
        },
      ])
      setDateError('')
      setNameError('')
      setCheckInError('')
      setNoMatchingRecords(false)
    } else {
      setTableData([])
      setNoMatchingRecords(true)
    }
  })
}

export const getDateInYYYYMMDD = (date: string) => {
  const newDate: Date = new Date(date)
  var day = newDate.getDate()
  var month = newDate.getMonth() + 1
  var year = newDate.getFullYear()
  let customDate = year + '-' + ('0' + month).slice(-2) + '-' + ('0' + day).slice(-2)
  // console.log('customDate', customDate)
  // console.log('newDate', newDate)

  return customDate
}

export const isGapThreeMonths = (startDate: Date, endDate: Date): boolean => {
  // Calculate three months ago from the start date
  const threeMonthsAgo = new Date(startDate)
  threeMonthsAgo.setMonth(startDate.getMonth() - 3)
  // Calculate three months from the start date
  const threeMonthsFromStart = new Date(startDate)
  threeMonthsFromStart.setMonth(startDate.getMonth() + 3)
  // Check if the end date is within the range of three months ago and three months from the start date
  return (
    endDate.getTime() >= threeMonthsFromStart.getTime() ||
    endDate.getTime() <= threeMonthsAgo.getTime()
  )
}

export const getDateBeforeSevenDays = () => {
  let nowDate = new Date()
  nowDate.setDate(nowDate.getDate() - 7)
  // console.log(getDateInYYYYMMDD(nowDate.toString()))
  return getDateInYYYYMMDD(nowDate.toString())
}

export const getDateBeforeOneMonth = () => {
  let nowDate = new Date()
  nowDate.setDate(nowDate.getDate() - 30)
  // console.log(getDateInYYYYMMDD(nowDate.toString()))
  return getDateInYYYYMMDD(nowDate.toString())
}

export const validateDateRange = (inputField, setDateError, setLessDateError) => {
  const currentDate = new Date()
  const startDate = new Date(inputField.startDate)
  const endDate = new Date(inputField.endDate)
  let isError = 0

  if (!inputField.startDate && inputField?.endDate) {
    setDateError('Please select/enter a Start Date.')
    isError += 1
  }
  // console.log('isGapThreeMonths', isGapThreeMonths(startDate, endDate))
  if (inputField?.startDate && inputField?.endDate) {
    if (isGapThreeMonths(startDate, endDate)) {
      isError = isError + 1

      setLessDateError('End Date must be within 3 months from the Start Date.')
    }
  }

  if (endDate < startDate) {
    isError = +1

    setLessDateError('End Date must be greater than or equal to Start Date.')
  }

  if (startDate > currentDate) {
    isError = isError + 1

    setDateError('Start Date must be less than or equal to Current Date.')
  }
  if (endDate > currentDate) {
    isError = isError + 1
    setLessDateError('End Date must be less than or equal to Current Date.')
  }

  // console.log('isError Value', isError)
  return isError === 0 ? true : false
}
