import {toast} from 'react-toastify'
import {
  addComment,
  deleteComments,
  getComment,
  getCommentById,
  updateComment,
} from '../../../../api/Organization'
import {Message} from '../../../../_metronic/helpers'
import {AnyObjectSchema} from 'yup'
import Swal from 'sweetalert2'
import {AlertProps} from '../../../data/AlertDefaults'
import {DELETE_MESSAGE} from '../../../../utils/contants'

export const handleSend = (
  taskId,
  InputField: {
    comment: string
    commenter: {
      id: string
    }
    file: File | null
  },
  setInputField,
  initialState: {
    comment: string
    commenter: {
      id: string
    }
    file: File | null
  },
  setEditId: Function,
  setComment: Function,
  setAPIStatus: Function,
  setInputError: Function,
  setSubmitAPIStatus: Function,
  validateComment: Function,
  cancelSend: Function,
  setSelectedFile: Function,
  setImageSelected: Function
  // setShowSaveButton: Function
) => {
  let {count, error} = validateComment(InputField)
  setInputError(error)
  if (count === 0) {
    setSubmitAPIStatus((prev: any) => ({...prev, loading: true}))
    addComment(taskId, InputField).then((res) => {
      if (res.statusCode === 200) {
        toast.success(res.message)
        setSelectedFile('/media/logos/uploadFile.jpg')
        setImageSelected(false)
        // setShowSaveButton(false)
        getComment(taskId).then((res: any) => {
          if (res.statusCode === 200) {
            // console.log('Create Data in API', res.data)
            setComment(res.data)

            setAPIStatus((prev: any) => ({...prev, loading: false}))
          } else {
            setAPIStatus((prev: any) => ({...prev, loading: false, error: res.message}))
          }
        })

        cancelSend(setInputField, initialState)
      } else {
        Message(res.message, 'error')
        setSubmitAPIStatus((prev: any) => ({...prev, loading: false, error: res.message}))
      }
      setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
    })
  }
}

export const getComments = (id, setComment: Function, setAPIStatus: Function) => {
  setAPIStatus((prev: any) => ({...prev, loading: true}))
  getComment(id).then((res: any) => {
    if (res.statusCode === 200) {
      setComment(res.data)
      setAPIStatus((prev: any) => ({...prev, loading: false}))
    } else {
      setAPIStatus((prev: any) => ({...prev, loading: false, error: res.message}))
    }
  })
}

export const getCommentsById = (
  id: string,
  updateComment: {
    comment: string
    commenter: {id: string}
  },

  setUpdatedComment: Function,
  setEditId: Function,
  setInputError: Function,
  editComment: Function
) => {
  getCommentById(id).then((res: any) => {
    if (res) {
      setUpdatedComment({
        ...updateComment,
        comment: res.data.comment,
      })
      editComment()
      setEditId(id)
      setInputError({})
      window.scrollTo(0, 0)
    } else {
      Message(res.message, 'error')
    }
  })
}

export const updateComments = (
  id,
  comment,
  taskId,
  setComment: Function,
  setInputField: Function,
  setAPIStatus: Function,
  cancelSend: Function,
  setInputError: Function,
  closeEditComment
) => {
  if (comment.comment !== comment.originalComment) {
    let {count, error} = validateComment(comment)
    setInputError(error)

    if (count === 0) {
      setAPIStatus((prev: any) => ({...prev, loading: true}))

      updateComment(id, comment).then((res: any) => {
        if (res.statusCode === 200) {
          closeEditComment()
          cancelSend(setInputField)
          toast.success(res.message)

          getComment(taskId).then((res: any) => {
            if (res.statusCode === 200) {
              setComment(res.data)
              setAPIStatus((prev: any) => ({...prev, loading: false}))
            } else {
              setAPIStatus((prev: any) => ({...prev, loading: false, error: res.message}))
            }
          })
        } else {
          Message(res.message, 'error')
          setAPIStatus((prev: any) => ({...prev, loading: false, error: res.message}))
        }
      })
    }
  } else {
    closeEditComment()
  }
}

export const deleteComment = (
  id: string,
  taskId,
  // getComments: Function,
  setComment: Function,
  setAPIStatus: Function,
  cancelSend: Function,
  setInputField: Function,
  InputField: {
    comment: string
    commenter: {id: string}
  },
  setEditId: Function,

  setInputError: Function
) => {
  cancelSend(setInputField, InputField, setEditId, setInputError)
  Swal.fire({...AlertProps, icon: 'warning', title: DELETE_MESSAGE}).then((result: any) => {
    if (result.isConfirmed) {
      deleteComments(id)
        .then((res: any) => {
          if (res.statusCode === 200) {
            getComment(taskId).then((res: any) => {
              if (res.statusCode === 200) {
                setComment(res.data)
                setAPIStatus((prev: any) => ({...prev, loading: false}))
              } else {
                setAPIStatus((prev: any) => ({...prev, loading: false, error: res.message}))
              }
            })

            toast.success(res.message)
          } else {
            Message(res.message, 'error')
          }
        })
        .catch((res: any) => {
          Message(res.message, 'error')
        })
    }
  })
}

export const handleInputChange = (event: React.SyntheticEvent, setInputField: Function) => {
  let target = event.target as HTMLInputElement
  setInputField((InputField: AnyObjectSchema) => ({
    ...InputField,
    [target.name]: target.type === 'checkbox' ? target.checked : target.value,
  }))
}

export const handleOtherInputChange = (
  value: string,
  fieldName: string,
  setInputField: Function
) => {
  setInputField((InputField: AnyObjectSchema) => ({
    ...InputField,
    [fieldName]: value,
  }))
  console.log('comment value after update', value)
}

export const validateComment = (InputField: {comment: string}) => {
  let count = 0
  const error: any = {}
  error.comment = ''
  if (!InputField.comment || !InputField.comment.replace(/<[^>]*>/g, '').trim()) {
    count = count + 1
    error.comment = 'Please enter a Comment.'
  }
  return {error, count}
}

export const cancelSend = (
  setInputField: Function,
  initialState: {
    id: string
    comment: string
    commenter: {
      id: string
    }
    file: null
  }
) => {
  setInputField(initialState)
}
