/* eslint-disable jsx-a11y/anchor-is-valid */

import {faEye, faEyeSlash, faKey} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {useContext, useEffect, useState} from 'react'
import {Link, useNavigate, useParams, useSearchParams} from 'react-router-dom'
import * as Yup from 'yup'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import {setupAxios} from '../core/AuthHelpers'
import {login} from '../core/_requests'
import roleContext from '../../../context/roleContext'
import { useThemeMode } from '../../../../_metronic/partials'
import { ThemeModeComponent } from '../../../../_metronic/assets/ts/layout'

const eye = <FontAwesomeIcon icon={faEye} />
const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Please enter a email'),

  password: Yup.string()
    .min(1, 'Minimum 1 symbols')
    .max(50, 'Maximum 50 letters')
    .required('Please enter a Password'),
})

const initialValues = {
  id: '',
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [searchParams, setSearchParams] = useSearchParams()
const {mode}=useThemeMode()
  const {token} = setupAxios()
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()

  const navigate = useNavigate()
  let roleState: any = useContext(roleContext)
  const [passwordShown, setPasswordShown] = useState(false)

  const togglePasswordVisiblity = () => {
    setPasswordShown((prev) => !prev)
  }
 
  

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      console.log(searchParams.get('tenant') ?? '')
      await login(values.id.trim(), values.email.trim(), values.password.trim()).then((res) => {
        setSubmitting(false)
        setLoading(false)
        if (res.statusCode == 200) {
          if (token) {
            setStatus('User is already logged in!')
            navigate(`/dashboard`)
          } else {
            saveAuth({...res.data, tenantId: res.data?.tenant})
            let features: {[key: string]: string[]}[] = []
            res.data?.role?.permission.map((obj: any) => {
              if (obj?.permission?.length > 0) {
                features?.push({
                  [`${obj?.feature}`]: obj.permission.map((level) => {
                    return level.name
                  }),
                })
              }
            })
            roleState.updateState(features)
            localStorage.setItem(
              'currentUserRole',
              JSON.stringify({
                features: features,
              })
            )
            localStorage.setItem('isLoggedIn', 'true')
            setCurrentUser({...res.data, tenantId: res.data?.tenant})
            setSubmitting(false)
            navigate(`/dashboard`)
          }
          sessionStorage.setItem('isLoggedIn', 'true')
        } else if (res.statusCode == 406) {
          setStatus(res.message)
          saveAuth(undefined)
          setSubmitting(false)
          setLoading(false)
          navigate(`/resetPassword`, {
            state: {userId: res.data.id, tenantId: res.data.tenant},
          })
          console.log('tenant', res.data.tenant)
        } else {
          setStatus(res.message)
          saveAuth(undefined)
          setSubmitting(false)
          setLoading(false)
        }
      })
    },
  })

  useEffect(() => {
    let mounted=true
    if (mounted = true) {
    console.log(localStorage.getItem('kt_theme_mode_menu'))
  }
    return () => {
      mounted=false
    }
  }, [])
  

  return (
    <>
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        {/* begin::Heading */}
        <div className='text-center mb-6'>
      <img
        alt='Skeletos'
        style={{ width: '170px', height: '120px', backgroundColor: 'transparent' }}
        src={ localStorage.getItem('kt_theme_mode_menu')==='dark' ||mode==='system' && ThemeModeComponent.getSystemMode()==='dark' ? '/media/logos/whitesialogo.png' : '/media/logos/sialogo.png'}
      ></img>

      <h1 className='text-dark fw-bold mb-3'>Sign in</h1>
    </div>
        {formik.status && (
          <div className='text-center mb-3'>
            <p className='text-danger'>{formik.status}</p>
          </div>
        )}
        {/* <div className=' border border-black p-4'> */}
        {/* begin::Form group */}
        <div className='fv-row mb-8'>
          <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
          <input
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email,
              }
            )}
            type='email'
            name='email'
            autoComplete='off'
            placeholder='Email'
            onChange={(e) => formik.setFieldValue('email', e.target.value.toLowerCase())}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        {/* begin::Form group */}
        <div className='fv-row mb-3 position-relative'>
          <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
          <div className='input-group'>
            <input
              placeholder='Password'
              type={passwordShown ? 'text' : 'password'}
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            <span className='input-group-text cursor-pointer' onClick={togglePasswordVisiblity}>
              {passwordShown ? (
                <FontAwesomeIcon icon={faEyeSlash} />
              ) : (
                <FontAwesomeIcon icon={faEye} />
              )}
            </span>
          </div>
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* end::Form group */}

        {/* begin::Wrapper */}
        <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
          <div />
        </div>
        {/* end::Wrapper */}

        {/* begin::Action */}
        <div className='d-grid mb-10'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-primary'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Sign In</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        <p className='text-dark'>
          <Link to='/forgotpassword'>Forgot Password?</Link>
        </p>
      </form>
    </>
  )
}
