import {toast} from 'react-toastify'
import {API_BASE_URL} from '../../../utils/contants'
import {setupAxios} from '../../modules/auth'
import axios from 'axios'

export const handleInputChange = (
  event: React.SyntheticEvent,
  setInputField: Function,
  InputField: {
    type: string
    startDate: string
    endDate: string
  }
) => {
  let target = event.target as HTMLInputElement
  setInputField({
    ...InputField,
    [target.name]: target.type === 'checkbox' ? target.checked : target.value,
  })
}
export async function createReports(
  InputField: {
    type: string
    startDate: string
    endDate: string
  },
  setInputField: Function,
  initialState: {
    type: string
    startDate: string
    endDate: string
  },

  validateForm: Function,
  setInputError: Function,
  cancelSubmit:Function
) {
  let {cnt, error} = validateForm(InputField)
  setInputError(error)

  if (cnt === 0) {
    const {token,tenantId} = setupAxios()

    const queryParams = new URLSearchParams({
      type: InputField.type,
      startDate: InputField.startDate,
      endDate: InputField.endDate,
    })

    const response = await axios.get(API_BASE_URL + `/reports?${queryParams.toString()}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-Tenant-Id':`${tenantId}`
      },
      responseType: 'blob',
    })

    const contentDisposition = response.request.getResponseHeader('content-disposition')

    if (response.status === 200) {
      const filenameMatch = contentDisposition.split('filename=')[1]
      console.log(response.data, contentDisposition, contentDisposition.split('filename=')[1])
      if (filenameMatch) {
        console.log(response.data)
        const filename = filenameMatch
        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })

        // Create a link element and trigger a download
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = filename
        link.click()
        toast.success('File downloaded successfully')
        cancelSubmit(setInputField, initialState, setInputError)
      }
    }
  }
}
export const validateForm = (InputField: { type: string; startDate: string; endDate: string }) => {
  let cnt = 0
  const error: any = {}

  if (!InputField.type.trim()) {
    cnt = cnt + 1
    error.type = 'Please select/enter a Module Type.'
  }
  if (!InputField.startDate.trim()) {
    cnt = cnt + 1
    error.startDate = 'Please select/enter a Start Date.'
  }else {
    const selectedDate = new Date(InputField.startDate)
    const currentDate = new Date()

    if (selectedDate > currentDate) {
      cnt = cnt + 1
      error.startDate = 'Start Date must not be greater than the current date.'
    }
  }
  if (!InputField.endDate.trim()) {
    cnt = cnt + 1
    error.endDate = 'Please select/enter an End Date.'
  } else if (new Date(InputField.endDate) < new Date(InputField.startDate)) {
    cnt = cnt + 1
    error.endDate = 'End Date must not be earlier than the Start Date.'
  }else {
    const selectedDate = new Date(InputField.endDate)
    const currentDate = new Date()

    if (selectedDate > currentDate) {
      cnt = cnt + 1
      error.endDate = 'End Date must not be greater than the current date.'
    }
  }
  return {error, cnt}
}

export const cancelSubmit = (
  setInputField: Function,
  InputField: {type: string; startDate: string; endDate: string},
  setInputError: Function
) => {
  setInputField(InputField)
  setInputError('')
}
