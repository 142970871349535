import {useState} from 'react'
import ComposeMail from './ComposeMail'
import Inbox from './Inbox'
import MailSidebar from './MailSidebar'
import Sent from './Sent'

const MailLayout = (editId) => {
  const [activeTab, setActiveTab] = useState('inbox')
  return (
    <div>
      <div className='content d-flex flex-column flex-column-fluid' id='kt_content'>
        <div className='d-flex flex-column flex-lg-row'>
          <MailSidebar setActiveTab={setActiveTab} />
          {activeTab === 'inbox' && <Inbox />}
          {activeTab === 'sent' && <Sent />}
          {activeTab === 'compose' && <ComposeMail mailId={editId} setActiveTab={setActiveTab} />}
        </div>
      </div>
    </div>
  )
}

export default MailLayout
