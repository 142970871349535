import {setupAxios} from '../app/modules/auth'
import {API_BASE_URL} from '../utils/contants'
import {api} from './APIMiddlewares'

export async function getAdminDatas() {
  const {token, tenantId} = setupAxios()
  const res = await fetch(API_BASE_URL + `/dashboard/admin`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'X-tenant-Id': `${tenantId}`,
    },
  })
  return await res.json()
}

export async function getActiveUser(status: string) {
  const {token, tenantId} = setupAxios()
  return await api.get(`/dashboard/admin/users?status=${status}`, token, tenantId, false)
}
