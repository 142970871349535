import {Center, Loader} from '@mantine/core'
import {useEffect, useState} from 'react'
import {useLocation, useSearchParams} from 'react-router-dom'
import {API_BASE_URL} from '../../../utils/contants'
import {setupAxios} from '../../modules/auth'

export const ActionPage = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const [apiStatus, setApiStatus] = useState({
    loading: false,
    error: null,
    success: null,
  })
  const updateAssetById = async (
    id: string,
    InputField: {
      category: string
      subCategory: string
      brand: string
      serialNo: string
      description: string
      purchaseDate: string
      status: string
      assignee: string
    },
    status: string,
    accessToken: string
  ) => {
    const {token} = setupAxios()
    const res = await fetch(API_BASE_URL + `/assets/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        status,
        accessToken,
        dto: {...InputField},
      }),
    })
    return await res.json()
  }
  useEffect(() => {
    let mounted = true
    let asset = searchParams.get('asset') ?? ''
    if (mounted) {
      setApiStatus((prev: any) => ({...prev, loading: true}))
      updateAssetById(
        searchParams.get('assetId') ?? '',
        JSON.parse(asset),
        location.pathname === '/accept/asset' ? 'accept' : 'reject',
        searchParams.get('t') ?? ''
      ).then((res) => {
        if (res.statusCode === 200) {
          setApiStatus((prev: any) => ({
            ...prev,
            loading: false,
            success: res.message,
            error: null,
          }))
        } else {
          setApiStatus((prev: any) => ({
            ...prev,
            loading: false,
            error: res.message,
            success: null,
          }))
        }
      })
    }
    return () => {
      mounted = false
    }
  }, [])

  return (
    <div className='container my-6 '>
      <Center>
        {apiStatus.loading ? (
          <div className='my-2'>
            <Loader color='cyan' />
          </div>
        ) : apiStatus.error ? (
          <div className='col-3 alert alert-danger bg-danger text-white  mb-10 mx-5'>
            {apiStatus.error}
          </div>
        ) : (
          apiStatus.success && (
            <div className='col-3 alert alert-success bg-success text-white mb-10 mx-5'>
              {apiStatus.success}
            </div>
          )
        )}
      </Center>
    </div>
  )
}
