import {toast} from 'react-toastify'
import {Message, clearInputError} from '../../../../_metronic/helpers'
import {
  addUserById,
  getProjectsById,
  getUsersAllocationsData,
  getUsersById,
  updateUsersById,
} from '../../../../api/Organization'
import {AnyObjectSchema} from 'yup'

export const handleInputChange = (event: React.SyntheticEvent, setUsersById: Function) => {
  let target = event.target as HTMLInputElement
  setUsersById((userById: AnyObjectSchema) => ({
    ...userById,
    [target.name]: target.type === 'checkbox' ? target.checked : target.value,
  }))
}

export const getProjectUsers = (id, setUsers: Function, setAPIStatus: Function) => {
  // console.log('in getUsers', setUsers)
  setAPIStatus((prev: any) => ({...prev, loading: true}))
  getProjectsById(id).then((res: any) => {
    // console.log(res)
    if (res.statusCode === 200) {
      // console.log(res)
      setUsers(res.data[0] ?? {})

      setAPIStatus((prev: any) => ({...prev, loading: false}))
    } else {
      setAPIStatus((prev: any) => ({...prev, loading: false, error: res.message}))
    }
  })
}

export const getProjectsUsersById = (id, setUsersById: Function, setAPIStatus: Function) => {
  setAPIStatus((prev: any) => ({...prev, loading: true}))

  getUsersById(id).then((res: any) => {
    if (res.statusCode === 200) {
      setUsersById(res.data ?? {})

      setAPIStatus((prev: any) => ({...prev, loading: false}))
    } else {
      Message(res.message, 'error')
      setAPIStatus((prev: any) => ({...prev, loading: false, error: res.message}))
    }
  })
}

export const updateUserById = (
  projectId,
  id,
  userById,
  setAPIStatus: Function,
  setUsersById: Function,
  setEditUser: Function,
  setProjectUser: Function
) => {
  setAPIStatus((prev: any) => ({...prev, loading: true}))
  updateUsersById(id, userById).then((res: any) => {
    if (res.statusCode === 200) {
      toast.success(res.message)
      getProjectsById(projectId).then((res) => {
        setProjectUser(res.data[0] ?? {})
      })
      setEditUser(false)
      setAPIStatus((prev: any) => ({...prev, loading: false}))
    } else {
      setAPIStatus((prev: any) => ({...prev, loading: false, error: res.message}))
      const errorMessage = res.message.split(',').join('\n')

      Message(errorMessage, 'error')
      setEditUser(false)
    }
  })
}

export const handleAddUsers = (
  value: string | null | boolean,
  inputName: string,
  index: number,
  setFormField: Function,
  projectUsers: any,
  inputError: any,
  setInputError: Function
) => {
  let data: any = [...projectUsers]

  if (inputName === 'selected') {
    data[index] = {
      ...data[index],
      [inputName as keyof any]: value ?? '',
      role: '',
    }
    clearInputError(`selected`, inputError, setInputError)
  } else {
    clearInputError(`${inputName}${index}`, inputError, setInputError)
    data[index] = {
      ...data[index],
      [inputName as keyof any]: value ?? '',
    }
  }
  setFormField((formField: any) => ({
    ...formField,
    projectUsers: [...data],
  }))
}

export const handleAddUsersFunctionallty = (
  projectId,
  inputField,
  closeAddUser: Function,
  setAPIStatus: Function,
  validateAddUser: Function,
  setInputError: Function,
  setShowModal: Function,
  setUsers: Function,
  handleNextStep: Function
) => {
  const filterUserData = inputField.projectUsers.filter((item: any) => item.selected === true)
  let inputData = {...inputField, projectUsers: filterUserData}
  let {count, error} = validateAddUser(inputField)
  // console.log(inputData)
  setInputError(error)
  if (count === 0) {
    setAPIStatus((prev: any) => ({...prev, loading: true}))

    addUserById(inputData).then((res: any) => {
      if (res.statusCode === 200) {
        // console.log(res.data)
        toast.success(res.message)
        // closeAddUser()
        getProjectsById(projectId).then((res: any) => {
          // console.log(res)
          if (res.statusCode === 200) {
            // console.log(res)
            setUsers(res.data[0] ?? {})

            setAPIStatus((prev: any) => ({...prev, loading: false}))
          } else {
            setAPIStatus((prev: any) => ({...prev, loading: false, error: res.message}))
          }
        })
        handleNextStep()
        setShowModal(false)
        setAPIStatus((prev: any) => ({...prev, loading: false}))
      } else {
        const errorMessage = res.message.split(',').join('\n')
        // console.log('Error message:', errorMessage)

        Message(errorMessage, 'error')

        setAPIStatus((prev: any) => ({
          ...prev,
          loading: false,
          error: res.message,
        }))
      }
    })
  }
}

export const validateAddUser = (inputField: {
  projectUsers: {
    user: string
    role: string
    allocationPercentage: string
    startDate: string
    endDate: string
    selected: boolean
    status: string
  }[]
}) => {
  let count = 0
  const error: any = {}
  let selectedUser = 0
  inputField.projectUsers.map((user, index) => {
    if (user.selected === true) selectedUser = selectedUser + 1
    if (!user.status && user.selected) {
      count = count + 1
      error[`status${index}`] = 'Please select a Status.'
    }
    if (!user.role && user.selected) {
      count = count + 1
      error[`role${index}`] = 'Please select a Role.'
    }
    // if (!user.allocationPercentage && user.selected) {
    //   count = count + 1
    //   error[`allocationPercentage${index}`] = 'Please select a Allocation Percentage.'
    // }
    if (!user.startDate && user.selected) {
      count = count + 1
      error[`startDate${index}`] = 'Please select a Start Date.'
    } else if (user.startDate && user.selected) {
      let newDate = user?.startDate.split('-')
      if (newDate[0].length > 4) {
        count = count + 1
        error[`startDate${index}`] = 'Please select/enter valid Year.'
      }
    }
    if (!user.endDate && user.selected) {
      count = count + 1
      error[`endDate${index}`] = 'Please select a End Date.'
    } else if (user.endDate && user.selected) {
      let newDate = user?.endDate.split('-')
      if (newDate[0].length > 4) {
        count = count + 1
        error[`endDate${index}`] = 'Please select/enter valid Year.'
      }
    }
  })
  if (selectedUser === 0) {
    count = count + 1
    error.selected = 'Please select at least one user.'
  }
  return {error, count}
}

export const getAllocatedUsersData = (
  userId,
  setAllocateUserData: Function,
  setAPIStatus: Function
) => {
  setAPIStatus((prev: any) => ({...prev, loading: true}))

  getUsersAllocationsData(userId).then((res: any) => {
    if (res.statusCode === 200) {
      setAllocateUserData(res.data ?? {})

      setAPIStatus((prev: any) => ({...prev, loading: false}))
    } else {
      Message(res.message, 'error')
      setAPIStatus((prev: any) => ({...prev, loading: false, error: res.message}))
    }
  })
}
