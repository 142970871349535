import {setupAxios} from '../app/modules/auth'
import {API_BASE_URL} from '../utils/contants'

export async function addTenant(InputField: {
  firstName: string
  lastName: string
  email: string
  companyName: string
  address: string
  country: string
  state: string
  city: string
  phoneNo: string
  pincode: string
  teamSize: string
}) {
  const {token} = setupAxios()

  const res = await fetch(API_BASE_URL + `/tenants`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
      'X-Tenant-Id': 'tenants',
    },
    body: JSON.stringify({
      firstName: InputField.firstName,
      lastName: InputField.lastName,
      email: InputField.email,
      companyName: InputField.companyName,
      address: InputField.address,
      country: InputField.country,
      state: InputField.state,
      city: InputField.city,
      phoneNo: InputField.phoneNo,
      pincode: InputField.pincode,
      teamSize: InputField.teamSize,
    }),
  })
  return await res.json()
}
export const verifyTenantEmailById = async (email: string, k: string) => {
  const {token} = setupAxios()
  const res = await fetch(API_BASE_URL + `/tenants/database`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
      'X-Tenant-Id': 'tenants',
    },
    body: JSON.stringify({
      email,
      k,
    }),
  })
  return await res.json()
}
