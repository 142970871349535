import {Center} from '@mantine/core'
import {useEffect, useMemo, useState} from 'react'
import {changeTextCapital} from '../../../_metronic/helpers/AssetHelpers'
import {EDIT_ROW_COLORS} from '../../../utils/contants'
import InputErrorBox from '../../components/InputErrorBox'
import {getUserAsset, handleInputChange} from './AssignAssetsFunction'
import {ToastContainer} from 'react-toastify'

const Assets = ({userAsset, setUserAsset, fetchAssets, isModalOpen}) => {
  const [editId, setEditId] = useState(null)
  const [inputError, setInputError] = useState<any>({})
  const [APIStatus, setAPIStatus] = useState({
    loading: false,
    error: null,
    submitLoading: false,
  })
  const [SubmitAPIStatus, setSubmitAPIStatus] = useState({
    loading: false,
  })

  const cancleEdit = useMemo(() => {
    if (isModalOpen) {
      setEditId(null)
    }
  }, [isModalOpen])
  const initialState = {
    category: '',
    user: '',
    brand: '',
    serialNo: '',
    issueDate: '',
    returnDate: '',
    description: '',
  }
  const [inputField, setInputField] = useState(initialState)

  const fetchUserAssets = () => {
    getUserAsset(setUserAsset, setAPIStatus)
  }
  useEffect(() => {
    let mounted = true
    if (mounted) {
      fetchUserAssets()
      fetchAssets()
    }
    return () => {
      mounted = false
    }
  }, [])

  return (
    <>
      <ToastContainer position={'bottom-center'} />

      <div className='row g-5 g-xl-8'>
        <div className='col-xl-12'>
          {/* begin:: card */}
          <div className='card card-xl-stretch mb-xl-8'>
            {/* begin:: card header */}
            <div className='card-header'>
              <div className='card-title'>Assignee Details</div>
            </div>
            {/* begin:: card body */}
            <div className='card-body'>
              {APIStatus.submitLoading ? (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              ) : APIStatus.error != null ? (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>{APIStatus.error}</div>
                </div>
              ) : (
                <div className='table-responsive  '>
                  <table
                    className='table 
                    table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-bordered'
                  >
                    <thead>
                      <tr className='fw-bold'>
                        <th className='pl-1 text-center text-muted' style={{minWidth: '80px'}}>
                          {changeTextCapital('Sr.No')}
                        </th>
                        <th className='pl-1 text-center text-muted' style={{minWidth: '130px'}}>
                          {changeTextCapital('User Name')}
                        </th>

                        <th className='pl-1 text-center text-muted' style={{minWidth: '90px'}}>
                          {changeTextCapital('Asset Tag')}
                        </th>
                        <th className='pl-1 text-center text-muted' style={{minWidth: '150px'}}>
                          {changeTextCapital('Serial No')}
                        </th>
                        <th
                          className='pl-1 text-center text-muted'
                          style={{width: '150px', whiteSpace: 'pre-wrap'}}
                        >
                          {changeTextCapital('Discription')}
                        </th>
                        <th className='pl-1 text-center text-muted' style={{minWidth: '50px'}}>
                          {changeTextCapital('Location')}
                        </th>
                        <th className='pl-1 text-center text-muted' style={{minWidth: '100px'}}>
                          {changeTextCapital('Issue Date')}
                        </th>
                        <th className='pl-1 text-center text-muted' style={{minWidth: '100px'}}>
                          {changeTextCapital('Return Date')}
                        </th>

                        {/* <th className='pl-1 text-center text-muted' style={{minWidth: '100px'}}>
                          {changeTextCapital('Action')}
                        </th> */}
                      </tr>
                    </thead>
                    {userAsset?.length > 0 ? (
                      <tbody>
                        {userAsset &&
                          userAsset.map((obj: any, index: number) => (
                            <tr
                              className={`${
                                editId === obj?.id ?? '-' ? `${EDIT_ROW_COLORS} ` : ''
                              }`}
                              key={obj?.id ?? '-'}
                            >
                              <td className='text-center'>{index + 1}</td>
                              <td className='text-center'>
                                {obj?.user?.firstName ?? '-'} {obj?.user?.lastName ?? '-'}
                              </td>

                              <td className='text-center'>{obj?.asset?.tag ?? '-'}</td>
                              <td
                                className='text-center'
                                style={{
                                  maxWidth: '200px',
                                  whiteSpace: 'pre-wrap',
                                  wordWrap: 'break-word',
                                }}
                              >
                                {obj?.asset?.serialNo ?? '-'}
                              </td>
                              <td
                                className='text-center'
                                style={{
                                  maxWidth: '400px',
                                  whiteSpace: 'pre-wrap',
                                  wordWrap: 'break-word',
                                }}
                              >
                                {obj?.asset?.description ?? '-'}
                              </td>
                              <td className='text-center'>
                                {`${
                                  obj?.locationType?.charAt(0).toUpperCase() +
                                    obj?.locationType?.slice(1) ?? '-'
                                } - ${obj?.location ?? '-'}`}
                              </td>
                              <td className='text-center'>{obj?.issueDate ?? '-'}</td>
                              <td className='text-center'>
                                {editId === obj?.id ? (
                                  <div>
                                    <input
                                      className='form-control'
                                      type='date'
                                      name='returnDate'
                                      value={inputField.returnDate}
                                      onChange={(event) =>
                                        handleInputChange(event, setInputField, inputField)
                                      }
                                    />
                                    {<InputErrorBox Message={inputError.returnDate} />}
                                  </div>
                                ) : (
                                  <div>{obj?.returnDate ?? '-'}</div>
                                )}
                              </td>

                              {/* {obj?.returnDate == null ? (
                                <td>
                                  <div className='d-flex justify-content-center flex-shrink-0'>
                                    {editId === obj?.id ? (
                                      <>
                                        <button
                                          className='btn btn-icon btn-success btn-sm me-1'
                                          onClick={() => {
                                            if (!SubmitAPIStatus.loading) {
                                              handleSubmit(
                                                inputField,
                                                setUserAsset,
                                                setEditId,
                                                initialState,
                                                setInputField,
                                                editId,
                                                cancelSubmit,
                                                setAPIStatus,
                                                setInputError,
                                                validateForm,
                                                setSubmitAPIStatus,
                                                fetchAssets
                                              )
                                            }
                                          }}
                                          disabled={SubmitAPIStatus.loading} // Disable the button when loading
                                        >
                                          <i className='far fa-save'></i>
                                        </button>

                                        <button
                                          className='btn btn-icon btn-light-danger btn-sm'
                                          onClick={() => {
                                            cancelSubmit(
                                              setInputField,
                                              initialState,
                                              setEditId,
                                              setInputError
                                            )
                                            setEditId(null)
                                          }}
                                        >
                                          <i className='far fa-window-close'></i>
                                        </button>
                                      </>
                                    ) : (
                                      <button
                                        className='btn btn-icon btn-light-primary btn-sm me-1'
                                        onClick={() => {
                                          getUserAssetById(
                                            obj?.id,
                                            inputField,
                                            setInputField,
                                            setEditId,
                                            setInputError
                                          )
                                          setEditId(obj?.id)
                                        }}
                                      >
                                        <i className='far fa-edit'></i>
                                      </button>
                                    )}
                                  </div>
                                </td>
                              ) : (
                                <td>
                                  {' '}
                                  <div className='text-center'>-</div>
                                </td>
                              )} */}
                            </tr>
                          ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td colSpan={9}>
                            <Center>No Record Available.</Center>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Assets
