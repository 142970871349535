import clsx from 'clsx'
import {useFormik} from 'formik'
import {useEffect, useState} from 'react'
import {Col, Row} from 'react-bootstrap'
import {Link, useNavigate} from 'react-router-dom'
import * as Yup from 'yup'
import {API_BASE_URL} from '../../../../utils/contants'
import {Message, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {toast} from 'react-toastify'
import {Button} from '@mantine/core'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons'
import { ThemeModeComponent } from '../../../../_metronic/assets/ts/layout'
import { useThemeMode } from '../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Please enter registered email.'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [message, setMessage] = useState('')

  const [APIStatus, setAPIStatus] = useState({loading: false, error: null, submitLoading: false})
  const [SubmitAPIStatus, setSubmitAPIStatus] = useState({
    loading: false,
  })

  const [resetSuccess, setResetSuccess] = useState(false)
  const {mode}=useThemeMode()


  const navigate = useNavigate()
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      setHasErrors(false)
      setMessage('')

      try {
        setAPIStatus((prev: any) => ({...prev, loading: true}))
        setSubmitAPIStatus((prev: any) => ({...prev, loading: true}))

        const response = await requestPassword({email: values.email})
      } catch (error) {
        setHasErrors(true)
        setMessage('Error sending email to reset password')
        setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
      }

      setLoading(false)
      setSubmitting(false)
      setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
    },
  })

  // forgot passord api

  const requestPassword = async ({email}) => {
    // This function should make a POST request to your API
    const response = await fetch(API_BASE_URL + `/forgot-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Tenant-Id': 'tenants',
      },
      body: JSON.stringify({email}),
    })
    const responseBody = await response.json()

    if (response.status === 201) {
      toast.success('Password reset email sent successfully.')
      setResetSuccess(true)
    } else {
      if (response.status === 406) {
        if (responseBody && responseBody.error) {
          Message(responseBody.error, 'error')
        } else {
          Message(responseBody.message, 'error')
        }
      } else {
        Message('Error occurred while processing your request.', 'error')
      }
    }
  }

  const [isDarkMode, setIsDarkMode] = useState(false);
  useEffect(() => {
    const prefersDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    setIsDarkMode(prefersDarkMode);
  }, []);

  const getLogoSrc = () => {
    return isDarkMode ? '/media/logos/whitesialogo.png' : '/media/logos/sialogo.png';
  };

  return (
    <>
      <div
        className='d-flex justify-content-center align-items-center h-100'
        style={{overflowX: 'hidden'}}
      >
        <div
          className='d-flex justify-content-center align-items-center h-100'
          style={{
            overflowX: 'hidden',
            backgroundImage: `url('/path/to/your/image.jpg')`, // Replace with your image path
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <div className='row g-5 g-xl-8'>
            <div className='col-xl-12'>
              {resetSuccess ? (
                <>
                  <div className='text-center'>
                    <img
                      src='/media/avatars/17-dark.png'
                      alt='noReacordAvailable'
                      className='w-70 h-100px h-sm-325px'
                    />
                  </div>
                  <div className='fw-bold text-gray-600 text-center mt-3 mb-2'>
                    Password reset email sent successfully. Have a great day!{' '}
                  </div>
                  <div className='text-center'>
                    <Button
                      variant='subtle'
                      color=''
                      onClick={() => {
                        navigate('/login')
                      }}
                    >
                      <span>
                        <u>
                          {' '}
                          <FontAwesomeIcon icon={faChevronLeft} size='sm' className='me-1 mt-1' />
                          Back To Sign In{' '}
                        </u>
                      </span>
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <div className='card shadow ms-5 me-5'>
                    <div
                      className='card-header d-flex justify-content-center'
                      style={{borderBottom: 'none'}}
                    >
                      <div className='text-center mb-6'>
      <img
        alt='Skeletos'
        style={{ width: '170px', height: '120px', backgroundColor: 'transparent' }}
        src={ localStorage.getItem('kt_theme_mode_menu')==='dark' || mode==='system' && ThemeModeComponent.getSystemMode()==='dark' ? '/media/logos/whitesialogo.png' : '/media/logos/sialogo.png'}
      ></img>

      <h1 className='text-dark fw-bold mb-3'>Sign in</h1>
    </div>
                    </div>
                    <div className='card-body'>
                      {hasErrors === false && message && (
                        <div className='mb-10 bg-light-info p-6 rounded' style={{width: '100%'}}>
                          <div className='text-info'>{message}</div>
                        </div>
                      )}
                      <form
                        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                        noValidate
                        id='kt_login_password_reset_form'
                        onSubmit={formik.handleSubmit}
                      >
                        {/* begin::Title */}
                        {hasErrors === true && (
                          <div
                            className='mb-5 bg-light-info p-6 rounded d-flex'
                            style={{width: '100%'}}
                          >
                            <div className='alert-text font-weight-bold' style={{width: '100px'}}>
                              {message}
                            </div>
                          </div>
                        )}
                        {/* end::Title */}
                        {/* begin::Form group */}
                        <div className='fv-row position-relative mb-5 d-flex justify-content-center align-items-center'>
                          <Row className='mb-4'>
                            <Col md={3}>
                              <label
                                className='form-label fw-bolder text-gray-900 fs-6'
                                style={{paddingRight: '20px'}}
                              >
                                Email
                              </label>
                            </Col>
                            <Col md={12}>
                              <input
                                type='email'
                                placeholder='Enter Your Email'
                                autoComplete='off'
                                {...formik.getFieldProps('email')}
                                className={clsx(
                                  'form-control bg-transparent',
                                  {'is-invalid': formik.touched.email && formik.errors.email},
                                  {
                                    'is-valid': formik.touched.email && !formik.errors.email,
                                  }
                                )}
                              />
                            </Col>
                            {formik.touched.email && formik.errors.email && (
                              <div
                                className='fv-plugins-message-container'
                                style={{maxWidth: '300px'}}
                              >
                                <div className='fv-help-block'>
                                  <span role='alert'>{formik.errors.email}</span>
                                </div>
                              </div>
                            )}
                          </Row>
                        </div>
                        {/* end::Form group */}
                        {/* begin::Form group */}
                        <div className='mb-5 d-flex justify-content-center align-items-center'>
                          <Row>
                            <Col className=''>
                              <Button
                                type='submit'
                                radius='md'
                                size='md'
                                id='kt_password_reset_submit'
                                className=''
                                loading={SubmitAPIStatus.loading}
                                style={{width: '300px'}}
                              >
                                <span className='indicator-label fw-bold fs-5'>Submit</span>
                              </Button>
                            </Col>
                          </Row>
                        </div>
                        <div className='d-flex justify-content-center'>
                          <Link to='/login'>
                            <Button
                              color='gray'
                              id='kt_login_password_reset_form_cancel_button'
                              className='btn pt-2 pb-2'
                            >
                              <FontAwesomeIcon icon={faChevronLeft} size='sm' />
                              <span className='ms-2 fs-7'>Back To Login </span>
                            </Button>
                          </Link>{' '}
                        </div>
                        {/* end::Form group */}
                      </form>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
