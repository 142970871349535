import {createRoot} from 'react-dom/client'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'
import './_metronic/assets/sass/style.scss'
import {AuthProvider, setupAxios} from './app/modules/auth'
import {AppRoutes} from './app/routing/AppRoutes'
import RoleState from './app/context/RoleState'
setupAxios()

const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <AuthProvider>
      <RoleState>
      <AppRoutes />
      </RoleState>
    </AuthProvider>
    // {/* <ReactQueryDevtools initialIsOpen={false} /> */}
  )
}
