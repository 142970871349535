import {toast} from 'react-toastify'
import {
  addLeave,
  addManualLeave,
  getFilterLeaves,
  getLeaveBalancesById,
  getLeaveEmails,
  getLeavesBalance,
  getPieChartDatas,
  getallUsersLeaves,
  handleLeaveApproves,
  handleLeaveRejects,
} from '../../../api/LeaveAPI'
import {Message} from '../../../_metronic/helpers'
import {UserModel, setupAxios} from '../../modules/auth'
import Swal from 'sweetalert2'
import {AlertProps} from '../../data/AlertDefaults'
import {API_BASE_URL} from '../../../utils/contants'
import striptags from 'striptags'

export const handleInputChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  setInputField: Function,
  inputField: {
    sender: string
    recipients: {
      to: string[]
      cc: string[]
    }
    leaveType: string
    reason: string
    leaveDate: {
      startDate: string
      endDate: string
      type: string
    }[]
  },
  fieldName: string
) => {
  const target = event.target

  if (target.type === 'radio' && target.checked) {
    setInputField((prevInputField) => ({
      ...prevInputField,
      leaveDate: [
        {
          ...prevInputField.leaveDate[0],
          type: target.value,
        },
      ],
    }))
  } else {
    setInputField((prevInputField) => ({
      ...prevInputField,
      [fieldName]: target.type === 'checkbox' ? target.checked : target.value,
    }))
  }
}

export const getLeave = (
  currentUser: UserModel | undefined,
  setLeave: Function,
  setAPIStatus: Function,
  action
) => {
  setAPIStatus((prev: any) => ({...prev, loading: true}))
  getLeaveEmails(currentUser?.id, action).then((res: any) => {
    if (res.statusCode === 200) {
      console.log('res', res)
      setLeave(res.data)
      console.log(res)
      setAPIStatus((prev: any) => ({...prev, loading: false}))
    } else {
      setAPIStatus((prev: any) => ({...prev, loading: false, error: res.message}))
    }
  })
}
export const getFilterLeave = (
  currentUser: UserModel | undefined,
  setLeave: Function,
  setAPIStatus: Function,
  action
) => {
  setAPIStatus((prev: any) => ({...prev, loading: true}))
  getFilterLeaves(currentUser?.id, action).then((res: any) => {
    if (res.statusCode === 200) {
      console.log('res', res)
      setLeave(res.data)
      console.log(res)
      setAPIStatus((prev: any) => ({...prev, loading: false}))
    } else {
      setAPIStatus((prev: any) => ({...prev, loading: false, error: res.message}))
    }
  })
}
export const getLeaveBalances = (
  currentUser: UserModel | undefined,
  setBalance: Function,
  setAPIStatus: Function
) => {
  setAPIStatus((prev: any) => ({...prev, loading: true}))
  getLeavesBalance(currentUser?.id).then((res: any) => {
    if (res.statusCode === 200) {
      console.log(res)
      setBalance(res.data)
      setAPIStatus((prev: any) => ({...prev, loading: false}))
    } else {
      setAPIStatus((prev: any) => ({...prev, loading: false, error: res.message}))
    }
  })
}
export const getPieChartData = (
  currentUser: UserModel | undefined,
  setPieChartData: Function,
  setAPIStatus: Function
) => {
  setAPIStatus((prev: any) => ({...prev, loading: true}))
  getPieChartDatas(currentUser?.id).then((res: any) => {
    if (res.statusCode === 200) {
      console.log(res)
      setPieChartData(res.data)
      setAPIStatus((prev: any) => ({...prev, loading: false}))
    } else {
      setAPIStatus((prev: any) => ({...prev, loading: false, error: res.message}))
    }
  })
}
export const handleInputChanges = (
  event: React.SyntheticEvent,
  section: string,
  setInputField: Function,
  inputField: any
) => {
  let target = event.target as HTMLInputElement

  setInputField({
    ...inputField,
    [section]: {
      ...inputField[section],
      [target.name]: target.type === 'checkbox' ? target.checked : target.value,
    },
  })
}

export const handleSubmit = (
  currentUser,
  inputField: {
    sender: string
    recipients: {
      to: string[]
      cc: string[]
    }
    leaveType: string
    reason: string
    leaveDate: {
      startDate: string
      endDate: string
      type: string
    }[]
  },
  initialState: {
    sender: string
    recipients: {
      to: string[]
      cc: string[]
    }
    leaveType: string
    reason: string
    leaveDate: {
      startDate: string
      endDate: string
      type: string
    }[]
  },
  setLeave: Function,
  setInputField: Function,
  editId: string | null,
  setAPIStatus: Function,
  setInputError: Function,
  setSubmitAPIStatus: Function,
  validateForm: Function,
  setMyLeaveModalOpen: Function,
  fetchData: Function
) => {
  let {cnt, errors} = validateForm(inputField)
  setInputError(errors)
  if (cnt === 0) {
    setSubmitAPIStatus((prev: any) => ({...prev, loading: true}))
    addLeave(inputField).then((res) => {
      if (res.statusCode === 200) {
        setMyLeaveModalOpen(false)
        toast.success(res.message)
        fetchData()
        setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
        setInputField(initialState)
      } else if (res.statusCode === 406) {
        Message(res.message, 'error')

        setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
      } else {
        Message(res.message, 'error')
        setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
      }
    })
  }
}
export const validateForm = (inputField: {
  recipients: {
    to: string[]
    cc: string[]
  }
  leaveType: string
  reason: string
  leaveDate: {
    startDate: string
    endDate: string
    type: string
  }[]
}) => {
  let cnt = 0
  const errors: any = {}
  const reasonWithoutTags = striptags(inputField?.reason).trim()

  if (
    !inputField.recipients ||
    !inputField.recipients.to ||
    inputField.recipients.to.length === 0
  ) {
    cnt = cnt + 1
    errors.to = 'Please select at least one recipient.'
  }

  if (!inputField.leaveType) {
    cnt = cnt + 1
    errors.leaveType = 'Please select a Subject.'
  }

  inputField.leaveDate.forEach((dateSet, index) => {
    if (!dateSet.startDate) {
      cnt = cnt + 1
      errors[`startDate${index}`] = 'Please select/enter Start Date.'
    }

    if (!dateSet.endDate) {
      cnt = cnt + 1
      errors[`endDate${index}`] = 'Please select/enter End Date.'
    } else if (new Date(dateSet.startDate) > new Date(dateSet.endDate)) {
      cnt = cnt + 1
      errors[`endDate${index}`] = 'End Date must not be earlier than the Start Date.'
    }

    if (!dateSet.type) {
      cnt = cnt + 1
      errors[`type${index}`] = 'Please select a Type.'
    }

    let nextIndex = index + 1
    while (nextIndex < inputField.leaveDate.length) {
      const nextDateSet = inputField.leaveDate[nextIndex]
      if (
        dateSet.startDate === nextDateSet.startDate ||
        dateSet.startDate === nextDateSet.endDate ||
        dateSet.endDate === nextDateSet.startDate ||
        dateSet.endDate === nextDateSet.endDate
      ) {
        cnt = cnt + 1
        errors[`startDate${nextIndex}`] =
          'Leaves for the selected date have already been requested. Kindly choose an alternate date accordingly.'
      }
      nextIndex++
    }
  })

  if (inputField.leaveDate.length > 1) {
    const firstDateSet = inputField.leaveDate[0]
    const lastDateSet = inputField.leaveDate[inputField.leaveDate.length - 1]

    for (let i = 1; i < inputField.leaveDate.length - 1; i++) {
      const currentDateSet = inputField.leaveDate[i]

      if (
        (new Date(currentDateSet.startDate) >= new Date(firstDateSet.startDate) &&
          new Date(currentDateSet.startDate) <= new Date(firstDateSet.endDate)) ||
        (new Date(currentDateSet.endDate) >= new Date(firstDateSet.startDate) &&
          new Date(currentDateSet.endDate) <= new Date(firstDateSet.endDate)) ||
        (new Date(currentDateSet.startDate) <= new Date(firstDateSet.startDate) &&
          new Date(currentDateSet.endDate) >= new Date(firstDateSet.endDate))
      ) {
        cnt = cnt + 1
        errors[`startDate${i}`] =
          'Leaves for the selected date have already been requested. Kindly choose an alternate date accordingly.'
      }
    }

    if (
      (new Date(lastDateSet.startDate) >= new Date(firstDateSet.startDate) &&
        new Date(lastDateSet.startDate) <= new Date(firstDateSet.endDate)) ||
      (new Date(lastDateSet.endDate) >= new Date(firstDateSet.startDate) &&
        new Date(lastDateSet.endDate) <= new Date(firstDateSet.endDate)) ||
      (new Date(lastDateSet.startDate) <= new Date(firstDateSet.startDate) &&
        new Date(lastDateSet.endDate) >= new Date(firstDateSet.endDate))
    ) {
      cnt = cnt + 1
      errors[`startDate${inputField.leaveDate.length - 1}`] =
        'Leaves for the selected date have already been requested. Kindly choose an alternate date accordingly.'
    }
  }

  if (!inputField.reason.trim()) {
    cnt = cnt + 1
    errors.reason = 'Please enter a Reason.'
  } else if (!reasonWithoutTags || reasonWithoutTags.trim().length > 300) {
    cnt = cnt + 1
    errors.reason = 'Reason must not exceed 300 characters.'
    console.log('reason', inputField.reason.length)
  }

  return {errors, cnt}
}

export const formatDates = (dateString) => {
  if (!dateString) return '-'

  const dateObject = new Date(dateString)

  if (isNaN(dateObject.getTime())) {
    console.error('Invalid date string:', dateString)
    return '-'
  }

  return dateObject.toISOString().split('T')[0]
}

export const handleCancelButtonClick = async (
  leaveId,
  currentUser,
  setLeave,
  setAPIStatus,
  action,
  fetchData
) => {
  Swal.fire({
    ...AlertProps,
    icon: 'warning',
    title: `Are you sure you want to cancel?`,
  }).then(async (result: any) => {
    if (result.isConfirmed) {
      const {token, tenantId} = setupAxios()
      const res = await fetch(API_BASE_URL + `/leave/${leaveId}/status`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'X-tenant-Id': `${tenantId}`,
        },
        body: JSON.stringify({
          status: 'cancel',
          approvedBy: {
            id: currentUser.id,
          },
        }),
      })

      if (!res.ok) {
        throw new Error(`Request failed with status: ${res.status}`)
      }

      let response = await res.json()

      if (response.statusCode === 200) {
        getLeave(currentUser, setLeave, setAPIStatus, action)
        fetchData()
        toast.success(response.message)
      } else {
        Message(response.message, 'error')
      }
    }
  })
}

export const handleLeaveApprove = async (
  leaveId,
  currentUser,
  setLeave,
  setAPIStatus,
  action,
  fetchData
) => {
  Swal.fire({
    ...AlertProps,
    icon: 'warning',
    title: `Are you sure you want to approved?`,
  }).then(async (result: any) => {
    if (result.isConfirmed) {
      handleLeaveApproves(leaveId, currentUser).then((res: any) => {
        if (res.statusCode === 200) {
          getLeave(currentUser, setLeave, setAPIStatus, action)
          fetchData()

          toast.success(res.message)
        } else {
          Message(res.message, 'error')
        }
      })
    }
  })
}
export const handleLeaveReject = async (
  leaveId,
  currentUser,
  setLeave,
  setAPIStatus,
  action,
  fetchData
) => {
  Swal.fire({
    ...AlertProps,
    icon: 'warning',
    title: `Are you sure you want to declined?`,
  }).then(async (result: any) => {
    if (result.isConfirmed) {
      handleLeaveRejects(leaveId, currentUser).then((res: any) => {
        if (res.statusCode === 200) {
          getLeave(currentUser, setLeave, setAPIStatus, action)
          fetchData()

          toast.success(res.message)
        } else {
          Message(res.message, 'error')
        }
      })
    }
  })
}

export const formatCreateAt = (dateString) => {
  if (!dateString) return '-'

  const options: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    day: 'numeric',
    month: 'short',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }

  const formattedDate = new Date(dateString).toLocaleString('en-US', options)
  return formattedDate
}
export function formatDateTime(dateString) {
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }
  const formattedDate = new Date(dateString).toLocaleDateString('en-US', options)
  return formattedDate
}

export const getallUsersLeave = (setUserBalance: Function, setAPIStatus: Function) => {
  setAPIStatus((prev: any) => ({...prev, loading: true}))
  getallUsersLeaves().then((res: any) => {
    if (res.statusCode === 200) {
      setUserBalance(res.data)
      setAPIStatus((prev: any) => ({...prev, loading: false}))
    } else {
      setAPIStatus((prev: any) => ({...prev, loading: false, error: res.message}))
    }
  })
}
export const getLeaveBalanceById = (id: string, setEditId: Function) => {
  getLeaveBalancesById(id).then((res: any) => {
    if (res) {
      setEditId(id)
      window.scrollTo(0, 0)
    } else {
      Message(res.message, 'error')
    }
  })
}
export const cancelSubmit = (
  setInputField: Function,
  InputField: {
    leaveType: string
    leaveDate: {
      startDate: string
      endDate: string
      type: string
      approvedBy: {
        id: string
      }
    }
    sender: {
      id: string
    }
  },
  setEditId: Function,
  setInputError: Function
) => {
  setEditId(null)
  setInputField(InputField)
  setInputError({})
}

export const addManualLeaves = (
  inputField: {
    leaveType: string
    leaveDate: {
      startDate: string
      endDate: string
      type: string
      approvedBy: {
        id: string
      }
    }
    sender: {
      id: string
    }
  },
  currentUser,
  setUserBalance: Function,
  setEditId: Function,
  initialState: {
    leaveType: string
    leaveDate: {
      startDate: string
      endDate: string
      type: string
      approvedBy: {
        id: string
      }
    }
    sender: {
      id: string
    }
  },
  setInputField: Function,
  editId: string | null,
  setAPIStatus: Function,
  setInputError: Function,
  validateFormFields: Function,
  setReasonModalOpen: Function,
  fetchData: Function
) => {
  let {cnt, errors} = validateFormFields(inputField)
  setInputError(errors)

  if (cnt === 0) {
    addManualLeave(inputField, editId, currentUser).then((res) => {
      if (res.statusCode === 200) {
        getallUsersLeave(setUserBalance, setAPIStatus)
        setReasonModalOpen(false)
        setEditId(null)
        fetchData()
        setInputField(initialState)
        setInputError({})
        toast.success(res.message)
      } else {
        Message(res.message, 'error')
      }
    })
  }
}

export const validateFormFields = (inputField: {
  leaveType: string
  leaveDate: {
    startDate: string
    endDate: string
    type: string
    approvedBy: {
      id: string
    }
    sender: {
      id: string
    }
  }
}) => {
  let cnt = 0
  const errors: any = {}

  if (!inputField.leaveType) {
    cnt = cnt + 1
    errors.leaveType = 'Please select a Subject.'
  }

  if (!inputField.leaveDate.startDate) {
    cnt = cnt + 1
    errors.startDate = 'Please select/enter a Start Date.'
  }

  if (!inputField.leaveDate.endDate) {
    cnt = cnt + 1
    errors.endDate = 'Please select/enter an End Date.'
  } else if (new Date(inputField.leaveDate.endDate) < new Date(inputField.leaveDate.startDate)) {
    cnt = cnt + 1
    errors.endDate = 'End Date must not be earlier than the Start Date.'
  }

  if (!inputField.leaveDate.type) {
    cnt = cnt + 1
    errors.type = 'Please select a Type.'
  }

  return {errors, cnt}
}
