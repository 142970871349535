import {Center, Loader} from '@mantine/core'
import {useContext, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {
  KTCard,
  KTCardBody,
  changeTextCamal,
  checkRole,
  toAbsoluteUrl,
} from '../../../_metronic/helpers'
import roleContext from '../../context/roleContext'
import {RoleContextValue} from '../../types/OtherTypes'
import CreateUser from './CreateUser'
import {getUser} from './UserFunction'

const User = () => {
  const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false)
  let roleState: RoleContextValue = useContext(roleContext)
  const [user, setUser] = useState([
    {
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      contact: '',
      password: '',
      dob: '',
      gender: '',
      employmentType: '',
      designation: '',
      startDate: '',
      endDate: '',
      role: {
        name: '',
      },
      inTime: '',
      outTime: '',
      reportingManager: {
        firstName: '',
        lastName: '',
      },
      hrManager: {
        firstName: '',
        lastName:''
      },
     
      personalEmail: '',
    },
  ])
  const [APIStatus, setAPIStatus] = useState({
    loading: false,
    error: null,
  })

  const fetchUsers = () => {
    getUser(setUser, setAPIStatus)
  }
  useEffect(() => {
    let mounted = true
    if (mounted && APIStatus.loading === false) {
      setAPIStatus({...APIStatus, loading: true})
      fetchUsers()
      setIsCreateUserModalOpen(false)
    }
    return () => {
      mounted = false
    }
  }, [])

  return (
    <>
      <KTCard>
        <div className='card-header' hidden={!checkRole(['Users Registration'], roleState.features, 'edit')}>
          <div className='card-title'>Users</div>
          <div className='card-toolbar'>
            <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
              <button
                type='button'
                className='btn btn-primary btn-sm'
                onClick={() => {
                  setIsCreateUserModalOpen(true)
                }}
              >
                Add User
              </button>
            </div>
          </div>
        </div>
        <KTCardBody className='py-4'>
          <div className='table-responsive'>
            {APIStatus.loading ? (
              <Center>
                {/* Render your loading spinner or indicator here */}
                <Loader />
              </Center>
            ) : (
              <table
                id='kt_table_users'
                className='table align-middle table-row-dashed fs-6 dataTable'
              >
                <thead className=' text-muted fw-bolder fs-7 text-uppercase gs-0 text-start'>
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Contact</th>
                    <th>Designation</th>
                    <th>Employment Type</th>
                    <th>Role</th>
                    <th>Reporting Manager</th>
                    <th>Human Resource</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                {user?.length > 0 ? (
                  <tbody className='text-gray-800 fw-semibold text-left'>
                    {user?.map((obj) => {
                      return (
                        <tr key={obj?.id}>
                          <td className='symbol symbol-45px'>
                            <img
                              src={toAbsoluteUrl('/media/avatars/blank.png')}
                              alt='user profile'
                            />
                          </td>
                          <td style={{minWidth: '70px', paddingLeft: '10px'}}>
                            {changeTextCamal(obj?.firstName)} {changeTextCamal(obj?.lastName)}{' '}
                            <br />
                            <a
                              className='fs-7'
                              href={`mailto:${obj?.email}`}
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              {obj?.email}
                            </a>
                          </td>

                          <td>{`${obj?.contact ? '+91' + obj.contact : '-'}`}</td>

                          <td>{changeTextCamal(obj?.designation)}</td>
                          <td>{changeTextCamal(obj?.employmentType)}</td>
                          <td>{obj?.role?.name}</td>
                          <td style={{minWidth: '70px', paddingLeft: '10px'}}>
                            {obj?.reportingManager !== null ? (
                              <span>
                                {changeTextCamal(obj?.reportingManager?.firstName)}{' '}
                                {changeTextCamal(obj?.reportingManager?.lastName)}
                              </span>
                            ) : (
                              '-'
                            )}
                          </td>
                          <td> {changeTextCamal(obj?.hrManager?.firstName)}{' '}
                                {changeTextCamal(obj?.hrManager?.lastName)}</td>

                          {/* Actions */}
                          <td>
                            <div className='d-flex justify-content-center flex-shrink-0'>
                              {/* link-button for update the user */}
                              <Link
                                to={`/profile/${obj?.id}`}
                                className='btn btn-icon btn-bg-light btn-active-color-info btn-sm me-1'
                              >
                                <i className='far fa-eye'></i>
                              </Link>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan={6}>
                        <Center>No Record Available.</Center>
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            )}
          </div>
        </KTCardBody>
      </KTCard>
      {isCreateUserModalOpen && (
        <CreateUser
          closeModal={() => setIsCreateUserModalOpen(false)}
          user={user}
          setUser={setUser}
          fetchUsers={fetchUsers}
        />
      )}
    </>
  )
}

export default User
