import {faEdit, faSearch} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Badge, Button, Center, Loader} from '@mantine/core'
import {useContext, useEffect, useState} from 'react'
import {Col, Modal, Pagination, Row} from 'react-bootstrap'
import {useParams} from 'react-router-dom'
import {boolean} from 'yup'
import {
  changeTextCapital,
  changeTextSentenceCase,
  checkRole,
  dateFormat,
} from '../../../../_metronic/helpers'
import {getUsers} from '../../../../api/Organization'
import {Active, On_Boarded, Released} from '../../../../utils/contants'
import InputErrorBox from '../../../components/InputErrorBox'
import Select2 from '../../../components/select2'
import roleContext from '../../../context/roleContext'
import {useAuth} from '../../../modules/auth'
import {RoleContextValue} from '../../../types/OtherTypes'
import {
  getAllocatedUsersData,
  getProjectUsers,
  getProjectsUsersById,
  handleAddUsers,
  handleAddUsersFunctionallty,
  handleInputChange,
  updateUserById,
  validateAddUser,
} from './UserFunction'

const Users = () => {
  let roleState: RoleContextValue = useContext(roleContext)
  const params = useParams()
  const {currentUser} = useAuth()
  const [showModal, setShowModal] = useState(false)
  const [editUser, setEditUser] = useState(false)
  const [search, setSearch] = useState('')

  const [showAllocatedUsersData, setShowAllocatedUsersData] = useState<number[]>([])

  const [inputError, setInputError] = useState<any>({})

  const [SubmitAPIStatus, setSubmitAPIStatus] = useState({
    loading: false,
  })

  const [APIStatus, setAPIStatus] = useState({loading: false, eror: null, submitLoading: false})

  const initialState = {
    assigner: currentUser?.id,
    projectUsers: [
      {
        user: '',
        role: '',
        status: 'On Boarded',
        allocationPercentage: 100,
        startDate: '',
        endDate: '',
        selected: false,
      },
    ],
    projectId: params?.id,
  }

  const [projectUser, setProjectUser] = useState({
    startDate: '',
    dueDate: '',
    projectManager: {
      id: '',
    },
    projectUsers: [
      {
        id: '',
        status: '',
        role: '',
        startDate: '',
        endDate: '',
        allocationPercentage: '',
        user: {id: '', firstName: '', lastName: '', isPresent: boolean},
        assigner: {id: '', firstName: '', lastName: ''},
      },
    ],
  })

  const [allocateUserData, setAllocateUserData] = useState([
    {
      id: '',
      startDate: '',
      endDate: '',
      role: '',
      allocationPercentage: '',
      user: {
        id: '',
        firstName: '',
      },
      project: {
        id: '',
        name: '',
      },
    },
  ])

  const handleNextStep = () => {}

  const [currentPage, setCurrentPage] = useState(1)
  const [userPerPage] = useState(5)
  const totalPages = Math.ceil(projectUser?.projectUsers?.length / userPerPage)
  const pageRange = 1
  const pagesToDisplay: number[] = []
  for (let i = currentPage - pageRange; i <= currentPage + pageRange; i++) {
    if (i >= 1 && i <= totalPages) {
      pagesToDisplay.push(i)
    }
  }

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const checkAllocatedUsersArrayElement = (element: number) => {
    let arr: number[] = [...showAllocatedUsersData]
    let isExists = false
    for (let i = 0; i <= arr.length - 1; i++) {
      if (arr[i] === element) {
        arr.splice(i, 1)
        isExists = true
        break
      }
    }

    if (!isExists) {
      arr.push(element)
    }
    setShowAllocatedUsersData(arr)
  }

  const [inputField, setInputField] = useState(initialState)
  const [users, setUsers] = useState([])
  const [userData, setUserData] = useState<any>([])

  const addUser = () => {
    setShowModal(true)
  }
  const closeAddUser = () => {
    setShowModal(false)
  }

  const editUserModal = () => {
    setEditUser(true)
  }

  const closeEditUserModal = () => {
    setEditUser(false)
  }

  const handleSearch = (value: string) => {
    setSearch(value)
    const filterUsersData = users?.filter((item: any) =>
      item.firstName.toLowerCase().includes(value.toLowerCase())
    )
    setUserData(filterUsersData)
    let projectUsers: any = []
    filterUsersData.map((obj: any) => {
      let user = projectUser.projectUsers.filter((item) => item.user.id === obj?.id)
      projectUsers.push({
        selected: false,
        user: obj?.id,
        startDate: projectUser?.startDate,
        endDate: projectUser?.dueDate,
        role: '',
        allocationPercentage: user.length > 0 ? 100 - Number(user[0]?.allocationPercentage) : 100,
        status: 'On Boarded',
      })
    })
    setInputField({...inputField, projectUsers: projectUsers})
  }

  const [usersById, setUsersById] = useState({
    id: '',
    status: '',
    startDate: '',
    endDate: '',
    allocationPercentage: '',
    role: '',
  })

  useEffect(() => {
    let mounted = true

    if (mounted) {
      getProjectUsers(params?.id, setProjectUser, setAPIStatus)
    }
    return () => {
      mounted = false
    }
  }, [])

  return (
    <>
      {/* {console.log('projectManager', projectUser?.projectManager?.id)}
      {console.log('currentUser', currentUser?.id)}
      {console.log('ProjectUser', projectUser)} */}

      <div className='card'>
        <div className='d-flex justify-content-between pb-7'>
          <h3 className='fw-bold ms-10 mt-5 me-5 my-1'>Users</h3>{' '}
          {/* {projectData[0]?.projectManager?.id === currentUser?.id && ( */}
          <Button
            hidden={
              !(
                checkRole(['Project Management'], roleState.features, 'edit') ||
                currentUser?.id === projectUser?.projectManager?.id
              )
            }
            variant='outline'
            className='me-3 mt-5'
            onClick={() => {
              addUser()
              getUsers().then((res) => {
                if (res.statusCode === 200) {
                  setUserData(res.data ?? [])
                  setUsers(res.data ?? [])

                  let projectUsers: any = []
                  res.data.map((obj) => {
                    let user = projectUser.projectUsers.filter((item) => item.user.id === obj?.id)

                    projectUsers.push({
                      selected: false,
                      user: obj?.id,
                      startDate: projectUser?.startDate,
                      endDate: projectUser?.dueDate,
                      role: '',
                      allocationPercentage:
                        user.length > 0 ? 100 - Number(user[0]?.allocationPercentage) : 100,
                      status: 'On Boarded',
                    })
                  })
                  setInputField({
                    ...inputField,
                    projectUsers: projectUsers,
                  })
                }
              })
            }}
          >
            Add User
          </Button>
          {/* )} */}
        </div>
        {APIStatus.loading ? (
          <Center>
            <Loader />
          </Center>
        ) : (
          <div className=''>
            <div className='card-body' style={{cursor: 'pointer'}}>
              <div className='table-responsive'>
                <table className='table table-hover table-row-dashed dataTabl'>
                  <thead>
                    <tr className='fw-bold'>
                      <th className=''>{changeTextCapital('User Name')}</th>
                      <th className=''>{changeTextCapital('Role')}</th>
                      <th className=''>{changeTextCapital('Start Date')}</th>
                      <th className=''>{changeTextCapital('End Date')}</th>
                      <th className=''>{changeTextCapital('Allocation (%)')}</th>
                      <th className=''>{changeTextCapital('Status')}</th>
                      <th className=''>{changeTextCapital('Assign By')}</th>
                      <th
                        className=''
                        hidden={
                          !(
                            checkRole(['Project Management'], roleState.features, 'edit') ||
                            currentUser?.id === projectUser?.projectManager?.id
                          )
                        }
                      >
                        {changeTextCapital('Action')}
                      </th>
                    </tr>
                  </thead>
                  {projectUser.projectUsers?.length > 0 ? (
                    <tbody className='justify-content-center'>
                      {projectUser?.projectUsers
                        ?.filter((obj) => obj.status !== 'Released')
                        ?.concat(
                          projectUser?.projectUsers?.filter((obj) => obj.status === 'Released')
                        )
                        ?.slice((currentPage - 1) * userPerPage, currentPage * userPerPage)
                        ?.map((obj: any) => {
                          return (
                            <tr className='' key={obj?.id}>
                              <td>
                                <div className='position-relative'>
                                  <div className='fw-semibold ms-3 mt-2'>
                                    {changeTextCapital(obj?.user?.firstName ?? '-')}{' '}
                                    {changeTextCapital(obj?.user?.lastName ?? '-')}
                                  </div>

                                  <div
                                    className={`position-absolute h-100 w-4px ${
                                      obj?.user?.isPresent ? 'bg-success' : 'bg-danger'
                                    } rounded top-0 start-0`}
                                  ></div>
                                </div>
                              </td>

                              <td>
                                <div className='mt-2'>{obj?.role ?? '-'}</div>
                              </td>

                              <td className=''>
                                <div className='mt-2'>{dateFormat(obj?.startDate ?? '-')}</div>
                              </td>

                              <td>
                                {' '}
                                {obj?.status === Released ? (
                                  <div className='mt-2'>{dateFormat(obj?.endDate ?? '-')}</div>
                                ) : (
                                  <>
                                    {obj?.endDate ? (
                                      <div className='mt-2'>{dateFormat(obj?.endDate ?? '-')}</div>
                                    ) : (
                                      <div className='mt-2'>{'-'}</div>
                                    )}
                                  </>
                                )}
                              </td>
                              <td>
                                {' '}
                                <div className='mt-2'>{obj?.allocationPercentage ?? '-'}</div>
                              </td>
                              <td>
                                <div className='mt-2'>
                                  <Badge
                                    variant='outline'
                                    color={`${
                                      obj?.status === On_Boarded
                                        ? 'blue'
                                        : obj?.status === Active
                                          ? 'green'
                                          : obj?.status === Released
                                            ? 'red'
                                            : 'red'
                                    }`}
                                  >
                                    {obj?.status ?? '-'}
                                  </Badge>
                                </div>
                              </td>
                              <td>
                                <div className='d-flex'>
                                  <div className='mt-2'>
                                    <span>
                                      {obj?.assigner?.firstName} {obj?.assigner?.lastName}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td
                                hidden={
                                  !(
                                    checkRole(['Project Management'], roleState.features, 'edit') ||
                                    currentUser?.id === projectUser?.projectManager?.id
                                  )
                                }
                              >
                                <div className='d-flex'>
                                  <span
                                    className='me-2 btn btn-icon btn-sm btn-active-color-primary bg-light p-0'
                                    style={{maxHeight: '30px', maxWidth: '30px'}}
                                    onClick={() => {
                                      editUserModal()
                                      getProjectsUsersById(obj?.id, setUsersById, setAPIStatus)
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faEdit} />
                                  </span>
                                </div>
                              </td>
                            </tr>
                          )
                        })}
                    </tbody>
                  ) : (
                    <tbody className='justify-content-center'>
                      <tr className=''>
                        <td colSpan={12} className='fw-semibold mt-5'>
                          <div className='text-center text-gray-600 fs-3'>No Records Available</div>
                          <div className='text-center text-muted fs-6'>
                            To begin, kindly add new users to this project.
                          </div>
                          <div className='text-center'>
                            <img
                              src='/media/avatars/20-dark.png'
                              alt='noReacordAvailable'
                              className='w-70 h-100px h-sm-325px'
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </div>
            <div className='card-footer'>
              <Pagination className='mt-3 justify-content-center pagination-lg'>
                <Pagination.Prev
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                />
                {pagesToDisplay.map((page) => (
                  <Pagination.Item
                    key={page}
                    active={page === currentPage}
                    onClick={() => handlePageChange(page)}
                  >
                    {page}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                />
              </Pagination>
            </div>
          </div>
        )}
      </div>

      <Modal show={showModal} onHide={closeAddUser} centered size='xl'>
        <div className='d-flex flex-column align-items-end pt-2 pe-5'>
          <div className='btn btn-icon btn-sm btn-active-icon-danger align-items-end'>
            <i
              className='fa-solid fa-xmark fs-1 d-flex'
              onClick={() => {
                closeAddUser()
                setInputError(' ')
              }}
            ></i>
          </div>
        </div>
        <Modal.Header className='d-flex flex-column align-items-center'>
          <Modal.Title className='w-100'>
            <Row className='d-flex flex-row justify-content-between align-items-center'>
              <Col md={6}>
                <div className='fs-1 ms-4'>
                  Add User
                  {/* <div className='text-muted fw-semibold fs-5'>Invite Collaborators To Project</div> */}
                </div>
              </Col>
              <Col md={4}>
                <div className='d-flex align-items-center position-relative '>
                  <FontAwesomeIcon
                    icon={faSearch}
                    style={{color: '#828282'}}
                    className='fs-1 position-absolute ms-3'
                  />
                  <input
                    type='text'
                    className='form-control from-control-solid ps-15 rounded-2 text-grey-500 bg-light-secondary me-5'
                    style={{border: 'none'}}
                    placeholder='Search by Username'
                    value={search}
                    onChange={(e) => handleSearch(e.target.value)}
                  />
                  {/* <Button onClick={handleSearch}>Search</Button> */}
                </div>
              </Col>
            </Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='' style={{maxHeight: '350px', overflowY: 'scroll'}}>
          <div className='table-responsive'>
            <table className='table table-responsive'>
              <thead>
                <tr className='fw-bold'>
                  <th style={{width: '10px'}}>{''}</th>
                  <th>{changeTextCapital('User Name')}</th>
                  <th>{changeTextCapital('Role')}</th>
                  <th>{changeTextCapital('Start Date')}</th>
                  <th>{changeTextCapital('End Date')}</th>
                  <th>{changeTextCapital('Allocation (%)')}</th>
                </tr>
              </thead>
              {userData?.length > 0 ? (
                <tbody className='justify-content-center'>
                  {inputField.projectUsers.map((obj: any, index) => {
                    return (
                      <>
                        <tr className='' key={obj?.id}>
                          <td className='text-center'>
                            <span
                              className={`badge badge-light-primary d-inline-block cursor-pointer mt-4 ${showAllocatedUsersData.includes(
                                index
                              )}`}
                              onClick={(e) => {
                                e.stopPropagation()
                                checkAllocatedUsersArrayElement(index)
                                getAllocatedUsersData(
                                  userData[index]?.id,
                                  setAllocateUserData,
                                  setAPIStatus
                                )
                              }}
                            >
                              {showAllocatedUsersData.includes(index) ? '↑' : '↓'}
                            </span>
                          </td>

                          <td className=''>
                            <div className='d-flex'>
                              <div className='form-check form-check-custom form-check-solid'>
                                <input
                                  className='form-check-input me-3'
                                  style={{cursor: 'pointer'}}
                                  type='checkbox'
                                  name='selected'
                                  checked={obj.selected}
                                  onChange={(event) =>
                                    handleAddUsers(
                                      event.target.checked,
                                      event.target.name,
                                      index,
                                      setInputField,
                                      inputField.projectUsers,
                                      inputError,
                                      setInputError
                                    )
                                  }
                                />
                                <div className='symbol symbol-35px symbol-sqaure me-3'>
                                  <span className='symbol-label bg-light-danger text-danger fw-semibold'>
                                    {userData[index]?.firstName[0].toUpperCase()}
                                  </span>
                                </div>
                              </div>

                              <div className=''>
                                <div className='d-flex align-items-center'>
                                  <a
                                    href='#'
                                    className='fs-5 fw-bold text-gray-900 text-hover-primary mb-2 me-2'
                                  >
                                    {userData[index]?.firstName} {userData[index]?.lastName}
                                  </a>
                                </div>
                                <div
                                  className='fw-semibold text-muted'
                                  style={{
                                    maxWidth: '125px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    cursor: 'pointer',
                                  }}
                                  title={userData[index]?.email}
                                >
                                  {userData[index]?.email}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className=''>
                              <select
                                className='form-select border-radius mt-2'
                                style={{cursor: 'pointer', width: '120px'}}
                                name='role'
                                value={obj?.role}
                                disabled={!obj.selected}
                                onChange={(event) =>
                                  handleAddUsers(
                                    event.target.value,
                                    event.target.name,
                                    index,
                                    setInputField,
                                    inputField.projectUsers,
                                    inputError,
                                    setInputError
                                  )
                                }
                              >
                                <option value='' hidden={true}>
                                  Select Role
                                </option>
                                <option>Full Stack Developer</option>
                                <option>Frontend Developer</option>
                                <option>Backend Developer</option>
                                <option>Software Tester</option>
                              </select>
                              {<InputErrorBox Message={inputError[`role${index}`]} />}
                            </div>
                          </td>
                          <td>
                            <div className=''>
                              <input
                                className='form-control mt-2'
                                style={{cursor: 'pointer', width: '130px'}}
                                type='date'
                                name='startDate'
                                value={obj?.startDate}
                                disabled={!obj.selected}
                                onChange={(event) =>
                                  handleAddUsers(
                                    event.target.value,
                                    event.target.name,
                                    index,
                                    setInputField,
                                    inputField.projectUsers,
                                    inputError,
                                    setInputError
                                  )
                                }
                              />
                              {<InputErrorBox Message={inputError[`startDate${index}`]} />}
                            </div>
                          </td>
                          <td>
                            <div className=''>
                              <input
                                className='form-control mt-2'
                                style={{cursor: 'pointer', width: '130px'}}
                                type='date'
                                name='endDate'
                                value={obj?.endDate}
                                disabled={!obj.selected}
                                onChange={(event) =>
                                  handleAddUsers(
                                    event.target.value,
                                    event.target.name,
                                    index,
                                    setInputField,
                                    inputField.projectUsers,
                                    inputError,
                                    setInputError
                                  )
                                }
                              />
                              {<InputErrorBox Message={inputError[`endDate${index}`]} />}
                            </div>
                          </td>
                          <td>
                            <div className=''>
                              <select
                                className='form-select border-radius mt-2'
                                style={{cursor: 'pointer', width: '80px'}}
                                name='allocationPercentage'
                                value={obj?.allocationPercentage}
                                disabled={!obj.selected}
                                onChange={(event) =>
                                  handleAddUsers(
                                    event.target.value,
                                    event.target.name,
                                    index,
                                    setInputField,
                                    inputField.projectUsers,
                                    inputError,
                                    setInputError
                                  )
                                }
                              >
                                <option value='' hidden={true}>
                                  0
                                </option>
                                <option>25</option>
                                <option>50</option>
                                <option>75</option>
                                <option>100</option>
                              </select>
                              {/* {<InputErrorBox Message={inputError[`allocationPercentage${index}`]} />} */}
                            </div>
                          </td>
                          {/* <td>
                            <div className=''>
                              <select
                                className='form-select border-radius mt-2'
                                style={{cursor: 'pointer'}}
                                name='status'
                                value={obj?.status}
                                disabled={!obj.selected}
                                onChange={(event) =>
                                  handleAddUsers(
                                    event.target.value,
                                    event.target.name,
                                    index,
                                    setInputField,
                                    inputField?.projectUsers,
                                    inputError,
                                    setInputError
                                  )
                                }
                              >
                                <option>On Boarded</option>
                                <option>Active</option>
                                <option>Released</option>
                              </select>
                              {<InputErrorBox Message={inputError[`status${index}`]} />}
                            </div>
                          </td> */}
                        </tr>

                        <tr
                          className=''
                          key={`secondIndex${index}`}
                          hidden={!showAllocatedUsersData.includes(index)}
                        >
                          <td className='' colSpan={12}>
                            <div className='table table-responsive table-row-dashed'>
                              <table className='table table-hover table-row-dashed dataTabl'>
                                <thead className=''>
                                  <tr>
                                    <th style={{width: '67px'}}></th>
                                    <th className='fw-bold text-muted' style={{width: '279px'}}>
                                      {changeTextCapital('Project Name')}
                                    </th>
                                    <th className='fw-bold text-muted' style={{width: '185px'}}>
                                      {changeTextCapital('Role')}
                                    </th>
                                    <th className='fw-bold text-muted' style={{width: '198px'}}>
                                      {changeTextCapital('Start Date')}
                                    </th>
                                    <th className='fw-bold text-muted' style={{width: '198px'}}>
                                      {changeTextCapital('End Date')}
                                    </th>
                                    <th className='fw-bold text-muted'>
                                      {changeTextCapital('Allocation (%)')}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className=''>
                                  {allocateUserData.length > 0 ? (
                                    allocateUserData.map((data) => {
                                      return (
                                        <>
                                          <tr key={`data${data?.id}`}>
                                            <td>
                                              <div></div>
                                            </td>
                                            <td className='p-3'>
                                              <div>
                                                <div className=''>
                                                  <div className='fw-semibold'>
                                                    {changeTextSentenceCase(
                                                      data?.project?.name ?? '-'
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </td>
                                            <td className='p-3 '>
                                              <span className=''>{data?.role ?? '-'}</span>
                                            </td>
                                            <td className='p-3 '>
                                              <span className=''>
                                                {dateFormat(data?.startDate ?? '-')}
                                              </span>
                                            </td>
                                            <td className='p-3 '>
                                              <span className=''>
                                                {dateFormat(data?.endDate ?? '-')}
                                              </span>
                                            </td>
                                            <td className='p-3 '>
                                              <span className=''>
                                                {data?.allocationPercentage ?? '-'}
                                              </span>
                                            </td>

                                            {/* <td className='p-3 '></td> */}
                                          </tr>
                                        </>
                                      )
                                    })
                                  ) : (
                                    <td className='text-center text-gray-600' colSpan={6}>
                                      This user is not assigned to any projects .
                                    </td>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      </>
                    )
                  })}
                </tbody>
              ) : (
                <tbody className='justify-content-center'>
                  <tr>
                    <td colSpan={7} className='fw-semibold mt-5'>
                      <div className='fw-semibold text-center'>
                        <div className='text-gray-600 fs-3 mb-2'>No users found</div>
                        <div className='text-muted fs-6'>Try to search by username</div>
                      </div>
                      <div className='text-center'>
                        <img
                          src='/media/avatars/5.png'
                          alt='UserNotFound'
                          className='w-70 h-100px h-sm-225px'
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </Modal.Body>
        <span className='ms-8'>{<InputErrorBox Message={inputError.selected} />}</span>

        <Modal.Footer>
          <Button
            variant='filled'
            color='gray'
            onClick={() => {
              closeAddUser()
              setInputError(' ')
            }}
          >
            Cancel
          </Button>
          <Button
            variant='filled'
            color='green'
            className=''
            loading={SubmitAPIStatus.loading}
            onClick={() =>
              handleAddUsersFunctionallty(
                params?.id,
                inputField,
                closeAddUser,
                setAPIStatus,
                validateAddUser,
                setInputError,
                setShowModal,
                setProjectUser,
                handleNextStep
              )
            }
          >
            Add Selected Users
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={editUser} onClose={() => closeEditUserModal()}>
        <Modal.Header>
          <Modal.Title>Edit User</Modal.Title>
          <div className='btn btn-icon btn-sm btn-active-icon-danger align-items-end'>
            <i
              className='fa-solid fa-xmark fs-1 d-flex'
              onClick={() => {
                closeEditUserModal() // setInputError(' ')
              }}
            ></i>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className='mb-5'>
            <span className='fw-bold fs-6'>Role</span>
            <Select2
              className='mt-2'
              name='role'
              data={[
                {label: 'Frontend Developer', value: 'Frontend Developer'},
                {label: 'Backend Developer', value: 'Backend Developer'},
                {label: 'Fullstack Developer', value: 'Fullstack Developer'},
                {label: 'Software Tester', value: 'Software Tester'},
              ].sort((a, b) => a.label.localeCompare(b.label))}
              value={usersById?.role}
              onChange={(selectedOption: any) => {
                // console.log(selectedOption)
                setUsersById((prevUsersById) => ({
                  ...prevUsersById,
                  role: selectedOption,
                }))
              }}
            ></Select2>
          </div>
          <div className='mb-5'>
            <span className='fw-bold fs-6'>Start Date</span>
            <div className='mt-2'>
              <input
                type='date'
                name='startDate'
                value={usersById?.startDate}
                className='form-control'
                onChange={(event) => handleInputChange(event, setUsersById)}
              />
            </div>
          </div>
          <div className='mb-5'>
            <span className='fw-bold fs-6'>End Date</span>
            <div className='mt-2'>
              <input
                type='date'
                name='endDate'
                value={usersById?.endDate}
                className='form-control'
                onChange={(event) => handleInputChange(event, setUsersById)}
              />
            </div>
          </div>
          <div className='mb-5'>
            <span className='fw-bold fs-6'>Allocation Percentage</span>
            <Select2
              className='mt-2'
              name='allocationPercentage'
              data={[
                {label: '25', value: '25'},
                {label: '50', value: '50'},
                {label: '75', value: '75'},
                {label: '100', value: '100'},
              ]}
              value={usersById?.allocationPercentage.toString()}
              onChange={(selectedOption: any) => {
                // console.log(selectedOption)
                setUsersById((prevUsersById) => ({
                  ...prevUsersById,
                  allocationPercentage: selectedOption,
                }))
              }}
            ></Select2>
          </div>
        </Modal.Body>
        <Modal.Footer className=''>
          <Button
            color='red'
            name='status'
            value={usersById?.status}
            className='ms-3'
            disabled={usersById?.status === Released}
            onClick={() => {
              setUsersById((prevUsersById) => ({
                ...prevUsersById,
                status: 'Released',
              }))

              updateUserById(
                params?.id,
                usersById?.id,
                {...usersById, status: 'Released'},
                setAPIStatus,
                setUsersById,
                setEditUser,
                setProjectUser
              )
            }}
          >
            Release
          </Button>
          <Button
            variant='filled'
            color=''
            className='me-3'
            onClick={() => {
              updateUserById(
                params?.id,
                usersById?.id,
                {...usersById, status: 'On Boarded'},
                setAPIStatus,
                setUsersById,
                setEditUser,
                setProjectUser
              )
            }}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Users
