import {getDate} from '../_metronic/helpers'
import {setupAxios} from '../app/modules/auth'
import {API_BASE_URL} from '../utils/contants'
import {api} from './APIMiddlewares'

export async function getPendingRequests(currentUser) {
  const {token, tenantId} = setupAxios()
  const res = await fetch(API_BASE_URL + `/attendence/early-checkout/pending/${currentUser.id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'X-Tenant-Id': `${tenantId}`,
    },
  })
  return await res.json()
}

export async function handleAttendancesApproves(id, currentUser) {
  const {token, tenantId} = setupAxios()
  return await api
    .patch(
      `/attendence/update`,
      token,
      tenantId,
      {
        body: JSON.stringify({
          remark: 'accept',
          id: id,
          actionBy: {id: currentUser?.id},
        }),
      },
      false
    )
    .then((res) => res)
}
export async function getAttendanceApplication(
  id: string,
  page: string,
  inputField: {
    startDate: string
    endDate: string
    status: string
    user: string
    reason: string
  }
) {
  const {token, tenantId} = setupAxios()
  return await api
    .post(
      `/attendence-application?page=${page}&take=${10}`,
      token,
      tenantId,
      {
        body: JSON.stringify({
          id: id ?? null,
          status: inputField?.status,
          filterUserId: !inputField.user ? null : inputField.user,
          startDate: inputField.startDate ? inputField.startDate + 'T00:00:00' : null,
          endDate: inputField.endDate ? inputField.endDate + 'T23:59:59' : null, // Ensure endDate is null if empty
        }),
      },
      false
    )
    .then((res) => res)
}
export async function handleAttendancessApproves(id, currentUser) {
  const {token, tenantId} = setupAxios()
  return await api
    .patch(
      `/attendence/update`,
      token,
      tenantId,
      {
        body: JSON.stringify({
          remark: 'accept',
          id: id,
          actionBy: {id: currentUser?.id},
        }),
      },
      false
    )
    .then((res) => res)
}
export async function handleForwardToHRs(
  inputField: {
    reason: string
  },
  id,
  currentUser
) {
  const {token, tenantId} = setupAxios()
  return await api
    .patch(
      `/attendence/update`,
      token,
      tenantId,
      {
        body: JSON.stringify({
          remark: 'review',
          reason: !inputField.reason ? null : inputField.reason || null,
          id: id,
          actor: 'Reporting Manager',
          actionBy: {id: currentUser?.id},
        }),
      },
      false
    )
    .then((res) => res)
}
export async function handleAttendanceRejects(
  inputField: {
    reason: string
  },
  idForReason: string,
  currentUser
) {
  const {token, tenantId} = setupAxios()
  return await api
    .patch(
      `/attendence/update`,
      token,
      tenantId,
      {
        body: JSON.stringify({
          remark: 'reject',
          reason: inputField.reason || null,
          id: idForReason,
          actor: 'HR Executive',
          actionBy: {id: currentUser?.id},
        }),
      },
      false
    )
    .then((res) => res)
}

export const updateAttendanceById = async (
  id: string,
  remark: string,
  accessToken: string,
  actionBy: {
    id: string
  }
) => {
  const {token, tenantId} = setupAxios()
  return await api
    .patch(
      `/attendence/update`,
      token,
      tenantId,
      {
        body: JSON.stringify({
          id,
          remark,
          accessToken,
          actionBy,
        }),
      },
      false
    )
    .then((res) => res)
}

export const handleClearFilterss = async (currentUser, inputField, tab) => {
  const {token, tenantId} = setupAxios()
  return await api
    .post(
      `/attendence?page=${1}&take=${10}`,
      token,
      tenantId,
      {
        body: JSON.stringify({
          tab: tab,
          id: currentUser?.id,
          startDate: inputField.startDate ? inputField.startDate + 'T00:00:00' : null,
          endDate: inputField.endDate ? inputField.endDate + 'T23:59:59' : null,
        }),
      },
      false
    )
    .then((res) => res)
}
