import {faPencil, faXmark} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Button} from '@mantine/core'
import React, {useEffect, useState} from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {handleInputChange, handleOtherInputChange, updateProject} from './SettingsFunction'
import axios from 'axios'
import {useParams} from 'react-router-dom'
import {getProjectsById} from '../../../../api/Organization'
import {API_BASE_URL} from '../../../../utils/contants'
import Select2 from '../../../components/select2'
import {setupAxios, useAuth} from '../../../modules/auth'
import InputErrorBox from '../../../components/InputErrorBox'

const Settings = (props: {setProjectData?: Function}) => {
  const params = useParams()

  const {currentUser} = useAuth()
  const [usersOptions, setUsersOptions] = useState<{label: string; value: string}[]>([])
  const sortedUserNames = usersOptions.slice().sort((a, b) => a.label.localeCompare(b.label))

  const [APIStatus, setAPIStatus] = useState({
    loading: false,
    error: null,
    submitLoading: false,
  })

  const [inputError, setInputError] = useState<any>({})

  const [selectedFile, setSelectedFile] = useState<String | null>('/media/logos/uploadFile.jpg')

  const initialState = {
    // id: '',
    name: '',
    type: '',
    description: '',
    startDate: '',
    dueDate: '',
    endDate: '',
    status: '',
    category: '',
    subCategory: '',
    projectManager: {
      id: '',
    },
  }
  const [inputField, setInputField] = useState(initialState)

  // const [projectData, setProjectData] = useState({
  //   id: '',
  //   name: '',
  //   description: '',
  //   endDate: '',
  //   status: '',
  // })

  // const [inputField, setinputField] = useState({
  //   id: '',
  //   name: '',
  //   type: '',
  //   description: '',
  //   startDate: '',
  //   endDate: '',
  //   status: true,
  // })

  const fetchNames = () => {
    const {token} = setupAxios()
    axios
      .get(API_BASE_URL + `/activeUsers`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: any) => {
        let userData: {label: string; value: string}[] = []
        response.data.data.map(
          (obj: {firstName: string; lastName: string; id: string}, index: number) => {
            userData.push({
              label: obj.firstName + ' ' + obj.lastName,
              value: obj.id,
            })
          }
        )
        setUsersOptions(userData)
      })
      .catch((error) => {
        console.error('Error fetching names:', error)
      })
  }

  useEffect(() => {
    let mounted = true

    if (mounted) {
      // updateProjectById(params.id).then((res) => {
      //   setInputField(res.data ?? {})
      // })
      getProjectsById(params.id).then((res) => {
        setInputField(res?.data[0] ?? {})
      })
      fetchNames()
    }
    return () => {
      mounted = false
    }
  }, [])

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]

    const imageUrl = file ? URL.createObjectURL(file) : ''
    setSelectedFile(imageUrl)
  }

  return (
    <div>
      <div className='card mt-10' key={params?.id}>
        <div className='card-header'>
          <div className='card-title fs-3 fw-bold'>Project Settings</div>
        </div>

        <div id='' className='form'>
          <div className='card-body p-9'>
            <div className='row mb-5'>
              <div className='col-xl-3'>
                <div className='fs-6 fw-semibold mt-2 mb-3'>Project Logo</div>
              </div>

              <div className='col-lg-8'>
                <div
                  className='image-input image-input-outline'
                  data-kt-image-input='true'
                  style={{
                    backgroundImage: `url(${selectedFile})`,
                  }}
                >
                  <div
                    className='image-input-wrapper w-125px h-125px bgi-position-center'
                    style={{
                      backgroundSize: '75%',
                      backgroundImage: `url(${selectedFile})`,
                    }}
                  ></div>

                  <label
                    className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px shadow'
                    data-kt-image-input-action='change'
                    data-bs-toggle='tooltip'
                    title='Change avatar'
                  >
                    <FontAwesomeIcon icon={faPencil} />
                    <input
                      type='file'
                      name='avatar'
                      accept='.png, .jpg, .jpeg'
                      onChange={handleFileChange}
                    />
                  </label>

                  <span
                    className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px shadow'
                    data-kt-image-input-action='remove'
                    data-bs-toggle='tooltip'
                    title='Remove avatar'
                  >
                    <FontAwesomeIcon
                      icon={faXmark}
                      onClick={() => setSelectedFile('media/logos/skitLogo.png')}
                    />
                  </span>
                </div>
                <div className='form-text'>Allowed file types: png, jpg, jpeg.</div>
              </div>
            </div>

            <div className='row mb-8'>
              <div className='col-xl-3'>
                <div className='fs-6 fw-semibold mt-2 mb-3'>Project Name</div>
              </div>

              <div className='col-xl-9 fv-row'>
                <input
                  type='text'
                  autoComplete='off'
                  className='form-control'
                  name='name'
                  value={inputField?.name}
                  onChange={(event) => handleInputChange(event, setInputField)}
                />
                {<InputErrorBox Message={inputError.name} />}
              </div>
            </div>

            <div className='row mb-8'>
              <div className='col-xl-3'>
                <div className='fs-6 fw-semibold mt-2 mb-3'>Category</div>
              </div>

              <div className='col-xl-9 fv-row'>
                <Select2
                  name='category'
                  data={[
                    {label: 'FPP', value: 'FPP'},
                    {label: 'T & M', value: 'T & M'},
                    {label: 'Unbilled', value: 'Unbilled'},
                  ].sort((a, b) => a.label.localeCompare(b.label))}
                  value={inputField?.category}
                  onChange={(selectedOption: any) => {
                    console.log(selectedOption)
                    setInputField((prevInputField) => ({
                      ...prevInputField,
                      category: selectedOption,
                    }))
                  }}
                />
                {<InputErrorBox Message={inputError.category} />}
              </div>
            </div>

            <div className='row mb-8'>
              <div className='col-xl-3'>
                <div className='fs-6 fw-semibold mt-2 mb-3'>Sub-Category</div>
              </div>

              <div className='col-xl-9 fv-row'>
                <Select2
                  name='subCategory'
                  data={[
                    {label: 'Professional Services', value: 'Professional Services'},
                    {label: 'Managed Services', value: 'Managed Services'},
                    {label: 'Staffing', value: 'Staffing'},
                  ].sort((a, b) => a.label.localeCompare(b.label))}
                  value={inputField?.subCategory}
                  onChange={(selectedOption: any) => {
                    console.log(selectedOption)
                    setInputField((prevInputField) => ({
                      ...prevInputField,
                      subCategory: selectedOption,
                    }))
                  }}
                />
                {<InputErrorBox Message={inputError.subCategory} />}
              </div>
            </div>

            <div className='row mb-8'>
              <div className='col-xl-3'>
                <div className='fs-6 fw-semibold mt-2 mb-3'>Project Type</div>
              </div>

              <div className='col-xl-9 fv-row'>
                <Select2
                  name='type'
                  data={[
                    {label: 'Web', value: 'Web'},
                    {label: 'Android', value: 'Android'},
                    {label: 'ios', value: 'ios'},
                    {label: 'Data Center', value: 'DataCenter'},
                    {label: 'Infra', value: 'Infra'},
                  ].sort((a, b) => a.label.localeCompare(b.label))}
                  value={inputField?.type}
                  onChange={(selectedOption: any) => {
                    console.log(selectedOption)
                    setInputField((prevInputField) => ({
                      ...prevInputField,
                      type: selectedOption,
                    }))
                  }}
                />
                {<InputErrorBox Message={inputError.type} />}
              </div>
            </div>

            <div className='row mb-8'>
              <div className='col-xl-3'>
                <div className='fs-6 fw-semibold mt-2 mb-3'>Project Description</div>
              </div>

              <div className='col-xl-9 fv-row '>
                <ReactQuill
                  style={{fontSize: '13px', minHeight: '175px'}}
                  theme='snow'
                  className='form-control'
                  placeholder='Enter text...'
                  value={inputField?.description}
                  onChange={(value) => handleOtherInputChange(value, 'description', setInputField)}
                />
                {<InputErrorBox Message={inputError.description} />}
              </div>
            </div>

            <div className='row mb-8'>
              <div className='col-xl-3'>
                <div className='fs-6 fw-semibold mt-2 mb-3'>Start Date</div>
              </div>

              <div className='col-xl-9 fv-row'>
                <div className='position-relative d-flex align-items-center'>
                  <input
                    className='form-control'
                    type='date'
                    name='startDate'
                    value={inputField?.startDate}
                    onChange={(event) => handleInputChange(event, setInputField)}
                  />
                </div>
                {<InputErrorBox Message={inputError.startDate} />}
              </div>
            </div>

            <div className='row mb-8'>
              <div className='col-xl-3'>
                <div className='fs-6 fw-semibold mt-2 mb-3'>Due Date</div>
              </div>

              <div className='col-xl-9 fv-row'>
                <div className='position-relative d-flex align-items-center'>
                  <input
                    className='form-control'
                    type='date'
                    name='dueDate'
                    value={inputField?.dueDate}
                    onChange={(event) => handleInputChange(event, setInputField)}
                  />
                </div>
                {<InputErrorBox Message={inputError.dueDate} />}
              </div>
            </div>

            <div className='row mb-8'>
              <div className='col-xl-3'>
                <div className='fs-6 fw-semibold mt-2 mb-3'>End Date</div>
              </div>

              <div className='col-xl-9 fv-row'>
                <div className='position-relative d-flex align-items-center'>
                  <input
                    className='form-control'
                    type='date'
                    name='endDate'
                    value={inputField?.endDate ?? '-'}
                    onChange={(event) => handleInputChange(event, setInputField)}
                  />
                </div>
                {<InputErrorBox Message={inputError.endDate} />}
              </div>
            </div>

            <div className='row mb-8'>
              <div className='col-xl-3'>
                <div className='fs-6 fw-semibold mt-2 mb-3'>Project Manager</div>
              </div>

              <div className='col-xl-9 fv-row'>
                <div className='position-relative d-flex align-items-center'>
                  <Select2
                    name='projectManager'
                    className=''
                    data={sortedUserNames}
                    value={inputField?.projectManager?.id || ' '}
                    onChange={(selectedOption: string) => {
                      setInputField((prevInputField) => ({
                        ...prevInputField,
                        projectManager: {id: selectedOption},
                      }))
                      console.log('selected user', selectedOption)
                    }}
                  />
                </div>
                {<InputErrorBox Message={inputError.projectManager} />}
              </div>
            </div>

            <div className='row'>
              <div className='col-xl-3'>
                <div className='fs-6 fw-semibold mt-2 mb-3'>Status</div>
              </div>

              <div className='col-xl-9 fv-row'>
                <div className='position-relative d-flex align-items-center'>
                  <Select2
                    name='status'
                    data={[
                      {label: 'Created', value: 'Created'},
                      {label: 'On Hold', value: 'On Hold'},
                      {label: 'Completed', value: 'Completed'},
                      {label: 'In Progress', value: 'In Progress'},
                    ].sort((a, b) => a.label.localeCompare(b.label))}
                    value={inputField?.status}
                    onChange={(selectedOption: any) => {
                      // console.log(selectedOption)
                      setInputField((prevInputField) => ({
                        ...prevInputField,
                        status: selectedOption,
                      }))
                    }}
                  />
                  {<InputErrorBox Message={inputError.status} />}
                </div>
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <Button
              variant='filled'
              type='submit'
              className=''
              color='green'
              style={{paddingTop: '5px', paddingBottom: '5px'}}
              onClick={() => {
                updateProject(
                  params.id,
                  inputField,
                  currentUser,
                  setInputField,
                  setAPIStatus,
                  setInputError,
                  props?.setProjectData
                )
              }}
            >
              Update
            </Button>
          </div>
        </div>
      </div>
      <div className='card mt-5'>
        <div className='card-body'>
          <div className='d-flex justify-content-between fs-5 fw-bold'>
            Delete Project
            <Button color='red' variant='light' size='md'>
              Delete
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Settings
