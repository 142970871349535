const API = {
    //Admin Module API's
    COUNTRY: 'countries',
    CITY: 'cities',
    STATE: 'states',
    ACCOUNT: 'accounts',
    ACCOUNT_MANAGER: 'account-managers',
    CLUSTER: 'clusters',
    CURRENCY: 'currencies',
    USER: 'users',
    PRODUCTS: 'products',
    LOCATION: 'locations',
    JOB_FAMILY_ROLES: 'job-family-roles',
    MODULE: 'modules',
    ROLES: 'roles',
    OCCUPATION: 'occupation',
    SOURCE: 'source',
    PROFIT_PLAN: 'profit-plan',
    PRODUCT_CATEGORIES: 'categories',
    PRODUCT_SUB_CATEGORIES: 'sub-categories',
  
    //Investment Module API
    PROSPECTS: 'prospect',
    COMMENTS: 'comment',
  }
  
  export default API