import {setupAxios} from '../app/modules/auth'
import {RoleMasterInput} from '../app/types/AdminModuleTypes'
import {api} from './APIMiddlewares'
import API from './APIUrl'

export async function getUsers() {
  const {token, tenantId} = setupAxios()
  return await api.get(`/users`, token, tenantId, false)
}

export async function getActiveUsers() {
  const {token, tenantId} = setupAxios()
  return await api.get(`/activeUsers`, token, tenantId, false)
}
export async function getUsersById(id: string | undefined) {
  const {token, tenantId} = setupAxios()
  return await api.get(`/users/${id}`, token, tenantId, false)
}

export async function getTeamOfReportingManager(id: string, manager: string) {
  const {token, tenantId} = setupAxios()
  return await api.get(`/users/rm/${id}?manager=${manager}`, token, tenantId, false)
}

export async function checkIsManager(id: string) {
  const {token, tenantId} = setupAxios()
  return await api.get(`/check-managers/${id}`, token, tenantId, false)
}

export async function deleteUser(id: string) {
  const {token, tenantId} = setupAxios()
  return await api.delete(`/users/${id}`, token, tenantId, false)
}
export async function addUser(InputField: {
  firstName: string
  lastName: string
  email: string
  contact: string
  password: string
  dob: string
  gender: string
  role: string
  hr: string
  employmentType: string
  designation: string
  startDate: string
  endDate: string
  inTime: string
  outTime: string
  reportingManager: string

  personalEmail: string
}) {
  const {token, tenantId} = setupAxios()

  return await api
    .post(
      `/register`,
      token,
      tenantId,
      {
        body: JSON.stringify({
          firstName: InputField.firstName.trim(),
          lastName: InputField.lastName.trim(),
          email: InputField.email,
          contact: InputField.contact,
          password: InputField.password,
          dob: InputField.dob,
          gender: InputField.gender,
          role: {
            id: InputField.role.toString(),
          },
          hrManager: InputField.hr ? {id: InputField.hr} : null,

          reportingManager: {
            id: !InputField.reportingManager ? null : InputField.reportingManager || null,
          },
          employmentType: InputField.employmentType,
          designation: InputField.designation,
          startDate: InputField.startDate,
          // passing the end date as null
          endDate: !InputField.endDate ? null : InputField.endDate || null,

          inTime: InputField.inTime,
          outTime: InputField.outTime,
          personalEmail: InputField.personalEmail,
        }),
      },
      false
    )

    .then((res) => res)
}
// api.js
export async function updateUserById(
  InputField: {
    firstName: string
    lastName: string
    email: string
    contact: string
    password: string
    dob: string
    gender: string
    role: string
    employmentType: string
    designation: string
    startDate: string
    endDate: string
    inTime: string
    outTime: string
    reportingManager: string

    personalEmail: string
  },
  id: string
) {
  const {token, tenantId} = setupAxios()
  return await api
    .put(
      `/users/${id}`,
      token,
      tenantId,
      {
        body: JSON.stringify({
          firstName: InputField.firstName.trim(),
          lastName: InputField.lastName.trim(),
          email: InputField.email,
          contact: InputField.contact,
          password: InputField.password,
          dob: InputField.dob,
          role: {
            id: InputField.role.toString(),
          },
          gender: InputField.gender,
          reportingManager: InputField.reportingManager,
          employmentType: InputField.employmentType,
          designation: InputField.designation,
          startDate: InputField.startDate,
          endDate: InputField.endDate,

          inTime: InputField.inTime,
          outTime: InputField.outTime,
          personalEmail: InputField.personalEmail,
        }),
      },
      false
    )
    .then((res) => res)
}
export const updateBankDetailsApi = async (userId, bankDetailData, token, tenantId) => {
  return await api
    .patch(`users/${userId}/bank`, token, tenantId, bankDetailData, false)
    .then((res) => res)
}
export async function updatePassword(
  InputField: {
    oldPassword: string
    password: string
  },
  id: string
) {
  const {token, tenantId} = setupAxios()
  return await api
    .patch(
      `/users/${id}/password`,
      token,
      tenantId,
      {
        body: JSON.stringify({
          oldPassword: InputField.oldPassword,
          password: InputField.password,
        }),
      },
      false
    )
    .then((res) => res)
}

export async function getRoles() {
  const {token, tenantId} = setupAxios()
  return await api.get(`/${API.ROLES}/all`, token, tenantId, false).then((res) => res)
}
export async function getRolesById(id: string) {
  const {token, tenantId} = setupAxios()
  return await api.get(`/role/${id}`, token, tenantId, false).then((res) => res)
}

export async function deleteRoles(id: string) {
  const {token, tenantId} = setupAxios()
  return await api.delete(`/${API.ROLES}/${id}`, token, tenantId, true).then((res) => res)
}
export async function addRoles(
  formField: RoleMasterInput,
  permission: {
    features: {id: string}
    permissions: (string | number)[]
  }[]
) {
  const {token, tenantId} = setupAxios()
  const permissionArray = permission.map((permissionItem) => ({
    features: permissionItem.features,
    permissions: permissionItem.permissions.map((permission) => permission.toString()), // Convert each permission to string
  }))
  return await api
    .post(
      `/roles`,
      token,
      tenantId,
      {
        body: JSON.stringify({
          name: formField.roleName.toUpperCase().trim(),
          permission: permissionArray,
        }),
      },
      true
    )
    .then((res) => res)
}
export async function checkIsRoleDuplicate(
  formField: RoleMasterInput,
  permission: {
    features: {id: string}
    permissions: string[]
  }[]
) {
  const {token, tenantId} = setupAxios()
  return await api
    .post(
      `/${API.ROLES}/check_duplicate`,
      token,
      tenantId,
      {
        body: JSON.stringify({
          name: formField.roleName.toUpperCase().trim(),
          permission,
        }),
      },
      false
    )
    .then((res) => res)
}
export async function updateRolesById(
  formField: RoleMasterInput,
  id: string,
  permission: {
    features: {id: string}
    permissions: (string | number)[]
  }[]
) {
  const {token, tenantId} = setupAxios()
  const permissionArray = permission.map((permissionItem) => ({
    features: permissionItem.features,
    permissions: permissionItem.permissions.map((permission) => permission.toString()), // Convert each permission to string
  }))
  return await api
    .put(
      `/role/${id}`,
      token,
      tenantId,
      {
        body: JSON.stringify({
          name: formField.roleName.toUpperCase().trim(),
          permission: permissionArray,
        }),
      },
      true
    )
    .then((res) => res)
}

export async function getFeatures() {
  const {token, tenantId} = setupAxios()
  return await api.get(`/features`, token, tenantId, false).then((res) => res)
}
export async function getFeatureLevels() {
  const {token, tenantId} = setupAxios()
  return await api.get(`/roles`, token, tenantId, false).then((res) => res)
}
export async function getModules() {
  const {token, tenantId} = setupAxios()
  return await api.get(`/${API.MODULE}`, token, tenantId, false).then((res) => res)
}
export async function getFeaturesByModuleId(id: string) {
  const {token, tenantId} = setupAxios()
  return await api.get(`/${API.MODULE}/${id}/features`, token, tenantId, false).then((res) => res)
}
