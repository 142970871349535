import {toast} from 'react-toastify'
import {Message} from '../../../../_metronic/helpers'
import {getTaskDetailes, updateTaskById} from '../../../../api/Organization'

export const getTaskOverview = (taskId, setTaskData: Function, setAPIStatus: Function) => {
  setAPIStatus((prev: any) => ({...prev, loading: true}))
  getTaskDetailes(taskId)
    .then((res: any) => {
      if (res.statusCode === 200) {
        // console.log(res.data)
        const dueDate = new Date(res.data[0]?.dueDate)
        const today = new Date()
        dueDate.setHours(0, 0, 0, 0)
        today.setHours(0, 0, 0, 0)

        if (dueDate.getTime() === today.getTime()) {
          toast.info('Your task is due today!')
        }
        setTaskData(res.data ?? {})
        setAPIStatus((prev: any) => ({...prev, loading: false}))
      } else {
        setAPIStatus((prev: any) => ({...prev, loading: false, error: res.message}))
      }
    })
    .catch((error) => {
      console.error('Error Fetching Tasks', error)
      setAPIStatus((prev: any) => ({...prev, loading: false, erorr: 'Error Fetching Tasks'}))
    })
}

export const updateTask = (taskId, InputField, setTaskData: Function, setAPIStatus: Function) => {
  setAPIStatus((prev: any) => ({...prev, loading: true}))
  updateTaskById(taskId, InputField).then((res: any) => {
    if (res.statusCode === 200) {
      toast.success(res.message)
      getTaskDetailes(taskId).then((res) => {
        setTaskData(res.data ?? {})
      })
      setAPIStatus((prev: any) => ({...prev, loading: false}))
    } else {
      Message(res.message, 'error')
      setAPIStatus((prev: any) => ({...prev, loading: false, error: res.message}))
    }
  })
}

// export const updateTaskStatus = (
//   taskId,
//   StatusField,
//   setTaskData: Function,
//   setAPIStatus: Function
// ) => {
//   setAPIStatus((prev: any) => ({...prev, loading: true}))
//   updateTaskStatusById(taskId, StatusField).then((res: any) => {
//     if (res.statusCode === 200) {
//       toast.success(res.message)
//       getTaskDetailes(taskId).then((res) => {
//         setTaskData(res.data ?? {})
//       })
//       setAPIStatus((prev: any) => ({...prev, loading: false}))
//     } else {
//       Message(res.message, 'error')
//       setAPIStatus((prev: any) => ({...prev, loading: false, error: res.message}))
//     }
//   })
// }

export const validateEditedField = ({title}) => {
  let count = 0
  const error: any = {}
  // const descriptionWithoutTags = stripTags(description).trim()

  if (!title.trim()) {
    count = count + 1
    error.title = 'Please enter a Task Title.'
  } else if (title.length > 250) {
    count = count + 1
    error.title = 'Task Title must not exceed 250 characters.'
  }
  // if (!description.trim() || !description.replace(/<[^>]*>/g, '').trim()) {
  //   count = count + 1
  //   error.description = 'Please enter a Description.'
  // } else if (!descriptionWithoutTags || descriptionWithoutTags.trim().length > 30000) {
  //   count = count + 1
  //   error.description = 'Description must not exceed 30,000 characters.'
  // }

  return error
}
