import {useContext, useEffect, useState} from 'react'

import {Button, Center} from '@mantine/core'
import axios from 'axios'
import {Modal} from 'react-bootstrap'
import {ToastContainer, toast} from 'react-toastify'
import {Message, changeTextCapital, checkRole} from '../../../_metronic/helpers/AssetHelpers'
import {Toolbar1} from '../../../_metronic/layout/components/toolbar/Toolbar1'
import {sendUpdateAssetEmail} from '../../../api/AssetsAPI'
import {API_BASE_URL, EDIT_ROW_COLORS} from '../../../utils/contants'
import InputErrorBox from '../../components/InputErrorBox'
import Select2 from '../../components/select2'
import roleContext from '../../context/roleContext'
import {setupAxios, useAuth} from '../../modules/auth'
import {RoleContextValue} from '../../types/OtherTypes'
import {
  cancelFormSubmit,
  cancelSubmit,
  getAsset,
  getAssetById,
  handleFormFieldChange,
  handleFormFieldSubmit,
  handleInputChange,
  handleSubmit,
  validateForm,
  validateFormField,
} from './AssetsFunction'
import AssignAssets from './AssignAssets'
import {getUserAssetById, handleReturnSubmit, validateReturnForm} from './AssignAssetsFunction'
import CommentPopup from './CommentPopup'

const Assets = () => {
  let roleState: RoleContextValue = useContext(roleContext)

  const [asset, setAsset] = useState([
    {
      id: '',
      createdAt: '',
      updatedAt: '',
      category: '',
      subCategory: '',
      tag: '',
      serialNumber: '',
      description: '',
      purchaseDate: '',
      status: '',
      assignee: '',
      location: '',
      locationType: '',
      UserAssets: [
        {
          issueDate: '',
          returnDate: '',
          user: {
            firstName: '',
            lastName: '',
          },
        },
      ],
    },
  ])

  const [userAsset, setUserAsset] = useState([
    {
      id: '',
      issueDate: '',
      returnDate: '',
      user: {
        id: '',
        firstName: '',
        lastName: '',
      },
      asset: {
        id: '',
        createdAt: '',
        category: '',
        status: '',
        brand: '',
        serialNo: '',
        description: '',
        location: '',
        locationType: '',
      },
    },
  ])
  const formFieldState = {
    id: '',
    issueDate: '',
    user: '',
    location: '',
    locationType: '',
  }
  const [formField, setFormField] = useState(formFieldState)
  const [editId, setEditId] = useState(null)
  const [inputError, setInputError] = useState<any>({})
  const [APIStatus, setAPIStatus] = useState({
    loading: false,
    error: null,
    submitLoading: false,
  })
  const [SubmitAPIStatus, setSubmitAPIStatus] = useState({
    loading: false,
  })
  const {currentUser} = useAuth()

  const initialState = {
    category: '',
    subCategory: '',
    brand: '',
    serialNo: '',
    description: '',
    purchaseDate: '',
    status: '',
    assignee: '',
    user: '',
    issueDate: '',
    returnDate: '',
    comment: '',
  }
  const [inputField, setInputField] = useState(initialState)
  const [expandedRows, setExpandedRows] = useState<number[]>([])
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [returnDateModal, setReturnDateModal] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [comment, setComment] = useState('')
  const [commentError, setCommentError] = useState<string | null>(null)
  const [usersOptions, setUsersOptions] = useState<{label: string; value: string}[]>([])
  const sortedUserNames = usersOptions.slice().sort((a, b) => a.label.localeCompare(b.label))
  const [showRow, setShowRow] = useState<number[]>([])
  const fetchNames = () => {
    const {token, tenantId} = setupAxios()
    axios
      .get(API_BASE_URL + `/activeUsers`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-Tenant-Id': `${tenantId}`,
        },
      })
      .then((response: any) => {
        let userData: {label: string; value: string}[] = []
        response.data.data?.map((obj: {firstName: string; lastName: string; id: string}) => {
          userData.push({
            label: obj.firstName + ' ' + obj.lastName,
            value: obj.id,
          })
        })
        setUsersOptions(userData)
      })
      .catch((error) => {
        console.error('Error fetching names:', error)
      })
  }
  const [isCommentPopupOpen, setIsCommentPopupOpen] = useState(false)

  const checkArrayElement = (element: number) => {
    let arr: number[] = [...showRow]
    let isExists = false
    for (let i = 0; i <= arr.length - 1; i++) {
      if (arr[i] === element) {
        arr.splice(i, 1)
        isExists = true
        break
      }
    }

    if (!isExists) {
      arr.push(element)
    }
    setShowRow(arr)
  }
  const handleCancelComment = () => {
    setIsCommentPopupOpen(false)
    setComment('')
    setCommentError(null)
    setEditId(null)
  }

  const handleSubmitComment = () => {
    let error = 0
    if (!comment) {
      error = error + 1
      setCommentError('Please enter a valid Justification.')
    } else if (comment.length >= 500) {
      error = error + 1
      setCommentError('Justification must not exceed 500 characters.')
    }

    if (error === 0) {
      setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
      sendUpdateAssetEmail(inputField, editId ?? '', currentUser?.id ?? '', comment).then(
        (res: any) => {
          if (res.statusCode === 200) {
            getAsset(setAsset, setAPIStatus)
            toast.success(res.message)
            setIsAddUserModalOpen(false)
            cancelSubmit(setInputField, initialState, setEditId, setInputError)
            handleCancelComment()
          } else {
            Message(res.message, 'error')
            setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
          }
          setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
          handleCancelComment()
        }
      )
    } else {
      console.error('Email is null. Unable to update status.')
    }
  }
  const fetchAssets = () => {
    getAsset(setAsset, setAPIStatus)
  }
  const resetInputFields = () => {
    setInputField(initialState)
    setEditId(null)
    setInputError({})
  }
  useEffect(() => {
    let mounted = true
    if (mounted) {
      fetchAssets()
      setIsAddUserModalOpen(false)
      setIsEditModalOpen(false)
    }
    return () => {
      mounted = false
    }
  }, [])

  const handleClose = () => {
    setModalOpen(false)
    setReturnDateModal(false)
    setIsAddUserModalOpen(false)
    setExpandedRows([])
  }

  return (
    <>
      <Modal
        show={isAddUserModalOpen}
        onClose={() => setIsAddUserModalOpen(false)}
        animation={false}
        backdrop='static'
        centered
      >
        <div className='modal-header'>
          <h2 className='fw-bolder'>Add Asset Details</h2>
          <div
            className='btn btn-icon btn-sm btn-active-icon-danger'
            data-kt-users-modal-action='close'
            style={{cursor: 'pointer'}}
            onClick={() => {
              handleClose()

              setInputError('')
              cancelSubmit(setInputField, initialState, setEditId, setInputError)
            }}
          >
            <i className='fa-solid fa-xmark fs-1' />
          </div>
        </div>
        <div className='m-3'>
          <div className='fv-row mb-5 col-md-12'>
            <label className='fs-6 fw-semibold mb-2'>Category</label>
            <span className='text-danger ms-1 fs-4 fw-bold'>*</span>

            <Select2
              name='category'
              data={[{label: 'IT Assets', value: 'IT Assets'}].sort((a, b) =>
                a.label.localeCompare(b.label)
              )}
              value={inputField.category}
              onChange={(selectedOption: any) => {
                console.log('selected options:', selectedOption)
                setInputField((prevInputField) => ({
                  ...prevInputField,
                  category: selectedOption,
                }))
              }}
            />
            {<InputErrorBox Message={inputError.category} />}
          </div>
          <div className='fv-row mb-5 col-md-12'>
            <label className='fs-6 fw-semibold mb-2'> Sub Category</label>
            <span className='text-danger ms-1 fs-4 fw-bold'>*</span>

            <Select2
              name='category'
              data={[
                {label: 'Laptop', value: 'Laptop'},
                {label: 'Mouse', value: 'Mouse'},
                {label: 'Monitor', value: 'Monitor'},
                {label: 'Keyboard', value: 'Keyboard'},
                {label: 'CPU', value: 'CPU'},
              ].sort((a, b) => a.label.localeCompare(b.label))}
              value={inputField.subCategory}
              onChange={(selectedOption: any) => {
                console.log('selected options:', selectedOption)
                setInputField((prevInputField) => ({
                  ...prevInputField,
                  subCategory: selectedOption,
                }))
              }}
            />
            {<InputErrorBox Message={inputError.subCategory} />}
          </div>
          <div className='fv-row mb-5 col-md-12'>
            <label className='fs-6 fw-semibold mb-2'>Asset Brand</label>
            <span className='text-danger ms-1 fs-4 fw-bold'>*</span>

            <input
              className='form-control'
              type='text'
              placeholder='Asset Brand'
              name='brand'
              value={inputField.brand}
              onChange={(event) => handleInputChange(event, setInputField, inputField)}
            />
            {<InputErrorBox Message={inputError.brand} />}
          </div>
          <div className='fv-row mb-5 col-md-12'>
            <label className='fs-6 fw-semibold mb-2'>Serial No.</label>
            <span className='text-danger ms-1 fs-4 fw-bold'>*</span>

            <input
              className='form-control'
              type='text'
              placeholder='Serial No.'
              name='serialNo'
              value={inputField.serialNo}
              onChange={(event) => handleInputChange(event, setInputField, inputField)}
            />
            {<InputErrorBox Message={inputError.serialNo} />}
          </div>

          <div className='fv-row mb-5 col-md-12'>
            <label className='fs-6 fw-semibold mb-2'>Description</label>
            <span className='text-danger ms-1 fs-4 fw-bold'>*</span>

            <input
              className='form-control'
              type='text'
              placeholder='Description'
              name='description'
              value={inputField.description}
              onChange={(event) => handleInputChange(event, setInputField, inputField)}
            />
            {<InputErrorBox Message={inputError.description} />}
          </div>
          <div className='fv-row mb-5 col-md-12'>
            <label className='fs-6 fw-semibold mb-2'>Purchase Date</label>
            <span className='text-danger ms-1 fs-4 fw-bold'>*</span>

            <input
              className='form-control'
              type='date'
              name='purchaseDate'
              value={inputField.purchaseDate}
              onChange={(event) => handleInputChange(event, setInputField, inputField)}
            />
            {<InputErrorBox Message={inputError.purchaseDate} />}
          </div>

          <div className='d-flex flex-wrap justify-content-end align-items-center'>
            <Button
              onClick={() => {
                cancelSubmit(setInputField, initialState, setEditId, setInputError)
              }}
              type='button'
              disabled={SubmitAPIStatus?.loading}
              className='btn btn-secondary me-2 btn-sm font-weight-bold'
              style={{marginLeft: '15px'}}
            >
              Clear
            </Button>
            <Button
              type='submit'
              className='btn btn-primary btn-sm font-weight-bold h-10'
              loading={SubmitAPIStatus.loading}
              onClick={() => {
                handleSubmit(
                  inputField,
                  // getAsset,
                  setAsset,
                  setEditId,
                  initialState,
                  setInputField,
                  editId,
                  cancelSubmit,
                  setAPIStatus,
                  setInputError,
                  validateForm,
                  setSubmitAPIStatus,
                  setIsAddUserModalOpen,
                  fetchAssets,
                  setIsCommentPopupOpen
                )
              }}
            >
              {editId !== null ? 'Update' : 'Save'}
            </Button>
          </div>
        </div>
      </Modal>
      {/* //end of the form */}
      <Modal
        show={modalOpen}
        onClose={() => setModalOpen(false)}
        animation={false}
        backdrop='static'
        centered
      >
        {/* begin::modal header--add holiday */}
        <div className='modal-header'>
          <h2 className='fw-bolder'>Assignee Asset</h2>
          <div
            className='btn btn-icon btn-sm btn-active-icon-danger'
            data-kt-users-modal-action='close'
            style={{cursor: 'pointer'}}
            onClick={() => {
              handleClose()
              setInputError('')
              setEditId(null)
              cancelFormSubmit(setFormField, formFieldState, setEditId, setInputError)
            }}
          >
            <i className='fa-solid fa-xmark fs-1' />
          </div>
        </div>
        <div className='m-3'>
          <div className='fv-row mb-5 col-md-12'>
            <label className='fs-6 fw-semibold mb-2'>User Name</label>
            <span className='text-danger ms-1 fs-4 fw-bold'>*</span>

            <Select2
              name='userName'
              data={sortedUserNames}
              value={formField.user}
              onChange={(selectedOption: string) => {
                console.log('Selected Option:', selectedOption)
                setFormField((prevFormField) => ({
                  ...prevFormField,
                  user: selectedOption,
                }))
              }}
            />

            {<InputErrorBox Message={inputError.user} />}
          </div>
          <div className='fv-row mb-5 col-md-12'>
            <label className='required fw-bold fs-6 mb-2'>Onsite/Remote</label>
            <div className='d-flex mt-2'>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='radio'
                  id='onsite'
                  name='locationType'
                  value='onsite'
                  checked={formField.locationType === 'onsite'}
                  onChange={(event) => {
                    handleFormFieldChange(event, setFormField, formField)
                  }}
                />
                <label htmlFor='onsite' className='fw-bold text-gray-800 d-block me-3'>
                  Onsite
                </label>
              </div>

              <div className='form-check' style={{paddingRight: '20px'}}>
                <input
                  className='form-check-input'
                  type='radio'
                  name='locationType'
                  placeholder='radio1'
                  value='remote'
                  id='remote'
                  checked={formField.locationType === 'remote'}
                  onChange={(event) => {
                    handleFormFieldChange(event, setFormField, formField)
                  }}
                />

                <label htmlFor='remote' className=' fw-bold text-gray-800 d-block'>
                  Remote
                </label>
              </div>
            </div>
            <InputErrorBox Message={inputError.locationType} />
          </div>
          <div className='fv-row mb-5 col-md-12'>
            <label className='fs-6 fw-semibold mb-2'>Location</label>
            <span className='text-danger ms-1 fs-4 fw-bold'>*</span>

            <input
              className='form-control'
              type='text'
              placeholder='Location'
              name='location'
              value={formField.location}
              onChange={(event) => handleFormFieldChange(event, setFormField, formField)}
            />
            {<InputErrorBox Message={inputError.location} />}
          </div>
          <div className='fv-row mb-5 col-md-12'>
            <label className='fs-6 fw-semibold mb-2'>Issue Date</label>
            <span className='text-danger ms-1 fs-4 fw-bold'>*</span>

            <input
              className='form-control'
              type='date'
              name='issueDate'
              value={formField.issueDate}
              onChange={(event) => handleFormFieldChange(event, setFormField, formField)}
            />
            {<InputErrorBox Message={inputError.issueDate} />}
          </div>

          <div className='d-flex flex-wrap justify-content-end align-items-center'>
            <Button
              onClick={() => {
                cancelFormSubmit(setFormField, formFieldState, setEditId, setInputError)
              }}
              type='button'
              className='btn btn-secondary me-2 btn-sm font-weight-bold'
              style={{marginLeft: '15px'}}
            >
              Clear
            </Button>
            <Button
              loaderPosition='center'
              type='submit'
              className='btn btn-primary btn-sm font-weight-bold h-10'
              loading={SubmitAPIStatus.loading}
              onClick={() => {
                handleFormFieldSubmit(
                  formField,
                  // getAsset,
                  setAsset,
                  setEditId,
                  formFieldState,
                  setFormField,
                  editId,
                  cancelSubmit,
                  setAPIStatus,
                  setInputError,
                  validateFormField,
                  setSubmitAPIStatus,
                  setModalOpen,
                  fetchAssets,
                  setUserAsset
                )
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        show={returnDateModal}
        onClose={() => setReturnDateModal(false)}
        animation={false}
        backdrop='static'
        centered
      >
        {/* begin::modal header--add holiday */}
        <div className='modal-header'>
          <h2 className='fw-bolder'>Return Asset</h2>
          <div
            className='btn btn-icon btn-sm btn-active-icon-danger'
            data-kt-users-modal-action='close'
            style={{cursor: 'pointer'}}
            onClick={() => {
              handleClose()
              setInputError('')
              setEditId(null)
              cancelSubmit(setInputField, initialState, setEditId, setInputError)
            }}
          >
            <i className='fa-solid fa-xmark fs-1' />
          </div>
        </div>
        <div className='m-3'>
          <div className='fv-row mb-5 col-md-12'>
            <label className='fs-6 fw-semibold mb-2'>Return Date</label>
            <span className='text-danger ms-1 fs-4 fw-bold'>*</span>

            <input
              className='form-control'
              type='date'
              name='returnDate'
              value={inputField.returnDate}
              onChange={(event) => handleInputChange(event, setInputField, inputField)}
            />
            {<InputErrorBox Message={inputError.returnDate} />}
          </div>

          <div className='d-flex flex-wrap justify-content-end align-items-center'>
            <Button
              onClick={() => {
                cancelSubmit(setInputField, initialState, setEditId, setInputError)
              }}
              type='button'
              className='btn btn-secondary me-2 btn-sm font-weight-bold'
              style={{marginLeft: '15px'}}
            >
              Clear
            </Button>
            <Button
              loaderPosition='center'
              type='submit'
              className='btn btn-primary btn-sm font-weight-bold h-10'
              loading={SubmitAPIStatus.loading}
              onClick={() => {
                if (!SubmitAPIStatus.loading) {
                  handleReturnSubmit(
                    inputField,
                    setUserAsset,
                    setEditId,
                    initialState,
                    setInputField,
                    editId,
                    cancelSubmit,
                    setAPIStatus,
                    setInputError,
                    validateReturnForm,
                    setSubmitAPIStatus,
                    fetchAssets,
                    setReturnDateModal
                  )
                }
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-12'>
          {/* begin:: card */}
          <div className='card card-xl-stretch mb-xl-8'>
            {/* begin:: card header */}
            <div className='card-header'>
              <div className='card-title'>Asset Details</div>
              <div
                className='d-flex mt-4 justify-content-end'
                data-kt-user-table-toolbar='base'
                style={{height: '3rem'}}
              >
                {/* Begin::Add User button */}

                <button
                  type='button'
                  className='btn btn-sm btn-primary'
                  onClick={() => {
                    setExpandedRows([])
                    setIsAddUserModalOpen(true)
                    resetInputFields()
                  }}
                  hidden={!checkRole(['Asset Management System'], roleState.features, 'edit')}
                >
                  Add Asset
                </button>

                {/* end::Add user button */}
              </div>
            </div>
            {/* begin:: card body */}
            <div className='card-body'>
              <div className='table-responsive  '>
                <table
                  className='table 
                    table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-bordered'
                >
                  <thead>
                    <tr>
                      <th className='pl-1 text-center text-muted' style={{minWidth: '50px'}}>
                        {changeTextCapital('Sr.No')}
                      </th>

                      <th className='pl-1 text-center text-muted' style={{minWidth: '80px'}}>
                        {changeTextCapital('Asset Tag')}
                      </th>
                      <th className='pl-1 text-center text-muted' style={{minWidth: '150px'}}>
                        {changeTextCapital('Serial No')}
                      </th>
                      <th className='pl-1 text-center text-muted' style={{minWidth: '150px'}}>
                        {changeTextCapital('Location')}
                      </th>

                      <th className='pl-1 text-center text-muted' style={{minWidth: '80px'}}>
                        {changeTextCapital('Status')}
                      </th>

                      <th className='pl-1 text-center text-muted' style={{minWidth: '100px'}}>
                        {changeTextCapital('Assigned')}
                      </th>

                      <th
                        className='pl-1 text-center text-muted'
                        hidden={!checkRole(['Asset Management System'], roleState.features, 'edit')}
                        style={{minWidth: '80px'}}
                      >
                        {changeTextCapital('Action')}
                      </th>
                    </tr>
                  </thead>
                  {asset?.length > 0 ? (
                    <tbody>
                      {asset?.map((obj: any, index: number) => (
                        <>
                          <tr
                            className={`${editId === obj?.id ?? '-' ? `${EDIT_ROW_COLORS} ` : ''}`}
                            key={obj?.id ?? '-'}
                          >
                            <td className='text-center'>
                              <td>
                                <div className='text-center'>
                                  <i
                                    className={`fas fa-chevron-${
                                      showRow.includes(index) ? 'up' : 'down'
                                    }`}
                                    style={{
                                      marginLeft: '2rem',
                                      marginRight: '3px',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                      checkArrayElement(index)
                                    }}
                                  />
                                  <span> {'   '}</span>
                                  {index + 1}
                                </div>
                              </td>
                            </td>
                            <td className='text-center'>{obj?.tag ?? '-'}</td>
                            <td
                              className='text-center'
                              style={{
                                maxWidth: '200px',
                                whiteSpace: 'pre-wrap',
                                wordWrap: 'break-word',
                              }}
                            >
                              {obj?.serialNo ?? '-'}
                            </td>
                            <td
                              className='text-center'
                              style={{
                                maxWidth: '300px',
                                whiteSpace: 'pre-wrap',
                                wordWrap: 'break-word',
                              }}
                            >
                              {`${
                                obj?.locationType?.charAt(0).toUpperCase() +
                                  obj?.locationType?.slice(1) ?? '-'
                              } - ${obj?.location ?? '-'}`}
                            </td>
                            <td className='text-center'>
                              {obj?.status ? (
                                <span
                                  className={`badge border background ${
                                    obj.status === 'Admin'
                                      ? 'border-success text-success'
                                      : 'border-primary text-primary'
                                  } d-inline-block`}
                                >
                                  {obj.status}
                                </span>
                              ) : (
                                '-'
                              )}
                            </td>

                            <td className='text-center'>
                              {obj?.userAssets && obj?.userAssets.length > 0
                                ? obj?.userAssets?.map(
                                    (asset: any) =>
                                      `${asset?.user?.firstName ?? '-'} ${
                                        asset?.user?.lastName ?? '-'
                                      }`
                                  )
                                : '-'}
                            </td>
                            <td
                              hidden={
                                !checkRole(['Asset Management System'], roleState.features, 'edit')
                              }
                            >
                              {obj?.userAssets && obj?.status === 'Assigned' ? (
                                <td>
                                  <div className='text-center'>
                                    <button
                                      className='btn btn-icon btn-light-primary btn-sm me-1'
                                      onClick={() => {
                                        setReturnDateModal(true)
                                        const userAssetId = obj?.userAssets?.[0]?.id
                                        getUserAssetById(
                                          userAssetId,
                                          inputField,
                                          setInputField,
                                          setEditId,
                                          setInputError
                                        )
                                        setEditId(userAssetId)
                                      }}
                                      hidden={
                                        !checkRole(
                                          ['Asset Management System'],
                                          roleState.features,
                                          'edit'
                                        )
                                      }
                                    >
                                      <i className='far fa-edit'></i>
                                    </button>
                                  </div>
                                </td>
                              ) : (
                                <td>
                                  <div className='d-flex justify-content-center flex-shrink-0'>
                                    <button
                                      className='btn btn-icon btn-light-primary btn-sm me-1'
                                      onClick={() => {
                                        getAssetById(
                                          obj.id,
                                          inputField,
                                          setInputField,
                                          setEditId,
                                          setInputError
                                        )
                                        setEditId(obj.id)
                                        setIsAddUserModalOpen(true)
                                        setFormField(formFieldState)
                                      }}
                                      hidden={
                                        !checkRole(
                                          ['Asset Management System'],
                                          roleState.features,
                                          'edit'
                                        )
                                      }
                                    >
                                      <i className='far fa-edit'></i>
                                    </button>
                                    <button
                                      className='btn btn-icon btn-light-primary btn-sm me-1'
                                      onClick={() => {
                                        getAssetById(
                                          obj.id,
                                          inputField,
                                          setInputField,
                                          setEditId,
                                          setInputError
                                        )
                                        setEditId(obj.id)
                                        setModalOpen(true)
                                        fetchNames()
                                      }}
                                      hidden={
                                        !checkRole(
                                          ['Asset Management System'],
                                          roleState.features,
                                          'edit'
                                        )
                                      }
                                    >
                                      <i className='fa-solid fa-user-plus'></i>
                                    </button>
                                  </div>
                                </td>
                              )}
                            </td>
                          </tr>
                          <tr key={`secondIndex${index}`} hidden={!showRow.includes(index)}>
                            <td
                              className=' align-middle'
                              colSpan={8}
                              style={{paddingLeft: '3.5rem', paddingRight: '3.5rem'}}
                            >
                              <>
                                <div className='table-responsive-sm'>
                                  <table className='table table-bordered '>
                                    <thead className='border text-center '>
                                      <tr className='align-middle'>
                                        <th
                                          className='pl-1 text-center text-muted'
                                          style={{width: '200px', whiteSpace: 'pre-wrap'}}
                                        >
                                          {changeTextCapital('Category')}
                                        </th>
                                        <th
                                          className='pl-1 text-center text-muted'
                                          style={{minWidth: '100px'}}
                                        >
                                          {changeTextCapital('Sub Category')}
                                        </th>
                                        <th
                                          className='pl-1 text-center text-muted'
                                          style={{minWidth: '100px'}}
                                        >
                                          {changeTextCapital('Brand Name')}
                                        </th>
                                        <th
                                          className='pl-1 text-center text-muted'
                                          style={{width: '200px', whiteSpace: 'pre-wrap'}}
                                        >
                                          {changeTextCapital('Description')}
                                        </th>
                                        <th
                                          className='pl-1 text-center text-muted'
                                          style={{minWidth: '100px'}}
                                        >
                                          {changeTextCapital('Purchase Date')}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className='border'>
                                      {asset
                                        ?.filter((detail) => detail.id === obj.id)
                                        ?.map((detail, detailIndex) => (
                                          <tr
                                            className={`${
                                              editId === obj?.id ?? '-' ? `${EDIT_ROW_COLORS} ` : ''
                                            }`}
                                            key={`${obj?.id ?? '-'}-details`}
                                          >
                                            <td className='p-3 text-center align-middle'>
                                              {obj?.category ?? '-'}
                                            </td>
                                            <td className='p-3 text-center align-middle'>
                                              {obj?.subCategory ?? '-'}
                                            </td>
                                            <td className='p-3 text-center align-middle'>
                                              {obj?.brand ?? '-'}
                                            </td>
                                            <td className='p-3 text-center align-middle'>
                                              {obj?.description ?? '-'}
                                            </td>
                                            <td className='p-3 text-center align-middle'>
                                              {obj?.purchaseDate ?? '-'}
                                            </td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                </div>
                              </>
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan={9}>
                          <Center>No Record Available.</Center>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div hidden={!checkRole(['Asset Management System'], roleState.features, 'edit')}>
        <AssignAssets
          userAsset={userAsset}
          isModalOpen={modalOpen || isAddUserModalOpen ? true : false}
          setUserAsset={setUserAsset}
          fetchAssets={fetchAssets}
        />
      </div>
      <div>
        <CommentPopup
          isOpen={isCommentPopupOpen}
          comment={comment}
          setComment={setComment}
          setCommentError={setCommentError}
          commentError={commentError}
          onClose={handleCancelComment}
          onSubmit={handleSubmitComment}
        />
      </div>
    </>
  )
}

export default Assets
