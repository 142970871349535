import {Badge, Button, Center, Pagination, Text} from '@mantine/core'
import axios from 'axios'
import React, {useContext, useEffect, useState} from 'react'
import {Form, Modal} from 'react-bootstrap'
import {
  KTCard,
  KTCardBody,
  Message,
  changeTextCapital,
  checkRole,
  convertTo24HourFormat,
  getDate,
  getPaginationInfoData,
} from '../../../_metronic/helpers'

import {faEraser, faSearch} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {ToastContainer, toast} from 'react-toastify'
import {useThemeMode} from '../../../_metronic/partials'
import {getHolidaysByDateRange} from '../../../api/HolidayAPI'
import {API_BASE_URL, EDIT_ROW_COLORS} from '../../../utils/contants'
import roleContext from '../../context/roleContext'
import {setupAxios, useAuth} from '../../modules/auth'
import {APIResponse, RoleContextValue} from '../../types/OtherTypes'
import {formatCreateAt} from '../leave/LeaveFunctions'
import {
  getDateBeforeSevenDays,
  getDateInYYYYMMDD,
  handleClearFilters,
  validateDateRange,
} from './ams'

interface TableData {
  result: {
    id: string
    user: {
      firstName: string
      lastName: string
      reportingManager: {
        firstName: string
        lastName: string
      }
      hrManager: {
        firstName: string
        lastName: string
      }
    }
    checkIn: string
    createdAt: string
    checkOut: string
    checkInTemp: string
    checkOutTemp: string
    date: string
    workingHours: string
    workingHoursTemp: string
    formattedWorkingHoursTemp: string
    editable: boolean
    remark: string
    status: string
    leaveType: string
    leaveStatus: string
    reason: string
    remarkUpdated?: boolean
    latitude: string
    longitude: string
    outlatitude: string
    outlongitude: string
    manualAttendance: string
    actualCheckIn: string
    actualcheckOut: string
    reasons: [
      {
        reason: string
        actor: string
        updatedAt: string
        actionBy: {
          firstName: string
          lastName: string
        }
      },
    ]
  }[]
  queryData: {
    total: string
    page: string
    take: string
  }
}

const AdminAttendance = () => {
  let roleState: RoleContextValue = useContext(roleContext)
  const {mode} = useThemeMode()
  const {currentUser} = useAuth()
  let {currentDate} = getDate()
  const isAdminUser = currentUser?.isAdmin || false
  const [userNames, setUserNames] = useState<{label: string; value: string}[]>([])
  const [editId, setEditId] = useState(null)
  const handleRowClick = (id) => {
    setEditId(id)
  }
  const initialState = {
    name: '',
    employeeName: '',
    startDate: getDateBeforeSevenDays(),
    endDate: currentDate,
    checkIn: '',
    checkOut: '',
    workingHours: '',
    remark: '',
    status: '',
    leaveType: '',
    leaveStatus: '',
    reason: '',
    latitude: '',
    longitude: '',
    outlatitude: '',
    outlongitude: '',
  }
  const [inputField, setInputField] = useState(initialState)
  const [holidayData, setHolidayData] = useState<
    {
      date: string
      holidayDay: null | string
      holidayName: string
    }[]
  >([])
  const [nameError, setNameError] = useState('')
  const [dateError, setDateError] = useState('')
  const [totalEntries, setTotalEntries] = useState(0)
  const [lessDateError, setLessDateError] = useState('')
  const [checkInDateError, setCheckInDateError] = useState('')
  const [checkInError, setCheckInError] = useState('')
  const [reasonError, setReasonError] = useState('')
  const [ReasonShowModal, setReasonShowModal] = useState(false)
  const [reasonForHr, setReasonForHr] = useState(false)
  const [selectedItemId, setSelectedItemId] = useState('')
  const [selectedReasonId, setSelectedReasonId] = useState('')
  const [tableData, setTableData] = useState<TableData[]>([])
  const [isCreateAttendanceModalOpen, setIsCreateAttendanceModalOpen] = useState(false)
  const [editingId, setEditingId] = useState<string | null>(null)
  const [pageSize, setPageSize] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [showRow, setShowRow] = useState<number[]>([])

  const [SubmitAPIStatus, setSubmitAPIStatus] = useState({
    loading: false,
  })

  const checkArrayElement = (element: number) => {
    setShowRow((prevShowRow) => {
      const updatedShowRow = [...prevShowRow]
      const index = updatedShowRow.indexOf(element)
      if (index !== -1) {
        updatedShowRow.splice(index, 1)
      } else {
        updatedShowRow.push(element)
      }
      return updatedShowRow
    })
  }
  const truncateString = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...'
    }
    return text
  }
  const handleTrashClick = () => {
    setInputField(initialState)
    setReasonError('')
  }

  const [activePage, setActivePage] = useState(1)
  const [noMatchingRecords, setNoMatchingRecords] = useState(false)

  const fetchNames = () => {
    const {token, tenantId} = setupAxios()
    axios
      .get(API_BASE_URL + `/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-tenant-Id': `${tenantId}`,
        },
      })
      .then((response: any) => {
        let userData: {label: string; value: string}[] = []
        response.data.data.map((obj: {firstName: string; lastName: string; id: string}) => {
          userData.push({
            label: obj.firstName + ' ' + obj.lastName,
            value: obj.id,
          })
        })
        setUserNames(userData)
      })
      .catch((error) => {
        console.error('Error fetching names:', error)
      })
  }
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target

    if (name === 'checkIn' || name === 'checkOut') {
      const isValidDateTimeFormat = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2})$/.test(value)
      if (!isValidDateTimeFormat) {
        console.error(`Invalid datetime format for ${name}: ${value}`)
        return
      }
    }
    setInputField((prevInputField) => ({
      ...prevInputField,
      [name]: value,
    }))
    if (name === 'name') {
      setNameError('')
    }
    if (name === 'checkIn') {
      setCheckInError('')
    }
  }
  const fetchTableData = async (page, take = 10) => {
    const {token, tenantId} = setupAxios()
    getHolidaysByDateRange(
      inputField.startDate && inputField.endDate ? inputField : {startDate: '', endDate: ''}
    ).then((res: APIResponse) => {
      if (res.statusCode) {
        setHolidayData(res.data)
      } else {
        setHolidayData([])
      }
    })
    await fetch(API_BASE_URL + `/attendence?page=${page}&take=${take}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'X-tenant-Id': `${tenantId}`,
      },
      body: JSON.stringify({
        tab: 'Self',
        id: currentUser?.id,
        startDate: inputField.startDate ? inputField.startDate + 'T00:00:00' : null,
        endDate: inputField.endDate ? inputField.endDate + 'T23:59:59' : null, // Ensure endDate is null if empty
      }),
    })
      .then(async (response) => {
        let res = await response.json()
        if (res.statusCode === 200) {
          // setTableData([res.data])
          setTableData([
            {
              result: res?.data?.result ?? res?.data,
              queryData: res?.data?.queryParams ?? null,
            },
          ])
          console.log('response', res.length, res.data)
          setDateError('')
          setNameError('')
          setCheckInError('')
          setNoMatchingRecords(false)
          setTotalEntries(res.data.queryParams.total)
        } else {
          setTableData([])
          setNoMatchingRecords(true)
          setTotalEntries(0)
          Message(res?.message, 'error')
        }
      })
      .catch((error) => {
        console.error('Error fetching table data:', error)
      })
  }

  useEffect(() => {
    let mounted = true
    if (mounted) {
      fetchNames()

      fetchTableData(activePage)
    }
    return () => {
      mounted = false
    }
  }, [])

  const handleEdit = (id: string) => {
    const updatedData = tableData.map((data) => {
      const updatedResult = data.result.map((item) => ({
        ...item,
        editable: item.id === id ? !item.editable : false,
      }))

      return {
        ...data,
        result: updatedResult,
      }
    })

    setTableData(updatedData)
  }

  const handleEditSave = async (id: any, editedItem: (typeof tableData)[0]['result'][0]) => {
    if (!inputField.reason.trim()) {
      setReasonError('Please enter a Reason.')
      return
    } else if (inputField.reason && inputField.reason.length > 250) {
      setReasonError('Reason must not exceed 250 characters.')
    } else {
      setReasonError('')
    }
    const itemToUpdate = editedItem
    if (!itemToUpdate) {
      console.error('Attendance not found for ID:', id)
      return
    }
    const checkInTime = itemToUpdate.checkIn ? convertTo24HourFormat(itemToUpdate.checkIn) : null
    const checkOutTime = itemToUpdate.checkOut ? convertTo24HourFormat(itemToUpdate.checkOut) : null
    if (checkInTime !== null || checkOutTime !== null) {
      const filterData = {
        id: itemToUpdate.id,
        checkIn: checkInTime,
        checkOut: checkOutTime,
        reason: inputField.reason,
        actor: 'Employee',
        actionBy: {id: currentUser?.id},
      }
      const {token, tenantId} = setupAxios()
      try {
        setSubmitAPIStatus((prev: any) => ({...prev, loading: true}))
        const response = await axios.patch(
          API_BASE_URL + `/userAttendence/${currentUser?.id}/update`,
          filterData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
              'X-tenant-Id': `${tenantId}`,
            },
          }
        )

        if (response.data.statusCode === 200) {
          const updatedData = {
            workingHours: response.data.data.workingHours,
            editable: false,
          }
          setIsCreateAttendanceModalOpen(false)
          setReasonError('')
          setInputField(initialState)
          toast.success(response.data.message)
          updateAttendanceById(id, updatedData)
          fetchTableData(activePage)
          setEditingId(null)
        } else {
          Message(response.data.message, 'error')
        }
        setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
      } catch (error) {
        console.error('Error updating attendance:', error)
      }
    } else {
      Message('Check-in time cannot be null.', 'error')
    }
  }

  const updateAttendanceById = async (id, updatedData) => {
    const updatedTableData = [...tableData]
    const resultIndex = updatedTableData.findIndex((data) =>
      data.result.some((item) => item.id === id)
    )

    if (resultIndex !== -1) {
      const itemIndex = updatedTableData[resultIndex].result.findIndex((item) => item.id === id)

      if (itemIndex !== -1) {
        updatedTableData[resultIndex].result[itemIndex] = {
          ...updatedTableData[resultIndex].result[itemIndex],
          ...updatedData,
        }

        setTableData(updatedTableData)
      }
    }
  }

  const handleOpenMapInNewTab = (latitude, longitude) => {
    const url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`
    window.open(url, '_blank')
  }

  const handleCreateAttendanceModalCLose = () => {
    handleTrashClick()
    setIsCreateAttendanceModalOpen(false)
    setEditingId(null)
  }

  return (
    <>
      {/* <Toolbar1 /> */}
      <ToastContainer position={'bottom-center'} />

      {/* modal for show the reason */}
      <Modal
        show={ReasonShowModal}
        className='modal-lg'
        onClose={() => setReasonShowModal(false)}
        animation={false}
        backdrop='static'
        centered
      >
        <div className='modal-header'>
          <h2 className='fw-bolder'>Reasons</h2>
          <div
            className='btn btn-icon btn-sm btn-active-icon-danger'
            data-kt-users-modal-action='close'
            style={{cursor: 'pointer'}}
            onClick={() => {
              setReasonShowModal(false)
            }}
          >
            <i className='fa-solid fa-xmark fs-1' />
          </div>
        </div>
        <div className='card-body'>
          <div className='table-responsive'>
            <table
              className='table ms-5 me-5 mt-5
                table-row-dashed table-row-gray-300 '
            >
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='pl-1 flex-start text-muted' style={{width: '30px'}}>
                    {changeTextCapital('Actor')}
                  </th>
                  <th className='pl-1 flex-start text-muted' style={{width: '100px'}}>
                    {changeTextCapital('Reason')}
                  </th>
                  <th className='pl-1 text-start text-muted' style={{width: '30px'}}>
                    {changeTextCapital('Action By')}
                  </th>
                </tr>
              </thead>

              <tbody className='text-gray-800 fw-semibold text-left'>
                {selectedReasonId && (
                  <>
                    {tableData[0]?.result
                      ?.find((record) => record.id === selectedReasonId)
                      ?.reasons?.map((reason, index) => (
                        <tr key={index}>
                          <td className='flex-start'>{reason.actor ?? '-'}</td>
                          <td className='flex-start'>{reason.reason ?? '-'}</td>
                          <td className='text-start'>
                            {reason.actionBy?.firstName ?? '-'} {reason.actionBy?.lastName ?? '-'}
                            <div className='text-muted'>
                              {formatCreateAt(reason.updatedAt ?? '-')}
                            </div>
                          </td>
                        </tr>
                      ))}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
      {/* modal to take a input reason */}
      <Modal show={isCreateAttendanceModalOpen} animation={false} backdrop='static' centered>
        <div className='modal-header'>
          <h3 className='modal-title'>Attendance Details</h3>
          <div
            className='btn btn-icon btn-sm btn-active-icon-danger'
            data-bs-placement='top'
            title='Close'
            style={{cursor: 'pointer'}}
            onClick={() => {
              handleCreateAttendanceModalCLose()
            }}
          >
            <i className='fa-solid fa-xmark fs-1' />
          </div>
        </div>
        <Modal.Body>
          <Form>
            <Form.Group className='mb-3 d-flex align-items-center'>
              <div className='me-20'>
                <Form.Label>Reporting Manager</Form.Label>
                <div>
                  <span>{tableData[0]?.result[0]?.user?.reportingManager?.firstName ?? '-'}</span>
                  <span className='ms-1'>
                    {tableData[0]?.result[0]?.user?.reportingManager?.lastName ?? '-'}
                  </span>
                </div>
              </div>
              <div className='me-20'>
                <Form.Label>Hr Manager</Form.Label>
                <div>
                  <span>{tableData[0]?.result[0]?.user?.hrManager?.firstName ?? '-'}</span>
                  <span className='ms-1'>
                    {tableData[0]?.result[0]?.user?.hrManager?.lastName ?? '-'}
                  </span>
                </div>
              </div>
            </Form.Group>

            <Form.Label className='required'>Reason</Form.Label>
            <Form.Group className=''>
              <input
                style={{padding: '7px'}}
                className='form-control'
                type='text'
                value={inputField.reason}
                name='reason'
                onChange={handleInputChange}
              />
              <span className='text-danger'>{reasonError}</span>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className='btn btn-secondary me-2 btn-sm font-weight-bold'
            onClick={handleTrashClick}
          >
            Clear
          </Button>
          <Button
            className='btn btn-primary me-2 btn-sm font-weight-bold'
            loading={SubmitAPIStatus.loading}
            onClick={() => {
              const editedItem = tableData
                .flatMap((data) => data.result)
                .find((item) => item.id === editingId)
              if (editedItem) {
                handleEditSave(editingId, editedItem)
                console.error('Edited item not found for ID:', editingId)
              }
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <div
        className='card my-3'
        hidden={holidayData?.every(
          (holiday) =>
            !tableData[0]?.result?.some(
              (record) => getDateInYYYYMMDD(holiday.date) === getDateInYYYYMMDD(record.createdAt)
            )
        )}
      >
        <div className='card-body py-2'>
          <span className='fs-4 me-2'>Holidays:</span>
          {holidayData.map((holiday) => {
            return (
              <Badge
                className='me-1 fs-7 mb-1'
                color='red'
                size='xl'
                hidden={tableData[0]?.result?.every(
                  (application) =>
                    getDateInYYYYMMDD(holiday.date) !== getDateInYYYYMMDD(application.createdAt)
                )}
              >
                {holiday.date} : {holiday.holidayName}
              </Badge>
            )
          })}
        </div>
      </div>
      <KTCard>
        <>
          <div className='card-header'>
            <div className='col-12'>
              <div className='d-flex my-5'>
                <div className='row'>
                  <div className='col-md-3 me-10'>
                    <div className='row'>
                      <div className='col-12 mb-2'>
                        <input
                          style={{
                            padding: '7px',
                            width: '10.5rem',
                          }}
                          className='form-control'
                          type={inputField?.startDate ? 'date' : 'text'}
                          value={inputField?.startDate}
                          name='startDate'
                          max={currentDate}
                          placeholder='Select Start Date'
                          onChange={(e) => {
                            handleInputChange(e)
                            setDateError('')
                            setLessDateError('')
                          }}
                          onFocus={(e) => {
                            if (!inputField?.startDate) {
                              e.target.type = 'date'
                            }
                          }}
                          onBlur={(e) => {
                            if (!e.target.value) {
                              e.target.type = 'text'
                            }
                          }}
                        />
                      </div>
                    </div>
                    {dateError && <div className='text-danger'>{dateError}</div>}
                  </div>
                  <div className='col-md-3 me-10'>
                    <div className='row'>
                      <div className='fv-row '>
                        {/* <label className='fs-6 fw-semibold mb-2'>End Date</label> */}
                      </div>
                      <div className='col-12 mb-2'>
                        <input
                          style={{padding: '7px', width: '10.5rem'}}
                          className='form-control'
                          type={inputField?.endDate ? 'date' : 'text'}
                          value={inputField?.endDate}
                          name='endDate'
                          max={currentDate}
                          placeholder='Select End Date'
                          onChange={(e) => {
                            handleInputChange(e)
                            setLessDateError('')
                          }}
                          onFocus={(e) => {
                            if (!inputField?.endDate) {
                              e.target.type = 'date'
                            }
                          }}
                          onBlur={(e) => {
                            if (!e.target.value) {
                              e.target.type = 'text'
                            }
                          }}
                        />
                        {lessDateError && <div className='text-danger'>{lessDateError}</div>}
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4 ms-md-2 mt-1'>
                    <Button
                      onClick={() => {
                        console.log(
                          'validate date range',
                          validateDateRange(inputField, setDateError, setLessDateError)
                        )
                        if (validateDateRange(inputField, setDateError, setLessDateError)) {
                          fetchTableData(1)
                          setActivePage(1)
                        }
                      }}
                      className='btn btn-primary me-2 btn-sm font-weight-bold'
                      // style={{height: '33px', width: '80px'}}
                    >
                      <FontAwesomeIcon icon={faSearch} />
                    </Button>{' '}
                    <Button
                      variant='filled'
                      color='rgba(214, 214, 214, 1)'
                      className='btn btn-secondary me-2 btn-sm font-weight-bold'
                      // style={{height: '33px', width: '80px'}}
                      onClick={() => {
                        setActivePage(1)
                        handleClearFilters(
                          setInputField,
                          initialState,
                          setLessDateError,
                          currentUser,
                          setTableData,
                          setDateError,
                          setNameError,
                          setCheckInError,
                          setNoMatchingRecords,
                          'Self',
                          setHolidayData
                        )
                      }}
                    >
                      <FontAwesomeIcon icon={faEraser} />
                    </Button>{' '}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
        {/* )} */}

        <KTCardBody className='py-4'>
          <div className='table-responsive'>
            <table
              id='kt_table_users'
              key={tableData.length}
              className='table align-middle table-row-dashed fs-6 gy-3 dataTable'
            >
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className=''></th>
                  <th className='' style={{width: '160px'}}>
                    {changeTextCapital('Date')}
                  </th>
                  <th className='' style={{width: '230px'}}>
                    {changeTextCapital('Check In')}
                  </th>
                  <th className='' style={{width: '230px'}}>
                    {changeTextCapital('Check Out')}
                  </th>
                  <th className='' style={{width: '200px'}}>
                    {changeTextCapital('Working Hours')}
                  </th>
                  <th className='' style={{width: '100px'}}>
                    {changeTextCapital('Reason')}
                  </th>
                  <th className='' style={{width: '100px'}}>
                    {changeTextCapital('Status')}
                  </th>
                  <th
                    className='text-center'
                    hidden={
                      !checkRole(['Attendance Management System'], roleState.features, 'edit')
                    }
                    style={{width: '150px'}}
                  >
                    {changeTextCapital('Action')}
                  </th>
                </tr>
              </thead>
              {tableData[0]?.result?.length > 0 ? (
                <tbody>
                  {noMatchingRecords && (
                    <tr className=''>
                      <td colSpan={7}>
                        <Center>
                          <div
                            className='alert alert-secondary my-0  '
                            role='alert'
                            style={{
                              textAlign: 'center',
                              color: mode === 'light' ? 'black' : 'white',
                            }}
                          >
                            No matching records found
                          </div>
                        </Center>
                      </td>
                    </tr>
                  )}

                  {tableData[0].result.map((item, index) => (
                    <>
                      <tr
                        key={index}
                        className={
                          holidayData.some(
                            (holiday) =>
                              getDateInYYYYMMDD(holiday.date) === getDateInYYYYMMDD(item.createdAt)
                          )
                            ? 'text-danger'
                            : ''
                        }
                      >
                        {item?.remark === 'pending' || item?.remark === 'review' ? (
                          <td>
                            <div>
                              <span
                                className={`badge badge-light-primary cursor-pointer ${showRow.includes(
                                  index
                                )}`}
                                onClick={() => {
                                  checkArrayElement(index)
                                }}
                              >
                                {showRow.includes(index) ? '↑' : '↓'}
                              </span>
                            </div>
                          </td>
                        ) : (
                          <td></td>
                        )}

                        {/* {isAdminUser && (
                          <td className=''>
                            {item?.user?.firstName ?? '-'} {item?.user?.lastName ?? '-'}
                          </td>
                        )} */}

                        <td className='' style={{width: '175px'}}>
                          {' '}
                          {item?.date ?? '-'}{' '}
                        </td>

                        <td className=''>
                          {!item?.editable && (
                            <>
                              {item?.latitude !== null && item?.longitude !== null ? (
                                <button
                                  type='button'
                                  className='btn btn-icon btn-light-primary btn-sm me-3'
                                  onClick={() =>
                                    handleOpenMapInNewTab(item?.latitude, item?.longitude)
                                  }
                                >
                                  <i className='fas fa-location-dot'></i>
                                </button>
                              ) : item.manualAttendance ? (
                                ''
                              ) : (
                                <button
                                  type='button'
                                  className='btn btn-icon btn-light-primary btn-sm me-3'
                                  disabled
                                >
                                  <i className='fas fa-location-dot' style={{opacity: 0.5}}></i>
                                </button>
                              )}
                            </>
                          )}

                          {item?.editable ? (
                            <input
                              className='form-control'
                              type='time'
                              value={convertTo24HourFormat(item?.checkIn)}
                              onChange={(e) => {
                                const updatedData = [...tableData]
                                const resultIndex = updatedData[0].result.findIndex(
                                  (resultItem) => {
                                    return resultItem?.id === item?.id
                                  }
                                )

                                if (resultIndex !== -1) {
                                  const updatedItem = {
                                    ...item,
                                    checkIn: e.target.value,
                                  }
                                  updatedData[0].result[resultIndex] = updatedItem
                                  setTableData(updatedData)
                                }
                              }}
                            />
                          ) : item?.manualAttendance ? (
                            '-'
                          ) : item?.checkIn === '' ? (
                            '-'
                          ) : (
                            item?.checkIn
                          )}
                        </td>

                        <td className=''>
                          {!item?.editable && (
                            <>
                              {item?.outlatitude !== null && item?.outlongitude !== null ? (
                                <button
                                  type='button'
                                  className='btn btn-icon btn-light-warning btn-sm me-3'
                                  onClick={() =>
                                    handleOpenMapInNewTab(item?.outlatitude, item?.outlongitude)
                                  }
                                >
                                  <i className='fas fa-location-dot'></i>
                                </button>
                              ) : item?.manualAttendance ? (
                                ''
                              ) : (
                                <button
                                  type='button'
                                  className='btn btn-icon btn-light-warning btn-sm me-3'
                                  disabled
                                >
                                  <i className='fas fa-location-dot' style={{opacity: 0.5}}></i>
                                </button>
                              )}
                            </>
                          )}

                          {item?.editable ? (
                            <input
                              className='form-control'
                              type='time'
                              value={convertTo24HourFormat(item?.checkOut)}
                              onChange={(e) => {
                                const updatedData = [...tableData]
                                const resultIndex = updatedData[0].result.findIndex(
                                  (resultItem) => {
                                    return resultItem?.id === item?.id
                                  }
                                )

                                if (resultIndex !== -1) {
                                  const updatedItem = {
                                    ...item,
                                    checkOut: e.target.value,
                                  }
                                  updatedData[0].result[resultIndex] = updatedItem
                                  setTableData(updatedData)
                                }
                              }}
                            />
                          ) : item.manualAttendance ? (
                            '-'
                          ) : item?.checkOut === '' ? (
                            '-'
                          ) : (
                            item?.checkOut
                          )}
                        </td>

                        <td className=''>{item?.workingHours ?? '-'}</td>
                        <td
                          className='text-start cursor-pointer'
                          onClick={() => {
                            setSelectedReasonId(item?.id)
                            setReasonShowModal(true)
                          }}
                        >
                          {truncateString(item?.reason ?? '-', 10)}
                        </td>

                        <td className='' style={{width: '00px', whiteSpace: 'pre-wrap'}}>
                          {item.remark ? (
                            <span
                              className={`badge ${
                                item?.remark === 'accept' ||
                                item?.remark === 'auto_approved_by_rm' ||
                                item?.remark === 'auto_approved_by_hr'
                                  ? 'badge-light-success'
                                  : item?.remark === 'pending'
                                    ? 'badge-light-warning'
                                    : item?.remark === 'reject'
                                      ? 'badge-light-danger'
                                      : item?.remark === 'Changed By Admin'
                                        ? 'bg-secondary'
                                        : item?.remark === 'review'
                                          ? 'badge-light-warning'
                                          : ''
                              }`}
                            >
                              {item?.remark === 'accept'
                                ? 'Approved'
                                : item?.remark === 'pending'
                                  ? 'Pending'
                                  : item?.remark === 'reject'
                                    ? 'Declined'
                                    : item?.remark === 'Changed By Admin'
                                      ? 'By Admin'
                                      : item?.remark === 'review'
                                        ? 'Pending With HR'
                                        : item?.remark === 'auto_approved_by_rm'
                                          ? 'Auto Approved RM'
                                          : item?.remark === 'auto_approved_by_hr'
                                            ? 'Auto Approved HR'
                                            : '-'}
                            </span>
                          ) : (
                            '-'
                          )}
                        </td>

                        {item.remark === 'pending' || item.remark === 'review' ? (
                          <td
                            className='text-center'
                            hidden={
                              !checkRole(
                                ['Attendance Management System'],
                                roleState.features,
                                'edit'
                              )
                            }
                          >
                            <button
                              className='btn btn-icon btn-secondary btn-sm me-1 disabled'
                              onClick={() => handleEdit(item?.id.toString())}
                            >
                              <i className='far fa-edit'></i>
                            </button>
                          </td>
                        ) : (
                          <td className='text-center'>
                            {item?.editable || editingId === item?.id ? (
                              <>
                                <button
                                  className='btn btn-icon btn-light-success btn-sm me-1'
                                  onClick={() => {
                                    setIsCreateAttendanceModalOpen(true)
                                    setEditingId(item?.id)
                                  }}
                                >
                                  <i className='far fa-save'></i>
                                </button>
                                <Button
                                  className='btn btn-icon btn-light-danger btn-sm me-1'
                                  style={{marginLeft: '15px', height: '35px'}}
                                  onClick={() => {
                                    if (item?.editable) {
                                      handleEdit(item?.id.toString())
                                      fetchTableData(activePage)
                                    } else {
                                      const originalItem = tableData[index]?.result.find(
                                        (i) => i?.id === item?.id
                                      )
                                      const currentItem = item
                                      if (
                                        originalItem?.checkIn !== currentItem?.checkIn ||
                                        originalItem?.checkOut !== currentItem?.checkOut ||
                                        originalItem?.remark !== currentItem?.remark
                                      ) {
                                        handleEdit(item?.id.toString())
                                      }
                                    }
                                  }}
                                >
                                  <i className='fas fa-xmark'></i>
                                </Button>
                              </>
                            ) : (
                              <button
                                hidden={
                                  !checkRole(
                                    ['Attendance Management System'],
                                    roleState.features,
                                    'edit'
                                  )
                                }
                                className='btn btn-icon btn-light-primary btn-sm me-1'
                                onClick={() => handleEdit(item?.id.toString())}
                              >
                                <i className='far fa-edit'></i>
                              </button>
                            )}
                          </td>
                        )}
                      </tr>

                      <tr key={`secondIndex${index}`} hidden={!showRow.includes(index)}>
                        <td colSpan={5}>
                          <>
                            <div className='table-responsive'>
                              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                <tbody>
                                  {tableData[0].result
                                    .filter((detail) => detail.id === item.id)
                                    .map((detail, index) => (
                                      <>
                                        <tr
                                          className={`${
                                            editId === item?.id ?? '-' ? `${EDIT_ROW_COLORS} ` : ''
                                          }`}
                                          key={`${item?.id ?? '-'}-details`}
                                        >
                                          <td style={{width: '35px'}}></td>
                                          <td
                                            className='text-muted fw-bold'
                                            style={{width: '150px'}}
                                          >
                                            {changeTextCapital('Previous Record')}
                                          </td>
                                          {item?.manualAttendance ? (
                                            <td>
                                              <div className='text-center'>-</div>
                                            </td>
                                          ) : (
                                            <td style={{width: '175px'}}>
                                              <>
                                                {item?.latitude !== null &&
                                                item?.longitude !== null ? (
                                                  <button
                                                    type='button'
                                                    className='btn btn-icon btn-light-primary btn-sm me-3'
                                                    onClick={() =>
                                                      handleOpenMapInNewTab(
                                                        item?.latitude,
                                                        item?.longitude
                                                      )
                                                    }
                                                  >
                                                    <i className='fas fa-location-dot'></i>
                                                  </button>
                                                ) : (
                                                  <button
                                                    type='button'
                                                    className='btn btn-icon btn-light-primary btn-sm me-3'
                                                    disabled
                                                  >
                                                    <i
                                                      className='fas fa-location-dot'
                                                      style={{opacity: 0.5}}
                                                    ></i>
                                                  </button>
                                                )}
                                              </>
                                              {item.actualCheckIn === ''
                                                ? '-'
                                                : item?.actualCheckIn ?? '-'}
                                            </td>
                                          )}
                                          {item?.manualAttendance ? (
                                            <td>
                                              <div className='text-center'>-</div>
                                            </td>
                                          ) : (
                                            <td style={{width: '175px'}}>
                                              <>
                                                {item?.outlatitude !== null &&
                                                item?.outlongitude !== null ? (
                                                  <button
                                                    type='button'
                                                    className='btn btn-icon btn-light-primary btn-sm me-3'
                                                    onClick={() =>
                                                      handleOpenMapInNewTab(
                                                        item?.outlatitude,
                                                        item?.outlongitude
                                                      )
                                                    }
                                                  >
                                                    <i className='fas fa-location-dot'></i>
                                                  </button>
                                                ) : (
                                                  <button
                                                    type='button'
                                                    className='btn btn-icon btn-light-warning btn-sm me-3'
                                                    disabled
                                                  >
                                                    <i
                                                      className='fas fa-location-dot'
                                                      style={{opacity: 0.5}}
                                                    ></i>
                                                  </button>
                                                )}
                                              </>
                                              {item.actualcheckOut === ''
                                                ? '-'
                                                : item?.actualcheckOut ?? '-'}
                                            </td>
                                          )}
                                          <td style={{width: ''}}>
                                            {item?.formattedWorkingHoursTemp ?? '-'}
                                          </td>
                                        </tr>
                                      </>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </>
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan={7}>
                      <Center>No Record Available.</Center>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
            {/* <div className=''>


            </div> */}
          </div>
          <div className='d-flex mt-5 justify-content-between'>
            {/* <div className='d-flex '>
                <div className='dataTables_length' id='example_length'>
                  <label className='d-flex align-items-center'>
                    <select
                      name='example_length'
                      aria-controls='example'
                      className='form-select form-select-sm'
                      onChange={(e) => {
                        const selectedPageSize = parseInt(e.target.value, 10)
                        setPageSize(selectedPageSize)
                        setCurrentPage(1)
                        fetchTableData(1, selectedPageSize)
                      }}
                    >
                      <option value='10'>10</option>
                      <option value='25'>25</option>
                      <option value='50'>50</option>
                      <option value='100'>100</option>
                    </select>
                  </label>
                </div>

                <div className=' mt-2 ms-2'>
                  {(currentPage - 1) * pageSize + 1} to{' '}
                  {Math.min(currentPage * pageSize, totalEntries)} of {totalEntries}
                </div>
              </div> */}
            <Text className='me-auto'>
              {getPaginationInfoData(activePage, '10', tableData[0]?.queryData?.total ?? '', {
                data: tableData[0]?.result ?? '',
              })}{' '}
              Of {Math.ceil(Number(tableData[0]?.queryData?.total ?? 0))}
            </Text>
            <div className='flex-grow-1'></div>

            <Pagination
              value={activePage}
              size='lg'
              styles={(theme) => ({
                control: {
                  '&[data-active]': {
                    backgroundImage: theme.colorScheme,
                  },
                },
              })}
              onChange={(page) => {
                setActivePage(page)
                fetchTableData(page)
              }}
              total={Math.ceil(Number(tableData[0]?.queryData?.total ?? 0) / Number(10))}
            ></Pagination>
            {/* <div className='d-flex justify-content-between align-items-center'>
                <div className='me-2 d-flex justify-content-end'>
                  <button
                    className={`ms-2 btn btn-icon btn-${
                      currentPage === 1 ? 'secondary' : 'light-primary'
                    } btn-sm me-1`}
                    onClick={fetchPreviousPage}
                    disabled={currentPage === 1}
                  >
                    <i className='fa-solid fa-angle-left'></i>
                  </button>

                  <button
                    className={`ms-2 btn btn-icon btn-${
                      currentPage * pageSize >= totalEntries ? 'secondary' : 'light-primary'
                    } btn-sm me-1`}
                    onClick={() => {
                      setCurrentPage((prevPage) => prevPage + 1)
                      fetchTableData(currentPage + 1, pageSize)
                    }}
                    disabled={currentPage * pageSize >= totalEntries}
                  >
                    <i className='fa-solid fa-angle-right'></i>
                  </button>
                </div>
              </div> */}
          </div>
        </KTCardBody>
      </KTCard>
    </>
  )
}
export default AdminAttendance
