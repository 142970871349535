import { useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { Message, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { API_BASE_URL } from '../../../../utils/contants'
import { Button } from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { setupAxios } from '..'
import InputErrorBox from '../../../components/InputErrorBox'
import { toast } from 'react-toastify'
import { ThemeModeComponent } from '../../../../_metronic/assets/ts/layout/ThemeMode'
import { useThemeMode } from '../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'

function ResetPassword() {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [confirmPasswordError, setConfirmPasswordError] = useState('')

  const [APIStatus, setAPIStatus] = useState({ loading: false, error: null, submitLoading: false })
  const location = useLocation()
  const [SubmitAPIStatus, setSubmitAPIStatus] = useState({
    loading: false,
  })

  const [inputError, setInputError] = useState<any>({})

  const userId = location.state?.userId
  const tenantId = location.state?.tenantId

  const navigate = useNavigate()
  const [passwordShown, setPasswordShown] = useState(false)

  const passwordVisiblity = () => {
    setPasswordShown((prev) => !prev)
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value)
  }

  const handleConfirmPassword = (event) => {
    setConfirmPassword(event.target.value)
  }

  const validatePassword = (password) => {
    let count = 0
    const error: any = {}

    if (!password.trim()) {
      count = count + 1
      error.password = 'Please enter a New Password.'
    } else if (password.length > 20) {
      count = count + 1
      error.password = 'Password must not exceed 20 characters.'
    } else if (password.length < 8) {
      count = count + 1
      error.password = 'Password must be at least 8 characters.'
    } else if (!/[a-z]/.test(password)) {
      count = count + 1
      error.password = 'Password must contain at least one lowercase letter.'
    } else if (!/[A-Z]/.test(password)) {
      count = count + 1
      error.password = 'Password must contain at least one uppercase letter.'
    } else if (!/[!@#$%^&*]/.test(password)) {
      count = count + 1
      error.password = 'Password must contain at least one special character (!@#$%^&*).'
    } else if (!/\d/.test(password)) {
      count = count + 1
      error.password = 'Password must contain at least one digit.'
    }
    return { count, error }
  }

  const validateConfirmPassword = (confirmPassword) => {
    if (confirmPassword !== password) {
      setConfirmPasswordError('Confirm Password does not match to the New Password.')
      return false
    } else {
      setConfirmPasswordError('')
      return true
    }
  }

  const handleSubmit = async (setInputError: Function) => {
    const { count, error } = validatePassword(password)
    setInputError(error)
    if (count === 0) {
      setAPIStatus((prev: any) => ({ ...prev, loading: true }))
      try {
        const token = setupAxios()
        const res = await fetch(API_BASE_URL + `/user/${userId}/first-time-password-reset`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            'X-Tenant-Id': `${tenantId}`

          },
          body: JSON.stringify({
            password: password,
          }),
        })

        const responseBody = await res.json()

        if (res.status === 200) {
          toast.success('Password reset successfully.')
          navigate(`/login?tenant=${location.state?.tenantId}`)

        } else {
          if (res.status === 406) {
            if (responseBody && responseBody.error) {
              Message(responseBody.error, 'error')
            } else {
              Message(responseBody.message, 'error')
            }
          } else {
            Message('Error occurred while processing your request.', 'error')
          }
        }
      } catch (error) {
        setSubmitAPIStatus((prev: any) => ({ ...prev, loading: false }))
      }
    }
  }
  const { mode } = useThemeMode()


  return (
    <>
      <div className='d-flex justify-content-center mt-20'>
        <div className='card ms-5 me-5 shadow'>
          <div className='card-header d-flex justify-content-center'>
            <div className='card-title text-center'>
              <div className=' '>
                <img
                  style={{ width: '170px', height: '120px', backgroundColor: 'transparent' }}
                  className=''
                  src={localStorage.getItem('kt_theme_mode_menu') === 'dark' || mode === 'system' && ThemeModeComponent.getSystemMode() === 'dark' ? '/media/logos/whitesialogo.png' : '/media/logos/sialogo.png'}
                  alt='Skeletos Logo'
                />
                <div className='card-toolbar'>
                  <h1 className='text-dark fw-bolder'>Reset Password</h1>
                </div>
              </div>
            </div>
          </div>
          <div className='card-body'>
            <div className='fv-row position-relative mb-5'>
              <label
                className='form-label fw-bolder text-gray-900 fs-6 required'
                style={{ paddingRight: '20px' }}
              >
                New Password
              </label>
              <div className='input-group'>
                <input
                  type={passwordShown ? 'text' : 'password'}
                  autoComplete='off'
                  className='form-control '
                  value={password}
                  onChange={handlePasswordChange}
                />
                <span className='input-group-text cursor-pointer' onClick={passwordVisiblity}>
                  {passwordShown ? (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  ) : (
                    <FontAwesomeIcon icon={faEye} />
                  )}
                </span>
              </div>
              {<InputErrorBox Message={inputError.password} />}
            </div>

            <div className='fv-row position-relative mb-5'>
              <label
                className='form-label fw-bolder text-gray-900 fs-6'
                style={{ paddingRight: '20px' }}
              >
                Confirm Password
              </label>
              <div className='input-group'>
                <input
                  type={passwordShown ? 'text' : 'password'}
                  autoComplete='off'
                  className='form-control '
                  value={confirmPassword}
                  onChange={handleConfirmPassword}
                />
                <span className='input-group-text cursor-pointer' onClick={passwordVisiblity}>
                  {passwordShown ? (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  ) : (
                    <FontAwesomeIcon icon={faEye} />
                  )}
                </span>
              </div>
              {confirmPasswordError && <InputErrorBox Message={confirmPasswordError} />}
            </div>
            <div className='d-flex justify-content-center mt-3'>
              <p className='text-muted text-center mb-0 fw-bold'>
                Password must be 8-16 characters with 1 special character, <br /> 1 uppercase
                letter, and 1 number.
              </p>
            </div>

            <div className='d-flex justify-content-center'>
              <Button
                variant='filled'
                radius='md'
                size='md'
                className='mt-5 mb-5 fw-bold fs-5'
                loading={SubmitAPIStatus.loading}
                onClick={() => {
                  const isConfirmPasswordValid = validateConfirmPassword(confirmPassword)
                  setInputError('')
                  if (isConfirmPasswordValid) {
                    handleSubmit(setInputError)
                  }
                }}
                style={{ width: '350px' }}
              >
                Reset Password
              </Button>
            </div>

            <div className='d-flex justify-content-center'>
              <Link to='/login'>
                <Button
                  onClick={() => {
                    setInputError('')
                  }}
                  className='btn pt-2 pb-2'
                >
                  <FontAwesomeIcon icon={faChevronLeft} size='sm' />

                  <span className='ms-2 fs-7'>Back To Login </span>
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ResetPassword
