import {useEffect} from 'react'
import {Link, Navigate, Routes} from 'react-router-dom'
import {useAuth} from './core/Auth'
import { setupAxios } from './core/AuthHelpers'

export function Logout() {
  const {logout} = useAuth()
  useEffect(() => {
    logout()
    document.location.reload()
  }, [logout])

  return (
    <Routes>
      <Link to='/login' />
      
    </Routes>
  )
}
