import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {getProjectUsers} from '../users/UserFunction'
import {changeTextSentenceCase, dateFormat} from '../../../../_metronic/helpers/AssetHelpers'
import {getProjectsById, getUsers} from '../../../../api/Organization'
import {faArrowLeft, faCheck, faSave, faTasks, faXmark} from '@fortawesome/free-solid-svg-icons'
import {API_BASE_URL, Completed, Created, In_Progress, On_Hold} from '../../../../utils/contants'
import {Col, Row} from 'react-bootstrap'
import {Button} from '@mantine/core'
import Select2 from '../../../components/select2'
import {updateProject, validateUpdateForm} from './ProjectOverviewFunction'
import {setupAxios, useAuth} from '../../../modules/auth'
import ReactQuill from 'react-quill'
import axios from 'axios'
import InputErrorBox from '../../../components/InputErrorBox'
import {CKEditor} from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

const ProjectOverview = () => {
  const {currentUser} = useAuth()

  const params = useParams()
  let navigate = useNavigate()

  const [APIStatus, setAPIStatus] = useState({
    loading: false,
    error: null,
    submitLoading: false,
  })

  const [inputError, setInputError] = useState<any>({})

  const [usersOptions, setUsersOptions] = useState<{label: string; value: string}[]>([])
  const sortedUserNames = usersOptions.slice().sort((a, b) => a.label.localeCompare(b.label))

  const [projectUser, setProjectUser] = useState({
    project_users: [{status: '', role: '', user: {id: '', firstName: '', lastName: ''}}],
  })

  const [projectData, setProjectData] = useState({
    id: '',
    name: '',
    description: '',
    startDate: '',
    dueDate: '',
    endDate: '',
    status: '',
    category: '',
    subCategory: '',
    type: '',
    projectManager: {
      id: '',
      firstName: '',
      lastName: '',
    },
  })

  const [projectEditMode, setProjectEditMode] = useState<
    | 'name'
    | 'description'
    | 'status'
    | 'startDate'
    | 'dueDate'
    | 'endDate'
    | 'category'
    | 'subCategory'
    | 'type'
    | 'projectManager'
    | null
  >(null)

  const [editedName, setEditedName] = useState('')
  const [editedDescription, setEditedDescription] = useState('')
  const [editedStatus, setEditedStatus] = useState('')
  const [editedStartDate, setEditedStartDate] = useState('')
  const [editedDueDate, setEditedDueDate] = useState('')
  const [editedEndDate, setEditedEndDate] = useState('')
  const [editedCategory, setEditedCategory] = useState('')
  const [editedSubCategory, setEditedSubCategory] = useState('')
  const [editedType, setEditedType] = useState('')
  const [editedProjectManager, setEditedProjectManager] = useState('')

  const handleEditedSaveClick = () => {
    const validationError = validateUpdateForm({
      name: editedName,
      category: editedCategory,
      subCategory: editedSubCategory,
      type: editedType,
      startDate: editedStartDate,
      dueDate: editedDueDate,
      endDate: editedEndDate,
      status: editedStatus,
      description: editedDescription,
    })

    if (Object.keys(validationError).length > 0) {
      setInputError(validationError)
      return
    } else {
      if (projectEditMode === 'name') {
        updateProject(params?.id, {name: editedName}, currentUser?.id, setProjectData, setAPIStatus)
      } else if (projectEditMode === 'description') {
        updateProject(
          params?.id,
          {description: editedDescription},
          currentUser?.id,
          setProjectData,
          setAPIStatus
        )
      } else if (projectEditMode === 'status') {
        updateProject(
          params?.id,
          {status: editedStatus},
          currentUser?.id,
          setProjectData,
          setAPIStatus
        )
      } else if (projectEditMode === 'category') {
        updateProject(
          params?.id,
          {category: editedCategory},
          currentUser?.id,
          setProjectData,
          setAPIStatus
        )
      } else if (projectEditMode === 'subCategory') {
        updateProject(
          params?.id,
          {subCategory: editedSubCategory},
          currentUser?.id,
          setProjectData,
          setAPIStatus
        )
      } else if (projectEditMode === 'type') {
        updateProject(params?.id, {type: editedType}, currentUser?.id, setProjectData, setAPIStatus)
      } else if (projectEditMode === 'startDate') {
        updateProject(
          params?.id,
          {startDate: editedStartDate},
          currentUser?.id,
          setProjectData,
          setAPIStatus
        )
      } else if (projectEditMode === 'dueDate') {
        updateProject(
          params?.id,
          {dueDate: editedDueDate},
          currentUser?.id,
          setProjectData,
          setAPIStatus
        )
      } else if (projectEditMode === 'endDate') {
        updateProject(
          params?.id,
          {endDate: editedEndDate},
          currentUser?.id,
          setProjectData,
          setAPIStatus
        )
      } else if (projectEditMode === 'projectManager') {
        updateProject(
          params?.id,
          {projectManager: {id: editedProjectManager}},
          currentUser?.id,
          setProjectData,
          setAPIStatus
        )
      }
      setInputError({})
      setProjectEditMode(null)
    }
  }

  const handleDoubleClick = (
    mode:
      | 'name'
      | 'description'
      | 'status'
      | 'startDate'
      | 'dueDate'
      | 'endDate'
      | 'category'
      | 'subCategory'
      | 'type'
      | 'projectManager'
  ) => {
    if (currentUser?.id === projectData?.projectManager?.id) {
      setProjectEditMode(mode)
      setEditedName(projectData.name || '')
      setEditedDescription(projectData?.description || '')
      setEditedStatus(projectData?.status || '')
      setEditedStartDate(projectData?.startDate || '')
      setEditedDueDate(projectData?.dueDate || '')
      setEditedEndDate(projectData?.endDate || '')
      setEditedCategory(projectData?.category || '')
      setEditedSubCategory(projectData?.subCategory || '')
      setEditedType(projectData?.type || '')
      setEditedProjectManager(projectData?.projectManager?.id || '')
    }
  }

  const fetchNames = () => {
    const {token, tenantId} = setupAxios()
    axios
      .get(API_BASE_URL + `/activeUsers`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-Tenant-Id': `${tenantId}`,
        },
      })
      .then((response: any) => {
        let userData: {label: string; value: string}[] = []
        response.data.data.map(
          (obj: {firstName: string; lastName: string; id: string}, index: number) => {
            userData.push({
              label: obj.firstName + ' ' + obj.lastName,
              value: obj.id,
            })
          }
        )
        setUsersOptions(userData)
      })
      .catch((error) => {
        console.error('Error fetching names:', error)
      })
  }

  const handleBlur = () => {
    handleEditedSaveClick()
  }

  useEffect(() => {
    let mounted = true

    if (mounted) {
      getProjectsById(params?.id).then((res) => {
        setProjectData(res?.data[0] ?? {})
        // console.log(res.data)
      })
      fetchNames()
      getProjectUsers(params?.id, setProjectUser, setAPIStatus)
    }
    return () => {
      mounted = false
    }
  }, [])

  return (
    <>
      {/* {console.log('Project Data : ', projectData)} */}
      <div>
        {/* <div className='card mb-6 mb-xl-9 mt-10' key={params?.id}>
          <div className='card-body pt-9 pb-0'>
            <div className='d-flex flex-wrap flex-sm-nowrap mb-6'>
              <div className='d-flex flex-center flex-shrink-0 bg-light rounded w-100px h-100px w-lg-150px h-lg-150px me-7 mb-4'>
                <img
                  src='/media/logos/skitLogo.png'
                  className='mw-50px mw-lg-75px'
                  alt='projectLogo'
                />
              </div>

              <div className='flex-grow-1'>
                <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center mb-1'>
                      <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bold me-3'>
                        {projectData?.name}
                      </a>
                      <span
                        className={`fw-bolder me-auto ${
                          projectData?.status === Created
                            ? 'badge badge-light-primary'
                            : projectData?.status === In_Progress
                            ? 'badge badge-light-info'
                            : projectData?.status === Completed
                            ? 'badge badge-light-success'
                            : projectData?.status === On_Hold
                            ? 'badge badge-light-warning'
                            : 'badge badge-light-danger'
                        }`}
                      >
                        {projectData?.status}
                      </span>
                    </div>

                    <div
                      className='d-flex flex-wrap fw-semibold mb-4 fs-5 text-gray-500'
                      dangerouslySetInnerHTML={{__html: projectData?.description ?? '-'}}
                    ></div>
                  </div>
                </div>

                <div className='d-flex flex-wrap justify-content-start'>
                  <div className='d-flex flex-wrap'>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <div className='fs-4 fw-bold'>{dateFormat(projectData?.startDate)}</div>
                      </div>

                      <div className='fw-semibold fs-6 text-gray-500'>start Date</div>
                    </div>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <div className='fs-4 fw-bold'>{dateFormat(projectData?.dueDate)}</div>
                      </div>

                      <div className='fw-semibold fs-6 text-gray-500'>Due Date</div>
                    </div>
                  </div>

                  <div className='symbol-group symbol-hover mb-3'>
                    {projectUser.project_users.map((obj) => {
                      return (
                        <div className='symbol symbol-35px symbol-circle'>
                          <span
                            className='symbol-label bg-success text-inverse-success fw-bold'
                            data-bs-toggle='tooltip'
                            title={`${obj?.user?.firstName ?? '-'} ${obj?.user?.lastName ?? '-'}`}
                          >
                            {obj?.user.firstName[0]}
                          </span>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className='card mb-5 mb-xl-10'>
          <div className='card-header cursor-pointer '>
            <div className='card-title'>
              <div className='d-flex'>
                <div
                  className='btn btn-sm btn-icon btn-active-color-primary'
                  onClick={() => {
                    navigate(`/projects`)
                  }}
                >
                  {' '}
                  <FontAwesomeIcon icon={faArrowLeft} size='lg' className='' />
                </div>{' '}
                <span>
                  <h3 className='fw-bold m-0 mt-2'>Project Details</h3>
                </span>
              </div>
            </div>
          </div>

          <div className='card-body p-9'>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-semibold text-muted'>Project Name</label>
              <div className='col-lg-8'>
                {projectEditMode === 'name' ? (
                  <div className=''>
                    <Row>
                      <Col md={5}>
                        <input
                          name='name'
                          type='text'
                          autoComplete='off'
                          className='mt-5 form-control'
                          value={editedName}
                          onChange={(e) => setEditedName(e.target.value)}
                          onBlur={handleBlur}
                        />
                      </Col>
                    </Row>
                    {inputError.name && projectEditMode === 'name' && (
                      <InputErrorBox Message={inputError?.name} />
                    )}
                    {/* <Row>
                      <Col md={5}>
                        <div className='d-flex justify-content-end'>
                          <Button
                            color='green'
                            variant='light'
                            data-bs-toggle='tooltip'
                            title='Save Project Name'
                            onClick={handleEditedSaveClick}
                            className='ms-3 mt-3'
                          >
                            <FontAwesomeIcon icon={faCheck} size='lg' />
                          </Button>
                          <Button
                            color='red'
                            variant='light'
                            className='ms-3 mt-3'
                            onClick={() => {
                              setProjectEditMode(null)
                              setInputError({})
                            }}
                          >
                            <FontAwesomeIcon icon={faXmark} size='lg' />
                          </Button>
                        </div>
                      </Col>{' '}
                    </Row> */}
                  </div>
                ) : (
                  <span
                    className='fw-bold fs-6 text-gray-800'
                    onDoubleClick={() => handleDoubleClick('name')}
                    style={{cursor: 'pointer'}}
                  >
                    {projectData?.name === '' ? (
                      <span>{'-'}</span>
                    ) : (
                      <span> {changeTextSentenceCase(projectData?.name ?? '-')}</span>
                    )}
                  </span>
                )}
              </div>
            </div>

            <div className='row mb-7'>
              <label className='col-lg-4 fw-semibold text-muted'>Status</label>
              <div className='col-lg-8 fv-row'>
                {projectEditMode === 'status' ? (
                  <div>
                    <Row>
                      <Col md={5}>
                        <span className='fs-5 fw-bold text-gray-900 mb-2 ms-5'>
                          <Select2
                            name='status'
                            value={editedStatus}
                            data={[
                              {label: 'Created', value: 'Created'},
                              {label: 'In Progress', value: 'In Progress'},
                              {label: 'On Hold', value: 'On Hold'},
                              {label: 'Completed', value: 'Completed'},
                            ].sort((a, b) => a.label.localeCompare(b.label))}
                            onChange={(selectedOption: any) => {
                              // console.log(selectedOption)
                              setEditedStatus(selectedOption)
                            }}
                            onBlur={handleBlur}
                          />
                        </span>
                      </Col>
                    </Row>
                    {<InputErrorBox Message={inputError?.status} />}
                  </div>
                ) : (
                  <span
                    className={` fs-7 p-3 ${
                      projectData?.status === Created
                        ? 'badge badge-light-primary'
                        : projectData?.status === In_Progress
                          ? 'badge badge-light-info'
                          : projectData?.status === Completed
                            ? 'badge badge-light-success'
                            : projectData?.status === On_Hold
                              ? 'badge badge-light-warning'
                              : 'badge badge-light-danger'
                    }`}
                    onDoubleClick={() => handleDoubleClick('status')}
                    style={{cursor: 'pointer'}}
                  >
                    {projectData?.status ?? '-'}
                  </span>
                )}
              </div>
            </div>

            <div className='row mb-7'>
              <label className='col-lg-4 fw-semibold text-muted'>Category </label>
              <div className='col-lg-8 fv-row'>
                {projectEditMode === 'category' ? (
                  <div>
                    <Row className=''>
                      <Col md={5}>
                        <span className='fs-5 fw-bold text-gray-900 mb-2 ms-5'>
                          <Select2
                            name='category'
                            value={editedCategory}
                            data={[
                              {label: 'FPP', value: 'FPP'},
                              {label: 'T & M', value: 'T & M'},
                              {label: 'Unbilled', value: 'Unbilled'},
                            ].sort((a, b) => a.label.localeCompare(b.label))}
                            onChange={(selectedOption: any) => {
                              setEditedCategory(selectedOption)
                            }}
                            onBlur={handleBlur}
                          />
                        </span>
                      </Col>
                    </Row>
                    {<InputErrorBox Message={inputError?.category} />}
                  </div>
                ) : (
                  <span
                    className='fw-bold fs-6 text-gray-800 me-2'
                    onDoubleClick={() => handleDoubleClick('category')}
                    style={{cursor: 'pointer'}}
                  >
                    {projectData?.category ?? '-'}
                  </span>
                )}
              </div>
            </div>

            <div className='row mb-7'>
              <label className='col-lg-4 fw-semibold text-muted'>Sub Category</label>
              <div className='col-lg-8 fv-row'>
                {projectEditMode === 'subCategory' ? (
                  <div>
                    <Row className=''>
                      <Col md={5}>
                        <span className='fs-5 fw-bold text-gray-900 mb-2 ms-5'>
                          <Select2
                            name='subCategory'
                            value={editedSubCategory}
                            data={[
                              {label: 'Professional Services', value: 'Professional Services'},
                              {label: 'Managed Services', value: 'Managed Services'},
                              {label: 'Staffing', value: 'Staffing'},
                            ].sort((a, b) => a.label.localeCompare(b.label))}
                            onChange={(selectedOption: any) => {
                              // console.log(selectedOption)
                              setEditedSubCategory(selectedOption)
                            }}
                            onBlur={handleBlur}
                          />
                        </span>
                      </Col>
                    </Row>
                    {<InputErrorBox Message={inputError?.subCategory} />}
                  </div>
                ) : (
                  <span
                    className='fw-bold fs-6 text-gray-800'
                    onDoubleClick={() => handleDoubleClick('subCategory')}
                    style={{cursor: 'pointer'}}
                  >
                    {projectData?.subCategory ?? '-'}{' '}
                  </span>
                )}
              </div>
            </div>

            <div className='row mb-7'>
              <label className='col-lg-4 fw-semibold text-muted'>Project Type</label>
              <div className='col-lg-8 fv-row'>
                {projectEditMode === 'type' ? (
                  <div>
                    <Row className=''>
                      <Col md={5}>
                        <span className='fs-5 fw-bold text-gray-900 mb-2 ms-5'>
                          <Select2
                            name='type'
                            value={editedType}
                            data={[
                              {label: 'Web', value: 'Web'},
                              {label: 'Android', value: 'Android'},
                              {label: 'ios', value: 'ios'},
                              {label: 'Data Center', value: 'DataCenter'},
                              {label: 'Infra', value: 'Infra'},
                            ].sort((a, b) => a.label.localeCompare(b.label))}
                            onChange={(selectedOption: any) => {
                              // console.log(selectedOption)
                              setEditedType(selectedOption)
                            }}
                            onBlur={handleBlur}
                          />
                        </span>
                      </Col>
                    </Row>
                    {<InputErrorBox Message={inputError?.type} />}
                  </div>
                ) : (
                  <span
                    className='fw-bold fs-6 text-gray-800'
                    onDoubleClick={() => handleDoubleClick('type')}
                    style={{cursor: 'pointer'}}
                  >
                    {projectData?.type ?? '-'}
                  </span>
                )}
              </div>
            </div>

            <div className='row mb-7'>
              <label className='col-lg-4 fw-semibold text-muted'>Start Date</label>
              <div className='col-lg-8 fv-row'>
                {projectEditMode === 'startDate' ? (
                  <div>
                    <Row className=''>
                      <Col md={5}>
                        <span className='fs-5 fw-bold text-gray-900 mb-2 ms-5'>
                          <div>
                            <input
                              type='date'
                              name='startDate'
                              className='form-control'
                              value={editedStartDate}
                              onChange={(e) => setEditedStartDate(e.target.value)}
                              onBlur={handleBlur}
                            />
                          </div>
                        </span>
                      </Col>
                    </Row>
                    {<InputErrorBox Message={inputError?.startDate} />}
                  </div>
                ) : (
                  <span
                    className='fw-bold fs-6 text-gray-800'
                    onDoubleClick={() => handleDoubleClick('startDate')}
                    style={{cursor: 'pointer'}}
                  >
                    {projectData?.startDate === null ? (
                      <span>{'-'}</span>
                    ) : (
                      <span>{dateFormat(projectData?.startDate ?? '-')}</span>
                    )}
                  </span>
                )}
              </div>
            </div>

            <div className='row mb-7'>
              <label className='col-lg-4 fw-semibold text-muted'>Due Date</label>
              <div className='col-lg-8 fv-row'>
                {projectEditMode === 'dueDate' ? (
                  <div>
                    <Row className=''>
                      <Col md={5}>
                        <span className='fs-5 fw-bold text-gray-900 mb-2 ms-5'>
                          <div>
                            <input
                              type='date'
                              name='dueDate'
                              className='form-control'
                              value={editedDueDate}
                              onChange={(e) => setEditedDueDate(e.target.value)}
                              onBlur={handleBlur}
                            />
                          </div>
                        </span>
                      </Col>
                    </Row>
                    {<InputErrorBox Message={inputError?.dueDate} />}
                  </div>
                ) : (
                  <span
                    className='fw-bold fs-6 text-gray-800'
                    onDoubleClick={() => handleDoubleClick('dueDate')}
                    style={{cursor: 'pointer'}}
                  >
                    {projectData?.dueDate === null ? (
                      <span>{'-'}</span>
                    ) : (
                      <span>{dateFormat(projectData?.dueDate ?? '-')}</span>
                    )}{' '}
                  </span>
                )}
              </div>
            </div>

            <div className='row mb-7'>
              <label className='col-lg-4 fw-semibold text-muted'>End Date</label>
              <div className='col-lg-8 fv-row'>
                {projectEditMode === 'endDate' ? (
                  <div>
                    <Row className=''>
                      <Col md={5}>
                        <span className='fs-5 fw-bold text-gray-900 mb-2 ms-5'>
                          <div>
                            <input
                              type='date'
                              name='endDate'
                              className='form-control'
                              value={editedEndDate}
                              onChange={(e) => setEditedEndDate(e.target.value)}
                              onBlur={handleBlur}
                            />
                          </div>
                        </span>
                      </Col>
                    </Row>
                    {<InputErrorBox Message={inputError?.endDate} />}
                  </div>
                ) : (
                  <span
                    className='fw-bold fs-6 text-gray-800'
                    onDoubleClick={() => handleDoubleClick('endDate')}
                    style={{cursor: 'pointer'}}
                  >
                    {projectData?.endDate === null ? (
                      <span>{'-'}</span>
                    ) : (
                      <span>{dateFormat(projectData?.endDate ?? '-')}</span>
                    )}{' '}
                  </span>
                )}
              </div>
            </div>

            <div className='row mb-7'>
              <label className='col-lg-4 fw-semibold text-muted'>Project Manager</label>
              <div className='col-lg-8 fv-row'>
                {projectEditMode === 'projectManager' ? (
                  <div>
                    <Row>
                      <Col md={5}>
                        <span className='fs-5 fw-bold text-gray-900 mb-2 ms-5 me-2'>
                          <Select2
                            name='projectManager'
                            value={editedProjectManager}
                            data={sortedUserNames}
                            onChange={(selectedOption: string) => {
                              console.log(selectedOption)
                              setEditedProjectManager(selectedOption)
                            }}
                            onBlur={handleBlur}
                          />
                        </span>
                      </Col>
                    </Row>
                    {<InputErrorBox Message={inputError?.projectManager} />}
                  </div>
                ) : (
                  <span
                    className='fw-bold fs-6 text-gray-800'
                    onDoubleClick={() => handleDoubleClick('projectManager')}
                    style={{cursor: 'pointer'}}
                  >
                    {projectData?.projectManager?.firstName ?? '-'}{' '}
                    {projectData?.projectManager?.lastName ?? '-'}
                  </span>
                )}
              </div>
            </div>

            <div className='row mb-7'>
              <label className='col-lg-4 fw-semibold text-muted'>Project Description</label>
              <div className='col-lg-8'>
                {projectEditMode === 'description' ? (
                  <div className='me-5'>
                    <CKEditor
                      editor={ClassicEditor}
                      data={editedDescription}
                      config={{
                        toolbar: {
                          items: [
                            'heading',
                            '|',
                            'bold',
                            'italic',
                            'link',
                            'bulletedList',
                            'numberedList',
                          ],
                        },
                      }}
                      onChange={(value, editor) => {
                        const data = editor.getData()
                        setEditedDescription(data)
                      }}
                    />
                    {<InputErrorBox Message={inputError?.description} />}

                    <div className='d-flex justify-content-end'>
                      <Button onClick={handleEditedSaveClick} className='mt-3'>
                        <FontAwesomeIcon icon={faSave} className='me-1' />
                        Save
                      </Button>
                    </div>
                  </div>
                ) : (
                  <span
                    className='fw-bold fs-6 text-gray-800 '
                    dangerouslySetInnerHTML={{__html: projectData?.description ?? '-'}}
                    onClick={() => handleDoubleClick('description')}
                    style={{cursor: 'pointer'}}
                  ></span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProjectOverview
