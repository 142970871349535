import {faImage, faUser, faXmark} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Button, Center, Image, Loader} from '@mantine/core'
import axios from 'axios'
import React, {useContext, useEffect, useState} from 'react'
import {Col, Dropdown, DropdownButton, Form, Modal, Pagination, Row} from 'react-bootstrap'
import ReactQuill from 'react-quill'
import {useNavigate, useParams} from 'react-router-dom'
import {changeTextCapital, changeTextSentenceCase, dateFormat} from '../../../../_metronic/helpers'
import {API_BASE_URL, Completed, In_Progress, On_Hold, Todo} from '../../../../utils/contants'
import InputErrorBox from '../../../components/InputErrorBox'
import Select2 from '../../../components/select2'
import roleContext from '../../../context/roleContext'
import {setupAxios, useAuth} from '../../../modules/auth'
import {RoleContextValue} from '../../../types/OtherTypes'
import {
  cancelSubmit,
  clearFilteredField,
  getTask,
  handleInputChanges,
  handleOtherInputChange,
  handleSubmit,
  validateTaskForm,
} from './TaskFunction'

const Task = () => {
  let roleState: RoleContextValue = useContext(roleContext)
  const params = useParams()
  const {currentUser} = useAuth()
  const navigate = useNavigate()

  const [showTaskModal, setShowTaskModal] = useState(false)
  const tagOptions = ['Highest', 'High', 'Medium', 'Low', 'Lowest']
  const tag = ['Easy', 'Medium', 'Hard']
  const [showSubTaskRow, setShowSubTaskRow] = useState<number[]>([])

  const [inputError, setInputError] = useState<any>({})

  const [SubmitAPIStatus, setSubmitAPIStatus] = useState({
    loading: false,
  })

  const [filterOptions, setFilterOptions] = useState({
    userId: '',
    status: '',
    date: '',
    take: 10,
    page: 1,
    sortBy: '',
  })

  const addTask = () => {
    setShowTaskModal(true)
  }
  const closeAddTask = () => {
    setShowTaskModal(false)
  }

  const handleFilterChange = (filterName, value) => {
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      [filterName]: value,
    }))
  }

  const checkSubTaskArrayElement = (element: number) => {
    let arr: number[] = [...showSubTaskRow]
    let isExists = false
    for (let i = 0; i <= arr.length - 1; i++) {
      if (arr[i] === element) {
        arr.splice(i, 1)
        isExists = true
        break
      }
    }

    if (!isExists) {
      arr.push(element)
    }
    setShowSubTaskRow(arr)
  }

  const [projectUsers, setProjectUsers] = useState({
    projectUsers: [{status: '', role: '', user: {id: '', firstName: '', lastName: ''}}],
  })

  const [projectDueDate, setProjectDueDate] = useState<string[]>([])

  const initialStates = {
    id: '',
    title: '',
    description: '',
    startDate: '',
    dueDate: '',
    endDate: '',
    priority: '',
    status: 'Todo',
    level: '',
    project: {
      id: params?.id,
    },
    createdBy: {
      id: currentUser?.id,
    },
    assigneeId: {
      id: projectUsers?.projectUsers[0]?.user?.id,
    },
    assignerId: {
      id: currentUser?.id,
    },

    file: [] as File[],
    isDefect: false,
  }

  const [taskData, setTaskData] = useState(initialStates)
  const [isCreatingDefect, setIsCreatingDefect] = useState(false)

  const [tasks, setTasks] = useState({
    result: [
      {
        id: '',
        title: '',
        description: '',
        startDate: '',
        dueDate: '',
        endDate: '',
        priority: '',
        status: '',
        ticketName: '',
        assigneeId: {
          id: '',
          firstName: '',
          lastName: '',
        },
        project: {
          name: '',
        },
        assignerId: {
          id: '',
          firstName: '',
          lastName: '',
        },
        Comment: [],
        childTasks: [
          {
            id: '',
            title: '',
            description: '',
            startDate: '',
            dueDate: '',
            endDate: '',
            priority: '',
            status: '',
            percentage: '',
            level: '',
            ticketName: '',
            assigneeId: {
              id: '',
              firstName: '',
              lastName: '',
            },
            project: {
              name: '',
            },
            assignerId: {
              id: '',
              firstName: '',
              lastName: '',
            },
            Comment: [],
          },
        ],
      },
    ],
    quryParams: {total: '', page: '', take: '', sortBy: ''},
  })

  const closeProject = () => {}

  useEffect(() => {
    if (tasks && tasks.result) {
      setTotalEntries(tasks.result.length)
    }
  }, [tasks])

  const [pageSize, setPageSize] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [taskPerPage] = useState(5)
  const totalPages = Math.ceil(tasks?.result.length / taskPerPage)
  const pageRange = 1
  const pagesToDisplay: number[] = []
  for (let i = currentPage - pageRange; i <= currentPage + pageRange; i++) {
    if (i >= 1 && i <= totalPages) {
      pagesToDisplay.push(i)
    }
  }
  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const [totalEntries, setTotalEntries] = useState(0)

  const [isimageSelected, setIsImageSelected] = useState(false)
  const [imageFiles, setImageFiles] = useState<File[]>([])
  const [imagePreviews, setImagePreviews] = useState<string[]>([])

  const iconBeforeSelect = faImage

  const [APIStatus, setAPIStatus] = useState({
    loading: false,
    error: null,
    submitLoading: false,
  })

  // const [formType, setFormType] = useState('Task')

  // const handleDropdownClick = (selectedType) => {
  //   setFormType(selectedType)
  // }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files) {
      const fileArray = Array.from(files)
      const imageArray = fileArray.filter((file) => file.type.startsWith('image/'))

      const imagePreviewsArray = imageArray.map((file) => URL.createObjectURL(file))

      setImageFiles((prevFiles) => [...prevFiles, ...imageArray])
      setImagePreviews((prevPreviews) => [...prevPreviews, ...imagePreviewsArray])

      setTaskData((prevTaskData) => ({
        ...prevTaskData,
        file: [...prevTaskData.file, ...imageArray],
      }))

      setIsImageSelected(true)
    }
  }

  const removeImage = (index: number) => {
    const updatedFiles = [...imageFiles]
    const updatedPreviews = [...imagePreviews]

    updatedFiles.splice(index, 1)
    updatedPreviews.splice(index, 1)

    setImageFiles(updatedFiles)
    setImagePreviews(updatedPreviews)

    setTaskData((prevTaskData) => ({
      ...prevTaskData,
      file: updatedFiles,
    }))
  }

  const clearImagePreviews = () => {
    setImageFiles([])
    setImagePreviews([])
  }

  const [projectUser, setProjectUser] = useState<{label: string; value: string}[]>([])
  const sortedProjectUser = projectUser.slice().sort((a, b) => a.label.localeCompare(b.label))

  const fetchprojectUserNames = (id) => {
    const {token, tenantId} = setupAxios()
    axios
      .get(API_BASE_URL + `/project/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-Tenant-Id': `${tenantId}`,
        },
      })
      .then((response: any) => {
        let projectdueDates: string[] = []
        let userData: {label: string; value: string}[] = []
        response?.data?.data.forEach((project) => {
          project?.projectUsers?.forEach((user) => {
            userData?.push({
              label: `${user.user?.firstName} ${user.user?.lastName}`,
              value: user.user?.id,
            })
          })
          // projectdueDates.push(project?.dueDate)
        })
        setProjectUser(userData)

        // if (projectdueDates.length > 0) {
        //   setTaskData((prevTaskData) => ({
        //     ...prevTaskData,
        //     dueDate: projectdueDates[0],
        //   }))
        // }

        // setProjectDueDate(projectdueDates)
        // console.log('Due Date', projectdueDates)
        // setTasks((prevTasks) => filterdTasks(prevTasks, selectedUser, selectedStatus, selectedDate))
      })
      .catch((error) => {
        console.error('Error fetching names:', error)
      })
  }

  useEffect(() => {
    let mounted = true
    if (mounted) {
      setCurrentPage(1)
      getTask(
        params?.id,
        filterOptions?.userId,
        filterOptions?.date,
        filterOptions?.status,
        filterOptions?.take,
        filterOptions?.page,
        filterOptions?.sortBy,
        setTasks,
        setAPIStatus
      )
      // const totalCount = tasks.result.length
      // setTotalEntries(totalCount)

      // if (tasks && tasks.result) {
      //   setTotalEntries(tasks.result.length)
      // }

      fetchprojectUserNames(params.id)
    }
    return () => {
      mounted = false
    }
  }, [filterOptions])

  return (
    <>
      <div className='card'>
        <div className='card-header' style={{cursor: 'pointer'}}>
          {/* <div className='card-title fs-3'>
            <div className='dataTables_length mt-8' id='example_length'>
              <label className='d-flex align-items-center'>
                <label className='fs-6 fw-semibold mb-2 me-2'>Show</label>
                <select
                  name='example_length'
                  aria-controls='example'
                  className='form-select form-select-sm'
                  onChange={(e) => {
                    const selectedPageSize = parseInt(e.target.value, 10)
                    setPageSize(selectedPageSize)
                    setCurrentPage(1)
                    getTask(
                      params?.id,
                      filterOptions?.userId,
                      filterOptions?.date,
                      filterOptions?.status,
                      selectedPageSize,
                      1,
                      filterOptions.sortBy,
                      setTasks,
                      setAPIStatus
                    )
                  }}
                >
                  <option value='10'>10</option>
                  <option value='25'>25</option>
                  <option value='50'>50</option>
                  <option value='100'>100</option>
                </select>
              </label>
            </div>
          </div> */}

          <div className='card-toolbar'>
            <Row>
              <Col md={3} className='mt-5'>
                <Form.Label>Users:</Form.Label>
                <Select2
                  name='id'
                  className='mb-4'
                  data={sortedProjectUser}
                  // value={users.project_users[0].user.id || ''}
                  value={filterOptions.userId ?? '-'}
                  onChange={(selectedOption: string) => {
                    // console.log('selected', users.project_users[0].user.id)
                    handleFilterChange('userId', selectedOption)
                  }}
                />
              </Col>

              <Col md={3} className='mt-5'>
                <Form.Label>Status:</Form.Label>
                <Select2
                  name='status'
                  data={[
                    {label: 'Completed', value: 'Completed'},
                    {label: 'Todo', value: 'Todo'},
                    {label: 'In Progress', value: 'In Progress'},
                    {label: 'On Hold', value: 'On Hold'},
                  ].sort((a, b) => a.label.localeCompare(b.label))}
                  value={filterOptions.status ?? '-'}
                  onChange={(selectedOption: any) => {
                    // console.log(selectedOption)
                    handleFilterChange('status', selectedOption)
                  }}
                />
              </Col>

              <Col md={3} className='mt-5 me-2 mb-5'>
                <Form.Label>Start Date:</Form.Label>
                <input
                  type='date'
                  name='date'
                  value={filterOptions.date ?? '-'}
                  className='form-control form-sm'
                  onChange={(event) => {
                    handleFilterChange('date', event.target.value)
                  }}
                />
              </Col>

              <Col md={1} className='mt-7 me-2 mb-5'>
                <Button
                  className='text-center mt-7'
                  color='gray'
                  onClick={() => {
                    clearFilteredField(setFilterOptions, filterOptions)
                  }}
                >
                  Clear
                </Button>
              </Col>
            </Row>
          </div>

          <div className='d-flex flex-end'>
            <DropdownButton
              title='Create'
              size='sm'
              className='mb-10'
              variant='primary'
              // hidden={!checkRole(['Project Management'], roleState.features, 'edit')}
            >
              <Dropdown.Item
                // hidden={!checkRole(['Project Management'], roleState.features, 'edit')}
                onClick={() => {
                  addTask()
                  fetchprojectUserNames(params?.id)
                  setTaskData({...taskData, isDefect: false})
                  setIsCreatingDefect(false)
                  // handleDropdownClick('Task')
                }}
              >
                Task
              </Dropdown.Item>
              <Dropdown.Item
                // hidden={!checkRole(['Project Management'], roleState.features, 'edit')}
                onClick={() => {
                  addTask()
                  fetchprojectUserNames(params?.id)
                  setTaskData((prevTaskData) => ({...prevTaskData, isDefect: true}))
                  setIsCreatingDefect(true)
                  // handleDropdownClick('Defect')
                }}
              >
                Defect
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </div>

        <div className='card-body' style={{cursor: 'pointer'}}>
          <div className='table-responsive'>
            {APIStatus.loading ? (
              <Center>
                <Loader></Loader>
              </Center>
            ) : (
              <table className='table table-hover table-row-dashed dataTabl'>
                <thead>
                  <tr className='fw-bold'>
                    <th className='' style={{width: '10px'}}></th>
                    <th className=''>{changeTextCapital('Task Title')}</th>
                    <th className=''>{changeTextCapital('Start Date')}</th>
                    <th className=''>{changeTextCapital('Due Date')}</th>
                    <th className=''>{changeTextCapital('priority')}</th>
                    <th className=''>{changeTextCapital('status')}</th>

                    <th className=''>
                      <FontAwesomeIcon
                        icon={faUser}
                        style={{color: '#a7b7d3'}}
                        className='text-center ms-1'
                      />
                    </th>
                  </tr>
                </thead>
                {tasks?.result.length > 0 ? (
                  <tbody>
                    {tasks?.result
                      ?.slice((currentPage - 1) * taskPerPage, currentPage * taskPerPage)
                      ?.map((obj, index) => {
                        // console.log(tasks)
                        return (
                          <>
                            <tr
                              className=''
                              key={obj?.id}
                              onClick={() => {
                                navigate(`/project/${params?.id}/taskOverview/${obj?.id}`)
                              }}
                            >
                              {obj?.childTasks.length > 0 ? (
                                <td className='text-center'>
                                  <span
                                    className='badge badge-light-primary d-inline-block'
                                    style={{
                                      cursor: 'pointer',
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      checkSubTaskArrayElement(index)
                                    }}
                                  >
                                    <i
                                      className={`fas fa-lg fa-caret-${
                                        showSubTaskRow.includes(index) ? 'up' : 'down'
                                      }`}
                                      style={{color: '#74C0FC'}}
                                    />
                                  </span>
                                </td>
                              ) : (
                                <td></td>
                              )}

                              <td>
                                <div className='position-relative'>
                                  <div className='fw-semibold ms-3'>
                                    <span className='me-5 badge badge-pill badge-light-primary p-2'>
                                      {obj?.ticketName ?? '-'}
                                    </span>

                                    {changeTextSentenceCase(obj?.title ?? '-')}
                                  </div>

                                  <span
                                    className={`position-absolute h-100 w-4px rounded top-0 start-0 ${
                                      obj?.status === Todo
                                        ? 'bg-primary'
                                        : obj?.status === In_Progress
                                          ? 'bg-info'
                                          : obj?.status === Completed
                                            ? 'bg-success'
                                            : obj?.status === On_Hold
                                              ? 'bg-warning'
                                              : 'bg-danger'
                                    } `}
                                  ></span>
                                </div>
                              </td>

                              <td>
                                {obj?.startDate === null ? (
                                  <span className=''>{'-'}</span>
                                ) : (
                                  <span className=''>{dateFormat(obj?.startDate ?? '-')}</span>
                                )}
                              </td>
                              <td>
                                {obj?.dueDate === null ? (
                                  <span className=''>{'-'}</span>
                                ) : (
                                  <span className=''>{dateFormat(obj?.dueDate ?? '-')}</span>
                                )}
                              </td>
                              <td>
                                <span className=''>{obj?.priority ?? '-'}</span>
                              </td>
                              <td>
                                <span className=''>{obj?.status ?? '-'}</span>
                              </td>
                              <td>
                                <div
                                  className='symbol symbol-20px'
                                  data-bs-toggle='tooltip'
                                  data-bs-placement='top'
                                  title={`${obj?.assigneeId?.firstName ?? '-'} ${
                                    obj?.assigneeId?.lastName ?? '-'
                                  }`}
                                >
                                  <div className='symbol-label bg-light-info'>
                                    <span className='text-info fw-bold'>
                                      {changeTextCapital(obj?.assigneeId?.firstName[0] ?? '-')}{' '}
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr
                              className=''
                              key={`secondIndex${index}`}
                              hidden={!showSubTaskRow.includes(index)}
                            >
                              <td className='' colSpan={12}>
                                <>
                                  <div className='table-responsive'>
                                    <table className='table table-hover table-bordered table-row-dashed dataTabl'>
                                      <thead className='border '>
                                        <tr className='fw-bold '>
                                          <th className='' style={{width: '10px'}}></th>

                                          <th className='text-muted' style={{width: '320px'}}>
                                            {changeTextCapital('Task Title')}
                                          </th>
                                          <th className='text-center text-muted'>
                                            {changeTextCapital('Start Date')}
                                          </th>
                                          <th className='text-center text-muted'>
                                            {changeTextCapital('Due Date')}
                                          </th>
                                          <th className='text-center text-muted'>
                                            {changeTextCapital('priority')}
                                          </th>
                                          <th className='text-center text-muted'>
                                            {changeTextCapital('status')}
                                          </th>
                                          <th className='text-center'>
                                            <FontAwesomeIcon
                                              icon={faUser}
                                              style={{color: '#a7b7d3'}}
                                              className='text-center'
                                            />
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody className='border'>
                                        {obj?.childTasks?.map((subTask) => {
                                          return (
                                            <>
                                              <tr
                                                key={`subTask${subTask?.id}`}
                                                onClick={() => {
                                                  navigate(
                                                    `/project/${params?.id}/subTask/${subTask?.id}`
                                                  )
                                                }}
                                              >
                                                <td>
                                                  <div></div>
                                                </td>
                                                <td className='p-3'>
                                                  <div>
                                                    <div className='position-relative'>
                                                      <div className='fw-semibold ms-3'>
                                                        <span className='me-5 badge badge-pill badge-light-success p-2'>
                                                          {subTask?.ticketName ?? '-'}
                                                        </span>

                                                        {changeTextSentenceCase(
                                                          subTask?.title ?? '-'
                                                        )}
                                                      </div>

                                                      <span
                                                        className={`position-absolute h-100 w-4px rounded top-0 start-0 ${
                                                          subTask?.status === Todo
                                                            ? 'bg-primary'
                                                            : subTask?.status === In_Progress
                                                              ? 'bg-info'
                                                              : subTask?.status === Completed
                                                                ? 'bg-success'
                                                                : subTask?.status === On_Hold
                                                                  ? 'bg-warning'
                                                                  : 'bg-danger'
                                                        } `}
                                                      ></span>
                                                    </div>
                                                  </div>
                                                </td>
                                                <td className='p-3 text-center align-middle'>
                                                  {obj?.startDate === null ? (
                                                    <span className=''>{'-'}</span>
                                                  ) : (
                                                    <span className=''>
                                                      {dateFormat(obj?.startDate ?? '-')}
                                                    </span>
                                                  )}
                                                </td>
                                                <td className='p-3 text-center align-middle'>
                                                  {obj?.dueDate === null ? (
                                                    <span className=''>{'-'}</span>
                                                  ) : (
                                                    <span className=''>
                                                      {dateFormat(obj?.dueDate ?? '-')}
                                                    </span>
                                                  )}
                                                </td>
                                                <td className='p-3 text-center align-middle'>
                                                  <span className=''>
                                                    {subTask?.priority ?? '-'}
                                                  </span>
                                                </td>
                                                <td className='p-3 text-center align-middle'>
                                                  <span className=''>{subTask?.status ?? '-'}</span>
                                                </td>
                                                <td className='p-3 text-center align-middle'>
                                                  <div
                                                    className='symbol symbol-20px'
                                                    data-bs-toggle='tooltip'
                                                    data-bs-placement='top'
                                                    title={`${
                                                      subTask?.assigneeId?.firstName ?? '-'
                                                    } ${subTask?.assigneeId?.lastName ?? '-'}`}
                                                  >
                                                    <div className='symbol-label bg-light-info'>
                                                      <span className='text-info fw-bold'>
                                                        {changeTextCapital(
                                                          subTask?.assigneeId?.firstName[0] ?? '-'
                                                        )}
                                                      </span>
                                                    </div>
                                                  </div>
                                                </td>
                                              </tr>
                                            </>
                                          )
                                        })}
                                      </tbody>
                                    </table>
                                  </div>
                                </>
                              </td>
                            </tr>
                          </>
                        )
                      })}
                  </tbody>
                ) : (
                  <tbody>
                    <tr className=''>
                      <td colSpan={7} className='fw-semibold mt-5'>
                        <div className='text-center text-gray-600 fs-3'>No Records Available</div>
                        <div className='text-center text-muted fs-6'>
                          Please filter with valid User, Status, and Date{' '}
                        </div>
                        <div className='text-center'>
                          <img
                            src='/media/avatars/20-dark.png'
                            alt='noReacordAvailable'
                            className='w-70 h-100px h-sm-325px'
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            )}
          </div>
        </div>

        <div className='card-footer d-flex justify-content-between'>
          <div className='dataTables_length mt-3' id='example_length'>
            <label className='d-flex align-items-center'>
              {/* <label className='fs-6 fw-semibold mb-2 me-2'>Show</label> */}
              <select
                name='example_length'
                aria-controls='example'
                className='form-select form-select-sm'
                onChange={(e) => {
                  const selectedPageSize = parseInt(e.target.value, 10)
                  setPageSize(selectedPageSize)
                  setCurrentPage(1)
                  getTask(
                    params?.id,
                    filterOptions?.userId,
                    filterOptions?.date,
                    filterOptions?.status,
                    selectedPageSize,
                    1,
                    filterOptions.sortBy,
                    setTasks,
                    setAPIStatus
                  )
                }}
              >
                <option value='10'>10</option>
                <option value='25'>25</option>
                <option value='50'>50</option>
                <option value='100'>100</option>
              </select>
            </label>
          </div>
          <div className='mt-5'>
            {currentPage} to {Math.min(currentPage * taskPerPage, totalEntries)} of {totalEntries}
          </div>
          <Pagination className='mt-3 justify-content-center pagination-md'>
            <Pagination.Prev
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            />
            {pagesToDisplay.map((page) => (
              <Pagination.Item
                key={page}
                active={page === currentPage}
                onClick={() => handlePageChange(page)}
              >
                {page}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            />
          </Pagination>
        </div>
      </div>

      <Modal show={showTaskModal} onHide={closeAddTask} centered size='lg'>
        <div className='d-flex flex-column align-items-end pt-2 pe-5'>
          <div className='btn btn-icon btn-sm btn-active-icon-danger'>
            <i
              className='fa-solid fa-xmark fs-1'
              onClick={() => {
                cancelSubmit(setTaskData, initialStates)
                setShowTaskModal(false)
                setInputError('')
                clearImagePreviews()
              }}
            />
          </div>
        </div>
        <Modal.Header className='d-flex flex-column align-items-center'>
          <Modal.Title className='fs-1'>
            {isCreatingDefect ? 'Assign Defect' : 'Assign Task'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='scroll-y px-5 pt-0'>
            <div className='d-flex flex-column mb-5 fv-row'>
              <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                <span className='required'>
                  {' '}
                  {isCreatingDefect ? 'Defect Title' : 'Task Title'}
                </span>
              </label>
              <input
                type='text'
                className='form-control form-control-solid'
                placeholder={`Please enter ${isCreatingDefect === true ? 'Defect' : 'Task'} Title`}
                name='title'
                value={taskData.title}
                onChange={(event) => handleInputChanges(event, setTaskData)}
              />
              {<InputErrorBox Message={inputError.title} />}
            </div>
            <div className='d-flex flex-column mb-5'>
              <label className=' fs-6 fw-semibold mb-2'>Description</label>

              <div className='align-items-center border-bottom pe-5 mb-3'>
                <ReactQuill
                  style={{minHeight: '175px', maxHeight: '175px', overflowY: 'scroll'}}
                  theme='snow'
                  className='form-control'
                  placeholder='Please enter Description'
                  value={taskData.description}
                  onChange={(value) => handleOtherInputChange(value, 'description', setTaskData)}
                />
              </div>
              {<InputErrorBox Message={inputError.description} />}
            </div>
            <div className='col-lg-8 mt-5'>
              <div className='fs-6 fw-semibold mb-3'>Attachments</div>
              <>
                <div className='d-flex overflow-x-auto mb-1'>
                  {imagePreviews.map((preview, index) => (
                    <div key={index} className='me-5'>
                      <label>
                        <Image
                          maw={240}
                          className=''
                          mx='auto'
                          radius='md'
                          src={preview}
                          alt={`Preview ${index + 1}`}
                          height={150}
                          width={150}
                        />
                      </label>
                      <span
                        className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px mb-5 mt-5 shadow'
                        data-kt-image-input-action='remove'
                        data-bs-toggle='tooltip'
                        title='Remove Image'
                      >
                        <FontAwesomeIcon icon={faXmark} onClick={() => removeImage(index)} />
                      </span>
                    </div>
                  ))}
                </div>

                <div className='image-input image-input-outline mb-2'>
                  <label
                    className='btn btn-icon btn-sqaure btn-sm btn-active-color-primary w-100px h-25px ms-15 shadow'
                    data-kt-image-input-action='change'
                    data-bs-toggle='tooltip'
                    title='Add Image'
                  >
                    <FontAwesomeIcon icon={faImage} />
                    <input
                      key={Date.now()}
                      type='file'
                      name='avatar'
                      accept='.png, .jpg, .jpeg'
                      onChange={handleFileChange}
                      multiple
                    />
                    <span className='ms-2'>Upload File</span>
                  </label>
                </div>
              </>

              <div className='form-text mb-5'>Allowed file types: png, jpg, jpeg.</div>
            </div>{' '}
            <div className='row g-9'>
              <div className='col-md-4 fv-row'>
                <label className='fs-6 fw-semibold mb-2'>Start Date</label>
                <div className='position-relative d-flex align-items-center'>
                  <input
                    className='form-control form-control-solid mb-5'
                    type='date'
                    name='startDate'
                    value={taskData.startDate}
                    onChange={(event) => handleInputChanges(event, setTaskData)}
                  />
                </div>
                {<InputErrorBox Message={inputError.startDate} />}
              </div>
              <div className='col-md-4 fv-row'>
                <label className='fs-6 fw-semibold mb-2'>Due Date</label>
                <div className='position-relative d-flex align-items-center'>
                  <input
                    className='form-control form-control-solid mb-5'
                    type='date'
                    name='dueDate'
                    value={taskData.dueDate}
                    onChange={(event) => handleInputChanges(event, setTaskData)}
                  />
                </div>
                {<InputErrorBox Message={inputError.dueDate} />}
              </div>
              <div className='col-md-4 fv-row'>
                <label className='fs-6 fw-semibold mb-2'>Assignee</label>
                <Select2
                  name='assigneeId'
                  className='mb-5'
                  data={sortedProjectUser}
                  value={taskData.assigneeId.id}
                  onChange={(selectedOption: string) => {
                    setTaskData((prevTaskData) => ({
                      ...prevTaskData,
                      assigneeId: {id: selectedOption},
                    }))
                  }}
                />
                {<InputErrorBox Message={inputError.assigneeId} />}
              </div>
            </div>
            <div className='row g-9'>
              <div className='col-md-4 fv-row'>
                <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                  <span className=''>Priority</span>
                </label>
                <Select2
                  name='priority'
                  data={[
                    {label: 'Highest', value: 'Highest'},
                    {label: 'High', value: 'High'},
                    {label: 'Medium', value: 'Medium'},
                    {label: 'Low', value: 'Low'},
                    {label: 'Lowest', value: 'Lowest'},
                  ].sort((a, b) => a.label.localeCompare(b.label))}
                  value={taskData?.priority}
                  onChange={(selectedOption: any) => {
                    // console.log(selectedOption)
                    setTaskData((prevTaskData) => ({
                      ...prevTaskData,
                      priority: selectedOption,
                    }))
                  }}
                />
              </div>

              <div className='col-md-4 mb-4'>
                <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                  <span className=''>Severity</span>
                </label>

                <Select2
                  name='level'
                  data={[
                    {label: 'Easy', value: 'Easy'},
                    {label: 'Medium', value: 'Medium'},
                    {label: 'Hard', value: 'Hard'},
                  ].sort((a, b) => a.label.localeCompare(b.label))}
                  value={taskData?.level}
                  onChange={(selectedOption: any) => {
                    // console.log(selectedOption)
                    setTaskData((prevTaskData) => ({
                      ...prevTaskData,
                      level: selectedOption,
                    }))
                  }}
                />
                {<InputErrorBox Message={inputError.level} />}
              </div>

              <div className='col-md-4 mb-4'>
                <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                  <span className=''>Status</span>
                </label>

                <Select2
                  name='status'
                  data={[
                    {label: 'Todo', value: 'Todo'},
                    {label: 'On Hold', value: 'On Hold'},
                    {label: 'In Progress', value: 'In Progress'},
                    {label: 'Completed', value: 'Completed'},
                  ].sort((a, b) => a.label.localeCompare(b.label))}
                  value={taskData.status}
                  onChange={(selectedOption: any) => {
                    setTaskData((prevTaskData) => ({
                      ...prevTaskData,
                      status: selectedOption,
                    }))
                  }}
                />
              </div>
            </div>
            {/* <div className='row g-6'></div> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='text-center'>
            <Button
              variant='light'
              onClick={() => {
                cancelSubmit(setTaskData, initialStates)
                setShowTaskModal(false)
                setInputError('')
                clearImagePreviews()
              }}
              className='me-3'
            >
              Cancel
            </Button>
            <Button
              variant='filled'
              color='green'
              type='submit'
              loading={SubmitAPIStatus.loading}
              className=''
              onClick={() => {
                handleSubmit(
                  params?.id,
                  filterOptions.userId,
                  filterOptions.date,
                  filterOptions.status,
                  filterOptions?.take,
                  filterOptions?.page,
                  filterOptions?.sortBy,
                  taskData,
                  // setTask,
                  initialStates,
                  setTaskData,
                  setAPIStatus,
                  setInputError,
                  setSubmitAPIStatus,
                  cancelSubmit,
                  closeAddTask,
                  setShowTaskModal,
                  validateTaskForm,
                  getTask,
                  setTasks,
                  closeProject,
                  clearImagePreviews,
                  isCreatingDefect
                )
              }}
            >
              Submit
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Task
