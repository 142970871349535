import React, {useEffect, useState} from 'react'
import {
  cancelSubmit,
  getTask,
  handleInputChanges,
  handleOtherInputChange,
  handleSubmit,
  validateTaskForm,
} from './SubTaskFunction'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faAngleUp,
  faChevronDown,
  faImage,
  faTasks,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import {Col, Modal, Row} from 'react-bootstrap'
import {Link, useNavigate, useParams} from 'react-router-dom'
import Select2 from '../../../components/select2'
import {setupAxios, useAuth} from '../../../modules/auth'
import {
  API_BASE_URL,
  Completed,
  High,
  Highest,
  In_Progress,
  Low,
  Lowest,
  Medium,
  On_Hold,
  Todo,
} from '../../../../utils/contants'
import axios from 'axios'
import {Badge, Button, Image} from '@mantine/core'
import ReactQuill from 'react-quill'
import InputErrorBox from '../../../components/InputErrorBox'

const SubTask = ({data, setTaskData}) => {
  const params = useParams()
  const {currentUser} = useAuth()

  const [showSubTaskModal, setShowSubTaskModal] = useState(false)
  const tagOptions = ['Highest', 'High', 'Medium', 'Low', 'Lowest']
  const tag = ['Easy', 'Medium', 'Hard']

  const [inputError, setInputError] = useState<any>({})

  const [SubmitAPIStatus, setSubmitAPIStatus] = useState({
    loading: false,
  })

  const addSubTask = () => {
    setShowSubTaskModal(true)
  }
  const closeAddSubTask = () => {
    setShowSubTaskModal(false)
  }

  const [projectUsers, setProjectUsers] = useState({
    projectUsers: [{status: '', role: '', user: {id: '', firstName: '', lastName: ''}}],
  })

  const initialStates = {
    title: '',
    description: '',
    startDate: '',
    dueDate: '',
    endDate: '',
    priority: '',
    status: 'Todo',
    level: '',
    project: {
      id: params?.id,
    },
    createdBy: {
      id: currentUser?.id,
    },
    assigneeId: {
      id: projectUsers?.projectUsers[0]?.user?.id,
    },
    assignerId: {
      id: currentUser?.id,
    },
    parentTask: {
      id: params?.id || '',
    },
    file: [] as File[],
  }

  const [subTaskData, setSubTaskData] = useState(initialStates)

  const [tasks, setTasks] = useState([
    {
      id: '',
      title: '',
      description: '',
      startDate: '',
      dueDate: '',
      endDate: '',
      priority: '',
      status: '',
      ticketName: '',
      assigneeId: {
        id: '',
        firstName: '',
        lastName: '',
      },
      project: {
        name: '',
      },
      assignerId: {
        id: '',
        firstName: '',
        lastName: '',
      },
      Comment: [],
    },
  ])

  const [currentPage, setCurrentPage] = useState(1)
  const [taskPerPage] = useState(5)
  const totalPages = Math.ceil(tasks.length / taskPerPage)
  const pageRange = 1
  const pagesToDisplay: number[] = []
  for (let i = currentPage - pageRange; i <= currentPage + pageRange; i++) {
    if (i >= 1 && i <= totalPages) {
      pagesToDisplay.push(i)
    }
  }
  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const [isimageSelected, setIsImageSelected] = useState(false)
  const [imageFiles, setImageFiles] = useState<File[]>([])
  const [imagePreviews, setImagePreviews] = useState<string[]>([])

  const iconBeforeSelect = faImage

  const [APIStatus, setAPIStatus] = useState({
    loading: false,
    error: null,
    submitLoading: false,
  })

  const [visibleSubTask, setVisibleSubTask] = useState(3)
  const [subTaskPerPage, setSubTaskPerPage] = useState(3)

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files) {
      const fileArray = Array.from(files)
      const imageArray = fileArray.filter((file) => file.type.startsWith('image/'))

      const imagePreviewsArray = imageArray.map((file) => URL.createObjectURL(file))

      setImageFiles((prevFiles) => [...prevFiles, ...imageArray])
      setImagePreviews((prevPreviews) => [...prevPreviews, ...imagePreviewsArray])

      setSubTaskData((prevsubTaskData) => ({
        ...prevsubTaskData,
        file: [...prevsubTaskData.file, ...imageArray],
      }))

      setIsImageSelected(true)
    }
  }

  const removeImage = (index: number) => {
    const updatedFiles = [...imageFiles]
    const updatedPreviews = [...imagePreviews]

    updatedFiles.splice(index, 1)
    updatedPreviews.splice(index, 1)

    setImageFiles(updatedFiles)
    setImagePreviews(updatedPreviews)

    setSubTaskData((prevsubTaskData) => ({
      ...prevsubTaskData,
      file: updatedFiles,
    }))
  }

  const clearImagePreviews = () => {
    setImageFiles([])
    setImagePreviews([])
  }

  const [projectUser, setProjectUser] = useState<{label: string; value: string}[]>([])
  const sortedProjectUser = projectUser.slice().sort((a, b) => a.label.localeCompare(b.label))

  useEffect(() => {
    let mounted = true
    if (mounted) {
      setCurrentPage(1)

      // getTask(
      //   params?.id,
      //   filterOptions.userId,
      //   filterOptions.date,
      //   filterOptions.status,
      //   setTasks,
      //   setAPIStatus
      // )
      fetchprojectUserNames()
    }
    return () => {
      mounted = false
    }
  }, [])

  const fetchprojectUserNames = () => {
    const {token, tenantId} = setupAxios()
    axios
      .get(API_BASE_URL + `/project/${params?.projectId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-Tenant-Id': `${tenantId}`,
        },
      })
      .then((response: any) => {
        let userData: {label: string; value: string}[] = []
        response?.data?.data?.forEach((project) => {
          project?.projectUsers?.forEach((user) => {
            userData.push({
              label: `${user.user.firstName} ${user.user.lastName}`,
              value: user.user.id,
            })
          })
        })
        setProjectUser(userData)
      })
      .catch((error) => {
        console.error('Error fetching names:', error)
      })
  }

  return (
    <>
      {/* {console.log('data', data)} */}
      <div className='card mb-5 ms-5 me-5'>
        <div className='card-Header'>
          <div className='fw-bold fs-3 ms-5 me-5 card-title d-flex justify-content-between mt-5'>
            Sub Task
            <div className='d-flex mb-5 ms-5'>
              <Button
                variant='light'
                className=''
                onClick={() => {
                  addSubTask()
                  fetchprojectUserNames()
                }}
              >
                <FontAwesomeIcon icon={faTasks} />
                <span className='ms-2'>Create</span>
              </Button>
            </div>
          </div>
        </div>
        <div className='card-body p-3' style={{maxHeight: '200px', overflowY: 'scroll'}}>
          {data?.map((obj) => {
            return obj?.subTasks.length > 0 ? (
              obj?.subTasks.slice(0, visibleSubTask).map((subTask) => (
                <Link to={`/project/${params?.projectId}/subTask/${subTask?.id}`} key={subTask?.id}>
                  <Row
                    style={{borderBottom: '1px solid #ddd'}}
                    className='bg-hover-light-primary rounded'
                  >
                    <Col md={8} className='mt-4 mb-3'>
                      <div className='d-flex'>
                        <span
                          className={`badge badge-light-${
                            subTask?.isDefect ? 'danger' : 'primary'
                          } p-2 mb-3`}
                        >
                          {subTask?.ticketName}
                        </span>
                        <span className='ms-2 fw-semibold mb-3 mt-1 text-dark fs-6'>
                          {subTask?.title}
                        </span>
                      </div>
                    </Col>
                    <Col md={4} className=''>
                      <div className='d-flex justify-content-end mt-6 mb-3'>
                        <Badge
                          variant='outline'
                          color={`${
                            subTask.priority === Highest
                              ? 'red'
                              : subTask.priority === High
                                ? 'yellow'
                                : subTask.priority === Medium
                                  ? 'violet'
                                  : subTask.priority === Low
                                    ? 'green'
                                    : subTask.priority === Lowest
                                      ? 'blue'
                                      : 'blue'
                          }`}
                          className='ms-2 mb-3 me-2'
                          style={{textAlign: 'center'}}
                        >
                          {subTask?.priority ?? '-'}
                        </Badge>
                        <Badge
                          variant='outline'
                          color={`${
                            subTask.status === Todo
                              ? 'blue'
                              : subTask.status === In_Progress
                                ? 'violet'
                                : subTask.status === Completed
                                  ? 'green'
                                  : subTask.status === On_Hold
                                    ? 'yellow'
                                    : 'red'
                          }`}
                          className='ms-2 mb-3 me-2'
                          style={{textAlign: 'center'}}
                        >
                          {subTask?.status}
                        </Badge>
                      </div>
                    </Col>
                  </Row>
                </Link>
              ))
            ) : (
              <div className='text-center'>No Sub Tasks Available.</div>
            )
          })}
        </div>
        <div className=''>
          {' '}
          {data?.map((obj) => {
            const remainingSubTasks = obj?.subTasks.length - visibleSubTask
            return remainingSubTasks > 0 ? (
              <div className='d-flex justify-content-center'>
                <Button
                  variant='subtle'
                  radius='xl'
                  color=''
                  onClick={() => setVisibleSubTask((prev) => prev + subTaskPerPage)}
                  loading={APIStatus.loading}
                >
                  <FontAwesomeIcon icon={faAngleUp} className='me-2 mt-1' /> Load More
                </Button>
              </div>
            ) : (
              remainingSubTasks < 0 && (
                <div className='d-flex justify-content-center'>
                  <Button
                    variant='subtle'
                    radius='xl'
                    color=''
                    onClick={() => setVisibleSubTask((prev) => Math.max(prev - subTaskPerPage, 0))}
                    loading={SubmitAPIStatus.loading}
                  >
                    <FontAwesomeIcon icon={faChevronDown} className='me-2 mt-1' />
                    Show Less{' '}
                  </Button>
                </div>
              )
            )
          })}
        </div>
      </div>

      <Modal show={showSubTaskModal} onHide={closeAddSubTask} centered size='lg'>
        <div className='d-flex flex-column align-items-end pt-2 pe-5'>
          <div className='btn btn-icon btn-sm btn-active-icon-danger'>
            <i
              className='fa-solid fa-xmark fs-1'
              onClick={() => {
                cancelSubmit(setSubTaskData, initialStates)
                setShowSubTaskModal(false)
                setInputError('')
                clearImagePreviews()
              }}
            />
          </div>
        </div>
        <Modal.Header className='d-flex flex-column align-items-center'>
          <Modal.Title className='fs-1'>Sub Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='scroll-y px-5 pt-0'>
            <div className='d-flex flex-column mb-5 fv-row'>
              <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                <span className='required'>Sub Task Title</span>
              </label>
              <input
                type='text'
                className='form-control form-control-solid'
                placeholder='Please enter Task Title'
                name='title'
                value={subTaskData.title}
                onChange={(event) => handleInputChanges(event, setSubTaskData)}
              />
              {<InputErrorBox Message={inputError.title} />}
            </div>
            <div className='d-flex flex-column mb-5'>
              <label className=' fs-6 fw-semibold mb-2'>Description</label>

              <div className='align-items-center border-bottom pe-5 mb-3'>
                <ReactQuill
                  style={{minHeight: '175px'}}
                  theme='snow'
                  className='form-control'
                  placeholder='Please enter Description'
                  value={subTaskData.description}
                  onChange={(value) => handleOtherInputChange(value, 'description', setSubTaskData)}
                />
              </div>
              {<InputErrorBox Message={inputError.description} />}
            </div>
            <div className='col-lg-8 mt-5'>
              <div className='fs-6 fw-semibold mb-3'>Attachments</div>
              <>
                <div className='d-flex overflow-x-auto mb-1'>
                  {imagePreviews.map((preview, index) => (
                    <div key={index} className='me-5'>
                      <label>
                        <Image
                          maw={240}
                          className=''
                          mx='auto'
                          radius='md'
                          src={preview}
                          alt={`Preview ${index + 1}`}
                          height={150}
                          width={150}
                        />
                      </label>
                      <span
                        className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px mb-5 mt-5 shadow'
                        data-kt-image-input-action='remove'
                        data-bs-toggle='tooltip'
                        title='Remove Image'
                      >
                        <FontAwesomeIcon icon={faXmark} onClick={() => removeImage(index)} />
                      </span>
                    </div>
                  ))}
                </div>

                <div className='image-input image-input-outline mb-2'>
                  <label
                    className='btn btn-icon btn-sqaure btn-sm btn-active-color-primary w-100px h-25px ms-15 shadow'
                    data-kt-image-input-action='change'
                    data-bs-toggle='tooltip'
                    title='Add Image'
                  >
                    <FontAwesomeIcon icon={faImage} />
                    <input
                      key={Date.now()}
                      type='file'
                      name='avatar'
                      accept='.png, .jpg, .jpeg'
                      onChange={handleFileChange}
                      multiple
                    />
                    <span className='ms-2'>Upload File</span>
                  </label>
                </div>
              </>

              <div className='form-text mb-5'>Allowed file types: png, jpg, jpeg.</div>
            </div>{' '}
            <div className='row g-9'>
              <div className='col-md-4 fv-row'>
                <label className=' fs-6 fw-semibold mb-2'>Start Date</label>
                <div className='position-relative d-flex align-items-center'>
                  <input
                    className='form-control mb-5'
                    type='date'
                    name='startDate'
                    value={subTaskData.startDate}
                    onChange={(event) => handleInputChanges(event, setSubTaskData)}
                  />
                </div>
                {<InputErrorBox Message={inputError.startDate} />}
              </div>

              <div className='col-md-4 fv-row'>
                <label className=' fs-6 fw-semibold mb-2'>Due Date</label>
                <div className='position-relative d-flex align-items-center'>
                  <input
                    className='form-control mb-5'
                    type='date'
                    name='dueDate'
                    value={subTaskData.dueDate}
                    onChange={(event) => handleInputChanges(event, setSubTaskData)}
                  />
                </div>
                {<InputErrorBox Message={inputError.dueDate} />}
              </div>

              <div className='col-md-4 fv-row'>
                <label className=' fs-6 fw-semibold mb-2'>Assignee</label>
                <Select2
                  name='assigneeId'
                  className='mb-5'
                  data={sortedProjectUser}
                  value={subTaskData.assigneeId?.id}
                  onChange={(selectedOption: string) => {
                    setSubTaskData((prevsubTaskData) => ({
                      ...prevsubTaskData,
                      assigneeId: {id: selectedOption},
                    }))
                  }}
                />
                {<InputErrorBox Message={inputError.assigneeId} />}
              </div>
            </div>
            <div className='row g-9'>
              <div className='col-md-4 fv-row'>
                <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                  <span className=''>Priority</span>
                </label>
                <Select2
                  name='priority'
                  data={[
                    {label: 'Highest', value: 'Highest'},
                    {label: 'High', value: 'High'},
                    {label: 'Medium', value: 'Medium'},
                    {label: 'Low', value: 'Low'},
                    {label: 'Lowest', value: 'Lowest'},
                  ].sort((a, b) => a.label.localeCompare(b.label))}
                  value={subTaskData?.priority}
                  onChange={(selectedOption: any) => {
                    // console.log(selectedOption)
                    setSubTaskData((prevSubTaskData) => ({
                      ...prevSubTaskData,
                      priority: selectedOption,
                    }))
                  }}
                />
              </div>

              <div className='col-md-4 mb-4'>
                <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                  <span className=''>Severity</span>
                </label>

                <Select2
                  name='level'
                  data={[
                    {label: 'Easy', value: 'Easy'},
                    {label: 'Medium', value: 'Medium'},
                    {label: 'Hard', value: 'Hard'},
                  ].sort((a, b) => a.label.localeCompare(b.label))}
                  value={subTaskData?.level}
                  onChange={(selectedOption: any) => {
                    // console.log(selectedOption)
                    setSubTaskData((prevSubTaskData) => ({
                      ...prevSubTaskData,
                      level: selectedOption,
                    }))
                  }}
                />
                {<InputErrorBox Message={inputError.level} />}
              </div>

              <div className='col-md-4 mb-4'>
                <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                  <span className=''>Status</span>
                </label>

                <Select2
                  name='status'
                  data={[
                    {label: 'Todo', value: 'Todo'},
                    {label: 'On Hold', value: 'On Hold'},
                    {label: 'In Progress', value: 'In Progress'},
                    {label: 'Completed', value: 'Completed'},
                  ].sort((a, b) => a.label.localeCompare(b.label))}
                  value={subTaskData?.status}
                  onChange={(selectedOption: any) => {
                    setSubTaskData((prevSubTaskData) => ({
                      ...prevSubTaskData,
                      status: selectedOption,
                    }))
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='text-center'>
            <Button
              variant='light'
              onClick={() => {
                cancelSubmit(setSubTaskData, initialStates)
                setShowSubTaskModal(false)
                setInputError('')
                clearImagePreviews()
              }}
              className='me-3'
            >
              Cancel
            </Button>
            <Button
              variant='filled'
              color='green'
              type='submit'
              loading={SubmitAPIStatus.loading}
              className=''
              onClick={() => {
                handleSubmit(
                  params?.id,
                  params?.projectId,
                  subTaskData,
                  // setTask,
                  initialStates,
                  setSubTaskData,
                  setAPIStatus,
                  setInputError,
                  setSubmitAPIStatus,
                  cancelSubmit,
                  closeAddSubTask,
                  setShowSubTaskModal,
                  validateTaskForm,
                  getTask,
                  setTasks,
                  setTaskData,
                  clearImagePreviews
                )
              }}
            >
              Submit
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default SubTask
