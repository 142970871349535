import {Button} from '@mantine/core'
import {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {formatDate, formatSenderfirstlastInitials} from '../../../_metronic/helpers/AssetHelpers'
import {useAuth} from '../../modules/auth'

import {getSimpleEmail, updatestatusById} from './Email'

export interface Email {
  id: string
  subject: string
  body: string
  sender: {
    id: string
    firstName: string
    lastName: string
  }
  createAt: string
  emailRecivers: {
    id: string
    type: string
    receiver: {
      id: string
      firstName: string
      lastName: string
    }
  }[]
  LeaveEmails: {
    id: string | null
    startDate: string
    endDate: string
    status: string
    type: string
    remark: string | null
    createAt: string
    receiver: {
      id: string
      firstName: string
      lastName: string
    }
  }[]
}

const Mail = () => {
  const {currentUser} = useAuth()
  const params = useParams()
  const userId = params['id'] ?? (currentUser?.id || '')
  const [email, setEmail] = useState<Email | null>(null)
  const initialState = {
    remark: '',
  }
  const [inputField, setInputField] = useState(initialState)

  const [editId, setEditId] = useState('null')

  const [inputError, setInputError] = useState<any>({})
  const [SubmitAPIStatus, setSubmitAPIStatus] = useState({
    loading: false,
  })

  useEffect(() => {
    let mounted = true

    if (mounted && SubmitAPIStatus.loading === false) {
      getSimpleEmail(userId, setEmail, setAPIStatus)
    }

    return () => {
      mounted = false
    }
  }, [userId, currentUser, SubmitAPIStatus])

  const [APIStatus, setAPIStatus] = useState({
    loading: false,
    error: null,
    submitLoading: false,
  })

  
  const formatRecipients = (emailRecivers, type) => {
    const recipients = emailRecivers
      .filter((recipient) => recipient.type === type)
      .map((recipient) => `${recipient.receiver.firstName} ${recipient.receiver.lastName} `)
      .join(', ')

    return recipients ? `${type === 'to' ? 'To:' : 'Cc:'} ${recipients}` : ''
  }

 
  return (
    <>
      {console.log(email)}
      {console.log('currentUser:', currentUser)}
      <div className='flex-lg-row-fluid'>
        {email ? (
          <div className='card' key={email.id}>
            <div className='card-header align-items-center py-5 gap-5'>
              <div className='d-flex'>
                <Link
                  to='/emails'
                  className='btn btn-sm btn-icon btn-clear btn-active-light-primary me-3'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  aria-label='Back'
                  data-bs-original-title='Back'
                  data-kt-initialized='1'
                >
                  <i className='fa-solid fa-arrow-left'></i>
                </Link>
                <div className='d-flex flex-wrap gap-2 justify-content-between '>
                  <div className='d-flex align-items-center flex-wrap gap-2'>
                    <h2 className='fw-semibold me-3 my-1'>{email.subject ?? '-'}</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className='d-flex align-items-center'></div>

            <div className='card-body'>
              <div data-kt-inbox-message='message_wrapper'>
                <div
                  className='d-flex flex-wrap gap-2 flex-stack cursor-pointer'
                  data-kt-inbox-message='header'
                >
                  <div className='d-flex align-items-center'>
                    <div className='pe-5'>
                      <div className='d-flex'>
                        <div className='symbol symbol-35px me-3'>
                          <div className='symbol-label bg-light-danger'>
                            <span className='text-danger'>
                              {formatSenderfirstlastInitials(email?.sender)}
                            </span>
                          </div>
                        </div>
                        <div className='mt-2'>
                          {email?.sender.firstName} {email?.sender.lastName}
                        </div>
                      </div>

                      <div className='d-flex align-items-center flex-wrap gap-1'>
                        <div className='d-flex align-items-center flex-wrap gap-1'>
                          {formatRecipients(email.emailRecivers, 'to')}
                          <br />
                          {formatRecipients(email.emailRecivers, 'cc')}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='d-flex align-items-center flex-wrap gap-2'>
                    <span className='fw-semibold text-muted text-end me-3'>
                      {formatDate(email?.createAt ?? '-')}
                    </span>
                  </div>
                </div>

                <div className='collapse fade show' data-kt-inbox-message='message'>
                  <div className='py-5' dangerouslySetInnerHTML={{__html: email?.body ?? '-'}} />
                </div>
              </div>

              <div className='separator my-6'></div>
              {email?.LeaveEmails?.length > 0 &&
                email.LeaveEmails.map((leaveEmail) => (
                  <div key={leaveEmail.id} className={`${editId === leaveEmail.id}`}>
                    <div
                      className='d-flex flex-wrap gap-2 flex-stack cursor-pointer'
                      data-kt-inbox-message='header'
                    >
                      <div className='d-flex align-items-center'>
                        <div className='pe-5'>
                          <div className='d-flex align-items-center flex-wrap gap-1'>
                            <a href='#' className='fw-bold text-dark text-hover-primary'>
                              {leaveEmail.type ?? '-'} Day Leave
                            </a>
                            <i className='ki-outline ki-abstract-8 fs-7 text-success mx-3'></i>{' '}
                            <span className='text-muted fw-bold'>
                              {formatDate(leaveEmail.startDate ?? '-')}
                            </span>
                            <span className='text-muted fw-bold'> To</span>
                            <span className='text-muted fw-bold'>
                              {formatDate(leaveEmail.endDate ?? '-')}
                            </span>
                          </div>

                          <div
                            className='text-muted fw-semibold mw-450px mt-2'
                            data-kt-inbox-message='preview'
                          >
                            {leaveEmail.remark ?? '-'}
                          </div>
                        </div>
                      </div>

                     
                    </div>
                  </div>
                ))}
              
            </div>
          </div>
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </>
  )
}

export default Mail
