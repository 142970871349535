import {toast} from 'react-toastify'
import {Message} from '../../../_metronic/helpers'

import {addAsset, addAssetWithUser, getAssets, getAssetsById} from '../../../api/AssetsAPI'
import {getUserAsset} from './AssignAssetsFunction'

export const getAsset = (setAsset: Function, setAPIStatus: Function) => {
  setAPIStatus((prev: any) => ({...prev, loading: true}))
  getAssets().then((res: any) => {
    if (res.statusCode === 200) {
      const assetsWithIndex = res.data.map((assets: any, index: number) => ({
        ...assets,
        index: index + 1,
      }))
      setAsset(assetsWithIndex)
      setAsset(res.data)

      setAPIStatus((prev: any) => ({...prev, loading: false}))
    } else {
      setAPIStatus((prev: any) => ({...prev, loading: false, error: res.message}))
    }
  })
}

// form save function

export const handleSubmit = (
  InputField: {
    category: string
    subCategory: string
    brand: string
    serialNo: string
    description: string
    purchaseDate: string

    status: string
    assignee: string
  },
  // getAsset: Function,
  setAsset: Function,
  setEditId: Function,
  initialState: {
    category: string
    subCategory: string
    brand: string
    serialNo: string
    description: string
    purchaseDate: string

    status: string
    assignee: string
  },
  setInputField: Function,
  editId: string | null,
  cancelSubmit: Function,
  setAPIStatus: Function,
  setInputError: Function,
  validateForm: Function,
  setSubmitAPIStatus: Function,
  setIsAddUserModalOpen: Function,
  fetchAssets: Function,
  setIsCommentPopupOpen: Function
) => {
  let {cnt, error} = validateForm(InputField)
  setInputError(error)

  if (cnt === 0) {
    if (editId == null) {
      setSubmitAPIStatus((prev: any) => ({...prev, loading: true}))
      addAsset(InputField).then((res) => {
        if (res.statusCode === 200) {
          toast.success(res.message)
          getAsset(setAsset, setAPIStatus)
          fetchAssets()
          cancelSubmit(setInputField, initialState, setEditId, setInputError)
          setIsAddUserModalOpen(false)
        } else {
          Message(res.message, 'error')
        }
        setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
      })
    } else {
      setIsCommentPopupOpen(true)
      setIsAddUserModalOpen(false)
      // Swal.fire({...AlertProps, icon: 'warning', title: UPDATE_MESSAGE}).then((result: any) => {
      //   if (result.isConfirmed) {
      //     // setSubmitAPIStatus((prev: any) => ({...prev, loading: true}))

      //   }
      // })
    }
  } else {
    console.log('Validation Errors:', error)
  }
}
export const handleFormFieldSubmit = (
  formField: {
    user: string
    issueDate: string
    location: string
    locationType: string
  },
  setAsset: Function,
  setEditId: Function,
  initialState: {
    user: string
    issueDate: string
    location: string
    locationType: string
  },
  setFormField: Function,
  editId: string | null,
  cancelSubmit: Function,
  setAPIStatus: Function,
  setInputError: Function,
  validateFormField: Function,
  setSubmitAPIStatus: Function,
  setModalOpen: Function,
  fetchAssets: Function,
  setUserAseet: Function
) => {
  let {cnt, error} = validateFormField(formField)
  setInputError(error)

  if (cnt === 0) {
    // setSubmitAPIStatus((prev: any) => ({ ...prev, loading: true }));
    addAssetWithUser(formField, editId).then((res: any) => {
      if (res.statusCode === 200) {
        getAsset(setAsset, setAPIStatus)
        getUserAsset(setUserAseet, setAPIStatus)
        toast.success(res.message)
        cancelSubmit(setFormField, initialState, setEditId, setInputError)
        setEditId(null)
        setModalOpen(false)
      } else {
        Message(res.message, 'error')
      }
      // setSubmitAPIStatus((prev: any) => ({ ...prev, loading: false })); // Set loading to false here
    })
  } else {
    console.log('Validation Errors:', error)
  }
}

export const handleInputChange = (
  event: React.SyntheticEvent,
  setInputField: Function,
  InputField: {
    category: string
    subCategory: string
    brand: string
    serialNo: string
    description: string
    purchaseDate: string
    returnDate: string
    status: string
    assignee: string
  }
) => {
  let target = event.target as HTMLInputElement
  if (target.name === 'locationType') {
    const defaultLocation = target.value === 'onsite' ? 'Pune' : ''
    setInputField({
      ...InputField,
      [target.name]: target.value,
      location: defaultLocation,
    })
  } else if (target.name.startsWith('userAssets.')) {
    const userAssetsField = target.name.split('userAssets.')[1]

    setInputField((prevInputField) => ({
      ...prevInputField,
      userAssets: {
        ...prevInputField.userAssets,
        [userAssetsField]: target.value,
      },
    }))
  } else {
    setInputField({
      ...InputField,
      [target.name]: target.type === 'checkbox' ? target.checked : target.value,
    })
  }
}

export const handleFormFieldChange = (
  event: React.SyntheticEvent,
  setFormField: Function,
  FormField: {
    user: string
    issueDate: string
    locationType: string
    location: string
  }
) => {
  let target = event.target as HTMLInputElement
  if (target.name === 'locationType') {
    const defaultLocation = target.value === 'onsite' ? 'Pune' : ''
    setFormField({
      ...FormField,
      [target.name]: target.value,
      location: defaultLocation,
    })
  } else if (target.name.startsWith('userAssets.')) {
    const userAssetsField = target.name.split('userAssets.')[1]

    setFormField((prevInputField) => ({
      ...prevInputField,
      userAssets: {
        ...prevInputField.userAssets,
        [userAssetsField]: target.value,
      },
    }))
  } else {
    setFormField({
      ...FormField,
      [target.name]: target.type === 'checkbox' ? target.checked : target.value,
    })
  }
}

export const getAssetById = (
  id: string,
  InputField: {
    category: string
    subCategory: string
    brand: string
    serialNo: string
    description: string
    purchaseDate: string

    status: string
    assignee: string
  },
  setInputField: Function,
  setEditId: Function,
  setInputError: Function
) => {
  getAssetsById(id).then((res: any) => {
    if (res) {
      setInputField({
        ...InputField,
        category: res.data.category,
        subCategory: res.data.subCategory,
        brand: res.data.brand,
        serialNo: res.data.serialNo,
        description: res.data.description,
        purchaseDate: res.data.purchaseDate,
        issueDate: res.data.userAssets.issueDate,
      })
      setEditId(id)
      setInputError({})
      window.scrollTo(0, 0)
    } else {
      Message(res.message, 'error')
    }
  })
}

export const validateForm = (InputField: {
  category: string
  subCategory: string
  brand: string
  serialNo: string
  description: string
  purchaseDate: string
  returnDate: string
  status: string
  assignee: string
}) => {
  let cnt = 0
  const error: any = {}
  if (!InputField.category || !InputField.category.trim()) {
    cnt = cnt + 1
    error.category = 'Please select a Category.'
  }
  if (!InputField.subCategory || !InputField.subCategory.trim()) {
    cnt = cnt + 1
    error.subCategory = 'Please select a Sub Category.'
  }

  if (!InputField.brand || !InputField.brand.trim()) {
    cnt = cnt + 1
    error.brand = 'Please enter a Asset Brand.'
  } else if (InputField.brand.length > 35) {
    cnt = cnt + 1
    error.brand = 'Asset Brand  must not exceed 35 characters.'
  }

  if (!InputField.serialNo || !InputField.serialNo.trim()) {
    cnt = cnt + 1
    error.serialNo = 'Please enter a Serial No.'
  } else if (!/^[a-zA-Z0-9@._-]+$/.test(InputField.serialNo)) {
    cnt = cnt + 1
    error.serialNo =
      'Please enter a valid Serial No. using only alphanumeric characters with this special symbol [@,.,_,-].'
  } else if (InputField.serialNo.length > 50) {
    cnt = cnt + 1
    error.serialNo = 'Serial No. must not exceed 50 characters.'
  } else if (InputField.serialNo.length < 5) {
    cnt = cnt + 1
    error.serialNo = 'Serial No. must be at least 5 characters.'
  }
  if (!InputField.purchaseDate || !InputField.purchaseDate.trim()) {
    cnt = cnt + 1
    error.purchaseDate = 'Please select/enter a valid Purchase Date.'
  } else {
    const selectedDate = new Date(InputField.purchaseDate)
    const currentDate = new Date()

    if (selectedDate > currentDate) {
      cnt = cnt + 1
      error.purchaseDate = 'Purchase Date must not be greater than the current date.'
    }
  }

  if (!InputField.description || !InputField.description.trim()) {
    cnt = cnt + 1
    error.description = 'Please enter a Description.'
  } else if (InputField.description.length > 500) {
    cnt = cnt + 1
    error.description = 'Description must not exceed 500 characters.'
  }
  return {error, cnt}
}
export const validateFormField = (formField: {
  user: string
  issueDate: string
  locationType: string
  location: string
}) => {
  let cnt = 0
  const error: any = {}

  if (!formField?.user) {
    cnt = cnt + 1
    error.user = 'Please select a User Name.'
  }
  if (!formField?.issueDate || !formField?.issueDate.trim()) {
    cnt = cnt + 1
    error.issueDate = 'Please select/enter a valid Issue Date.'
  } else {
    const selectedDate = new Date(formField.issueDate)
    const currentDate = new Date()

    if (selectedDate > currentDate) {
      cnt = cnt + 1
      error.issueDate = 'Issue Date must not be greater than the current date.'
    }
  }
  if (!formField.locationType || !formField.locationType.trim()) {
    cnt = cnt + 1
    error.locationType = 'Please select a Onsite/Remote.'
  }
  if (!formField.location || !formField.location.trim()) {
    cnt = cnt + 1
    error.location = 'Please enter a Location.'
  }
  return {error, cnt}
}

export const cancelSubmit = (
  setInputField: Function,
  InputField: {
    category: string
    subCategory: string
    brand: string
    serialNo: string
    description: string
    purchaseDate: string

    status: string
    assignee: string
  },
  setEditId: Function,
  setInputError: Function
) => {
  setEditId(null)
  setInputField(InputField)
  setInputError({})
}

export const cancelFormSubmit = (
  setFormField: Function,
  formField: {
    user: string
    issueDate: string
  },
  setEditId: Function,
  setInputError: Function
) => {
  setEditId(null)
  setFormField(formField)
  setInputError({})
}
