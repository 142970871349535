import React, {HtmlHTMLAttributes} from 'react'
import {AnyObjectSchema} from 'yup'
import {getProjects, getProjectsById, updateProjectById} from '../../../../api/Organization'
import {toast} from 'react-toastify'
import stripTags from 'striptags'
import {Message} from '../../../../_metronic/helpers'

export const handleInputChange = (event: React.SyntheticEvent, setInputField: Function) => {
  let target = event.target as HTMLInputElement
  setInputField((InputField: AnyObjectSchema) => ({
    ...InputField,
    [target.name]: target.type === 'checkbox' ? target.checked : target.value,
  }))
}

export const handleOtherInputChange = (
  value: string,
  inputName: string,
  setInputField: Function
) => {
  setInputField((InputField: AnyObjectSchema) => ({
    ...InputField,
    [inputName]: value,
  }))
}

export const updateProject = (
  id,
  InputField,
  currentUser,
  setProject: Function,
  setAPIStatus: Function
) => {
  setAPIStatus((prev: any) => ({...prev, loading: true}))
  // console.log(InputField, currentUser)
  updateProjectById(id, currentUser, InputField).then((res: any) => {
    if (res.statusCode === 200) {
      // console.log(res)
      toast.success(res.message)

      getProjectsById(id).then((res) => {
        // console.log(res.data[0])
        setProject(res.data[0] ?? {})
      })

      setAPIStatus((prev: any) => ({...prev, loading: false}))
    } else {
      Message(res.message, 'error')
      setAPIStatus((prev: any) => ({...prev, loading: false, error: res.message}))
    }
  })
}
// }

export const validateUpdateForm = ({
  name,
  category,
  subCategory,
  type,
  startDate,
  dueDate,
  endDate,
  status,
  description,
}) => {
  let count = 0
  const error: any = {}
  const descriptionWithoutTags = stripTags(description).trim()

  if (!name.trim()) {
    count = count + 1
    error.name = 'Please enter a Project Name.'
  } else if (!/^[a-zA-Z0-9\s]+$/.test(name)) {
    count = count + 1
    error.name = 'Project Name should only contain alphanumeric characters.'
  } else if (name.length > 50) {
    count = count + 1
    error.name = 'Project Name must not exceed 50 characters.'
  } else if (name.length < 2) {
    count = count + 1
    error.name = 'Project Name must be at least 2 characters.'
  }
  if (!category.trim()) {
    count = count + 1
    error.category = 'Please select a Category.'
  }
  if (!subCategory.trim()) {
    count = count + 1
    error.subCategory = 'Please select a Sub-Category.'
  }
  if (!type.trim()) {
    count = count + 1
    error.type = 'Please select a Project Type.'
  }
  if (!startDate.trim()) {
    count = count + 1
    error.startDate = 'Please select/enter a Project Start Date.'
  }
  if (!dueDate.trim()) {
    count = count + 1
    error.dueDate = 'Please select/enter a Project Due Date.'
  } else {
    const selectedStartDate = new Date(startDate)
    const selectedDueDate = new Date(dueDate)
    if (selectedDueDate < selectedStartDate) {
      count = count + 1
      error.dueDate = 'Project Due Date must not be earlier than the Project Start Date.'
    }
  }
  if (!endDate.trim()) {
    count = count + 1
    error.endDate = 'Please select/enter a Project End Date.'
  } else {
    const selectedStartDate = new Date(startDate)
    const selectedEndDate = new Date(endDate)
    const selectedDueDate = new Date(dueDate)
    if (selectedEndDate < selectedStartDate) {
      count = count + 1
      error.endDate = 'Project End Date must not be earlier than the Project Start Date.'
    }
  }

  if (!description.trim() || !description.replace(/<[^>]*>/g, '')) {
    count = count + 1
    error.description = 'Please enter a Project Description.'
  } else if (!descriptionWithoutTags || descriptionWithoutTags.trim().length > 500) {
    count = count + 1
    error.description = 'Project Description must not exceed 500 characters.'
    console.log('Description length:', description.trim().length)
  }
  if (!status.trim()) {
    count = count + 1
    error.status = 'Please select a Status.'
  }

  return error
}
