import {getStatusCount} from '../../../../api/Organization'

export const getAllProjectTaskStatusCount = (
  userId,
  projectId,
  setAllProjectTaskStatusCount: Function,
  setAPIStatus: Function
) => {
  setAPIStatus((prev: any) => ({...prev, loading: true}))
  getStatusCount(userId, projectId).then((res: any) => {
    if (res.statusCode === 200) {
      setAllProjectTaskStatusCount(res?.data)
      setAPIStatus((prev: any) => ({...prev, loading: false}))
    } else {
      setAPIStatus((prev: any) => ({...prev, loading: false, error: res.message}))
    }
  })
}

export const clearFilteredField = (
  setFilterOptions: Function,
  filterOptions: {
    userId: string
  }
) => {
  setFilterOptions({
    userId: '',
  })
}
