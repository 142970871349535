import {Center, Loader} from '@mantine/core'
import {useEffect, useState} from 'react'
import Chart from 'react-apexcharts'
import {Modal} from 'react-bootstrap'
import {KTCard, KTCardBody, changeTextCamal, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {getActiveUsersData, getAdminData} from '../admin_dashboard/AdminDashboardFunction'

const UsersPieCharts = () => {
  const [Users, setUsers] = useState<any[]>([])
  const [adminData, setAdminData] = useState<any>({})
  const [APIStatus, setAPIStatus] = useState({
    loading: false,
    error: null,
    submitLoading: false,
  })

  const [userModalTitle, setUserModalTitle] = useState('')

  const [activeUserModal, setActiveUserModal] = useState(false)

  // const isNoData = Object.values(Users).every((value) => Number(value) === 0)

  const checkStatus = (lable) => {
    if (lable === 'Pending') {
      return 'pending'
    } else if (lable === 'Active Users') {
      return 'activate'
    } else if (lable === 'Deactive Users') {
      return 'deactivate'
    }
  }

  useEffect(() => {
    let mounted = true
    if (mounted == true) {
      getAdminData(setAdminData, setAPIStatus)
    }
  }, [])

  return (
    <>
      <div>
        <div className=''>
          <div className=''>
            <div className='card me-2 me-sm-3 me-md-5 me-lg-5 mb-3 mb-sm-4 mb-md-5 mb-lg-5'>
              <div className='card-header' style={{minHeight: '3rem'}}>
                <div className='card-title d-flex flex-column fw-bold'>Users</div>
              </div>
              <div className='d-flex justify-content-center'>
                <div className='mb-5'>
                  {/* {isNoData ? (
                    <>
                      <div className='fw-bold text-gray-600 text-center mt-7 mb-5'>
                        No Data Available
                      </div>
                      <div className='text-center'>
                        <img
                          src='/media/avatars/19-dark.png'
                          alt='noReacordAvailable'
                          className='w-70 h-80px h-sm-325px'
                        />
                      </div>
                    </>
                  ) : ( */}
                  <Chart
                    type='donut'
                    redrawOnParentResize='true'
                    // series={[
                    //   Number(allProjectTaskStatusCount.ToDoTasks) || 0,
                    //   Number(allProjectTaskStatusCount.InProgress) || 0,
                    //   Number(allProjectTaskStatusCount.OnHold) || 0,
                    //   Number(allProjectTaskStatusCount.CompletedTasks) || 0,
                    // ]}
                    series={[
                      adminData?.pendingUsers ?? 0,
                      adminData?.activeUser ?? 0,
                      adminData?.deactivateUser ?? 0,
                    ]}
                    options={{
                      chart: {
                        events: {
                          dataPointSelection: (event, chartContext, config) => {
                            // console.log(event, chartContext, config)
                            console.log(
                              config.dataPointIndex,
                              chartContext.opts.labels[config.dataPointIndex]
                            )
                            const clickedLabel =
                              chartContext.opts.labels[config.dataPointIndex] ?? ''

                            setUserModalTitle(clickedLabel)
                            getActiveUsersData(
                              setUsers,
                              setAPIStatus,
                              setActiveUserModal,
                              checkStatus(clickedLabel) ?? ''
                            )
                          },
                        },
                      },
                      labels: ['Pending', 'Active Users', 'Deactive Users'],

                      colors: ['#FFD633', '#4DA6FF', '#F1416C', '#00CC66'],
                      noData: {text: 'empty data'},

                      legend: {
                        show: true,
                        // Position legend to the right
                        //  horizontalAlign: 'left', // Align legend text to the left
                        fontSize: '12px',
                        horizontalAlign: 'center',
                        position: 'bottom',
                        fontFamily: 'Arial, sans-serif',
                        markers: {
                          width: 12,
                          height: 12,
                        },
                        labels: {
                          colors: 'primary',
                        },
                      },
                      dataLabels: {
                        enabled: false,
                      },
                      plotOptions: {
                        pie: {
                          dataLabels: {
                            offset: -5,
                          },
                          expandOnClick: false,
                        },
                      },
                      responsive: [
                        {
                          breakpoint: 1000,
                          options: {
                            plotOptions: {
                              pie: {
                                horizontal: false,
                                dataLabels: {
                                  offset: -5,
                                },
                                expandOnClick: false,
                              },
                            },
                            legend: {
                              position: 'bottom',
                            },
                            Chart: {
                              position: 'left',
                            },
                          },
                        },
                      ],
                    }}
                  />
                  {/* )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={activeUserModal} onHide={() => setActiveUserModal(false)} size='lg'>
        <Modal.Header>
          <Modal.Title>{userModalTitle}</Modal.Title>
          <div className='d-flex flex-column align-items-end pt-2 pe-5'>
            <div className='btn btn-icon btn-sm btn-active-icon-danger'>
              <i
                className='fa-solid fa-xmark fs-1'
                onClick={() => {
                  setActiveUserModal(false)
                }}
              />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body style={{maxHeight: '450px', overflowY: 'scroll'}}>
          <KTCard>
            <KTCardBody className='py-4'>
              <div className='table-responsive'>
                {APIStatus.loading ? (
                  <Center>
                    {/* Render your loading spinner or indicator here */}
                    <Loader />
                  </Center>
                ) : (
                  <table
                    id='kt_table_users'
                    className='table align-middle table-row-dashed fs-6 dataTable'
                  >
                    <thead className=' text-muted fw-bolder fs-7 text-uppercase gs-0 text-start'>
                      <tr>
                        <th></th>
                        <th>Name</th>
                        <th>Contact</th>
                        <th>Designation</th>
                        <th>Reporting Manager</th>
                        <th>Human Resource</th>
                        {/* <th>Actions</th> */}
                      </tr>
                    </thead>
                    {Users?.length > 0 ? (
                      <tbody className='text-gray-800 fw-semibold text-left'>
                        {Users?.map((obj) => {
                          return (
                            <tr key={obj?.id}>
                              <td className='symbol symbol-45px'>
                                <img
                                  src={toAbsoluteUrl('/media/avatars/blank.png')}
                                  alt='user profile'
                                />
                              </td>
                              <td style={{minWidth: '70px', paddingLeft: '10px'}}>
                                {changeTextCamal(obj?.firstName)} {changeTextCamal(obj?.lastName)}{' '}
                                <br />
                                <a
                                  className='fs-7'
                                  href={`mailto:${obj?.email}`}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                >
                                  {obj?.email}
                                </a>
                              </td>

                              <td>{`${obj?.contact ? '+91' + obj.contact : '-'}`}</td>

                              <td>{changeTextCamal(obj?.designation)}</td>

                              <td style={{minWidth: '70px', paddingLeft: '10px'}}>
                                {obj?.reportingManager !== null ? (
                                  <span>
                                    {changeTextCamal(obj?.reportingManager?.firstName)}{' '}
                                    {changeTextCamal(obj?.reportingManager?.lastName)}
                                  </span>
                                ) : (
                                  '-'
                                )}
                              </td>
                              <td>
                                {' '}
                                {changeTextCamal(obj?.hrManager?.firstName)}{' '}
                                {changeTextCamal(obj?.hrManager?.lastName)}
                              </td>

                              {/* Actions */}
                              {/* <td>
                            <div className='d-flex justify-content-center flex-shrink-0'>
                           
                              <Link
                                to={`/profile/${obj?.id}`}
                                className='btn btn-icon btn-bg-light btn-active-color-info btn-sm me-1'
                              >
                                <i className='far fa-eye'></i>
                              </Link>
                            </div>
                          </td> */}
                            </tr>
                          )
                        })}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td colSpan={6}>
                            {' '}
                            <div className='fw-bold text-gray-600 text-center mt-4 mb-4'>
                              No Record Available.
                            </div>
                            <div className='text-center'>
                              <img
                                src='/media/avatars/20-dark.png'
                                alt='noReacordAvailable'
                                className='w-70 h-100px h-sm-325px'
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                )}
              </div>
            </KTCardBody>
          </KTCard>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default UsersPieCharts
