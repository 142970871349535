import {getCurrentUserTask, getStatusCount} from '../../../../api/Organization'

export const getTaskByUsers = (
  id,
  userId,
  date,
  status,
  setTasks: Function,
  setAPIStatus: Function
) => {
  setAPIStatus((prev: any) => ({...prev, loading: true}))
  getCurrentUserTask(id, userId, date, status)
    .then((res: any) => {
      if (res.statusCode === 200) {
        setTasks(res.data ?? {})
        setAPIStatus((prev: any) => ({...prev, loading: false}))
      } else {
        setTasks([])
        setAPIStatus((prev: any) => ({...prev, loading: false, error: res.message}))
      }
    })
    .catch((error) => {
      console.log('Error fetching to task by userId', error)
      setAPIStatus((prev: any) => ({...prev, loading: false, error: 'Error fetching tasks'}))
    })
}

export const clearDateField = (
  setFilterTasks: Function,
  filterTasks: {
    date: string
    status: string
  }
) => {
  setFilterTasks({date: '', status: ''})
}
