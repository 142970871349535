import {Center, Loader} from '@mantine/core'
import {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {verifyTenantEmailById} from '../../../api/TenantAPI'

const VerifyEmail = () => {
  const location = useLocation()

  const getQueryParamValue = (queryParam) => {
    const params = new URLSearchParams(location.search)
    return params.get(queryParam)
  }
  const [apiStatus, setApiStatus] = useState({
    loading: false,
    error: null,
    success: null,
  })

  useEffect(() => {
    let mounted = true
    if (mounted) {
      const email = getQueryParamValue('id')
      const k = getQueryParamValue('k')
      if (email) {
        setApiStatus((prev: any) => ({...prev, loading: true}))
        verifyTenantEmailById(email, k ?? '').then((res) => {
          if (res.statusCode === 200) {
            setApiStatus((prev: any) => ({
              ...prev,
              loading: false,
              success: res.message,
              error: null,
            }))
          } else {
            setApiStatus((prev: any) => ({
              ...prev,
              loading: false,
              error: res.message,
              success: null,
            }))
          }
        })
      }
    }
    return () => {
      mounted = false
    }
  }, [location.search])
  return (
    <div className='container my-6 '>
      <Center>
        {apiStatus.loading ? (
          <div className='my-2'>
            <Loader color='cyan' className='ms-20' />
            <div className=' alert alert-warning bg-warning text-white me-5 mt-5'>
              Email verification is in progress.
            </div>
          </div>
        ) : apiStatus.error ? (
          <div className='col-3 alert alert-danger bg-danger text-white  mb-10 mx-5'>
            {apiStatus.error}
          </div>
        ) : (
          apiStatus.success && (
            <div className='col-3 alert alert-success bg-success text-white mb-10 mx-5'>
              {apiStatus.success}
            </div>
          )
        )}
      </Center>
    </div>
  )
}

export default VerifyEmail
