import {Button, Center} from '@mantine/core'
import {useContext, useEffect, useRef, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {changeTextCapital, checkRole} from '../../../_metronic/helpers/AssetHelpers'
import {EDIT_ROW_COLORS} from '../../../utils/contants'
import InputErrorBox from '../../components/InputErrorBox'
import roleContext from '../../context/roleContext'
import {RoleContextValue} from '../../types/OtherTypes'
import {
  cancelSubmit,
  deletePolicy,
  getPolicy,
  handleFileChange,
  handleFileDownload,
  handleSubmit,
  validateForm,
  viewFile,
} from './PoliciesFunction'
import {tr} from 'date-fns/locale'
import {ToastContainer} from 'react-toastify'
interface Policy {
  id: string
  file: string
  url: string
}

const Policies = () => {
  let roleState: RoleContextValue = useContext(roleContext)
  const [policy, setPolicy] = useState<Policy[]>([])
  const [editId, setEditId] = useState(null)
  const [inputError, setInputError] = useState<any>({})
  const [APIStatus, setAPIStatus] = useState({
    loading: false,
    error: null,
    submitLoading: false,
  })
  const [SubmitAPIStatus, setSubmitAPIStatus] = useState({
    loading: false,
  })
  const initialState = {file: ''}
  const [inputField, setInputField] = useState(initialState)
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)

  const [fileUrl, setFileUrl] = useState<any>('')
  const [isViewModalOpen, setIsViewModalOpen] = useState(false)

  const [imageFiles, setImageFiles] = useState<File[]>([])
  const [imagePreviews, setImagePreviews] = useState<string[]>([])

  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleCloseViewModal = () => {
    setFileUrl('')
    setIsViewModalOpen(false)
  }

  useEffect(() => {
    let mounted = true
    if (mounted) {
      getPolicy(setPolicy, setAPIStatus)
      setIsAddUserModalOpen(false)
      setIsEditModalOpen(false)
    }
    return () => {
      mounted = false
    }
  }, [])

  const handleClose = () => {
    setIsAddUserModalOpen(false)
  }

  return (
    <div>
      <ToastContainer position={'bottom-center'} />

      <Modal
        className='modal-lg'
        backdrop='static'
        show={isViewModalOpen}
        onHide={handleCloseViewModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>View File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe src={fileUrl ?? ''} height={'470rem'} width={'750rem'}></iframe>
        </Modal.Body>
      </Modal>

      <Modal
        show={isAddUserModalOpen}
        onClose={() => setIsAddUserModalOpen(false)}
        animation={false}
        backdrop='static'
        onRequestClose={handleClose}
        centered
      >
        {/* begin::modal header--add holiday */}
        <Modal.Header>
          <h2 className='fw-bolder'>Add Policy</h2>
          <div
            className='btn btn-icon btn-sm btn-active-icon-danger'
            data-kt-users-modal-action='close'
            style={{cursor: 'pointer'}}
            onClick={() => {
              cancelSubmit(setInputField, inputField, setEditId, setInputError, fileInputRef)
              handleClose()
            }}
          >
            <i className='fa-solid fa-xmark fs-1' />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className='fv-row mb-5 col-md-12 ms-5 mt-4'>
            <input
              type='file'
              ref={fileInputRef}
              accept='.png, .jpg, .jpeg, .txt, .pdf'
              onChange={(event) => {
                handleFileChange(inputField, setInputField, event)
                setInputError('')
              }}
            />
            {<InputErrorBox Message={inputError?.file} />}
          </div>
        </Modal.Body>

        <Modal.Footer className='p-4'>
          {' '}
          <div className='d-flex flex-wrap justify-content-end align-items-center'>
            <Button
              variant='filled'
              color='gray'
              className='me-2'
              disabled={SubmitAPIStatus?.loading}
              style={{marginLeft: '15px'}}
              onClick={() => {
                cancelSubmit(setInputField, inputField, setEditId, setInputError, fileInputRef)
                setInputError('')
              }}
            >
              Clear
            </Button>
            <Button
              className='me-2'
              loading={SubmitAPIStatus.loading}
              onClick={() => {
                handleSubmit(
                  inputField,
                  getPolicy,
                  setPolicy,
                  setEditId,
                  initialState,
                  setInputField,
                  editId,
                  cancelSubmit,
                  setAPIStatus,
                  setInputError,
                  validateForm,
                  setSubmitAPIStatus,
                  setIsAddUserModalOpen
                )
                {
                  console.log('submitApiStatus', SubmitAPIStatus?.loading)
                }
              }}
            >
              {editId !== null ? 'Update' : 'Upload'}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <div className='row g-5 g-xl-8'>
        <div className='col-xl-12'>
          <div className='card card-xl-stretch mb-xl-8'>
            <div className='card-header'>
              <div className='card-title'>My Policy</div>
              <div
                className='d-flex mt-5 justify-content-end'
                data-kt-user-table-toolbar='base'
                style={{height: '3rem'}}
              >
                <Button
                  hidden={!checkRole(['Policy'], roleState.features, 'edit')}
                  type='button'
                  className=''
                  onClick={() => {
                    setIsAddUserModalOpen(true)
                  }}
                >
                  Add Policy
                </Button>
              </div>
            </div>
            {/* begin:: card body */}
            <div className='card-body'>
              {APIStatus.submitLoading ? (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              ) : APIStatus.error != null ? (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>{APIStatus.error}</div>
                </div>
              ) : (
                <div className='table-responsive  '>
                  <table
                    className='table 
                    table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-bordered'
                  >
                    {policy?.length > 0 ? (
                      <thead>
                        <tr className='fw-bold text-muted'>
                          <th className='pl-1 text-center text-muted' style={{minWidth: '150px'}}>
                            {changeTextCapital('Policy')}
                          </th>

                          <th
                            className='pl-1 text-center text-muted'
                            //   hidden={!checkRole(['Holidays'], roleState.features, 'edit')}
                            style={{minWidth: '100px'}}
                          >
                            {changeTextCapital('Actions')}
                          </th>
                        </tr>
                      </thead>
                    ) : (
                      <thead>
                        <tr></tr>
                      </thead>
                    )}

                    {policy?.length > 0 ? (
                      <tbody className='text-gray-800 fw-semibold text-left'>
                        {policy.map((obj: any, index: number) => (
                          <tr
                            className={`${editId === obj?.id ?? '-' ? `${EDIT_ROW_COLORS} ` : ''}`}
                            key={obj?.id ?? '-'}
                          >
                            <td className='text-center'>{obj?.fileName ?? '-'}</td>

                            <td
                            //  hidden={!checkRole(['Holidays'], roleState.features, 'edit')}
                            >
                              <div className='d-flex justify-content-center flex-shrink-0'>
                                <button
                                  className='btn btn-icon btn-bg-light btn-active-color-info btn-sm me-1'
                                  onClick={() => {
                                    {
                                      /* // setIsViewModalOpen(true) */
                                    }
                                    {
                                      /* // setFileUrl(obj.url) */
                                    }
                                    viewFile(
                                      obj?.fileData,
                                      obj?.fileName,
                                      setIsViewModalOpen,
                                      setFileUrl
                                    )
                                  }}
                                >
                                  <i className='far fa-eye'></i>
                                </button>
                                <button
                                  className='btn btn-icon btn-bg-light btn-active-color-success btn-sm me-1'
                                  onClick={() => handleFileDownload(obj?.url, obj?.fileName)}
                                >
                                  <i className='fa fa-download'></i>
                                </button>
                                <button
                                  hidden={!checkRole(['Policy'], roleState.features, 'edit')}
                                  className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                                  onClick={() =>
                                    deletePolicy(
                                      obj.id,
                                      getPolicy,
                                      setPolicy,
                                      setAPIStatus,
                                      cancelSubmit,
                                      setInputField,
                                      initialState,
                                      setEditId,
                                      setInputError
                                    )
                                  }
                                >
                                  <i className='fa fa-trash'></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td colSpan={5}>
                            <Center>
                              {' '}
                              <div className=''>
                                <img
                                  src='/media/avatars/addDocument.png'
                                  alt='noDocumentAvailable'
                                  className='w-70 h-100px h-sm-325px'
                                />
                              </div>
                            </Center>
                            <Center>
                              <span className='fw-bold text-gray-600'>No Record Available.</span>
                            </Center>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Policies
