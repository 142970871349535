import {clearInputError} from '../../../../_metronic/helpers'

export const handleAddUsers = (
  value: string | null | boolean,
  inputName: string,
  index: number,
  setFormField: Function,
  projectUsers: any,
  inputError: any,
  setInputError: Function
) => {
  let data: any = [...projectUsers]

  if (inputName === 'selected') {
    data[index] = {
      ...data[index],
      [inputName as keyof any]: value ?? '',
      role: '',
    }
    clearInputError(`selected`, inputError, setInputError)
  } else {
    clearInputError(`${inputName}${index}`, inputError, setInputError)
    data[index] = {
      ...data[index],
      [inputName as keyof any]: value ?? '',
    }
  }
  setFormField((formField: any) => ({
    ...formField,
    data: [...data],
  }))
}

export const validateAddUsers = (Inputfield: {
  projectUsers: {
    user: string
    role: string
    allocationPercentage: number
    startDate: string
    endDate: string
    selected: boolean
    status: string
    assigner: string
  }[]
}) => {
  let count = 0
  const error: any = {}
  let selectedUser = 0
  Inputfield?.projectUsers.map((user, index) => {
    if (user.selected === true) selectedUser = selectedUser + 1
    if (!user.status && user.selected) {
      count = count + 1
      error[`status${index}`] = 'Please select a Status.'
    }
    if (!user.role && user.selected) {
      count = count + 1
      error[`role${index}`] = 'Please select a Role.'
    }
    if (!user.startDate && user.selected) {
      count = count + 1
      error[`startDate${index}`] = 'Please select a Start Date.'
    } else if (user.startDate && user.selected) {
      let newDate = user?.startDate.split('-')
      if (newDate[0].length > 4) {
        count = count + 1
        error[`startDate${index}`] = 'Please select/enter valid Year.'
      }
    }
    if (!user.endDate && user.selected) {
      count = count + 1
      error[`endDate${index}`] = 'Please select an End Date.'
    } else if (user.startDate && user.selected) {
      let newDate = user?.endDate.split('-')
      if (newDate[0].length > 4) {
        count = count + 1
        error[`endDate${index}`] = 'Please select/enter valid Year.'
      }
    }
  })
  if (selectedUser === 0) {
    count = count + 1
    error.selected = 'Please select at least one user.'
  }
  return {error, count}
}
