import PieChart from '../../chart/PieChart'
function Report() {
  return (
    <>
      <div>
        <PieChart />
      </div>
    </>
  )
}

export default Report
