import {ToastContent, toast} from 'react-toastify'
import Swal from 'sweetalert2'
import {Message} from '../../../_metronic/helpers'
import {
  addEmail,
  addSimpleEmail,
  addSimpledraftEmail,
  getEmails,
  getSimpleEmails,
  updateLeaveStatus,
} from '../../../api/EmailAPI'
import {UPDATE_MESSAGE} from '../../../utils/contants'
import {AlertProps} from '../../data/AlertDefaults'
import {UserModel} from '../../modules/auth/core/_models'
import {Email} from './Mail'
export const notify = (message) => toast.error(message, {position: 'bottom-center'})
export const getEmail = (
  currentUser: UserModel | undefined,
  setEmail: Function,
  setAPIStatus: Function,
  action
) => {
  setAPIStatus((prev: any) => ({...prev, loading: true}))
  getEmails(currentUser?.id, action).then((res: any) => {
    if (res.statusCode === 200) {
      setEmail(res.data)
      console.log(res)
      setAPIStatus((prev: any) => ({...prev, loading: false}))
    } else {
      setAPIStatus((prev: any) => ({...prev, loading: false, error: res.message}))
    }
  })
}
export const getSimpleEmail = (id: string, setEmail: Function, setAPIStatus: Function) => {
  setAPIStatus((prev: any) => ({...prev, loading: true}))
  getSimpleEmails(id).then((response) => {
    if (response.statusCode === 200) {
      setEmail(response.data.email)

      console.log(response)
      console.log(id)
      setAPIStatus((prev: any) => ({...prev, loading: false}))
    } else {
      setAPIStatus((prev: any) => ({
        ...prev,
        loading: false,
        error: response.message,
      }))
    }
  })
}

export const handleSimpleMailSubmit = (
  inputField: {
    recipients: {
      to: string[]
      cc: string[]
    }
    subject: string
    body: string
    sender: string
    isLeave: boolean
    isPublish: boolean
  },
  getEmail: Function,
  setEmail: Function,

  initialState: {
    recipients: {
      to: string[]
      cc: string[]
    }
    sender: string
    subject: string
    body: string
    isLeave: boolean
    isPublish: boolean
  },
  setInputField: Function,
  editId: string | null,

  setAPIStatus: Function,
  setInputError: Function,

  setSubmitAPIStatus: Function,
  validateFields: Function,
  setActiveTab: Function
) => {
  const errors = validateFields(inputField)

  if (Object.keys(errors).length > 0) {
    const firstError = Object.values(errors)[0] as string
    notify(firstError)
    return
  }

  if (editId == null) {
    setSubmitAPIStatus((prev: any) => ({...prev, loading: true}))
    addSimpleEmail(inputField).then((res) => {
      if (res.statusCode === 200) {
        toast.success('Mail sent succesfully')
        setActiveTab('sent')
        const newEmailId = res.data.id
        getEmail(newEmailId, setEmail, setAPIStatus, setInputError, setInputField, initialState)
        setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
        setInputField(initialState)
      } else {
        Message(res.message, 'error')
        setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
      }
    })
  } else {
    Swal.fire({...AlertProps, icon: 'warning', title: UPDATE_MESSAGE}).then((result: any) => {
      if (result.isConfirmed) {
        setSubmitAPIStatus((prev: any) => ({...prev, loading: true}))
        setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
      } else {
        setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
      }
    })
  }
}
// export const handleSimpleDraftSubmit = (
//   inputField: {
//     recipients: {
//       to: string[]
//       cc: string[]
//     }
//     subject: string
//     body: string
//     sender: string
//     isLeave:boolean
//     isPublish:boolean
//   },
//   getEmail: Function,
//   setEmail: Function,

//   initialState: {
//     recipients: {
//       to: string[]
//       cc: string[]
//     }
//     sender: string
//     subject: string
//     body: string
//     isLeave:boolean
//     isPublish:boolean
//   },
//   setInputField: Function,
//   editId: string | null,

//   setAPIStatus: Function,
//   setInputError: Function,

//   setSubmitAPIStatus: Function,
//   validateFields:Function
// ) => {

//   if (editId == null) {
//     setSubmitAPIStatus((prev: any) => ({...prev, loading: true}))
//     addDraft(inputField).then((res) => {
//       if (res.statusCode === 200) {
//         toast.success("Draft saved succesfully")

//         const newEmailId = res.data.id
//         getEmail(newEmailId, setEmail, setAPIStatus, setInputError, setInputField, initialState)
//         setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))

//       } else {
//         Message(res.message, 'error')
//         setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
//         setInputField(initialState)
//       }
//     })
//   } else {
//     Swal.fire({...AlertProps, icon: 'warning', title: UPDATE_MESSAGE}).then((result: any) => {
//       if (result.isConfirmed) {
//         setSubmitAPIStatus((prev: any) => ({...prev, loading: true}))
//         setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))

//       } else {
//         setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
//       }
//     })
//   }
// }
export const handleSubmit = (
  inputField: {
    sender: string
    isLeave: boolean
    recipients: {
      to: string[]
      cc: string[]
    }
    title: string
    reason: string
    LeaveEmail: {
      startDate: string
      endDate: string
      type: string
    }[]
  },
  getEmail: Function,
  setEmail: Function,
  initialState: {
    sender: string
    isLeave: boolean
    recipients: {
      to: string[]
      cc: string[]
    }
    title: string
    reason: string
    LeaveEmail: any
  },
  setInputField: Function,
  editId: string | null,
  setAPIStatus: Function,
  setInputError: Function,
  setSubmitAPIStatus: Function,
  validateForm: Function,
  setInputFields: Function
) => {
  const errors = validateForm(inputField)

  if (Object.keys(errors).length > 0) {
    const firstError = Object.values(errors)[0] as string
    notify(firstError)
    return
  }
  if (editId == null) {
    console.log('in handle')
    setSubmitAPIStatus((prev: any) => ({...prev, loading: true}))
    addEmail(inputField).then((res) => {
      if (Array.isArray(res) && res.length > 0) {
        const responseItem = res[0]
        if (responseItem.statusCode === 200) {
          toast.success('Mail sent succesfully')

          getEmail(setEmail, setAPIStatus, setInputError, setInputField, initialState)
          setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
          setInputField(initialState)
          setInputFields([initialState])
        } else {
          Message(responseItem.message, 'error')
          setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
        }
      }
    })
  } else {
    Swal.fire({...AlertProps, icon: 'warning', title: UPDATE_MESSAGE}).then((result: any) => {
      if (result.isConfirmed) {
        setSubmitAPIStatus((prev: any) => ({...prev, loading: true}))
        setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
      } else {
        setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
      }
    })
  }
}
export const handleDraftSubmit = (
  inputField: {
    sender: string
    isLeave: boolean
    isPublish: boolean
    recipients: {
      to: string[]
      cc: string[]
    }
    title: string
    reason: string
    LeaveEmail: {
      startDate: string
      endDate: string
      type: string
    }[]
  },
  getEmail: Function,
  setEmail: Function,
  initialState: {
    sender: string
    isLeave: boolean
    isPublish: boolean
    recipients: {
      to: string[]
      cc: string[]
    }
    title: string
    reason: string
    LeaveEmail: any
  },
  setInputField: Function,
  editId: string | null,
  setAPIStatus: Function,
  setInputError: Function,
  setSubmitAPIStatus: Function,
  validateForm: Function,
  setInputFields: Function
) => {
  const errors = validateForm(inputField)

  if (Object.keys(errors).length > 0) {
    const firstError = Object.values(errors)[0] as string
    notify(firstError)
    return
  }
  if (editId == null) {
    setSubmitAPIStatus((prev: any) => ({...prev, loading: true}))
    addSimpledraftEmail(inputField)
      .then((res) => {
        if (Array.isArray(res) && res.length > 0) {
          const responseItem = res[0]

          if (responseItem.statusCode === 200) {
            toast.success('Mail sent succesfully')

            getEmail(setEmail, setAPIStatus, setInputError, setInputField, initialState)
            setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
            setInputField(initialState)
            setInputFields([initialState])
          } else {
            Message(responseItem.message, 'error')
            setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
          }
        } else {
        }
      })
      .catch((error) => {
        console.error('Error:', error)
        setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
      })
  } else {
    Swal.fire({...AlertProps, icon: 'warning', title: UPDATE_MESSAGE}).then((result: any) => {
      if (result.isConfirmed) {
        setSubmitAPIStatus((prev: any) => ({...prev, loading: true}))
        setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
      } else {
        setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
      }
    })
  }
}
export const updatestatusById = async (
  currentUser: UserModel | undefined,
  updatedField: {
    leaveId: any
    status: string
    remark: string
  }[],
  email: Email,
  setEmail: Function,
  setEditId: Function,
  setInputField: Function,
  setAPIStatus: Function,
  setInputError: Function,
  setSubmitAPIStatus: Function
) => {
  try {
    console.log('updated Feilds:', updatedField)
    const res = await updateLeaveStatus(currentUser?.id || '', updatedField)

    console.log('server response:', res)
    if (res && typeof res === 'object' && 'statusCode' in res && 'message' in res) {
      if (res.statusCode === 200) {
        // If the status update is successful, update the local state immediately
        const updatedEmails = email.LeaveEmails.map((leave) => {
          const updatedLeave = updatedField.find((field) => field.leaveId === leave.id)
          return updatedLeave ? {...leave, ...updatedLeave} : leave
        })

        setEmail((prevEmail) => ({
          ...prevEmail,
          LeaveEmails: updatedEmails,
        }))

        toast.success(res.message as ToastContent)
        setEditId(null)
        setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
      }
    } else {
      toast.success('Status updated successfully')
      setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
    }
  } catch (error) {
    console.log('API Error:', error)
    setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
  }
}

export const validateFields = (inputField) => {
  const errors: any = {}

  if (!inputField.recipients.to.length) {
    errors.to = 'Please select at least one recipient.'
  }

  if (!inputField.subject.trim()) {
    errors.subject = 'Please enter a Subject.'
  }

  if (!inputField.body.trim()) {
    errors.body = 'Please enter a Description.'
  }

  return errors
}
export const validateForm = (inputField) => {
  const errors: any = {}

  // if (
  //   !inputField.recipients ||
  //   !inputField.recipients.to ||
  //   inputField.recipients.to.length === 0
  // ) {
  //   errors.to = 'Please select at least one recipient.'
  // }

  // if (!inputField.title) {
  //   errors.title = 'Please select Subject.'
  // }

  // if (!inputField.LeaveEmail[0].type) {
  //   errors.type = 'Please select Leave Type.'
  // }

  // if (!inputField.LeaveEmail[0].startDate) {
  //   errors.startDate = 'Please enter/select Start Date.'
  // }

  // if (!inputField.LeaveEmail[0].endDate) {
  //   errors.startDate = 'Please enter/select End Date.'
  // }

  // if (inputField.LeaveEmail[0].type !== 'Full' && !inputField.LeaveEmail[0].endDate) {
  //   errors.endDate = 'Please enter/select End Date.'
  // }

  return errors
}

export const modules = {
  toolbar: {
    container: [['bold', 'italic', 'underline']],
  },
}
