import {useState} from 'react'

const ChangeLog = () => {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded)
  }
  const [isExpandedV2, setIsExpandedV2] = useState(false)

  const toggleAccordionV2 = () => {
    setIsExpandedV2(!isExpandedV2)
  }
  return (
    <div className='docs-content d-flex flex-column flex-column-fluid' id='kt_docs_content'>
      <div className='container d-flex flex-column flex-lg-row' id='kt_docs_content_container'>
        <div className='card card-docs flex-row-fluid mb-2' id='kt_docs_content_card'>
          <div className='card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700'>
            <div className='accordion accordion-flush accordion-icon-toggle' id='kt_accordion'>
              <div className='accordion-item mb-5'>
                <div
                  className={`accordion-header py-3 d-flex ${isExpanded ? '' : 'collapsed'}`}
                  onClick={toggleAccordion}
                  aria-expanded={isExpanded ? 'true' : 'false'}
                >
                  <span className='accordion-icon'>
                    <span
                      className={`badge badge-light-primary cursor-pointer`}
                      onClick={toggleAccordion}
                    >
                      {isExpanded ? '↑' : '↓'}
                    </span>
                  </span>
                  <h3 className='fs-2 text-gray-800 fw-bold mb-0 ms-4'>
                    v1.0.0 - 1 February, 2024(Initial Release)
                  </h3>
                </div>

                <div
                  id='v2_0_0'
                  className={`fs-6 mt-1 mb-1 py-0 ps-10 collapse ${isExpanded ? 'show' : ''}`}
                  data-bs-parent='#kt_accordion'
                >
                  <div className='accordion-body ps-0 pt-0'>
                    <div className='mb-5'>
                      <h3 className='fs-6 fw-bold mb-1'>AMS</h3>
                      <ul className='my-0 py-0'>
                        <li className='py-2'>
                          Implemented basic attendance tracking functionality.
                        </li>
                        <li className='py-2'>
                          Users can log in and mark their attendance for the day. Integrated
                          barcode/QR code scanning for login in.
                        </li>
                        <li className='py-2'>
                          Admin panel allows administrators to view and manage attendance records.
                        </li>
                      </ul>
                    </div>
                    <div className='mb-5'>
                      <h3 className='fs-6 fw-bold mb-1'>LMS</h3>
                      <ul className='my-0 py-0'>
                        <li className='py-2'>
                          Added user interface for employees to request leave.
                        </li>
                        <li className='py-2'>
                          Integrated approval workflow for managers to approve or reject leave
                          requests.
                        </li>
                        <li className='py-2'>
                          Developed backend functionality to track and manage leave balances.
                        </li>
                      </ul>
                    </div>
                    <div className='mb-5'>
                      <h3 className='fs-6 fw-bold mb-1'>Users</h3>
                      <ul className='my-0 py-0'>
                        <li className='py-2'>Implemented user registration functionality.</li>
                        <li className='py-2'>Added login and authentication system.</li>
                        <li className='py-2'>
                          Created user profile management features (view, edit, delete).
                        </li>
                      </ul>
                    </div>
                    <div className='mb-5'>
                      <h3 className='fs-6 fw-bold mb-1'>Holidays</h3>
                      <ul className='my-0 py-0'>
                        <li className='py-2'>
                          Users can log in and view their holiday entitlement.
                        </li>

                        <li className='py-2'>
                          Admin panel allows administrators to create, edit, delete a holiday
                          record.
                        </li>
                      </ul>
                    </div>
                    <div className='mb-5'>
                      <h3 className='fs-6 fw-bold mb-1'>Assets</h3>
                      <ul className='my-0 py-0'>
                        <li className='py-2'>
                          Implemented basic CRUD operations for managing assets.
                        </li>

                        <li className='py-2'>
                          Included basic reporting capabilities for asset usage and status.
                        </li>
                      </ul>
                    </div>
                    <div className='mb-5'>
                      <h3 className='fs-6 fw-bold mb-1'>Reports</h3>
                      <ul className='my-0 py-0'>
                        <li className='py-2'>
                          Basic functionality implemented for generating reports.
                        </li>

                        <li className='py-2'>Supports generation of Excel reports.</li>
                      </ul>
                    </div>
                    <div className='mb-5'>
                      <h3 className='fs-6 fw-bold mb-1'>Project</h3>
                      <ul className='my-0 py-0'>
                        <li className='py-2'>
                          <code> Basic task management:</code> create, assign, and track tasks.
                        </li>

                        <li className='py-2'>
                          <code>Task status tracking:</code> Ability to mark tasks as open, in
                          progress, or completed.
                        </li>
                        <li className='py-2'>
                          <code> Basic project dashboard:</code> Overview of project progress and
                          pending tasks.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className='accordion-item mb-5'>
                <div
                  className={`accordion-header py-3 d-flex ${isExpandedV2 ? '' : 'collapsed'}`}
                  onClick={toggleAccordionV2}
                  aria-expanded={isExpandedV2 ? 'true' : 'false'}
                >
                  <span className='accordion-icon'>
                    <span
                      className={`badge badge-light-primary cursor-pointer`}
                      onClick={toggleAccordionV2}
                    >
                      {isExpandedV2 ? '↑' : '↓'}
                    </span>
                  </span>
                  <h3 className='fs-2 text-gray-800 fw-bold mb-0 ms-4'>
                    v2.0.0 - 19 March, 2024
                  </h3>
                </div>

                <div
                  id='v2_0_0'
                  className={`fs-6 mt-1 mb-1 py-0 ps-10 collapse ${isExpandedV2 ? 'show' : ''}`}
                  data-bs-parent='#kt_accordion'
                >
                  <div className='accordion-body ps-0 pt-0'>
                    <div className='mb-5'>
                      <h3 className='fs-6 fw-bold mb-1'>Multi Tenancy</h3>
                      <ul className='my-0 py-0'>
                        <li className='py-2'>
                          Our implementation of multi-tenancy allows a single software application
                          to serve multiple tenants (clients or customers) while ensuring data
                          isolation and security between them
                        </li>
                      </ul>
                    </div>
                    <div className='mb-5'>
                      <h3 className='fs-6 fw-bold mb-1'>User Management</h3>
                      <ul className='my-0 py-0'>
                      <li className='py-2'>
                        User Roles and Permissions:
                          <ul>
                          Users are assigned roles defining their level of access and permissions within the system.
                          </ul>
                        </li>
                        <li className='py-2 '>
                          User Authentication:
                          <ul>
                            Authentication is the process of verifying the identity of users
                            attempting to access the system.
                          </ul>
                        </li>
                        
                        
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ChangeLog
