// Dev Environment
export const API_BASE_URL = process.env.REACT_APP_API_KEY
export const DELETE_MESSAGE = 'Are you sure you want to delete the record?'
export const UPDATE_MESSAGE = 'Are you sure you want to update the record?'
export const CONFIRM_BUTTON_TEXT = 'Yes'
export const CANCLE_BUTTON_TEXT = 'No'
export const CONFIRM_BUTTON_COLOR = '#50cd89'
export const CANCLE_BUTTON_COLOR = '#F64E60'
export const EDIT_ROW_COLORS = 'bg-secondary'
export const Created = 'Created'
export const In_Progress = 'In Progress'
export const Completed = 'Completed'
export const On_Hold = 'On Hold'
export const Todo = 'Todo'
export const Highest = 'Highest'
export const High = 'High'
export const Medium = 'Medium'
export const Low = 'Low'
export const Lowest = 'Lowest'
export const On_Boarded= 'On Boarded'
export const Active = 'Active'
export const Released = 'Released'
export const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]
