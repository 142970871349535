import axios from 'axios'
import {setupAxios} from '../app/modules/auth'
import {API_BASE_URL} from '../utils/contants'
import {api} from './APIMiddlewares'

export async function addLeave(inputField: {
  sender: string
  recipients: {
    to: string[]
    cc: string[]
  }
  leaveType: string
  reason: string
  leaveDate: {
    startDate: string
    endDate: string
    type: string
  }[]
}) {
  const {token, id, tenantId} = setupAxios()

  const leaveUser = [
    ...inputField.recipients.to.map((receiver) => ({type: 'to', receiver: {id: receiver}})),
    ...inputField.recipients.cc.map((receiver) => ({type: 'cc', receiver: {id: receiver}})),
  ]

  const emailData = {
    leaveUser,
    sender: {
      id: id,
    },
    leaveType: inputField.leaveType,
    leaveDate: inputField.leaveDate,
    reason: inputField.reason,
  }

  return await api.post(
    `/leaveEmail`,
    token,
    tenantId,
    {
      body: JSON.stringify(emailData),
    },
    false
  )
}

export async function getLeaveEmails(userId, action) {
  const {token, tenantId} = setupAxios()
  return await api.get(`/user/${userId}/leaveEmails?action=${action}`, token, tenantId, false)
}
export async function getLeavesBalance(userId) {
  const {token, tenantId} = setupAxios()
  return await api.get(`/leaveBalance/${userId}`, token, tenantId, false)
}
export async function getPieChartDatas(userId) {
  const {token, tenantId} = setupAxios()
  return await api.get(`/pie-chart/user/${userId}`, token, tenantId, false)
}

export async function cancelLeave(leaveId, currentUser) {
  const {token, tenantId} = setupAxios()
  return await api.put(
    `${API_BASE_URL}/leave/${leaveId}/status`,
    token,
    tenantId,
    {
      body: JSON.stringify({
        status: 'cancel',
        approvedBy: {
          id: currentUser.id,
        },
      }),
    },
    false
  )
}
export async function getallUsersLeaves() {
  const {token, tenantId} = setupAxios()
  return await api.get(`/leaveBalance`, token, tenantId, false)
}

export async function getLeaveBalancesById(id: string) {
  const {token, tenantId} = setupAxios()
  return await api.get(`/leaveBalance/${id}`, token, tenantId, false)
}
export async function updateLeaveBalanceById(
  InputField: {
    leaveType: string
    leaveDate: {
      startDate: string
      endDate: string
      type: string
      approvedBy: {
        id: string
      }
      sender: {
        id: string
      }
    }
  },
  id: string | null
) {
  const {token, tenantId} = setupAxios()
  return await api.put(
    `/leaveBalance/${id}`,
    token,
    tenantId,
    {
      body: JSON.stringify({
        leaveType: InputField.leaveType,
        leaveDate: {
          startDate: InputField.leaveDate.startDate,
          endDate: InputField.leaveDate.endDate,
          type: InputField.leaveDate.type,
        },
      }),
    },
    false
  )
}

export async function addManualLeave(
  InputField: {
    leaveType: string
    leaveDate: {
      startDate: string
      endDate: string
      type: string
      approvedBy: {
        id: string
      }
    }
    sender: {
      id: string
    }
  },
  editId,
  currentUser
) {
  const {token, tenantId} = setupAxios()
  return await api.post(
    `/manualLeaveEmail`,
    token,
    tenantId,
    {
      body: JSON.stringify({
        leaveType: InputField.leaveType,
        leaveDate: {
          startDate: InputField.leaveDate.startDate,
          endDate: InputField.leaveDate.endDate,
          type: InputField.leaveDate.type,
          approvedBy: {
            id: currentUser.id,
          },
        },
        sender: {
          id: editId,
        },
      }),
    },
    false
  )
}

export async function getFilterLeaves(userId, action) {
  const {token, tenantId} = setupAxios()
  return await api.get(`/user/${userId}/leavesForYear?action=${action}`, token, tenantId, false)
}

export const handleLeaveRejects = async (leaveId, currentUser) => {
  const {token, tenantId} = setupAxios()
  return await api.put(
    `/leave/${leaveId}/status`,
    token,
    tenantId,
    {
      body: JSON.stringify({
        status: 'reject',
        approvedBy: {
          id: currentUser.id,
        },
      }),
    },
    false
  )
}
export const handleLeaveApproves = async (leaveId, currentUser) => {
  const {token, tenantId} = setupAxios()
  return await api.put(
    `/leave/${leaveId}/status`,
    token,
    tenantId,
    {
      body: JSON.stringify({
        status: 'accept',
        approvedBy: {
          id: currentUser.id,
        },
      }),
    },
    false
  )
}
