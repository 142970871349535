import {setupAxios} from '../app/modules/auth'
import {api} from './APIMiddlewares'

// form save api integration
export async function addHoliday(InputField: {holidayName: string; date: string}) {
  const {token, tenantId} = setupAxios()

  return await api
    .post(
      `/holiday`,
      token,
      tenantId,
      {
        body: JSON.stringify({
          holidayName: InputField.holidayName,
          date: InputField.date,
        }),
      },
      false
    )
    .then((res) => res)
}

export async function getHolidaysByDateRange(InputField: {startDate: string; endDate: string}) {
  const {token, tenantId} = setupAxios()

  return await api
    .post(
      `/holiday/search`,
      token,
      tenantId,
      {
        body: JSON.stringify({
          startDate: InputField.startDate ? InputField.startDate + 'T00:00:00' : null,
          endDate: InputField.endDate ? InputField.endDate + 'T23:59:59' : null,
        }),
      },
      false
    )
    .then((res) => res)
}
//edit by id
export async function updateHolidayById(
  InputField: {
    holidayName: string
    date: string
  },
  id: string
) {
  const {token, tenantId} = setupAxios()
  return await api
    .patch(
      `/holiday/${id}`,
      token,
      tenantId,
      {
        body: JSON.stringify({
          holidayName: InputField.holidayName,
          date: InputField.date,
        }),
      },
      false
    )
    .then((res) => res)
}

//   get Holiday

export async function getHolidays() {
  const {token, tenantId} = setupAxios()
  return await api.get(`/holiday`, token, tenantId, false)
}

//   delete holiday

export async function deleteHolidays(id: string) {
  const {token, tenantId} = setupAxios()
  return await api.delete(`/holiday/${id}`, token, tenantId, false)
}

//getHolidaybyid

export async function getHolidaysById(id: string) {
  const {token, tenantId} = setupAxios()
  return await api.get(`/holiday/${id}`, token, tenantId, false)
}
