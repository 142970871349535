import {clsx} from '@mantine/core'
import {FC, useContext, useEffect, useMemo, useState} from 'react'
import {Link, useLocation, useParams} from 'react-router-dom'
import roleContext from '../../../../app/context/roleContext'
import {setupAxios, useAuth} from '../../../../app/modules/auth'
import AdminAttendance from '../../../../app/pages/attendance/AdminAttendance'
import Application from '../../../../app/pages/attendance/Application'
import Attendance, {TableData} from '../../../../app/pages/attendance/Attendace'
import {getUserByIdForAMS} from '../../../../app/pages/attendance/ams'
import Overviews from '../../../../app/pages/organization/project/Overviews'
import ProjectOverview from '../../../../app/pages/organization/project/ProjectOverview'
import Defects from '../../../../app/pages/organization/task/Defects'
import Task from '../../../../app/pages/organization/task/Task'
import Users from '../../../../app/pages/organization/users/Users'
import Role from '../../../../app/pages/role/Role'
import User from '../../../../app/pages/user/User'
import {RoleContextValue} from '../../../../app/types/OtherTypes'
import {API_BASE_URL} from '../../../../utils/contants'
import {checkRole} from '../../../helpers/AssetHelpers'
import {useLayout} from '../../core'

const Toolbar1: FC = () => {
  let roleState: RoleContextValue = useContext(roleContext)
  const location = useLocation()
  const {currentUser} = useAuth()
  const params = useParams()
  const isAttendanceRoute = location.pathname === '/ams'
  const isUser = location.pathname === '/users'
  const isProjectOverview = location.pathname === `/projectOverview/${params?.id}`
  const isOrganization = location.pathname === `/organization`
  const {classes} = useLayout()
  const today = new Date()
  const [user, setUser] = useState({
    designation: '',
    email: '',
    role: {
      name: '',
    },
  })
  const [tableData, setTableData] = useState<TableData[]>([])
  const [isProjectManager, setIsProjectManager] = useState<boolean | null>(null)
  const [loading, setLoading] = useState(true)
  const [activeTab, setActiveTab] = useState(
    isProjectOverview
      ? 'projectOverview'
      : isAttendanceRoute
        ? 'myAttendance'
        : isUser
          ? 'user'
          : ''
  )
  const handleTabClick = (tab) => {
    setActiveTab(tab)
  }
  const cancleEdit = useMemo(() => {
    if (isUser) {
      setActiveTab(
        checkRole(['Users Registration'], roleState.features)
          ? 'user'
          : isProjectOverview
            ? 'projectOverview'
            : isAttendanceRoute
              ? 'myAttendance'
              : 'roles'
      )
    }
  }, [isUser])
  const cancleEdit2 = useMemo(() => {
    if (isAttendanceRoute) {
      setActiveTab(
        isProjectOverview
          ? 'projectOverview'
          : isAttendanceRoute
            ? 'myAttendance'
            : isUser
              ? 'user'
              : ''
      )
    }
  }, [isAttendanceRoute])

  const [APIStatus, setAPIStatus] = useState({
    loading: false,
    error: null,
    submitLoading: false,
  })

  const fetchTableData = async (page = 1, take = 10) => {
    const {token, tenantId} = setupAxios()

    await fetch(API_BASE_URL + `/attendence?page=${page}&take=${take}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'X-tenant-Id': `${tenantId}`,
      },
      body: JSON.stringify({
        id: currentUser?.id,
      }),
    })
      .then(async (response) => {
        let res = await response.json()
        if (res.statusCode === 200) {
          setTableData([res.data])
          console.log('response', res.length)
        } else {
          setTableData([])
        }
      })
      .catch((error) => {
        console.error('Error fetching table data:', error)
      })
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const {token, tenantId} = setupAxios()

        const response = await fetch(API_BASE_URL + `/check-manager/${currentUser?.id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            'X-tenant-Id': `${tenantId}`,
          },
        })

        const data = await response.json()

        setIsProjectManager(data.isProjectManager)
      } catch (error) {
        console.error('Error fetching project manager status', error)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [currentUser?.id])
  useEffect(() => {
    let mounted = true
    if (mounted) {
      fetchTableData()
      getUserByIdForAMS(setUser, setAPIStatus, currentUser)
    }
    return () => {
      mounted = false
    }
  }, [])
  return (
    <>
      <div className='toolbar mb-8' id='kt_toolbar'>
        <div
          id='kt_toolbar_container'
          className={clsx(classes.toolbarContainer.join(' '), 'd-flex justify-content-between')}
        >
          {isProjectOverview && (
            <div className='d-flex h-55px'>
              <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                {checkRole(['Project Management'], roleState.features) && (
                  <li className='nav-item'>
                    <Link
                      className={clsx('nav-link text-active-primary me-4', {
                        active: activeTab === 'projectOverview',
                      })}
                      to=''
                      onClick={() => handleTabClick('projectOverview')}
                    >
                      Detail
                    </Link>
                  </li>
                )}
                {checkRole(['Project Management'], roleState.features) && (
                  <li className='nav-item'>
                    <Link
                      className={clsx('nav-link text-active-primary me-4', {
                        active: activeTab === 'overviews',
                      })}
                      to=''
                      onClick={() => handleTabClick('overviews')}
                    >
                      Overview
                    </Link>
                  </li>
                )}
                {checkRole(['Project Management'], roleState.features) && (
                  <li className='nav-item'>
                    <Link
                      className={clsx('nav-link text-active-primary me-4', {
                        active: activeTab === 'users',
                      })}
                      to=''
                      onClick={() => handleTabClick('users')}
                    >
                      Users
                    </Link>
                  </li>
                )}
                {checkRole(['Project Management'], roleState.features) && (
                  <li className='nav-item'>
                    <Link
                      className={clsx('nav-link text-active-primary me-4', {
                        active: activeTab === 'task',
                      })}
                      to=''
                      onClick={() => handleTabClick('task')}
                    >
                      Task
                    </Link>
                  </li>
                )}
                {checkRole(['Project Management'], roleState.features) && (
                  <li className='nav-item'>
                    <Link
                      className={clsx('nav-link text-active-primary me-4', {
                        active: activeTab === 'defect',
                      })}
                      to=''
                      onClick={() => handleTabClick('defect')}
                    >
                      Defect
                    </Link>
                  </li>
                )}
                {/* {projectData[0]?.projectManager?.id === currentUser?.id && ( */}
                {/* <li className='nav-item'>
                  <Link
                    className={clsx('nav-link text-active-primary me-4', {
                      active: activeTab === 'settings',
                    })}
                    to=''
                    onClick={() => handleTabClick('settings')}
                  >
                    Settings
                  </Link>
                </li> */}
                {/* )} */}
              </ul>
            </div>
          )}
          {/* <div className='flex-grow-1 flex-shrink-0  ms-5'> */}
          {isUser && (
            <div className='d-flex h-55px'>
              <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                {checkRole(['Users Registration'], roleState.features) && (
                  <li className='nav-item'>
                    <Link
                      className={clsx('nav-link text-active-primary me-4', {
                        active: activeTab === 'user',
                      })}
                      to=''
                      onClick={() => handleTabClick('user')}
                    >
                      Users
                    </Link>
                  </li>
                )}
                {checkRole(['Roles'], roleState.features) && (
                  <li className='nav-item'>
                    <Link
                      className={clsx('nav-link text-active-primary me-4', {
                        active: activeTab === 'roles',
                      })}
                      to=''
                      onClick={() => handleTabClick('roles')}
                    >
                      Role
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          )}
          {/* </div> */}

          {/* <div className='flex-grow-1 flex-shrink-0 text-start'> */}
          {isAttendanceRoute && (
            <div className='d-flex h-55px'>
              <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                {checkRole(['Attendance Management System'], roleState.features) && (
                  <li className='nav-item'>
                    <Link
                      className={clsx('nav-link text-active-primary me-4', {
                        active: activeTab === 'myAttendance',
                      })}
                      to=''
                      onClick={() => handleTabClick('myAttendance')}
                    >
                      My Attendance
                    </Link>
                  </li>
                )}

                {(isProjectManager ||
                  user.designation === 'HR Executive' ||
                  user.role.name === 'ADMIN') && (
                  <li className='nav-item'>
                    <Link
                      className={clsx('nav-link text-active-primary me-4', {
                        active: activeTab === 'allAttendance',
                      })}
                      to=''
                      onClick={() => {
                        handleTabClick('allAttendance')
                        fetchTableData()
                      }}
                    >
                      Team Attendance
                    </Link>
                  </li>
                )}

                {isProjectManager ||
                user.designation === 'HR Executive' ||
                user.email === 'hr@skeletos.in' ? (
                  <li className='nav-item'>
                    <Link
                      className={clsx('nav-link text-active-primary me-4', {
                        active: activeTab === 'applications',
                      })}
                      to=''
                      onClick={() => handleTabClick('applications')}
                    >
                      Applications
                    </Link>
                  </li>
                ) : (
                  <div></div>
                )}
              </ul>
            </div>
          )}
          {/* </div> */}
        </div>
      </div>
      <div className='mt-13'>
        {activeTab === 'projectOverview' && (
          <div className=''>
            <ProjectOverview />
          </div>
        )}
        {activeTab === 'overviews' && <Overviews />}
        {activeTab === 'users' && <Users />}
        {activeTab === 'myAttendance' && <AdminAttendance />}
        {activeTab === 'allAttendance' && <Attendance />}
        {activeTab === 'applications' && <Application />}
        {/* {activeTab === 'settings' && <Settings setProjectData={setProjectData} />} */}
        {activeTab === 'task' && <Task />}
        {activeTab === 'defect' && <Defects />}
        {activeTab === 'user' && <User />}
        {activeTab === 'roles' && <Role />}
      </div>
      {console.log('Active Tab', activeTab)}
    </>
  )
}

export {Toolbar1}
