import {RingProgress} from '@mantine/core'
import {useState} from 'react'
import Chart from 'react-apexcharts'
import {useAuth} from '../../../modules/auth'
const EmployeeDashboard = () => {
  const {currentUser} = useAuth()

  const [state] = useState({
    options: {
      chart: {
        id: 'basic-bar',
      },
      xaxis: {
        categories: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
      },
      yaxis: {
        min: 6,
        max: 21, // 6 AM to 9 PM, 24-hour format
        labels: {
          formatter: function (value) {
            const hour = Math.floor(value)
            const minute = Math.round((value % 1) * 60)
            const timeString = `${hour}:${minute < 10 ? '0' : ''}${minute} hrs`
            return timeString
          },
        },
        tickAmount: 8, // Adjust according to your preference
        ticks: [6, 8, 10, 12, 14, 16, 18, 21], // Number of ticks on the y-axis (adjust according to your preference)
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          type: 'horizontal',
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 0.85,
          opacityTo: 0.85,
          stops: [50, 0, 100],
        },
      },
      title: {
        text: 'Employee Work Hours',
        align: 'center' as 'center',
        margin: 20,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: '18px',
          color: '#444',
        },
      },
    },
    series: [
      {
        name: 'Work Hours',
        data: [9, 11, 10.12, 12, 9],
      },
    ],
  })

  return (
    <div>
      <div>Employee Dashboard - {currentUser?.tenantId}</div>
      {/* <div className='d-flex '>
      <div className='card me-2'>
  <div className='ms-10 mt-5'>Statistics</div>
  <div className='card-body'>
    <div className='d-flex justify-content-center'>
      <RingProgress
        size={170}
        thickness={16}
        label={
          <div className='text-center'>
            Total
            <br />
            Employees
            <br />
            <div className='fs-3 fw-bold counted text-primary mt-2'>2500</div>
          </div>
        }
        sections={[
          {value: 40, color: 'blue', tooltip: 'Checked In'},
          {value: 8, color: 'red', tooltip: 'Not Checked In'},
          {value: 10, color: 'green', tooltip: 'On Leave'},
          {value: 12, color: 'purple', tooltip: 'Weekly Off'},
          {value: 10, color: 'grey', tooltip: 'Holiday'},
          {value: 20, color: 'orange', tooltip: 'Checked Out'},
        ]}
      />
    </div>
  </div>
</div>

        
        <div className='card   '>
          <div className='ms-10 mt-5'>Attendance</div>
          <div className='card-body d-flex   '>
            <div className='card card-custom gutter-b card-stretch me-2'>
              <div className='card-body bg-light-secondary rounded-3 text-lg-center '>
                <button className='btn btn-icon btn-light-primary btn-sm me-1 '>
                  <i className='fa-solid fa-check'></i>
                </button>
                <div className='mt-3'>checked In</div>
                <div className='fs-3 fw-bold counted text-primary mt-2'>2500</div>
              </div>
            </div>
            <div
              className='card card-custom bg-light-secondary rounded-3 gutter-b card-stretch me-2'
              style={{width: '160px'}}
            >
              <div className='card-body text-lg-center'>
                <button className='btn btn-icon btn-light-danger btn-sm me-1 '>
                  <i className='fa-solid fa-triangle-exclamation'></i>
                </button>
                <div className='mt-3'>Not checked In</div>
                <div className='fs-3 fw-bold counted text-danger mt-2'>4509</div>
              </div>
            </div>
            <div
              className='card card-custom bg-light-secondary rounded-3 gutter-b card-stretch me-2'
              style={{width: '160px'}}
            >
              <div className='card-body text-lg-center'>
                <button className='btn btn-icon btn-light-success btn-sm me-1 '>
                  <i className='fa-solid fa-umbrella'></i>
                </button>
                <div className='mt-3'>On Leave</div>
                <div className='fs-3 fw-bold counted text-success mt-2'>8876</div>
              </div>
            </div>
            <div
              className='card card-custom bg-light-secondary rounded-3 gutter-b card-stretch me-2'
              style={{width: '160px'}}
            >
              <div className='card-body text-lg-center'>
                <button className='btn btn-icon btn-light-info btn-sm me-1 '>
                  <i className='fa-solid fa-gift'></i>
                </button>
                <div className='mt-3'>Holidays</div>
                <div className='fs-3 fw-bold counted text-info mt-2'>2500</div>
              </div>
            </div>
            <div
              className='card card-custom bg-light-secondary rounded-3 gutter-b card-stretch me-2'
              style={{width: '160px'}}
            >
              <div className='card-body text-lg-center'>
                <button className='btn btn-icon btn-secondary btn-sm me-1 '>
                  <i className='fa-solid fa-calendar-day'></i>
                </button>
                <div className='mt-3'>Weekly off</div>
                <div className='fs-3 fw-bold counted text-secondary mt-2'>2500</div>
              </div>
            </div>
            <div
              className='card card-custom bg-light-secondary rounded-3 gutter-b card-stretch me-2'
              style={{width: '160px'}}
            >
              <div className='card-body text-lg-center'>
                <button className='btn btn-icon btn-light-warning btn-sm me-1 '>
                  <i className='fa-solid fa-arrow-right-from-bracket'></i>
                </button>
                <div className='mt-3'>Check Out</div>
                <div className='fs-3 fw-bold counted text-warning mt-2'>2500</div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className='d-flex mt-5'>
      <div className="card me-2">
      <div className="row">
        <div className="mixed-chart">
          <Chart
            options={state.options}
            series={state.series}
            type="bar"
            width="500"
          />
        </div>
      </div>
    </div>
      <div className='card ' style={{width: '30rem'}}>
        <div className='card-header border-0 pt-4'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-4 mb-1'>Upcoming Bday's🎂🎊🎉</span>
          </h3>
        </div>
        <div className='card-body py-2'>
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-3'>
              <thead>
                <tr>
                  <th className=''></th>
                  <th className=''></th>
                  <th className=''></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className='symbol symbol-40px'>
                      <img src='media/avatars/300-20.jpg' alt='' />
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-900 fw-bold text-hover-primary mb-1 fs-6'>
                      Akanksha Bangar
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-900 fw-bold text-hover-primary mb-1 fs-6'>
                      Mon, 22 Feb 2024
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className='symbol symbol-40px'>
                      <img src='media/avatars/300-20.jpg' alt='' />
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-900 fw-bold text-hover-primary mb-1 fs-6'>
                      Mayuri Tupe
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-900 fw-bold text-hover-primary mb-1 fs-6'>
                      Mon, 22 Feb 2024
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className='symbol symbol-40px'>
                      <img src='media/avatars/300-20.jpg' alt='' />
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-900 fw-bold text-hover-primary mb-1 fs-6'>
                      Sakshi Lambe
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-900 fw-bold text-hover-primary mb-1 fs-6'>
                      Mon, 22 Feb 2024
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      </div> */}
    </div>
  )
}

export default EmployeeDashboard
