import {toast} from 'react-toastify'
import Swal from 'sweetalert2'
import {Message} from '../../../_metronic/helpers'
import {addUser, getUsers, updateUserById} from '../../../api/AdminAPI'
import {UPDATE_MESSAGE} from '../../../utils/contants'
import {AlertProps} from '../../data/AlertDefaults'

export const getUser = (setUser: Function, setAPIStatus: Function) => {
  setAPIStatus((prev: any) => ({...prev, loading: true}))
  getUsers().then((res) => {
    if (res.statusCode === 200) {
      setUser(res.data)

      setAPIStatus((prev: any) => ({...prev, loading: false}))
    } else {
      setAPIStatus((prev: any) => ({...prev, loading: false, error: res.message}))
    }
  })
}

// Update the handleInputChange function
export const handleInputChange = (event, setInputField, inputField) => {
  const {name, value, type, checked} = event.target

  if (type === 'checkbox') {
    setInputField((prevInputField) => ({
      ...prevInputField,
      [name]: checked,
    }))
  } else if (name === 'inTime') {
    const inTime = new Date(`2000-01-01T${value}`)
    const outTime = new Date(inTime.getTime() + 9 * 60 * 60 * 1000)
    const formattedOutTime = outTime.toTimeString().slice(0, 5)

    setInputField((prevInputField) => ({
      ...prevInputField,
      [name]: value,
      outTime: formattedOutTime,
    }))
  } else {
    setInputField((prevInputField) => ({
      ...prevInputField,
      [name]: name === 'password' && value === '' ? undefined : value,
    }))
  }
}

export const handleSubmit = (
  InputFieldType: {
    firstName: string
    lastName: string
    email: string
    contact: string
    password: string
    dob: string
    gender: string
    employmentType: string
    designation: string
    startDate: string
    endDate: string
    role: string
    inTime: string
    outTime: string
    reportingManager: string
    personalEmail: string
    hr: string
    currentUserEmail: string | null
  },
  getUser: Function,
  setUser: Function,
  setEditId: Function,
  initialState: {
    firstName: string
    lastName: string
    email: string
    contact: string
    password: string
    dob: string
    role: string
    hr: string
    gender: string
    employmentType: string
    designation: string
    startDate: string
    endDate: string
    inTime: string
    outTime: string
    reportingManager: string
    personalEmail: string
  },
  setInputField: Function,
  editId: string | null,
  cancelSubmit: Function,
  setAPIStatus: Function,
  setInputError: Function,
  validateForm: Function,
  setSubmitAPIStatus: Function,
  handleClose: Function,
  fetchUsers: Function
) => {
  let {cnt, error} = validateForm(InputFieldType)
  setInputError(error)

  if (cnt === 0) {
    if (editId == null) {
      console.log('Please enter')
      setSubmitAPIStatus((prev: any) => ({...prev, loading: true}))
      addUser(InputFieldType)
        .then((res) => {
          if (res.statusCode === 200) {
            toast.success(res.message)
            getUser(setUser, setAPIStatus)
            fetchUsers()
            cancelSubmit(setInputField, initialState, setEditId, setInputError)
            setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
            handleClose()
          } else {
            Message(res.message, 'error')
            setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
          }
          return res.json()
        })
        .then((data) => {
          console.log(data)
        })
        .catch((error) => {
          console.error('Error adding user:', error)
        })
    } else {
      Swal.fire({...AlertProps, icon: 'warning', title: UPDATE_MESSAGE}).then((result: any) => {
        if (result.isConfirmed) {
          setSubmitAPIStatus((prev: any) => ({...prev, loading: true}))
          updateUserById(InputFieldType, editId).then((res) => {
            if (res.statusCode === 200) {
              getUser(setUser, setAPIStatus)
              toast.success(res.message)
              cancelSubmit(setInputField, initialState, setEditId, setInputError)
              setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
            } else {
              Message(res.message, 'error')
              setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
            }
          })
        }
      })
    }
  }
}

export const cancelSubmit = (
  setInputField: Function,
  InputField: {
    firstName: string
    lastName: string
    email: string
    contact: string
    password: string
    dob: string
    gender: string
    role: string
    hr: string
    employmentType: string
    designation: string
    startDate: string
    endDate: string | null
    inTime: string
    outTime: string
    reportingManager: string

    personalEmail: string
  },
  setEditId: Function,
  setShowForm: Function
) => {
  setEditId(null)
  setShowForm(false)
  setInputField(InputField)
}

// begin:: form validation

export const validateForm = (InputField: {
  firstName: string
  lastName: string
  email: string
  contact: string
  password: string
  dob: string
  gender: string
  employmentType: string
  designation: string
  startDate: string
  endDate: string
  inTime: string
  role: string
  hr: string
  outTime: string
  reportingManager: string

  currentUserEmail: string | null
  personalEmail: string
}) => {
  let cnt = 0
  const error: any = {}

  if (!InputField.firstName.trim()) {
    cnt = cnt + 1
    error.firstName = 'Please enter a First Name.'
  } else {
    // First Name must be at least 2 characters.
    if (InputField.firstName.length < 2) {
      cnt = cnt + 1
      error.firstName = 'First Name must be at least 2 characters.'
    }

    // First Name must not exceed 35 characters.
    if (InputField.firstName.length > 35) {
      cnt = cnt + 1
      error.firstName = 'First Name must not exceed 35 characters.'
    }

    // First Name must contain only alphabetic characters.
    const nameRegex = /^[a-zA-Z]+$/
    if (!nameRegex.test(InputField.firstName)) {
      cnt = cnt + 1
      error.firstName = 'Please enter a valid First Name using only alphabetic characters.'
    }
  }

  if (!InputField.lastName.trim()) {
    cnt = cnt + 1
    error.lastName = 'Please enter a Last Name.'
  } else {
    // First Name must be at least 2 characters.
    if (InputField.lastName.length < 2) {
      cnt = cnt + 1
      error.lastName = 'Last Name must be at least 2 characters.'
    }

    // First Name must not exceed 35 characters.
    if (InputField.lastName.length > 35) {
      cnt = cnt + 1
      error.lastName = 'Last Name must not exceed 35 characters.'
    }

    // First Name must contain only alphabetic characters.
    const nameRegex = /^[a-zA-Z]+$/
    if (!nameRegex.test(InputField.lastName)) {
      cnt = cnt + 1
      error.lastName = 'Please enter a valid Last Name using only alphabetic characters.'
    }
  }

  if (!InputField.contact.trim()) {
    cnt = cnt + 1
    error.contact = 'Please enter a Mobile Number.'
  } else {
    // Check if contact is numeric
    if (!/^\d+$/.test(InputField.contact)) {
      cnt = cnt + 1
      error.contact = 'Please enter a valid Mobile Number using only numeric characters.'
    } else if (InputField.contact.length !== 10) {
      cnt = cnt + 1
      error.contact = 'Mobile Number must be exactly 10 digits.'
    }
  }

  // ...

  if (!InputField.password.trim()) {
    cnt = cnt + 1
    error.password = 'Please enter a Password'
  } else {
    // Password must be at least 8 characters.
    if (InputField.password.length < 8) {
      cnt = cnt + 1
      error.password = 'Password must be at least 8 characters.'
    }

    // Password must not exceed 16 characters.
    if (InputField.password.length > 16) {
      cnt = cnt + 1
      error.password = 'Password must not exceed 16 characters.'
    }

    // Password must contain at least one uppercase letter.
    const uppercaseRegex = /[A-Z]/
    if (!uppercaseRegex.test(InputField.password)) {
      cnt = cnt + 1
      error.password = 'Password must contain at least one uppercase letter.'
    }

    // Password must contain at least one lowercase letter.
    const lowercaseRegex = /[a-z]/
    if (!lowercaseRegex.test(InputField.password)) {
      cnt = cnt + 1
      error.password = 'Password must contain at least one lowercase letter.'
    }

    // Password must contain at least one number.
    const numberRegex = /\d/
    if (!numberRegex.test(InputField.password)) {
      cnt = cnt + 1
      error.password = 'Password must contain at least one number.'
    }

    // Password must contain at least one special symbol.
    const specialSymbolRegex = /[!@#$%^&*(),.?":{}|<>]/
    if (!specialSymbolRegex.test(InputField.password)) {
      cnt = cnt + 1
      error.password = 'Password must contain at least one special symbol.'
    }
  }

  // ...

  if (!InputField.dob.trim()) {
    cnt = cnt + 1
    error.dob = 'Please enter/select a Date of Birth.'
  } else {
    const dobDate = new Date(InputField.dob)
    const currentDate = new Date()
    const minAge = 18

    // Calculate age
    const age =
      currentDate.getFullYear() -
      dobDate.getFullYear() -
      (currentDate.getMonth() < dobDate.getMonth() ||
      (currentDate.getMonth() === dobDate.getMonth() && currentDate.getDate() < dobDate.getDate())
        ? 1
        : 0)

    if (age < minAge) {
      cnt = cnt + 1
      error.dob = `Users must be at least ${minAge} years old to register.`
    }
  }

  if (!InputField.gender.trim()) {
    cnt = cnt + 1
    error.gender = 'Please select a Gender.'
  }
  if (!InputField.employmentType.trim()) {
    cnt = cnt + 1
    error.employmentType = 'Please select a Employment Type.'
  }
  if (!InputField.startDate.trim()) {
    cnt = cnt + 1
    error.startDate = 'Please enter/select a Date of Joining.'
  }

  if (InputField.endDate.trim() && InputField.startDate.trim()) {
    const endDate = new Date(InputField.endDate)
    const startDate = new Date(InputField.startDate)

    if (endDate < startDate) {
      cnt = cnt + 1
      error.endDate = 'Date of Leaving must be greater than Date of Joining.'
    }
  }

  if (!InputField.inTime.trim()) {
    cnt = cnt + 1
    error.inTime = 'Please enter/select an In Time.'
  }
  if (!InputField.outTime.trim()) {
    cnt = cnt + 1
    error.outTime = 'Please enter/select an Out Time.'
  }

  const baseDate = new Date()

  const [inHours, inMinutes] = InputField.inTime.split(':')
  const [outHours, outMinutes] = InputField.outTime.split(':')

  const inTime = new Date(baseDate)
  inTime.setHours(parseInt(inHours, 10), parseInt(inMinutes, 10), 0, 0)

  const outTime = new Date(baseDate)
  outTime.setHours(parseInt(outHours, 10), parseInt(outMinutes, 10), 0, 0)

  // Check if out time is earlier than in time and adjust for the next day
  if (outTime.getTime() < inTime.getTime()) {
    outTime.setDate(outTime.getDate() + 1)
  }

  const timeDifferenceInMilliseconds = outTime.getTime() - inTime.getTime()
  const timeDifferenceInHours = timeDifferenceInMilliseconds / (1000 * 60 * 60)

  console.log('Time Difference in Hours:', timeDifferenceInHours)

  if (timeDifferenceInHours > 9) {
    cnt = cnt + 1
    error.outTime =
      "Please ensure that the 'Out Time' does not exceed 9 hours after the 'In Time', and Please adjust your 'In Time' and 'Out Time' accordingly."
  } else if (timeDifferenceInHours < 4) {
    cnt = cnt + 1
    error.outTime =
      "Please ensure that the 'Out Time' must be at least 4 hours after the 'In Time', and Please adjust your 'In Time' and 'Out Time' accordingly."
  }

  if (!InputField.personalEmail.trim()) {
    cnt = cnt + 1
    error.personalEmail = 'Please enter a Personal Email.'
  } else {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRegex.test(InputField.personalEmail.trim())) {
      cnt = cnt + 1
      error.personalEmail = 'Please enter a valid Personal Email.'
    }
  }
  // if (!InputField.email.trim()) {
  //   cnt = cnt + 1
  //   error.email = 'Please enter an Organization Email.'
  // } else {
  //   const organizationEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  //   if (!organizationEmailRegex.test(InputField.email.trim())) {
  //     cnt = cnt + 1
  //     error.email = 'Please enter a valid Organization Email.'
  //   }
  // }
  // Assuming currentUser is available and contains the email property
  const currentUserEmail = InputField.currentUserEmail
  if (!InputField.email.trim()) {
    cnt = cnt + 1
    error.email = 'Please enter an Organization Email.'
  } else {
    const organizationEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!organizationEmailRegex.test(InputField.email.trim())) {
      cnt = cnt + 1
      error.email = 'Please enter a valid Organization Email.'
    } else if (currentUserEmail) {
      const currentUserDomain = currentUserEmail.split('@')[1]
      const inputEmailDomain = InputField.email.trim().split('@')[1]
      if (currentUserDomain !== inputEmailDomain) {
        cnt = cnt + 1
        // error.email = 'Organization email domain must match your current email domain.'
        error.email = 'Please enter a valid organization email domain.'
      }
    }
  }

  if (!InputField.designation.trim()) {
    cnt = cnt + 1
    error.designation = 'Please select a Designation.'
  }

  if (!InputField.reportingManager.trim()) {
    cnt = cnt + 1
    error.reportingManager = 'Please select a Reporting Manager.'
  }
  // if (!InputField.hr.trim()) {
  //   cnt = cnt + 1
  //   error.hr = 'Please select a Human Resource.'
  // }
  if (!InputField.role) {
    cnt = cnt + 1
    error.role = 'Please select a Role.'
  }

  return {error, cnt}
}
