import {
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import {useLocation} from 'react-router-dom'
import {Message, WithChildren, isTokenExpired} from '../../../../_metronic/helpers'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import * as authHelper from './AuthHelpers'
import {AuthModel, UserModel} from './_models'
import {logoutUser} from './_requests'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    const {token} = authHelper.setupAxios()
    localStorage.removeItem('intervalId')

    if (isTokenExpired(token)) {
      saveAuth(undefined)
      setCurrentUser(undefined)
      sessionStorage.setItem('isLoggedIn', 'false')
    } else {
      logoutUser().then((res) => {
        if (res.statusCode === 200 || res.statusCode === 401) {
          saveAuth(undefined)
          setCurrentUser(undefined)
          sessionStorage.setItem('isLoggedIn', 'false')
        } else {
          Message(res.message, 'error')
        }
      })
    }
  }

  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const location = useLocation()
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          let data = JSON.parse(window.atob(apiToken.split('.')[1]))

          if (data) {
            data = auth
            setCurrentUser(data)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.accessToken) {
      requestUser(auth.accessToken)
    } else {
      logout()
      setShowSplashScreen(false)
    }
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthInit, AuthProvider, useAuth}
