/* eslint-disable jsx-a11y/anchor-is-valid */
import {Center, Loader} from '@mantine/core'
import axios from 'axios'
import clsx from 'clsx'
import React, {useContext, useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {changeTextCamal, checkRole, toAbsoluteUrl} from '../../../_metronic/helpers'
import {getUsersById} from '../../../api/AdminAPI'
import {API_BASE_URL} from '../../../utils/contants'
import {useAuth} from '../../modules/auth'
import Overview from './overview/Overview'
import Report from './report/Report'

import Possesion from './possesion/Possesion'
import {RoleContextValue} from '../../types/OtherTypes'
import roleContext from '../../context/roleContext'

const Profile: React.FC = () => {
  const {currentUser} = useAuth()
  let roleState: RoleContextValue = useContext(roleContext)
  const params = useParams() // Access the user ID from the URL parameter
  const userId = params['id'] ?? (currentUser?.id || '')

  const [activeTab, setActiveTab] = useState<string | null>('Overview') // Initialize to 'Overview'

  const handleTabChange = (tabName: string) => {
    setActiveTab(tabName)
  }

  const IUser = {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    contact: '',
    password: '',
    dob: '',
    gender: '',
    employmentType: '',
    designation: '',
    startDate: '',
    endDate: '',
    inTime: '',
    outTime: '',
    reportingManager: {
      firstName: '',
      lastName: '',
    },
    leaveBalance: {
      totalLeave: '',
      usedLeave: '',
      remainingLeave: '',
      paidLeave: '',
      currentMonthLeave: '',
    },
  
    personalEmail: '',
    aadharNumber: '',
    panNumber: '',
    bankName: '',
    accountType: '',
    accountNumber: '',
    ifsc: '',
  }

  const [user, setUser] = useState(IUser)
  const [loader, setLoader] = useState(false)

  const fetchUserById = () => {
    getUsersById(userId)
      .then((user) => {
        console.log('leave:', user.data.leaveBalance)
        if (user.data) {
          setUser(user.data)
          setLoader(false)
        } else {
          setLoader(true)
          toast.error(user.message, {autoClose: 1000})
        }
      })
      .catch((error) => {
        console.error('Error fetching user data:', error)
      })
  }

  useEffect(() => {
    let mounted = true
    if (mounted) {
      fetchUserById()
    }
    return () => {
      mounted = false
    }
  }, [userId])

  return (
    <>
      {loader ? (
        <Center>
          <Loader />
        </Center>
      ) : (
        <div>
          <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
            <div className='card-body pt-9 pb-0'>
              <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                <div className='me-7 mb-4'>
                  <div className='symbol symbol-70px symbol-lg-70px symbol-fixed position-relative'>
                    <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt={user?.firstName} />
                    <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
                  </div>
                </div>

                <div className='flex-grow-1'>
                  <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                    <div className='d-flex flex-column'>
                      <div className='d-flex align-items-center mb-2'>
                        <span className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                          {changeTextCamal(user?.firstName)} {changeTextCamal(user.lastName)}
                        </span>
                      </div>

                      <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                        <p className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'>
                          <i className='fa-solid fa-user fs-4 me-1' />
                          {changeTextCamal(user.designation)}
                        </p>
                        <p className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'>
                          <i className='fa-solid fa-suitcase fs-4 me-1' />
                          {changeTextCamal(user.employmentType)}
                        </p>
                        <p className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'>
                          <i className='fa-solid fa-envelope fs-4 me-1' />
                          {user.email}
                        </p>
                      </div>
                    </div>

                    {/* {isAdminUser && (
                      <Menu>
                        <div className='d-flex my-4'>
                          <div className='me-0'>
                            <Menu.Target>
                              <button
                                className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                                data-kt-menu-trigger='click'
                                data-kt-menu-placement='bottom-end'
                                onClick={toggleDropdown}
                                data-kt-menu-flip='top-end'
                              >
                                <i className='bi bi-three-dots fs-3'></i>
                              </button>
                            </Menu.Target>
                            <Menu.Dropdown>
                              <Menu.Item onClick={handleLeaveEdit}>Edit Leave</Menu.Item>
                            </Menu.Dropdown>
                          </div>
                        </div>
                      </Menu>
                    )} */}
                  </div>

                  {/* <div className='d-flex flex-wrap flex-stack'>
                    <div className='d-flex flex-column flex-grow-1 pe-8'>
                      {!isLeaveEdit ? (
                        <div className='d-flex flex-wrap'>
                          <div className='text-center border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                            <div className='fs-2 fw-bolder'>
                              {user?.leaveBalance?.remainingLeave}
                            </div>
                            <div className='fw-bold fs-6 text-gray-400'>Remaining Leaves</div>
                          </div>

                          <div className='text-center border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                            <div className='fs-2 fw-bolder'>
                              {user?.leaveBalance?.currentMonthLeave}
                            </div>
                            <div className='fw-bold fs-6 text-gray-400'>Current month</div>
                          </div>

                          <div className='text-center border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                            <div className='fs-2 fw-bolder'>{user?.leaveBalance?.paidLeave}</div>
                            <div className='fw-bold fs-6 text-gray-400'>Paid Leaves</div>
                          </div>
                        </div>
                      ) : (
                        <div className='d-flex flex-wrap'>
                          <div className='text-center border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                            <input
                              type='text'
                              name='remainingLeave'
                              className='form-control form-control-sm text text-center'
                              value={LeaveData?.remainingLeave || ''}
                              onChange={(e) => {
                                handleLeaveChange(e)
                              }}
                            />
                            <div className='fw-bold fs-6 text-gray-400'>Remaining Leaves</div>
                          </div>

                          <div className='text-center border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                            <div className='fs-2 fw-bolder'>
                              <input
                                type='text'
                                name='currentMonthLeave'
                                className='form-control form-control-sm text text-center'
                                value={LeaveData?.currentMonthLeave || ''}
                                onChange={(e) => {
                                  handleLeaveChange(e)
                                }}
                              />
                            </div>
                            <div className='fw-bold fs-6 text-gray-400'>Current month</div>
                          </div>

                          <div className='text-center border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                            <input
                              type='text'
                              name='paidLeave'
                              className='form-control form-control-sm text text-center'
                              value={LeaveData?.paidLeave || ''}
                              onChange={(e) => {
                                handleLeaveChange(e)
                              }}
                            />
                            <div className='fw-bold fs-6 text-gray-400'>Paid Leaves</div>
                          </div>
                          <div className='row mb-3'>
                            <div className='col-md-12 mt-4 d-flex justify-content-end'>
                              <div>
                                <button
                                  className='btn btn-sm btn-secondary align-self-center me-2'
                                  onClick={resetLeave}
                                >
                                  Cancel
                                </button>
                                <button
                                  className='btn btn-sm btn-primary align-self-center'
                                  onClick={updateLeaveDetail}
                                >
                                  Update
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div> */}
                </div>
              </div>

              <div className='d-flex h-55px'>
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                  <li className='nav-item'>
                    <Link
                      className={clsx('nav-link text-active-primary me-6', {
                        active: activeTab === 'Overview',
                      })}
                      to=''
                      onClick={() => handleTabChange('Overview')}
                    >
                      Details
                    </Link>
                  </li>
                  {checkRole(['User Asset Details'], roleState.features) && (
                  <li
                    className='nav-item'
                  
                  >
                    <Link
                      className={clsx('nav-link text-active-primary me-6', {
                        active: activeTab === 'Possesion',
                      })}
                      to=''
                      onClick={() => handleTabChange('Possesion')}
                    >
                      Assets
                    </Link>
                  </li>)}

                  {/* <li className='nav-item'>
                    <Link
                      className={clsx(
                        'nav-link text-active-primary me-6 ',
                        activeTab === 'Report' ? 'active' : ''
                      )}
                      to=''
                      onClick={() => handleTabChange('Report')}
                    >
                      Report
                    </Link>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
          {activeTab === 'Overview' && <Overview data={user} setUser={setUser} />}
          {activeTab === 'Possesion' && <Possesion />}

          {activeTab === 'Report' && <Report />}
        </div>
      )}
    </>
  )
}

export default Profile
