import {Badge, Button, Center, Pagination, Text} from '@mantine/core'
import axios from 'axios'
import React, {useContext, useEffect, useState} from 'react'
import {Col, Form, Modal, Row} from 'react-bootstrap'
import {
  KTCard,
  KTCardBody,
  Message,
  changeTextCapital,
  convertTo24HourFormat,
  getDate,
  getPaginationInfoData,
} from '../../../_metronic/helpers'

import {faEraser, faSearch} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {toast} from 'react-toastify'
import {useThemeMode} from '../../../_metronic/partials'
import {checkIsManager, getTeamOfReportingManager} from '../../../api/AdminAPI'
import {getHolidaysByDateRange} from '../../../api/HolidayAPI'
import {API_BASE_URL, EDIT_ROW_COLORS} from '../../../utils/contants'
import Select2 from '../../components/select2'
import roleContext from '../../context/roleContext'
import {setupAxios, useAuth} from '../../modules/auth'
import {APIResponse, RoleContextValue, SelectValueType} from '../../types/OtherTypes'
import {formatCreateAt} from '../leave/LeaveFunctions'
import {getDateInYYYYMMDD, handleClearFilters, validateDateRange} from './ams'
import {spawn} from 'child_process'
import {getUserData} from '../../CommonFunction'

export interface TableData {
  result: {
    id: string
    user: {
      firstName: string
      lastName: string
      reportingManager: {
        firstName: string
        lastName: string
      }
      hrManager: {
        firstName: string
        lastName: string
      }
    }
    createdAt: string
    checkIn: string
    checkOut: string
    checkInTemp: string
    checkOutTemp: string
    date: string
    workingHours: string
    workingHoursTemp: string
    formattedWorkingHoursTemp: string
    editable: boolean
    remark: string
    status: string
    leaveType: string
    leaveStatus: string
    reason: string
    remarkUpdated?: boolean
    latitude: string
    longitude: string
    outlatitude: string
    outlongitude: string
    manualAttendance: string
    actualCheckIn: string
    actualcheckOut: string
    reasons: [
      {
        reason: string
        actor: string
        updatedAt: string
        actionBy: {
          firstName: string
          lastName: string
        }
      },
    ]
  }[]
  queryData: {
    total: string
    page: string
    take: string
  }
}

const Attendance = () => {
  let roleState: RoleContextValue = useContext(roleContext)
  const {mode} = useThemeMode()
  const {currentUser} = useAuth()
  let {currentDate} = getDate()
  const isAdminUser = currentUser?.isAdmin || false
  const [userNames, setUserNames] = useState<{label: string; value: string}[]>([])
  const [editId, setEditId] = useState(null)
  const [activePage, setActivePage] = useState(1)
  const [holidayData, setHolidayData] = useState<
    {
      date: string
      holidayDay: null | string
      holidayName: string
    }[]
  >([])
  const initialState = {
    name: '',
    employeeName: '',
    startDate: currentDate,
    endDate: currentDate,
    checkIn: '',
    checkOut: '',
    workingHours: '',
    remark: '',
    status: '',
    leaveType: '',
    leaveStatus: '',
    reason: '',
    latitude: '',
    longitude: '',
    outlatitude: '',
    outlongitude: '',
  }
  const [inputField, setInputField] = useState(initialState)
  const [nameError, setNameError] = useState('')
  const [dateError, setDateError] = useState('')
  const [totalEntries, setTotalEntries] = useState(0)
  const [lessDateError, setLessDateError] = useState('')
  const [checkInDateError, setCheckInDateError] = useState('')
  const [checkInError, setCheckInError] = useState('')
  const [reasonError, setReasonError] = useState('')
  const [ReasonShowModal, setReasonShowModal] = useState(false)
  const [reasonForHr, setReasonForHr] = useState(false)
  const [selectedItemId, setSelectedItemId] = useState('')
  const [selectedReasonId, setSelectedReasonId] = useState('')
  const [tableData, setTableData] = useState<TableData[]>([])
  const [isCreateAttendanceModalOpen, setIsCreateAttendanceModalOpen] = useState(false)
  const [editingId, setEditingId] = useState<string | null>(null)
  const [pageSize, setPageSize] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [showRow, setShowRow] = useState<number[]>([])

  const [SubmitAPIStatus, setSubmitAPIStatus] = useState({
    loading: false,
  })

  const checkArrayElement = (element: number) => {
    setShowRow((prevShowRow) => {
      const updatedShowRow = [...prevShowRow]
      const index = updatedShowRow.indexOf(element)
      if (index !== -1) {
        updatedShowRow.splice(index, 1)
      } else {
        updatedShowRow.push(element)
      }
      return updatedShowRow
    })
  }
  const truncateString = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...'
    }
    return text
  }
  const handleTrashClick = () => {
    setInputField(initialState)
    setReasonError('')
  }

  const handleEndDateBlur = () => {
    validateDateRange(inputField, setDateError, setLessDateError)
  }
  const fetchPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1)
      fetchTableData(currentPage - 1)
    }
  }

  const [noMatchingRecords, setNoMatchingRecords] = useState(false)
  const sortedUserNames = userNames.slice().sort((a, b) => a.label.localeCompare(b.label))

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target

    if (name === 'checkIn' || name === 'checkOut') {
      const isValidDateTimeFormat = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2})$/.test(value)
      if (!isValidDateTimeFormat) {
        console.error(`Invalid datetime format for ${name}: ${value}`)
        return
      }
    }
    setInputField((prevInputField) => ({
      ...prevInputField,
      [name]: value,
    }))
    if (name === 'name') {
      setNameError('')
    }
    if (name === 'checkIn') {
      setCheckInError('')
    }
  }

  const fetchTableData = async (page) => {
    const {token, tenantId} = setupAxios()
    getHolidaysByDateRange(inputField).then((res: APIResponse) => {
      if (res.statusCode) {
        setHolidayData(res.data)
      } else {
        setHolidayData([])
      }
    })
    setDateError('')
    setNameError('')
    setCheckInError('')
    await fetch(API_BASE_URL + `/attendence?page=${page}&take=${10}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'X-tenant-Id': `${tenantId}`,
      },
      body: JSON.stringify({
        tab: !inputField.employeeName ? null : 'Self',
        id: !inputField.employeeName ? currentUser?.id : inputField.employeeName,
        startDate: inputField.startDate ? inputField.startDate + 'T00:00:00' : null,
        endDate: inputField.endDate ? inputField.endDate + 'T23:59:59' : null, // Ensure endDate is null if empty
      }),
    })
      .then(async (response) => {
        let res = await response.json()
        if (res.statusCode === 200) {
          setTableData([
            {
              result: res?.data?.result ?? res?.data,
              queryData: res?.data?.queryParams ?? null,
            },
          ])
          console.log('response', res.length)
          setDateError('')
          setLessDateError('')
          setNoMatchingRecords(false)
          setTotalEntries(res.data.queryData.total)
        } else {
          setTableData([])
          setNoMatchingRecords(true)
          setTotalEntries(0)
          Message(res.message, 'error')
        }
      })
      .catch((error) => {
        console.error('Error fetching table data:', error)
      })
  }

  useEffect(() => {
    let mounted = true
    if (mounted) {
      checkIsManager(currentUser?.id ?? '').then((res1: APIResponse) => {
        if (res1.statusCode === 200) {
          if (currentUser?.designation === 'Admin') {
            getUserData(setUserNames)
          } else {
            getTeamOfReportingManager(
              currentUser?.id ?? '',
              res1?.data?.isReportingManager && res1?.data?.isHrManager
                ? 'all'
                : res1?.data?.isReportingManager
                  ? 'rm'
                  : res1?.data?.isHrManager
                    ? 'hr'
                    : ''
            ).then((res: APIResponse) => {
              if (res.statusCode === 200) {
                let users: SelectValueType[] = []
                res?.data?.map((obj: any) => {
                  users.push({
                    label: `${obj?.firstName ?? ''} ${obj?.lastName ?? ''}`,
                    value: obj.id,
                  })
                })
                setUserNames(users)
              }
            })
          }
        }
      })

      fetchTableData(activePage)
    }
    return () => {
      mounted = false
    }
  }, [])

  const handleEdit = (id: string) => {
    const updatedData = tableData.map((data) => {
      const updatedResult = data.result.map((item) => ({
        ...item,
        editable: item.id === id ? !item.editable : false,
      }))

      return {
        ...data,
        result: updatedResult,
      }
    })

    setTableData(updatedData)
  }
  const handleEditSave = async (id: any, editedItem: (typeof tableData)[0]['result'][0]) => {
    if (!inputField.reason.trim()) {
      setReasonError('Please enter a Reason.')
      return
    } else {
      setReasonError('')
    }

    const itemToUpdate = editedItem
    if (!itemToUpdate) {
      console.error('Attendance not found for ID:', id)
      return
    }
    const checkInTime = itemToUpdate.checkIn ? convertTo24HourFormat(itemToUpdate.checkIn) : null
    const checkOutTime = itemToUpdate.checkOut ? convertTo24HourFormat(itemToUpdate.checkOut) : null
    if (checkInTime !== null || checkOutTime !== null) {
      const actorRole = currentUser?.isAdmin ? 'Admin' : 'Employee'
      const filterData = {
        id: itemToUpdate.id,
        checkIn: checkInTime,
        checkOut: checkOutTime,
        reason: inputField.reason,
        actor: actorRole,
        actionBy: {id: currentUser?.id},
      }
      const {token, tenantId} = setupAxios()
      try {
        setSubmitAPIStatus((prev: any) => ({...prev, loading: true}))
        const response = await axios.patch(
          API_BASE_URL + `/userAttendence/${currentUser?.id}/update`,
          filterData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
              'X-tenant-Id': `${tenantId}`,
            },
          }
        )

        if (response.data.statusCode === 200) {
          const updatedData = {
            workingHours: response.data.data.workingHours,
            editable: false,
          }
          setIsCreateAttendanceModalOpen(false)
          setReasonError('')
          setInputField(initialState)
          toast.success(response.data.message)
          updateAttendanceById(id, updatedData)
          setActivePage(1)
          fetchTableData(1)
          setEditingId(null)
        } else {
          Message(response.data.message, 'error')
        }
        setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
      } catch (error) {
        console.error('Error updating attendance:', error)
      }
    } else {
      Message('Check-in time cannot be null.', 'error')
    }
  }

  const updateAttendanceById = async (id, updatedData) => {
    const updatedTableData = [...tableData]
    const resultIndex = updatedTableData.findIndex((data) =>
      data.result.some((item) => item.id === id)
    )

    if (resultIndex !== -1) {
      const itemIndex = updatedTableData[resultIndex].result.findIndex((item) => item.id === id)

      if (itemIndex !== -1) {
        updatedTableData[resultIndex].result[itemIndex] = {
          ...updatedTableData[resultIndex].result[itemIndex],
          ...updatedData,
        }
        setTableData(updatedTableData)
      }
    }
  }

  const handleOpenMapInNewTab = (latitude, longitude) => {
    const url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`
    window.open(url, '_blank')
  }

  const handleCreateAttendanceModalCLose = () => {
    handleTrashClick()
    setIsCreateAttendanceModalOpen(false)
    setEditingId(null)
  }

  return (
    <>
      {/* <Toolbar1 /> */}
      {/* modal for show the reason */}
      <Modal
        show={ReasonShowModal}
        className='modal-lg'
        onClose={() => setReasonShowModal(false)}
        animation={false}
        backdrop='static'
        centered
      >
        <div className='modal-header'>
          <h2 className='fw-bolder'>Reasons</h2>
          <div
            className='btn btn-icon btn-sm btn-active-icon-danger'
            data-kt-users-modal-action='close'
            style={{cursor: 'pointer'}}
            onClick={() => {
              setReasonShowModal(false)
            }}
          >
            <i className='fa-solid fa-xmark fs-1' />
          </div>
        </div>
        <div className='card-body'>
          <div className='table-responsive'>
            <table
              className='table ms-5 me-5 mt-5
                table-row-dashed table-row-gray-300 '
            >
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='pl-1 flex-start text-muted' style={{width: '30px'}}>
                    {changeTextCapital('Actor')}
                  </th>
                  <th className='pl-1 flex-start text-muted' style={{width: '100px'}}>
                    {changeTextCapital('Reason')}
                  </th>
                  <th className='pl-1 text-start text-muted' style={{width: '30px'}}>
                    {changeTextCapital('Action By')}
                  </th>
                </tr>
              </thead>
              <tbody className='text-gray-800 fw-semibold text-left'>
                {selectedReasonId && (
                  <>
                    {tableData[0]?.result
                      ?.find((record) => record.id === selectedReasonId)
                      ?.reasons?.map((reason, index) => (
                        <tr key={index}>
                          <td className='flex-start'>{reason.actor ?? '-'}</td>
                          <td className='flex-start'>{reason.reason ?? '-'}</td>
                          <td className='text-start'>
                            {reason.actionBy?.firstName ?? '-'} {reason.actionBy?.lastName ?? '-'}
                            <div className='text-muted'>
                              {formatCreateAt(reason.updatedAt ?? '-')}
                            </div>
                          </td>
                        </tr>
                      ))}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
      {/* modal to take a input reason */}

      <Modal show={isCreateAttendanceModalOpen} animation={false} backdrop='static' centered>
        <div className='modal-header'>
          <h3 className='modal-title'>Attendance Details</h3>
          <div
            className='btn btn-icon btn-sm btn-active-icon-danger'
            data-bs-placement='top'
            title='Close'
            style={{cursor: 'pointer'}}
            onClick={() => {
              handleCreateAttendanceModalCLose()
            }}
          >
            <i className='fa-solid fa-xmark fs-1' />
          </div>
        </div>
        <Modal.Body>
          <Form>
            <Form.Group className='mb-3 d-flex align-items-center'>
              <div className='me-20'>
                <Form.Label>Reporting Manager</Form.Label>
                <div>
                  <span>{tableData[0]?.result[0]?.user?.reportingManager?.firstName ?? '-'}</span>
                  <span className='ms-1'>
                    {tableData[0]?.result[0]?.user?.reportingManager?.lastName ?? '-'}
                  </span>
                </div>
              </div>
              <div className='me-20'>
                <Form.Label>Hr Manager</Form.Label>
                <div>
                  <span>{tableData[0]?.result[0]?.user?.hrManager?.firstName ?? '-'}</span>
                  <span className='ms-1'>
                    {tableData[0]?.result[0]?.user?.hrManager?.lastName ?? '-'}
                  </span>
                </div>
              </div>
            </Form.Group>

            <Form.Label className='required'>Reason</Form.Label>
            <Form.Group className=''>
              <input
                style={{padding: '7px'}}
                className='form-control'
                type='text'
                value={inputField.reason}
                name='reason'
                onChange={handleInputChange}
              />
              <span className='text-danger'>{reasonError}</span>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className='btn btn-secondary me-2 btn-sm font-weight-bold'
            onClick={handleTrashClick}
          >
            Clear
          </Button>
          <Button
            className='btn btn-primary me-2 btn-sm font-weight-bold'
            loading={SubmitAPIStatus.loading}
            onClick={() => {
              const editedItem = tableData
                .flatMap((data) => data.result)
                .find((item) => item.id === editingId)
              if (editedItem) {
                handleEditSave(editingId, editedItem)
                console.error('Edited item not found for ID:', editingId)
              }
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <div
        className='card my-3'
        hidden={holidayData?.every(
          (holiday) =>
            !tableData[0]?.result?.some(
              (record) => getDateInYYYYMMDD(holiday.date) === getDateInYYYYMMDD(record.createdAt)
            )
        )}
      >
        <div className='card-body py-2'>
          <span className='fs-4 me-2'>Holidays:</span>
          {holidayData.map((holiday) => {
            return (
              <Badge
                className='me-1 fs-7 mb-1'
                color='red'
                size='xl'
                hidden={tableData[0]?.result?.every(
                  (application) =>
                    getDateInYYYYMMDD(holiday.date) !== getDateInYYYYMMDD(application.createdAt)
                )}
              >
                {holiday.date} : {holiday.holidayName}
              </Badge>
            )
          })}
        </div>
      </div>
      <KTCard>
        {isAdminUser ? (
          <div className='card-header'>
            <div className='col-12'>
              <div className='d-flex my-5'>
                <div className='row d-flex justify-content-between'>
                  <div className='col-md-4'>
                    <div className='row'>
                      <div className='fv-row  col-md-3'>
                        <label className='fs-6 fw-semibold mb-2'>Name</label>
                      </div>
                      <div className='col-12'>
                        <div className='col-12'>
                          <Select2
                            name='employeeName'
                            data={sortedUserNames}
                            value={inputField.employeeName}
                            onChange={(selectedOption: any) => {
                              setInputField((prevInputField) => ({
                                ...prevInputField,
                                employeeName: selectedOption,
                              }))
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='row'>
                      <div className='fv-row me-6 '>
                        <label className='fs-6 fw-semibold mb-2'>Start Date</label>
                      </div>
                      <div className='col-12'>
                        <input
                          style={{padding: '7px'}}
                          className='form-control '
                          type='date'
                          value={inputField.startDate}
                          name='startDate'
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <span className='text-danger'>{dateError}</span>
                  </div>
                  <div className='col-md-4' style={{paddingLeft: '5px'}}>
                    <div className='row'>
                      <div className='fv-row '>
                        <label className='fs-6 fw-semibold mb-2'>End Date</label>
                      </div>
                      <div className='col-12'>
                        <input
                          style={{padding: '7px'}}
                          className='form-control  custom-select-field'
                          type='date'
                          value={inputField.endDate}
                          name='endDate'
                          onChange={handleInputChange}
                          onBlur={handleEndDateBlur}
                        />
                        {lessDateError && <div className='text-danger'>{lessDateError}</div>}
                      </div>
                    </div>
                  </div>
                </div>
                <Row className=' mt-3 ms-2'>
                  <Col md={6}>
                    <Button
                      onClick={() => {
                        if (validateDateRange(inputField, setDateError, setLessDateError)) {
                          fetchTableData(1)
                          setActivePage(1)
                        } else {
                        }
                      }}
                      type='button'
                      className='btn btn-primary me-2 btn-sm font-weight-bold'
                      style={{height: '33px', width: '80px'}}
                    >
                      Search
                    </Button>{' '}
                  </Col>

                  <Col md={6}>
                    <div className='fv-row '>
                      <label className='fs-6 fw-semibold mb-2'></label>
                    </div>
                    <Button
                      type='button'
                      className='btn btn-secondary me-2 btn-sm font-weight-bold'
                      style={{height: '33px', width: '80px'}}
                      onClick={() => {
                        setActivePage(1)
                        handleClearFilters(
                          setInputField,
                          initialState,
                          setLessDateError,
                          currentUser,
                          setTableData,
                          setDateError,
                          setNameError,
                          setCheckInError,
                          setNoMatchingRecords,
                          null,
                          setHolidayData
                        )
                      }}
                    >
                      Clear
                    </Button>{' '}
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className='card-header'>
              <div className='col-12'>
                <div className='d-flex my-5'>
                  <div className='row'>
                    <div className='col-md-2 mb-2 me-15'>
                      <div className='row'>
                        <div className='col-3'>
                          <input
                            style={{padding: '7px', width: '12rem'}}
                            className='form-control '
                            type={inputField?.startDate ? 'date' : 'text'}
                            value={inputField?.startDate}
                            name='startDate'
                            max={currentDate}
                            placeholder='Select Start Date'
                            onChange={(e) => {
                              handleInputChange(e)
                              setDateError('')
                              setLessDateError('')
                            }}
                            onFocus={(e) => {
                              if (!inputField?.startDate) {
                                e.target.type = 'date'
                              }
                            }}
                            onBlur={(e) => {
                              if (!e.target.value) {
                                e.target.type = 'text'
                              }
                            }}
                          />
                        </div>
                      </div>
                      {dateError && <span className='text-danger'>{dateError}</span>}
                    </div>
                    <div className='col-md-2 mb-2 me-15'>
                      <div className='row'>
                        <div className='col-3'>
                          <input
                            style={{padding: '7px', width: '12rem'}}
                            className='form-control '
                            type={inputField?.endDate ? 'date' : 'text'}
                            value={inputField?.endDate}
                            name='endDate'
                            max={currentDate}
                            placeholder='Select End Date'
                            onChange={(e) => {
                              handleInputChange(e)
                              setLessDateError('')
                            }}
                            onFocus={(e) => {
                              if (!inputField?.endDate) {
                                e.target.type = 'date'
                              }
                            }}
                            onBlur={(e) => {
                              if (!e.target.value) {
                                e.target.type = 'text'
                              }
                            }}
                          />
                        </div>
                      </div>
                      {lessDateError && <span className='text-danger'>{lessDateError}</span>}
                    </div>

                    <div className='col-md-2 mb-2 me-15'>
                      <div className='row'>
                        <div className='col-3'>
                          <Select2
                            name='employeeName'
                            placeholder='Select User'
                            style={{width: '12rem'}}
                            data={sortedUserNames}
                            value={inputField?.employeeName}
                            onChange={(selectedOption: any) => {
                              setInputField((prevInputField) => ({
                                ...prevInputField,
                                employeeName: selectedOption,
                              }))
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='col-md-3 ms-md-2 mt-1'>
                      <Button
                        onClick={() => {
                          if (validateDateRange(inputField, setDateError, setLessDateError))
                            fetchTableData(1)
                          setActivePage(1)
                        }}
                        type='button'
                        className='btn btn-primary me-2 btn-sm font-weight-bold'
                      >
                        <FontAwesomeIcon icon={faSearch} />
                      </Button>{' '}
                      <Button
                        type='button'
                        className='btn btn-secondary me-2 btn-sm font-weight-bold'
                        onClick={() => {
                          setActivePage(1)
                          handleClearFilters(
                            setInputField,
                            initialState,
                            setLessDateError,
                            currentUser,
                            setTableData,
                            setDateError,
                            setNameError,
                            setCheckInError,
                            setNoMatchingRecords,
                            null,
                            setHolidayData
                          )
                        }}
                      >
                        <FontAwesomeIcon icon={faEraser} />
                      </Button>{' '}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <KTCardBody className='py-4'>
          <div className='table-responsive'>
            <table
              id='kt_table_users'
              key={tableData.length}
              className='table align-middle table-row-dashed fs-6 gy-3 dataTable'
            >
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className=''></th>

                  <th className='' style={{width: '200px'}}>
                    {changeTextCapital('Name')}
                  </th>

                  <th className='' style={{width: '160px'}}>
                    {changeTextCapital('Date')}
                  </th>
                  <th className='' style={{width: '230px'}}>
                    {changeTextCapital('Check In')}
                  </th>
                  <th className='' style={{width: '230px'}}>
                    {changeTextCapital('Check Out')}
                  </th>
                  <th className='' style={{width: '200px'}}>
                    {changeTextCapital('Working Hours')}
                  </th>
                  {/* <th className='' style={{width: '100px'}}>
                    {changeTextCapital('Reason')}
                  </th>
                  <th className='' style={{width: '100px'}}>
                    {changeTextCapital('Status')}
                  </th> */}

                  {/* <th
                    hidden={!checkRole(['All Attendance'], roleState.features, 'edit')}
                    className='text-center'
                    style={{width: '150px'}}
                  >
                    {changeTextCapital('Action')}
                  </th> */}
                </tr>
              </thead>
              {tableData[0]?.result?.length > 0 ? (
                <tbody>
                  {noMatchingRecords && (
                    <tr className=''>
                      <td colSpan={7}>
                        <Center>
                          <div
                            className='alert alert-secondary my-0  '
                            role='alert'
                            style={{
                              textAlign: 'center',
                              color: mode === 'light' ? 'black' : 'white',
                            }}
                          >
                            No matching records found
                          </div>
                        </Center>
                      </td>
                    </tr>
                  )}

                  {tableData[0].result.map((item, index) => (
                    <>
                      <tr
                        key={index}
                        className={
                          holidayData.some(
                            (holiday) =>
                              getDateInYYYYMMDD(holiday.date) === getDateInYYYYMMDD(item.createdAt)
                          )
                            ? 'text-danger'
                            : ''
                        }
                      >
                        {item?.remark === 'pending' || item?.remark === 'review' ? (
                          <td>
                            <div>
                              <span
                                className={`badge badge-light-primary cursor-pointer ${showRow.includes(
                                  index
                                )}`}
                                onClick={() => {
                                  checkArrayElement(index)
                                }}
                              >
                                {showRow.includes(index) ? '↑' : '↓'}
                              </span>
                            </div>
                          </td>
                        ) : (
                          <td></td>
                        )}

                        <td className=''>
                          {item?.user?.firstName ?? '-'} {item?.user?.lastName ?? '-'}
                        </td>

                        <td className='' style={{width: '175px'}}>
                          {' '}
                          {item?.date ?? '-'}{' '}
                        </td>

                        <td className=''>
                          {!item?.editable && (
                            <>
                              {item?.latitude !== null && item?.longitude !== null ? (
                                <button
                                  type='button'
                                  className='btn btn-icon btn-light-primary btn-sm me-3'
                                  onClick={() =>
                                    handleOpenMapInNewTab(item?.latitude, item?.longitude)
                                  }
                                >
                                  <i className='fas fa-location-dot'></i>
                                </button>
                              ) : item.manualAttendance ? (
                                ''
                              ) : (
                                <button
                                  type='button'
                                  className='btn btn-icon btn-light-primary btn-sm me-3'
                                  disabled
                                >
                                  <i className='fas fa-location-dot' style={{opacity: 0.5}}></i>
                                </button>
                              )}
                            </>
                          )}

                          {item?.editable ? (
                            <input
                              className='form-control'
                              type='time'
                              value={convertTo24HourFormat(item?.checkIn)}
                              onChange={(e) => {
                                const updatedData = [...tableData]
                                const resultIndex = updatedData[0].result.findIndex(
                                  (resultItem) => {
                                    return resultItem?.id === item?.id
                                  }
                                )

                                if (resultIndex !== -1) {
                                  const updatedItem = {
                                    ...item,
                                    checkIn: e.target.value,
                                  }
                                  updatedData[0].result[resultIndex] = updatedItem
                                  setTableData(updatedData)
                                }
                              }}
                            />
                          ) : item?.manualAttendance ? (
                            '-'
                          ) : // <span>{item?.checkIn ?? '-'}</span>
                          item?.checkIn === '' ? (
                            '-'
                          ) : (
                            item?.checkIn
                          )}
                        </td>

                        <td className=''>
                          {!item?.editable && (
                            <>
                              {item?.outlatitude !== null && item?.outlongitude !== null ? (
                                <button
                                  type='button'
                                  className='btn btn-icon btn-light-warning btn-sm me-3'
                                  onClick={() =>
                                    handleOpenMapInNewTab(item?.outlatitude, item?.outlongitude)
                                  }
                                >
                                  <i className='fas fa-location-dot'></i>
                                </button>
                              ) : item?.manualAttendance ? (
                                '-'
                              ) : (
                                <button
                                  type='button'
                                  className='btn btn-icon btn-light-warning btn-sm me-3'
                                  disabled
                                >
                                  <i className='fas fa-location-dot' style={{opacity: 0.5}}></i>
                                </button>
                              )}
                            </>
                          )}

                          {item?.editable ? (
                            <input
                              className='form-control'
                              type='time'
                              value={convertTo24HourFormat(item?.checkOut)}
                              onChange={(e) => {
                                const updatedData = [...tableData]
                                const resultIndex = updatedData[0].result.findIndex(
                                  (resultItem) => {
                                    return resultItem?.id === item?.id
                                  }
                                )

                                if (resultIndex !== -1) {
                                  const updatedItem = {
                                    ...item,
                                    checkOut: e.target.value,
                                  }
                                  updatedData[0].result[resultIndex] = updatedItem
                                  setTableData(updatedData)
                                }
                              }}
                            />
                          ) : item.manualAttendance ? (
                            '-'
                          ) : item?.checkOut === '' ? (
                            '-'
                          ) : (
                            item?.checkOut
                          )}
                        </td>

                        <td className=''>{item?.workingHours ?? '-'}</td>
                        {/* <td
                          className='text-start cursor-pointer'
                          onClick={() => {
                            setSelectedReasonId(item?.id)
                            setReasonShowModal(true)
                          }}
                        >
                          {truncateString(item?.reasons[0]?.reason ?? '-', 10)}
                        </td>

                        <td className='' style={{width: '00px', whiteSpace: 'pre-wrap'}}>
                          {item.remark ? (
                            <span
                              className={`badge ${
                                item?.remark === 'accept'
                                  ? 'badge-light-success'
                                  : item?.remark === 'pending'
                                    ? 'badge-light-warning'
                                    : item?.remark === 'reject'
                                      ? 'badge-light-danger'
                                      : item?.remark === 'Changed By Admin'
                                        ? 'bg-secondary'
                                        : item?.remark === 'review'
                                          ? 'badge-light-warning'
                                          : ''
                              }`}
                            >
                              {item?.remark === 'accept'
                                ? 'Approved'
                                : item?.remark === 'pending'
                                  ? 'Pending'
                                  : item?.remark === 'reject'
                                    ? 'Declined'
                                    : item?.remark === 'Changed By Admin'
                                      ? 'By Admin'
                                      : item?.remark === 'review'
                                        ? 'Pending With HR'
                                        : '-'}
                            </span>
                          ) : (
                            '-'
                          )}
                        </td> */}

                        {/* <td  className='' hidden={!checkRole(['My Attendance'], roleState.features, 'edit')}>
                          {item.remark === 'pending' || item.remark === 'review' ? (
                            isAdminUser && item.remark === 'review' ? (
                              <td>
                                <button
                                  className='btn btn-icon btn-light-primary btn-sm me-1'
                                  onClick={() =>
                                    handleAttendanceApproves(item.id, currentUser, fetchTableData)
                                  }
                                >
                                  <i className='fa-solid fa-check'></i>
                                </button>
                                <button
                                  className='btn btn-icon btn-light-danger btn-sm me-1 '
                                  onClick={() => {
                                    setReasonForHr(true)
                                    setSelectedItemId(item?.id)
                                  }}
                                >
                                  <i className='fa-solid fa-xmark'></i>
                                </button>
                              </td>
                            ) : (
                              <td className='text-center'>
                                <button
                                  className='btn btn-icon btn-secondary btn-sm me-1 disabled'
                                  onClick={() => handleEdit(item?.id.toString())}
                                >
                                  <i className='far fa-edit'></i>
                                </button>
                              </td>
                            )
                          ) : (
                            <td className='text-center'>
                              {item?.editable || editingId === item?.id ? (
                                <>
                                  <button
                                    className='btn btn-icon btn-light-success btn-sm me-1'
                                    onClick={() => {
                                      setIsCreateAttendanceModalOpen(true)
                                      setEditingId(item?.id)
                                    }}
                                  >
                                    <i className='far fa-save'></i>
                                  </button>
                                  <Button
                                    className='btn btn-icon btn-light-danger btn-sm me-1'
                                    style={{marginLeft: '15px', height: '35px'}}
                                    onClick={() => {
                                      if (item?.editable) {
                                        handleEdit(item?.id.toString())
                                        fetchTableData()
                                      } else {
                                        const originalItem = tableData[index]?.result.find(
                                          (i) => i?.id === item?.id
                                        )
                                        const currentItem = item
                                        if (
                                          originalItem?.checkIn !== currentItem?.checkIn ||
                                          originalItem?.checkOut !== currentItem?.checkOut ||
                                          originalItem?.remark !== currentItem?.remark
                                        ) {
                                          handleEdit(item?.id.toString())
                                        }
                                      }
                                    }}
                                  >
                                    <i className='fas fa-xmark'></i>
                                  </Button>
                                </>
                              ) : (
                                <button
                                  className='btn btn-icon btn-light-primary btn-sm me-1'
                                  onClick={() => handleEdit(item?.id.toString())}
                                >
                                  <i className='far fa-edit'></i>
                                </button>
                              )}
                            </td>
                          )}
                        </td> */}
                      </tr>

                      <tr key={`secondIndex${index}`} hidden={!showRow.includes(index)}>
                        <td colSpan={5}>
                          <>
                            <div className='table-responsive'>
                              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                <tbody>
                                  {tableData[0].result
                                    .filter((detail) => detail.id === item.id)
                                    .map((detail, index) => (
                                      <>
                                        <tr
                                          className={`${
                                            editId === item?.id ?? '-' ? `${EDIT_ROW_COLORS} ` : ''
                                          }`}
                                          key={`${item?.id ?? '-'}-details`}
                                        >
                                          <td style={{width: '22rem'}}></td>
                                          <td
                                            className='text-muted fw-bold'
                                            style={{width: '260rem'}}
                                          >
                                            {changeTextCapital('Previous Record')}
                                          </td>

                                          <td style={{width: '200rem'}}></td>

                                          {item?.manualAttendance ? (
                                            <td>
                                              <div className='text-center'>-</div>
                                            </td>
                                          ) : (
                                            <td style={{width: '230rem'}}>
                                              <>
                                                {item?.latitude !== null &&
                                                item?.longitude !== null ? (
                                                  <button
                                                    type='button'
                                                    className='btn btn-icon btn-light-primary btn-sm me-3'
                                                    onClick={() =>
                                                      handleOpenMapInNewTab(
                                                        item?.latitude,
                                                        item?.longitude
                                                      )
                                                    }
                                                  >
                                                    <i className='fas fa-location-dot'></i>
                                                  </button>
                                                ) : (
                                                  <button
                                                    type='button'
                                                    className='btn btn-icon btn-light-primary btn-sm me-3'
                                                    disabled
                                                  >
                                                    <i
                                                      className='fas fa-location-dot'
                                                      style={{opacity: 0.5}}
                                                    ></i>
                                                  </button>
                                                )}
                                              </>
                                              {item?.actualCheckIn === ''
                                                ? '-'
                                                : item?.actualCheckIn}
                                            </td>
                                          )}
                                          {item?.manualAttendance ? (
                                            <td>
                                              <div className='text-center'>-</div>
                                            </td>
                                          ) : (
                                            <td style={{width: '140rem'}}>
                                              <>
                                                {item?.outlatitude !== null &&
                                                item?.outlongitude !== null ? (
                                                  <button
                                                    type='button'
                                                    className='btn btn-icon btn-light-primary btn-sm me-3'
                                                    onClick={() =>
                                                      handleOpenMapInNewTab(
                                                        item?.outlatitude,
                                                        item?.outlongitude
                                                      )
                                                    }
                                                  >
                                                    <i className='fas fa-location-dot'></i>
                                                  </button>
                                                ) : (
                                                  <button
                                                    type='button'
                                                    className='btn btn-icon btn-light-warning btn-sm me-3 ms-7'
                                                    disabled
                                                  >
                                                    <i
                                                      className='fas fa-location-dot'
                                                      style={{opacity: 0.5}}
                                                    ></i>
                                                  </button>
                                                )}
                                              </>
                                              {item?.actualcheckOut === ''
                                                ? '-'
                                                : item?.actualcheckOut}
                                            </td>
                                          )}
                                          <td style={{width: '20rem'}} className=''>
                                            <div> {item?.formattedWorkingHoursTemp ?? '-'}</div>
                                          </td>
                                        </tr>
                                      </>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </>
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan={7}>
                      <Center>No Record Available.</Center>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
          <div className='d-flex mt-5 justify-content-between'>
            {/* <div className='d-flex'>
                <div className='dataTables_length ' id='example_length'>
                  <label className='d-flex align-items-center'>
                    <select
                      name='example_length'
                      aria-controls='example'
                      className='form-select form-select-sm'
                      onChange={(e) => {
                        const selectedPageSize = parseInt(e.target.value, 10)
                        setPageSize(selectedPageSize)
                        setCurrentPage(1)
                        fetchTableData(1, selectedPageSize)
                      }}
                    >
                      <option value='10'>10</option>
                      <option value='25'>25</option>
                      <option value='50'>50</option>
                      <option value='100'>100</option>
                    </select>
                  </label>
                </div>
                <div className='mt-2 ms-2'>
                  {(currentPage - 1) * pageSize + 1} to{' '}
                  {Math.min(currentPage * pageSize, totalEntries)} of {totalEntries}
                </div>
              </div> */}
            <Text className='me-auto'>
              {getPaginationInfoData(activePage, '10', tableData[0]?.queryData?.total ?? '', {
                data: tableData[0]?.result ?? '',
              })}{' '}
              Of {Math.ceil(Number(tableData[0]?.queryData?.total ?? 0))}
            </Text>
            <Pagination
              value={activePage}
              size='lg'
              styles={(theme) => ({
                control: {
                  '&[data-active]': {
                    backgroundImage: theme.colorScheme,
                  },
                },
              })}
              onChange={(page) => {
                setActivePage(page)
                fetchTableData(page)
              }}
              total={Math.ceil(Number(tableData[0]?.queryData?.total ?? 0) / Number(10))}
            ></Pagination>

            <div className='d-flex justify-content-between align-items-center'>
              <div className='me-2 d-flex justify-content-end'>
                {/* <button
                    className={`ms-2 btn btn-icon btn-${
                      currentPage === 1 ? 'secondary' : 'light-primary'
                    } btn-sm me-1`}
                    onClick={fetchPreviousPage}
                    disabled={currentPage === 1}
                  >
                    <i className='fa-solid fa-angle-left'></i>
                  </button>

                  <button
                    className={`ms-2 btn btn-icon btn-${
                      currentPage * pageSize >= totalEntries ? 'secondary' : 'light-primary'
                    } btn-sm me-1`}
                    onClick={() => {
                      setCurrentPage((prevPage) => prevPage + 1)
                      fetchTableData(currentPage + 1, pageSize)
                    }}
                    disabled={currentPage * pageSize >= totalEntries}
                  >
                    <i className='fa-solid fa-angle-right'></i>
                  </button> */}
              </div>
            </div>
          </div>
        </KTCardBody>
      </KTCard>
    </>
  )
}
export default Attendance
