import {clearInputError} from '../_metronic/helpers'
import {api} from '../api/APIMiddlewares'
import {getActiveUsers} from '../api/AdminAPI'
import {setupAxios} from './modules/auth'
import {APIResponse, CommonApiDataType, InputErrorType, SelectValueType} from './types/OtherTypes'

export const getUserData = (setUser: Function) => {
  getActiveUsers().then((res: APIResponse) => {
    if (res.statusCode === 200) {
      let users: SelectValueType[] = []
      res?.data?.map((obj: any) => {
        users.push({
          label: `${obj?.firstName ?? ''} ${obj?.lastName ?? ''}`,
          value: obj.id,
        })
      })
      setUser(users)
    }
  })
}

export const handleInputChange = (
  event: React.SyntheticEvent,
  setFormField: Function,
  InputError: InputErrorType,
  setInputError: Function
) => {
  let target = event.target as HTMLInputElement
  clearInputError(target.name, InputError, setInputError)
  console.log(target.name, target.value, typeof target.value)
  setFormField((prev: any) => ({
    ...prev,
    [target.name]: target.type === 'checkbox' ? target.checked : target.value,
  }))
}

export const getFilterLengthData = (url: string) => {
  let storageData: any = sessionStorage.getItem('filterLength')
  const pattern = /[!@#$%^&*()_{}\[\]:;<>,.?~\\/\-=|]/g
  let filterDataLength: number | null = null
  let key = url.replaceAll(pattern, '')
  if (storageData) {
    storageData = JSON.parse(storageData)
    filterDataLength = isNaN(Number(storageData[key])) ? 10 : Number(storageData[key])
  } else {
    filterDataLength = 10
  }
  return {filterDataLength}
}
export const getRecords = (url: string, setData: Function) => {
  setData((prev: CommonApiDataType) => ({...prev, loading: true}))
  const {token, tenantId} = setupAxios()
  let otherQuery = url.includes('?')
  console.log(otherQuery)
  const {filterDataLength} = getFilterLengthData(otherQuery ? url.split('?')[0] : url)
  console.log(filterDataLength)
  api
    .get(`/${url}${otherQuery ? `&` : '?'}take=${filterDataLength ?? null}`, token, tenantId, false)
    .then((res: APIResponse) => {
      if (res.statusCode === 200) {
        setTimeout(() => {
          setData((prev: CommonApiDataType) => ({
            ...prev,
            loading: false,
            data: res?.data?.result ?? res?.data,
            queryData: res?.data?.queryParams ?? null,
          }))
        }, 150)
      } else {
        setData((prev: CommonApiDataType) => ({
          ...prev,
          loading: false,
          error: res.message,
          data: res.data?.result,
          queryData: res?.data?.queryParams ?? null,
        }))
      }
    })
}
