import {Center, Loader} from '@mantine/core'
import {useEffect, useState} from 'react'
import {useLocation, useSearchParams} from 'react-router-dom'
import {updateAttendanceById} from '../../../api/AttendanceAPI'
import {useAuth} from '../../modules/auth'

export const ActionPageForAttendance = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const {currentUser} = useAuth()

  const [apiStatus, setApiStatus] = useState({
    loading: false,
    error: null,
    success: null,
  })

  useEffect(() => {
    let mounted = true
    console.log('redirected')
    let attendance = searchParams.get('attendance') ?? ''
    if (mounted) {
      console.log('test')
      setApiStatus((prev: any) => ({...prev, loading: true}))
      const actionBy = currentUser?.id ?? `${currentUser?.id}`
      updateAttendanceById(
        searchParams.get('attendanceId') ?? '',
        location.pathname === '/accept/attendance' ? 'accept' : 'reject',
        searchParams.get('t') ?? '',
        {id: actionBy}
      ).then((res) => {
        if (res.statusCode === 200) {
          setApiStatus((prev: any) => ({
            ...prev,
            loading: false,
            success: res.message,
            error: null,
          }))
        } else {
          setApiStatus((prev: any) => ({
            ...prev,
            loading: false,
            error: res.message,
            success: null,
          }))
        }
      })
    }
    return () => {
      mounted = false
    }
  }, [])

  return (
    <div className='container my-6 '>
      <Center>
        {apiStatus.loading ? (
          <div className='my-2'>
            <Loader color='cyan' />
          </div>
        ) : apiStatus.error ? (
          <div className='col-3 alert alert-danger bg-danger text-white  mb-10 mx-5'>
            {apiStatus.error}
          </div>
        ) : (
          apiStatus.success && (
            <div className='col-3 alert alert-success bg-success text-white mb-10 mx-5'>
              {apiStatus.success}
            </div>
          )
        )}
      </Center>
    </div>
  )
}
