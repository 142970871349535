import {useEffect, useState} from 'react'
import Chart from 'react-apexcharts'
import {clearFilteredField, getAllProjectTaskStatusCount} from './PieChartFunction'
import {useParams} from 'react-router-dom'
import {setupAxios, useAuth} from '../../../modules/auth'
import {Col, Form} from 'react-bootstrap'
import Select2 from '../../../components/select2'
import axios from 'axios'
import {API_BASE_URL} from '../../../../utils/contants'
import {Button} from '@mantine/core'
function PieCharts() {
  const params = useParams()

  const [allProjectTaskStatusCount, setAllProjectTaskStatusCount] = useState({
    CompletedTasks: '',
    InProgress: '',
    ToDoTasks: '',
    OnHold: '',
  })

  const isNoData = Object.values(allProjectTaskStatusCount).every((value) => Number(value) === 0)

  const [APIStatus, setAPIStatus] = useState({
    loading: false,
    erorr: null,
    submitLoading: false,
  })

  const [projectUser, setProjectUser] = useState<{label: string; value: string}[]>([])
  const sortedProjectUser = projectUser.slice().sort((a, b) => a.label.localeCompare(b.label))

  const [filterOptions, setFilterOptions] = useState({
    userId: '',
  })

  const handleFilterChange = (filterName, value) => {
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      [filterName]: value,
    }))
  }

  useEffect(() => {
    let mounted = true
    if (mounted) {
      getAllProjectTaskStatusCount(
        filterOptions?.userId,
        params?.id,
        setAllProjectTaskStatusCount,
        setAPIStatus
      )
      fetchprojectUserNames(params?.id)
    }
    return () => {
      mounted = false
    }
  }, [filterOptions])

  const fetchprojectUserNames = (id) => {
    const {token, tenantId} = setupAxios()
    axios
      .get(API_BASE_URL + `/project/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-Tenant-Id': `${tenantId}`,
        },
      })
      .then((response: any) => {
        let userData: {label: string; value: string}[] = []
        response.data.data.forEach((project) => {
          project.projectUsers.forEach((user) => {
            userData.push({
              label: `${user.user.firstName} ${user.user.lastName}`,
              value: user.user.id,
            })
          })
        })
        setProjectUser(userData)
      })
      .catch((error) => {
        console.error('Error fetching names:', error)
      })
  }

  return (
    <>
      <div className=''>
        <div className=''>
          <div className='card me-2 me-sm-3 me-md-5 me-lg-5 mb-3 mb-sm-4 mb-md-5 mb-lg-5'>
            <div className='card-header'>
              <div className='card-title d-flex flex-column fw-bold'>
                Task Summary
                <span className='fs-6 fw-semibold text-gray-400'>Total Tasks</span>
              </div>
              <div className='card-toolbar'>
                <Col className='mt-5'>
                  {/* <Form.Label className='text-gray-500'>Filter By User Names:</Form.Label> */}
                  <Select2
                    name='id'
                    className='mb-4'
                    placeholder='Select Users'
                    data={sortedProjectUser}
                    value={filterOptions?.userId ?? '-'}
                    onChange={(selectedOption: string) => {
                      handleFilterChange('userId', selectedOption)
                    }}
                  />
                </Col>
                <Button
                  variant='filled'
                  color='gray'
                  className='mt-1 ms-3'
                  onClick={() => {
                    clearFilteredField(setFilterOptions, filterOptions)
                  }}
                >
                  Clear
                </Button>
              </div>
            </div>
            <div className='d-flex justify-content-center'>
              <div className=''>
                {isNoData ? (
                  <>
                    <div className='fw-bold text-gray-600 text-center mt-7 mb-5'>
                      No Data Available
                    </div>
                    <div className='text-center'>
                      <img
                        src='/media/avatars/19-dark.png'
                        alt='noReacordAvailable'
                        className='w-70 h-80px h-sm-325px'
                      />
                    </div>
                  </>
                ) : (
                  <Chart
                    type='pie'
                    redrawOnParentResize='true'
                    series={[
                      Number(allProjectTaskStatusCount.ToDoTasks) || 0,
                      Number(allProjectTaskStatusCount.InProgress) || 0,
                      Number(allProjectTaskStatusCount.OnHold) || 0,
                      Number(allProjectTaskStatusCount.CompletedTasks) || 0,
                    ]}
                    options={{
                      labels: ['Todo', 'In Progress', 'On Hold', 'Completed'],
                      colors: ['#4DA6FF', '#FFD633', '#A8A8A8', '#00CC66'],
                      noData: {text: 'empty data'},

                      legend: {
                        show: true,
                        // Position legend to the right
                        //  horizontalAlign: 'left', // Align legend text to the left
                        fontSize: '12px',
                        horizontalAlign: 'center',
                        position: 'bottom',
                        fontFamily: 'Arial, sans-serif',
                        markers: {
                          width: 12,
                          height: 12,
                        },
                        labels: {
                          colors: 'primary',
                        },
                      },

                      plotOptions: {
                        pie: {
                          dataLabels: {
                            offset: -5,
                          },
                        },
                      },
                      responsive: [
                        {
                          breakpoint: 1000,
                          options: {
                            plotOptions: {
                              pie: {
                                horizontal: false,
                                dataLabels: {
                                  offset: -5,
                                },
                              },
                            },
                            legend: {
                              position: 'bottom',
                            },
                            Chart: {
                              position: 'left',
                            },
                          },
                        },
                      ],
                    }}
                  ></Chart>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PieCharts
