import React, {useState} from 'react'
import {handleInputChange, handleSubmit, validateForm} from './SignUpFunction'
import InputErrorBox from '../../components/InputErrorBox'
import {Button} from '@mantine/core'
import { Link } from 'react-router-dom'

const SignUp = () => {
  const initialState = {
    firstName: '',
    lastName: '',
    email: '',
    companyName: '',
    address: '',
    country: '',
    state: '',
    city: '',
    phoneNo: '',
    pincode: '',
    teamSize: '',
  }
  const [inputField, setInputField] = useState(initialState)
  const [inputError, setInputError] = useState<any>({})
  const [APIStatus, setAPIStatus] = useState({
    loading: false,
    error: null,
    submitLoading: false,
  })
  const [SubmitAPIStatus, setSubmitAPIStatus] = useState({
    loading: false,
  })
  return (
    <>
      <style>{
        `.form-control{
          background-color: #ffffff;
      }
      .form-select{
          background-color: #ffffff;
      }
      .form-control:focus {
  outline: none; 
  box-shadow: none;
  background-color: #ffffff;
      }
      `
      }</style>
  
        <div className='row '>
          <div className='col-md-7 d-flex flex-column align-items-center justify-content-center' style={{ backgroundColor: '#99B9FF' }} >
            <div className="col-md-7 d-flex justify-content-center align-items-center">
              <img src={'/media/logos/sialogo.png'} alt='Logo' style={{ width: '160px', height: '110px'}} />
            </div>
            <div className='col-md-8 px-auto mx-auto' >
              <form className='signup-form pt-1'>
                <div className='row mb-2'>
                  <div className='col-md-6 '>
                    <label htmlFor='firstName' className='form-label fw-medium' style={{ color: 'black' }}>
    First Name <span style={{ color: 'red' }}>*</span>
  </label>
                    <input
    autoComplete='off'
    type='string'
    className='form-control'
    id='firstName'
    name='firstName'
    style={{ height: '37px', color: 'black' }} // Set color property for input
    value={inputField.firstName}
    onChange={(event) => handleInputChange(event, setInputField, inputField)}
  />
                    {<InputErrorBox Message={inputError.firstName} />}
                  </div>
                  <div className='col-md-6 '>
                    <label htmlFor='lastName' className='form-label fw-medium'  style={{ color: 'black' }}>
                      Last Name <span style={{ color: "red"}}>*</span>
                    </label>
                    <input
                    autoComplete='off'
                      type='text'
                      className='form-control'
                      id='lastName'
                    name='lastName'
style={{ height: '37px', color: 'black' }} // Set color property for input
                      value={inputField.lastName}
                      onChange={(event) => handleInputChange(event, setInputField, inputField)}
                    />
                    {<InputErrorBox Message={inputError.lastName} />}
                  </div>
                </div>
                <div className='row mb-2'>
                  <div className='col-md-6 '>
                    <label htmlFor='email' className='form-label fw-medium'  style={{ color: 'black' }}>
                      Email <span style={{ color: "red"}}>*</span>
                    </label>
                    <input
                    autoComplete='off'
                      type='email'
                      className='form-control'
                      id='email'
                      name='email'
style={{ height: '37px', color: 'black' }} // Set color property for input                      value={inputField.email}
                      onChange={(event) => handleInputChange(event, setInputField, inputField)}
                    />
                    {<InputErrorBox Message={inputError.email} />}
                  </div>
                  <div className='col-md-6 '>
                    <label htmlFor='companyName' className='form-label fw-medium'  style={{ color: 'black' }}>
                      Company Name <span style={{ color: "red"}}>*</span>
                    </label>
                    <input
                    autoComplete='off'
                      type='text'
                      className='form-control'
                      id='companyName'
                    name='companyName'
style={{ height: '37px', color: 'black' }} // Set color property for input
                      value={inputField.companyName}
                      onChange={(event) => handleInputChange(event, setInputField, inputField)}
                    />
                    {<InputErrorBox Message={inputError.companyName} />}
                  </div>
                </div>
                <div className='row mb-2'>
                  <div className='col-md-6 '>
                    <label htmlFor='address' className='form-label fw-medium'  style={{ color: 'black' }}>
                      Address <span style={{ color: "red"}}>*</span>
                    </label>
                    <input
                      type='text'
                      autoComplete='off'
                      className='form-control'
                      id='address'
                    name='address'
style={{ height: '37px', color: 'black' }} // Set color property for input
                      value={inputField.address}
                      onChange={(event) => handleInputChange(event, setInputField, inputField)}
                    />
                    {<InputErrorBox Message={inputError.address} />}
                  </div>
                  <div className='col-md-6 '>
                    <label htmlFor='country' className='form-label fw-medium'  style={{ color: 'black' }}>
                      Country <span style={{ color: "red"}}>*</span>
                    </label>
                    <input
                      type='text'
                      autoComplete='off'
                      className='form-control'
                      id='country'
                      name='country'
style={{ height: '37px', color: 'black' }} // Set color property for input
                      value={inputField.country}
                      onChange={(event) => handleInputChange(event, setInputField, inputField)}
                    />
                    {<InputErrorBox Message={inputError.country} />}
                  </div>
                </div>
                <div className='row mb-2'>
                  <div className='col-md-6 '>
                    <label htmlFor='state' className='form-label fw-medium'  style={{ color: 'black' }}>
                      State <span style={{ color: "red"}}>*</span>
                    </label>
                    <input
                      type='text'
                      autoComplete='off'
                      className='form-control'
                      id='state'
style={{ height: '37px', color: 'black' }} // Set color property for input
                      name='state'
                      value={inputField.state}
                      onChange={(event) => handleInputChange(event, setInputField, inputField)}
                    />
                    {<InputErrorBox Message={inputError.state} />}
                  </div>
                  <div className='col-md-6 '>
                    <label htmlFor='state' className='form-label fw-medium'  style={{ color: 'black' }}>
                      City <span style={{ color: "red"}}>*</span>
                    </label>
                    <input
                      type='text'
                      autoComplete='off'
                      className='form-control'
                      id='city'
                     name='city'
style={{ height: '37px', color: 'black' }} // Set color property for input
                      value={inputField.city}
                      onChange={(event) => handleInputChange(event, setInputField, inputField)}
                    />
                    {<InputErrorBox Message={inputError.city} />}
                  </div>
                </div>
                <div className='row mb-2'>
                  <div className='col-md-6 '>
                    <label htmlFor='mobileNo' className='form-label fw-medium'  style={{ color: 'black' }}>
                      Phone No <span style={{ color: "red"}}>*</span>
                    </label>
                    <input
                    autoComplete='off'
                      type='number'
                      className='form-control'
                      id='mobileNo'
                      //   pattern='[0-9]{10}'
                      name='phoneNo'
style={{ height: '37px', color: 'black' }} // Set color property for input
                      value={inputField.phoneNo}
                      onChange={(event) => handleInputChange(event, setInputField, inputField)}
                    />
                    {<InputErrorBox Message={inputError.phoneNo} />}
                  </div>
                  <div className='col-md-6 '>
                    <label htmlFor='pincode' className='form-label fw-medium'  style={{ color: 'black' }}>
                      Pin Code <span style={{ color: "red"}}>*</span>
                    </label>
                    <input
                    autoComplete='off'
                      type='number'
                      className='form-control'
                      id='pincode'
                      //   pattern='\d{5,6}'
                    name='pincode'
style={{ height: '37px', color: 'black' }} // Set color property for input
                      value={inputField.pincode}
                      onChange={(event) => handleInputChange(event, setInputField, inputField)}
                    />
                    {<InputErrorBox Message={inputError.pincode} />}
                  </div>
                </div>
                <div className='row mb-2'>
                  <div className='col-md-6 '>
                    <label htmlFor='teamSize' className='form-label fw-medium'  style={{ color: 'black' }}>
                      Team Size <span style={{ color: "red"}}>*</span>
                    </label>
                    <select
                    autoComplete='off'
                      className='form-select'
                      id='teamSize'
                      name='teamSize'
                      style={{ height: '37px', fontSize: '12px', color: 'black' }}

                      value={inputField.teamSize}
                      onChange={(event) => handleInputChange(event, setInputField, inputField)}
                    >
                      <option value='' hidden>
                        Select Team Size 
                      </option>
                      <option value='1-10'>1-9</option>
                      <option value='11-50'>10-50</option>
                      <option value='1-10'>Enterprise</option>
                    </select>
                    {<InputErrorBox Message={inputError.teamSize} />}
                  </div>
                </div>

                <div className='text-center mt-6'>
                <Button
                  style={{fontSize:'18px'}}
                    loaderPosition='center'
                    loading={SubmitAPIStatus.loading}
                    onClick={() => {
                      handleSubmit(
                        inputField,
                        initialState,
                        setInputField,
                        setInputError,
                        validateForm,
                        setAPIStatus,
                        setSubmitAPIStatus
                      )
                    }}
                  >
                    Signup
                  </Button>
                </div>
                <div className='text-center mt-2 '>
                  <p className=''>
                  Already have an account?
                  <Link to={'/login'} className='text-dark'>
                  <a href=''> Login</a>
                </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
         <div className='col-md-5 d-flex justify-content-center align-items-center' style={{backgroundColor: '#ffffff'}}>
  <img
    src={'/media/logos/hero-img.png'}
    className='img-fluid'
    alt='Signup Image'
    style={{ maxWidth: '100%', height: 'auto' }}
  />
</div>
        </div>
      
      <div
        className='container-fluid d-flex text-center align-items-center flex-column'
        style={{backgroundColor: '#dddddd'}}
      >
        <a href='/homepage'>
          <h4
            className='p-1'
            style={{font: 'normal normal normal 16px/22px Noto Sans', color: '#6A6AE2'}}
          >OfficeSIA 2024
          </h4>
        </a>
      </div>
    </>
  )
}

export default SignUp
