import {Button, Divider, Group} from '@mantine/core'
import {Col, Row} from 'react-bootstrap'
import GroupPieCharts from '../dashboard_charts/GroupPieChart'
import TreeMapChart from '../dashboard_charts/TreeMapChart'
import UsersPieCharts from '../dashboard_charts/UsersPieCharts'

const AdminDashboard = () => {
  return (
    <>
      <div>
        <Row>
          <Col md={6}>
            <TreeMapChart />
          </Col>

          <Col md={3}>
            <div className='card mb-5'>
              <div className='card-header' style={{minHeight: '3rem'}}>
                <div className='card-title'>
                  <span className='fw-bold'>Licenses</span>
                </div>
              </div>
              <div className='card-body'>
                <Row>
                  <Group className='text-center'>
                    <Col>
                      <div className='text-center mb-5'>
                        <div className='fw-bold'>Total</div>
                        <div className='mt-5 fs-2'>20</div>
                      </div>
                    </Col>
                    <Divider orientation='vertical' />
                    <Col>
                      <div className='text-center mb-5'>
                        <div className='fw-bold'>Available</div>
                        <div className='mt-5 fs-2'>5</div>
                      </div>
                    </Col>
                  </Group>
                </Row>
                <Row>
                  <Col>
                    <div>
                      <div className='text-center mt-5'>
                        <Button>Add License</Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>

          <Col md={3}>
            <div className='card '>
              <div className='card-header' style={{minHeight: '3rem'}}>
                <div className='card-title'>
                  <span className='fw-bold'>Billing</span>
                </div>
              </div>
              <div className='card-body' style={{height: '13.8rem'}}></div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <UsersPieCharts />
          </Col>{' '}
          <Col md={4}>
            <GroupPieCharts />
          </Col>
          <Col md={4}>
            <div className='card mb-5'>
              <div className='card-header' style={{minHeight: '3rem'}}>
                <div className='card-title'>
                  <span className='fw-bold'>Templates</span>
                </div>
              </div>
              <div className='card-body' style={{height: '18rem'}}></div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className='card mb-5'>
              <div className='card-header' style={{minHeight: '3rem'}}>
                <div className='card-title'>
                  <span className='fw-bold'>Logs & Alerts</span>
                </div>
              </div>
              <div className='card-body h-200px'></div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default AdminDashboard
