import {Button, Image} from '@mantine/core'
import React, {useEffect, useState} from 'react'
import {Col, Modal} from 'react-bootstrap'
import {useAuth} from '../../../modules/auth'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {Magnifier} from 'react-image-magnifiers'
import {
  cancelSend,
  deleteComment,
  getComments,
  getCommentsById,
  handleOtherInputChange,
  handleSend,
  updateComments,
  validateComment,
} from './CommentFunction'
import {useParams} from 'react-router-dom'
import {formatDateCell} from '../../../../_metronic/helpers'
import {
  faAngleUp,
  faChevronDown,
  faImage,
  faPencil,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import InputErrorBox from '../../../components/InputErrorBox'
import {CKEditor} from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

const Comments = () => {
  const params = useParams()
  const {currentUser} = useAuth()
  const [editId, setEditId] = useState(null)
  const [inputError, setInputError] = useState<any>({})

  const initialState = {
    id: '',
    comment: '',
    commenter: {id: currentUser?.id ? currentUser?.id.toString() : ''},
    file: null as File | null,
  }

  const [inputField, setInputField] = useState(initialState)

  const [commentById, setCommentById] = useState({
    id: '',
    comment: '',
    commenter: {
      id: '',
      firstName: '',
      LastName: '',
    },
  })

  const [comment, setComment] = useState([
    {
      id: '',
      comment: '',
      createdAt: '',
      UpdatedAt: '',
      commenter: {
        id: '',
        firstName: '',
        lastName: '',
      },
      files: [
        {
          id: '',
          file: '',
          fileName: '',
          createdAt: '',
          fileExtension: '',
        },
      ],
      task: {
        id: '',
        title: '',
      },
    },
  ])

  const [showModal, setShowModal] = useState(false)
  const [visibleComments, setVisibleComments] = useState(3)
  const [commentsPerPage, setCommentsPerPage] = useState(3)

  const editComment = (commentId, commentContent) => {
    setEditId(commentId) // Set the ID of the comment being edited
    setInputField((prevInputField) => ({
      ...prevInputField,
      comment: commentContent, // Set the comment content to edit
    }))
    setShowModal(true) // Open the modal
    console.log('InputFields After Modal Open', inputField)
    console.log('after modal open comment', comment)
    console.log('comment content', commentContent)
  }

  const closeEditComment = () => {
    setShowModal(false)
  }

  const [APIStatus, setAPIStatus] = useState({
    loading: false,
    error: null,
    submitLoading: false,
  })

  const [SubmitAPIStatus, setSubmitAPIStatus] = useState({
    loading: false,
  })

  const [selectedFile, setSelectedFile] = useState<String | null>('/media/logos/uploadFile.jpg')

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    const imageUrl = file ? URL.createObjectURL(file) : ''
    setSelectedFile(imageUrl)
    setInputField((prevInputField) => ({
      ...prevInputField,
      file: file !== undefined ? file : null,
    }))
  }
  const [imageSelected, setImageSelected] = useState(false)

  const [showSaveButton, setShowSaveButton] = useState(false)

  const iconBeforeSelect = faImage

  const [largedImage, setlargedImage] = useState<string | null>(null)

  useEffect(() => {
    let mounted = true
    if (mounted) {
      getComments(params?.id, setComment, setAPIStatus)
    }
    return () => {
      mounted = false
    }
  }, [])

  return (
    <>
      {/* {console.log('saveButton', showSaveButton)} */}
      <div>
        <div className='mb-5 ms-5 me-5'>
          <div className=''>
            <div className='fw-bold fs-3 mb-4'>Comments</div>
            <div>
              <ReactQuill
                className='form-control'
                style={{fontSize: '13px', border: 'bottem-border', minHeight: '100px'}}
                theme='snow'
                placeholder='Your Comment'
                value={inputField?.comment}
                onChange={(value) => handleOtherInputChange(value, 'comment', setInputField)}
                // onFocus={() => setShowSaveButton(true)}
              />
              {<InputErrorBox Message={inputError.comment} />}
              {inputField?.comment?.length > 0 && (
                <div className='d-flex justify-content-end'>
                  <Button
                    className='mt-5'
                    // style={{display: inputField.comment.trim().length > 0 ? 'block' : 'none'}}
                    loading={APIStatus?.loading}
                    onClick={() => {
                      handleSend(
                        params?.id,
                        inputField,
                        setInputField,
                        initialState,
                        setEditId,
                        setComment,
                        setAPIStatus,
                        setInputError,
                        setSubmitAPIStatus,
                        validateComment,
                        cancelSend,
                        setSelectedFile,
                        setImageSelected
                        // setShowSaveButton
                      )
                    }}
                    // onBlur={() => setShowSaveButton(false)} // Hide the Save button when the input field loses focus
                  >
                    Save
                  </Button>
                </div>
              )}
              <div className='col-lg-8 mt-5'>
                <div className='fw-semibold mb-3 fs-6'>Attachments</div>

                <div
                  className='image-input image-input-outline mb-2'
                  data-kt-image-input='true'
                  style={{
                    backgroundImage: `url(${selectedFile})`,
                  }}
                >
                  {imageSelected && (
                    <div
                      className='image-input-wrapper w-125px h-125px bgi-position-center'
                      style={{
                        backgroundSize: '75%',
                        backgroundImage: `url(${selectedFile})`,
                      }}
                    ></div>
                  )}

                  {imageSelected ? (
                    <>
                      <label
                        className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px shadow'
                        data-kt-image-input-action='change'
                        data-bs-toggle='tooltip'
                        title='Add Image'
                      >
                        <FontAwesomeIcon icon={faPencil} />
                        <input
                          key={Date.now()}
                          type='file'
                          name='avatar'
                          accept='.png, .jpg, .jpeg'
                          onChange={(event) => {
                            handleFileChange(event)
                            setImageSelected(true)
                          }}
                        />
                      </label>
                      <span
                        className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px mb-5 shadow'
                        data-kt-image-input-action='remove'
                        data-bs-toggle='tooltip'
                        title='Remove Image'
                      >
                        <FontAwesomeIcon
                          icon={faXmark}
                          onClick={() => setSelectedFile('/media/logos/uploadFile.jpg')}
                        />
                      </span>
                    </>
                  ) : (
                    <label
                      className='btn btn-icon btn-sqaure btn-sm btn-active-color-primary w-100px h-25px ms-15 shadow mb-5'
                      data-kt-image-input-action='change'
                      data-bs-toggle='tooltip'
                      title='Add Image'
                    >
                      <FontAwesomeIcon icon={iconBeforeSelect} />
                      <input
                        key={Date.now()}
                        type='file'
                        name='avatar'
                        accept='.png, .jpg, .jpeg'
                        onChange={(event) => {
                          handleFileChange(event)
                          setImageSelected(true)
                        }}
                      />

                      <span className='ms-2'>Upload File</span>
                    </label>
                  )}
                </div>

                <div className='form-text mt-2'>Allowed file types: png, jpg, jpeg.</div>
              </div>{' '}
            </div>

            <br />
            <hr />
            {comment.length > 0 ? (
              <div style={{maxHeight: '350px', overflowY: 'scroll'}}>
                {comment.slice(0, visibleComments)?.map((obj) => {
                  return (
                    <div className={`${editId === obj?.id ?? '-'}mt-7`} key={obj?.id}>
                      <div className='symbol symbol-35px symbol-sqaure d-flex'>
                        <span className='symbol-label bg-light-success text-success fw-semibold'>
                          {obj?.commenter?.firstName[0] ?? '-'}
                        </span>
                        <span className='fs-5 fw-bold text-gray-900 text-hover-primary mb-2 ms-4 text-center mt-2'>
                          {obj?.commenter?.firstName ?? '-'} {obj?.commenter?.lastName ?? '-'}{' '}
                        </span>
                        <span className='mt-3 ms-3'> {formatDateCell(obj?.createdAt ?? '-')}</span>
                      </div>
                      <div className=''>
                        <p
                          className='ms-15 me-5 mb-3 mt-2'
                          dangerouslySetInnerHTML={{__html: obj?.comment ?? '-'}}
                        />

                        {obj.files.map((file) => {
                          return (
                            <>
                              <div key={file.id} onClick={() => setlargedImage(file.id)}>
                                {' '}
                                <Image
                                  maw={240}
                                  className='ms-15'
                                  mx='auto'
                                  radius='md'
                                  src={`http://192.168.1.251:8002/api/v1/file/${file?.id}`}
                                  alt='Task Image'
                                  height={150}
                                  width={150}
                                />
                              </div>
                            </>
                          )
                        })}

                        {comment.length > 0 && (
                          <div className='d-flex'>
                            <Button
                              className='btn btn-icon btn-active-color-info btn-sm me-2 mb-3 text-gray-500 ms-15'
                              loading={APIStatus?.loading}
                              onClick={() => {
                                document.getElementById('comment')?.focus()
                                getCommentsById(
                                  obj?.id,
                                  commentById,
                                  setCommentById,
                                  setEditId,
                                  setInputError,
                                  editComment
                                )
                              }}
                            >
                              {/* <FontAwesomeIcon icon={faEdit} /> */}
                              Edit <span className='ms-2'>•</span>
                            </Button>
                            <Button
                              className='btn btn-icon btn-active-color-danger btn-sm text-gray-500 me-2 mb-3'
                              loading={APIStatus?.loading}
                              onClick={() => {
                                deleteComment(
                                  obj?.id,
                                  params?.id,
                                  setComment,
                                  setAPIStatus,
                                  cancelSend,
                                  setInputField,
                                  initialState,
                                  setEditId,
                                  setInputError
                                )
                              }}
                            >
                              {' '}
                              Delete
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  )
                })}
              </div>
            ) : (
              <div style={{textAlign: 'center'}}>
                <i style={{fontSize: '3em'}} className='fa-regular fa-comment text-gray-500'></i>{' '}
                <div className='fs-5 fw-bold text-gray-600 mt-2'> No Comments Yet</div>
              </div>
            )}

            {comment.length > visibleComments ? (
              <div className='d-flex justify-content-center mt-3'>
                <Button
                  variant='subtle'
                  radius='xl'
                  color=''
                  onClick={() => setVisibleComments(visibleComments + commentsPerPage)}
                  loading={APIStatus.loading}
                >
                  <FontAwesomeIcon icon={faAngleUp} className='me-2 mt-1' /> Load More
                </Button>
              </div>
            ) : (
              <div>
                <div className='d-flex justify-content-center mt-3'>
                  <Button
                    variant='subtle'
                    radius='xl'
                    color=''
                    onClick={() => setVisibleComments(visibleComments - commentsPerPage)}
                    loading={SubmitAPIStatus.loading}
                  >
                    <FontAwesomeIcon icon={faChevronDown} className='me-2 mt-1' />
                    Show Less{' '}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* 
      {console.log('comment value', inputField?.comment)}
      {console.log('InputField on Modal Open:', inputField)} */}

      <Modal
        show={showModal}
        onHide={closeEditComment}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header>
          {' '}
          <Modal.Title id='contained-modal-title-vcenter'>Edit Comment</Modal.Title>
          <div className='btn btn-icon btn-sm btn-active-icon-danger'>
            <i
              className='fa-solid fa-xmark fs-1'
              onClick={() => {
                closeEditComment()
                setInputField(initialState)
                setInputError('')
              }}
            />
          </div>
        </Modal.Header>
        <Modal.Body>
          {/* <ReactQuill
            className='form-control'
            style={{fontSize: '13px', border: 'bottem-border', minHeight: '175px'}}
            theme='snow'
            value={commentById?.comment || ''}
            onChange={(value) => handleOtherInputChange(value, 'comment', setInputField)}
          /> */}

          <CKEditor
            editor={ClassicEditor}
            data={commentById?.comment}
            config={{
              toolbar: {
                items: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList'],
              },
            }}
            onChange={(event, editor) => {
              const data = editor.getData()
              handleOtherInputChange(data, 'comment', setInputField)
            }}
          />

          {<InputErrorBox Message={inputError.comment} />}
          <div className='d-flex flex-end mt-5'>
            <Button
              loading={APIStatus?.loading}
              onClick={() => {
                updateComments(
                  editId,
                  inputField,
                  params?.id,
                  setComment,
                  setInputField,
                  setAPIStatus,
                  cancelSend,
                  setInputError,
                  closeEditComment
                )
              }}
            >
              Update
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {/* {console.log('InputField on Modal Closed', inputField)} */}

      <Modal show={largedImage !== null} onHide={() => setlargedImage(null)} size='lg'>
        <Modal.Header>
          <Modal.Title>Zoom Image</Modal.Title>
          <div className='btn btn-icon btn-sm btn-active-icon-danger'>
            <i
              className='fa-solid fa-xmark fs-1'
              onClick={() => {
                setlargedImage(null)
              }}
            />
          </div>
        </Modal.Header>
        <Modal.Body style={{backgroundColor: 'transparent'}}>
          <Magnifier
            imageSrc={`http://192.168.1.251:8002/api/v1/file/${largedImage}`}
            imageAlt='Enlarged Image'
            magnifierSize='30%'
            magnifierBorderSize={3}
            magnifierBorderColor='rgba(255, 255, 255, 0.8)'
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Comments
