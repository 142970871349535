/* eslint-disable react/jsx-no-target-blank */

import {useContext} from 'react'
import roleContext from '../../../../app/context/roleContext'
import {useAuth} from '../../../../app/modules/auth'
import {RoleContextValue} from '../../../../app/types/OtherTypes'
import {checkRole} from '../../../helpers'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  let roleState: RoleContextValue = useContext(roleContext)
  const {currentUser} = useAuth()
  return (
    <>
      <AsideMenuItem
        title='Dashboard'
        to='/dashboard'
        fontIcon='bi bi-speedometer2 fs-2'
        bsTitle='Dashboard'
        className='py-2'
      />
      {checkRole(['Attendance Management System'], roleState.features) && (
        <AsideMenuItem
          to='/ams'
          title='AMS'
          fontIcon='bi-house fs-2'
          bsTitle='AMS'
          className='py-2'
        />
      )}

      {/* <AsideMenuItem
        to='/emails'
        title='Email'
        fontIcon='bi-envelope'
        bsTitle='Inbox'
        className='py-2'
      /> */}
      {checkRole(['Leave Management System', 'Leave Accounting'], roleState.features) && (
        <AsideMenuItem
          to='/leave'
          title='LMS'
          fontIcon='bi-house-check'
          bsTitle='LMS'
          className='py-2'
        />
      )}
      {checkRole(['Users Registration', 'Roles'], roleState.features) && (
        <AsideMenuItem
          to='/users'
          title='Users'
          fontIcon='bi-person fs-2'
          bsTitle='Users'
          className='py-2'
        />
      )}
      {checkRole(['Holidays'], roleState.features) && (
        <AsideMenuItem
          to='/holidays'
          title='Holidays'
          fontIcon='bi bi-umbrella'
          bsTitle='Policy'
          className='py-2'
        />
      )}
      {checkRole(['Asset Management System'], roleState.features) && (
        <AsideMenuItem
          to='/assets'
          title='Assets'
          fontIcon='bi bi-motherboard'
          bsTitle='Assets'
          className='py-2'
        />
      )}
      {checkRole(['Reports'], roleState.features) && (
        <AsideMenuItem
          to='/reports'
          title='Reports'
          fontIcon='bi bi-files'
          bsTitle='Reports'
          className='py-2'
        />
      )}
      {checkRole(['Project Management'], roleState.features) && (
        <AsideMenuItem
          to='/projects'
          title='Project'
          fontIcon='bi bi-folder-fill'
          bsTitle='Policy'
          className='py-2'
        />
      )}
      {checkRole(['Policy'], roleState.features) && (
        <AsideMenuItem
          to='/policies'
          title='Policy'
          fontIcon='bi bi-file-earmark-text'
          bsTitle='Policy'
          className='py-2'
        />
      )}
    </>
  )
}
