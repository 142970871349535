import Chart from 'react-apexcharts'
function PieChart() {
  return (
    <>
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-6'>
          <div
            className='card card-xl-stretch mb-xl-6'
            style={{maxWidth: '100%', margin: '35px auto'}}
          >
            <div className='card-header'>
              <div className='card-title'>Monthly Attendence Status</div>
            </div>
            <div className='card-body'>
              <div className='table-responsive'>
                <Chart
                  type='pie'
                  width={500}
                  height={400}
                  redrawOnParentResize='true'
                  series={[30, 22, 5, 2, 1, 9, 6.25, 0]}
                  options={{
                    labels: [
                      'Total Count',
                      'Present days',
                      'Full day Leave',
                      'Half day Leave',
                      'Short leave',
                      'Work From home',
                      'Paid Leave',
                      'Holiday',
                    ],

                    noData: {text: 'empty data'},
                    theme: {
                      monochrome: {
                        enabled: true,
                      },
                    },
                    legend: {
                      show: true,
                      // Position legend to the right
                      //  horizontalAlign: 'left', // Align legend text to the left
                      fontSize: '14px',
                      horizontalAlign: 'center',
                      position: 'right',
                      fontFamily: 'Arial, sans-serif',
                      // Set font size for legend text
                      markers: {
                        width: 12,
                        height: 12,
                      },
                      labels: {
                        colors: 'primary',
                        // useSeriesColors: true,
                      },
                    },
                    plotOptions: {
                      pie: {
                        dataLabels: {
                          offset: -5,
                        },
                      },
                    },
                    responsive: [
                      {
                        breakpoint: 1000,
                        options: {
                          plotOptions: {
                            bar: {
                              horizontal: false,
                            },
                          },
                          legend: {
                            position: 'bottom',
                          },
                          Chart: {
                            position: 'left',
                          },
                        },
                      },
                    ],
                  }}
                ></Chart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PieChart
