import {setupAxios} from '../app/modules/auth'
import {API_BASE_URL} from '../utils/contants'
//create Email
export async function addEmail(inputField: {
  isLeave: boolean
  sender: string
  recipients: {
    to: string[]
    cc: string[]
  }
  title: string
  reason: string
  LeaveEmail: {
    startDate: string
    endDate: string
    type: string
  }[]
}) {
  const {token, id} = setupAxios()

  const emailRecivers = [
    ...inputField.recipients.to.map((receiver) => ({type: 'to', receiver: {id: receiver}})),
    ...inputField.recipients.cc.map((receiver) => ({type: 'cc', receiver: {id: receiver}})),
  ]

  const responses = await Promise.all(
    inputField.LeaveEmail.map(async (leaveEmail) => {
      const leaveTypeTimes = {
        Full: {start: 'T10:00', end: 'T19:00'},
        'Work From Home': {start: 'T10:00', end: 'T19:00'},
        'First Half': {start: 'T10:00', end: 'T14:00'},
        'Second Half': {start: 'T15:00', end: 'T19:00'},
      }

      const {start, end} = leaveTypeTimes[leaveEmail.type] || {start: '', end: ''}

      const emailData = {
        emailRecivers,
        sender: id,
        isLeave: inputField.isLeave,
        subject: inputField.title,
        LeaveEmail: [
          {
            type: leaveEmail.type,
            startDate: leaveEmail.startDate + start,
            endDate: leaveEmail.endDate + end,
          },
        ],
        body: inputField.reason,
      }

      const res = await fetch(API_BASE_URL + `/emails`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(emailData),
      })

      return await res.json()
    })
  )

  return responses
}
export async function addSimpledraftEmail(inputField: {
  isLeave: boolean
  isPublish: boolean
  sender: string
  recipients: {
    to: string[]
    cc: string[]
  }
  title: string
  reason: string
  LeaveEmail: {
    startDate: string
    endDate: string
    type: string
  }[]
}) {
  const {token, id} = setupAxios()

  const emailRecivers = [
    ...inputField.recipients.to.map((receiver) => ({type: 'to', receiver: {id: receiver}})),
    ...inputField.recipients.cc.map((receiver) => ({type: 'cc', receiver: {id: receiver}})),
  ]

  try {
    const responses = await Promise.all(
      inputField.LeaveEmail.map(async (leaveEmail) => {
        const emailData = {
          emailRecivers,
          sender: id,
          isLeave: inputField.isLeave,
          isPublish: inputField.isPublish,
          subject: inputField.title,
          LeaveEmail: [
            {
              type: leaveEmail.type,
              startDate: leaveEmail.startDate,
              endDate: leaveEmail.endDate,
            },
          ],
          body: inputField.reason,
        }

        const res = await fetch(API_BASE_URL + `/emails`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(emailData),
        })

        if (!res.ok) {
          throw new Error(`Request failed with status: ${res.status}`)
        }

        return await res.json()
      })
    )

    return responses
  } catch (error) {
    // Handle any error that occurs during the API calls
    console.error('Error:', error)
    throw error // You can re-throw the error or handle it in some other way
  }
}

//create a simple Email
export async function addSimpleEmail(InputField: {
  recipients: {
    to: string[]
    cc: string[]
  }
  sender: string
  subject: string
  body: string
  isLeave: boolean
}) {
  const {token, id} = setupAxios()
  const emailRecivers = [
    ...InputField.recipients.to.map((receiver) => ({receiver:{id:receiver}, type: 'to'})),
    ...InputField.recipients.cc.map((receiver) => ({receiver:{id:receiver}, type: 'cc'})),
  ]

  const res = await fetch(API_BASE_URL + `/emails`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      emailRecivers,
      sender: id,
      subject: InputField.subject,
      body: InputField.body,
      isLeave: InputField.isLeave,
    }),
  })
  return await res.json()
}
export async function addDraft(InputField: {
  recipients: {
    to: string[]
    cc: string[]
  }
  sender: string
  subject: string
  body: string
  isLeave: boolean
  isPublish: boolean
}) {
  const {token, id} = setupAxios()
  const emailRecivers = [
    ...InputField.recipients.to.map((receiver) => ({receiver, type: 'to'})),
    ...InputField.recipients.cc.map((receiver) => ({receiver, type: 'cc'})),
  ]

  const res = await fetch(API_BASE_URL + `/emails`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      emailRecivers,
      sender: id,
      subject: InputField.subject,
      body: InputField.body,
      isLeave: InputField.isLeave,
      isPublish: InputField.isPublish,
    }),
  })
  return await res.json()
}

//inbox
export async function getEmails(userId, action) {
  const {token} = setupAxios()
  const res = await fetch(API_BASE_URL + `/users/${userId}/emails?action=${action}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
  return await res.json()
}
export async function getSimpleEmails(id) {
  const {token} = setupAxios()
  const res = await fetch(API_BASE_URL + `/emails/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
  return await res.json()
}
export function updateLeaveStatus(
  userId: string,
  updatedFields: {leaveId: string; status: string; remark: string}[]
) {
  const {token} = setupAxios()

  const promises = updatedFields.map((updatedField) => {
    return fetch(API_BASE_URL + `/receiver/${userId}/emails`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify([
        {
          id: updatedField.leaveId,
          status: updatedField.status,
          remark: updatedField.remark,
        },
      ]),
    }).then((res) => res.json())
  })

  return Promise.all(promises)
}
export function updateDraft(
  mailId: string,
  updatedFields: {
    leaveId: string
    subject: string
    body: string
    sender: string
    isLeave: boolean
    isPublish: boolean
    recipients: {receiver: string; type: string}[]
  }
) {
  const {token} = setupAxios()
  const promises = updatedFields.recipients.map((recipient) => {
    return fetch(API_BASE_URL + `/emails/${mailId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify([
        {
          id: updatedFields.leaveId,
          subject: updatedFields.subject,
          body: updatedFields.body,
          sender: updatedFields.sender,
          isLeave: updatedFields.isLeave,
          isPublish: updatedFields.isPublish,
          recipients: {
            receiver: recipient.receiver,
            type: recipient.type,
          },
        },
      ]),
    }).then((res) => res.json())
  })

  return Promise.all(promises)
}
