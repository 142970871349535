import axios from 'axios'
import {toast} from 'react-toastify'
import Swal from 'sweetalert2'
import {Message} from '../../../../_metronic/helpers'
import {updatePassword} from '../../../../api/AdminAPI'
import {API_BASE_URL} from '../../../../utils/contants'
import {AlertProps} from '../../../data/AlertDefaults'
import {setupAxios} from '../../../modules/auth'

export const updatePersonalDetailApi = async (userId, personalDetailData, token) => {
  try {
    const response = await axios.patch(
      `${API_BASE_URL}/users/${userId}/personal`,
      personalDetailData,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return response.data.data
  } catch (error) {
    console.error('Error updating user:', error)
    throw error
  }
}
export const deactivateAccount = async (userId, setUserData, setSubmitAPIStatus, isActive) => {
  Swal.fire({
    ...AlertProps,
    icon: 'warning',
    title: `Are you sure you want to ${isActive ? 'deactivate' : 'activate'} this account?`,
  }).then(async (result: any) => {
    if (result.isConfirmed) {
      const {token, tenantId} = setupAxios()
      setSubmitAPIStatus((prev: any) => ({...prev, loading: true}))
      const res = await fetch(API_BASE_URL + `/users/${userId}/status`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'X-Tenant-Id': `${tenantId}`,
        },
        body: JSON.stringify({
          isActive: false,
        }),
      })

      let response: any = await res.json()

      if (response.statusCode === 200) {
        setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
        console.log(response.data)
        setUserData(response.data)
        toast.success(
          response.data?.isActive
            ? 'Account activated successfully.'
            : 'Account deactivated successfully.'
        )
      } else {
        setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
        toast.error('Failed to deactivate account.')
      }
    }
  })
}
export const handleInputChange = (
  event: React.SyntheticEvent,
  setInputField: Function,
  InputField: {
    oldPassword: string
    password: string
  }
) => {
  let target = event.target as HTMLInputElement
  setInputField({
    ...InputField,
    [target.name]: target.type === 'checkbox' ? target.checked : target.value,
  })
}

export const handleSubmit = (
  event: React.FormEvent,
  InputField: {
    oldPassword: string
    password: string
  },
  initialState: {
    oldPassword: string
    password: string
  },
  setInputField: Function,
  cancelSubmit: Function,
  setAPIStatus: Function,
  setInputError: Function,
  validateForm: Function,
  setSubmitAPIStatus: Function,
  id: string,
  setPasswordForm: Function
) => {
  event.preventDefault()
  console.log(id)
  let {cnt, error} = validateForm(InputField)
  setInputError(error)

  if (cnt === 0) {
    console.log('current User:', id)
    setSubmitAPIStatus((prev: any) => ({...prev, loading: true}))
    updatePassword(InputField, id).then((res) => {
      if (res.statusCode === 200) {
        toast.success(res.message)
        setPasswordForm(false)
        // cancelSubmit(setInputField, initialState, setInputError)
      } else {
        Message(res.message, 'error')
      }
      setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
    })
  } else {
    console.log('Validation Errors:', error)
  }
}

export const validateForm = (InputField: {oldPassword: string; password: string}) => {
  let cnt = 0
  const error: any = {}
  const uppercaseRegex = /[A-Z]/
  const lowercaseRegex = /[a-z]/
  const specialSymbolRegex = /[!@#$%^&*(),.?":{}|<>]/
  const numberRegex = /\d/

  if (!InputField.oldPassword.trim()) {
    cnt = cnt + 1
    error.oldPassword = 'Please enter a Current Password.'
  }
  if (!InputField.password.trim()) {
    cnt = cnt + 1
    error.password = 'Please enter a Password.'
  } else {
    if (InputField.password.length < 8) {
      cnt = cnt + 1
      error.password = 'Password must be at least 8 characters.'
    }
    if (InputField.password.length > 16) {
      cnt = cnt + 1
      error.password = 'Password must not exceed 16 characters.'
    }

    if (!uppercaseRegex.test(InputField.password) || !lowercaseRegex.test(InputField.password)) {
      cnt = cnt + 1
      error.password = 'Password must contain at least one uppercase and one lowercase letter.'
    }

    if (!numberRegex.test(InputField.password)) {
      cnt = cnt + 1
      error.password = 'Password must contain at least one number.'
    }

    if (!specialSymbolRegex.test(InputField.password)) {
      cnt = cnt + 1
      error.password = 'Password must contain at least one special symbol.'
    }
  }

  return {error, cnt}
}
export const cancelSubmit = (
  setInputField: Function,
  InputField: {oldPassword: string; password: string},
  setInputError: Function
) => {
  setInputField(InputField)
  setInputError({})
}
