import {Button, Center, MultiSelect} from '@mantine/core'
import axios from 'axios'
import {useContext, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import ReactQuill from 'react-quill'
import {ToastContainer} from 'react-toastify'
import {changeTextCapital, checkRole} from '../../../_metronic/helpers'
import {useThemeMode} from '../../../_metronic/partials'
import {API_BASE_URL} from '../../../utils/contants'
import InputErrorBox from '../../components/InputErrorBox'
import Select2 from '../../components/select2'
import roleContext from '../../context/roleContext'
import {setupAxios, useAuth} from '../../modules/auth'
import {RoleContextValue} from '../../types/OtherTypes'
import {
  formatCreateAt,
  formatDates,
  getFilterLeave,
  getLeave,
  handleCancelButtonClick,
  handleSubmit,
  validateForm,
} from './LeaveFunctions'
interface InputFieldState {
  sender: string
  recipients: {
    to: string[]
    cc: never[]
  }
  leaveType: string
  reason: string
  leaveDate: {
    startDate: string
    endDate: string
    type: string
  }[]
}
const MyLeave = ({getLeaveBalances, setBalance, getPieChartData, setPieChartDate}) => {
  const [leave, setLeave] = useState([])
  let roleState: RoleContextValue = useContext(roleContext)

  const {currentUser} = useAuth()
  const {mode} = useThemeMode()

  type OptionType = {
    label: string
    value: string
    email: string
  }
  const [APIStatus, setAPIStatus] = useState({
    loading: false,
    error: null,
    submitLoading: false,
  })
  const [SubmitAPIStatus, setSubmitAPIStatus] = useState({
    loading: false,
  })
  const [dateError, setDateError] = useState('')
  const [reasonModalOpen, setReasonModalOpen] = useState(false)
  const [selectedReason, setSelectedReason] = useState('')
  const initialState = {
    sender: '',
    recipients: {
      to: [],
      cc: [],
    },
    leaveType: '',
    reason: '',
    leaveDate: [
      {
        startDate: '',
        endDate: '',
        type: '',
      },
    ],
  }
  const [inputError, setInputError] = useState<any>({})
  const [inputField, setInputField] = useState<InputFieldState>(initialState)
  const [myLeaveModalOpen, setMyLeaveModalOpen] = useState(false)
  const [editId, setEditId] = useState(null)

  const handleTrashClick = () => {
    setInputField(initialState)
    setDateError('')
  }
  const [showRow, setShowRow] = useState<number[]>([])

  const checkArrayElement = (element: number) => {
    let arr: number[] = [...showRow]
    let isExists = false
    for (let i = 0; i <= arr.length - 1; i++) {
      if (arr[i] === element) {
        arr.splice(i, 1)
        isExists = true
        break
      }
    }

    if (!isExists) {
      arr.push(element)
    }
    setShowRow(arr)
  }
  const themeStyles = {
    input: {
      backgroundColor: 'transparent',
      color: mode === 'dark' ? 'white' : 'var(--bs-gray-700)',
      border: 'none',
    },
    item: {
      fontSize: '13px',
      color: mode === 'dark' ? 'white' : 'black',
      '&:hover': {
        backgroundColor: mode === 'dark' ? 'var(--bs-gray-500)' : 'var(--bs-gray-200)',
      },
    },
    dropdown: {
      backgroundColor: mode === 'dark' ? '#333333' : 'white',
      border: 'none',
    },
  }
  const [dropdownOptions, setDropdownOptions] = useState<OptionType[]>([])
  const sortedUserNames = dropdownOptions.slice().sort((a, b) => a.label.localeCompare(b.label))
  const fetchNames = () => {
    const {token, tenantId} = setupAxios()
    axios
      .get(API_BASE_URL + `/activeUsers`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-tenant-Id': `${tenantId}`,
        },
      })
      .then((response: any) => {
        let userData: OptionType[] = []
        response.data.data.map(
          (obj: {firstName: string; lastName: string; id: string; email: string}) => {
            userData.push({
              label: obj.firstName + ' ' + obj.lastName,
              value: obj.id,
              email: obj.email,
            })
          }
        )

        const reportingManagerId = currentUser?.reportingManager?.id
        const reportingManager = userData.find((user) => user.value === reportingManagerId)

        setDropdownOptions(userData)

        if (reportingManager) {
          const defaultReportingManager = [
            {label: reportingManager.label, value: reportingManager.value},
          ]
          setInputField((prevInputField) => ({
            ...prevInputField,
            recipients: {
              to: defaultReportingManager.map((option) => option.value),
              cc: [],
            },
          }))
        }
      })
      .catch((error) => {
        console.error('Error fetching names:', error)
      })
  }

  const handleClose = () => {
    setInputError('')
    setMyLeaveModalOpen(false)
    setInputField(initialState)
  }
  const truncateString = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...'
    }
    return text
  }
  const removeLeaveDate = (index) => {
    setInputField((prevInputField) => ({
      ...prevInputField,
      leaveDate: prevInputField.leaveDate.filter((_, i) => i !== index),
    }))
    setInputError('')
  }

  const addLeaveDate = () => {
    setInputField((prevInputField) => ({
      ...prevInputField,
      leaveDate: [
        ...prevInputField.leaveDate,
        {
          startDate: '',
          endDate: '',
          type: '',
        },
      ],
    }))
  }
  const handleStartDateChange = (e, index) => {
    setInputField((prevInputField) => ({
      ...prevInputField,
      leaveDate: [
        ...prevInputField.leaveDate.slice(0, index),
        {
          ...prevInputField.leaveDate[index],
          startDate: e.target.value,
        },
        ...prevInputField.leaveDate.slice(index + 1),
      ],
    }))
  }

  const handleEndDateChange = (e, index) => {
    setInputField((prevInputField) => ({
      ...prevInputField,
      leaveDate: [
        ...prevInputField.leaveDate.slice(0, index),
        {
          ...prevInputField.leaveDate[index],
          endDate: e.target.value,
        },
        ...prevInputField.leaveDate.slice(index + 1),
      ],
    }))
  }
  const handleTypeChange = (e, leaveIndex) => {
    const {value} = e.target

    setInputField((prevInputField) => ({
      ...prevInputField,
      leaveDate: prevInputField.leaveDate.map((leave, index) =>
        index === leaveIndex ? {...leave, type: value} : leave
      ),
    }))
  }

  const fetchData = () => {
    getLeaveBalances(currentUser, setBalance, setAPIStatus)
    getLeave(currentUser, setLeave, setAPIStatus, 'sent')
    getPieChartData(currentUser, setPieChartDate, setAPIStatus)
  }
  useEffect(() => {
    let mounted = true
    if (mounted) {
      const action = 'sent'
      getLeave(currentUser, setLeave, setAPIStatus, action)
      fetchNames()
    }
    return () => {
      mounted = false
    }
  }, [currentUser, setLeave, setAPIStatus, getLeaveBalances])

  return (
    <div>
      <ToastContainer position={'bottom-center'} />
      <Modal
        show={reasonModalOpen}
        onClose={() => setReasonModalOpen(false)}
        animation={false}
        backdrop='static'
        centered
      >
        <div className='modal-header'>
          <h2 className='fw-bolder'>Reason</h2>
          <div
            className='btn btn-icon btn-sm btn-active-icon-danger'
            data-kt-users-modal-action='close'
            style={{cursor: 'pointer'}}
            onClick={() => {
              setReasonModalOpen(false)
            }}
          >
            <i className='fa-solid fa-xmark fs-1' />
          </div>
        </div>
        <div className='card-body'>
          <div className='mt-5 ms-5' dangerouslySetInnerHTML={{__html: selectedReason}} />
        </div>
      </Modal>
      <Modal
        show={myLeaveModalOpen}
        className='modal-lg'
        onClose={() => setMyLeaveModalOpen(false)}
        animation={false}
        onRequestClose={handleClose}
        centered
      >
        <div className='modal-header'>
          <h2 className='fw-bolder'>Apply Leave</h2>
          <div
            className='btn btn-icon btn-sm btn-active-icon-danger'
            data-kt-users-modal-action='close'
            style={{cursor: 'pointer'}}
            onClick={() => {
              handleClose()
            }}
          >
            <i className='fa-solid fa-xmark fs-1' />
          </div>
        </div>
        <div className='card-body p-0'>
          <div className='d-block'>
            <div className='d-flex align-items-center border-bottom ps-8 pe-5 min-h-50px d-flex'>
              <div className='text-dark fw-bold w-35px'>To:</div> ​
              <MultiSelect
                styles={{
                  input: {
                    backgroundColor: 'transparent',
                    border: 'none',
                  },
                  searchInput: {fontSize: '16px'},
                  item: {fontSize: '13px', lineHeight: '2rem'},
                  defaultValue: {height: '2rem', fontSize: '13px', borderRadius: '.5rem'},
                }}
                data={sortedUserNames}
                searchable
                value={
                  inputField.recipients && inputField.recipients.to
                    ? inputField.recipients.to.slice(0, 1)
                    : []
                }
                onChange={(selectedOptions) => {
                  const updatedToRecipients = selectedOptions.slice(0, 1)
                  setInputField((prevInputField) => ({
                    ...prevInputField,
                    recipients: {
                      ...prevInputField.recipients,
                      to: updatedToRecipients,
                    },
                  }))
                }}
                placeholder='Select..'
                style={{width: '100%'}}
              />
            </div>
            <div className='ms-7'>
              {' '}
              <InputErrorBox Message={inputError?.to} />
            </div>

            <div className='align-items-center border-bottom ps-8 pe-5 min-h-50px d-flex'>
              <div className='text-dark fw-bold w-35px'>Cc:</div>
              <MultiSelect
                styles={{
                  input: {
                    backgroundColor: 'transparent',
                    border: 'none',
                  },
                  searchInput: {fontSize: '16px'},
                  item: {fontSize: '13px', lineHeight: '2rem'},
                  defaultValue: {height: '2rem', fontSize: '13px', borderRadius: '.5rem'},
                }}
                data={sortedUserNames}
                searchable
                value={
                  inputField.recipients && inputField.recipients.cc ? inputField.recipients.cc : []
                }
                onChange={(selectedOptions) => {
                  const updatedCcRecipients = selectedOptions
                  setInputField(
                    (prevInputField) =>
                      ({
                        ...prevInputField,
                        recipients: {
                          ...prevInputField.recipients,
                          cc: updatedCcRecipients,
                        },
                      }) as typeof prevInputField
                  )
                }}
                placeholder='Select...'
                style={{width: '100%'}}
              />
            </div>

            <div className=' border-bottom px-4'>
              <Select2
                name='leaveType'
                data={[
                  {label: 'Optional Leave', value: 'Optional Leave'},
                  {label: 'Earned Leave', value: 'Paid'},
                  {label: 'Sick Leave', value: 'Sick Leave'},
                  {label: 'Work From Home', value: 'Work From Home'},
                ]}
                value={inputField.leaveType}
                styles={(theme) => ({
                  ...theme,
                  ...themeStyles,
                  '&[data-focus]': {
                    border: '1px solid red',
                  },
                })}
                placeholder='Subject'
                onChange={(selectedOption: any) => {
                  setInputField((prevInputField) => ({
                    ...prevInputField,
                    leaveType: selectedOption,
                  }))
                }}
              />
            </div>
            <div className='ms-7'>
              {' '}
              <InputErrorBox Message={inputError?.leaveType} />
            </div>
            {inputField.leaveDate.map((leave, index) => (
              <div key={index} className='d-flex mt-2 flex-wrap border-bottom'>
                <div className='d-flex mt-2 flex-wrap border-bottom '>
                  <div className='fv-row me-4 ms-7'>
                    <label className=' required fs-6  fw-semibold mb-2'>Start Date</label>

                    <div className='col-12'>
                      <input
                        style={{width: '190px'}}
                        className='form-control w-170px'
                        name='startDate'
                        type='date'
                        value={leave.startDate}
                        onChange={(e) => handleStartDateChange(e, index)}
                      />
                    </div>
                    <div style={{width: '190px'}}>
                      {' '}
                      <InputErrorBox Message={inputError[`startDate${index}`]} />
                    </div>
                  </div>
                  <div className='fv-row me-4 mb-2'>
                    <label className=' required fs-6 fw-semibold mb-2'>End Date</label>

                    <div className='col-12'>
                      <input
                        style={{width: '190px'}}
                        className='form-control w-170px'
                        name='endDate'
                        type='date'
                        value={leave.endDate}
                        onChange={(e) => handleEndDateChange(e, index)}
                      />
                      <div style={{width: '190px'}}>
                        {' '}
                        <InputErrorBox Message={inputError[`endDate${index}`]} />
                      </div>

                      {dateError && <div className='text-danger'>{dateError}</div>}
                    </div>
                  </div>
                  <div>
                    <div className='fv-row me-1 mb-2 '>
                      <label className='required fw-bold fs-6 mb-2'>Type</label>
                      <div className='d-flex mt-3'>
                        <div className='form-check mb-2'>
                          <input
                            className='form-check-input'
                            type='radio'
                            id={`full-${index}`}
                            name={`type-${index}`}
                            value='full'
                            checked={leave.type === 'full'}
                            onChange={(e) => handleTypeChange(e, index)}
                          />

                          <label
                            htmlFor={`full-${index}`}
                            className='fw-bold text-gray-800 d-block me-3'
                          >
                            Full
                          </label>
                        </div>

                        <div className='form-check mb-2'>
                          <input
                            className='form-check-input'
                            type='radio'
                            id={`firstHalf-${index}`}
                            name={`type-${index}`}
                            value='firstHalf'
                            checked={leave.type === 'firstHalf'}
                            onChange={(e) => handleTypeChange(e, index)}
                          />
                          <label
                            htmlFor={`firstHalf-${index}`}
                            className=' fw-bold text-gray-800 d-block me-3'
                          >
                            First Half
                          </label>
                        </div>

                        <div className='form-check mb-2'>
                          <input
                            className='form-check-input'
                            type='radio'
                            id={`secondHalf-${index}`}
                            name={`type-${index}`}
                            value='secondHalf'
                            checked={leave.type === 'secondHalf'}
                            onChange={(e) => handleTypeChange(e, index)}
                          />
                          <label
                            htmlFor={`secondHalf-${index}`}
                            className=' fw-bold text-gray-800 d-block'
                          >
                            Second Half
                          </label>
                        </div>
                      </div>
                      <div className='mt-3'>
                        <InputErrorBox Message={inputError[`type${index}`]} />
                      </div>
                    </div>
                  </div>

                  <div className='fv-row mb-2 mt-3'>
                    {index === 0 && <label className='  fs-6 fw-semibold mb-2'></label>}
                    <div className='col-12 mt-1'>
                      {index === inputField.leaveDate.length - 1 && (
                        <>
                          {index < 5 && (
                            <Button
                              type='button'
                              className='btn btn-light btn-light-primary me-2  btn-sm font-weight-bold'
                              style={{height: '33px', width: '45px'}}
                              onClick={addLeaveDate}
                            >
                              <i className='fa-solid fa-plus'></i>
                            </Button>
                          )}

                          {index !== 0 && (
                            <Button
                              type='button'
                              className='btn btn-light btn-light-danger me-2  btn-sm font-weight-bold'
                              style={{height: '33px', width: '45px'}}
                              onClick={() => removeLeaveDate(index)}
                            >
                              <i className='fa-solid fa-xmark'></i>
                            </Button>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}

            <div className='align-items-center border-bottom ps-5 pe-5 min-h-150px'>
              <ReactQuill
                style={{fontSize: '13px'}}
                theme='snow'
                className='h-100px'
                value={inputField.reason}
                onChange={(e) => {
                  setInputField((prevInputField) => ({
                    ...prevInputField,
                    reason: e,
                  }))
                }}
                id='body'
                placeholder='Enter text...'
                // modules={modules}
              />
            </div>
            <div className='ms-7'>
              {' '}
              <InputErrorBox Message={inputError?.reason} />
            </div>

            <div className='d-flex flex-stack flex-wrap gap-2 py-5 ps-8 pe-5 border-top'>
              <div className='d-flex align-items-center me-3'>
                <Button
                  loaderPosition='center'
                  loading={SubmitAPIStatus.loading}
                  className='btn btn-sm btn-primary fs-bold px-6'
                  onClick={() => {
                    handleSubmit(
                      currentUser,
                      inputField,
                      initialState,
                      setLeave,
                      setInputField,
                      editId,
                      setAPIStatus,
                      setInputError,
                      setSubmitAPIStatus,
                      validateForm,

                      setMyLeaveModalOpen,
                      fetchData
                    )
                  }}
                >
                  Send
                </Button>
              </div>
              <div className='d-flex align-items-center'>
                <span
                  className='btn btn-icon btn-sm btn-light-danger btn-active-danger'
                  data-inbox='dismiss'
                  data-toggle='tooltip'
                  title='Discard'
                  onClick={handleTrashClick}
                >
                  <i className='fa-solid fa-trash'></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <div className='card mb-5 mb-xl-8'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>My Leave</span>
          </h3>
          <div
            className='d-flex mt-4 justify-content-end'
            data-kt-user-table-toolbar='base'
            style={{height: '3rem'}}
          >
            <div className='me-3' style={{width: '175px'}}>
              <Select2
                name='leaveTypeDropdown'
                data={[
                  {label: 'Optional Leave', value: 'Optional Leave'},
                  {label: 'Earned Leave', value: 'Paid'},
                  {label: 'Sick Leave', value: 'Sick Leave'},
                  {label: 'Unpaid', value: 'Unpaid'},
                  {label: 'Work From Home', value: 'Work From Home'},
                ]}
                value={inputField.leaveType}
                placeholder='Search..'
                onChange={(selectedOption: any) => {
                  setInputField((prevInputField) => ({
                    ...prevInputField,
                    leaveType: selectedOption,
                  }))
                  getFilterLeave(currentUser, setLeave, setAPIStatus, selectedOption)
                }}
              />
            </div>
            <Button
              hidden={!checkRole(['Leave Management System'], roleState.features, 'edit')}
              type='button'
              className='btn btn-sm btn-primary h-35px mt-1'
              onClick={() => {
                setMyLeaveModalOpen(true)
                fetchNames()
              }}
            >
              Apply Leave
            </Button>
          </div>
        </div>

        <div className='card-body py-3 '>
          <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted '>
                  <th className=''></th>
                  <th className='' style={{minWidth: '70px'}}>
                    {changeTextCapital('Subject')}
                  </th>
                  <th className='' style={{minWidth: '110px'}}>
                    {changeTextCapital('Primary Reciepient')}
                  </th>
                  <th className='' style={{minWidth: '110px'}}>
                    {changeTextCapital('Reason')}
                  </th>
                  <th className='' style={{minWidth: '150px'}}>
                    {changeTextCapital('Status')}
                  </th>
                  <th className='' style={{minWidth: '110px'}}>
                    {changeTextCapital('Create Date')}
                  </th>
                </tr>
              </thead>
              {leave?.length > 0 ? (
                <tbody>
                  {leave.map((obj: any, index: number) => (
                    <>
                      <tr>
                        <td>
                          <div>
                            <span
                              className={`badge badge-light-primary d-inline-block cursor-pointer ${showRow.includes(
                                index
                              )}`}
                              onClick={() => {
                                checkArrayElement(index)
                              }}
                            >
                              {showRow.includes(index) ? '↑' : '↓'}
                            </span>
                          </div>
                        </td>

                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              {`Request for ${
                                obj?.leaveType === 'Paid'
                                  ? 'Earned Leave'
                                  : obj?.leaveType === 'Unpaid'
                                    ? 'Unpaid Leave'
                                    : obj?.leaveType === 'Sick Leave'
                                      ? 'Sick Leave'
                                      : obj?.leaveType === 'Optional Leave'
                                        ? 'Optional Leave'
                                        : obj?.leaveType === 'Work From Home'
                                          ? 'Work from home'
                                          : '-'
                              }`}
                            </div>
                          </div>
                        </td>

                        <td>
                          {obj?.leaveUser.map((user) =>
                            user.type === 'to'
                              ? `${user.receiver.firstName ?? '-'} ${user.receiver.lastName ?? '-'}`
                              : ''
                          )}
                        </td>
                        <td
                          className='d-flex flex-column cursor-pointer mt-5'
                          style={{paddingTop: '10px'}}
                          onClick={() => {
                            setSelectedReason(obj?.reason ?? '-')
                            setReasonModalOpen(true)
                          }}
                          dangerouslySetInnerHTML={{
                            __html: truncateString(obj?.reason ?? '-', 20),
                          }}
                        />

                        <td>
                          <span
                            className={`badge border ${
                              obj?.status === 'approved'
                                ? 'badge-light-success'
                                : obj?.status === 'cancel'
                                  ? 'bg-secondary'
                                  : obj?.status === 'declined'
                                    ? 'badge-light-danger'
                                    : obj?.status === 'partially approved'
                                      ? 'badge badge-light-success'
                                      : obj?.status === 'partially declined'
                                        ? 'badge badge-light-danger'
                                        : 'badge badge-light-warning'
                            }`}
                          >
                            {obj?.status === 'approved'
                              ? 'Approved'
                              : obj?.status === 'cancel'
                                ? 'Canceled'
                                : obj?.status === 'declined'
                                  ? 'Declined'
                                  : obj?.status === 'partially approved'
                                    ? 'Partially Approved'
                                    : obj?.status === 'partially declined'
                                      ? 'Partially Declined'
                                      : obj?.status === 'partially pending'
                                        ? 'Partially Pending'
                                        : 'Pending'}
                          </span>
                        </td>

                        <td>{formatCreateAt(obj?.createAt ?? '-')}</td>
                      </tr>

                      <tr key={`secondIndex${index}`} hidden={!showRow.includes(index)}>
                        <td colSpan={6}>
                          <>
                            <div className='table-responsive'>
                              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                <thead>
                                  <tr className='fw-bold text-muted'>
                                    <th style={{width: '35px'}}></th>{' '}
                                    <th style={{width: '175px'}}>{changeTextCapital('Type')}</th>
                                    <th style={{width: '160px'}}>
                                      {changeTextCapital('Start Date')}
                                    </th>
                                    <th style={{width: '115px'}}>
                                      {changeTextCapital('End Date')}
                                    </th>
                                    <th style={{width: '150px'}}>{changeTextCapital('Status')}</th>
                                    <th style={{width: '140px'}}>
                                      {changeTextCapital('Action By')}
                                    </th>
                                  </tr>
                                </thead>

                                <tbody>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                  {obj.leaveDate.map((detail) => (
                                    <>
                                      <tr key={`${obj?.id ?? '-'}-${detail.id}`}>
                                        <td>
                                          <div>
                                            <span></span>
                                          </div>
                                        </td>
                                        <td>
                                          <div className='d-flex align-items-center'>
                                            <div className='d-flex justify-content-start flex-column'>
                                              {detail.type === 'full'
                                                ? 'Full'
                                                : detail.type === 'firstHalf'
                                                  ? 'First Half'
                                                  : detail.type === 'secondHalf'
                                                    ? 'Second Half'
                                                    : '-'}
                                            </div>
                                          </div>
                                        </td>

                                        <td>{formatDates(detail.startDate ?? '-')}</td>

                                        <td>
                                          <div className='d-flex flex-column '>
                                            {formatDates(detail.endDate ?? '-')}
                                          </div>
                                        </td>

                                        <td className=' align-middle'>
                                          {detail?.status === 'pending' ? (
                                            <span className='badge badge-light-warning d-inline-block'>
                                              Pending
                                            </span>
                                          ) : (
                                            <span
                                              className={`badge d-inline-block ${
                                                detail?.status === 'accept'
                                                  ? 'badge-light-success'
                                                  : detail?.status === 'cancel'
                                                    ? 'bg-secondary'
                                                    : 'badge-light-danger'
                                              }`}
                                            >
                                              {detail?.status === 'accept'
                                                ? 'Approved'
                                                : detail?.status === 'cancel'
                                                  ? 'Canceled'
                                                  : 'Declined'}
                                            </span>
                                          )}
                                        </td>
                                        {detail?.status === 'pending' ? (
                                          <td className=' align-middle'>
                                            <button
                                              type='button'
                                              className='btn btn-secondary btn-sm'
                                              onClick={() => {
                                                handleCancelButtonClick(
                                                  detail.id,
                                                  currentUser,
                                                  setLeave,
                                                  setAPIStatus,
                                                  'sent',
                                                  fetchData
                                                )
                                              }}
                                            >
                                              Cancel
                                            </button>
                                          </td>
                                        ) : (
                                          <td className='align-middle'>
                                            <div className='mt-5'>
                                              {detail?.approvedBy?.firstName ?? '-'}{' '}
                                              {detail?.approvedBy?.lastName ?? '-'}
                                            </div>
                                            <div className='text-muted'>
                                              {formatCreateAt(detail?.approvedAt ?? '-')}
                                            </div>
                                          </td>
                                        )}
                                      </tr>
                                    </>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </>
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan={6}>
                      <Center>No Record Available.</Center>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MyLeave
