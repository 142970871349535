import {useEffect} from 'react'
import {useAuth} from '../../modules/auth'
import HrDashboard from './admin_dashboard/AdminDashboard'
import EmployeeDashboard from './employee_dashboard/EmployeeDashboard'
import AdminDashboard from './admin_dashboard/AdminDashboard'

const DashboardWrapper = () => {
  const {currentUser} = useAuth()
  useEffect(() => {
    // We have to show toolbar only for dashboard page
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }
  }, [])

  return <>{currentUser?.designation === 'Admin' ? <AdminDashboard /> : <EmployeeDashboard />}</>
}

export {DashboardWrapper}
