import {useContext, useEffect, useState} from 'react'
import {setupAxios, useAuth} from '../../../modules/auth'
import axios from 'axios'
import {Col, Row} from 'react-bootstrap'
import {toast} from 'react-toastify'
import {
  Message,
  changeTextCamal,
  changeTextCapital,
  checkRole,
  convertToAMPM,
} from '../../../../_metronic/helpers'
import {getUsersById} from '../../../../api/AdminAPI'
import {API_BASE_URL} from '../../../../utils/contants'
import Select2 from '../../../components/select2'
import roleContext from '../../../context/roleContext'
import {RoleContextValue} from '../../../types/OtherTypes'
import {SignInMethod} from './SignInMethod'
import {handleInputChange} from '../../user/UserFunction'

export function Overview({data, setUser}) {
  let roleState: RoleContextValue = useContext(roleContext)
  const {currentUser} = useAuth()
  const [aadharValidationError, setAadharValidationError] = useState('')
  const [panValidationError, setPanValidationError] = useState('')
  const [bankNameError, setBankNameError] = useState('')
  const [ifscValidationError, setIFSCValidationError] = useState('')
  const [accountNoValidationError, setAccountNoValidationError] = useState('')
  const [accountTypeError, setAccontTypeError] = useState('')
  const [reportingManagerError, setreportingManagerError] = useState('')
  const [firstNameError, setFirstNameError] = useState('')
  const [lastNameError, setLastNameError] = useState('')
  const [contactError, setContactError] = useState('')
  const [inTineError, setInTimeError] = useState('')
  const [outTimeError, setOutTimeError] = useState('')
  const [designationError, setDesignationError] = useState('')
  const [empolyementTypeError, setEmpolyementTypeError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [personalEmailError, setPersonalEmailError] = useState('')
  const [startDateError, setStartDateError] = useState('')
  const [endDateError, setEndDateError] = useState('')
  const [dobError, setDobError] = useState('')
  const [roleError, setRoleError] = useState('')
  const [genderError, setGenderError] = useState('')
  const [hrManager, setHrManager] = useState('')

  const fetchUserById = () => {
    getUsersById(data.id)
      .then((userData) => setUser(userData.data))
      .catch((error) => {
        console.error('Error fetching user data:', error)
      })
  }

  useEffect(() => {
    let mounted = true
    if (mounted) {
      setUserId(data?.id)
      setPersonalDetailData({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        contact: data.contact,
        dob: data.dob,
        gender: data.gender,
        role: data.role,
        hrManager: data.hrManager,
        employmentType: data.employmentType,
        designation: data.designation,
        startDate: data.startDate,
        endDate: data.endDate,
        inTime: data.inTime,
        outTime: data.outTime,
        reportingManager: data.reportingManager,

        personalEmail: data.personalEmail,
      })
      setBankDetailData({
        bankName: data.bankName,
        accountType: data.accountType,
        accountNumber: data.accountNumber,
        ifsc: data.ifsc,
        panNumber: data.panNumber,
        aadharNumber: data.aadharNumber,
      })
    }
    return () => {
      mounted = false
    }
  }, [data])

  const handlePersonalDataChange = (event, field?) => {
    if (field) {
      setPersonalDetailData((prevInputField) => ({
        ...prevInputField,
        [field]: event,
      }))
    } else if (event.target) {
      const {name, value} = event.target

      if (name === 'firstName') {
        const isValidFirstName = /^[A-Za-z.][A-Za-z. ]*$/.test(value)

        if (!isValidFirstName) {
          setFirstNameError('Please enter a valid First Name using only alphabetic characters.')
        } else if (value.length < 2) {
          setFirstNameError('First Name must be at least 2 characters.')
        } else if (value.length > 35) {
          setFirstNameError('First Name must not exceed 35 characters.')
        } else {
          setFirstNameError('')
        }
        setPersonalDetailData((prevInputField) => ({
          ...prevInputField,
          [name]: value,
        }))
      } else if (name === 'lastName') {
        const isValidLastName = /^[A-Za-z.][A-Za-z. ]*$/.test(value)
        if (!isValidLastName) {
          setLastNameError('Please enter a valid Last Name using only alphabetic characters.')
        } else if (value.length < 2) {
          setLastNameError('Last Name must be at least 2 characters.')
        } else if (value.length > 35) {
          setLastNameError('Last Name must not exceed 35 characters.')
        } else {
          setLastNameError('')
        }
        setPersonalDetailData((prevInputField) => ({
          ...prevInputField,
          [name]: value,
        }))
      } else if (name === 'contact') {
        const isValidContact = /^[0-9]+$/.test(value)

        if (!isValidContact) {
          setContactError('Please enter a valid Mobile Number using only numeric characters.')
        } else if (value.length > 10) {
          setContactError('Mobile Number must be exactly 10 digits.')
        } else {
          setContactError('')
        }
        setPersonalDetailData((prevInputField) => ({
          ...prevInputField,
          [name]: value,
        }))
      }

      // if (name === 'email') {
      //   const isValidEmail = /@(skeletos\.in|skeletos\.io)$/.test(value)
      //   if (!isValidEmail) {
      //     setEmailError('Please enter a valid Organization Email.')
      //   } else {
      //     setEmailError('')
      //   }
      else if (name === 'email') {
        console.log(data.email)
        if (data.email) {
          const currentUserDomain = data.email.split('@')[1]
          const inputEmailDomain = value.split('@')[1]

          if (currentUserDomain !== inputEmailDomain) {
            setEmailError('Please enter a valid organization email domain.')
          } else {
            setEmailError('')
          }
        }

        setPersonalDetailData((prevInputField) => ({
          ...prevInputField,
          [name]: value,
        }))
      } else if (name === 'personalEmail') {
        const isValidPersonalEmail = /@(gmail\.com)$/.test(value)
        if (!isValidPersonalEmail) {
          setPersonalEmailError('Please enter a valid Personal Email.')
        } else {
          setPersonalEmailError('')
        }
        setPersonalDetailData((prevInputField) => ({
          ...prevInputField,
          [name]: value,
        }))
      } else if (name === 'startDate') {
        if (!value) {
          setStartDateError('Please enter/select a Date of Joining')
        } else {
          setStartDateError('')
        }
        setPersonalDetailData((prevInputField) => ({
          ...prevInputField,
          [name]: value,
        }))
      } else if (name === 'inTime') {
        if (!value) {
          setInTimeError('Please enter/select a In Time.')
        } else {
          setInTimeError('')
        }
        setPersonalDetailData((prevInputField) => ({
          ...prevInputField,
          [name]: value,
        }))
      } else if (name === 'role') {
        if (!value) {
          setRoleError('Please enter/select a Role.')
        } else {
          setRoleError('')
        }
        setPersonalDetailData((prevInputField) => ({
          ...prevInputField,
          [name]: value,
        }))
      } else if (name === 'outTime') {
        if (!value) {
          setOutTimeError('Please enter/select an Out Time.')
        } else {
          setOutTimeError('')

          const baseDate = new Date()
          const inTimeValue = data?.inTime ?? '-'
          const [inHours, inMinutes] = inTimeValue?.split(':') ?? '-'
          const [outHours, outMinutes] = value?.split(':') ?? '-'
          const inTime = new Date(baseDate)
          inTime.setHours(parseInt(inHours, 10), parseInt(inMinutes, 10), 0, 0)
          const outTime = new Date(baseDate)
          outTime.setHours(parseInt(outHours, 10), parseInt(outMinutes, 10), 0, 0)
          const maxHours = 9
          const minHours = 4
          let timeDifferenceInMilliseconds = outTime.getTime() - inTime.getTime()
          if (timeDifferenceInMilliseconds < 0) {
            timeDifferenceInMilliseconds += 24 * 60 * 60 * 1000
          }
          const timeDifferenceInHours = timeDifferenceInMilliseconds / (1000 * 60 * 60)
          console.log('Time Difference in Hours:', timeDifferenceInHours)
          if (timeDifferenceInHours > maxHours) {
            setOutTimeError(
              "Please ensure that the 'Out Time' does not exceed 9 hours after the 'In Time', and Please adjust your 'In Time' and 'Out Time' accordingly."
            )
          } else if (timeDifferenceInHours < minHours) {
            setOutTimeError(
              "Please ensure that the 'Out Time' must be at least 4 hours after the 'In Time', and Please adjust your 'In Time' and 'Out Time' accordingly."
            )
          }
        }

        setPersonalDetailData((prevInputField) => ({
          ...prevInputField,
          [name]: value,
        }))
      } else if (name === 'dob') {
        if (!value) {
          setDobError('Please enter/select a Date of Birth.')
        } else {
          setDobError('')

          const dobDate = new Date(value)
          const currentDate = new Date()
          const minAge = 18

          // Calculate age
          const age =
            currentDate.getFullYear() -
            dobDate.getFullYear() -
            (currentDate.getMonth() < dobDate.getMonth() ||
            (currentDate.getMonth() === dobDate.getMonth() &&
              currentDate.getDate() < dobDate.getDate())
              ? 1
              : 0)

          if (age < minAge) {
            setDobError(`Users must be at least ${minAge} years old to register.`)
          }
        }

        setPersonalDetailData((prevInputField) => ({
          ...prevInputField,
          [name]: value,
        }))
      }

      setPersonalDetailData((prevInputField) => ({
        ...prevInputField,
        [name]: value,
      }))
    }
  }

  const handleBankDataChange = (event, field?) => {
    if (field) {
      setBankDetailData((prevInputField) => ({
        ...prevInputField,
        [field]: event,
      }))
      if (field === 'accountType') {
        if (!event) {
          setAccontTypeError('Please select a Account Type.')
        } else {
          setAccontTypeError('')
        }
      }
    } else if (event.target) {
      const {name, value} = event.target

      if (name === 'aadharNumber') {
        const isValidAadhar = /^\d{12}$/.test(value)
        if (!isValidAadhar) {
          setAadharValidationError('Aadhaar Card Number must be 12 digits and numerical.')
        } else {
          setAadharValidationError('')
        }
        setBankDetailData((prevInputField) => ({
          ...prevInputField,
          [name]: value,
        }))
      } else if (name === 'panNumber') {
        const upperCaseValue = value.toUpperCase()
        const isValidPan = /^[A-Z]{5}\d{4}[A-Z]$/.test(upperCaseValue)
        if (!isValidPan) {
          setPanValidationError('Please enter a valid Pan Card Number')
        } else {
          setPanValidationError('')
        }
        setBankDetailData((prevInputField) => ({
          ...prevInputField,
          [name]: upperCaseValue,
        }))
      } else if (name === 'ifsc') {
        const upperCaseValue = value.toUpperCase()
        const isValidIFSC = /^[A-Z]{4}0[A-Z0-9]{6}$/.test(upperCaseValue)
        if (!isValidIFSC) {
          setIFSCValidationError('Please enter a valid IFSC Code.')
        } else {
          setIFSCValidationError('')
        }
        setBankDetailData((prevInputField) => ({
          ...prevInputField,
          [name]: upperCaseValue,
        }))
      } else if (name === 'accountNumber') {
        const isValidAccountNumber = /^[0-9]{9,18}$/.test(value)
        if (!isValidAccountNumber) {
          setAccountNoValidationError('Please enter a valid account number with 9 to 18 digits.')
        } else {
          setAccountNoValidationError('')
        }
        setBankDetailData((prevInputField) => ({
          ...prevInputField,
          [name]: value,
        }))
      } else if (name === 'bankName') {
        const isValidBankName = /^[A-Za-z.][A-Za-z0-9. ]*$/.test(value)
        if (!isValidBankName) {
          setBankNameError('Please enter a valid Bank Name using only alphanumeric characters.')
        } else if (value.length < 3) {
          setBankNameError('Bank Name must be at least 3 characters.')
        } else if (value.length > 50) {
          setBankNameError('Bank Name must not exceed 50 characters.')
        } else if (!value) {
          setBankNameError('Please enter a Bank Name.')
        } else {
          setBankNameError('')
        }
        setBankDetailData((prevInputField) => ({
          ...prevInputField,
          [name]: value,
        }))
      }
    }
  }

  // Reset the message state after a successful password update

  const [isPersonalDetailEdit, setPersonalDetailEdit] = useState(false)
  const [userNames, setUserNames] = useState<{label: string; value: string}[]>([])
  const sortedUserNames = userNames.slice().sort((a, b) => a.label.localeCompare(b.label))

  const fetchNames = () => {
    const {token, tenantId} = setupAxios()
    axios
      .get(API_BASE_URL + `/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-tenant-Id': `${tenantId}`,
        },
      })
      .then((response: any) => {
        let userData: {label: string; value: string}[] = []
        response.data.data?.map((obj: {firstName: string; lastName: string; id: string}) => {
          userData.push({
            label: obj.firstName + ' ' + obj.lastName,
            value: obj.id,
          })
        })
        setUserNames(userData)
      })
      .catch((error) => {
        console.error('Error fetching names:', error)
      })
  }
  const [roles, setRoles] = useState<{label: string; value: string}[]>([])
  const sortedRoles = roles.slice().sort((a, b) => a.label.localeCompare(b.label))

  const fetchRoles = () => {
    const {token, tenantId} = setupAxios()
    axios
      .get(API_BASE_URL + `/roles`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-tenant-Id': `${tenantId}`,
        },
      })
      .then((response: any) => {
        let userData: {label: string; value: string}[] = []
        response.data.data?.map((obj: {name: string; id: string}) => {
          userData.push({
            label: obj.name + ' ',
            value: obj.id,
          })
        })
        setRoles(userData)
      })
      .catch((error) => {
        console.error('Error fetching names:', error)
      })
  }
  const [hr, setHr] = useState<{label: string; value: string}[]>([])
  const sortedHr = hr.slice().sort((a, b) => a.label.localeCompare(b.label))
  const fetchHr = () => {
    const {token, tenantId} = setupAxios()
    axios
      .get(API_BASE_URL + `/users/hr`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-Tenant-Id': `${tenantId}`,
        },
      })
      .then((response: any) => {
        let userData: {label: string; value: string}[] = []

        userData = response.data.data.map(
          (obj: {firstName: string; lastName: string; id: string}) => ({
            label: `${obj.firstName} ${obj.lastName}`,
            value: obj.id,
          })
        )

        setHr(userData)
      })
      .catch((error) => {
        console.error('Error fetching names:', error)
      })
  }

  const handleEditProfileClick = () => {
    fetchNames()
    fetchRoles()
    fetchHr()
    setPersonalDetailEdit(true)
    setPersonalDetailData(initialPersonalDetailData)
    setIsBankDetailEdit(false)
    const reportingManagerId = data.reportingManager ? data.reportingManager.id : ''
    setPersonalDetailData((prevInputField) => ({
      ...prevInputField,
      reportingManager: reportingManagerId,
    }))
    const roleId = data.role ? data.role.id : ''
    setPersonalDetailData((prevInputField) => ({
      ...prevInputField,
      role: roleId,
    }))
    const hrId = data.hrManager ? data.hrManager.id : ''
    setPersonalDetailData((prevInputField) => ({
      ...prevInputField,
      hrManager: hrId,
    }))
  }

  const resetPersonalDetail = () => {
    setPersonalDetailEdit(false)
    setFirstNameError('')
    setLastNameError('')
    setContactError('')
    setInTimeError('')
    setOutTimeError('')
    setDesignationError('')
    setEmpolyementTypeError('')
    setEmailError('')
    setRoleError('')
    setPersonalEmailError('')
    setStartDateError('')
    setEndDateError('')
    setDobError('')
    setreportingManagerError('')
    setGenderError('')
    setHrManager('')
  }

  const initialPersonalDetailData = {
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    contact: data.contact,
    dob: data.dob,
    gender: data.gender,
    role: data.name,
    hrManager: data.hrManager,
    employmentType: data.employmentType,
    designation: data.designation,
    startDate: data.startDate,
    endDate: data.endDate,
    inTime: data.inTime,
    outTime: data.outTime,
    reportingManager: data.reportingManager,
    personalEmail: data.personalEmail,
  }

  const [userId, setUserId] = useState(data?.id)

  const [personalDetailData, setPersonalDetailData] = useState(initialPersonalDetailData)

  const initialBankDetailData = {
    bankName: data.bankName,
    accountType: data.accountType,
    accountNumber: data.accountNumber,
    ifsc: data.ifsc,
    panNumber: data.panNumber,
    aadharNumber: data.aadharNumber,
  }
  // console.log(initialPersonalDetailData)
  const [bankDetailData, setBankDetailData] = useState(initialBankDetailData)

  const updatePersonalDetail = () => {
    const {token, tenantId} = setupAxios()
    let error = 0
    if (!personalDetailData.firstName) {
      setFirstNameError('Please enter First Name.')
      error = error + 1
    } else {
      setFirstNameError('')
    }

    if (!personalDetailData.reportingManager) {
      setreportingManagerError('Please select/enter Reporting Manager.')
      error = error + 1
    } else {
      setreportingManagerError('')
    }

    if (!personalDetailData.dob) {
      setDobError('Please enter/select a Date of Birth.')
      error = error + 1
    } else {
      setDobError('')
    }

    if (!personalDetailData.lastName) {
      setLastNameError('Please enter Last Name.')
      error = error + 1
    } else {
      setLastNameError('')
    }
    if (!personalDetailData.role) {
      setRoleError('Please enter/select a Role.')
      error = error + 1
    } else if (roleError !== '') {
      error = error + 1
    } else {
      setRoleError('')
    }
    if (!personalDetailData.contact) {
      setContactError('Please enter a Mobile Number.')
      error = error + 1
    } else if (contactError !== '') {
      error = error + 1
    } else {
      setContactError('')
    }
    if (!personalDetailData.email) {
      setEmailError('Please enter a Organization Email.')
      error = error + 1
    } else if (emailError !== '') {
      error = error + 1
    } else {
      setEmailError('')
    }
    if (!personalDetailData.personalEmail) {
      setPersonalEmailError('Please enter a Personal Email.')
      error = error + 1
    } else if (personalEmailError !== '') {
      error = error + 1
    } else {
      setPersonalEmailError('')
    }
    if (!personalDetailData.inTime) {
      setInTimeError('Please select/enter a InTime.')
      error = error + 1
    } else if (inTineError !== '') {
      error = error + 1
    } else {
      setInTimeError('')
    }
    if (!personalDetailData.outTime) {
      setOutTimeError('Please select/enter a OutTime.')
      error = error + 1
    } else if (outTimeError !== '') {
      error = error + 1
    } else {
      setOutTimeError('')
    }
    if (!personalDetailData.designation) {
      setDesignationError('Please select/enter a Designtion.')
      error = error + 1
    } else if (designationError !== '') {
      error = error + 1
    } else {
      setDesignationError('')
    }
    if (!personalDetailData.employmentType) {
      setEmpolyementTypeError('Please select/enter a Employement Type.')
      error = error + 1
    } else if (empolyementTypeError !== '') {
      error = error + 1
    } else {
      setEmpolyementTypeError('')
    }
    if (!personalDetailData.startDate) {
      setStartDateError('Please select/enter a Date Of Joining.')
      error = error + 1
    } else if (startDateError !== '') {
      error = error + 1
    } else {
      setStartDateError('')
    }

    if (!personalDetailData.gender) {
      setGenderError('Please select a Gender.')
      error = error + 1
    } else if (genderError !== '') {
      error = error + 1
    } else {
      setGenderError('')
    }
    if (error === 0) {
      fetch(`${API_BASE_URL}/users/${userId}/personal`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${currentUser?.token}`,
          'X-Tenant-Id': `${tenantId}`,
        },
        method: 'PATCH',
        body: JSON.stringify({
          ...personalDetailData,
          reportingManager: {
            id: !personalDetailData?.reportingManager ? null : personalDetailData?.reportingManager,
          },
          role: {
            id: !personalDetailData?.role ? null : String(personalDetailData?.role), // Convert role ID to string
          },

          hrManager: personalDetailData.hrManager ? {id: personalDetailData.hrManager} : null,
        }),
      }).then(async (res) => {
        let response = await res.json()
        if (response.statusCode === 200) {
          console.log(response.data)
          setUser(response.data)
          toast.success('User details updated successfully.')
          setPersonalDetailData(response.data)
          setPersonalDetailEdit(false)
          fetchUserById()
          setTimeout(() => {
            resetPersonalDetail()
          }, 1000)
        } else {
          Message(response.message, 'error')
        }
      })
    }
  }

  // for bank details:update
  const [isBankDetailEdit, setIsBankDetailEdit] = useState(false)

  const handleBankDetailEdit = () => {
    fetchNames()
    setIsBankDetailEdit(true)
    setBankDetailData(initialBankDetailData)
    setPersonalDetailEdit(false)
    setAadharValidationError('')
    setPanValidationError('')
    setIFSCValidationError('')
    setAccountNoValidationError('')
    setAccontTypeError('')
    setBankNameError('')
  }

  const resetBankDetail = () => {
    setIsBankDetailEdit(false)
    setAadharValidationError('')
    setPanValidationError('')
    setIFSCValidationError('')
    setAccountNoValidationError('')
    setAccontTypeError('')
    setBankNameError('')
  }

  const updateBankDetail = () => {
    let error = 0
    console.log(bankDetailData)
    if (!bankDetailData.bankName) {
      setBankNameError('Please enter a Bank Name.')
      error = error + 1
    } else if (bankNameError !== '') {
      error = error + 1
    } else {
      setBankNameError('')
    }
    if (!bankDetailData.aadharNumber) {
      setAadharValidationError('Please enter a Aadhar Card Number.')
      error = error + 1
    } else if (aadharValidationError !== '') {
      error = error + 1
    } else {
      setAadharValidationError('')
    }
    if (!bankDetailData.panNumber) {
      setPanValidationError('Please enter a Pan Card Number.')
      error = error + 1
    } else if (panValidationError !== '') {
      error = error + 1
    } else {
      setPanValidationError('')
    }
    if (!bankDetailData.ifsc) {
      setIFSCValidationError('Please enter a IFSC Code.')
      error = error + 1
    } else if (ifscValidationError !== '') {
      error = error + 1
    } else {
      setIFSCValidationError('')
    }

    if (!bankDetailData.accountNumber) {
      setAccountNoValidationError('Please enter a Account No.')
      error = error + 1
    } else if (accountNoValidationError !== '') {
      error = error + 1
    } else {
      setAccountNoValidationError('')
    }

    if (!bankDetailData.accountType) {
      setAccontTypeError('Please select a Account Type.')
      error = error + 1
    } else {
      setAccontTypeError('')
    }

    if (error === 0) {
      axios
        .patch(`${API_BASE_URL}/users/${userId}/bank`, bankDetailData, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${currentUser?.token}`,
            'X-tenant-Id': `${currentUser?.tenantId}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            // Handle the response here and print it to the console
            console.log(response.data.data)
            setBankDetailData(response.data.data)
            toast.success('Bank details updated successfully.')
            setIsBankDetailEdit(false)
            fetchUserById()
            setTimeout(() => {
              resetBankDetail()
            }, 1000)
          }
        })
        .catch((error) => {})
    }
  }
  return (
    <>
      {/* Profile Datails */}
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Personal Details</h3>
          </div>

          <div
            className='card-toolbar'
            hidden={!checkRole(['User Personal Details'], roleState.features, 'edit')}
          >
            <i className='far fa-edit cursor-pointer' onClick={handleEditProfileClick}></i>
          </div>
        </div>

        <div className='card-body p-9'>
          {/* fname nd lname */}
          {!isPersonalDetailEdit ? (
            <>
              <Row className='mb-4'>
                <Col md={2}>
                  <label className=' fw-bold fs-6 text-muted'>First Name</label>
                </Col>
                <Col md={3}>
                  <span className='fw-bolder fs-6 text-dark'>
                    {changeTextCamal(data?.firstName)}{' '}
                  </span>
                </Col>
                <Col md={1}></Col>
                <Col md={2}>
                  <label className=' fw-bold fs-6 text-muted'>Last Name</label>
                </Col>
                <Col md={3}>
                  <span className='fw-bolder fs-6 text-dark'>
                    {changeTextCamal(data?.lastName)}{' '}
                  </span>
                </Col>
              </Row>
              {/* contact email */}

              <Row className='mb-4'>
                <Col md={2}>
                  <label className=' fw-bold fs-6 text-muted'>Mobile Number</label>
                </Col>
                <Col md={3}>
                  <span className='fw-bolder fs-6 text-dark'> {data?.contact ?? '-'} </span>
                </Col>
                <Col md={1}></Col>
                <Col md={2}>
                  <label className=' fw-bold fs-6 text-muted'>Designation</label>
                </Col>
                <Col md={3}>
                  <span className='fw-bolder fs-6 text-dark'>
                    {changeTextCamal(data?.designation)}{' '}
                  </span>
                </Col>
              </Row>

              {/* role employ type */}
              <Row className='mb-4'>
                <Col md={2}>
                  <label className=' fw-bold fs-6 text-muted'>Organization Email</label>
                </Col>
                <Col md={3}>
                  <span className='fw-bolder fs-6 text-dark'> {data?.email ?? '-'} </span>
                </Col>
                <Col md={1}></Col>
                <Col md={2}>
                  <label className=' fw-bold fs-6 text-muted'>Personal Email</label>
                </Col>
                <Col md={3}>
                  <span className='fw-bolder fs-6 text-dark'> {data?.personalEmail ?? '-'}</span>
                </Col>
              </Row>
              {/* reporting manager */}

              <Row className='mb-4'>
                <Col md={2}>
                  <label className='fw-bold fs-6 text-muted'>Reporting Manager</label>
                </Col>
                <Col md={3}>
                  <span className='fw-bolder fs-6 text-dark'>
                    {`${
                      data?.reportingManager
                        ? changeTextCamal(data?.reportingManager?.firstName) +
                          ' ' +
                          changeTextCamal(data?.reportingManager?.lastName)
                        : '-'
                    }`}
                  </span>
                </Col>
                <Col md={1}></Col>
                <Col md={2}>
                  <label className=' fw-bold fs-6 text-muted'>Employment Type </label>
                </Col>
                <Col md={3}>
                  <span className='fw-bolder fs-6 text-dark'>
                    {changeTextCamal(data?.employmentType ?? '-')}
                  </span>
                </Col>
              </Row>

              <Row className='mb-4'>
                <Col md={2}>
                  <label className=' fw-bold fs-6 text-muted'>Date of Joining</label>
                </Col>
                <Col md={3}>
                  <span className='fw-bolder fs-6 text-dark'> {data?.startDate ?? '-'}</span>
                </Col>
                <Col md={1}></Col>
                <Col md={2}>
                  <label className=' fw-bold fs-6 text-muted'>Date Of Leaving</label>
                </Col>
                <Col md={3}>
                  <span className='fw-bolder fs-6 text-dark'> {data?.endDate ?? '-'} </span>
                </Col>
              </Row>

              <Row className='mb-4'>
                <Col md={2}>
                  <label className=' fw-bold fs-6 text-muted'>DOB</label>
                </Col>
                <Col md={3}>
                  <span className='fw-bolder fs-6 text-dark'> {data?.dob ?? '-'} </span>
                </Col>
                <Col md={1}></Col>

                <Col md={2}>
                  <label className=' fw-bold fs-6 text-muted'>Gender</label>
                </Col>
                <Col md={3}>
                  <span className='fw-bolder fs-6 text-dark'> {changeTextCamal(data?.gender)}</span>
                </Col>
              </Row>
              {/* in/out time */}

              <Row className='mb-4'>
                <Col md={2}>
                  <label className=' fw-bold fs-6 text-muted'>In Time</label>
                </Col>
                <Col md={3}>
                  <span className='fw-bolder fs-6 text-dark'>
                    {convertToAMPM(data?.inTime) ?? '-'}
                  </span>
                </Col>
                <Col md={1}></Col>
                <Col md={2}>
                  <label className=' fw-bold fs-6 text-muted'>Out Time</label>
                </Col>
                <Col md={3}>
                  <span className='fw-bolder fs-6 text-dark'>
                    {convertToAMPM(data?.outTime) ?? '-'}
                  </span>
                </Col>
              </Row>
              <Row className='mb-4'>
                <Col md={2}>
                  <label className=' fw-bold fs-6 text-muted'>Role</label>
                </Col>
                <Col md={3}>
                  <span className='fw-bolder fs-6 text-dark'>{data?.role?.name ?? '-'}</span>
                </Col>
                <Col md={1}></Col>
                <Col md={2}>
                  <label className=' fw-bold fs-6 text-muted'>Hr Manager</label>
                </Col>
                <Col md={3}>
                  <span className='fw-bolder fs-6 text-dark'>{`${
                    data?.hrManager
                      ? changeTextCamal(data?.hrManager?.firstName) +
                        ' ' +
                        changeTextCamal(data?.hrManager?.lastName)
                      : '-'
                  }`}</span>
                </Col>
              </Row>
            </>
          ) : null}

          {isPersonalDetailEdit ? (
            <>
              {/* fname nd lname */}
              <Row className='mb-4'>
                <Col md={2}>
                  <label className='required fw-bold fs-6 text-muted'>First Name</label>
                </Col>
                <Col md={3}>
                  <span className='fs-6 text-dark'>
                    <input
                      type='text'
                      className={` required form-control ${firstNameError ? 'is-invalid' : ''}`}
                      name='firstName'
                      value={personalDetailData?.firstName}
                      onChange={(e) => {
                        handlePersonalDataChange(e)
                      }}
                    />
                    {firstNameError && <div className='invalid-feedback'>{firstNameError}</div>}
                  </span>
                </Col>
                <Col md={1}></Col>
                <Col md={2}>
                  <label className='required fw-bold fs-6 text-muted'>Last Name</label>
                </Col>
                <Col md={3}>
                  <input
                    type='text'
                    name='lastName'
                    className={` required form-control ${lastNameError ? 'is-invalid' : ''}`}
                    value={personalDetailData?.lastName}
                    onChange={(e) => {
                      handlePersonalDataChange(e)
                    }}
                  />
                  {lastNameError && <div className='invalid-feedback'>{lastNameError}</div>}
                </Col>
              </Row>
              {/* contact email */}
              <Row className='mb-4'>
                <Col md={2}>
                  <label className='required fw-bold fs-6 text-muted'>Mobile Number</label>
                </Col>
                <Col md={3}>
                  <input
                    type='tel'
                    name='contact'
                    className={` required form-control ${contactError ? 'is-invalid' : ''}`}
                    value={personalDetailData?.contact}
                    onChange={(e) => {
                      handlePersonalDataChange(e)
                    }}
                  />
                  {contactError && <div className='invalid-feedback'>{contactError}</div>}
                </Col>
                <Col md={1}></Col>
                <Col md={2}>
                  <label className='required fw-bold fs-6 text-muted'>Designation</label>
                </Col>
                <Col md={3}>
                  <Select2
                    name='designation'
                    // error={designationError}
                    className={`${designationError ? 'is-invalid' : ''}`}
                    data={[
                      {label: 'Test Engineer (Manual QA)', value: 'Test Engineer (Manual QA)'},
                      {label: 'Jr. Manual Tester', value: 'Jr. Manual Tester'},
                      {label: 'Jr. Automation Tester', value: 'Jr. Automation Tester'},
                      {label: 'DevOps Support Engineer', value: 'DevOps Support Engineer'},
                      {label: 'Sr. Software Engineer', value: 'Sr. Software Engineer'},
                      {label: 'Jr. Software Engineer', value: 'Junior Software Engineer'},
                      {label: 'Business Analyst', value: 'Business Analyst'},
                      {label: 'HR Executive', value: 'HR Executive'},
                      {label: 'FullStack Developer', value: 'FullStack Developer'},
                      {label: 'Project Manager', value: 'Project Manager'},
                      {label: 'Admin', value: 'Admin'},
                    ]}
                    value={personalDetailData?.designation}
                    onChange={(e: any) => {
                      handlePersonalDataChange(e, 'designation')
                    }}
                  />
                  {designationError && <div className='invalid-feedback'>{designationError}</div>}
                </Col>
              </Row>
              {/* role employ type */}
              <Row className='mb-4'>
                <Col md={2}>
                  <label className='required fw-bold fs-6 text-muted'>Organization Email</label>
                </Col>
                <Col md={3}>
                  <input
                    type='text'
                    name='email'
                    className={` required form-control ${emailError ? 'is-invalid' : ''}`}
                    placeholder='XXX@skeletos.in/io'
                    value={personalDetailData?.email}
                    onChange={(e) => {
                      handlePersonalDataChange(e)
                    }}
                  />
                  {emailError && <div className='invalid-feedback'>{emailError}</div>}
                </Col>
                <Col md={1}></Col>
                <Col md={2}>
                  <label className='required fw-bold fs-6 text-muted'>Personal Email</label>
                </Col>
                <Col md={3}>
                  <input
                    type='text'
                    name='personalEmail'
                    className={` required form-control ${personalEmailError ? 'is-invalid' : ''}`}
                    placeholder='XXX@gmail.com'
                    value={personalDetailData?.personalEmail}
                    onChange={(e) => {
                      handlePersonalDataChange(e)
                    }}
                  />
                  {personalEmailError && (
                    <div className='invalid-feedback'>{personalEmailError}</div>
                  )}
                </Col>
              </Row>
              {/* reporting manager */}
              <Row className='mb-4'>
                <Col md={2}>
                  <label className=' required fw-bold fs-6 text-muted'>Reporting Manager</label>
                </Col>
                <Col md={3}>
                  <Select2
                    name='reportingManager'
                    data={sortedUserNames}
                    value={personalDetailData?.reportingManager}
                    onChange={(e: any) => {
                      handlePersonalDataChange(e, 'reportingManager')
                    }}
                    // error={reportingManagerError}
                    className={` ${reportingManagerError ? 'is-invalid' : ''}`}
                  />
                  {reportingManagerError && (
                    <div className='invalid-feedback'>{reportingManagerError}</div>
                  )}
                </Col>
                <Col md={1}></Col>
                <Col md={2}>
                  <label className='required fw-bold fs-6 text-muted'>Employement Type </label>
                </Col>
                <Col md={3}>
                  <Select2
                    name='employmentType'
                    className={`${empolyementTypeError ? 'is-invalid' : ''}`}
                    data={[
                      {label: 'Employee', value: 'Employee'},
                      {label: 'Intern', value: 'Intern'},
                      {label: 'Probation', value: 'Probation'},
                    ]}
                    value={personalDetailData?.employmentType}
                    onChange={(e: any) => {
                      handlePersonalDataChange(e, 'employmentType')
                    }}
                  />
                  {empolyementTypeError && (
                    <div className='invalid-feedback'>{empolyementTypeError}</div>
                  )}
                </Col>
              </Row>
              <Row className='mb-4'>
                <Col md={2}>
                  <label className='required fw-bold fs-6 text-muted'>Date of Joining</label>
                </Col>
                <Col md={3}>
                  <input
                    type='date'
                    name='startDate'
                    className={` form-control ${startDateError ? 'is-invalid' : ''}`}
                    value={personalDetailData?.startDate}
                    onChange={(e) => {
                      handlePersonalDataChange(e)
                    }}
                  />
                  {startDateError && <div className='invalid-feedback'>{startDateError}</div>}
                </Col>
                <Col md={1}></Col>
                <Col md={2}>
                  <label className=' fw-bold fs-6 text-muted'>Date Of Leaving</label>
                </Col>
                <Col md={3}>
                  <input
                    type='date'
                    name='endDate'
                    className={` form-control ${endDateError ? 'is-invalid' : ''}`}
                    value={personalDetailData?.endDate ?? ''}
                    onChange={(e) => {
                      handlePersonalDataChange(e)
                    }}
                  />{' '}
                  {/* {endDateError && <div className='invalid-feedback'>{endDateError}</div>} */}
                </Col>
              </Row>
              <Row className='mb-4'>
                <Col md={2}>
                  <label className='required fw-bold fs-6 text-muted'>DOB</label>
                </Col>
                <Col md={3}>
                  <input
                    type='date'
                    name='dob'
                    className={` form-control ${dobError ? 'is-invalid' : ''}`}
                    value={personalDetailData?.dob}
                    onChange={(e) => {
                      handlePersonalDataChange(e)
                    }}
                  />{' '}
                  {dobError && <div className='invalid-feedback'>{dobError}</div>}
                </Col>
                <Col md={1}></Col>
                <Col md={2}>
                  <label className='required fw-bold fs-6 text-muted'>Gender</label>
                </Col>
                <Col md={3}>
                  <div
                    // className='d-flex mt-2'
                    className={` d-flex mt-2  ${genderError ? 'is-invalid' : ''}`}
                  >
                    <div className='form-check'>
                      <input
                        className='form-check-input'
                        type='radio'
                        id='male'
                        name='gender'
                        value='male'
                        checked={personalDetailData?.gender === 'male'}
                        onChange={(e) => {
                          handlePersonalDataChange(e)
                        }}
                      />
                      {/* {genderError && <div className='invalid-feedback'>{genderError}</div>} */}

                      <label htmlFor='true' className='fw-bold text-gray-800 d-block me-3'>
                        Male
                      </label>
                    </div>
                    <div className='form-check' style={{paddingRight: '20px'}}>
                      <input
                        className='form-check-input'
                        type='radio'
                        name='gender'
                        placeholder='radio1'
                        value='female'
                        id='female'
                        checked={personalDetailData?.gender === 'female'}
                        onChange={(e) => {
                          handlePersonalDataChange(e)
                        }}
                      />
                      <label htmlFor='false' className=' fw-bold text-gray-800 d-block'>
                        Female
                      </label>
                    </div>
                  </div>
                  {genderError && <div className='invalid-feedback'>{genderError}</div>}
                </Col>
              </Row>
              {/* in/out time */}
              <Row className='mb-4'>
                <Col md={2}>
                  <label className='required fw-bold fs-6 text-muted'>In Time</label>
                </Col>
                <Col md={3}>
                  <input
                    type='time'
                    name='inTime'
                    className={` form-control ${inTineError ? 'is-invalid' : ''}`}
                    value={personalDetailData?.inTime}
                    onChange={(event) =>
                      handleInputChange(event, setPersonalDetailData, personalDetailData)
                    }
                  />
                  {inTineError && <div className='invalid-feedback'>{inTineError}</div>}
                </Col>
                <Col md={1}></Col>
                <Col md={2}>
                  <label className='required fw-bold fs-6 text-muted'>Out Time</label>
                </Col>
                <Col md={3}>
                  <input
                    type='time'
                    name='outTime'
                    className={` form-control ${outTimeError ? 'is-invalid' : ''}`}
                    value={personalDetailData?.outTime}
                    onChange={(event) =>
                      handleInputChange(event, setPersonalDetailData, personalDetailData)
                    }
                  />
                  {outTimeError && <div className='invalid-feedback'>{outTimeError}</div>}
                </Col>
              </Row>
              <Row className='mb-4'>
                <Col md={2}>
                  <label className=' required fw-bold fs-6 text-muted'>Role</label>
                </Col>
                <Col md={3}>
                  <Select2
                    name='role'
                    data={sortedRoles}
                    className={`${roleError ? 'is-invalid' : ''}`}
                    value={personalDetailData?.role}
                    onChange={(e: any) => {
                      handlePersonalDataChange(e, 'role')
                    }}
                  />
                  {roleError && <div className='invalid-feedback'>{roleError}</div>}
                </Col>
                <Col md={1}></Col>
                <Col md={2}>
                  <label className='fw-bold fs-6 text-muted'>Hr manager</label>
                </Col>
                <Col md={3}>
                  <Select2
                    name='hrManager'
                    data={sortedHr}
                    value={personalDetailData?.hrManager}
                    onChange={(e: any) => {
                      handlePersonalDataChange(e, 'hrManager')
                    }}
                  />
                  {reportingManagerError && (
                    <div className='invalid-feedback'>{reportingManagerError}</div>
                  )}
                </Col>
              </Row>
            </>
          ) : null}

          {isPersonalDetailEdit && (
            <div className='row mb-3'>
              <div className='col-md-12 mt-4 d-flex justify-content-end'>
                <div>
                  <button
                    className='btn btn-sm btn-secondary align-self-center me-2'
                    onClick={resetPersonalDetail}
                  >
                    Cancel
                  </button>
                  <button
                    className='btn btn-sm btn-primary align-self-center'
                    onClick={updatePersonalDetail}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Bank Details</h3>
          </div>

          <div
            className='card-toolbar'
            hidden={!checkRole(['Bank Details'], roleState.features, 'edit')}
          >
            <i className='far fa-edit cursor-pointer' onClick={handleBankDetailEdit}></i>
          </div>
        </div>

        {!isBankDetailEdit ? (
          <div className='card-body p-9'>
            {/* Bank Name */}
            <Row className='mb-4'>
              <Col md={3}>
                <label className=' fw-bold fs-6 text-muted'>Bank Name</label>
              </Col>
              <Col md={2}>
                <span className='fw-bolder fs-6 text-dark'>{data?.bankName ?? '-'}</span>
              </Col>
              <Col md={1}></Col>
              <Col md={3}>
                <label className=' fw-bold fs-6 text-muted'>Account Type</label>
              </Col>
              <Col md={2}>
                <span className='fw-bolder fs-6 text-dark'>
                  {changeTextCapital(data?.accountType ?? '-')}
                </span>
              </Col>
            </Row>
            {/* Accountnumber*/}

            <Row className='mb-4'>
              <Col md={3}>
                <label className=' fw-bold fs-6 text-muted'>Account Number</label>
              </Col>
              <Col md={2}>
                <span className='fw-bolder fs-6 text-dark'> {data?.accountNumber ?? '-'} </span>
              </Col>
              <Col md={1}></Col>
              {/* Aadhar number */}

              <Col md={3}>
                <label className=' fw-bold fs-6 text-muted'>IFSC Code</label>
              </Col>
              <Col md={2}>
                <span className='fw-bolder fs-6 text-dark'> {data?.ifsc ?? '-'} </span>
              </Col>
            </Row>

            {/* Pan number */}
            <Row className='mb-4'>
              <Col md={3}>
                <label className=' fw-bold fs-6 text-muted'>Pan Card Number</label>
              </Col>
              <Col md={2}>
                <span className='fw-bolder fs-6 text-dark'> {data?.panNumber ?? '-'} </span>
              </Col>
              <Col md={1}></Col>
              <Col md={3}>
                <label className=' fw-bold fs-6 text-muted'>Aadhar Card Number</label>
              </Col>
              <Col md={2}>
                <span className='fw-bolder fs-6 text-dark'> {data?.aadharNumber ?? '-'} </span>
              </Col>
            </Row>
          </div>
        ) : (
          <div className='card-body p-9'>
            {/* Bank Name */}
            <Row className='mb-4'>
              <Col md={3}>
                <label className='required fw-bold fs-6 text-muted'>Bank Name</label>
              </Col>
              <Col md={2}>
                <input
                  type='text'
                  name='bankName'
                  className={` required form-control ${bankNameError ? 'is-invalid' : ''}`}
                  value={bankDetailData?.bankName}
                  onChange={(e) => {
                    handleBankDataChange(e)
                  }}
                />
                {bankNameError && <div className='invalid-feedback'>{bankNameError}</div>}
              </Col>
              <Col md={1}></Col>
              <Col md={3}>
                <label className='required fw-bold fs-6 text-muted'>Account Type</label>
              </Col>
              <Col md={2}>
                <Select2
                  name='accountType'
                  data={[
                    {label: 'Saving ', value: 'Saving'},
                    {label: 'Current ', value: 'Current'},
                    {label: 'Salary ', value: 'Salary'},
                  ]}
                  value={bankDetailData?.accountType}
                  error={accountTypeError}
                  onChange={(selectedValue) => handleBankDataChange(selectedValue, 'accountType')}
                />
                {/* {accountTypeError && <div className='invalid-feedback'>{accountTypeError}</div>} */}
              </Col>
            </Row>
            {/* Accountnumber*/}

            <Row className='mb-4'>
              <Col md={3}>
                <label className='required fw-bold fs-6 text-muted'>Account Number</label>
              </Col>
              <Col md={2}>
                <input
                  type='number'
                  name='accountNumber'
                  className={` required form-control ${
                    accountNoValidationError ? 'is-invalid' : ''
                  }`}
                  value={bankDetailData?.accountNumber}
                  onChange={(e) => handleBankDataChange(e)}
                />
                {accountNoValidationError && (
                  <div className='invalid-feedback'>{accountNoValidationError}</div>
                )}
              </Col>
              <Col md={1}></Col>
              {/* Aadhar number */}

              <Col md={3}>
                <label className='required fw-bold fs-6 text-muted'>IFSC Code</label>
              </Col>
              <Col md={2}>
                <input
                  type='text'
                  name='ifsc'
                  className={`form-control ${ifscValidationError ? 'is-invalid' : ''}`}
                  value={bankDetailData?.ifsc}
                  onChange={(e) => handleBankDataChange(e)}
                  maxLength={11}
                  pattern='/^[A-Z]{4}0[A-Z0-9]{6}$/'
                  placeholder='ABCD0123456'
                  required
                />
                {ifscValidationError && (
                  <div className='invalid-feedback'>{ifscValidationError}</div>
                )}
              </Col>
            </Row>

            {/* Pan number */}
            <Row className='mb-4'>
              <Col md={3}>
                <label className='required fw-bold fs-6 text-muted'>Pan Card Number</label>
              </Col>
              <Col md={2}>
                <input
                  type='text'
                  name='panNumber'
                  className={`form-control ${panValidationError ? 'is-invalid' : ''}`}
                  value={bankDetailData?.panNumber}
                  onChange={(e) => handleBankDataChange(e)}
                  maxLength={10}
                  pattern='/^[A-Z]{5}\d{4}[A-Z]$/'
                  placeholder='XXXXX1234X'
                  required
                />
                {panValidationError && <div className='invalid-feedback'>{panValidationError}</div>}
              </Col>
              <Col md={1}></Col>

              <Col md={3}>
                <label className='required fw-bold fs-6 text-muted'>Aadhaar Card Number</label>
              </Col>
              <Col md={2}>
                <input
                  type='text'
                  name='aadharNumber'
                  className={` required form-control ${aadharValidationError ? 'is-invalid' : ''}`}
                  value={bankDetailData?.aadharNumber}
                  onChange={(e) => handleBankDataChange(e)}
                  maxLength={14}
                  pattern='/^[2-9]{1}[0-9]{3}\\s[0-9]{4}\\s[0-9]{4}$/'
                  placeholder='xxxx xxxx xxxx'
                  required
                />
                {aadharValidationError && (
                  <div className='invalid-feedback'>{aadharValidationError}</div>
                )}
              </Col>
            </Row>

            <div className='row mb-3'>
              <div className='col-md-12 mt-4 d-flex justify-content-end'>
                <div>
                  <button
                    className='btn btn-sm btn-secondary align-self-center me-2'
                    onClick={resetBankDetail}
                  >
                    Cancel
                  </button>
                  <button
                    className='btn btn-sm btn-primary align-self-center'
                    onClick={updateBankDetail}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div>
        <SignInMethod userDetails={data} />
      </div>
    </>
  )
}
export default Overview
