import {faImage, faXmark} from '@fortawesome/free-solid-svg-icons'
import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {setupAxios, useAuth} from '../../../modules/auth'
import {handleInputChanges, handleOtherInputChange} from '../task/TaskFunction'
import InputErrorBox from '../../../components/InputErrorBox'
import ReactQuill from 'react-quill'
import Select2 from '../../../components/select2'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import {CKEditor} from '@ckeditor/ckeditor5-react'

const CreateTask = ({userData, taskData, setTaskData}) => {
  const params = useParams()
  const {currentUser} = useAuth()

  const [showTaskModal, setShowTaskModal] = useState(false)
  // const tagOptions = ['Highest', 'High', 'Medium', 'Low', 'Lowest']
  // const tag = ['Easy', 'Medium', 'Hard']
  const [showSubTaskRow, setShowSubTaskRow] = useState<number[]>([])

  const [inputError, setInputError] = useState<any>({})

  const [SubmitAPIStatus, setSubmitAPIStatus] = useState({
    loading: false,
  })

  const [filterOptions, setFilterOptions] = useState({
    userId: '',
    status: '',
    date: '',
  })

  const [projectUsers, setProjectUsers] = useState({
    project_users: [{status: '', role: '', user: {id: '', firstName: '', lastName: ''}}],
  })

  // const initialStates = {
  //   id: '',
  //   title: '',
  //   description: '',
  //   startDate: new Date().toISOString().split('T')[0],
  //   dueDate: '',
  //   endDate: '',
  //   priority: 'Highest',
  //   status: 'Todo',
  //   // level: 'Easy',
  //   project: {
  //     id: params?.id,
  //   },
  //   createdBy: {
  //     id: currentUser?.id,
  //   },
  //   assigneeId: {
  //     id: projectUsers?.project_users[0]?.user?.id,
  //   },
  //   assignerId: {
  //     id: currentUser?.id,
  //   },

  //   file: [] as File[],
  // }

  // const [taskData, setTaskData] = useState(initialStates)

  const [tasks, setTasks] = useState([
    {
      id: '',
      title: '',
      description: '',
      startDate: '',
      dueDate: '',
      endDate: '',
      priority: '',
      status: '',
      ticketName: '',
      assigneeId: {
        id: '',
        firstName: '',
        lastName: '',
      },
      project: {
        name: '',
      },
      assignerId: {
        id: '',
        firstName: '',
        lastName: '',
      },
      Comment: [],
      childTasks: [
        {
          id: '',
          title: '',
          description: '',
          startDate: '',
          dueDate: '',
          endDate: '',
          priority: '',
          status: '',
          percentage: '',
          level: '',
          ticketName: '',
          assigneeId: {
            id: '',
            firstName: '',
            lastName: '',
          },
          project: {
            name: '',
          },
          assignerId: {
            id: '',
            firstName: '',
            lastName: '',
          },
          Comment: [],
        },
      ],
    },
  ])

  const [isimageSelected, setIsImageSelected] = useState(false)
  const [imageFiles, setImageFiles] = useState<File[]>([])
  const [imagePreviews, setImagePreviews] = useState<string[]>([])

  const iconBeforeSelect = faImage

  const [APIStatus, setAPIStatus] = useState({
    loading: false,
    error: null,
    submitLoading: false,
  })

  const [projectUser, setProjectUser] = useState<{label: string; value: string}[]>([])
  const sortedProjectUser = projectUser.slice().sort((a, b) => a.label.localeCompare(b.label))

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files) {
      const fileArray = Array.from(files)
      const imageArray = fileArray.filter((file) => file.type.startsWith('image/'))

      const imagePreviewsArray = imageArray.map((file) => URL.createObjectURL(file))

      setImageFiles((prevFiles) => [...prevFiles, ...imageArray])
      setImagePreviews((prevPreviews) => [...prevPreviews, ...imagePreviewsArray])

      setTaskData((prevTaskData) => ({
        ...prevTaskData,
        file: [...prevTaskData.file, ...imageArray],
      }))

      setIsImageSelected(true)
    }
  }

  const removeImage = (index: number) => {
    const updatedFiles = [...imageFiles]
    const updatedPreviews = [...imagePreviews]

    updatedFiles.splice(index, 1)
    updatedPreviews.splice(index, 1)

    setImageFiles(updatedFiles)
    setImagePreviews(updatedPreviews)

    setTaskData((prevTaskData) => ({
      ...prevTaskData,
      file: updatedFiles,
    }))
  }

  const clearImagePreviews = () => {
    setImageFiles([])
    setImagePreviews([])
  }

  useEffect(() => {
    let mounted = true

    if (mounted) {
      // console.log('userData:', userData)
      let selectedUsers: {label: string; value: string}[] = []
      if (userData) {
        userData.forEach((project) => {
          // console.log('Project:', project)
          // console.log('Project user:', project.user)
          if (project.user && typeof project.user === 'object') {
            selectedUsers.push({
              label: `${project.user.firstName} ${project.user.lastName}`,
              value: project.user.id,
            })
          }
        })
      }
      console.log('selectedUsers:', selectedUsers) // Log the selectedUsers array
      setProjectUser(selectedUsers)
    }

    return () => {
      mounted = false
    }
  }, [userData])

  return (
    <>
      {console.log('ProjectUserData', projectUser)}{' '}
      <div>
        <div className='scroll-y px-5 pt-0'>
          <div className='d-flex flex-column mb-5 fv-row'>
            <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
              <span className=''>Task Title</span>
            </label>
            <input
              type='text'
              className='form-control form-control-solid'
              placeholder='Please enter Task Title'
              name='title'
              value={taskData?.title}
              onChange={(event) => handleInputChanges(event, setTaskData)}
            />
            {<InputErrorBox Message={inputError.title} />}
          </div>
          <div className='d-flex flex-column mb-5'>
            <label className=' fs-6 fw-semibold mb-2'>Description</label>

            <div className='align-items-center border-bottom pe-5 mb-3'>
              <CKEditor
                editor={ClassicEditor}
                data={taskData?.description}
                config={{
                  toolbar: {
                    items: [
                      'heading',
                      '|',
                      'bold',
                      'italic',
                      'link',
                      'bulletedList',
                      'numberedList',
                    ],
                  },
                }}
                onChange={(value, editor) => {
                  const data = editor.getData()
                  handleOtherInputChange(data, 'description', setTaskData)
                }}
              />
            </div>
            {<InputErrorBox Message={inputError.description} />}
          </div>

          {/* <div className='col-lg-8 mt-5'>
            <div className='fs-6 fw-semibold mb-3'>Attachments</div>
            <>
              <div className='d-flex overflow-x-auto mb-1'>
                {imagePreviews.map((preview, index) => (
                  <div key={index} className='me-5'>
                    <label>
                      <Image
                        maw={240}
                        className=''
                        mx='auto'
                        radius='md'
                        src={preview}
                        alt={`Preview ${index + 1}`}
                        height={150}
                        width={150}
                      />
                    </label>
                    <span
                      className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px mb-5 mt-5 shadow'
                      data-kt-image-input-action='remove'
                      data-bs-toggle='tooltip'
                      title='Remove Image'
                    >
                      <FontAwesomeIcon icon={faXmark} onClick={() => removeImage(index)} />
                    </span>
                  </div>
                ))}
              </div>

              <div className='image-input image-input-outline mb-2'>
                <label
                  className='btn btn-icon btn-sqaure btn-sm btn-active-color-primary w-100px h-25px ms-15 shadow'
                  data-kt-image-input-action='change'
                  data-bs-toggle='tooltip'
                  title='Add Image'
                >
                  <FontAwesomeIcon icon={faImage} />
                  <input
                    key={Date.now()}
                    type='file'
                    name='avatar'
                    accept='.png, .jpg, .jpeg'
                    onChange={handleFileChange}
                    multiple
                  />
                  <span className='ms-2'>Upload File</span>
                </label>
              </div>
            </>

            <div className='form-text mb-5'>Allowed file types: png, jpg, jpeg.</div>
          </div>{' '} */}

          <div className='row g-9'>
            <div className='col-md-6 fv-row'>
              <label className=' fs-6 fw-semibold mb-2'>Start Date</label>
              <div className='position-relative d-flex align-items-center'>
                <input
                  className='form-control form-control-solid mb-5'
                  type='date'
                  name='startDate'
                  value={taskData.startDate}
                  onChange={(event) => handleInputChanges(event, setTaskData)}
                />
              </div>
              {<InputErrorBox Message={inputError.startDate} />}
            </div>
            <div className='col-md-6 fv-row'>
              <label className=' fs-6 fw-semibold mb-2'>Due Date</label>
              <div className='position-relative d-flex align-items-center'>
                <input
                  className='form-control form-control-solid mb-5'
                  type='date'
                  name='dueDate'
                  value={taskData.dueDate}
                  onChange={(event) => handleInputChanges(event, setTaskData)}
                />
              </div>
              {<InputErrorBox Message={inputError.dueDate} />}
            </div>
          </div>
          <div className='row g-6'>
            <div className='col-md-6 fv-row'>
              <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                <span className=''>Priority</span>
              </label>

              <Select2
                name='priority'
                data={[
                  {label: 'Highest', value: 'Highest'},
                  {label: 'High', value: 'High'},
                  {label: 'Medium', value: 'Medium'},
                  {label: 'Low', value: 'Low'},
                  {label: 'Lowest', value: 'Lowest'},
                ].sort((a, b) => a.label.localeCompare(b.label))}
                value={taskData?.priority}
                onChange={(selectedOption: any) => {
                  // console.log(selectedOption)
                  setTaskData((prevTaskData) => ({
                    ...prevTaskData,
                    priority: selectedOption,
                  }))
                }}
              />
            </div>

            <div className='col-md-6 mb-4'>
              <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                <span className=''>Severity</span>
              </label>

              <Select2
                name='level'
                data={[
                  {label: 'Easy', value: 'Easy'},
                  {label: 'Medium', value: 'Medium'},
                  {label: 'Hard', value: 'Hard'},
                ].sort((a, b) => a.label.localeCompare(b.label))}
                value={taskData?.level}
                onChange={(selectedOption: any) => {
                  // console.log(selectedOption)
                  setTaskData((prevTaskData) => ({
                    ...prevTaskData,
                    level: selectedOption,
                  }))
                }}
              />
              {<InputErrorBox Message={inputError.level} />}
            </div>
          </div>
          <div className='row g-6'>
            <div className='col-md-6 fv-row'>
              <label className=' fs-6 fw-semibold mb-2'>Assignee</label>
              <Select2
                name='assigneeId'
                className='mb-5'
                data={sortedProjectUser}
                value={taskData.assigneeId?.id}
                onChange={(selectedOption: string) => {
                  setTaskData((prevTaskData) => ({
                    ...prevTaskData,
                    assigneeId: selectedOption ? {id: selectedOption} : null,
                  }))
                }}
              />
              {<InputErrorBox Message={inputError.assigneeId} />}
            </div>
            <div className='col-md-6 mb-4'>
              <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                <span className=''>Status</span>
              </label>

              <Select2
                name='status'
                data={[
                  {label: 'Todo', value: 'Todo'},
                  {label: 'On Hold', value: 'On Hold'},
                  {label: 'In Progress', value: 'In Progress'},
                  {label: 'Completed', value: 'Completed'},
                ].sort((a, b) => a.label.localeCompare(b.label))}
                value={taskData.status}
                onChange={(selectedOption: any) => {
                  setTaskData((prevTaskData) => ({
                    ...prevTaskData,
                    status: selectedOption,
                  }))
                }}
              />
            </div>
          </div>
        </div>

        <div className='d-flex justify-content-end'>
          {' '}
          {/* <Button
          variant='light'
          onClick={() => {
            closeProject()
            setShowTaskModal(false)
            setInputError('')
            setCurrentStep(0)
            clearImagePreviews()
          }}
          className='me-3 mt-10'
        >
          Skip
        </Button>
        <Button
          variant='filled'
          color='green'
          type='submit'
          loading={SubmitAPIStatus.loading}
          className='mt-10'
          onClick={() => {
            handleSubmit(
              projectId,
              filterOptions.userId,
              filterOptions.date,
              filterOptions.status,
              taskData,
              // setTask,
              initialStates,
              setTaskData,
              setAPIStatus,
              setInputError,
              setSubmitAPIStatus,
              setAPIStatus,
              setSubmitAPIStatus,
              setShowTaskModal,
              validateTaskForm,
              getTask,
              setTasks,
              closeProject,
              clearImagePreviews
            )
          }}
        >
          Finish
        </Button> */}
        </div>
      </div>
    </>
  )
}

export default CreateTask
