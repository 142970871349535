import {setupAxios} from '../app/modules/auth'
import {API_BASE_URL} from '../utils/contants'
import {api} from './APIMiddlewares'

// form save api integration
export async function addAsset(InputField: {
  category: string
  subCategory: string
  brand: string
  serialNo: string
  description: string
  purchaseDate: string
  status: string
  assignee: string
}) {
  const {token, tenantId} = setupAxios()

  return await api
    .post(
      `/assets`,
      token,
      tenantId,
      {
        body: JSON.stringify({
          category: InputField.category,
          subCategory: InputField.subCategory,
          brand: InputField.brand,
          serialNo: InputField.serialNo,
          description: InputField.description,
          purchaseDate: InputField.purchaseDate,
        }),
      },
      false
    )

    .then((res) => res)
}

export async function addAssetWithUser(
  formField: {
    user: string
    issueDate: string
    location: string
    locationType: string
  },
  editId: string | null
) {
  const {token, tenantId} = setupAxios()

  return await api
    .post(
      `/assets/user`,
      token,
      tenantId,
      {
        body: JSON.stringify({
          id: editId,
          user: {
            id: formField.user,
          },
          issueDate: formField.issueDate,
          location: formField.location,
          locationType: formField.locationType,
        }),
      },
      false
    )
    .then((res) => res)
}
//edit by id
export async function sendUpdateAssetEmail(
  InputField: {
    category: string
    subCategory: string
    brand: string
    serialNo: string
    description: string
    purchaseDate: string
    status: string
    assignee: string
  },
  id: string,
  userId: string,
  comment: string
) {
  const {token, tenantId} = setupAxios()
  return await api
    .put(
      `/assets/${id}/email`,
      token,
      tenantId,
      {
        body: JSON.stringify({
          category: InputField.category,
          subCategory: InputField.subCategory,
          brand: InputField.brand,
          serialNo: InputField.serialNo,
          description: InputField.description,
          purchaseDate: InputField.purchaseDate,
          comment,
          user: {id: userId},
        }),
      },
      false
    )
    .then((res) => res)
}

//   get Assets

export async function getAssets() {
  const {token, tenantId} = setupAxios()
  return await api.get(`/assets`, token, tenantId, false)
}

//getAssets by id

export async function getAssetsById(id: string) {
  const {token, tenantId} = setupAxios()
  return await api.get(`/assets/${id}`, token, tenantId, false)
}

export async function getAssetsByUserId(userId: string) {
  const {token, tenantId} = setupAxios()
  return await api.get(`/assets/userAsset/${userId}`, token, tenantId, false)
}
export async function getAssetsByUserIdforProfile(userId: string) {
  const {token, tenantId} = setupAxios()
  return await api.get(`/assets/user/${userId}`, token, tenantId, false)
}

export async function addUserAsset(InputField: {
  category: string
  brand: string
  serialNo: string
  issueDate: string
  user: string
}) {
  const {token, tenantId} = setupAxios()

  return await api
    .post(
      `/assets/user`,
      token,
      tenantId,
      {
        body: JSON.stringify({
          category: InputField.category,
          brand: InputField.brand,
          serialNo: InputField.serialNo,
          issueDate: InputField.issueDate,
          user: {
            id: InputField.user,
          },
        }),
      },
      false
    )
    .then((res) => res)
}
export async function updateUserAssetById(
  InputField: {
    category: string
    brand: string
    serialNo: string
    issueDate: string
    returnDate: string
    user: string
  },
  id: string
) {
  const {token, tenantId} = setupAxios()
  return await api
    .put(
      `/assets/${id}/userAsset`,
      token,
      tenantId,
      {
        body: JSON.stringify({
          user: {
            id: InputField.user,
          },
          category: InputField.category,
          brand: InputField.brand,
          serialNo: InputField.serialNo,
          issueDate: InputField.issueDate,
          returnDate: InputField.returnDate,
        }),
      },
      false
    )
    .then((res) => res)
}
export async function getUserAssets() {
  const {token, tenantId} = setupAxios()
  return await api.get(`/assets/user`, token, tenantId, false)
}
