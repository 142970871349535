import {useEffect, useState} from 'react'
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom'
import {Message, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {API_BASE_URL} from '../../../../utils/contants'
import {Button} from '@mantine/core'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronLeft, faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons'
import {toast} from 'react-toastify'
import InputErrorBox from '../../../components/InputErrorBox'

function ResetPassword() {
  const params =useParams()
  const [password, setPassword] = useState('')

  const [APIStatus, setAPIStatus] = useState({loading: false, error: null, submitLoading: false})

  const [SubmitAPIStatus, setSubmitAPIStatus] = useState({
    loading: false,
  })

  const navigate = useNavigate()
  const [passwordShown, setPasswordShown] = useState(false)

  const [resetSuccess, setResetSuccess] = useState(false)

  const [confirmPassword, setConfirmPassword] = useState('')
  const [confirmPasswordError, setConfirmPasswordError] = useState('')

  const passwordVisiblity = () => {
    setPasswordShown((prev) => !prev)
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value)
  }

  const hanldeConfirmPassword = (event) => {
    setConfirmPassword(event.target.value)
  }

  const validateConfirmPassword = (confirmPassword) => {
    if (confirmPassword !== password) {
      setConfirmPasswordError('Confirm Password does not match to the New Password.')
      return false
    } else {
      setConfirmPasswordError('')
      return true
    }
  }

  const validatePassword = (password) => {
    let count = 0
    const error: any = {}

    if (!password.trim()) {
      count = count + 1
      error.password = 'Please enter a New Password.'
    } else if (password.length > 20) {
      count = count + 1
      error.password = 'Password must not exceed 20 characters.'
    } else if (password.length < 8) {
      count = count + 1
      error.password = 'Password must be at least 8 characters.'
    } else if (!/[a-z]/.test(password)) {
      count = count + 1
      error.password = 'Password must contain at least one lowercase letter.'
    } else if (!/^[A-Z]/.test(password)) {
      count = count + 1
      error.password = 'Password must start with an uppercase letter.'
    } else if (!/[!@#$%^&*]/.test(password)) {
      count = count + 1
      error.password = 'Password must contain at least one special character (!@#$%^&*).'
    } else if (!/\d/.test(password)) {
      count = count + 1
      error.password = 'Password must contain at least one digit.'
    }
    return {count, error}
  }

  const location = useLocation()
  const token = location.search.substring(0)

  const [inputError, setInputError] = useState<any>({})

  // api fetch for resetting password
  const handleSubmit = async (setInputError: Function) => {
    const {error, count} = validatePassword(password)
    setInputError(error)
    if (count === 0) {
      setAPIStatus((prev: any) => ({...prev, loading: true}))
      try {
        const response = await fetch(API_BASE_URL + `/reset-password${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',

          },
          body: JSON.stringify({
            // token, // Passing token in the request body
            password: password,
          }),
        })

        const responseBody = await response.json()

        if (response.status === 201) {
          toast.success('Password reset successfully.')
          setResetSuccess(true)
        } else {
          if (response.status === 406) {
            if (responseBody && responseBody.error) {
              Message(responseBody.error, 'error')
            } else {
              Message(responseBody.message, 'error')
            }
          } else {
            Message('Error occurred while processing your request.', 'error')
          }
        }
      } catch (error) {
        setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
      }
    }
  }

  return (
    <>
      <div className='d-flex justify-content-center align-items-center h-100'>
        <div className='row g-5 g-xl-8'>
          <div className='col-xl-12'>
            {resetSuccess ? (
              <>
                <div className='text-center mt-5'>
                  <img
                    src='/media/avatars/17-dark.png'
                    alt='noReacordAvailable'
                    className='w-70 h-100px h-sm-325px'
                  />
                </div>
                <div className='fw-bold text-gray-600 text-center mt-3 mb-2'>
                  Password changed successfully! Have a great day!{' '}
                </div>
                <div className='text-center'>
                  <Button
                    variant='subtle'
                    color=''
                    onClick={() => {
                      navigate('/auth')
                    }}
                  >
                    <u> Back To Sign In </u>
                  </Button>
                </div>
              </>
            ) : (
              <div className='card shadow'>
                <div className='card-header d-flex justify-content-center'>
                  <div className='card-title text-center'>
                    <div className=' '>
                      <img
                        style={{height: '100px', width: '120px'}}
                        className=''
                        src={toAbsoluteUrl('/media/logos/sialogo.png')}
                        alt='Skeletos Logo'
                      />
                      <div className='card-toolbar'>
                        <h1 className='text-dark fw-bolder'>Reset Password</h1>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card-body'>
                  <div className='fv-row position-relative mb-5'>
                    <label
                      className='form-label fw-bolder text-gray-900 fs-6 required'
                      style={{paddingRight: '20px'}}
                    >
                      New Password
                    </label>
                    <div className='input-group'>
                      <input
                        type={passwordShown ? 'text' : 'password'}
                        autoComplete='off'
                        className='form-control '
                        value={password}
                        onChange={handlePasswordChange}
                      />

                      <span className='input-group-text cursor-pointer' onClick={passwordVisiblity}>
                        {passwordShown ? (
                          <FontAwesomeIcon icon={faEyeSlash} />
                        ) : (
                          <FontAwesomeIcon icon={faEye} />
                        )}
                      </span>
                    </div>
                    {<InputErrorBox Message={inputError.password} />}
                  </div>

                  <div className='fv-row position-relative mb-5'>
                    <label
                      className='form-label fw-bolder text-gray-900 fs-6'
                      style={{paddingRight: '20px'}}
                    >
                      Confirm Password
                    </label>
                    <div className='input-group'>
                      <input
                        type={passwordShown ? 'text' : 'password'}
                        autoComplete='off'
                        className='form-control '
                        value={confirmPassword}
                        onChange={hanldeConfirmPassword}
                      />

                      <span className='input-group-text cursor-pointer' onClick={passwordVisiblity}>
                        {passwordShown ? (
                          <FontAwesomeIcon icon={faEyeSlash} />
                        ) : (
                          <FontAwesomeIcon icon={faEye} />
                        )}
                      </span>
                    </div>
                    {confirmPasswordError && <InputErrorBox Message={confirmPasswordError} />}
                  </div>

                  <div className='d-flex justify-content-center'>
                    <Button
                      variant='filled'
                      radius='md'
                      size='md'
                      className='mt-5 mb-5 fw-bold fs-5'
                      loading={SubmitAPIStatus.loading}
                      onClick={() => {
                        const isConfirmedPasswordValid = validateConfirmPassword(confirmPassword)
                        setInputError('')
                        if (isConfirmedPasswordValid) {
                          handleSubmit(setInputError)
                        }
                      }}
                      style={{width: '350px'}}
                    >
                      Reset Password
                    </Button>
                  </div>
                  <div className='d-flex justify-content-center'>
                    <Link to='/auth'>
                      <Button
                        onClick={() => {
                          setInputError('')
                        }}
                        className='btn pt-2 pb-2'
                      >
                        <FontAwesomeIcon icon={faChevronLeft} size='sm' />
                        <span className='ms-2 fs-7'>Back To Login </span>
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default ResetPassword
