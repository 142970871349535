import Swal, {SweetAlertIcon} from 'sweetalert2'
import {ThemeModeComponent} from '../assets/ts/layout'
import {useLayout} from '../layout/core'

export const toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + pathname

export const getPaginationInfoData = (page: number, take: string, total: string, data: any) => {
  let range = ``
  let start = 0
  let end = 0
  if (page === Math.ceil(Number(total ?? 0) / Number(take))) {
    end = Number(total ?? 0)
    if (page === 1) {
      start = Number(data?.data?.length ?? 0) > 1 ? 1 : 0
    } else {
      start = end - Number(data?.data?.length ?? 0) + 1
    }
  } else if (page === 1) {
    end = Number(data?.data?.length ?? 0)
    start = Number(data?.data?.length ?? 0) > 1 ? 1 : 0
  } else if (page > 1) {
    end = Number(data?.data?.length ?? 0) * page
    start = end - Number(data?.data?.length ?? 0) + 1
  }
  console.log(page, take, total, data, end)
  range = start + ' - ' + end
  return range
}
export const statusColor = (status: string) => {
  switch (status.toLocaleLowerCase()) {
    case 'success':
    case 'approved':
    case 'accepted':
      return 'success'
    case 'warning':
    case 'pending':
      return 'warning'
    case 'error':
    case 'rejected':
      return 'danger'
    default:
      return 'info'
  }
}

export function calculateDateDifference(date1: Date, Date2: string) {
  let date2 = new Date(Date2)
  // To calculate the time difference of two dates
  let diffTime = date2.getTime() - date1.getTime()

  // To calculate the no. of days between two dates
  let diffDays = Math.ceil(diffTime / (1000 * 3600 * 24))

  return diffDays
}

export const formatAttendanceDate = (dateTimeString) => {
  const date = new Date(dateTimeString)
  // Get hours, minutes, and AM/PM indicator
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const ampm = hours >= 12 ? 'PM' : 'AM'
  // Convert hours to 12-hour format
  const formattedHours = hours % 12 || 12
  // Add leading zero to minutes if necessary
  const formattedMinutes = minutes < 10 ? '0' + minutes : minutes
  // Construct formatted time string
  const formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`
  return formattedTime
}

export function changeTextCapital(Text: string) {
  return Text.toUpperCase()
}
// EmailHelpers.js

export const sortEmailsDescending = (emails) => {
  return emails
    .slice()
    .sort((a, b) => new Date(b.createAt).getTime() - new Date(a.createAt).getTime())
}

export const changeTextCamal = (text) => {
  if (text === null || text === undefined) {
    return '-'
  }
  return text.charAt(0).toUpperCase() + text.slice(1)
}

export const changeTextSentenceCase = (text) => {
  const words = text.split(' ')

  const sentenceCaseText = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1))

  return sentenceCaseText.join(' ')
}

export const Message = (Message: string, MessageType: SweetAlertIcon) => {
  Swal.fire({
    title: Message,
    icon: MessageType,
    heightAuto: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
  })
}

export function numberFormat(number: string | number, type?: string) {
  var x: any = 0
  if (typeof number === 'string') {
    x = Number(number)
  } else {
    x = number
  }
  if (type == 'onedecimal') {
    x = Math.ceil(x * 10) / 10
  } else if (type == 'roundup') {
    x = x.toFixed(0)
  } else if (type == 'no-roundup') {
    x = x
  } else if (type == 'twodecimal') {
    x = x.toFixed(2)
  } else {
    x = x.toFixed(2)
  }

  x = x.toString()
  var afterPoint = ''
  if (x.indexOf('.') > 0) afterPoint = x.substring(x.indexOf('.'), x.length)
  x = Math.floor(x)
  x = x.toString()
  var lastThree = x.substring(x.length - 3)
  var otherNumbers = x.substring(0, x.length - 3)
  if (otherNumbers != '') lastThree = ',' + lastThree
  var res = otherNumbers.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + lastThree + afterPoint
  return res
}

export const getDate = () => {
  const date = new Date()
  let day: any = date.getDate()
  let month: any = date.getMonth() + 1
  let year = date.getFullYear()

  if (day < 10) {
    day = `0${day}`
  }
  if (month < 10) {
    month = `0${month}`
  }
  const currentDate = `${year}-${month}-${day}`
  return {currentDate}
}
export const getCustomDate = (date: string | Date) => {
  const newDate: Date = new Date(date)
  var h = newDate.getHours()
  var m = newDate.getMinutes()
  var s = newDate.getSeconds()
  var day = newDate.getDate()
  var month = newDate.getMonth() + 1
  var year = newDate.getFullYear()
  let customDate =
    ('0' + month).slice(-2) +
    '-' +
    ('0' + day).slice(-2) +
    '-' +
    year +
    ' ' +
    ('0' + h).slice(-2) +
    ':' +
    ('0' + m).slice(-2) +
    ':' +
    ('0' + s).slice(-2)
  return customDate
}

export const generateRandomText = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const textLength = 10
  let randomText = ''
  for (let i = 0; i < textLength; i++) {
    randomText += characters.charAt(Math.floor(Math.random() * characters.length))
  }
  return randomText
}
export const checkRole = (
  Roles: string[],
  features: {
    [key: string]: string[]
  }[],
  featureLevel?: string
) => {
  // console.log('features', features)
  for (let i = 0; i < features?.length; i++) {
    for (let j = 0; j < Roles.length; j++) {
      if (Object.keys(features[i]).includes(Roles[j])) {
        {
          if (featureLevel) {
            if (features[i][Roles[j]].includes(featureLevel)) return true
          } else {
            if (features[i][Roles[j]].length > 0) return true
          }
        }
      }
    }
  }
  return false
}

export const convertToAMPM = (time: string) => {
  if (time == null || time == '') {
    return null
  }
  const parts = time?.split(':') ?? '-'
  const hour = parseInt(parts[0])
  const minute = parts[1]

  const period = hour >= 12 ? 'PM' : 'AM'
  const formattedHour = hour % 12 === 0 ? 12 : hour % 12

  return `${formattedHour}:${minute} ${period}`
}

export const convertTo24HourFormat = (timeAMPM: string) => {
  const [time, period] = timeAMPM?.split(' ') ?? '-'
  const [hour, minute] = time?.split(':') ?? '-'

  let hour24 = parseInt(hour)

  if (period === 'PM' && hour24 < 12) {
    hour24 += 12
  } else if (period === 'AM' && hour24 === 12) {
    hour24 = 0
  }

  const formattedHour = String(hour24).padStart(2, '0')

  return `${formattedHour}:${minute}`
}

export const getNumber = (number: string) => {
  let number1: string = number?.toString()
  if (number1?.charAt(0) == '.') {
    number1 = '0' + number1
  }
  return Number(number1)
}

export const convertCurrency = (
  conversionCost: number | string,
  primaryCurrency: {code: string; exchangeRate: number},
  secondaryCurrency: {code: string; exchangeRate: number}
) => {
  var conversionCostVar: any = 0
  if (typeof conversionCost === 'string') {
    conversionCostVar = Number(conversionCost)
  } else {
    conversionCostVar = conversionCost
  }
  var secondaryCost = 0
  if (primaryCurrency.code === 'USD') {
    secondaryCost = conversionCostVar * secondaryCurrency.exchangeRate
  } else {
    const convert = conversionCostVar / primaryCurrency.exchangeRate
    secondaryCost = convert * secondaryCurrency.exchangeRate
  }

  return secondaryCost
}

export const clearInputError = (errorName: string, InputError: any, setInputError: Function) => {
  if (!InputError) {
    console.error('InputError object is undefined or null')
    return
  }

  if (errorName in InputError) {
    let error = {...InputError}
    delete error[errorName]
    setInputError(error)
  }
}

// export const clearInputError = (errorName: string, InputError: any, setInputError: Function) => {
//   if (errorName in InputError) {
//     let error = {...InputError}
//     delete error[errorName]
//     setInputError(error)
//   }
// }

export const HandleSelectChange = (Data: {label: string; value: string}) => {}

export const checkStatusExists = (status: any, statuses: any) => {
  for (let i = 0; i < statuses.length; i++) {
    for (let j = 0; j < status.length; j++) {
      if (statuses[i] === status[j]) {
        return true
      }
    }
  }
  return false
}
export const formatDate = (createAt) => {
  const date = new Date(createAt)
  const day = date.getDate()
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const month = monthNames[date.getMonth()]
  const year = date.getFullYear()
  let hour = date.getHours()
  const minute = date.getMinutes()
  const ampm = hour >= 12 ? 'pm' : 'am'
  hour = hour % 12 || 12

  const formattedDate = `${day} ${month} ${year}, ${hour}:${minute
    .toString()
    .padStart(2, '0')} ${ampm}`
  return formattedDate
}

// date format for project
export const dateFormat = (projectDates) => {
  const date = new Date(projectDates)
  const day = date.getDate()
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const month = monthNames[date.getMonth()]
  const year = date.getFullYear()

  return `${month} ${day},${year}`
}

// Helper function to get the day name (e.g., Tue)
const getDayName = (date) => {
  const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  return dayNames[date.getDay()]
}

// Helper function to format time (e.g., 23:42)
const formatTime = (date) => {
  const hours = date.getHours().toString().padStart(2, '0')
  const minutes = date.getMinutes().toString().padStart(2, '0')
  return `${hours}:${minutes}`
}

// Helper function to format time difference (e.g., 9 hours ago)
const formatTimeDifference = (date, currentDate) => {
  const timeDifference = Math.floor((currentDate - date) / (60 * 1000)) // in minutes

  if (timeDifference < 60) {
    return `${timeDifference} ${timeDifference === 1 ? 'minute' : 'minutes'} ago`
  } else if (timeDifference < 1440) {
    const hours = Math.floor(timeDifference / 60)
    return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`
  } else {
    const days = Math.floor(timeDifference / 1440)
    return `${days} ${days === 1 ? 'day' : 'days'} ago`
  }
}

export const formatReceiverInitials = (emailRecivers) => {
  if (!emailRecivers || emailRecivers.length === 0) {
    return ''
  }

  const firstReceiver = emailRecivers[0]
  const initials = firstReceiver.receiver.firstName?.charAt(0) || ''

  return initials.toUpperCase()
}

export const formatSenderfirstlastInitials = (sender) => {
  if (sender) {
    const firstNameInitial = sender.firstName.charAt(0).toUpperCase()
    const lastNameInitial = sender.lastName.charAt(0).toUpperCase()

    return `${firstNameInitial}${lastNameInitial}`
  }
  return ''
}

export const formatDateCell = (createdAt) => {
  const date = new Date(createdAt)
  const day = date.getDate()
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const month = monthNames[date.getMonth()]

  // Helper function to format time with AM/PM
  const formatTimeWithAMPM = () => {
    const hours = date.getHours() % 12 || 12
    const minutes = date.getMinutes().toString().padStart(2, '0')
    const ampm = date.getHours() >= 12 ? 'PM' : 'AM'
    return `${hours}:${minutes} ${ampm}`
  }

  return `${getDayName(date)}, ${day} ${month}, ${formatTimeWithAMPM()}`
}

export function isTokenExpired(token: any) {
  if (!token) return true // if no token is provided, assume it's expired
  const tokenParts = token.split('.')
  if (tokenParts.length !== 3) return true // invalid token format

  const decodedToken = JSON.parse(atob(tokenParts[1]))
  const currentTime = Math.floor(Date.now() / 1000) // convert milliseconds to seconds

  return decodedToken.exp < currentTime
}
export const useIllustrationsPath = (illustrationName: string): string => {
  const {config} = useLayout()

  const extension = illustrationName.substring(
    illustrationName.lastIndexOf('.'),
    illustrationName.length
  )
  const illustration =
    ThemeModeComponent.getMode() === 'dark'
      ? `${illustrationName.substring(0, illustrationName.lastIndexOf('.'))}-dark`
      : illustrationName.substring(0, illustrationName.lastIndexOf('.'))
  return toAbsoluteUrl(
    `/media/illustrations/${config.illustrations?.set}/${illustration}${extension}`
  )
}
