import { Button, MultiSelect } from '@mantine/core'
import { useEffect, useState } from 'react'

import axios from 'axios'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { API_BASE_URL } from '../../../utils/contants'
import { setupAxios } from '../../modules/auth'
import { getEmail, getSimpleEmail, handleSimpleMailSubmit, modules, validateFields } from './Email'
const ComposeMail = ({mailId,setActiveTab}) => {

  const [email, setEmail] = useState([
    {
      recipients: {
        to: [],
        cc: [],
        receiver: {
          id: '',
        },
      },
      sender: '',
      subject: '',
      body: '',
      isLeave: false,
      isPublish:true
    },
  ])
  const [dropdownOptions, setDropdownOptions] = useState<{label: string; value: string}[]>([])
  const initialState = {
    recipients: {
      to: [],
      cc: [],
      receiver: {
        id: '',
      },
    },
    sender: '',
    subject: '',
    body: '',
    isLeave: false,
    isPublish: true
  }
  const [inputField, setInputField] = useState(initialState)
  const [APIStatus, setAPIStatus] = useState({
    loading: false,
    error: null,
    submitLoading: false,
  })
  const [editId, setEditId] = useState(null)
  const sortedUserNames = dropdownOptions.slice().sort((a, b) => a.label.localeCompare(b.label));

  const [inputError, setInputError] = useState<any>({})
  const [SubmitAPIStatus, setSubmitAPIStatus] = useState({
    loading: false,
  })
  const handleTrashClick = () => {
    setInputField(initialState)
  }
  const fetchNames = () => {
    const {token} = setupAxios()
    axios
      .get(API_BASE_URL + `/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: any) => {
        let userData: {label: string; value: string}[] = []
        response.data.data.map((obj: {firstName: string; lastName: string; id: string}) => {
          userData.push({
            label: obj.firstName + ' ' + obj.lastName,
            value: obj.id,
          })
        })
        setDropdownOptions(userData)
      })
      .catch((error) => {
        console.error('Error fetching names:', error)
      })
  }
 
  useEffect(() => {
    
    let mounted = true
    if (mounted) {
      fetchNames()
      getSimpleEmail(mailId,setEmail,setAPIStatus)
    }
    return () => {
      mounted = false
    }
  }, [mailId])
  
  return (
    <div className='flex-lg-row-fluid ms-lg-7 ms-xl-10'>
      
          <ToastContainer position={'bottom-center'} />
      <div className='card'>
        <div className='card-header d-flex align-items-center justify-content-between py-3'>
          <h2 className='card-title m-0'>Simple Email</h2>
        </div>

        <div className='card-body p-0'>
          <div className='d-block'>
            <div className='d-flex align-items-center border-bottom ps-8 pe-5 min-h-50px d-flex'>
              <div className='text-dark fw-bold w-35px'>To:</div> ​
              <MultiSelect
                styles={{
                  input: {
                    backgroundColor: 'transparent',
                    border: 'none',
                  },
                  searchInput: {fontSize: '16px'},
                  item: {fontSize: '13px', lineHeight: '2rem'},
                  defaultValue: {height: '2rem', fontSize: '13px', borderRadius: '.5rem'},
                }}
                data={sortedUserNames}
                searchable
                value={inputField.recipients && inputField.recipients.to ? inputField.recipients.to : []}
                onChange={(selectedOptions) => {
                  const updatedToRecipients = selectedOptions
                  setInputField(
                    (prevInputField) =>
                      ({
                        ...prevInputField,
                        recipients: {
                          ...prevInputField.recipients,
                          to: updatedToRecipients,
                        },
                      }) as typeof prevInputField
                  )
                }}
                placeholder='Select..'
                style={{width: '100%'}}
              />

            </div>

            <div className='align-items-center border-bottom ps-8 pe-5 min-h-50px d-flex'>
              <div className='text-dark fw-bold w-35px'>Cc:</div>
              <MultiSelect
                styles={{
                  input: {
                    backgroundColor: 'transparent',
                    border: 'none',
                  },
                  
                  searchInput: {fontSize: '16px'},
                  item: {fontSize: '13px', lineHeight: '2rem'},
                  defaultValue: {height: '2rem', fontSize: '13px', borderRadius: '.5rem'},
                }}
                data={sortedUserNames}
                searchable
                value={inputField.recipients && inputField.recipients.cc ? inputField.recipients.cc : []}
                onChange={(selectedOptions) => {
                  const updatedToRecipients = selectedOptions
                  setInputField(
                    (prevInputField) =>
                      ({
                        ...prevInputField,
                        recipients: {
                          ...prevInputField.recipients,
                          cc: updatedToRecipients,
                        },
                      }) as typeof prevInputField
                  )
                }}
                placeholder='Select...'
                style={{width: '100%'}}
              />
            </div>

            <div className='border-bottom'>
              <input
                className='form-control form-control-transparent border-0 px-8 min-h-45px'
                name='compose_subject'
                placeholder='Subject'
                value={inputField.subject}
                onChange={(e) => {
                  setInputField((prevInputField) => ({
                    ...prevInputField,
                    subject: e.target.value,
                  }))
                }}
              />
            </div>

            <div className='align-items-center border-bottom ps-5 pe-5 min-h-150px'>
              <ReactQuill
                style={{fontSize: '13px'}}
                theme='snow'
                className='h-100px'
                value={inputField.body}
                onChange={(e) => {
                  setInputField((prevInputField) => ({
                    ...prevInputField,
                    body: e,
                  }))
                }}
                id='body'
                placeholder='Enter text...'
                modules={modules}
              />
            </div>
          </div>

          <div className='d-flex flex-stack flex-wrap gap-2 py-5 ps-8 pe-5 border-top'>
            <div className='d-flex align-items-center me-3'>
              <Button
               loaderPosition='center'
               loading={SubmitAPIStatus.loading}
                className='btn btn-sm btn-primary fs-bold px-6'
                onClick={() => {
                  handleSimpleMailSubmit(
                    {
                      ...inputField,
                      isLeave: false,
                    },
                    getEmail,
                    setEmail,
                    initialState,
                    setInputField,
                    editId,
                    setAPIStatus,
                    setInputError,
                    setSubmitAPIStatus,
                    validateFields,
                    setActiveTab
                  )
                }}
              >
                Send
              </Button>
              
            </div>
            <div className='d-flex align-items-center'>
              <span
                className='btn btn-icon btn-sm btn-light-danger btn-active-danger'
                data-inbox='dismiss'
                data-toggle='tooltip'
                title='Discard'
                onClick={handleTrashClick}
              >
                <i className='fa-solid fa-trash'></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ComposeMail
