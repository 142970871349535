import {toast} from 'react-toastify'
import {addTasksData, getTaskData} from '../../../../api/Organization'
import {Message} from '../../../../_metronic/helpers'
import {AnyObjectSchema} from 'yup'

export const handleInputChanges = (event: React.SyntheticEvent, setTaskData: Function) => {
  let target = event.target as HTMLInputElement
  setTaskData((InputField: AnyObjectSchema) => ({
    ...InputField,
    [target.name]: target.type === 'checkbox' ? target.checked : target.value,
  }))
}

export const handleOtherInputChange = (
  value: string,
  inputName: string,
  setInputField: Function
) => {
  setInputField((InputField: AnyObjectSchema) => ({
    ...InputField,
    [inputName]: value,
  }))
}

export const getTask = (
  id,
  userId,
  date,
  status,
  take,
  page,
  sortBy,
  setTasks: Function,
  setAPIStatus: Function
) => {
  setAPIStatus((prev: any) => ({...prev, loading: true}))
  getTaskData(id, userId, date, status, take, page, sortBy).then((res: any) => {
    if (res.statusCode === 200) {
      setTasks(res?.data ?? {})
      setAPIStatus((prev: any) => ({...prev, loading: false}))
    } else {
      setTasks([])
      setAPIStatus((prev: any) => ({...prev, loading: false, error: res.message}))
    }
  })
}

export const clearFilteredField = (
  setFilterOptions: Function,
  filterOptions: {
    userId: string
    status: string
    date: string
    take: number
    page: number
    sortBy: string
  }
) => {
  setFilterOptions({
    userId: '',
    status: '',
    date: '',
    take: 10,
    page: 1,
    sortBy: '',
  })
}

export const handleSubmit = (
  id,
  userId,
  date,
  status,
  take,
  page,
  sortBy,
  taskData: {
    title: string
    description: string
    startDate: string
    dueDate: string
    endDate: string
    priority: string
    // assign: string
    status: string
    level: string

    // project: {
    //   id: any
    // }
    createdBy: {
      id: any
    }
    assigneeId: {
      id: string
    }
    assignerId: {
      id: any
    }

    file: File[]

    isDefect: boolean
  },
  // setTask: Function,
  initialState: {
    title: string
    description: string
    startDate: string
    dueDate: string
    endDate: string
    priority: string
    status: string
    level: string
    // project: {
    //   id: any
    // }
    createdBy: {
      id: any
    }
    assigneeId: {
      id: string
    }
    assignerId: {
      id: any
    }

    file: File[]

    isDefect: boolean
  },
  setTaskField: Function,
  setAPIStatus: Function,
  setInputError: Function,
  setSubmitAPIStatus: Function,
  cancelSubmit: Function,
  closeAddTask: Function,
  setShowModal: Function,
  validateTaskForm: Function,
  getTask: Function,
  setTasks: Function,
  closeProject: Function,
  clearImagePreview: Function,
  isCreatingDefect: boolean
) => {
  const formType = isCreatingDefect ? 'defect' : 'task'
  let {count, error} = validateTaskForm(taskData, formType)
  setInputError(error)

  if (count === 0) {
    setSubmitAPIStatus((prev: any) => ({...prev, loading: true}))
    addTasksData(id, {...taskData, file: taskData?.file}).then((res) => {
      // console.log(res.data)
      if (res.statusCode === 200) {
        toast.success(res.message)

        getTask(id, userId, date, status, take, page, sortBy, setTasks, setAPIStatus)
        cancelSubmit(setTaskField, initialState, setInputError)
        setShowModal(false)
        closeAddTask()
        clearImagePreview()
        closeProject(false)
        setAPIStatus((prev: any) => ({...prev, loading: false}))
      } else {
        // setAPIStatus((prev: any) => ({...prev, loading: false, error: res.message}))
        Message(res.message, 'error')
      }
      setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
    })
  }
}

export const cancelSubmit = (
  setTaskData: Function,
  taskData: {
    title: string
    description: string
    startDate: string
    dueDate: string
    endDate: string
    priority: string
    status: string

    project: {
      id: any
    }
    createdBy: {
      id: any
    }
    assigneeId: {
      id: string
    }
    assignerId: {
      id: any
    }
  }
) => {
  setTaskData(taskData)
}

export const validateTaskForm = (
  taskData: {
    title: string
    description: string
    startDate: string
    dueDate: string
    priority: string
    status: string
    level: string
    assigneeId: {
      id: string
    }
  },
  formType: string
) => {
  // console.log('Form Type:', formType)
  // console.log('Form Type Condition:', formType === 'task' ? 'Task' : 'Defect')

  let count = 0
  const error: any = {}
  if (!taskData.title.trim()) {
    count = count + 1
    error.title = `Please enter a ${formType === 'defect' ? 'Defect' : 'Task'} Title.`
  } else if (taskData.title.length > 250) {
    count = count + 1
    error.title = `${
      formType === 'defect' ? 'Defect' : 'Task'
    } Title must not exceed 250 characters.`
  }
  // if (!taskData.description.trim()) {
  //   count = count + 1
  //   error.description = 'Please enter a Description.'
  // } else if (taskData.description.length > 30000) {
  //   count = count + 1
  //   error.description = 'Description must not exceed 30,000 characters.'
  // }
  // if (!taskData.startDate.trim()) {
  //   count = count + 1
  //   error.startDate = 'Please select/enter a Start Date.'
  // } else if (taskData?.startDate) {
  //   let newDate = taskData?.startDate.split('-')
  //   if (newDate[0].length > 4) {
  //     count = count + 1
  //     error.startDate = 'Please select/enter valid Year.'
  //   }
  // }
  // if (!taskData.dueDate.trim()) {
  //   count = count + 1
  //   error.dueDate = 'Please select/enter a Due Date.'
  // } else {
  //   const selectedStartDate = new Date(taskData.startDate)
  //   const selectedDueDate = new Date(taskData.dueDate)
  //   let newDate = taskData?.dueDate.split('-')

  //   if (selectedDueDate < selectedStartDate) {
  //     count = count + 1
  //     error.dueDate = 'Due Date must not be earlier than the Start Date.'
  //   }
  //   if (newDate[0].length > 4) {
  //     count = count + 1
  //     error.dueDate = 'Please select/enter valid Year.'
  //   }
  // }

  // if (!taskData.assigneeId.id) {
  //   count = count + 1
  //   error.assigneeId = 'Please select a Assignee.'
  // }

  // if (!taskData.priority.trim()) {
  //   count = count + 1
  //   error.priority = 'Please select a Priority.'
  // }
  // if (!taskData.status.trim()) {
  //   count = count + 1
  //   error.status = 'Please select a Status.'
  // }
  // if (!taskData.level.trim()) {
  //   count = count + 1
  //   error.level = 'Please select a Severity.'
  // }
  return {error, count}
}
