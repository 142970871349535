import {toast} from 'react-toastify'
import {addProject, addProjects, getProjects} from '../../../../api/Organization'
import {Message} from '../../../../_metronic/helpers/AssetHelpers'
import {AnyObjectSchema} from 'yup'
import stripTags from 'striptags'

export const handleSubmit = (
  id,
  currentUser,
  InputField: {
    projectInputField: {
      name: string
      type: string
      startDate: string
      dueDate: string
      endDate: string
      description: string
      category: string
      subCategory: string
      status: string
      createdBy: string
      projectManager: {
        id: string
      }
    }
    projectUserInputField: {
      projectUsers: {
        assigner: string
        user: string
        role: string
        status: string
        allocationPercentage: number
        startDate: string
        endDate: string
        selected: boolean
      }[]
    }
    taskInputField: {
      title: null
      description: null
      startDate: null
      dueDate: null
      priority: null
      status: string
      level: null

      createdBy: {
        id: any
      }
      assigneeId: null
      assignerId: {
        id: string | undefined
      }
    }
  },

  setProject: Function,
  initialState: {
    name: string
    type: string
    startDate: string
    dueDate: String
    endDate: string
    description: string
    category: string
    subCategory: string
    status: string
    projectManager: {
      id: string
    }
  },
  setInputField: Function,
  setAPIStatus: Function,

  setInputError: Function,

  validateForm: Function,
  setSubmitAPIStatus: Function,

  cancelSubmit: Function,
  handleFinishStep: Function
  // setIsAddProjectModalOpen: Function
  // handleNextStep: Function,
  // setProjectId: Function,
  // setProjectDueDate: Function,
  // setProjectStartDate: Function
) => {
  // let {count, error} = validateForm(InputField)
  // setInputError(error)

  // if (count === 0) {
  setSubmitAPIStatus((prev: any) => ({...prev, loading: true}))
  addProjects(currentUser, InputField).then((res) => {
    if (res.statusCode === 200) {
      toast.success(res.message)

      getProjects(id).then((projects) => {
        setProject(projects.data)
      })

      // const projectId = res.data?.id
      // const projectDueDate = res.data?.dueDate
      // const projectStartDate = res?.data?.startDate
      // handleNextStep(projectId)
      // setProjectId(projectId)
      // setProjectStartDate(projectStartDate)
      // setProjectDueDate(projectDueDate)
      cancelSubmit(setInputField, initialState, setInputError)
      // setShowModal(false)
      handleFinishStep()
      setAPIStatus((prev: any) => ({...prev, loading: false}))
    } else if (res.statusCode === 400) {
      Message(res.message, 'error')
      setAPIStatus((prev: any) => ({...prev, loading: false, messsage: res.message}))
    } else {
      const errorMessage = res.message.split(',').join('\n')

      Message(errorMessage, 'error')
      setAPIStatus((prev: any) => ({...prev, loading: false, messsage: res.message}))
    }
    setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
  })
  // }
}

export const handleInputChange = (event, setInputField) => {
  const {name, type, value, checked} = event.target
  setInputField((InputField) => ({
    ...InputField,
    [name]: type === 'checkbox' ? checked : value,
  }))
}

export const handleOtherInputChange = (
  value: string,
  inputName: string,
  setInputField: Function
) => {
  setInputField((InputField: AnyObjectSchema) => ({
    ...InputField,
    [inputName]: value,
  }))
}

// Validation Of Create New Project
export const validateForm = (InputField: {
  projectInputField: {
    name: string
    category: string
    subCategory: string
    type: string
    startDate: string
    dueDate: string
    endDate: string
    status: string
    description: string
    projectManager: {
      id: string
    }
  }
}) => {
  let count = 0
  const error: any = {}
  const descriptionWithoutTags = stripTags(InputField?.projectInputField?.description).trim()

  if (!InputField?.projectInputField?.name.trim()) {
    count = count + 1
    error.name = 'Please enter a Project Name.'
  } else if (!/^[a-zA-Z0-9\s]+$/.test(InputField?.projectInputField?.name)) {
    count = count + 1
    error.name = 'Project Name should only contain alphanumeric characters.'
  } else if (InputField?.projectInputField?.name.length > 50) {
    count = count + 1
    error.name = 'Project Name must not exceed 50 characters.'
  } else if (InputField?.projectInputField?.name.length < 2) {
    count = count + 1
    error.name = 'Project Name must be at least 2 characters.'
  }
  if (!InputField?.projectInputField?.category.trim()) {
    count = count + 1
    error.category = 'Please select a Category.'
  }
  if (!InputField?.projectInputField?.subCategory.trim()) {
    count = count + 1
    error.subCategory = 'Please select a Sub-Category.'
  }
  if (!InputField?.projectInputField?.type.trim()) {
    count = count + 1
    error.type = 'Please select a Project Type.'
  }
  if (!InputField?.projectInputField?.startDate.trim()) {
    count = count + 1
    error.startDate = 'Please select/enter a Project Start Date.'
  } else if (InputField?.projectInputField?.startDate) {
    let newDate = InputField?.projectInputField?.startDate.split('-')
    if (newDate[0].length > 4) {
      count = count + 1
      error.startDate = 'Please select/enter valid Year.'
    }
  }
  if (!InputField?.projectInputField?.dueDate.trim()) {
    count = count + 1
    error.dueDate = 'Please select/enter a Project Due Date.'
  } else {
    const selectedStartDate = new Date(InputField?.projectInputField?.startDate)
    const selectedDueDate = new Date(InputField?.projectInputField?.dueDate)
    let newDate = InputField?.projectInputField?.dueDate.split('-')

    if (selectedDueDate < selectedStartDate) {
      count = count + 1
      error.dueDate = 'Project Due Date must not be earlier than the Project Start Date.'
    }
    if (newDate[0].length > 4) {
      count = count + 1
      error.dueDate = 'Please select/enter valid Year.'
    }
  }
  if (!InputField?.projectInputField?.endDate.trim()) {
    count = count + 1
    error.endDate = 'Please select/enter a Project End Date.'
  } else {
    const selectedStartDate = new Date(InputField?.projectInputField?.startDate)
    const selectedEndDate = new Date(InputField?.projectInputField?.endDate)
    const selectedDueDate = new Date(InputField?.projectInputField?.dueDate)
    let newDate = InputField?.projectInputField?.endDate.split('-')

    if (selectedEndDate < selectedStartDate) {
      count = count + 1
      error.endDate = 'Project End Date must not be earlier than the Project Start Date.'
    }
    if (selectedDueDate > selectedEndDate) {
      count = count + 1
      error.endDate = 'Project End Date must not be earlier than the Project Due Date.'
    }
    if (newDate[0].length > 4) {
      count = count + 1
      error.endDate = 'Please select/enter valid Year.'
    }
  }
  if (!InputField?.projectInputField?.projectManager.id) {
    count = count + 1
    error.projectManager = 'Please select a Project Manager.'
  }
  if (
    !InputField?.projectInputField?.description.trim() ||
    !InputField?.projectInputField?.description.replace(/<[^>]*>/g, '')
  ) {
    count = count + 1
    error.description = 'Please enter a Project Description.'
  } else if (!descriptionWithoutTags || descriptionWithoutTags.trim().length > 500) {
    count = count + 1
    error.description = 'Project Description must not exceed 500 characters.'
    console.log('Description length:', InputField?.projectInputField?.description.trim().length)
  }
  if (!InputField?.projectInputField?.status) {
    count = count + 1
    error.status = 'Please select a Status.'
  }

  return {error, count}
}

export const cancelSubmit = (
  setInputField: Function,
  InputField: {
    name: string
    type: string
    startDate: string
    endDate: string
    description: string
  }
) => {
  setInputField(InputField)
}
