import {faEraser, faSearch} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Badge, Button, Center, Pagination, Text} from '@mantine/core'
import {useEffect, useMemo, useState} from 'react'
import {Form, Modal} from 'react-bootstrap'
import {
  KTCard,
  KTCardBody,
  Message,
  changeTextCapital,
  getDate,
  getPaginationInfoData,
} from '../../../_metronic/helpers'
import {checkIsManager, getTeamOfReportingManager} from '../../../api/AdminAPI'
import {getAttendanceApplication} from '../../../api/AttendanceAPI'
import {getHolidaysByDateRange} from '../../../api/HolidayAPI'
import {handleInputChange} from '../../CommonFunction'
import Select2 from '../../components/select2'
import {APIStatusData} from '../../data/OtherDefaultData'
import {useAuth} from '../../modules/auth'
import {APIResponse, APIStatusType, SelectValueType} from '../../types/OtherTypes'
import {formatCreateAt} from '../leave/LeaveFunctions'
import {
  getDateBeforeOneMonth,
  getDateInYYYYMMDD,
  getUserByIdForAMS,
  handleAttendanceApprove,
  handleAttendanceReject,
  handleForwardToHR,
  truncateString,
  validateDateRange,
} from './ams'
import {spawn} from 'child_process'
import {ToastContainer} from 'react-toastify'

export interface TableData extends APIStatusType {
  data: {
    id: string
    user: {
      firstName: string
      lastName: string
      hrManager: {
        id: string
        firstName: string
        lastName: string
        email: string
      }
    }
    date: string
    actualCheckIn: string
    actualcheckOut: string
    checkIn: string
    checkOut: string
    checkOutTemp: string
    createdAt: string
    checkInTemp: string
    remark: string
    reason: string
    latitude: string
    longitude: string
    outlatitude: string
    outlongitude: string
    manualAttendance: string

    reasons: [
      {
        id: string
        actor: string
        reason: string
        updatedAt: string
        actionBy: {
          id: string
          firstName: string
          lastName: string
        }
      },
    ]
  }[]
}
const Application = () => {
  const [user, setUser] = useState({
    designation: '',
    email: '',
  })
  let {currentDate} = getDate()
  const [userData, setUserData] = useState<SelectValueType[]>([])
  const [dateError, setDateError] = useState('')
  const [lessDateError, setLessDateError] = useState('')
  const [isManager, setIsManager] = useState({isReportingManager: false, isHrManager: false})
  const {currentUser} = useAuth()
  const [tableData, setTableData] = useState<TableData>({...APIStatusData, data: []})
  const initialState: {
    reason: string
    startDate: string
    endDate: string
    user: string
    status: string | null
  } = {
    reason: '',
    startDate: '',
    endDate: '',
    user: '',
    status: null,
  }
  const [inputField, setInputField] = useState(initialState)
  const [reasonModalOpen, setReasonModalOpen] = useState(false)
  const [ReasonShowModal, setReasonShowModal] = useState(false)
  const [selectedReasonId, setSelectedReasonId] = useState('')
  const [selectedItemId, setSelectedItemId] = useState('')
  const [reasonError, setReasonError] = useState('')
  const [reasonForDecline, setReasonForDecline] = useState(false)
  const [idForReason, setIdForReason] = useState('')
  const [holidayData, setHolidayData] = useState<
    {
      date: string
      holidayDay: null | string
      holidayName: string
    }[]
  >([])
  const [APIStatus, setAPIStatus] = useState({
    loading: false,
    error: null,
    submitLoading: false,
  })

  const [SubmitAPIStatus, setSubmitAPIStatus] = useState({
    loading: false,
  })

  const [showRow, setShowRow] = useState<number[]>([])
  const [expandedRowsPerPage, setExpandedRowsPerPage] = useState({})

  const checkArrayElement = (element: number) => {
    let arr: number[] = [...showRow]
    let isExists = false
    for (let i = 0; i <= arr.length - 1; i++) {
      if (arr[i] === element) {
        arr.splice(i, 1)
        isExists = true
        break
      }
    }

    if (!isExists) {
      arr.push(element)
    }
    setShowRow(arr)
  }

  const [activePage, setActivePage] = useState(1)
  const handleTrashClick = () => {
    setInputField(initialState)
    setReasonError('')
  }

  const fetchTableData = async (page: any, formData: any, isManager: any) => {
    setShowRow([])
    getHolidaysByDateRange(
      inputField.startDate && inputField.endDate
        ? inputField
        : {startDate: getDateBeforeOneMonth(), endDate: currentDate}
    ).then((res: APIResponse) => {
      if (res.statusCode) {
        setHolidayData(res.data)
      } else {
        setHolidayData([])
      }
    })
    setDateError('')
    setLessDateError('')
    getAttendanceApplication(
      currentUser?.id ?? '',
      page,
      formData.status === null
        ? !isManager.isHrManager
          ? isManager.isReportingManager
            ? {...formData, status: 'pending'}
            : formData
          : formData
        : formData
    ).then((res: APIResponse) => {
      if (res.statusCode === 200) {
        setTableData((prev) => ({
          ...prev,
          data: res.data?.result,
          queryData: res.data?.queryParams,
        }))
      } else {
        setTableData({...APIStatusData, data: []})
        Message(res?.message, 'error')
      }
    })
  }

  const fetchData = () => {
    return fetchTableData(activePage, inputField, isManager)
  }

  const handleOpenMapInNewTab = (latitude, longitude) => {
    const url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`
    window.open(url, '_blank')
  }

  useEffect(() => {
    let mounted = true
    if (mounted) {
      checkIsManager(currentUser?.id ?? '').then((res1: APIResponse) => {
        if (res1.statusCode === 200) {
          setIsManager(res1.data)
          getTeamOfReportingManager(
            currentUser?.id ?? '',
            res1?.data?.isReportingManager && res1?.data?.isHrManager
              ? 'all'
              : res1?.data?.isReportingManager
                ? 'rm'
                : res1?.data?.isHrManager
                  ? 'hr'
                  : ''
          ).then((res: APIResponse) => {
            if (res.statusCode === 200) {
              let users: SelectValueType[] = []
              res?.data?.map((obj: any) => {
                users.push({
                  label: `${obj?.firstName ?? ''} ${obj?.lastName ?? ''}`,
                  value: obj.id,
                })
              })
              setUserData(users)
            }
          })
          fetchTableData(activePage, inputField, res1.data)
        }
      })

      // getUserByIdForAMS(setUser, setAPIStatus, currentUser)
    }
    return () => {
      mounted = false
    }
  }, [])
  return (
    <>
      {/* {console.log(
        'filter holiday data',
        holidayData?.filter((item1) =>
          tableData?.data?.some((item2) => item1.date !== item2.createdAt)
        ).length,
        holidayData?.filter((item1) =>
          tableData?.data?.some((item2) => item1.date !== item2.createdAt)
        )
      )} */}

      <ToastContainer position={'bottom-center'} />

      {/* Modal to show the reasons */}
      <Modal
        show={ReasonShowModal}
        onClose={() => setReasonShowModal(false)}
        animation={false}
        backdrop='static'
        centered
        size='lg'
      >
        <div className='modal-header'>
          <h2 className='fw-bolder'>Reasons</h2>
          <div
            className='btn btn-icon btn-sm btn-active-icon-danger'
            data-kt-users-modal-action='close'
            style={{cursor: 'pointer'}}
            onClick={() => {
              setReasonShowModal(false)
            }}
          >
            <i className='fa-solid fa-xmark fs-1' />
          </div>
        </div>
        <div className='card-body'>
          <div className='table-responsive'>
            <table
              className='table ms-5 me-5 mt-5
                table-row-dashed table-row-gray-300 '
            >
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='pl-1 flex-start text-muted' style={{width: '30px'}}>
                    {changeTextCapital('Actor')}
                  </th>
                  <th className='pl-1 flex-start text-muted' style={{width: '50px'}}>
                    {changeTextCapital('Reason')}
                  </th>
                  <th className='pl-1 text-start text-muted me-2' style={{width: '50px'}}>
                    {changeTextCapital('Action By')}
                  </th>
                </tr>
              </thead>

              <tbody className='text-gray-800 fw-semibold text-left'>
                {tableData?.data
                  ?.filter((item) => item.id === selectedReasonId)
                  .map((item) =>
                    item?.reasons?.map((reason) => (
                      <tr key={reason?.id}>
                        <td className='flex-start'>{reason?.actor}</td>
                        <td className='flex-start'>{reason?.reason}</td>
                        <td className='text-start me-2'>
                          {`${reason?.actionBy?.firstName} ${reason?.actionBy?.lastName}`}
                          <div className='text-muted '>{formatCreateAt(reason?.updatedAt)}</div>
                        </td>
                      </tr>
                    ))
                  )}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
      {/* modal to take a reason to forward to HR */}
      <Modal show={reasonModalOpen} animation={false} backdrop='static' centered>
        <div className='modal-header'>
          <h2 className='fw-bolder'>Reason</h2>
          <div
            className='btn btn-icon btn-sm btn-active-icon-danger '
            data-bs-placement='top'
            title='Close'
            style={{cursor: 'pointer'}}
            onClick={() => {
              setReasonModalOpen(false)
              setInputField(initialState)
              setReasonError('')
            }}
          >
            <i className='fa-solid fa-xmark fs-1' />
          </div>
        </div>
        <Modal.Body>
          <Form>
            <Form.Group className='mb-3 d-flex align-items-center'>
              <div>
                <Form.Label>HR Manager</Form.Label>
                <div>
                  {' '}
                  <span>
                    {tableData?.data[0]?.user?.hrManager?.firstName ?? '-'}{' '}
                    {tableData?.data[0]?.user?.hrManager?.lastName ?? '-'}
                  </span>
                </div>
              </div>
            </Form.Group>
            <Form.Label className='required'>Reason</Form.Label>

            <Form.Group className='mb-3'>
              <input
                style={{padding: '7px'}}
                className='form-control'
                type='text'
                value={inputField.reason}
                name='reason'
                onChange={(e) => setInputField({...inputField, reason: e.target.value})}
              />
              {reasonError && <div className='text-danger'>{reasonError}</div>}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className='btn btn-secondary me-2 btn-sm font-weight-bold'
            onClick={handleTrashClick}
          >
            Clear
          </Button>

          <Button
            className='btn btn-primary me-2 btn-sm font-weight-bold'
            loading={SubmitAPIStatus.loading}
            onClick={() => {
              handleForwardToHR(
                selectedItemId,
                setSelectedItemId,
                fetchData,
                inputField,
                setInputField,
                initialState,
                setReasonModalOpen,
                currentUser,
                setReasonError,
                setAPIStatus
              )
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal for attendance reject */}
      <Modal show={reasonForDecline} animation={false} backdrop='static' centered>
        <div className='modal-header'>
          <h2 className='fw-bolder'>Reason</h2>
          <div
            className='btn btn-icon btn-sm btn-active-icon-danger '
            data-bs-placement='top'
            title='Close'
            style={{cursor: 'pointer'}}
            onClick={() => {
              setReasonForDecline(false)
              setInputField(initialState)
              setReasonError('')
            }}
          >
            <i className='fa-solid fa-xmark fs-1' />
          </div>
        </div>
        <Modal.Body>
          <Form>
            <Form.Label className='required'>Reason</Form.Label>
            <Form.Group className='mb-3'>
              <input
                style={{padding: '7px'}}
                className='form-control'
                type='text'
                value={inputField.reason}
                name='reason'
                onChange={(e) => setInputField({...inputField, reason: e.target.value})}
              />
              {reasonError && <div className='text-danger'>{reasonError}</div>}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className='btn btn-secondary me-2 btn-sm font-weight-bold'
            onClick={handleTrashClick}
          >
            Clear
          </Button>

          <Button
            className='btn btn-primary me-2 btn-sm font-weight-bold'
            onClick={() => {
              handleAttendanceReject(
                idForReason,
                setIdForReason,
                fetchData,
                inputField,
                setInputField,
                initialState,
                setReasonForDecline,
                currentUser,
                setReasonError
              )
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <div
        className='card my-3'
        hidden={holidayData?.every(
          (holiday) =>
            !tableData?.data?.some(
              (record) => getDateInYYYYMMDD(holiday.date) === getDateInYYYYMMDD(record.createdAt)
            )
        )}
      >
        <div className='card-body py-2'>
          <span className='fs-4 me-2'>Holidays:</span>
          {holidayData.map((holiday) => {
            return (
              <Badge
                className='me-1 fs-7 mb-1'
                color='red'
                size='xl'
                hidden={tableData?.data?.every(
                  (application) =>
                    getDateInYYYYMMDD(holiday.date) !== getDateInYYYYMMDD(application.createdAt)
                )}
              >
                {holiday.date} : {holiday.holidayName}
              </Badge>
            )
          })}
        </div>
      </div>
      <KTCard>
        <div className='card-header'>
          <div className='col-12'>
            <div className='d-flex my-5'>
              <div className='row '>
                <div className='col-md-2 mb-2 me-5'>
                  <div className='row'>
                    <div className='col-3'>
                      <input
                        style={{padding: '7px', width: '10.5rem'}}
                        className='form-control '
                        type={inputField?.startDate ? 'date' : 'text'}
                        value={inputField.startDate}
                        name='startDate'
                        placeholder='Select Start Date'
                        max={currentDate}
                        onChange={(e) => {
                          setDateError('')
                          handleInputChange(e, setInputField, {}, () => {})
                        }}
                        onFocus={(e) => {
                          if (!inputField?.startDate) {
                            e.target.type = 'date'
                          }
                        }}
                        onBlur={(e) => {
                          if (!e.target.value) {
                            e.target.type = 'text'
                          }
                        }}
                      />
                    </div>
                  </div>
                  {dateError && <span className='text-danger'>{dateError}</span>}
                </div>
                <div className='col-md-2 mb-2 me-5'>
                  <div className='row'>
                    <div className='col-3'>
                      <input
                        style={{padding: '7px', width: '10.5rem'}}
                        className='form-control  custom-select-field'
                        type={inputField?.endDate ? 'date' : 'text'}
                        value={inputField?.endDate}
                        name='endDate'
                        placeholder='Select End Date'
                        max={currentDate}
                        onChange={(e) => {
                          setLessDateError('')
                          handleInputChange(e, setInputField, {}, () => {})
                        }}
                        onFocus={(e) => {
                          if (!inputField?.endDate) {
                            e.target.type = 'date'
                          }
                        }}
                        onBlur={(e) => {
                          if (!e.target.value) {
                            e.target.type = 'text'
                          }
                        }}
                      />
                    </div>
                    {lessDateError && <span className='text-danger'>{lessDateError}</span>}
                  </div>
                </div>

                <div className='col-md-2 mb-2 me-10'>
                  <div className='row'>
                    <div className='col-3'>
                      <Select2
                        data={userData}
                        value={inputField.user}
                        placeholder='Select User'
                        onChange={(value) => {
                          setInputField((prevInputField) => ({
                            ...prevInputField,
                            user: value ?? '',
                          }))
                        }}
                        style={{width: '12rem'}}
                      />
                    </div>
                  </div>
                </div>
                <div className='col-md-2 mb-2 me-10'>
                  <div className='row'>
                    <div className='col-3'>
                      <Select2
                        data={[
                          {label: 'Approved', value: 'accept'},
                          {label: 'Pending', value: 'pending'},
                          {label: 'Declined', value: 'reject'},
                          {label: 'Pending With HR', value: 'review'},
                          {label: 'Auto Approved HR', value: 'auto_approved_by_hr'},
                          {label: 'Auto Approved RM', value: 'auto_approved_by_rm'},
                          {label: 'All', value: ''},
                        ]}
                        value={inputField.status}
                        style={{width: '12rem'}}
                        placeholder='Select Status'
                        onChange={(value) => {
                          setInputField((prevInputField) => ({
                            ...prevInputField,
                            status: value,
                          }))
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className='d-flex col-md-2 mb-3 mt-1'>
                  <Button
                    onClick={() => {
                      if (validateDateRange(inputField, setDateError, setLessDateError)) {
                        setActivePage(1)
                        fetchTableData(1, inputField, isManager)
                      }
                    }}
                    type='button'
                    className='btn btn-primary me-2 btn-sm font-weight-bold mb-2'
                  >
                    <FontAwesomeIcon icon={faSearch} />
                  </Button>{' '}
                  <Button
                    type='button'
                    className='btn btn-secondary me-2 btn-sm font-weight-bold mb-2'
                    onClick={() => {
                      setInputField(initialState)
                      fetchTableData(1, initialState, isManager)
                      setActivePage(1)
                    }}
                  >
                    <FontAwesomeIcon icon={faEraser} />
                  </Button>{' '}
                </div>
              </div>
            </div>
          </div>
        </div>
        <KTCardBody className='py-4'>
          <div className='table-responsive'>
            <table
              id='kt_table_users'
              key={tableData?.data?.length}
              className='table align-middle table-row-dashed fs-6 gy-3 dataTable'
            >
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='flex-start' style={{width: '2rem'}}></th>
                  <th className='flex-start' style={{width: '10rem'}}>
                    {changeTextCapital('Name')}
                  </th>
                  <th className='flex-start' style={{width: '8rem'}}>
                    {changeTextCapital('Date')}
                  </th>
                  <th className='text-start' style={{width: '10rem'}}>
                    {changeTextCapital('Check In')}
                  </th>
                  <th className='text-start' style={{width: '10rem'}}>
                    {changeTextCapital('Check Out')}
                  </th>
                  <th className='text-start' style={{width: '7rem'}}>
                    {changeTextCapital('Reason')}
                  </th>
                  <th className='text-start' style={{width: '7rem'}}>
                    {changeTextCapital('Status')}
                  </th>
                  <th className='text-start' style={{width: '6rem'}}>
                    {changeTextCapital('Action')}
                  </th>
                </tr>
              </thead>
              {tableData?.data?.length > 0 ? (
                <tbody>
                  {tableData?.data?.map((item, index) => (
                    <>
                      {/* {console.log('hrManagerId', item?.user?.hrManager?.id)}
                      {console.log('currentUser', currentUser?.id)} */}
                      <tr
                        className={
                          holidayData.some(
                            (holiday) =>
                              getDateInYYYYMMDD(holiday.date) === getDateInYYYYMMDD(item.createdAt)
                          )
                            ? 'text-danger'
                            : ''
                        }
                      >
                        {item?.remark === 'pending' || item?.remark === 'review' ? (
                          <td>
                            <div>
                              <span
                                className={`badge badge-light-primary d-inline-block cursor-pointer ${showRow.includes(
                                  index
                                )}`}
                                onClick={() => {
                                  checkArrayElement(index)
                                }}
                              >
                                {showRow.includes(index) ? '↑' : '↓'}
                              </span>
                            </div>
                          </td>
                        ) : (
                          <td></td>
                        )}
                        <td className='flex-start'>
                          {item?.user?.firstName ?? '-'} {item?.user?.lastName ?? '-'}
                        </td>

                        <td className='flex-start'>{item?.date ?? '-'}</td>

                        <td className='text-start'>
                          <span>
                            {' '}
                            {item?.latitude !== null && item?.longitude !== null ? (
                              <button
                                type='button'
                                className='btn btn-icon btn-light-primary btn-sm me-3'
                                onClick={() =>
                                  handleOpenMapInNewTab(item?.latitude, item?.longitude)
                                }
                              >
                                <i className='fas fa-location-dot'></i>
                              </button>
                            ) : item.manualAttendance ? (
                              ''
                            ) : (
                              <button
                                type='button'
                                className='btn btn-icon btn-light-primary btn-sm me-3'
                                disabled
                              >
                                <i className='fas fa-location-dot' style={{opacity: 0.5}}></i>
                              </button>
                            )}
                            {item?.checkIn ?? '-'}
                          </span>
                        </td>

                        <td className='text-start'>
                          <span>
                            {' '}
                            {item?.outlatitude !== null && item?.outlongitude !== null ? (
                              <button
                                type='button'
                                className='btn btn-icon btn-light-warning btn-sm me-3'
                                onClick={() =>
                                  handleOpenMapInNewTab(item?.outlatitude, item?.outlongitude)
                                }
                              >
                                <i className='fas fa-location-dot'></i>
                              </button>
                            ) : item?.manualAttendance ? (
                              '-'
                            ) : (
                              <button
                                type='button'
                                className='btn btn-icon btn-light-warning btn-sm me-3'
                                disabled
                              >
                                <i className='fas fa-location-dot' style={{opacity: 0.5}}></i>
                              </button>
                            )}
                            {item?.checkOut ?? '-'}
                          </span>
                        </td>
                        <td
                          className='text-start cursor-pointer'
                          onClick={() => {
                            setSelectedReasonId(item?.id)
                            setReasonShowModal(true)
                          }}
                        >
                          {truncateString(item?.reason ?? '-', 5)}
                        </td>
                        <td className='' style={{width: '', whiteSpace: 'pre-wrap'}}>
                          {item.remark ? (
                            <span
                              className={`badge ${
                                item?.remark === 'accept' ||
                                item?.remark === 'auto_approved_by_rm' ||
                                item?.remark === 'auto_approved_by_hr'
                                  ? 'badge-light-success'
                                  : item?.remark === 'pending'
                                    ? 'badge-light-warning'
                                    : item?.remark === 'reject'
                                      ? 'badge-light-danger'
                                      : item?.remark === 'Changed By Admin'
                                        ? 'bg-secondary'
                                        : item?.remark === 'review'
                                          ? 'badge-light-warning'
                                          : ''
                              }`}
                            >
                              {item?.remark === 'accept'
                                ? 'Approved'
                                : item?.remark === 'pending'
                                  ? 'Pending'
                                  : item?.remark === 'reject'
                                    ? 'Declined'
                                    : item?.remark === 'Changed By Admin'
                                      ? 'By Admin'
                                      : item?.remark === 'review'
                                        ? 'Pending With HR'
                                        : item?.remark === 'auto_approved_by_rm'
                                          ? 'Auto Approved RM'
                                          : item?.remark === 'auto_approved_by_hr'
                                            ? 'Auto Approved HR'
                                            : '-'}
                            </span>
                          ) : (
                            '-'
                          )}
                        </td>

                        <td>
                          {item?.remark !== 'accept' &&
                          item?.remark !== 'reject' &&
                          (item?.remark !== 'review' ||
                            currentUser?.id === item?.user?.hrManager?.id) &&
                          item?.remark !== 'auto_approved_by_hr' &&
                          item?.remark !== 'auto_approved_by_rm' ? (
                            <span>
                              <button
                                className='btn btn-icon btn-light-primary btn-sm me-1 mb-2'
                                onClick={() =>
                                  handleAttendanceApprove(item.id, fetchData, currentUser)
                                }
                              >
                                <i className='fa-solid fa-check'></i>
                              </button>

                              {isManager.isHrManager && (
                                <button
                                  className='btn btn-icon btn-light-danger btn-sm me-1 mb-2'
                                  onClick={() => {
                                    setReasonForDecline(true)
                                    setIdForReason(item?.id)
                                  }}
                                >
                                  <i className='fa-solid fa-xmark'></i>
                                </button>
                              )}

                              {!isManager.isHrManager && (
                                <button
                                  className='btn btn-icon btn-light-warning btn-sm me-1'
                                  onClick={() => {
                                    setReasonModalOpen(true)
                                    setSelectedItemId(item?.id)
                                  }}
                                >
                                  <i className='fa-solid fa-share'></i>
                                </button>
                              )}
                            </span>
                          ) : (
                            <span className='text-start'>{'-'}</span>
                          )}
                        </td>
                      </tr>

                      <tr key={`secondIndex${index}`} hidden={!showRow.includes(index)}>
                        <td colSpan={8}>
                          <>
                            <div className='table-responsive '>
                              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                <thead>
                                  <tr>
                                    <th style={{width: '2rem'}}></th>
                                    <th style={{width: '10rem'}}></th>
                                    <th style={{width: '8rem'}}></th>
                                    <th style={{width: '10rem'}}></th>
                                    <th style={{width: '10rem'}}></th>
                                    <th style={{width: '7rem'}}></th>
                                    <th style={{width: '7rem'}}></th>
                                    <th style={{width: '6rem'}}></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {tableData?.data
                                    ?.filter((detail) => detail.id === item.id)
                                    .map((detail) => (
                                      <>
                                        <tr key={`${detail?.id ?? '-'}-${detail.id}`}>
                                          <td className='text-start'></td>
                                          <td className='text-muted fw-bold'>
                                            {changeTextCapital('Previous Record')}
                                          </td>
                                          <td className=''></td>
                                          <td className=''>
                                            {item?.latitude !== null && item?.longitude !== null ? (
                                              <button
                                                type='button'
                                                className='btn btn-icon btn-light-primary btn-sm me-3'
                                                onClick={() =>
                                                  handleOpenMapInNewTab(
                                                    item?.latitude,
                                                    item?.longitude
                                                  )
                                                }
                                              >
                                                <i className='fas fa-location-dot'></i>
                                              </button>
                                            ) : item.manualAttendance ? (
                                              ''
                                            ) : (
                                              <button
                                                type='button'
                                                className='btn btn-icon btn-light-primary btn-sm me-3'
                                                disabled
                                              >
                                                <i
                                                  className='fas fa-location-dot'
                                                  style={{opacity: 0.5}}
                                                ></i>
                                              </button>
                                            )}
                                            <span className=''>{item?.actualCheckIn ?? '-'}</span>
                                          </td>
                                          <td className=''>
                                            {' '}
                                            {item?.outlatitude !== null &&
                                            item?.outlongitude !== null ? (
                                              <button
                                                type='button'
                                                className='btn btn-icon btn-light-warning btn-sm me-3'
                                                onClick={() =>
                                                  handleOpenMapInNewTab(
                                                    item?.outlatitude,
                                                    item?.outlongitude
                                                  )
                                                }
                                              >
                                                <i className='fas fa-location-dot'></i>
                                              </button>
                                            ) : item?.manualAttendance ? (
                                              '-'
                                            ) : (
                                              <button
                                                type='button'
                                                className='btn btn-icon btn-light-warning btn-sm me-3'
                                                disabled
                                              >
                                                <i
                                                  className='fas fa-location-dot'
                                                  style={{opacity: 0.5}}
                                                ></i>
                                              </button>
                                            )}
                                            <span>{item?.actualcheckOut ?? '-'}</span>
                                          </td>
                                          <td className=''></td>
                                          <td className=''></td>
                                          <td className=''></td>
                                        </tr>
                                      </>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </>
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan={8}>
                      <Center>No Record Available.</Center>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
          <div className='d-flex mt-5 justify-content-start'>
            {/* <div className='d-flex '>
                <div className='dataTables_length' id='example_length'>
                  <label className='d-flex align-items-center'>
                    <select
                      name='example_length'
                      aria-controls='example'
                      className='form-select form-select-sm'
                      onChange={(e) => {
                        const selectedPageSize = parseInt(e.target.value, 10)
                        setPageSize(selectedPageSize)
                        setCurrentPage(1)
                        fetchTableData(1, selectedPageSize)
                      }}
                    >
                      <option value='10'>10</option>
                      <option value='25'>25</option>
                      <option value='50'>50</option>
                      <option value='100'>100</option>
                    </select>
                  </label>
                </div>

                <div className=' mt-2 ms-2'>
                  {(currentPage - 1) * pageSize + 1} to{' '}
                  {Math.min(currentPage * pageSize, totalEntries)} of {totalEntries}
                </div>
              </div> */}
            {/* <Text className='me-auto'>
              {getPaginationInfoData(activePage, '10', tableData[0]?.queryData?.total ?? '', {
                data: tableData[0]?.result ?? '',
              })}{' '}
              Of {Math.ceil(Number(tableData[0]?.queryData?.total ?? 0))}
            </Text> */}

            <Text className='me-auto'>
              {getPaginationInfoData(
                activePage,
                '10',
                tableData?.queryData?.total ?? '',
                tableData
              )}{' '}
              Of {Math.ceil(Number(tableData?.queryData?.total ?? 0))}
            </Text>
            <Pagination
              value={activePage}
              size='lg'
              styles={(theme) => ({
                control: {
                  '&[data-active]': {
                    backgroundImage: theme.colorScheme,
                  },
                },
              })}
              onChange={(page) => {
                setActivePage(page)
                fetchTableData(page, inputField, isManager)
              }}
              total={Math.ceil(Number(tableData?.queryData?.total ?? 0) / Number(10))}
            ></Pagination>

            {/* <div className='d-flex justify-content-between align-items-center'>
                <div className='me-2 d-flex justify-content-end'>
                  <button
                    className={`ms-2 btn btn-icon btn-${
                      currentPage === 1 ? 'secondary' : 'light-primary'
                    } btn-sm me-1`}
                    onClick={fetchPreviousPage}
                    disabled={currentPage === 1}
                  >
                    <i className='fa-solid fa-angle-left'></i>
                  </button>

                  <button
                    className={`ms-2 btn btn-icon btn-${
                      currentPage * pageSize >= totalEntries ? 'secondary' : 'light-primary'
                    } btn-sm me-1`}
                    onClick={() => {
                      setCurrentPage((prevPage) => prevPage + 1)
                      fetchTableData(currentPage + 1, pageSize)
                    }}
                    disabled={currentPage * pageSize >= totalEntries}
                  >
                    <i className='fa-solid fa-angle-right'></i>
                  </button>
                </div>
              </div> */}
          </div>
          <></>
        </KTCardBody>
      </KTCard>
    </>
  )
}

export default Application
