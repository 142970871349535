import {toast} from 'react-toastify'
import Swal from 'sweetalert2'
import {Message} from '../../../_metronic/helpers'

import {addPolicy, deletePolicys, getPolicys} from '../../../api/PolicyAPI'
import {API_BASE_URL, DELETE_MESSAGE} from '../../../utils/contants'
import {AlertProps} from '../../data/AlertDefaults'
import {setupAxios} from '../../modules/auth'

export const getPolicy = (setPolicy: Function, setAPIStatus: Function) => {
  setAPIStatus((prev: any) => ({...prev, loading: true}))
  getPolicys().then((res: any) => {
    if (res.statusCode === 200) {
      setPolicy(res.data)
      setAPIStatus((prev: any) => ({...prev, loading: false}))
    } else {
      setAPIStatus((prev: any) => ({...prev, loading: false, error: res.message}))
    }
  })
}

export const handleSubmit = (
  InputField: {
    file: string
  },
  getPolicy: Function,
  setPolicy: Function,
  setEditId: Function,
  initialState: {
    file: string
  },
  setInputField: Function,
  editId: string | null,
  cancelSubmit: Function,
  setAPIStatus: Function,
  setInputError: Function,
  validateForm: Function,
  setSubmitAPIStatus: Function,
  setIsAddUserModalOpen: Function
) => {
  let {cnt, error} = validateForm(InputField)
  setInputError(error)
  if (cnt === 0) {
    // console.log('Input field value:', InputField)
    setSubmitAPIStatus((prev: any) => ({...prev, loading: true}))
    addPolicy(InputField).then((res) => {
      if (res.statusCode === 200) {
        toast.success(res.message)
        getPolicy(setPolicy, setAPIStatus)
        cancelSubmit(setInputField, initialState, setEditId, setInputError)
        setIsAddUserModalOpen(false)
        setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
      } else {
        Message(res.message, 'error')
        setSubmitAPIStatus((prev: any) => ({...prev, loading: false, error: res.message}))
      }
    })
  }
}

export const deletePolicy = (
  id: string,
  getPolicy: Function,
  setPolicy: Function,
  setAPIStatus: Function,
  cancelSubmit: Function,
  setInputField: Function,
  InputField: {
    file: string
  },
  setEditId: Function,

  setInputError: Function
) => {
  cancelSubmit(setInputField, InputField, setEditId, setInputError)
  Swal.fire({...AlertProps, icon: 'warning', title: DELETE_MESSAGE}).then((result: any) => {
    if (result.isConfirmed) {
      deletePolicys(id)
        .then((res: any) => {
          if (res.statusCode === 200) {
            getPolicy(setPolicy, setAPIStatus)
            toast.success(res.message)
          } else {
            Message(res.message, 'error')
          }
        })
        .catch((res: any) => {
          Message(res.message, 'error')
        })
    }
  })
}
export const handleFileChange = (inputField, setInputField, event) => {
  setInputField({
    ...inputField,
    file: event.target.files[0], // Assuming you only want to upload one file
  })
}

export const validateForm = (InputField: {file: string}) => {
  let cnt = 0
  const error: any = {}

  if (!InputField.file) {
    cnt = cnt + 1
    error.file = 'Please select a Document.'
  }
  return {error, cnt}
}
export const cancelSubmit = (
  setInputField: Function,
  InputField: {file: string},
  setEditId: Function,
  setInputError: Function,
  fileInputRef: React.RefObject<HTMLInputElement>
) => {
  setEditId(null)
  setInputField({file: ''})
  setInputError({})
  if (fileInputRef && fileInputRef.current) {
    fileInputRef.current.value = ''
  }
}

export const handleFileDownload = (url, fileName) => {
  const {token, tenantId} = setupAxios()
  fetch(API_BASE_URL + url, {
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Tenant-Id': `${tenantId}`,
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      const fileUrl = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement('a')
      link.href = fileUrl
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })
    .catch((error) => {
      console.error('Error downloading file:', error)
    })
}

export const viewFile = async (fileData, fileName, setIsViewModalOpen, setFileUrl) => {
  const uint8Array = new Uint8Array(fileData?.data)
  const fileExtension = fileName.split('.').pop()?.toLowerCase()

  if (fileExtension === 'png') {
    const blob = new Blob([uint8Array], {type: 'image/png'})
    const file = new File([blob], fileName, {type: 'image/png'})

    if (file) {
      setIsViewModalOpen(true)
      const fileUrl = URL.createObjectURL(file)
      console.log(fileUrl, fileName)
      setFileUrl(fileUrl)
    }
  } else if (fileExtension === 'pdf') {
    const blob = new Blob([uint8Array], {type: 'application/pdf'})
    const file = new File([blob], fileName, {type: 'application/pdf'})

    if (file) {
      setIsViewModalOpen(true)
      const fileUrl = URL.createObjectURL(file)
      console.log(fileUrl, fileName)
      setFileUrl(fileUrl)
    }
  } else if (fileExtension === 'txt') {
    const blob = new Blob([uint8Array], {type: 'text/plain'})
    const file = new File([blob], fileName, {type: 'text/plain'})

    if (file) {
      setIsViewModalOpen(true)
      const fileUrl = URL.createObjectURL(file)
      console.log(fileUrl, fileName)
      setFileUrl(fileUrl)
    }
  } else {
    console.error('Unsupported file type')
  }
}
