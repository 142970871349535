/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'
import {AsideMenu} from './AsideMenu'

const AsideDefault: FC = () => {
  const {classes} = useLayout()
  return (
    <div
      id='kt_aside'
      className={clsx('aside pb-5  pt-lg-0 ', classes.aside.join(' '))}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'80px', '300px': '100px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_mobile_toggle'
    >
      <div className='mx-auto'  id='kt_aside_logo'>
        <Link to='/dashboard' className='d-flex align-items-center'>
          <img src={toAbsoluteUrl('/media/logos/whitesialogo.png')} alt='logo' height={'75rem'} />
        </Link>
      </div>

      <div className='aside-menu flex-column-fluid' id='kt_aside_menu'>
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      </div>

      <div className='aside-footer flex-column-auto' id='kt_aside_footer'>
        <div className='d-flex justify-content-center'>
          <Link
            to='/changelog'
            className='d-flex align-items-center btn btm-sm btn-icon btn-active-color-primary text-info'
          >
            <KTSVG
              path={toAbsoluteUrl('/media/icons/changelog.svg')}
              className='svg-icon svg-icon-muted svg-icon-1 me-1'
            />
            v2.0.0
          </Link>
        </div>
      </div>
    </div>
  )
}

export {AsideDefault}
