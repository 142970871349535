import {Loader} from '@mantine/core'
import {FC, Suspense, useContext} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {WithChildren, checkRole} from '../../_metronic/helpers'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {Toolbar1} from '../../_metronic/layout/components/toolbar/Toolbar1'
import roleContext from '../context/roleContext'
import {ForgotPassword} from '../modules/auth/components/ForgotPassword'
import Assets from '../pages/assests/Assets'
import Attendance from '../pages/attendance/Attendace'
import ChangeLog from '../pages/changelog/ChangeLog'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import Holiday from '../pages/holiday/Holiday'
import Leave from '../pages/leave/Leave'
import ComposeMail from '../pages/mail/ComposeMail'
import Mail from '../pages/mail/Mail'
import MailLayout from '../pages/mail/MailLayout'
import Organization from '../pages/organization/Organization'
import Overviews from '../pages/organization/project/Overviews'
import ProjectOverview from '../pages/organization/project/ProjectOverview'
import Settings from '../pages/organization/settings/Settings'
import SubTaskOverview from '../pages/organization/task/SubTaskOverview'
import Task from '../pages/organization/task/Task'
import TaskOverview from '../pages/organization/task/TaskOverview'
import Users from '../pages/organization/users/Users'
import Policies from '../pages/policy/Policies'
import Profile from '../pages/profile/Profile'
import Reports from '../pages/reports/Reports'
import {RoleContextValue} from '../types/OtherTypes'

const PrivateRoutes = () => {
  let roleState: RoleContextValue = useContext(roleContext)

  console.log('in private')
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* <Route path='auth/*' element={<Navigate to='/dashboard' />} /> */}
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        {checkRole(['Attendance Management System'], roleState.features) && (
          <Route path='/attendance' element={<Toolbar1 />} />
        )}
        <Route path='/mail/:id' element={<Mail />} />
        {checkRole(
          ['User Personal Details', 'Bank Details', 'Deactivate Account'],
          roleState.features
        ) && <Route path='/profile' element={<Profile />} />}
        {checkRole(['Users Registration', 'Roles'], roleState.features) && (
          <Route path='/users' element={<Toolbar1 />} />
        )}
        {checkRole(['Holidays'], roleState.features) && (
          <Route path='/holidays' element={<Holiday />} />
        )}
        {checkRole(['Project Management'], roleState.features) && (
          <Route path='/projects' element={<Organization />} />
        )}
        {checkRole(['Project Management'], roleState.features) && (
          <Route path='/projectOverview/:id' element={<Toolbar1 />}></Route>
        )}
        {checkRole(['Project Management'], roleState.features) && (
          <Route path='/overviews' element={<Overviews />} />
        )}
        {checkRole(['Project Management'], roleState.features) && (
          <Route path='/projectOverview' element={<ProjectOverview />} />
        )}

        {checkRole(['Project Management'], roleState.features) && (
          <Route path='/user' element={<Users />}></Route>
        )}
        {checkRole(['Project Management'], roleState.features) && (
          <Route path='/task' element={<Task />}></Route>
        )}
        {checkRole(['Project Management'], roleState.features) && (
          <Route path='/project/:projectId/taskOverview/:id' element={<TaskOverview />}></Route>
        )}
        {checkRole(['Project Management'], roleState.features) && (
          <Route path='/project/:projectId/subTask/:id' element={<SubTaskOverview />}></Route>
        )}
        {checkRole(
          ['User Personal Details', 'Bank Details', 'Deactivate Account', 'Users Registration'],
          roleState.features
        ) && <Route path='/profile/:id' element={<Profile />} />}
        <Route path='/settings' element={<Settings />}></Route>
        <Route path='/compose' element={<ComposeMail mailId setActiveTab />} />
        <Route path='/changelog' element={<ChangeLog />} />
        {checkRole(['Asset Management System'], roleState.features) && (
          <Route path='/assets' element={<Assets />} />
        )}
        {checkRole(['Reports'], roleState.features) && (
          <Route path='/reports' element={<Reports />} />
        )}
        {checkRole(['Leave Management System', 'Leave Accounting'], roleState.features) && (
          <Route path='/leave' element={<Leave />} />
        )}
        {checkRole(['Attendance Management System'], roleState.features) && (
          <Route path='/ams' element={<Toolbar1 />} />
        )}
        {checkRole(['Roles'], roleState.features) && <Route path='/roles' element={<Toolbar1 />} />}
        {checkRole(['Policy'], roleState.features) && (
          <Route path='/policies' element={<Policies />} />
        )}
        {/* <Route path='/forgotpassword' element={<ForgotPassword />} /> */}

        {/* Lazy Modules */}
        {/* <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        /> */}
        <Route
          path='/attendance'
          element={
            <SuspensedView>
              <Attendance />
            </SuspensedView>
          }
        />
        <Route
          path='/emails'
          element={
            <SuspensedView>
              <MailLayout />
            </SuspensedView>
          }
        />

        <Route
          path='/forgotpassword'
          element={
            <SuspensedView>
              <ForgotPassword />
            </SuspensedView>
          }
        />

        <Route
          path='/profile'
          element={
            <SuspensedView>
              <Profile />
            </SuspensedView>
          }
        />

        <Route
          path='/mail'
          element={
            <SuspensedView>
              <Mail />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  return <Suspense fallback={<Loader />}>{children}</Suspense>
}

export {PrivateRoutes}
