import React, {HtmlHTMLAttributes} from 'react'
import {AnyObjectSchema} from 'yup'
import {getProjects, getProjectsById, updateProjectById} from '../../../../api/Organization'
import {toast} from 'react-toastify'
import stripTags from 'striptags'

export const handleInputChange = (event: React.SyntheticEvent, setInputField: Function) => {
  let target = event.target as HTMLInputElement
  setInputField((InputField: AnyObjectSchema) => ({
    ...InputField,
    [target.name]: target.type === 'checkbox' ? target.checked : target.value,
  }))
}

export const handleOtherInputChange = (
  value: string,
  inputName: string,
  setInputField: Function
) => {
  setInputField((InputField: AnyObjectSchema) => ({
    ...InputField,
    [inputName]: value,
  }))
}

export const updateProject = (
  id,
  InputField,
  currentUser,
  setProject: Function,
  setAPIStatus: Function,
  setInputError: Function,
  setProjectData: Function | undefined
) => {
  let {count, error} = validateUpdateForm(InputField)
  setInputError(error)
  if (count === 0) {
    setAPIStatus((prev: any) => ({...prev, loading: true}))
    // console.log(InputField, currentUser)
    updateProjectById(id, currentUser, InputField).then((res: any) => {
      if (res.statusCode === 200) {
        // console.log(res)
        toast.success(res.message)

        getProjectsById(id).then((res) => {
          // console.log(res.data[0])
          setProject(res.data[0] ?? {})
          setProjectData !== undefined && setProjectData(res.data[0] ?? {})
        })

        setAPIStatus((prev: any) => ({...prev, loading: false}))
      } else {
        setAPIStatus((prev: any) => ({...prev, loading: false, error: res.message}))
      }
    })
  }
}

export const validateUpdateForm = (InputField: {
  name: string
  category: string
  subCategory: string
  type: string
  startDate: string
  dueDate: string
  endDate: string
  status: string
  description: string
  projectManager: {
    id: string
  }
}) => {
  let count = 0
  const error: any = {}
  const descriptionWithoutTags = stripTags(InputField?.description).trim()

  if (!InputField.name.trim()) {
    count = count + 1
    error.name = 'Please enter a Project Name.'
  } else if (!/^[a-zA-Z0-9\s]+$/.test(InputField.name)) {
    count = count + 1
    error.name = 'Project Name should only contain alphanumeric characters.'
  } else if (InputField.name.length > 50) {
    count = count + 1
    error.name = 'Project Name must not exceed 50 characters.'
  } else if (InputField.name.length < 2) {
    count = count + 1
    error.name = 'Project Name must be at least 2 characters.'
  }
  if (!InputField.category.trim()) {
    count = count + 1
    error.category = 'Please select a Category.'
  }
  if (!InputField.subCategory.trim()) {
    count = count + 1
    error.subCategory = 'Please select a Sub-Category.'
  }
  if (!InputField.type.trim()) {
    count = count + 1
    error.type = 'Please select a Project Type.'
  }
  if (!InputField.startDate.trim()) {
    count = count + 1
    error.startDate = 'Please select/enter a Project Start Date.'
  }
  if (!InputField.dueDate.trim()) {
    count = count + 1
    error.dueDate = 'Please select/enter a Project Due Date.'
  } else {
    const selectedStartDate = new Date(InputField.startDate)
    const selectedDueDate = new Date(InputField.dueDate)
    if (selectedDueDate < selectedStartDate) {
      count = count + 1
      error.dueDate = 'Project Due Date must not be earlier than the Project Start Date.'
    }
  }
  if (!InputField.endDate.trim()) {
    count = count + 1
    error.endDate = 'Please select/enter a Project End Date.'
  } else {
    const selectedStartDate = new Date(InputField.startDate)
    const selectedEndDate = new Date(InputField.endDate)
    const selectedDueDate = new Date(InputField.dueDate)
    if (selectedEndDate < selectedStartDate) {
      count = count + 1
      error.endDate = 'Project End Date must not be earlier than the Project Start Date.'
    }
    if (selectedDueDate > selectedEndDate) {
      count = count + 1
      error.endDate = 'Project End Date must not be earlier than the Project Due Date.'
    }
  }
  if (!InputField.projectManager.id) {
    count = count + 1
    error.projectManager = 'Please select a Project Manager.'
  }
  if (!InputField.description.trim() || !InputField.description.replace(/<[^>]*>/g, '')) {
    count = count + 1
    error.description = 'Please enter a Project Description.'
  } else if (!descriptionWithoutTags || descriptionWithoutTags.trim().length > 500) {
    count = count + 1
    error.description = 'Project Description must not exceed 500 characters.'
    console.log('Description length:', InputField.description.trim().length)
  }
  if (!InputField.status) {
    count = count + 1
    error.status = 'Please select a Status'
  }

  return {error, count}
}
