import {Select, SelectProps} from '@mantine/core'
import {useState} from 'react'
import {useThemeMode} from '../../_metronic/partials'

function Select2(props: SelectProps ) {
  const {mode} = useThemeMode()
  const [searchValue, onSearchChange] = useState('')
  // console.log(props?.styles)
  const borderStyles = true
    ? {
        border: '1px solid var(--bs-gray-300)',
        borderRadius: '0.475rem',
      }
    : {
        border: 'none',
      }

  const themeStyles = {
    input: {
      backgroundColor: 'transparent',
      color: mode === 'dark' ? 'white' : 'var(--bs-gray-700)',
      ...borderStyles,
    },
    item: {
      fontSize: '13px',
      color: mode === 'dark' ? 'white' : 'black',
      '&:hover': {
        backgroundColor: mode === 'dark' ? 'var(--bs-gray-500)' : 'var(--bs-gray-200)',
      },
    },
    dropdown: {
      backgroundColor: mode === 'dark' ? '#1e1e2d' : 'white',
      ...borderStyles,
    },
  }

  return (
    <Select
  {...props}
  size='lg'
  placeholder={props.placeholder ?? 'Select...'}
  searchable
  onSearchChange={onSearchChange}
  searchValue={searchValue}
  nothingFound='No options'
  styles={
    props?.styles !== undefined
      ? props.styles
      : (theme) => ({
          ...theme,
          ...themeStyles,
          '&[data-focus]': {
            border: '1px solid red',
          },
        })
  }
/>
  )
}

export default Select2
