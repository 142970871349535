import {Center, Loader} from '@mantine/core'
import {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../modules/auth'
import {getEmail} from './Email'
import {
  formatDateCell,
  formatReceiverInitials,
  sortEmailsDescending,
} from '../../../_metronic/helpers/AssetHelpers'
import {Pagination} from 'react-bootstrap'
import {useThemeMode} from '../../../_metronic/partials'

const Sent = () => {
  const {currentUser} = useAuth()
  const {mode} = useThemeMode()
  // Change this line
  const [emails, setEmails] = useState([
    {
      id: '',
      subject: '',
      createAt: '',
      sender: {
        firstName: '',
        lastName: '',
      },
      emailRecivers: [
        {
          id: '',
          type: '',
          receiver: {
            id: '',
            firstName: '',
            lastName: '',
          },
        },
      ],
    },
  ])

  // Change this line
  const [filteredEmails, setFilteredEmails] = useState([
    {
      id: '',
      subject: '',
      createAt: '',
      sender: {
        firstName: '',
        lastName: '',
      },
      emailRecivers: [
        {
          id: '',
          type: '',
          receiver: {
            id: '',
            firstName: '',
            lastName: '',
          },
        },
      ],
    },
  ])

  const [APIStatus, setAPIStatus] = useState({
    loading: false,
    error: null,
    submitLoading: false,
  })
  const [searchTerm, setSearchTerm] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [emailsPerPage] = useState(10)
  useEffect(() => {
    let mounted = true
    if (mounted) {
      const action = 'sent'
      setAPIStatus({...APIStatus, loading: true})
      getEmail(
        currentUser,
        (fetchedEmails) => {
          const sortedEmails = sortEmailsDescending(fetchedEmails)
          setEmails(sortedEmails)
          setAPIStatus({
            loading: false,
            error: null,
            submitLoading: false,
          })
        },
        setAPIStatus,
        action
      )
    }
    return () => {
      mounted = false
    }
  }, [currentUser])
  useEffect(() => {
    const filtered = emails.filter((email) => {
      const subjectMatch = email.subject.toLowerCase().includes(searchTerm.toLowerCase())
      const receiverMatch =
        email.emailRecivers &&
        email.emailRecivers.some((receiver) =>
          `${receiver.receiver.firstName} ${receiver.receiver.lastName}`
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        )

      return subjectMatch || receiverMatch
    })

    setFilteredEmails(filtered)
  }, [emails, searchTerm])

  const indexOfLastEmail = currentPage * emailsPerPage
  const indexOfFirstEmail = indexOfLastEmail - emailsPerPage
  const currentEmails = emails.slice(indexOfFirstEmail, indexOfLastEmail)

  const paginate = (pageNumber) => setCurrentPage(pageNumber)
  return (
    <div className='flex-lg-row-fluid ms-lg-7 ms-xl-10'>
      <div className='card'>
        <div className='card-header align-items-center py-5 gap-2 gap-md-5'>
          <div className='d-flex justify-content-between'>
            <h5>Sent</h5>
          </div>

          <div className='d-flex align-items-center '>
            <input
              type='text'
              className='form-control'
              name='search'
              placeholder='Search...'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <a
              href='#'
              className='btn btn-sm btn-icon btn-color-primary btn-light btn-active-light-primary d-lg-none ms-2'
              data-bs-toggle='tooltip'
              data-bs-dismiss='click'
              data-bs-placement='top'
              id='kt_inbox_aside_toggle'
              aria-label='Toggle inbox menu'
              data-bs-original-title='Toggle inbox menu'
              data-kt-initialized='1'
            >
            <i className="fa fa-arrow-left" aria-hidden="true"></i>
            </a>
          </div>
        </div>

        <div className='card-body'>
          {APIStatus.loading ? (
            <Center>
              {/* Render your loading spinner or indicator here */}
              <Loader />
            </Center>
          ) : (
            <div id='kt_inbox_listing_wrapper' className='dataTables_wrapper dt-bootstrap4'>
              <div className='table-responsive'>
                <table
                  className='table table-hover table-row-dashed fs-6 gy-5 my-0 dataTable'
                  id='kt_inbox_listing'
                >
                  {filteredEmails.length > 0 ? (
                    <tbody>
                      {filteredEmails
                        .slice(indexOfFirstEmail, indexOfLastEmail)
                        .map((obj: any, index: number) => (
                          <tr className='odd' key={obj?.id}>
                            <td className='w-150px w-md-175px'>
                              <Link
                                to={`/mail/${obj?.id}`}
                                className='d-flex align-items-center text-dark'
                              >
                                <div className='symbol symbol-35px ms-2 me-3'>
                                  <div className='symbol-label bg-light-danger'>
                                    <span className='text-danger'>
                                      {formatReceiverInitials(obj?.emailRecivers)}
                                    </span>
                                  </div>
                                </div>

                                {obj?.emailRecivers ? (
                                  <span className='fw-semibold'>
                                    {obj?.emailRecivers?.find((receiver) => receiver.type === 'to')
                                      ?.receiver?.firstName ?? '-'}{' '}
                                    {obj?.emailRecivers?.find((receiver) => receiver.type === 'to')
                                      ?.receiver?.lastName ?? '-'}
                                  </span>
                                ) : (
                                  '-'
                                )}
                              </Link>
                            </td>
                            <td>
                              <div className='text-dark gap-1 pt-2'>
                                <Link to={`/mail/${obj?.id}`} className='text-dark'>
                                  <span className='fw-bold'>{obj?.subject ?? '-'}</span>
                                  <span className='fw-bold d-none d-md-inine'> - </span>
                                </Link>
                              </div>
                            </td>
                            <td className='w-250px text-dark text-end fs-7 pe-2 pt-8'>
                              <span className='fw-bold text-dark'>
                                {formatDateCell(obj?.createAt ?? '-')}
                              </span>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan={4}>
                          <Center>No Record Available.</Center>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>

                <div className='d-flex justify-content-between mt-4'>
                  <div className='text-muted'>
                    {indexOfFirstEmail + 1}-{Math.min(indexOfLastEmail, emails.length)} of{' '}
                    {emails.length}
                  </div>
                  <Pagination>
                    <Pagination.Prev
                      onClick={() => setCurrentPage(currentPage - 1)}
                      disabled={currentPage === 1}
                    />
                    <Pagination.Next
                      onClick={() => setCurrentPage(currentPage + 1)}
                      disabled={currentPage === Math.ceil(emails.length / emailsPerPage)}
                    />
                  </Pagination>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Sent
