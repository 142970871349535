import {Button, rem} from '@mantine/core'
import React, {useEffect, useState} from 'react'
import PieCharts from '../charts/PieCharts'
import {useParams} from 'react-router'
import {clearDateField, getTaskByUsers} from './OverviewFunction'
import {useAuth} from '../../../modules/auth'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCalendarDays, faEraser} from '@fortawesome/free-solid-svg-icons'
import {Link, useNavigate} from 'react-router-dom'
import {Completed, In_Progress, On_Hold, Todo} from '../../../../utils/contants'

const Overview = () => {
  const params = useParams()
  const {currentUser} = useAuth()
  const navigate = useNavigate()

  const [filterTasks, setFilterTasks] = useState({
    date: new Date().toISOString().split('T')[0],
    status: '',
  })

  const [APIStatus, setAPIStatus] = useState({
    loading: false,
    error: null,
    submitLoading: false,
  })

  const [tasks, setTasks] = useState([
    {
      id: '',
      title: '',
      startDate: '',
      dueDate: '',
      endDate: '',
      status: '',
      assigneeId: {
        id: '',
        firstName: '',
        lastName: '',
      },
      assignerId: {
        id: '',
        firstName: '',
        lastName: '',
      },
    },
  ])

  const handleFilterChange = (filterName, value) => {
    setFilterTasks((prevOptions) => ({
      ...prevOptions,
      [filterName]: value,
    }))
  }

  useEffect(() => {
    let mounted = true
    if (mounted) {
      getTaskByUsers(
        params?.id,
        currentUser?.id,
        filterTasks?.date,
        filterTasks?.status,
        setTasks,
        setAPIStatus
      )
    }
    return () => {
      mounted = false
    }
  }, [filterTasks])

  return (
    <>
      {/* <div>
        {' '}
        <ProjectOverview />{' '}
      </div> */}

      <div className='row'>
        <div className='col-md-6'>
          <PieCharts />
        </div>

        {/* Dashboard */}
        <div className='col-md-6'>
          <div className='card mb-5'>
            <div className='card-header mt-4'>
              <div className='card-title flex-column'>
                <h3 className='fw-bold mb-1'>Dashboard</h3>
                <div className='fs-6 text-gray-400'> Welcome back Stay productive</div>
              </div>
              <div className='d-flex mt-5'>
                <div>
                  <input
                    type='date'
                    name='date'
                    value={filterTasks?.date}
                    className='form-control form-sm form-solid'
                    onChange={(event) => {
                      // console.log('selected Date', event.target.value)
                      handleFilterChange('date', event.target.value)
                    }}
                  />
                </div>
                <div className='ms-2 mt-2'>
                  <button
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    onClick={() => {
                      clearDateField(setFilterTasks, filterTasks)
                    }}
                  >
                    <FontAwesomeIcon icon={faEraser} />
                  </button>
                </div>
              </div>
            </div>
            {tasks?.length > 0 ? (
              <div className='card-body p-9 pt-4' style={{maxHeight: '300px', overflowY: 'scroll'}}>
                {tasks?.map((obj) => {
                  return (
                    <div
                      id='kt_schedule_day_0'
                      className='tab-pane fade show'
                      role='tabpanel'
                      key={obj?.id}
                    >
                      <div className='d-flex flex-stack position-relative mt-8'>
                        <div
                          className={`position-absolute h-100 w-4px rounded top-0 start-0 ${
                            obj?.status === Todo
                              ? 'bg-primary'
                              : obj?.status === In_Progress
                              ? 'bg-info'
                              : obj?.status === Completed
                              ? 'bg-success'
                              : obj?.status === On_Hold
                              ? 'bg-warning'
                              : 'bg-danger'
                          }`}
                        ></div>
                        <div className='fw-semibold ms-5 text-gray-600'>
                          <a
                            className='fs-5 fw-bold text-gray-800 text-hover-primary mb-2'
                            style={{cursor: 'pointer'}}
                            onClick={() => {
                              navigate(`/project/${params?.id}/taskOverview/${obj?.id}`)
                            }}
                          >
                            {obj?.title ?? '-'}{' '}
                          </a>
                          <div className='text-gray-400'>
                            Assign By{' '}
                            <a className='me-3'>
                              {obj?.assignerId?.firstName ?? '-'} {obj?.assignerId?.lastName ?? '-'}
                            </a>
                            Assign To{' '}
                            <a className='me-3'>
                              {obj?.assigneeId?.firstName ?? '-'} {obj?.assigneeId?.lastName ?? '-'}
                            </a>
                          </div>
                        </div>

                        <Button
                          variant='subtle'
                          onClick={() => {
                            navigate(`/project/${params?.id}/taskOverview/${obj?.id}`)
                          }}
                        >
                          View
                        </Button>
                      </div>
                    </div>
                  )
                })}
              </div>
            ) : (
              <>
                <div className='fw-bold text-gray-600 text-center mt-7 mb-5'>No Record Found</div>
                <div className='text-center'>
                  <img
                    src='/media/avatars/20-dark.png'
                    alt='noReacordAvailable'
                    className='w-70 h-100px h-sm-325px'
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Overview
