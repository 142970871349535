import {useContext, useEffect, useState} from 'react'
import Chart from 'react-apexcharts'
import {Col, Row} from 'react-bootstrap'
import {checkRole} from '../../../_metronic/helpers/AssetHelpers'
import roleContext from '../../context/roleContext'
import {useAuth} from '../../modules/auth'
import {RoleContextValue} from '../../types/OtherTypes'
import Applications from './Applications'
import LeaveAccount from './LeaveAccount'
import {getLeaveBalances, getPieChartData} from './LeaveFunctions'
import MyLeave from './MyLeave'

const Leave = () => {
  const {currentUser} = useAuth()
  let roleState: RoleContextValue = useContext(roleContext)
  const [APIStatus, setAPIStatus] = useState({
    loading: false,
    error: null,
    submitLoading: false,
  })

  const [balance, setBalance] = useState({
    users: {
      id: '',
      totalLeave: '',
      sickLeave: '',
      earnedLeave: '',
      optionalLeave: '',
      wfh: '',
      unpaidLeave: '',
    },
    currentWeekWFH: '',
  })
  const [piechartData, setPieChartDate] = useState({
    consumeSickLeave: '',
    consumeEarnLeave: '',
    consumeOptionalLeave: '',
    consumeTotalLeave: '',
    consumedWorkFromHome: '',
    AvailableLeave: '',
    consumeUnpaidLeave: '',
  })

  const [activeTab, setActiveTab] = useState(
    checkRole(['Leave Management System'], roleState.features) ? 'myLeave' : 'leaveaccount'
  )

  const handleTabChange = (tab) => {
    setActiveTab(tab)
  }
  useEffect(() => {
    let mounted = true
    if (mounted) {
      getLeaveBalances(currentUser, setBalance, setAPIStatus)
      getPieChartData(currentUser, setPieChartDate, setAPIStatus)
    }
    return () => {
      mounted = false
    }
  }, [])

  return (
    <>
      <Row>
        <Col md={4}>
          <div className='card h-80 '>
            <div className='card-header' style={{minHeight: '3rem'}}>
              <div className='card-title'>
                {Number(piechartData?.consumeTotalLeave) === 0
                  ? 'Total Availed '
                  : `Total Availed(${piechartData.consumeTotalLeave})`}
              </div>
            </div>

            <div className='align-items-center mt-2'>
              {Number(piechartData.consumeTotalLeave) === 0 ? (
                <>
                  <div className='ms-2 mt-2 mb-2 me-2'>
                    <div className='fw-bold text-gray-600 text-center  mb-2'>No Data Available</div>
                    <div className='text-center'>
                      <img
                        src='/media/avatars/19-dark.png'
                        alt='noReacordAvailable'
                        style={{width: '110px', height: '85px'}}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <Chart
                  type='donut'
                  width='290'
                  height='290'
                  series={[
                    Number(piechartData.consumeEarnLeave) || 0,
                    Number(piechartData.consumeSickLeave) || 0,
                    Number(piechartData.consumeOptionalLeave) || 0,
                    Number(piechartData.consumeUnpaidLeave) || 0,
                    Number(piechartData.consumedWorkFromHome) || 0,
                  ]}
                  options={{
                    labels: [`Earned Leave`, `Sick Leave`, `Optional Leave`, `Unpaid Leave`, `WFH`],
                    colors: ['#128BFF', '#FCBA18', '#d633ff', '#FF0000', '#9FA6B2'],
                    noData: {text: 'empty data'},
                    stroke: {
                      width: 0.5,
                    },
                    legend: {
                      show: true,
                      fontSize: '12px',
                      horizontalAlign: 'center',
                      position: 'right',
                      fontFamily: 'Arial, sans-serif',
                      markers: {
                        width: 6,
                        height: 6,
                      },
                      labels: {
                        colors: 'primary',
                      },
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    plotOptions: {
                      pie: {
                        donut: {
                          size: '70%',
                        },
                      },
                    },
                    responsive: [
                      {
                        breakpoint: 1000,
                        options: {
                          plotOptions: {
                            pie: {
                              horizontal: false,
                              dataLabels: {
                                offset: -5,
                              },
                            },
                          },
                          legend: {
                            show: true,
                            position: 'bottom',
                          },
                          chart: {
                            position: 'left',
                          },
                        },
                      },
                    ],
                  }}
                />
              )}
            </div>
          </div>
        </Col>
        <Col md={6}>
          <div className='card mb-5 mb-xl-10 h-70' id='kt_profile_details_view'>
            <div className='card-header' style={{minHeight: '3rem'}}>
              <div className='card-title'>Leave Available</div>
            </div>
            <div className='card-body align-items-center '>
              <div className='row'>
                <div className='col-md-3  text-gray-300 h-80px' style={{borderRight: '1px solid '}}>
                  <div className=' mt-5 text-lg-center'>
                    <div className='fs-3 fw-semibold counted  '>
                      <span className='text-gray-800'> {balance?.users?.totalLeave ?? '-'}</span>
                    </div>
                    <div className='mt-3 text-muted'>Total Leaves</div>
                  </div>
                </div>
                <div className='col-md-3  text-gray-300' style={{borderRight: '1px solid '}}>
                  <div className=' mt-5 text-lg-center'>
                    <div className='fs-3 fw-semibold counted  mt-2'>
                      <span className='text-gray-900'>{balance?.users?.earnedLeave ?? '-'}</span>
                    </div>
                    <div className='mt-3 text-muted'>Earned Leaves</div>
                  </div>
                </div>
                <div className='col-md-3  text-gray-300' style={{borderRight: '1px solid '}}>
                  <div className=' mt-5 text-lg-center'>
                    <div className='fs-3 fw-semibold counted  mt-2'>
                      <span className='text-gray-900'>{balance?.users?.sickLeave ?? '-'}</span>
                    </div>
                    <div className='mt-3 text-muted'>Sick Leave</div>
                  </div>
                </div>

                <div className='col-md-3  text-gray-300'>
                  <div className='mt-5 text-lg-center'>
                    <div className='fs-3 fw-semibold counted  mt-2'>
                      <span className='text-gray-900'>{balance?.users?.optionalLeave ?? '-'}</span>
                    </div>
                    <div className='mt-3 text-muted'>Optional Leaves</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>

        <Col md={2}>
          <div className='card mb-5 mb-xl-10 ' id='kt_profile_details_view'>
            <div className='card-header' style={{minHeight: '3rem'}}>
              <div className='card-title fs-6'>WFH Available</div>
            </div>

            <div className=''>
              <div className=' mt-13 mb-5 text-lg-center'>
                <div className='fs-3 fw-semibold counted  '>{balance?.users?.wfh ?? '-'}</div>
                <div className=' mt-2 text-muted'>Work From Home</div>
                <div className='text-muted'>{`(Monthly)`}</div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <ul className='nav nav-tabs ms-3 me-3 '>
        {checkRole(['Leave Management System'], roleState.features) && (
          <li className='nav-item cursor-pointer '>
            <a
              className={`nav-link ${activeTab === 'myLeave' ? 'active' : ''}`}
              onClick={() => handleTabChange('myLeave')}
            >
              My Leave
            </a>
          </li>
        )}
        {checkRole(['Leave Management System'], roleState.features) && (
          <li className='nav-item cursor-pointer'>
            <a
              className={`nav-link ${activeTab === 'applications' ? 'active' : ''}`}
              onClick={() => handleTabChange('applications')}
            >
              Applications
            </a>
          </li>
        )}
        {checkRole(['Leave Accounting'], roleState.features) && (
          <li className='nav-item cursor-pointer'>
            <a
              className={`nav-link ${activeTab === 'leaveaccount' ? 'active' : ''}`}
              onClick={() => handleTabChange('leaveaccount')}
            >
              Leave Account
            </a>
          </li>
        )}
      </ul>

      <div className='tab-content p-3'>
        {activeTab === 'myLeave' && (
          <MyLeave
            getLeaveBalances={getLeaveBalances}
            setBalance={setBalance}
            getPieChartData={getPieChartData}
            setPieChartDate={setPieChartDate}
          />
        )}
        {activeTab === 'applications' && (
          <Applications getLeaveBalances={getLeaveBalances} setBalance={setBalance} />
        )}
        {activeTab === 'leaveaccount' && (
          <LeaveAccount getLeaveBalances={getLeaveBalances} setBalance={setBalance} />
        )}
      </div>
    </>
  )
}

export default Leave
