import {useEffect, useState} from 'react'
import RoleContext from './roleContext'
import {RoleContextValue} from '../types/OtherTypes'

const RoleState = (props: {children: any}) => {
 

  const [features, setFeatures] = useState<RoleContextValue['features']>([])
  const updateState = ( features: RoleContextValue['features']) => {

    setFeatures(features)
  }

  useEffect(() => {
    const firstRole: string | null = localStorage.getItem('currentUserRole')
    if (firstRole) {
      const Role: {
        roleName: string

        features: RoleContextValue['features']
      } = JSON.parse(firstRole)
      if (Role !== null) {

        setFeatures(Role.features)
      }
    }
  }, [])

  return (
    <>
      <RoleContext.Provider value={{ features, updateState}}>
        {props.children}
      </RoleContext.Provider>
    </>
  )
}

export default RoleState
