import {useState} from 'react'
import ReactApexChart from 'react-apexcharts'

const TreeMapChart = () => {
  const [selectedStatus, setSelectedStatus] = useState('')

  // Example function to get data for the treemap chart
  const getDataForTreeMapChart = () => {
    // Return your data here (array of objects)
    return [
      {x: 'Todo', y: 10},
      {x: 'In Progress', y: 20},
      {x: 'On Hold', y: 30},
      {x: 'Completed', y: 40},
    ]
  }

  return (
    <div>
      <div className='card me-2 me-sm-3 me-md-5 me-lg-5 mb-3 mb-sm-4 mb-md-5 mb-lg-5 p-0'>
        <div className='card-header' style={{minHeight: '3rem'}}>
          <div className='card-title d-flex flex-column fw-bold'>
            Application Availability Metrics
          </div>
        </div>
        <div className='card-body ps-10 pe-lg-0 pe-7 py-0'>
          <ReactApexChart
            type='treemap'
            options={{
              legend: {
                show: false,
              },
              noData: {
                text: 'No Data Available',
              },

              plotOptions: {
                treemap: {
                  distributed: true,
                  enableShades: false,
                },
              },
              theme: {mode: 'light'}, // Set your theme mode here (light/dark)
              title: {text: ''},
              chart: {
                // background: 'var(--kt-card-bg)',
                height: getDataForTreeMapChart().length > 0 ? 280 : 50,
                width: '100rem',
                toolbar: {show: false},
                type: 'treemap',
              },
              dataLabels: {
                enabled: true,
                style: {
                  fontSize: '12px',
                },
                formatter: function (text, op) {
                  return op.value === 0 ? '' : `${text},\n ${op.value}`
                },
                offsetY: -4,
              },
            }}
            height={getDataForTreeMapChart().length > 0 ? '165rem' : 50}
            series={[
              {
                data: getDataForTreeMapChart(),
              },
            ]}
            events={{
              dataPointSelection: (e, chart, options) => {
                const selectedData =
                  options.w.config.series[options.seriesIndex].data[options.dataPointIndex]
                setSelectedStatus(selectedData.x)
                // Perform any additional actions on data point selection
              },
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default TreeMapChart
