import axios from 'axios'
import {useEffect, useState} from 'react'
import {Col, Modal, Row} from 'react-bootstrap'
import {API_BASE_URL} from '../../../utils/contants'
import InputErrorBox from '../../components/InputErrorBox'
import Select2 from '../../components/select2'
import {setupAxios, useAuth} from '../../modules/auth'
import {cancelSubmit, getUser, handleInputChange, handleSubmit, validateForm} from './UserFunction'

const CreateUser = ({closeModal, user, setUser, fetchUsers}) => {
  const {currentUser} = useAuth()

  const [editId, setEditId] = useState(null)
  const [inputError, setInputError] = useState<any>({})
  const [SubmitAPIStatus, setSubmitAPIStatus] = useState({
    loading: false,
  })
  const initialState = {
    firstName: '',
    lastName: '',
    email: '',
    contact: '',
    password: '',
    dob: '',
    gender: '',
    employmentType: '',
    designation: '',
    startDate: '',
    endDate: '',
    role: '',
    inTime: '',
    outTime: '',
    reportingManager: '',

    personalEmail: '',
    hr: '',
  }

  const [inputField, setInputField] = useState(initialState)
  const [usersOptions, setUsersOptions] = useState<{label: string; value: string}[]>([])
  const sortedUserNames = usersOptions.slice().sort((a, b) => a.label.localeCompare(b.label))

  const [role, setURoles] = useState<{label: string; value: string}[]>([])
  const sortedRoles = role.slice().sort((a, b) => a.label.localeCompare(b.label))
  const fetchRoles = () => {
    const {token, tenantId} = setupAxios()
    axios
      .get(API_BASE_URL + `/roles`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-Tenant-Id': `${tenantId}`,
        },
      })
      .then((response: any) => {
        let userData: {label: string; value: string}[] = []

        response.data.data.map((obj: {name: string; id: string}) => {
          userData.push({
            label: obj.name,
            value: obj.id,
          })
        })

        setURoles(userData)
      })
      .catch((error) => {
        console.error('Error fetching names:', error)
      })
  }
  const fetchuser = () => {
    const {token, tenantId} = setupAxios()
    axios
      .get(API_BASE_URL + `/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-Tenant-Id': `${tenantId}`,
        },
      })
      .then((response: any) => {
        let userData: {label: string; value: string}[] = []

        response.data.data.map((obj: {email: string; id: string}) => {
          userData.push({
            label: obj.email,
            value: obj.id,
          })
        })

        setUsersOptions(userData)
      })
      .catch((error) => {
        console.error('Error fetching names:', error)
      })
  }
  const [hr, setHr] = useState<{label: string; value: string}[]>([])
  const sortedHr = hr.slice().sort((a, b) => a.label.localeCompare(b.label))
  const fetchHr = () => {
    const {token, tenantId} = setupAxios()
    axios
      .get(API_BASE_URL + `/users/hr`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-Tenant-Id': `${tenantId}`,
        },
      })
      .then((response: any) => {
        let userData: {label: string; value: string}[] = []

        userData = response.data.data.map(
          (obj: {firstName: string; lastName: string; id: string}) => ({
            label: `${obj.firstName} ${obj.lastName}`,
            value: obj.id,
          })
        )

        setHr(userData)
      })
      .catch((error) => {
        console.error('Error fetching names:', error)
      })
  }
  // const [user, setUser] = useState({
  //   id: '',
  //   firstName: '',
  //   lastName: '',
  //   email: '',
  //   contact: '',
  //   password: '',
  //   dob: '',
  //   gender: '',
  //   employmentType: '',
  //   designation: '',
  //   startDate: '',
  //   endDate: '',
  //   inTime: '',
  //   outTime: '',
  //   reportingManager: {
  //     firstName: '',
  //     lastName: '',
  //   },

  //   personalEmail: '',
  // })
  const [APIStatus, setAPIStatus] = useState({
    loading: false,
    error: null,
  })
  const [] = useState<any>({})

  const handleClose = () => {
    closeModal()
    setInputError('')
    setInputField(initialState)
  }

  useEffect(() => {
    let mounted = true
    if (mounted) {
      fetchuser()
      fetchRoles()
      fetchHr()
      // getUser(setUser, setAPIStatus)
      // fetchUsers()
    }
    // if (!SubmitAPIStatus.loading) {
    //   fetchuser()
    // }

    return () => {
      mounted = false
    }
  }, [SubmitAPIStatus.loading])

  return (
    <>
      {/* Add user modal form */}
      <Modal
        className='modal-lg'
        show={true}
        animation={false} // Disable the default modal animation
        backdrop='static' // Keep the backdrop open when clicking outside the modal
        onHide={handleClose}
      >
        {/* add user heading-modal header*/}
        <div className='modal-header'>
          <h2 className='fw-bolder'>Add User</h2>
          <div
            className='btn btn-icon btn-sm btn-active-icon-danger'
            data-bs-placement='top'
            title='Close'
            data-kt-users-modal-action='close'
            onClick={handleClose}
            style={{cursor: 'pointer'}}
          >
            <i className='fa-solid fa-xmark fs-1' />
          </div>
        </div>
        {/*begin::Form*/}
        <div className='m-3'>
          {/* fname lname */}
          <Row className='mb-4 m-3'>
            <Col md={5}>
              <label className='required fw-bold fs-6 mb-2'>First Name</label>
              <input
                placeholder='First Name'
                className='form-control'
                type='text'
                name='firstName'
                autoComplete='off'
                value={inputField.firstName}
                onChange={(event) => handleInputChange(event, setInputField, inputField)}
              />
              {<InputErrorBox Message={inputError.firstName} />}
            </Col>

            <Col md={1}></Col>
            <Col md={5}>
              <label className='required fw-bold fs-6 mb-2'>Last Name</label>
              <input
                placeholder='Last Name'
                className='form-control'
                type='text'
                name='lastName'
                value={inputField.lastName}
                onChange={(event) => handleInputChange(event, setInputField, inputField)}
              />
              {<InputErrorBox Message={inputError.lastName} />}
            </Col>
          </Row>

          {/* email personalemail */}
          <Row className='mb-4 m-3'>
            <Col md={5}>
              <label className='required fw-bold fs-6 mb-2'> Organization Email</label>
              <input
                className='form-control'
                placeholder='Organization Email'
                type='email'
                name='email'
                value={inputField.email}
                onChange={(event) => handleInputChange(event, setInputField, inputField)}
              />
              {<InputErrorBox Message={inputError.email} />}
            </Col>

            <Col md={1}></Col>
            <Col md={5}>
              <label className='required fw-bold fs-6 mb-2'>Personal Email</label>
              <input
                className='form-control'
                placeholder='Personal Email'
                type='email'
                name='personalEmail'
                value={inputField.personalEmail}
                onChange={(event) => handleInputChange(event, setInputField, inputField)}
              />
              {<InputErrorBox Message={inputError.personalEmail} />}
            </Col>
          </Row>
          {/* gender dob */}
          <Row className='mb-4 m-3'>
            <Col md={5}>
              <label className='required fw-bold fs-6 mb-2'>Gender</label>
              <div className='d-flex mt-2'>
                <div className='form-check'>
                  <input
                    className='form-check-input'
                    type='radio'
                    id='male'
                    name='gender'
                    value='male'
                    checked={inputField.gender === 'male'}
                    onChange={(event) => {
                      handleInputChange(event, setInputField, inputField)
                    }}
                  />
                  <label htmlFor='male' className='fw-bold text-gray-800 d-block me-3'>
                    Male
                  </label>
                </div>

                <div className='form-check' style={{paddingRight: '20px'}}>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='gender'
                    placeholder='radio1'
                    value='female'
                    id='female'
                    checked={inputField.gender === 'female'}
                    onChange={(event) => {
                      handleInputChange(event, setInputField, inputField)
                    }}
                  />

                  <label htmlFor='female' className=' fw-bold text-gray-800 d-block'>
                    Female
                  </label>
                </div>
              </div>
              <InputErrorBox Message={inputError.gender} />
            </Col>

            <Col md={1}></Col>
            <Col md={5}>
              <label className='required fw-bold fs-6 mb-2'>DOB</label>
              <input
                className='form-control'
                type='date'
                name='dob'
                value={inputField.dob}
                onChange={(event) => handleInputChange(event, setInputField, inputField)}
              />
              {<InputErrorBox Message={inputError.dob} />}
            </Col>
          </Row>

          {/* employee type and designation */}

          <Row className='mb-4 m-3'>
            <Col md={5}>
              <label className='required fw-bold fs-6 mb-2'>Employment Type</label>
              <div className='col-12'>
                <Select2
                  name='employmentType'
                  data={[
                    {label: 'Employee', value: 'Employee'},
                    {label: 'Intern', value: 'Intern'},
                    {label: 'Probation', value: 'Probation'},
                  ].sort((a, b) => a.label.localeCompare(b.label))}
                  value={inputField.employmentType}
                  onChange={(selectedOption: any) => {
                    console.log(selectedOption)
                    setInputField((prevInputField) => ({
                      ...prevInputField,
                      employmentType: selectedOption,
                    }))
                  }}
                />
                {<InputErrorBox Message={inputError.employmentType} />}
              </div>
            </Col>

            <Col md={1}></Col>
            <Col md={5}>
              <label className='required fw-bold fs-6 mb-2'>Designation</label>
              <div className='col-12'>
                <Select2
                  name='designation'
                  data={[
                    {label: 'Test Engineer (Manual QA)', value: 'Test Engineer (Manual QA)'},
                    {label: 'Jr. Manual Tester', value: 'Jr. Manual Tester'},
                    {label: 'Jr. Automation Tester', value: 'Jr. Automation Tester'},
                    {label: 'DevOps Support Engineer', value: 'DevOps Support Engineer'},
                    {label: 'Sr. Software Engineer', value: 'Sr. Software Engineer'},
                    {label: 'Jr. Software Engineer', value: 'Junior Software Engineer'},
                    {label: 'Business Analyst', value: 'Business Analyst'},
                    {label: 'HR Executive', value: 'HR Executive'},
                    {label: 'FullStack Developer', value: 'FullStack Developer'},
                    {label: 'Project Manager', value: 'Project Manager'},
                    {label: 'Admin', value: 'Admin'},
                  ].sort((a, b) => a.label.localeCompare(b.label))}
                  value={inputField.designation}
                  onChange={(selectedOption: any) => {
                    console.log(selectedOption)
                    setInputField((prevInputField) => ({
                      ...prevInputField,
                      designation: selectedOption,
                    }))
                  }}
                />
                <InputErrorBox Message={inputError.designation} />
              </div>
            </Col>
          </Row>
          {/* INTIME AND OUTTIME */}
          <Row className='mb-4 m-3'>
            <Col md={5}>
              <label className='required fw-bold fs-6 mb-2'>In Time</label>
              <input
                className='form-control'
                type='time'
                value={inputField.inTime}
                name='inTime'
                onChange={(event) => handleInputChange(event, setInputField, inputField)}
              />
              <InputErrorBox Message={inputError.inTime} />
            </Col>

            <Col md={1}></Col>
            <Col md={5}>
              <label className='required fw-bold fs-6 mb-2'>Out Time</label>
              <input
                className='form-control'
                type='time'
                value={inputField.outTime}
                name='outTime'
                onChange={(event) => handleInputChange(event, setInputField, inputField)}
              />
              <InputErrorBox Message={inputError.outTime} />
            </Col>
          </Row>

          {/*start data and enddate  */}
          <Row className='mb-4 m-3'>
            <Col md={5}>
              <label className='required fw-bold fs-6 mb-2'>Date of Joining</label>
              <input
                className='form-control'
                type='date'
                value={inputField.startDate}
                name='startDate'
                onChange={(event) => handleInputChange(event, setInputField, inputField)}
              />
              <InputErrorBox Message={inputError.startDate} />
            </Col>

            <Col md={1}></Col>
            <Col md={5}>
              <label className=' fw-bold fs-6 mb-2'>Date of Leaving</label>
              <input
                className='form-control'
                type='date'
                autoComplete='off'
                name='endDate'
                value={inputField.endDate}
                onChange={(event) => handleInputChange(event, setInputField, inputField)}
              />
              {<InputErrorBox Message={inputError.endDate} />}
            </Col>
          </Row>

          <Row className='mb-4 m-3'>
            <Col md={5}>
              <label className='required fw-bold fs-6 mb-2'>Mobile Number</label>
              <input
                placeholder='Mobile Number'
                className='form-control'
                type='tel'
                name='contact'
                pattern='[0-9]{3}-[0-9]{2}-[0-9]{3}'
                value={inputField.contact}
                onChange={(event) => handleInputChange(event, setInputField, inputField)}
              />
              {<InputErrorBox Message={inputError.contact} />}
            </Col>
            <Col md={1}></Col>
            <Col md={5}>
              <label className='required fw-bold fs-6 mb-2'>Password</label>
              <input
                placeholder='Password'
                className='form-control'
                type='password'
                name='password'
                value={inputField.password || ''}
                onChange={(event) => handleInputChange(event, setInputField, inputField)}
              />

              {<InputErrorBox Message={inputError.password} />}
            </Col>
          </Row>
          <Row className='mb-2 m-3'>
            {/* reporting manager */}
            <Col md={5}>
              <label className=' required fs-6 fw-semibold mb-2'>Reporting Manager</label>
              <div className='col-12'>
                <Select2
                  data={sortedUserNames}
                  value={inputField.reportingManager}
                  onChange={(selectedOption: any) => {
                    setInputField((prevInputField) => ({
                      ...prevInputField,
                      reportingManager: selectedOption,
                    }))
                  }}
                  name='reportingManager'
                  disabled={false}
                ></Select2>
                {<InputErrorBox Message={inputError.reportingManager} />}
              </div>
            </Col>
            <Col md={1}></Col>
            <Col md={5}>
              <label className=' required fs-6 fw-semibold mb-2'>Role</label>
              <div className='col-12'>
                <Select2
                  data={sortedRoles}
                  value={inputField.role}
                  onChange={(selectedOption: any) => {
                    setInputField((prevInputField) => ({
                      ...prevInputField,
                      role: selectedOption,
                    }))
                  }}
                  name='role'
                  disabled={false}
                ></Select2>
                {<InputErrorBox Message={inputError.role} />}
              </div>
            </Col>

            <Col md={5} className=''></Col>
          </Row>
          <Row className='mb-2 m-3'>
            <Col md={5}>
              <label className='fs-6 fw-semibold mb-2'>Human Resource</label>
              <div className='col-12'>
                <Select2
                  data={sortedHr}
                  value={inputField.hr}
                  onChange={(selectedOption: any) => {
                    setInputField((prevInputField) => ({
                      ...prevInputField,
                      hr: selectedOption,
                    }))
                  }}
                  name='hr'
                ></Select2>
                {/* {<InputErrorBox Message={inputError.hr} />} */}
              </div>
            </Col>
          </Row>
        </div>

        <div className='form m-5'>
          <div className='text-center'>
            {/* begin:: discard button */}
            <button
              type='reset'
              className='btn btn-light me-3 btn-sm'
              data-kt-users-modal-action='cancel'
              onClick={() => {
                setInputField(initialState)
                setInputError('')
              }}
            >
              Clear
            </button>

            {/* end:: discard button */}

            {/* begin:: modal-form save button */}
            <button
              type='submit'
              className='btn btn-sm  btn-primary'
              onClick={() =>
                handleSubmit(
                  {
                    ...inputField,
                    currentUserEmail: currentUser?.email ?? null,
                  },
                  // inputField,
                  getUser,
                  setUser,
                  setEditId,
                  initialState,
                  setInputField,
                  editId,
                  cancelSubmit,
                  setAPIStatus,
                  // setShowForm,
                  setInputError,
                  validateForm,
                  setSubmitAPIStatus,
                  handleClose,
                  fetchUsers
                  // currentUser?.email
                )
              }
            >
              Save
            </button>

            {/* end:: modal-form save button */}
          </div>
          {/* <Message type="success">Success</Message> */}
        </div>
      </Modal>
    </>
  )
}

export default CreateUser
