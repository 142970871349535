import {toast} from 'react-toastify'
import Swal from 'sweetalert2'
import {Message} from '../../../_metronic/helpers'
import {
  addHoliday,
  deleteHolidays,
  getHolidays,
  getHolidaysById,
  updateHolidayById,
} from '../../../api/HolidayAPI'
import {DELETE_MESSAGE, UPDATE_MESSAGE} from '../../../utils/contants'
import {AlertProps} from '../../data/AlertDefaults'

export const getHoliday = (setHoliday: Function, setAPIStatus: Function) => {
  setAPIStatus((prev: any) => ({...prev, loading: true}))
  getHolidays().then((res: any) => {
    if (res.statusCode === 200) {
      const holidaysWithIndex = res.data.map((holiday: any, index: number) => ({
        ...holiday,
        index: index + 1,
      }))
      setHoliday(holidaysWithIndex)
      setHoliday(res.data)

      setAPIStatus((prev: any) => ({...prev, loading: false}))
    } else {
      setAPIStatus((prev: any) => ({...prev, loading: false, error: res.message}))
    }
  })
}

// form save function

export const handleSubmit = (
  InputField: {
    holidayName: string
    date: string
  },
  getHoliday: Function,
  setHoliday: Function,
  setEditId: Function,
  initialState: {
    holidayName: string
    date: string
  },
  setInputField: Function,
  editId: string | null,
  cancelSubmit: Function,
  setAPIStatus: Function,
  setInputError: Function,
  validateForm: Function,
  setSubmitAPIStatus: Function,
  setIsAddUserModalOpen: Function
) => {
  let {cnt, error} = validateForm(InputField)
  setInputError(error)

  if (cnt === 0) {
    if (editId == null) {
      setSubmitAPIStatus((prev: any) => ({...prev, loading: true}))
      addHoliday(InputField).then((res) => {
        if (res.statusCode === 200) {
          toast.success(res.message)
          getHoliday(setHoliday, setAPIStatus)
          cancelSubmit(setInputField, initialState, setEditId, setInputError)
          setIsAddUserModalOpen(false)
        } else {
          Message(res.message, 'error')
        }
        setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
      })
    } else {
      Swal.fire({...AlertProps, icon: 'warning', title: UPDATE_MESSAGE}).then((result: any) => {
        if (result.isConfirmed) {
          setSubmitAPIStatus((prev: any) => ({...prev, loading: true}))
          updateHolidayById(InputField, editId).then((res: any) => {
            if (res.statusCode === 200) {
              getHoliday(setHoliday, setAPIStatus)
              toast.success(res.message)
              cancelSubmit(setInputField, initialState, setEditId, setInputError)
              setEditId(null)
              setIsAddUserModalOpen(false)
            } else {
              Message(res.message, 'error')
            }
            setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
          })
        }
      })
    }
  }
}

// delete holiday

export const deleteHoliday = (
  id: string,
  getHoliday: Function,
  setHoliday: Function,
  setAPIStatus: Function,
  cancelSubmit: Function,
  setInputField: Function,
  InputField: {
    holidayName: string
    date: string
  },
  setEditId: Function,

  setInputError: Function
) => {
  cancelSubmit(setInputField, InputField, setEditId, setInputError)
  Swal.fire({...AlertProps, icon: 'warning', title: DELETE_MESSAGE}).then((result: any) => {
    if (result.isConfirmed) {
      deleteHolidays(id)
        .then((res: any) => {
          if (res.statusCode === 200) {
            getHoliday(setHoliday, setAPIStatus)
            toast.success(res.message)
          } else {
            Message(res.message, 'error')
          }
        })
        .catch((res: any) => {
          Message(res.message, 'error')
        })
    }
  })
}
export const handleInputChange = (
  event: React.SyntheticEvent,
  setInputField: Function,
  InputField: {
    holidayName: string
    date: string
  }
) => {
  let target = event.target as HTMLInputElement
  setInputField({
    ...InputField,
    [target.name]: target.type === 'checkbox' ? target.checked : target.value,
  })
}

//getHolidayByID

export const getHolidayById = (
  id: string,
  InputField: {
    holidayName: string
    date: string
  },
  setInputField: Function,
  setEditId: Function,
  setInputError: Function
) => {
  getHolidaysById(id).then((res: any) => {
    if (res) {
      setInputField({
        ...InputField,
        holidayName: res.data.holidayName,
        date: res.data.date,
      })
      setEditId(id)
      setInputError({})
      window.scrollTo(0, 0)
    } else {
      Message(res.message, 'error') // Assuming the Message function is imported from the correct location
    }
  })
}
export const validateForm = (InputField: {holidayName: string; date: string}) => {
  let cnt = 0
  const error: any = {}

  const minHolidayNameLength = 2
  const maxHolidayNameLength = 35
  const holidayName = InputField.holidayName.trim()

  if (!holidayName) {
    cnt = cnt + 1
    error.holidayName = 'Please enter a Holiday Name.'
  } else if (holidayName.length < minHolidayNameLength) {
    cnt = cnt + 1
    error.holidayName = 'Holiday Name must be at least 2 characters.'
  } else if (holidayName.length > maxHolidayNameLength) {
    cnt = cnt + 1
    error.holidayName = 'Holiday Name must not exceed 35 characters.'
  } else if (!/^[a-zA-Z ]+$/.test(holidayName)) {
    cnt = cnt + 1
    error.holidayName = 'Please enter a valid Holiday Name using only alphabetic characters.'
  }

  if (!InputField.date.trim()) {
    cnt = cnt + 1
    error.date = 'Please select/enter a valid Date.'
  }
  return {error, cnt}
}
export const cancelSubmit = (
  setInputField: Function,
  InputField: {holidayName: string; date: string},
  setEditId: Function,
  setInputError: Function
) => {
  setEditId(null)
  setInputField(InputField)
  setInputError({})
}
