import {Menu} from '@mantine/core'
import {useAuth} from '../../modules/auth'

interface MailSidebarProps {
  setActiveTab: React.Dispatch<React.SetStateAction<string>>
}
const MailSidebar: React.FC<MailSidebarProps> = ({setActiveTab}) => {
  const {currentUser} = useAuth()
  return (
    <div
      className='d-none d-lg-flex flex-column flex-lg-row-auto w-100 w-lg-275px'
      data-kt-drawer='true'
      data-kt-drawer-name='inbox-aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width='225px'
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_inbox_aside_toggle'
    >
      <div
        className='card card-flush mb-0'
        data-kt-sticky='false'
        data-kt-sticky-name='inbox-aside-sticky'
        data-kt-sticky-offset="{default: false, xl: '100px'}"
        data-kt-sticky-width="{lg: '275px'}"
        data-kt-sticky-left='auto'
        data-kt-sticky-top='100px'
        data-kt-sticky-animation='false'
        data-kt-sticky-zindex='95'
      >
        <div className='card-body'>
          <div className='btn-group me-4 w-100'>
            <span className='btn-group me-4 w-100'>
              <span
                className='btn btn-primary fs-bold px-6 w-50 mb-8'
                onClick={() => setActiveTab('compose')}
              >
                Compose
              </span>
              
            </span>
          </div>

          <div className='menu menu-column menu-rounded menu-state-bg menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary mb-10'>
            <div className='menu-item mb-3'>
              <span className='menu-link' onClick={() => setActiveTab('inbox')}>
                <span className='menu-icon'>
                  <i className='fa-solid fa-inbox fs-2 me-3'></i>
                </span>
                <span className='menu-title fw-bold'>Inbox</span>
              </span>
            </div>

            <div className='menu-item mb-3'>
              <span className='menu-link' onClick={() => setActiveTab('sent')}>
                <span className='menu-icon'>
                  <i className='fa-solid fa-paper-plane fs-2 me-3'></i>
                </span>
                <span className='menu-title fw-bold'>Sent</span>
              </span>
            </div>

            
          </div>

          
        </div>
      </div>
    </div>
  )
}

export default MailSidebar
