/* eslint-disable jsx-a11y/anchor-is-valid */
import {Button} from '@mantine/core'
import {useContext, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {checkRole} from '../../../../_metronic/helpers/AssetHelpers'
import InputErrorBox from '../../../components/InputErrorBox'
import roleContext from '../../../context/roleContext'
import {useAuth} from '../../../modules/auth'
import {RoleContextValue} from '../../../types/OtherTypes'
import {
  cancelSubmit,
  deactivateAccount,
  handleInputChange,
  handleSubmit,
  validateForm,
} from './SignInMethodFunction'
import {ToastContainer} from 'react-toastify'

const SignInMethod = (props: {userDetails: any}) => {
  let roleState: RoleContextValue = useContext(roleContext)
  const [inputError, setInputError] = useState<any>({})
  const [APIStatus, setAPIStatus] = useState({
    loading: false,
    error: null,
    submitLoading: false,
  })
  const [SubmitAPIStatus, setSubmitAPIStatus] = useState({
    loading: false,
  })

  const [userData, setUserData] = useState(props.userDetails)
  const [showPasswordForm, setPasswordForm] = useState<boolean>(false)
  const [loading2] = useState(false)
  const params = useParams()
  const userId = params.id

  const {currentUser} = useAuth()
  const isAdminUser = currentUser?.isAdmin || false
  const initialState = {
    oldPassword: '',
    password: '',
  }
  const [inputField, setInputField] = useState(initialState)
  useEffect(() => {
    setUserData(props.userDetails)
  }, [])
  return (
    <>
      {console.log(userData?.isActive, props?.userDetails?.isActive)}
      <ToastContainer position={'bottom-center'} />

      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_signin_method'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Sign-In Method</h3>
          </div>
        </div>

        <div id='kt_account_signin_method' className='collapse show'>
          <div className='card-body border-top p-9'>
            <div className='d-flex flex-wrap align-items-center justify-content-between'>
              <div
                id='kt_signin_email'
                hidden={!checkRole(['Deactivate Account'], roleState.features, 'edit')}
              >
                <div className='fs-6 fw-bolder mb-1'>
                  {userData?.isActive !== undefined
                    ? userData?.isActive
                      ? 'Deactivate'
                      : 'Activate'
                    : props?.userDetails?.isActive
                      ? 'Deactivate'
                      : 'Activate'}
                  Account
                </div>
              </div>

              <div>
                <Button
                  hidden={!checkRole(['Deactivate Account'], roleState.features, 'edit')}
                  onClick={() =>
                    deactivateAccount(
                      userId,
                      setUserData,
                      setSubmitAPIStatus,
                      userData?.isActive !== undefined
                        ? userData?.isActive
                        : props?.userDetails?.isActive
                    )
                  }
                  className={`btn btn-lg ${
                    userData?.isActive !== undefined
                      ? userData?.isActive
                        ? 'btn-light-danger'
                        : 'btn-light-success'
                      : props?.userDetails?.isActive
                        ? 'btn-light-danger'
                        : 'btn-light-success'
                  } py-3 `}
                  loading={SubmitAPIStatus?.loading}
                  size='md'
                >
                  {userData?.isActive !== undefined
                    ? userData?.isActive
                      ? 'Deactivate'
                      : 'Activate'
                    : props?.userDetails?.isActive
                      ? 'Deactivate'
                      : 'Activate'}{' '}
                </Button>
              </div>
            </div>

            {(isAdminUser && userId === null) ||
              (userId === undefined && (
                <div className='d-flex flex-wrap align-items-center mb-10 mt-10'>
                  <div id='kt_signin_password' className={' ' + (showPasswordForm && 'd-none')}>
                    <div className='fs-6 fw-bolder mb-1'>Password</div>
                    <div className='fw-bold text-gray-600'>************</div>
                  </div>

                  <div
                    id='kt_signin_password_edit'
                    className={'flex-row-fluid ' + (!showPasswordForm && 'd-none')}
                  >
                    <form id='kt_signin_change_password' className='form' noValidate>
                      <div className='row mb-1'>
                        <div className='col-lg-4'>
                          <div className='fv-row mb-0'>
                            <label
                              htmlFor='currentpassword'
                              className='form-label fs-6 fw-bolder mb-3'
                            >
                              Current Password
                            </label>
                            <input
                              placeholder='Current Password'
                              className='form-control'
                              type='password'
                              name='oldPassword'
                              value={inputField.oldPassword || ''}
                              onChange={(event) =>
                                handleInputChange(event, setInputField, inputField)
                              }
                            />
                            {<InputErrorBox Message={inputError.oldPassword} />}
                          </div>
                        </div>

                        <div className='col-lg-4'>
                          <div className='fv-row mb-0'>
                            <label htmlFor='newpassword' className='form-label fs-6 fw-bolder mb-3'>
                              New Password
                            </label>
                            <input
                              placeholder='Password'
                              className='form-control'
                              type='password'
                              name='password'
                              value={inputField.password || ''}
                              onChange={(event) =>
                                handleInputChange(event, setInputField, inputField)
                              }
                            />
                            {<InputErrorBox Message={inputError.password} />}
                          </div>
                        </div>
                      </div>

                      <div className='d-flex'>
                        <button
                          id='kt_password_submit'
                          type='submit'
                          className='btn btn-primary me-2 px-6'
                          onClick={(event) => {
                            console.log('current User:', currentUser)
                            if (currentUser) {
                              handleSubmit(
                                event,
                                inputField,
                                initialState,
                                setInputField,
                                cancelSubmit,
                                setAPIStatus,
                                setInputError,
                                validateForm,
                                setSubmitAPIStatus,
                                currentUser.id,
                                setPasswordForm
                              )
                            } else {
                              console.error('currentUser is undefined')
                            }
                          }}
                        >
                          {!loading2 && 'Update Password'}
                          {loading2 && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                              Please wait...{' '}
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                        <button
                          onClick={() => {
                            setPasswordForm(false)
                          }}
                          id='kt_password_cancel'
                          type='button'
                          className='btn btn-color-gray-400 btn-active-light-primary px-6'
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>

                  <div
                    id='kt_signin_password_button'
                    className={'ms-auto ' + (showPasswordForm && 'd-none')}
                  >
                    <Button
                      onClick={() => {
                        setPasswordForm(true)
                      }}
                      className='btn btn-light btn-active-light-primary '
                      style={{height: '33px', borderRadius: '8px'}}
                    >
                      Reset Password
                    </Button>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  )
}

export {SignInMethod}
