import {setupAxios} from '../app/modules/auth'
import {api} from './APIMiddlewares'

export async function addPolicy(InputField: {file: string}) {
  const {token, tenantId} = setupAxios()
  let formData = new FormData()
  formData.append('file', InputField.file)
  return await api
    .post(
      `/policy`,
      token,
      tenantId,
      {
        body: formData,
      },
      false,
      true
    )
    .then((res) => res)
}


export async function getPolicys() {
  const {token, tenantId} = setupAxios()
  return await api.get(`/policy`, token, tenantId, false)
}

export async function deletePolicys(id: string) {
  const {token, tenantId} = setupAxios()
  return await api.delete(`/policy/${id}`, token, tenantId, false)
}
