import Swal, {SweetAlertResult} from 'sweetalert2'
import {AlertProps} from '../../data/AlertDefaults'
import {DELETE_MESSAGE, UPDATE_MESSAGE} from '../../../utils/contants'
import {Message, clearInputError} from '../../../_metronic/helpers'
import {UserModel} from '../../modules/auth'
import {
  APIResponse,
  InputErrorType,
  SelectValueType,
  SubmitAPIStatusType,
  TableHeaderDataType,
} from '../../types/OtherTypes'
import {
  ModuleOutput,
  RoleMasterFeatureInputType,
  RoleMasterFeatureType,
  RoleMasterInput,
} from '../../types/AdminModuleTypes'
import API from '../../../api/APIUrl'
import {RoleMasterInitialInput} from '../../data/AdminModuleDefaultData'
import {
  addRoles,
  checkIsRoleDuplicate,
  deleteRoles,
  getFeatureLevels,
  getFeatures,
  getFeaturesByModuleId,
  getModules,
  getRolesById,
  updateRolesById,
} from '../../../api/AdminAPI'

export const getFeaturesData = (setFeatures: Function, setInputFeatures: Function) => {
  getFeatures().then((res: APIResponse) => {
    if (res.statusCode === 200) {
      let features: RoleMasterFeatureInputType = {}
      res.data.map((feature: RoleMasterFeatureType) => {
          features[`${feature.id}`] = []
 
      })
      setFeatures(res.data)
      console.log(features)
      setInputFeatures(features)
    } else {
      setFeatures([])
      setInputFeatures({})
    }
  })
}

export const handleFeatureLevelChange = (
  featureLevel: string,
  feature: string,
  inputFeatures: RoleMasterFeatureInputType,
  setInputFeatures: Function
) => {
  let arr: string[] = inputFeatures[`${feature}`]
  let isExists = false
  for (let i = 0; i <= arr.length - 1; i++) {
    if (arr[i] === featureLevel) {
      arr.splice(i, 1)
      isExists = true
      break
    }
  }
  if (!isExists) {
    arr.push(featureLevel)
  }
  setInputFeatures((feature: RoleMasterFeatureInputType) => ({...feature, [`${feature}`]: ''}))
}

export const getFeatureLevel = (setFeatureLevels: Function) => {
  getFeatureLevels().then((res: APIResponse) => {
    if (res.statusCode === 200) {
      setFeatureLevels(res.data)
    } else {
      setFeatureLevels([])
    }
  })
}

export const deleteRole = (
  id: string,
  getRole: Function,
  setRole: Function,
  setFormField: Function,
  setEditId: Function,
  setInputError: Function,
  setFeatures: Function,
  setInputFeatures: Function,
  setShowForm: Function
) => {
  cancelSubmit(setFormField, setEditId, setInputError, setFeatures, setInputFeatures, setShowForm)
  Swal.fire({...AlertProps, icon: 'warning', title: DELETE_MESSAGE}).then(
    (result: SweetAlertResult) => {
      if (result.isConfirmed) {
        deleteRoles(id).then((res: APIResponse) => {
          if (res.statusCode === 200) {
            getRole(API.ROLES, setRole)
          }
        })
      }
    }
  )
}

export const getRoleByID = async (
  id: string,
  formField: RoleMasterInput,
  setFormField: Function,
  setEditId: Function,
  setInputError: Function,
  setFeatures: Function,
  setInputFeatures: Function,
  setShowForm: Function
) => {
  getFeaturesData(setFeatures, setInputFeatures)
  getRolesById(id).then((res: APIResponse) => {
    if (res.statusCode === 200) {
      setShowForm(true)
      let features: RoleMasterFeatureInputType = {}
      res.data.permission.map((permission: UserModel['role']['permission'][number]) => {
        let featureLevel = permission.permission.map((permissionLevel) => {
          return permissionLevel.id
        })
        features[`${permission.id}`] = featureLevel
      })
      console.log(features)
      setInputFeatures((inputFeatures: RoleMasterFeatureInputType) => ({
        ...inputFeatures,
        ...features,
      }))

      setFormField({
        ...formField,
        roleName: res.data.name,
      })
      setEditId(id)
      setInputError({})
      window.scrollTo(0, 0)
    } else {
      Message(res.message, 'error')
    }
  })
}

export const handleSubmit = async (
  formField: RoleMasterInput,
  getRole: Function,
  setRole: Function,
  setEditId: Function,
  setFormField: Function,
  editId: string | null,
  setInputError: Function,
  validateForm: Function,
  setFeatures: Function,
  setInputFeatures: Function,
  inputFeatures: RoleMasterFeatureInputType,
  setSubmitAPIStatus: Function,
  setShowForm: Function
) => {
  let permission: {
    features: {id: string}
    permissions: string[]
  }[] = []

  Object.keys(inputFeatures).map((permissions) => {
    if (inputFeatures[permissions].length > 0) {
      permission.push({
        features: {id: permissions},
        permissions: inputFeatures[permissions.toString()],
      })
    }
  })
  let {cnt, error} = validateForm(formField, permission)
  setInputError(error)
  if (cnt === 0) {
    if (editId === null) {
      setSubmitAPIStatus((prev: SubmitAPIStatusType) => ({...prev, loading: true}))
      addRoles(formField, permission).then((res: APIResponse) => {
        if (res.statusCode === 200) {
          getRole(API.ROLES, setRole)
          cancelSubmit(
            setFormField,
            setEditId,
            setInputError,
            setFeatures,
            setInputFeatures,
            setShowForm
          )
        }
        setSubmitAPIStatus((prev: SubmitAPIStatusType) => ({...prev, loading: false}))
      })
    } else {
      Swal.fire({...AlertProps, icon: 'warning', title: UPDATE_MESSAGE}).then(
        (result: SweetAlertResult) => {
          if (result.isConfirmed) {
            setSubmitAPIStatus((prev: SubmitAPIStatusType) => ({...prev, loading: true}))
            updateRolesById(formField, editId, permission).then((res: APIResponse) => {
              if (res.statusCode === 200) {
                getRole(API.ROLES, setRole)
                cancelSubmit(
                  setFormField,
                  setEditId,
                  setInputError,
                  setFeatures,
                  setInputFeatures,
                  setShowForm
                )
              }
              setSubmitAPIStatus((prev: SubmitAPIStatusType) => ({...prev, loading: false}))
            })
          }
        }
      )
    }
  }
}

export const cancelSubmit = (
  setFormField: Function,
  setEditId: Function,
  setInputError: Function,
  setFeatures: Function,
  setInputFeatures: Function,
  setShowForm: Function
) => {
  setEditId(null)
  getFeaturesData(setFeatures, setInputFeatures)
  setShowForm(false)
  setFormField(RoleMasterInitialInput)
  setInputError({})
}

export const validateForm = (
  formField: RoleMasterInput,
  inputFeatures: {
    permission: {id: string}
    permissionLevel: string[]
  }[]
) => {
  let cnt = 0
  const error: InputErrorType = {}
  let alphaRegex = /^([a-z]+(\s{1})?)+[a-z\d]+$/i
  if (!formField.roleName.trim()) {
    cnt = cnt + 1
    error.roleName = 'Please enter a Role Name.'
  } else {
    if (formField.roleName.trim().length <= 1) {
      cnt = cnt + 1
      error.roleName = 'User Role Name must be greater than 1 characters'
    } else {
      if (!alphaRegex.test(formField.roleName.trim())) {
        cnt = cnt + 1
        error.roleName =
          'User Role Name should start and end with a letter or number. It can contain only letters (A-Z), numbers (0-9), or a single space.'
      } else {
        if (formField.roleName.replaceAll(' ', '').length > 50) {
          cnt = cnt + 1
          error.roleName = 'User Role Name should not be greater than 50 characters'
        }
      }
    }

    if (inputFeatures.length === 0) {
      cnt = cnt + 1
      error.feature = 'Please select at least one Permission.'
    }
  }

  return {error, cnt}
}

export const handleModuleChange = (
  value: string | null,
  setFormField: Function,
  inputError: InputErrorType,
  setInputError: Function,
  setFeatures: Function,
  setInputFeatures: Function
) => {
  clearInputError('module', inputError, setInputError)
  if (value !== null) {
    setFormField((prev: RoleMasterInput) => ({
      ...prev,
      module: value,
    }))
    getFeaturesByModuleId(value).then((res: APIResponse) => {
      if (res.statusCode === 200) {
        let features: RoleMasterFeatureInputType = {}
        res.data.map((feat: {id: string}) => {
          features[`${feat.id}`] = []
        })
        setFeatures(res.data)
        setInputFeatures(features)
      } else {
        setFeatures([])
        setInputFeatures({})
      }
    })
  }
}

export const getModuleData = (setModules: Function) => {
  getModules().then((res: APIResponse) => {
    let Modules: SelectValueType[] = []
    if (res.statusCode === 200) {
      res.data.map((obj: ModuleOutput) => {
        Modules.push({label: obj.description, value: obj.id})
      })
      setModules(Modules)
    } else {
      setModules(Modules)
    }
  })
}

export const getRoleTableHeaderData = (isActionColumnNeeded: boolean) => {
  const roleTableHeaderData: TableHeaderDataType[] = [
    {
      th: {
        id: 'name',
        style: {
          minWidth: '90px',
          width: '100px',
          marginLeft:'3px'
        },
      },
      text: 'User Role',
    },
    // {
    //   th: {
    //     id: 'module',
    //     // style: {
    //     //   minWidth: '90px',
    //     //   width: '100px',
    //     // },
    //   },
    //   text: 'Module',
    //   isSortable: false,
    // },
    {
      th: {
        id: 'features',
        // style: {
        //   minWidth: '500px',
        //   width: '100px',
        // },
      },
      text: 'Features',
      isSortable: false,
    },
    {
      th: {
        id: 'actions',
        style: {
          width: '100px',
          minWidth: '70px',
          
        },
        hidden: isActionColumnNeeded,
      },
      text: 'Actions',
      // justifyContent: 'end',
      isSortable: false,
    },
  ]
  return {roleTableHeaderData}
}
