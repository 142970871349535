import {toast} from 'react-toastify'
import {Message} from '../../../_metronic/helpers'
import {
  addUserAsset,
  getAssetsByUserId,
  getUserAssets,
  updateUserAssetById,
} from '../../../api/AssetsAPI'
import Swal from 'sweetalert2'
import {UPDATE_MESSAGE} from '../../../utils/contants'
import {AlertProps} from '../../data/AlertDefaults'

export const getUserAsset = (setUserAsset: Function, setAPIStatus: Function) => {
  setAPIStatus((prev: any) => ({...prev, loading: true}))
  getUserAssets().then((res: any) => {
    if (res.statusCode === 200) {
      const assetsWithIndex = res.data.map((assets: any, index: number) => ({
        ...assets,
        index: index + 1,
      }))
      setUserAsset(assetsWithIndex)
      setUserAsset(res.data)
      setAPIStatus((prev: any) => ({...prev, loading: false}))
    } else {
      setAPIStatus((prev: any) => ({...prev, loading: false, error: res.message}))
    }
  })
}
export const handleReturnSubmit = (
  InputField: {
    category: string
    brand: string
    serialNo: string
    issueDate: string
    returnDate: string
    user: string
  },
  // getUserAsset: Function,
  setUserAsset: Function,
  setEditId: Function,
  initialState: {
    category: string
    brand: string
    serialNo: string
    issueDate: string
    returnDate: string
    user: string
  },
  setInputField: Function,
  editId: string | null,
  cancelSubmit: Function,
  setAPIStatus: Function,
  setInputError: Function,
  validateReturnForm: Function,
  setSubmitAPIStatus: Function,

  fetchAssets: Function,
  setReturnDateModal: Function
) => {
  let {cnt, error} = validateReturnForm(InputField)

  setInputError(error)
  if (cnt === 0) {
    if (editId == null) {
      setSubmitAPIStatus((prev: any) => ({...prev, loading: true}))
      addUserAsset(InputField).then((res) => {
        if (res.statusCode === 200) {
          toast.success(res.message)
          getUserAsset(setUserAsset, setAPIStatus)
          fetchAssets()
          cancelSubmit(setInputField, initialState, setEditId, setInputError)
          setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
        } else {
          Message(res.message, 'error')
        }
        setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
      })
    } else {
      Swal.fire({...AlertProps, icon: 'warning', title: UPDATE_MESSAGE}).then((result: any) => {
        if (result.isConfirmed) {
          setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
          updateUserAssetById(InputField, editId).then((res: any) => {
            if (res.statusCode === 200) {
              getUserAsset(setUserAsset, setAPIStatus)
              toast.success(res.message)
              fetchAssets()
              cancelSubmit(setInputField, initialState, setEditId, setInputError)
              setEditId(null)
              setReturnDateModal(false)
            } else {
              Message(res.message, 'error')
              setSubmitAPIStatus((prev: any) => ({...prev, loading: false}))
            }
          })
        }
      })
    }
  }
}
export const handleInputChange = (
  event: React.SyntheticEvent,
  setInputField: Function,
  InputField: {
    returnDate: string
  }
) => {
  let target = event.target as HTMLInputElement
  setInputField({
    ...InputField,
    [target.name]: target.type == 'checkbox' ? target.checked : target.value,
  })
}
export const getUserAssetById = (
  userAssetId: string | null,
  InputField: {
    category: string
    subCategory: string
    brand: string
    serialNo: string
    description: string
    purchaseDate: string
    status: string
    assignee: string
    user: string
    issueDate: string
    returnDate: string
  },
  setInputField: Function,
  setEditId: Function,
  setInputError: Function
) => {
  if (!userAssetId) {
    setInputField({
      ...InputField,
      category: '',
      subCategory: '',
      brand: '',
      serialNo: '',
      description: '',
      purchaseDate: '',
      status: '',
      assignee: '',
      user: '',
      issueDate: '',
      returnDate: '',
    })
    setEditId(null)
    setInputError({})
    return
  }
  getAssetsByUserId(userAssetId).then((res: any) => {
    if (res.statusCode === 200 && res.data) {
      setInputField({
        ...InputField,
        user: res.data.user.id,
        category: res.data.asset.category,
        brand: res.data.asset.id,
        serialNo: res.data.asset.serialNo,
        description: res.data.asset.description,
        issueDate: res.data.issueDate,
        returnDate: res.data.returnDate,
      })
      setEditId(userAssetId)
      setInputError({})
    } else {
      Message(res.message, 'error')
    }
  })
}

export const validateReturnForm = (InputField: {issueDate: string; returnDate: string}) => {
  let cnt = 0
  const error: any = {}

  if (!InputField.returnDate || !InputField.returnDate.trim()) {
    cnt = cnt + 1
    error.returnDate = 'Please select/enter a valid Return Date.'
  } else if (InputField.returnDate && InputField.issueDate) {
    const returningDate = new Date(InputField.returnDate)
    const issuingDate = new Date(InputField.issueDate)

    if (issuingDate > returningDate) {
      cnt = cnt + 1
      error.returnDate = 'Returning Date must be greater than or equal to the Issuing Date.'
    }
  }

  return {error, cnt}
}

export const cancelSubmit = (
  setInputField: Function,
  InputField: {
    category: string
    brand: string
    serialNo: string
    issueDate: string
    user: string
  },
  setEditId: Function,
  setInputError: Function
) => {
  setEditId(null)
  setInputField(InputField)
  setInputError({})
}
