import React, {useEffect, useState} from 'react'
import {
  faArrowLeft,
  faCalendarDays,
  faChartLine,
  faCheck,
  faCircleLeft,
  faEdit,
  faSave,
  faSquare,
  faUserTie,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Carousel, Col, Modal, Row} from 'react-bootstrap'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {dateFormat} from '../../../../_metronic/helpers'
// import {Button, Textarea} from '@mantine/core'
import {getTaskOverview, updateTask, validateEditedField} from './TaskOverviewFunction'
import Comments from './Comments'
import SubTask from './SubTask'
import Select2 from '../../../components/select2'
import {setupAxios, useAuth} from '../../../modules/auth'
import ReactQuill from 'react-quill'
import {Button, Image} from '@mantine/core'
import {Magnifier} from 'react-image-magnifiers'
import InputErrorBox from '../../../components/InputErrorBox'
import {
  API_BASE_URL,
  Completed,
  High,
  Highest,
  In_Progress,
  Low,
  Lowest,
  Medium,
  On_Hold,
  Todo,
} from '../../../../utils/contants'
import axios from 'axios'
import {CKEditor} from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

const SubTaskOverview = () => {
  const params = useParams()
  let navigate = useNavigate()

  const [editMode, setEditMode] = useState<
    | 'title'
    | 'description'
    | 'status'
    | 'assigneeId'
    | 'priority'
    | 'level'
    | 'startDate'
    | 'dueDate'
    | null
  >(null)
  const [editedTitle, setEditedTitle] = useState('')
  const [editedDescription, setEditedDescription] = useState('')
  const [editedStatus, setEditedStatus] = useState('')
  const [editedAssignee, setEditedAssignee] = useState('')
  const [editedPriority, setEditedPriority] = useState('')
  const [editedlevel, setEditedLevel] = useState('')
  const [editedStartDate, setEditedStartDate] = useState('')
  const [editedDueDate, setEditedDueDate] = useState('')

  const handleDoubleClick = (
    mode:
      | 'title'
      | 'description'
      | 'status'
      | 'assigneeId'
      | 'priority'
      | 'level'
      | 'startDate'
      | 'dueDate'
  ) => {
    setEditMode(mode)
    setEditedTitle(taskData[0]?.title || '')
    setEditedDescription(taskData[0]?.description || '')
    setEditedStatus(taskData[0]?.status || '')
    setEditedAssignee(taskData[0]?.assigneeId?.id || '')
    setEditedPriority(taskData[0]?.priority || '')
    setEditedLevel(taskData[0]?.level || '')
    setEditedStartDate(taskData[0]?.startDate || '')
    setEditedDueDate(taskData[0]?.dueDate || '')
  }

  const handleBack = () => {
    navigate(-1)
  }

  const handleSaveClick = () => {
    const validationErros = validateEditedField({
      title: editedTitle,
      // description: editedDescription,
    })
    if (Object.keys(validationErros).length > 0) {
      setInputError(validationErros)
      return
    }

    setInputError({})
    if (editMode === 'title') {
      updateTask(params?.id, {title: editedTitle}, setTaskData, setAPIStatus)
    } else if (editMode === 'description') {
      updateTask(params?.id, {description: editedDescription}, setTaskData, setAPIStatus)
    } else if (editMode === 'status') {
      updateTask(params?.id, {status: editedStatus}, setTaskData, setAPIStatus)
    } else if (editMode === 'assigneeId') {
      updateTask(
        params?.id,
        {
          assigneeId: {id: editedAssignee},
        },
        setTaskData,
        setAPIStatus
      )
    } else if (editMode === 'priority') {
      updateTask(
        params?.id,
        {
          priority: editedPriority,
        },
        setTaskData,
        setAPIStatus
      )
    } else if (editMode === 'level') {
      updateTask(
        params?.id,
        {
          level: editedlevel,
        },
        setTaskData,
        setAPIStatus
      )
    } else if (editMode === 'startDate') {
      updateTask(params?.id, {startDate: editedStartDate}, setTaskData, setAPIStatus)
    } else if (editMode === 'dueDate') {
      updateTask(params?.id, {dueDate: editedDueDate}, setTaskData, setAPIStatus)
    }

    setEditMode(null)
    setInputError({})
  }

  const handleBlur = () => {
    handleSaveClick()
  }

  const [largedImage, setlargedImage] = useState<string | null>(null)

  const [APIStatus, setAPIStatus] = useState({
    loading: false,
    error: null,
    submitLoading: false,
  })

  const [inputError, setInputError] = useState<any>({})

  const [taskData, setTaskData] = useState([
    {
      id: '',
      title: '',
      description: '',
      startDate: '',
      dueDate: '',
      endDate: '',
      priority: '',
      status: '',
      percentage: '',
      level: '',
      ticketName: '',
      parentTicketName: '',
      assigneeId: {
        id: '',
        firstName: '',
        lastName: '',
      },
      assignerId: {
        id: '',
        firstName: '',
        lastName: '',
      },
      parentTaskId: '',
      isDefect: false,
      project: {
        id: '',
        name: '',
      },
      updatedAt: '',
      files: [
        {
          id: '',
          file: '',
          fileName: '',
          createdAt: '',
          fileExtension: '',
        },
      ],
    },
  ])

  const [projectUsers, setProjectUsers] = useState<{label: string; value: string}[]>([])
  const sortedProjectUser = projectUsers.slice().sort((a, b) => a.label.localeCompare(b.label))

  useEffect(() => {
    let mounted = true
    if (mounted) {
      getTaskOverview(params?.id, setTaskData, setAPIStatus)
      fetchprojectUserNames()
    }
    return () => {
      mounted = false
    }
  }, [])

  const fetchprojectUserNames = () => {
    const {token, tenantId} = setupAxios()
    // console.log(params)
    axios
      .get(API_BASE_URL + `/project/${params?.projectId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-Tenant-Id': `${tenantId}`,
        },
      })
      .then((response: any) => {
        let userData: {label: string; value: string}[] = []
        response?.data?.data.forEach((project) => {
          project.projectUsers.forEach((user) => {
            userData.push({
              label: `${user.user.firstName} ${user.user.lastName}`,
              value: user.user.id,
            })
          })
        })
        setProjectUsers(userData)
        // setTasks((prevTasks) => filterdTasks(prevTasks, selectedUser, selectedStatus, selectedDate))
      })
      .catch((error) => {
        console.error('Error fetching names:', error)
      })
  }
  return (
    <>
      <div className='card mt-5 '>
        {taskData?.map((obj) => {
          return (
            <>
              <div>
                <div className='card-header mb-5 d-flex '>
                  <div className='card-title'>
                    <span className='btn btn-icon btn-active-color-primary' onClick={handleBack}>
                      {' '}
                      <FontAwesomeIcon icon={faArrowLeft} size='lg' />
                    </span>
                    <span className=''>Back</span>
                    <span
                      className={`btn btn-active-color-primary badge badge-light-${
                        obj?.isDefect ? 'danger' : 'primary'
                      } ms-3 mt-1 p-3`}
                      onClick={() => {
                        navigate(`/project/${params?.projectId}/taskOverview/${obj?.parentTaskId}`)
                      }}
                      style={{cursor: 'pointer'}}
                    >
                      {obj?.parentTicketName ?? '-'}
                    </span>
                    <span className='ms-2'>/</span>
                    <span
                      className={`btn btn-active-color-primary badge badge-light-${
                        obj?.isDefect ? 'danger' : 'primary'
                      } ms-3 mt-1 p-3`}
                    >
                      {obj?.ticketName ?? '-'}
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <Row>
                  <Col md={7}>
                    <div
                      className='card mb-5 mt-5 ms-5 mb-5 me-5 border border-2 border-gray-300'
                      key={obj?.id}
                    >
                      <div className=''>
                        <div className='mt-5 fw-bold fs-3 mb-4'>
                          {editMode === 'title' ? (
                            <div className='ms-5 me-5'>
                              <Row>
                                <Col>
                                  {' '}
                                  <input
                                    type='text'
                                    className='mt-5 form-control'
                                    value={editedTitle}
                                    onChange={(e) => setEditedTitle(e.target.value)}
                                    onBlur={handleBlur}
                                  />
                                </Col>
                              </Row>
                              {<InputErrorBox Message={inputError?.title} />}
                            </div>
                          ) : (
                            <>
                              <span
                                data-bs-toggle='tooltip'
                                title='Edit Title'
                                className='ms-5 mt-5'
                                onDoubleClick={() => handleDoubleClick('title')}
                                style={{cursor: 'pointer'}}
                              >
                                {' '}
                                {obj?.title ?? '-'}
                              </span>
                            </>
                          )}
                        </div>

                        <div className=' mt-5 ms-5 fw-bold text-gray-500 mb-5'>Description</div>

                        {editMode === 'description' ? (
                          <div className='ms-5 me-5'>
                            {/* <ReactQuill
                              className='form-control fs-5 mt-5'
                              style={{
                                fontSize: '13px',
                                border: 'bottem-border',
                                minHeight: '175px',
                                maxHeight: '175px',
                                overflowY: 'scroll',
                              }}
                              theme='snow'
                              value={editedDescription}
                              onChange={(value) => setEditedDescription(value)}
                              onBlur={handleBlur}
                            />{' '} */}
                            <CKEditor
                              editor={ClassicEditor}
                              data={editedDescription}
                              config={{
                                toolbar: {
                                  items: [
                                    'heading',
                                    '|',
                                    'bold',
                                    'italic',
                                    'link',
                                    'bulletedList',
                                    'numberedList',
                                  ],
                                },
                              }}
                              onChange={(event, editor) => {
                                const data = editor.getData()
                                setEditedDescription(data)
                              }}
                            />
                            <div className='d-flex justify-content-end'>
                              <Button onClick={handleSaveClick} className='mt-3'>
                                <FontAwesomeIcon icon={faSave} className='me-1' />
                                Save
                              </Button>
                            </div>
                            {<InputErrorBox Message={inputError?.description} />}
                          </div>
                        ) : (
                          <>
                            <span
                              data-bs-toggle='tooltip'
                              title='Edit Description'
                              className=''
                              onDoubleClick={() => handleDoubleClick('description')}
                              style={{cursor: 'pointer'}}
                            >
                              <p
                                className='fs-5 ms-5 me-5'
                                dangerouslySetInnerHTML={{__html: obj?.description ?? '-'}}
                              />
                            </span>
                          </>
                        )}

                        <div className='d-flex flex-wrap mt-10 me-5 mb-5'>
                          {obj.files?.map((file) => {
                            return (
                              <div
                                className=''
                                key={file?.id}
                                onClick={() => setlargedImage(file?.id)}
                                style={{cursor: 'pointer'}}
                              >
                                <Image
                                  maw={240}
                                  className='d-flex flex-wrap ms-5 me-5 mb-5'
                                  mx='auto'
                                  radius='md'
                                  src={`http://192.168.1.251:8002/api/v1/file/${file?.id}`}
                                  alt='Task Image'
                                  height={150}
                                  width={150}
                                />
                              </div>
                            )
                          })}
                        </div>
                      </div>

                      <div className='mt-2 mb-2'>
                        {' '}
                        <br />
                        <hr />{' '}
                      </div>
                      <Comments />
                    </div>
                  </Col>
                  <Col md={5}>
                    <div className='card mb-5 border border-2 border-gray-300 mt-5 ms-5 me-5'>
                      <div className='card-header d-flex justify-content-start'>
                        <div className='card-title'>Details</div>
                      </div>
                      <div className='card-body'>
                        <div className='mb-5 d-flex'>
                          <span className='mb-md-0 me-md-2 mt-2'>
                            <FontAwesomeIcon icon={faChartLine} /> Status
                          </span>
                          <div className='ms-14 d-flex'>
                            {editMode === 'status' ? (
                              <>
                                <Row className=''>
                                  <Col md={7} className='d-flex align-items-center'>
                                    <span className='fs-5 fw-bold text-gray-900 mb-2 ms-5'>
                                      <Select2
                                        name='status'
                                        value={editedStatus}
                                        data={[
                                          {label: 'Todo', value: 'Todo'},
                                          {label: 'On Hold', value: 'On Hold'},
                                          {label: 'In Progress', value: 'In Progress'},
                                          {label: 'Completed', value: 'Completed'},
                                        ].sort((a, b) => a.label.localeCompare(b.label))}
                                        onChange={(selectedOption: any) => {
                                          // console.log(selectedOption)
                                          setEditedStatus(selectedOption)
                                        }}
                                        onBlur={handleBlur}
                                      />
                                    </span>
                                  </Col>
                                </Row>
                              </>
                            ) : (
                              <>
                                <span
                                  data-bs-toggle='tooltip'
                                  title='Edit Status'
                                  className={` fw-bolder mb-2 ms-6 p-3 ${
                                    obj?.status === Todo
                                      ? 'badge badge-light-primary'
                                      : obj?.status === In_Progress
                                        ? 'badge badge-light-info'
                                        : obj?.status === Completed
                                          ? 'badge badge-light-success'
                                          : obj?.status === On_Hold
                                            ? 'badge badge-light-warning'
                                            : 'badge badge-light-danger'
                                  }`}
                                  onDoubleClick={() => handleDoubleClick('status')}
                                  style={{cursor: 'pointer'}}
                                >
                                  {obj?.status ?? '-'}
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                        <div className='mb-5 d-flex'>
                          <span className='mb-md-0 me-md-2 mt-2'>
                            <FontAwesomeIcon icon={faUserTie} /> Assignee{' '}
                          </span>
                          <div className='ms-14 d-flex'>
                            {editMode === 'assigneeId' ? (
                              <>
                                <Row>
                                  <Col md={7} className='d-flex align-items-center'>
                                    <span className='fs-5 fw-bold text-gray-900 mb-2 ms-5 me-2'>
                                      <Select2
                                        name='assineeId'
                                        value={editedAssignee}
                                        data={sortedProjectUser}
                                        onChange={(selectedOption: string) => {
                                          console.log(selectedOption)
                                          setEditedAssignee(selectedOption)
                                        }}
                                        onBlur={handleBlur}
                                      />
                                    </span>
                                  </Col>
                                </Row>
                              </>
                            ) : (
                              <>
                                <span
                                  className='fs-5 fw-semibold text-gray-900 text-hover-primary mb-2 mt-2 ms-2'
                                  onDoubleClick={() => handleDoubleClick('assigneeId')}
                                  style={{cursor: 'pointer'}}
                                  data-bs-toggle='tooltip'
                                  title='Edit Assignee'
                                >
                                  {obj?.assigneeId?.firstName ?? '-'}{' '}
                                  {obj?.assigneeId?.lastName ?? '-'}
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                        <div className='mb-5 d-flex'>
                          <span className='mb-md-0 me-md-2 mt-2'>
                            <FontAwesomeIcon icon={faChartLine} /> Priority
                          </span>
                          <div className='ms-14 d-flex'>
                            {editMode === 'priority' ? (
                              <>
                                <Row>
                                  <Col md={7} className='d-flex align-items-center'>
                                    <span className='fs-5 fw-bold text-gray-900 mb-2 ms-5'>
                                      <Select2
                                        name='priorty'
                                        value={editedPriority}
                                        data={[
                                          {label: 'Highest', value: 'Highest'},
                                          {label: 'High', value: 'High'},
                                          {label: 'Medium', value: 'Medium'},
                                          {label: 'Low', value: 'Low'},
                                          {label: 'Lowest', value: 'Lowest'},
                                        ].sort((a, b) => a.label.localeCompare(b.label))}
                                        onChange={(selectedOption: any) => {
                                          // console.log(selectedOption)
                                          setEditedPriority(selectedOption)
                                        }}
                                        onBlur={handleBlur}
                                      />
                                    </span>
                                  </Col>
                                </Row>
                              </>
                            ) : (
                              <>
                                <span
                                  data-bs-toggle='tooltip'
                                  title='Edit Priority'
                                  className={` fw-bolder mb-2 ms-5 p-3 ${
                                    obj?.priority === Highest
                                      ? 'badge badge-light-danger'
                                      : obj?.priority === High
                                        ? 'badge badge-light-warning'
                                        : obj?.priority === Medium
                                          ? 'badge badge-light-warning'
                                          : obj?.priority === Low
                                            ? 'badge badge-light-success'
                                            : obj?.priority === Lowest
                                              ? 'badge badge-light-primary'
                                              : 'badge badge-light-primary'
                                  }`}
                                  onDoubleClick={() => handleDoubleClick('priority')}
                                  style={{cursor: 'pointer'}}
                                >
                                  {obj?.priority ?? '-'}
                                </span>
                              </>
                            )}
                          </div>
                        </div>

                        <div className='mb-5 d-flex'>
                          <span className='mb-md-0 me-md-2 mt-2'>
                            <FontAwesomeIcon icon={faChartLine} /> Severity
                          </span>
                          <div className='ms-14 d-flex '>
                            {editMode === 'level' ? (
                              <>
                                <Row>
                                  <Col md={7} className='d-flex align-items-center'>
                                    <span className='fs-5 fw-bold text-gray-900 mb-2 ms-5'>
                                      <Select2
                                        name='level'
                                        value={editedlevel}
                                        data={[
                                          {label: 'Easy', value: 'Easy'},
                                          {label: 'Medium', value: 'Medium'},
                                          {label: 'Hard', value: 'Hard'},
                                        ].sort((a, b) => a.label.localeCompare(b.label))}
                                        onChange={(selectedOption: any) => {
                                          // console.log(selectedOption)
                                          setEditedLevel(selectedOption)
                                        }}
                                        onBlur={handleBlur}
                                      />
                                    </span>
                                  </Col>
                                </Row>
                              </>
                            ) : (
                              <>
                                <span
                                  data-bs-toggle='tooltip'
                                  title='Edit Severity'
                                  className={`fw-bolder mb-2 p-3`}
                                  onDoubleClick={() => handleDoubleClick('level')}
                                  style={{cursor: 'pointer'}}
                                >
                                  {obj?.level ?? '-'}
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                        <div className='mb-7 d-flex'>
                          <span className='mb-md-0 me-md-2'>
                            <FontAwesomeIcon icon={faCalendarDays} /> Start Date
                          </span>
                          <div className='ms-10 d-flex justify-content-end'>
                            {editMode === 'startDate' ? (
                              <span className='fs-5 fw-bold text-gray-900 mb-2 ms-5'>
                                <div>
                                  <Row>
                                    <Col md={7} className='d-flex align-items-center'>
                                      <input
                                        type='date'
                                        name='startDate'
                                        className='form-control'
                                        value={editedStartDate}
                                        onChange={(e) => setEditedStartDate(e.target.value)}
                                        onBlur={handleBlur}
                                      />
                                    </Col>
                                  </Row>
                                </div>
                              </span>
                            ) : (
                              <span
                                className='fs-5 fw-semibold text-gray-900 mb-2 ms-4 text-center'
                                onClick={() => handleDoubleClick('startDate')}
                                style={{cursor: 'pointer'}}
                              >
                                {obj?.startDate === null ? (
                                  <span>{'-'}</span>
                                ) : (
                                  <span>{dateFormat(obj?.startDate ?? '-')}</span>
                                )}{' '}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className='mb-7 d-flex'>
                          <span className='mb-md-0 me-md-2'>
                            <FontAwesomeIcon icon={faCalendarDays} /> Due Date
                          </span>
                          <div className='ms-10 d-flex justify-content-end'>
                            {editMode === 'dueDate' ? (
                              <span className='fs-5 fw-bold text-gray-900 mb-2 ms-5'>
                                <div>
                                  <Row>
                                    <Col md={7} className='d-flex align-items-center'>
                                      <input
                                        type='date'
                                        name='dueDate'
                                        className='form-control'
                                        value={editedDueDate}
                                        onChange={(e) => setEditedDueDate(e.target.value)}
                                        onBlur={handleBlur}
                                      />
                                    </Col>
                                  </Row>
                                </div>
                              </span>
                            ) : (
                              <span
                                className='fs-5 fw-semibold text-gray-900 mb-2 ms-5 text-center'
                                onClick={() => handleDoubleClick('dueDate')}
                                style={{cursor: 'pointer'}}
                              >
                                {obj?.dueDate === null ? (
                                  <span>{'-'}</span>
                                ) : (
                                  <span>{dateFormat(obj?.dueDate ?? '-')}</span>
                                )}
                              </span>
                            )}
                          </div>
                        </div>
                        {obj?.status === `${Completed}` ? (
                          <div className='mb-7 d-flex'>
                            <span className='mb-md-0 me-md-2'>
                              <FontAwesomeIcon icon={faCalendarDays} /> End Date
                            </span>
                            <div className='ms-10 d-flex justify-content-end'>
                              <span className='fs-5 fw-semibold text-gray-900 mb-2 ms-5 text-center'>
                                {dateFormat(obj?.endDate ?? '-')}
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div className='mb-7 d-flex'>
                            <span className='mb-md-0 me-md-2'>
                              <FontAwesomeIcon icon={faCalendarDays} /> End Date
                            </span>
                            <div className='ms-10 d-flex justify-content-end'>
                              <span className='fs-5 fw-semibold text-gray-900 mb-2 ms-5 text-center'>
                                {'-'}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          )
        })}
      </div>

      <Modal
        show={largedImage !== null}
        onHide={() => setlargedImage(null)}
        size='lg'
        // style={{backgroundColor: 'rgba(0, 0, 0, 0.5)', backdropFilter: 'blur(4px)'}}
      >
        <Modal.Header>
          <Modal.Title>Zoom Image</Modal.Title>
          <div className='btn btn-icon btn-sm btn-active-icon-danger'>
            <i
              className='fa-solid fa-xmark fs-1'
              onClick={() => {
                setlargedImage(null)
              }}
            />
          </div>
        </Modal.Header>
        <Modal.Body
          style={{
            overflowY: 'auto',
            maxHeight: '100vh',
          }}
          className='p-9'
        >
          <Carousel>
            {taskData.map((obj) => {
              return obj?.files.map((file) => {
                return (
                  <Carousel.Item key={file?.id}>
                    <div
                      style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Magnifier
                        imageSrc={`http://192.168.1.251:8002/api/v1/file/${file?.id}`}
                        imageAlt='Enlarged Image'
                        magnifierSize='30%'
                        magnifierBorderSize={3}
                        magnifierBorderColor='rgba(255, 255, 255, 0.8)'
                        // style={{maxWidth: '100%', maxHeight: '100%'}}
                      />
                    </div>
                  </Carousel.Item>
                )
              })
            })}
          </Carousel>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default SubTaskOverview
