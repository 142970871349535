import {Button, Form, Modal} from 'react-bootstrap'
import InputErrorBox from '../../components/InputErrorBox'

const CommentPopup = ({
  isOpen,
  onClose,
  onSubmit,
  comment,
  setComment,
  setCommentError,
  commentError,
}) => {
  return (
    <>
      {console.log(commentError)}
      <Modal show={isOpen} onHide={onClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className='required'>Justification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className='mb-3 '>
              <Form.Control
              
                as='textarea'
                value={comment}
                onChange={(e) => {
                  setComment(e.target.value)
                  setCommentError(null)
                }}
                placeholder='Enter Justification here'
                required
              />
              <InputErrorBox Message={commentError} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className='py-3'>
          <Button className='btn btn-secondary btn-sm font-weight-bold py-2 ' onClick={onClose}>
            Cancel
          </Button>
          <Button className='btn btn-primary btn-sm font-weight-bold py-2' onClick={onSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default CommentPopup
