import React, {useEffect, useState} from 'react'
import {Card, Modal} from 'react-bootstrap'
import {changeTextCapital} from '../../../../_metronic/helpers'
import {useAuth} from '../../../modules/auth'
import {getAssetsByUserId, getAssetsByUserIdforProfile} from '../../../../api/AssetsAPI'
import {toast} from 'react-toastify'
import {useParams} from 'react-router-dom'
import {Center} from '@mantine/core'
import { truncateString } from '../../attendance/ams'

interface IUserAsset {
  id: string
  location: string
  locationType: string
  issueDate: string
  returnDate: string | null
  createdAt: string
  updatedAt: string
  asset: {
    id: string
    tag: string
    brand: string
    serialNo: string
    purchaseDate: string
    description: string
    createdAt: string
    updatedAt: string
    status: string
    category: string
    subCategory: string
    location: string
    locationType: string
    comment: string | null
  }
}

interface IUser {
  id: string
  firstName: string
  lastName: string
  userAssets: IUserAsset[] 
}

const Possesion = () => {
  const {currentUser} = useAuth()
  const [user, setUser] = useState<IUser | null>(null)
  const [APIStatus, setAPIStatus] = useState({
    loading: false,
    error: null,
    submitLoading: false,
  })
  const [reasonModalOpen, setReasonModalOpen] = useState(false)
  const [selectedReason, setSelectedReason] = useState('')
  const params = useParams()
  const userId = params.id ?? (currentUser?.id || '')

  const fetchUserById = () => {
    setAPIStatus({...APIStatus, loading: true})
    getAssetsByUserIdforProfile(userId).then((response) => {
      const {statusCode, data, message} = response
      if (statusCode === 200) {
        setAPIStatus({...APIStatus, loading: false})

        setUser(data)
      } else {
        toast.error(message, {autoClose: 1000})
        setAPIStatus({...APIStatus, error: message})
      }
    })
  }

  useEffect(() => {
    fetchUserById()
  }, [])

  return (

    <>  <Modal
    show={reasonModalOpen}
    onClose={() => setReasonModalOpen(false)}
    animation={false}
    backdrop='static'
    centered
  >
    <div className='modal-header'>
      <h2 className='fw-bolder'>Reason</h2>
      <div
        className='btn btn-icon btn-sm btn-active-icon-danger'
        data-kt-users-modal-action='close'
        style={{cursor: 'pointer'}}
        onClick={() => {
          setReasonModalOpen(false)
        }}
      >
        <i className='fa-solid fa-xmark fs-1' />
      </div>
    </div>
    <div className='card-body'>
      <div className='mt-5 ms-5 mb-5' dangerouslySetInnerHTML={{__html: selectedReason}} />
    </div>
  </Modal>
    <div className='row g-5 g-xl-8'>
      <div className='col-xl-12'>
        <div className='card card-xl-stretch mb-xl-8'>
          <div className='card-header'>
            <div className='card-title'>Asset Details</div>
          </div>
          <div className='card-body'>
            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-bordered'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='pl-1 text-center' style={{minWidth: '80px'}}>
                      {changeTextCapital('Sr.No')}
                    </th>
                    <th className='pl-1 text-center' style={{minWidth: '80px'}}>
                      {changeTextCapital('Asset Tag')}
                    </th>
                    <th className='pl-1 text-center' style={{minWidth: '150px'}}>
                      {changeTextCapital('Serial No')}
                    </th>
                    <th className='pl-1 text-center' style={{minWidth: '120px'}}>
                      {changeTextCapital('Description')}
                    </th>
                    <th className='pl-1 text-center' style={{minWidth: '120px'}}>
                      {changeTextCapital('Location')}
                    </th>
                    <th className='pl-1 text-center' style={{minWidth: '100px'}}>
                      {changeTextCapital('Issue Date')}
                    </th>
                    <th className='pl-1 text-center' style={{minWidth: '100px'}}>
                      {changeTextCapital('Return Date')}
                    </th>
                  </tr>
                </thead>
                {user && user.userAssets && user.userAssets.length > 0 ? (
                  <tbody className='text-gray-800 fw-semibold text-left'>
                    {user?.userAssets.map((asset, index) => (
                      <tr key={asset.id}>
                        <td className='text-center'>{index + 1}</td>
                        <td className='text-center'>{asset?.asset?.tag}</td>
                        <td className='text-center'>{asset?.asset?.serialNo ?? '-'}</td>
                        <td className='text-center cursor-pointer' onClick={() => {
                            setSelectedReason(asset?.asset?.description ?? '-')
                            setReasonModalOpen(true)
                          }}>{truncateString(asset?.asset?.description ?? '-',5)}</td>
                        <td className='text-center'>{asset?.asset?.location ?? '-'}</td>
                        <td className='text-center'>{asset?.issueDate ?? '-'}</td>
                        <td className='text-center'>{asset?.returnDate ?? '-'}</td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan={6}>
                        <Center>No Record Available.</Center>
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Possesion
