import {setupAxios} from '../app/modules/auth'
import {API_BASE_URL} from '../utils/contants'
import {api} from './APIMiddlewares'

// save api integration
export async function addProject(
  currentUser: {id: any},
  InputField: {
    name: string
    type: string
    startDate: string
    dueDate: string
    endDate: string
    description: string
    category: string
    subCategory: string
    status: string
    createdBy: string
    projectManager: {
      id: string
    }
  }
) {
  const {token, tenantId} = setupAxios()
  return await api
    .post(
      `/project`,
      token,
      tenantId,
      {
        body: JSON.stringify({
          name: InputField.name,
          type: InputField.type,
          startDate: InputField.startDate,
          dueDate: InputField.dueDate,
          endDate: InputField.endDate,
          category: InputField.category,
          subCategory: InputField.subCategory,
          status: InputField.status,
          description: InputField.description,
          createdBy: {id: currentUser?.id},
          projectManager: InputField.projectManager,
        }),
      },
      false
    )
    .then((res) => res)
}

export async function addProjects(
  currentUser: {id: any},
  InputField: {
    projectInputField: {
      name: string
      type: string
      startDate: string
      dueDate: string
      endDate: string
      description: string
      category: string
      subCategory: string
      status: string
      createdBy: string
      projectManager: {
        id: string
      }
    }
    projectUserInputField: {
      projectUsers: {
        assigner: string
        user: string
        role: string
        status: string
        allocationPercentage: number
        startDate: string
        endDate: string
        selected: boolean
      }[]
    }
    taskInputField: {
      title: null
      description: null
      startDate: null
      dueDate: null
      priority: null
      status: string
      level: null
      createdBy: {
        id: any
      }
      assigneeId: null

      assignerId: {
        id: string | undefined
      }
    }
  }
) {
  const {token, tenantId} = setupAxios()

  let projectUsers: {
    user: string
    role: string
    status: string
    allocationPercentage: number
    startDate: string
    endDate: string
    assigner: string
    type?: string
  }[] = []
  InputField?.projectUserInputField?.projectUsers
    .filter((item) => item.selected === true)
    .map((user) => {
      // console.log(user)
      projectUsers.push({
        user: user?.user,
        role: user?.role,
        status: user?.status,
        allocationPercentage: Number(user?.allocationPercentage),
        startDate: user?.startDate,
        endDate: user?.endDate,
        assigner: user?.assigner,
      })
    })

  return await api
    .post(
      `/project/create-project`,
      token,
      tenantId,
      {
        body: JSON.stringify({
          project: {
            name: InputField.projectInputField?.name,
            type: InputField.projectInputField?.type,
            startDate: InputField.projectInputField?.startDate,
            dueDate: InputField.projectInputField?.dueDate,
            endDate: InputField.projectInputField?.endDate,
            category: InputField.projectInputField?.category,
            subCategory: InputField.projectInputField?.subCategory,
            status: InputField.projectInputField?.status,
            description: InputField.projectInputField?.description,
            createdBy: {id: currentUser?.id},
            projectManager: InputField.projectInputField?.projectManager,
          },
          projectUser: projectUsers,
          task: {
            title: InputField.taskInputField?.title,
            description: InputField.taskInputField?.description,
            startDate: InputField.taskInputField?.startDate,
            dueDate: InputField.taskInputField?.dueDate,
            priority: InputField.taskInputField?.priority,
            status: InputField.taskInputField?.status,
            level: InputField.taskInputField?.level,
            createdBy: {id: InputField.taskInputField?.createdBy?.id},
            assigneeId: InputField.taskInputField?.assigneeId,
            assignerId: {id: InputField.taskInputField?.assignerId?.id},
          },
        }),
      },
      false
    )
    .then((res) => res)
}

export async function getProjects(id) {
  const {token, tenantId} = setupAxios()
  return await api.get(`/project/${id}/projects`, token, tenantId, false)
}

// show users in add users tab
export async function getUsers() {
  const {token, tenantId} = setupAxios()
  return await api.get(`/activeUsers`, token, tenantId, false)
}

export async function getProjectsUsers(id) {
  const {token, tenantId} = setupAxios()
  return await api.get(`/project/projectUsers/${id}`, token, tenantId, false)
}

export async function getUsersById(id) {
  const {token, tenantId} = setupAxios()
  // console.log(token)
  return await api.get(`/project/projectUser/${id}`, token, tenantId, false)
}

export async function updateUsersById(
  id,
  userById: {
    id: string
    startDate: string
    endDate: string
    status: string
    allocationPercentage: number
    role: string
  }
) {
  const {token, tenantId} = setupAxios()

  return await api
    .put(
      `/project/projectUser/${id}`,
      token,
      tenantId,
      {
        body: JSON.stringify({
          status: userById.status,
          startDate: userById.startDate,
          endDate: userById.endDate,
          allocationPercentage: Number(userById.allocationPercentage),
          role: userById.role,
        }),
      },
      false
    )
    .then((res) => res)
}

// For Particular Project Overview
export async function getProjectsById(id) {
  const {token, tenantId} = setupAxios()
  return await api.get(`/project/${id}`, token, tenantId, false)
}

// Add User In Selective Project
export async function addUserById(InputField: {
  assigner: string
  projectUsers: {
    user: string
    role: string
    status: string
    allocationPercentage: number
    startDate: string
    endDate: string
    selected: boolean
  }[]
  projectId: string
}) {
  // console.log('Input Field', InputField)
  const {token, tenantId} = setupAxios()
  let projectUsers: {
    user: {id: string}
    role: string
    status: string
    allocationPercentage: number
    startDate: string
    endDate: string
    assigner: {
      id: any
    }
    type?: string
  }[] = []
  InputField.projectUsers.map((user) => {
    // console.log(user)
    projectUsers.push({
      user: {id: user.user},
      role: user.role,
      status: user.status,
      allocationPercentage: Number(user.allocationPercentage),
      startDate: user.startDate,
      endDate: user.endDate,
      assigner: {
        id: InputField.assigner,
      },
    })
  })
  return await api
    .post(
      `/project/${InputField.projectId}/users`,
      token,
      tenantId,
      {
        body: JSON.stringify({
          // assigner: {id: InputField.assigner},
          projectUsers: projectUsers,
          // project: {id: InputField.project},
        }),
      },
      false
    )
    .then((res) => res)
}

export async function updateProjectById(
  id,
  currentUser: {id: any},
  InputField: {
    name: string
    type: string
    startDate: string
    dueDate: string
    endDate: string
    description: string
    category: string
    subCategory: string
    projectManager: {
      id: string
    }
    status: string
  }
) {
  const {token, tenantId} = setupAxios()

  return await api
    .put(
      `/project/${id}`,
      token,
      tenantId,
      {
        body: JSON.stringify({
          name: InputField.name,
          type: InputField.type,
          startDate: InputField.startDate,
          dueDate: InputField.dueDate,
          endDate: InputField.endDate,
          description: InputField.description,
          category: InputField.category,
          subCategory: InputField.subCategory,
          projectManager: InputField.projectManager,
          status: InputField.status,
        }),
      },
      false
    )
    .then((res) => res)
}

export async function addSubTasksData(
  // currentUser: {id: any},
  id,
  taskData: {
    title: string
    description: string
    startDate: string
    dueDate: string
    priority: string
    status: string
    level: string

    // project: {
    //   id: any
    // }
    createdBy: {
      id: any
    }
    assigneeId: {
      id: string
    }
    assignerId: {
      id: string
    }
    parentTask: {
      id: string
    }
    file: File[]
  }
) {
  const {token, tenantId} = setupAxios()
  const formData = new FormData()

  formData.append('title', taskData?.title)
  formData.append('description', taskData?.description)
  formData.append('startDate', taskData?.startDate)
  formData.append('dueDate', taskData?.dueDate)
  formData.append('priority', taskData?.priority)
  formData.append('status', taskData?.status)
  formData.append('level', taskData?.level)
  formData.append('createdBy[id]', String(taskData?.createdBy?.id))
  formData.append('assigneeId[id]', String(taskData?.assigneeId?.id))
  formData.append('assignerId[id]', String(taskData?.assignerId?.id))
  formData.append('parentTask[id]', String(taskData?.parentTask?.id))

  // if (taskData?.file !== null) {
  //   formData.append('file', taskData?.file)
  // }

  taskData.file.forEach((file, index) => {
    formData.append(`files`, file)
  })

  const res = await fetch(API_BASE_URL + `/project/${id}/task`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Tenant-Id': `${tenantId}`,
    },

    body: formData,
  })
  return await res.json()
}

export async function addTasksData(
  // currentUser: {id: any},
  id,
  taskData: {
    title: null | string
    description: null | string
    startDate: string
    dueDate: string
    priority: string
    status: string
    level: string

    // project: {
    //   id: any
    // }
    createdBy: {
      id: any
    }
    assigneeId: null | {id: string}
    assignerId: {
      id: string
    }

    file: File[]
    isDefect: boolean
  }
) {
  const {token, tenantId} = setupAxios()
  const formData = new FormData()

  if (taskData.title !== null) formData.append('title', taskData?.title)
  if (taskData.description !== null) formData.append('description', taskData?.description)
  formData.append('startDate', taskData?.startDate)
  formData.append('dueDate', taskData?.dueDate)
  formData.append('priority', taskData?.priority)
  formData.append('status', taskData?.status)
  formData.append('level', taskData?.level)
  formData.append('createdBy[id]', String(taskData?.createdBy?.id))
  if (taskData.assigneeId !== null) formData.append('assigneeId[id]', taskData.assigneeId.id)
  formData.append('assignerId[id]', String(taskData?.assignerId?.id))
  formData.append('isDefect', String(taskData?.isDefect))

  // if (taskData?.file !== null) {
  //   formData.append('file', taskData?.file)
  // }

  taskData.file.forEach((file, index) => {
    formData.append(`files`, file)
  })
  const res = await fetch(API_BASE_URL + `/project/${id}/task`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Tenant-Id': `${tenantId}`,
    },

    body: formData,
  })
  return await res.json()
}

export async function getTaskData(id, userId, date, status, take, page, sortBy) {
  const {token, tenantId} = setupAxios()
  const queryParams = new URLSearchParams({
    userId: userId ?? null,
    date: date ?? null,
    status: status ?? null,
    take: take ?? null,
    page: page ?? null,
    sortBy: sortBy ?? null,
  })
  return await api.get(`/project/${id}/tasks?${queryParams.toString()}`, token, tenantId, false)
}

export async function getCurrentUserTask(id, userId, date, status) {
  const {token, tenantId} = setupAxios()
  const queryParams = new URLSearchParams({
    date: date ?? null,
    status: status ?? null,
  })
  return await api.get(
    `/user/${userId}/project/${id}/tasks?${queryParams.toString()}`,
    token,
    tenantId,
    false
  )
}

export async function getTaskDetailes(taskId) {
  const {token, tenantId} = setupAxios()
  return await api.get(`/task/${taskId}`, token, tenantId, false)
}

export async function updateTaskById(
  taskId,
  // currentUser: {id: any},
  InputField: {
    title: string
    description: null | string
    status: string
    file: File | null
    priority: null | string
    level: null | string
    startDate: string
    dueDate: string
    assigneeId: {
      id: string | null
    }
  }
) {
  const {token, tenantId} = setupAxios()
  // const formData=new FormData()
  // formData.append('title', InputField.title)
  // formData.append('description', InputField.description)
  // formData.append('status', InputField.status)
  // if (InputField?.file !== null) {
  //   formData.append('file', InputField.file)
  // }

  const body: any = {
    assigneeId: InputField.assigneeId?.id !== '' ? InputField.assigneeId : null,
  }

  // Optional fields will be added to the body if provided
  if (InputField.title !== undefined) {
    body.title = InputField.title !== '' ? InputField.title : null
  }
  if (InputField.description !== undefined) {
    body.description = InputField.description !== '' ? InputField.description : null
  }
  if (InputField.status !== undefined) {
    body.status = InputField.status !== '' ? InputField.status : null
  }
  if (InputField.priority !== undefined) {
    body.priority = InputField.priority !== '' ? InputField.priority : null
  }
  if (InputField.level !== undefined) {
    body.level = InputField.level !== '' ? InputField.level : null
  }
  if (InputField.startDate !== undefined) {
    body.startDate = InputField.startDate !== '' ? InputField.startDate : null
  }
  if (InputField.dueDate !== undefined) {
    body.dueDate = InputField.dueDate !== '' ? InputField.dueDate : null
  }
  if (InputField.file !== undefined) {
    body.file = InputField.file
  }
  return await api
    .put(
      `/task/${taskId}`,
      token,
      tenantId,
      {
        body: JSON.stringify(body),
      },
      false
    )
    .then((res) => res)
}

// export async function updateTaskStatusById(taskId, StatusField: {status: string}) {
//   const {token, tenantId} = setupAxios()
//   return await api
//     .put(
//       `/task/${taskId}/status`,
//       token,
//       tenantId,
//       {
//         body: JSON.stringify({status: StatusField?.status}),
//       },
//       false
//     )
//     .then((res) => res)
// }

export async function addComment(
  taskId: {id: any},
  InputField: {
    comment: string
    commenter: {
      id: string
    }
    file: File | null
  }
) {
  const {token, tenantId} = setupAxios()
  const formData = new FormData()

  formData.append('comment', InputField.comment)
  formData.append('commenter[id]', String(InputField.commenter.id))

  if (InputField.file !== null) {
    formData.append('file', InputField.file)
  }

  const res = await fetch(API_BASE_URL + `/task/${taskId}/comment`, {
    method: 'POST',
    headers: {
      // 'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
      'X-Tenant-Id': `${tenantId}`,
    },
    body: formData,
  })
  return await res.json()
}

export async function getComment(id: string) {
  const {token, tenantId} = setupAxios()
  return await api.get(`/task/${id}/comment`, token, tenantId, false)
}

export async function getCommentById(id: string) {
  const {token, tenantId} = setupAxios()
  return await api.get(`/comment/${id}`, token, tenantId, false)
}

export async function updateComment(
  id,
  InputField: {
    comment: string
    commenter: {
      id: string
    }
  }
) {
  const {token, tenantId} = setupAxios()

  return await api
    .put(
      `/comment/${id}`,
      token,
      tenantId,
      {
        body: JSON.stringify({
          comment: InputField.comment,
          commenter: InputField.commenter,
        }),
      },
      false
    )
    .then((res) => res)
}

export async function deleteComments(id: string) {
  const {token, tenantId} = setupAxios()
  return await api.delete(`/comment/${id}`, token, tenantId, false)
}

export async function getStatusCount(userId, projectId: string) {
  const {token, tenantId} = setupAxios()
  const queryParams = new URLSearchParams({
    user: userId ?? null,
  })
  return await api.get(
    `/project/${projectId}/taskCount?${queryParams.toString()}`,
    token,
    tenantId,
    false
  )
}

export async function getUsersAllocationsData(userId: string) {
  const {token, tenantId} = setupAxios()
  return await api.get(`/project/getProjectUsers/user/${userId}`, token, tenantId, false)
}

export async function getDefects(id, userId, date, status, take, page) {
  const {token, tenantId} = setupAxios()
  const queryParams = new URLSearchParams({
    userId: userId ?? null,
    date: date ?? null,
    status: status ?? null,
    take: take ?? null,
    page: page ?? null,
  })
  return await api.get(`/project/${id}/defects?${queryParams.toString()}`, token, tenantId, false)
}
