import React from 'react'
import Project from './project/Project'
import {Toolbar1} from '../../../_metronic/layout/components/toolbar/Toolbar1'
// import CreateNewProject from './project/CreateNewProject'

const Organization = () => {
  return (
    <>
      <div className='mt-5'>
        <Project />
        {/* <CreateNewProject /> */}
      </div>
    </>
  )
}

export default Organization
