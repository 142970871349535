import {useContext, useEffect, useState} from 'react'

import {Button, Center} from '@mantine/core'
import {Modal} from 'react-bootstrap'
import {
  changeTextCapital,
  checkRole,
  sortEmailsDescending,
} from '../../../_metronic/helpers/AssetHelpers'
import {Toolbar1} from '../../../_metronic/layout/components/toolbar/Toolbar1'
import {EDIT_ROW_COLORS} from '../../../utils/contants'
import InputErrorBox from '../../components/InputErrorBox'
import roleContext from '../../context/roleContext'
import {useAuth} from '../../modules/auth'
import {RoleContextValue} from '../../types/OtherTypes'
import {
  cancelSubmit,
  deleteHoliday,
  getHoliday,
  getHolidayById,
  handleInputChange,
  handleSubmit,
  validateForm,
} from './HolidayFunction'
import {ToastContainer} from 'react-toastify'

const Holiday = () => {
  let roleState: RoleContextValue = useContext(roleContext)
  const [holiday, setHoliday] = useState([{id: '', holidayName: '', date: ''}])
  const [editId, setEditId] = useState(null)
  const [inputError, setInputError] = useState<any>({})
  const [APIStatus, setAPIStatus] = useState({
    loading: false,
    error: null,
    submitLoading: false,
  })
  const [SubmitAPIStatus, setSubmitAPIStatus] = useState({
    loading: false,
  })
  const {currentUser} = useAuth()
  const isAdminUser = currentUser?.isAdmin || false
  const initialState = {holidayName: '', date: ''}
  const [inputField, setInputField] = useState(initialState)
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)

  useEffect(() => {
    let mounted = true
    if (mounted) {
      getHoliday((fetchedEmails) => {
        const sortedEmails = sortEmailsDescending(fetchedEmails)
        setHoliday(sortedEmails)
        setAPIStatus({
          loading: false,
          error: null,
          submitLoading: false,
        })
      }, setAPIStatus)
      setIsAddUserModalOpen(false)
      setIsEditModalOpen(false)
    }
    return () => {
      mounted = false
    }
  }, [])

  const handleClose = () => {
    setIsAddUserModalOpen(false)
  }

  return (
    <>
      <ToastContainer position={'bottom-center'} />

      <Modal
        show={isAddUserModalOpen}
        onClose={() => setIsAddUserModalOpen(false)}
        animation={false}
        backdrop='static'
        onRequestClose={handleClose}
        centered
      >
        {/* begin::modal header--add holiday */}
        <div className='modal-header'>
          <h2 className='fw-bolder'>Add Holiday</h2>
          <div
            className='btn btn-icon btn-sm btn-active-icon-danger'
            data-kt-users-modal-action='close'
            style={{cursor: 'pointer'}}
            onClick={() => {
              cancelSubmit(setInputField, initialState, setEditId, setInputError)
              handleClose()
            }}
          >
            <i className='fa-solid fa-xmark fs-1' />
          </div>
        </div>
        <div className='m-3'>
          <div className='fv-row mb-5 col-md-12'>
            <label className='fs-6 fw-semibold mb-2'>Holiday Name</label>
            <span className='text-danger ms-1 fs-4 fw-bold'>*</span>

            <input
              className='form-control'
              type='text'
              placeholder='Holiday Name...'
              name='holidayName'
              value={inputField.holidayName}
              onChange={(event) => handleInputChange(event, setInputField, inputField)}
            />
            {<InputErrorBox Message={inputError.holidayName} />}
          </div>
          <div className='fv-row mb-5 col-md-12'>
            <label className='fs-6 fw-semibold mb-2'>Date</label>
            <span className='text-danger ms-1 fs-4 fw-bold'>*</span>

            <input
              className='form-control'
              type='date'
              name='date'
              value={inputField.date}
              onChange={(event) => handleInputChange(event, setInputField, inputField)}
            />
            {<InputErrorBox Message={inputError.date} />}
          </div>
          <div className='d-flex flex-wrap justify-content-end align-items-center'>
            <Button
              onClick={() => {
                cancelSubmit(setInputField, initialState, setEditId, setInputError)
                setInputError('')
              }}
              type='button'
              className='btn btn-secondary me-2 btn-sm font-weight-bold'
              style={{marginLeft: '15px'}}
              disabled={SubmitAPIStatus?.loading}
            >
              Clear
            </Button>
            <Button
              loaderPosition='center'
              type='submit'
              className='btn btn-primary btn-sm font-weight-bold h-10'
              loading={SubmitAPIStatus.loading}
              onClick={() => {
                handleSubmit(
                  inputField,
                  getHoliday,
                  setHoliday,
                  setEditId,
                  initialState,
                  setInputField,
                  editId,
                  cancelSubmit,
                  setAPIStatus,
                  setInputError,
                  validateForm,
                  setSubmitAPIStatus,
                  setIsAddUserModalOpen
                )
              }}
            >
              {editId !== null ? 'Update' : 'Save'}
            </Button>
          </div>
        </div>
      </Modal>
      {/* //end of the form */}

      <div className='row g-5 g-xl-8'>
        <div className='col-xl-12'>
          {/* begin:: card */}
          <div className='card card-xl-stretch mb-xl-8'>
            {/* begin:: card header */}
            <div className='card-header'>
              <div className='card-title'>Holidays</div>
              <div
                className='d-flex mt-4 justify-content-end'
                data-kt-user-table-toolbar='base'
                style={{height: '3rem'}}
              >
                {/* Begin::Add User button */}

                <button
                  hidden={!checkRole(['Holidays'], roleState.features, 'edit')}
                  type='button'
                  className='btn btn-sm btn-primary'
                  onClick={() => {
                    setIsAddUserModalOpen(true)
                  }}
                >
                  Add Holiday
                </button>

                {/* end::Add user button */}
              </div>
            </div>
            {/* begin:: card body */}
            <div className='card-body'>
              {APIStatus.submitLoading ? (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              ) : APIStatus.error != null ? (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>{APIStatus.error}</div>
                </div>
              ) : (
                <div className='table-responsive  '>
                  <table
                    className='table 
                    table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-bordered'
                  >
                    <thead>
                      <tr className='fw-bold text-muted'>
                        <th className='pl-1 text-center text-muted' style={{minWidth: '80px'}}>
                          {changeTextCapital('Sr.No')}
                        </th>
                        <th className='pl-1 flex-start text-muted' style={{minWidth: '150px'}}>
                          {changeTextCapital('Holiday Name')}
                        </th>
                        <th className='pl-1 text-center text-muted' style={{minWidth: '120px'}}>
                          {changeTextCapital('Day')}
                        </th>
                        <th className='pl-1 text-center text-muted' style={{minWidth: '120px'}}>
                          {changeTextCapital('Date')}
                        </th>

                        <th
                          className='pl-1 text-center text-muted'
                          hidden={!checkRole(['Holidays'], roleState.features, 'edit')}
                          style={{minWidth: '100px'}}
                        >
                          {changeTextCapital('Actions')}
                        </th>
                      </tr>
                    </thead>
                    {holiday?.length > 0 ? (
                      <tbody className='text-gray-800 fw-semibold text-left'>
                        {holiday.map((obj: any, index: number) => (
                          <tr
                            className={`${editId === obj?.id ?? '-' ? `${EDIT_ROW_COLORS} ` : ''}`}
                            key={obj?.id ?? '-'}
                          >
                            <td className='text-center'>{index + 1}</td>
                            <td className='flex-start'>{obj?.holidayName ?? '-'}</td>
                            <td className='text-center'>
                              {obj?.date
                                ? new Date(obj.date).toLocaleDateString(undefined, {
                                    weekday: 'long',
                                  })
                                : '-'}
                            </td>
                            <td className='text-center'>
                              {obj?.date
                                ? new Date(obj.date)
                                    .toLocaleDateString(undefined, {
                                      day: 'numeric',
                                      month: 'numeric',
                                      year: 'numeric',
                                    })
                                    .replace(/(\d+)\/(\d+)\/(\d+)/, '$2/$1/$3')
                                : '-'}
                            </td>

                            <td hidden={!checkRole(['Holidays'], roleState.features, 'edit')}>
                              <div className='d-flex justify-content-center flex-shrink-0'>
                                <button
                                  className='btn btn-icon btn-bg-light btn-active-color-info btn-sm me-1'
                                  onClick={() => {
                                    document.getElementById('holidayName')?.focus()
                                    getHolidayById(
                                      obj.id,
                                      inputField,
                                      setInputField,
                                      setEditId,
                                      setInputError
                                    )
                                    setIsEditModalOpen(true)
                                    setIsAddUserModalOpen(true)
                                  }}
                                >
                                  <i className='far fa-edit'></i>
                                </button>
                                <button
                                  className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                                  onClick={() =>
                                    deleteHoliday(
                                      obj.id,
                                      getHoliday,
                                      setHoliday,
                                      setAPIStatus,
                                      cancelSubmit,
                                      setInputField,
                                      initialState,
                                      setEditId,
                                      setInputError
                                    )
                                  }
                                >
                                  <i className='fa fa-trash'></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td colSpan={5}>
                            <Center>No Record Available.</Center>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Holiday
