import {Button, Center, Loader} from '@mantine/core'
import React, {useContext, useEffect, useState} from 'react'
import {Col, Form, Modal, Row} from 'react-bootstrap'
import {Link, useParams} from 'react-router-dom'
import {
  changeTextCapital,
  changeTextSentenceCase,
  checkRole,
  dateFormat,
} from '../../../../_metronic/helpers'

import {faChevronLeft, faChevronRight, faPlus} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import axios from 'axios'
import {getProjects, getUsers} from '../../../../api/Organization'
import {API_BASE_URL, Completed, Created, In_Progress, On_Hold} from '../../../../utils/contants'
import InputErrorBox from '../../../components/InputErrorBox'
import Select2 from '../../../components/select2'
import roleContext from '../../../context/roleContext'
import {setupAxios, useAuth} from '../../../modules/auth'
import {RoleContextValue} from '../../../types/OtherTypes'
import AddUsers from './AddUsers'
import {validateAddUsers} from './AddUsersFunction'
import CreateTask from './CreateTask'
import {
  cancelSubmit,
  handleInputChange,
  handleOtherInputChange,
  handleSubmit,
  validateForm,
} from './ProjectFunction'
import ProjectStepper from './ProjectStepper'
import {CKEditor} from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

const steps = ['step 1', 'step 2', 'step 3']

const Project = () => {
  let roleState: RoleContextValue = useContext(roleContext)
  const {currentUser} = useAuth()
  const params = useParams()

  const [project, setProject] = useState([])
  const [loading, setLoading] = useState(true)
  const [initialLoad, setInitialLoad] = useState(true)

  const [inputError, setInputError] = useState<any>({})

  const [APIStatus, setAPIStatus] = useState({
    loading: false,
    error: null,
    submitLoading: false,
  })

  const [SubmitAPIStatus, setSubmitAPIStatus] = useState({
    loading: false,
  })

  const initialState = {
    id: '',
    name: '',
    type: '',
    startDate: '',
    dueDate: '',
    endDate: '',
    description: '',
    category: '',
    subCategory: '',
    status: 'Created',
    project_users: [],
    createdBy: '',
    projectManager: {
      id: '',
    },
  }

  const [inputField, setInputField] = useState(initialState)

  const userInitialState = {
    projectUsers: [
      {
        assigner: '',
        user: '',
        role: '',
        status: 'On Boarded',
        allocationPercentage: 100,
        startDate: '',
        endDate: '',
        selected: false,
      },
    ],
  }

  const [projectUserInputField, setProjectUserInputField] = useState(userInitialState)
  const [userData, setUserData] = useState<any>([])

  const [taskData, setTaskData] = useState({
    id: null,
    title: null,
    description: null,
    startDate: null,
    dueDate: null,
    endDate: null,
    priority: null,
    status: 'Todo',
    level: null,
    createdBy: {
      id: currentUser?.id,
    },
    assigneeId: null,
    assignerId: {
      id: currentUser?.id,
    },
  })

  const [firstStepCompleted, setFirstStepCompleted] = useState(false)

  const [usersOptions, setUsersOptions] = useState<{label: string; value: string}[]>([])
  const sortedUserNames = usersOptions.slice().sort((a, b) => a.label.localeCompare(b.label))

  const [showModal, setShowModal] = useState(false)

  const newProject = () => {
    setShowModal(true)
  }

  const closeProject = () => {
    setShowModal(false)
  }

  const [currentStep, setCurrentStep] = useState(0)

  const handleNextStep = () => {
    if (currentStep === 0) {
      const {error: formError, count: formErrorCount} = validateForm({
        projectInputField: inputField,
      })

      if (formErrorCount > 0) {
        setInputError(formError)
        return
      } else {
        setCurrentStep(currentStep + 1)
        setInputError('')
        setFirstStepCompleted(true)
        return
      }
    }

    const {error: addUserError, count: addUserErrorCount} = validateAddUsers({
      projectUsers: projectUserInputField?.projectUsers,
    })

    if (addUserErrorCount > 0) {
      setInputError(addUserError)
    } else {
      setCurrentStep(currentStep + 1)
    }
  }

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1)
  }

  const handleFinishStep = () => {
    closeProject()
  }

  const [showUsers, setShowUsers] = useState(false)
  const [selectedProjectUsers, setSelectedProjectUsers] = useState<Array<any>>([])

  const userShowsModalOpen = (users: Array<any>) => {
    setSelectedProjectUsers(users)
    setShowUsers(true)
  }

  const userShowModalClose = () => {
    setShowUsers(false)
  }

  const [selectedFile, setSelectedFile] = useState<String | null>(
    'media/avatars/blank-profile-user.png'
  )

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]

    const imageUrl = file ? URL.createObjectURL(file) : ''
    setSelectedFile(imageUrl)
  }

  const fetchNames = () => {
    const {token, tenantId} = setupAxios()
    axios
      .get(API_BASE_URL + `/activeUsers`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-Tenant-Id': `${tenantId}`,
        },
      })
      .then((response: any) => {
        let userData: {label: string; value: string}[] = []
        response.data.data.map(
          (obj: {firstName: string; lastName: string; id: string}, index: number) => {
            userData.push({
              label: obj.firstName + ' ' + obj.lastName,
              value: obj.id,
            })
          }
        )

        setUsersOptions(userData)
      })
      .catch((error) => {
        console.error('Error fetching names:', error)
      })
  }

  const fetchUserData = async () => {
    try {
      const res = await getUsers()
      if (res.statusCode === 200) {
        setUserData(res.data ?? [])
        let projectUsers = res.data.map((user) => ({
          selected: false,
          user: {id: user?.id, firstName: user?.firstName, lastName: user?.lastName},
          startDate: '',
          endDate: '',
          role: '',
          allocationPercentage: 100,
          status: 'On Boarded',
          assigner: {
            id: currentUser?.id,
          },
        }))

        setProjectUserInputField({...projectUserInputField, projectUsers: projectUsers})
      }
    } catch (error) {
      console.error('Error fetching user data:', error)
    }
  }

  useEffect(() => {
    let mounted = true

    const fetchData = async () => {
      try {
        setAPIStatus((prev) => ({...prev, loading: true}))

        const projects = await getProjects(currentUser?.id)

        if (mounted) {
          setProject(projects.data)
          // console.log('Project', project)
          setAPIStatus((prev) => ({...prev, loading: false, error: null}))
          setLoading(false)
          setInitialLoad(false)
        }
      } catch (error) {
        if (mounted) {
          if (error) {
            console.log('Error for get project', error)
          } else {
            console.log('An unknown error occurred.', error)
          }
        }
      }
    }

    fetchData()

    return () => {
      mounted = false
    }
  }, [])

  // useEffect(() => {
  //   let mounted = true

  //   const fetchNames = async () => {
  //     try {
  //       const {token} = setupAxios()
  //       const response = await axios.get(API_BASE_URL + `/users`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       })
  //       if (mounted) {
  //         let userData = response.data.data.map((obj) => ({
  //           label: obj.firstName + ' ' + obj.lastName,
  //           value: obj.id,
  //         }))
  //         setUsersOptions(userData)
  //       }
  //     } catch (error) {
  //       console.error('Error fetching names:', error)
  //     }
  //   }

  //   if (mounted) {
  //     fetchUserData()
  //     fetchNames()
  //   }

  //   return () => {
  //     mounted = false
  //   }
  // }, [inputField?.startDate, inputField?.dueDate, currentUser?.id, projectUserInputField])

  // console.log('Project', project.length)
  return (
    <>
      {/* {console.log('Project after return', project.length)} */}
      {/* {console.log('startDate', inputField?.startDate)}
      // {console.log('dueDate', inputField?.dueDate)} */}

      {loading && initialLoad ? (
        <Center>
          <Loader></Loader>
        </Center>
      ) : (
        <>
          <div className=''>
            {project?.length > 0 ? (
              <>
                <div className='d-flex justify-content-between'>
                  <div>
                    <h1 className='text-gray-900 fw-semibolder fs-4'>My Project</h1>
                  </div>
                  <div className='d-flex justify-content-end'>
                    <span hidden={!checkRole(['Project Management'], roleState.features, 'edit')}>
                      <Button
                        variant='filled'
                        color=''
                        className='d-flex flex-end me-3 shadow'
                        onClick={() => {
                          newProject()
                          fetchNames()
                          fetchUserData()
                        }}
                      >
                        <span>
                          <FontAwesomeIcon icon={faPlus} />{' '}
                        </span>{' '}
                        <span className='ms-2'>Create</span>{' '}
                      </Button>
                    </span>
                  </div>
                </div>
                <div className='row'>
                  {project?.map((obj: any) => {
                    const maxUserSymbols = 6
                    return (
                      <Col md={4}>
                        <Link to={`/projectOverview/${obj?.id}`}>
                          <div className='card mt-5 mb-5 me-5 shadow-sm' key={obj?.id}>
                            <div className='card-header'>
                              <div className='card-title m-0'>
                                <div className='symbol symbol-50px w-50px bg-light'>
                                  <img
                                    src='/media/avatars/NoImage.png'
                                    alt='card'
                                    className='p-3'
                                  />
                                </div>
                              </div>
                              <div className='card-toolbar'>
                                <span
                                  className={`fw-bolder me-auto px-4 py-3 ${
                                    obj?.status === Created
                                      ? 'badge badge-light-primary'
                                      : obj?.status === In_Progress
                                        ? 'badge badge-light-info'
                                        : obj?.status === Completed
                                          ? 'badge badge-light-success'
                                          : obj?.status === On_Hold
                                            ? 'badge badge-light-warning'
                                            : 'badge badge-light-danger'
                                  }`}
                                >
                                  {obj?.status ?? '-'}
                                </span>
                              </div>
                            </div>
                            <div className='card-body p-6'>
                              <div
                                className='fs-3 fw-bolder'
                                style={{
                                  maxWidth: '225px',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  cursor: 'pointer',
                                }}
                                title={obj?.name}
                              >
                                {changeTextSentenceCase(obj?.name ?? '-')}
                              </div>

                              <div className='mt-1 mb-7 fs-5'>
                                <span className='badge badge-light'>{obj?.type ?? '-'}</span>
                                <span className='badge badge-light'>{obj?.category ?? '-'}</span>
                              </div>

                              <div className='d-flex flex-wrap mb-5'>
                                <div className='border border-gray-300 border-dashed rounded py-3 px-4 me-7 mb-3'>
                                  {obj?.startDate.length > 0 ? (
                                    <div className='fs-6 text-gray-800 fw-bolder'>
                                      {dateFormat(obj?.startDate ?? '-')}
                                    </div>
                                  ) : (
                                    <div className='fs-6 text-gray-800 fw-bolder'>{'-'}</div>
                                  )}
                                  <div className='text-gray-400 fw-bold'>Start Date</div>
                                </div>
                                <div className='border border-gray-300 border-dashed rounded py-3 px-4 me-7 mb-3'>
                                  {obj?.dueDate.length > 0 ? (
                                    <div className='fs-6 text-gray-800 fw-bolder'>
                                      {dateFormat(obj?.dueDate ?? '-')}
                                    </div>
                                  ) : (
                                    <div className='fs-6 text-gray-800 fw-bolder'>{'-'}</div>
                                  )}

                                  <div className='text-gray-400 fw-bold'>Due Date</div>
                                </div>
                              </div>
                              <div className='symbol-group symbol-hover flex-nowrap'>
                                {obj?.projectUsers?.length > 0 ? (
                                  obj?.projectUsers?.slice(0, maxUserSymbols)?.map((user) => {
                                    return (
                                      <div
                                        className='symbol symbol-35px symbol-circle  shadow-sm'
                                        data-bs-toggle='tooltip'
                                        title={`${user?.user.firstName ?? '-'} ${
                                          user?.user?.lastName ?? '-'
                                        }`}
                                      >
                                        <span
                                          className={`symbol-label border border-gray-600  text-inverse-${
                                            user?.user?.textColor ?? 'gray-400'
                                          } fs-8 fw-bold`}
                                        >
                                          {' '}
                                          {changeTextCapital(user?.user?.firstName[0] ?? '-')}{' '}
                                        </span>
                                      </div>
                                    )
                                  })
                                ) : (
                                  <div
                                    className='symbol symbol-35px symbol-circle  shadow-sm'
                                    data-bs-toggle='tooltip'
                                    title='Add More Users'
                                  >
                                    <span
                                      className={`symbol-label border border-gray-600 fs-8 fw-bold`}
                                    >
                                      {' '}
                                      <FontAwesomeIcon icon={faPlus} />{' '}
                                    </span>
                                  </div>
                                )}

                                {obj?.projectUsers?.length > maxUserSymbols && (
                                  <a
                                    className='symbol symbol-35px symbol-circle'
                                    data-bs-toggle='Modal'
                                    data-bs-target='#kt_modal_view_users'
                                    onClick={(e) => {
                                      e.preventDefault()
                                      userShowsModalOpen(obj?.projectUsers)
                                    }}
                                    key={`remainingUsers_${obj?.id}`}
                                  >
                                    <span className='symbol-label border border-gray-600 bg-light text-gray-800 fs-8 fw-bold'>
                                      +{obj?.projectUsers.length - maxUserSymbols}{' '}
                                    </span>
                                  </a>
                                )}
                              </div>
                            </div>
                          </div>
                        </Link>
                      </Col>
                    )
                  })}
                </div>
              </>
            ) : (
              <>
                <div className='text-center'>
                  <img
                    src='/media/avatars/noProjectFound.png'
                    alt='noReacordAvailable'
                    className='w-70 h-100px h-sm-325px'
                  />
                </div>
                <div className='fw-bold text-gray-600 text-center mt-3 mb-2'>
                  Oops! No projects found.
                </div>
                <div className='d-flex justify-content-center'>
                  <Button
                    variant='subtle'
                    color=''
                    className='d-flex flex-end me-3'
                    onClick={() => {
                      newProject()
                      fetchNames()
                      fetchUserData()
                    }}
                  >
                    <u>
                      <span
                        className='ms-2'
                        hidden={!checkRole(['Project Management'], roleState.features, 'edit')}
                      >
                        Create Project
                      </span>
                    </u>{' '}
                  </Button>
                </div>
              </>
            )}
          </div>
        </>
      )}

      <>
        <Modal show={showModal} onHide={closeProject} backdrop='static' centered size='lg'>
          <Modal.Header>
            <Modal.Title>
              {currentStep === 0 && (
                <div>
                  <span className='fw-bold'> Create New Project</span>
                </div>
              )}
              {currentStep === 1 && (
                <div>
                  <span className='fw-bold'>Add Users</span>
                </div>
              )}
              {currentStep === 2 && (
                <div>
                  <span className='fw-bold'>Create Task</span>
                </div>
              )}
            </Modal.Title>
            <button
              className='btn btn-icon btn-sm btn-active-icon-danger'
              disabled={SubmitAPIStatus?.loading}
            >
              <i
                className='fa-solid fa-xmark fs-1'
                onClick={() => {
                  setShowModal(false)
                  cancelSubmit(setInputField, initialState)
                  setInputError('')
                  setCurrentStep(0)
                }}
              />
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className='mb-5'>
              <ProjectStepper currentStep={currentStep} />
            </div>

            {currentStep === 0 && (
              <>
                <Modal.Body>
                  <Row className='mb-4'>
                    <Col md={5}>
                      <div className='mb-5 m-3'>
                        <Form.Label className='mb-3 fw-bold required'>Project Name</Form.Label>
                        <Form.Control
                          type='text'
                          name='name'
                          placeholder='Project Name'
                          autoComplete='off'
                          value={inputField?.name}
                          onChange={(event) => handleInputChange(event, setInputField)}
                        />
                        {<InputErrorBox Message={inputError.name} />}
                      </div>

                      {/* <div className='mb-5 m-3'>
                        <Form.Label className='mb-3 fw-bold required'>Status</Form.Label>

                        <Select2
                          name='status'
                          data={[
                            {label: 'Created', value: 'Created'},
                            {label: 'On Hold', value: 'On Hold'},
                            {label: 'In Progress', value: 'In Progress'},
                            {label: 'Completed', value: 'Completed'},
                          ].sort((a, b) => a.label.localeCompare(b.label))}
                          value={inputField?.status}
                          onChange={(selectedOption: any) => {
                            // console.log(selectedOption)
                            setInputField((prevInputField) => ({
                              ...prevInputField,
                              status: selectedOption,
                            }))
                          }}
                        />
                        {<InputErrorBox Message={inputError.status} />}
                      </div> */}

                      <div className='mb-5 m-3'>
                        <Form.Label className='mb-3 fw-bold required'>Category</Form.Label>
                        <Select2
                          name='category'
                          data={[
                            {label: 'FPP', value: 'FPP'},
                            {label: 'T & M', value: 'T & M'},
                            {label: 'Unbilled', value: 'unbilled'},
                          ].sort((a, b) => a.label.localeCompare(b.label))}
                          value={inputField?.category}
                          onChange={(selectedOption: any) => {
                            // console.log(selectedOption)
                            setInputField((prevInputField) => ({
                              ...prevInputField,
                              category: selectedOption,
                            }))
                          }}
                        />
                        {<InputErrorBox Message={inputError.category} />}
                      </div>

                      <div className='mb-5 m-3'>
                        <Form.Label className='fw-bold required'>Sub-Category</Form.Label>
                        <Select2
                          name='subCategory'
                          data={[
                            {label: 'Professional Services', value: 'Professional Services'},
                            {label: 'Managed Services', value: 'Managed Services'},
                            {label: 'Staffing', value: 'Staffing'},
                          ].sort((a, b) => a.label.localeCompare(b.label))}
                          value={inputField?.subCategory}
                          onChange={(selectedOption: any) => {
                            // console.log(selectedOption)
                            setInputField((prevInputField) => ({
                              ...prevInputField,
                              subCategory: selectedOption,
                            }))
                          }}
                        />
                        {<InputErrorBox Message={inputError.subCategory} />}
                      </div>

                      <div className='mb-5 m-3'>
                        <Form.Label className='mb-3 fw-bold required'>Project Type</Form.Label>

                        <Select2
                          name='type'
                          data={[
                            {label: 'Web', value: 'Web'},
                            {label: 'Android', value: 'Android'},
                            {label: 'ios', value: 'ios'},
                            {label: 'Data Center', value: 'DataCenter'},
                            {label: 'Infra', value: 'Infra'},
                          ].sort((a, b) => a.label.localeCompare(b.label))}
                          value={inputField?.type}
                          onChange={(selectedOption: any) => {
                            // console.log(selectedOption)
                            setInputField((prevInputField) => ({
                              ...prevInputField,
                              type: selectedOption,
                            }))
                          }}
                        />

                        {<InputErrorBox Message={inputError.type} />}
                      </div>
                    </Col>
                    <Col md={1}></Col>
                    <Col md={5}>
                      <div className='mb-5 m-3'>
                        <Form.Label className='mb-3 fw-bold required'>
                          Project Start Date
                        </Form.Label>
                        <input
                          type='Date'
                          name='startDate'
                          className='form-control'
                          value={inputField?.startDate}
                          onChange={(event) => handleInputChange(event, setInputField)}
                        />
                        {<InputErrorBox Message={inputError.startDate} />}
                      </div>
                      <div className='mb-5 m-3'>
                        <Form.Label className='mb-3 fw-bold required'>Project Due Date</Form.Label>
                        <input
                          type='date'
                          name='dueDate'
                          className='form-control'
                          value={inputField?.dueDate}
                          onChange={(event) => handleInputChange(event, setInputField)}
                        />
                        {<InputErrorBox Message={inputError.dueDate} />}
                      </div>
                      <div className='mb-5 m-3'>
                        <Form.Label className='fw-bold required'>Project End Date</Form.Label>
                        <input
                          type='date'
                          name='endDate'
                          className='form-control'
                          value={inputField?.endDate}
                          onChange={(event) => handleInputChange(event, setInputField)}
                        />
                        {<InputErrorBox Message={inputError.endDate} />}
                      </div>
                      <div className='mb-5 m-3'>
                        <Form.Label className='mb-3 fw-bold required'>Project Manager</Form.Label>
                        <Select2
                          name='projectManager'
                          className=''
                          data={sortedUserNames}
                          value={inputField.projectManager.id}
                          onChange={(selectedOption: string) => {
                            setInputField((prevInputField) => ({
                              ...prevInputField,
                              projectManager: {id: selectedOption},
                            }))
                            console.log('selected user', selectedOption)
                          }}
                        />
                        {<InputErrorBox Message={inputError.projectManager} />}
                      </div>
                    </Col>
                    <Form.Label className='mb-3 m-3 fw-bold required'>
                      Project Description
                    </Form.Label>
                    <Col className='ms-3'>
                      {/* <ReactQuill
                        className='form-control'
                        theme='snow'
                        style={{minHeight: '175px'}}
                        value={inputField?.description}
                        onChange={(value) =>
                          handleOtherInputChange(value, 'description', setInputField)
                        }
                      /> */}
                      <CKEditor
                        editor={ClassicEditor}
                        data={inputField?.description}
                        config={{
                          toolbar: {
                            items: [
                              'heading',
                              '|',
                              'bold',
                              'italic',
                              'link',
                              'bulletedList',
                              'numberedList',
                            ],
                          },
                        }}
                        onChange={(value, editor) => {
                          const data = editor.getData()
                          handleOtherInputChange(data, 'description', setInputField)
                        }}
                      />
                      {<InputErrorBox Message={inputError.description} />}
                    </Col>
                  </Row>
                </Modal.Body>
              </>
            )}
            {currentStep === 1 && (
              <AddUsers
                userData={userData}
                // projectInputField={inputField}
                inputError={inputError}
                setInputError={setInputError}
                projectUserData={projectUserInputField}
                setProjectUserInputField={setProjectUserInputField}
              />
            )}
            {currentStep === 2 && (
              <Modal.Body>
                {SubmitAPIStatus?.loading ? (
                  <Center>
                    <Loader />
                  </Center>
                ) : (
                  <CreateTask
                    userData={projectUserInputField.projectUsers?.filter(
                      (item) => item.selected === true
                    )}
                    taskData={taskData}
                    setTaskData={setTaskData}
                  />
                )}
              </Modal.Body>
            )}
          </Modal.Body>
          <Modal.Footer>
            {currentStep > 0 && (
              <Button
                variant='filled'
                color='gray'
                disabled={SubmitAPIStatus.loading}
                onClick={handlePreviousStep}
              >
                <span className='me-2'>
                  <FontAwesomeIcon icon={faChevronLeft} />
                </span>
                Previous
              </Button>
            )}
            {currentStep < steps.length - 1 && (
              <Button variant='filled' color='primary' onClick={handleNextStep}>
                Next
                <span className='ms-2'>
                  <FontAwesomeIcon icon={faChevronRight} />
                </span>
              </Button>
            )}
            {currentStep === steps.length - 1 && (
              <Button
                variant='filled'
                color='green'
                loading={SubmitAPIStatus.loading}
                onClick={() => {
                  handleSubmit(
                    currentUser?.id,
                    currentUser,
                    {
                      projectInputField: inputField,
                      projectUserInputField: projectUserInputField,
                      taskInputField: taskData,
                    },
                    setProject,
                    initialState,
                    setInputField,
                    setAPIStatus,
                    setInputError,
                    validateForm,
                    setSubmitAPIStatus,
                    cancelSubmit,
                    handleFinishStep
                  )
                  // console.log('Project', inputField)
                  // console.log(
                  //   'Projects Users',
                  //   projectUserInputField?.projectUsers?.filter((obj) => obj?.selected === true)
                  // )
                  // console.log('filterUsers', projectUserInputField)

                  // console.log('taskData', taskData)
                }}
              >
                Finish
              </Button>
            )}
          </Modal.Footer>
        </Modal>

        <Modal show={showUsers} onClose={() => userShowModalClose()} centered size='lg'>
          <Modal.Header>
            <Modal.Title>Project Users</Modal.Title>
            <div className='btn btn-icon btn-sm btn-active-icon-danger'>
              <i
                className='fa-solid fa-xmark fs-1'
                onClick={() => {
                  setShowUsers(false)
                }}
              />
            </div>
          </Modal.Header>
          <Modal.Body style={{maxHeight: '400px', overflowY: 'scroll'}}>
            {selectedProjectUsers.map((user) => (
              <div
                className='d-flex flex-stack py-5 border-bottom border-gray-300 border-bottom-dashed'
                key={user.id}
              >
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-45px'>
                    <img src='/media/avatars/blank.png' alt='profilePhoto' />
                  </div>

                  <div className='ms-6'>
                    <a
                      href='#'
                      className='d-flex align-items-center fs-5 fw-bold text-dark text-hover-primary'
                    >
                      {user.user.firstName ?? '-'} {user.user.lastName ?? '-'}
                    </a>
                    <div className='fw-semibold text-muted'>{user.user.email ?? '-'}</div>
                  </div>
                </div>
                <div className='d-flex'>
                  <div className='text-end'>
                    <div className='fs-5 fw-bold text-dark'>{user.role}</div>
                    <div className='fs-7 text-muted'>Role</div>
                  </div>
                </div>
              </div>
            ))}
          </Modal.Body>
        </Modal>
      </>
    </>
  )
}

export default Project
