//Applications.tsx
import React, {useEffect, useState} from 'react'
import {changeTextCapital} from '../../../_metronic/helpers'
import {Center} from '@mantine/core'
import {useAuth} from '../../modules/auth'
import {
  formatCreateAt,
  formatDateTime,
  formatDates,
  getLeave,
  handleLeaveApprove,
  handleLeaveReject,
} from './LeaveFunctions'
import {Modal} from 'react-bootstrap'
import {truncateString} from '../attendance/ams'
import {ToastContainer} from 'react-toastify'

const Applications = ({getLeaveBalances, setBalance}) => {
  const [reasonModalOpen, setReasonModalOpen] = useState(false)
  const [selectedReason, setSelectedReason] = useState('')
  const [showRow, setShowRow] = useState<number[]>([])
  const fetchData = () => {
    getLeaveBalances(currentUser, setBalance, setAPIStatus)
  }
  const checkArrayElement = (element: number) => {
    setShowRow((prevShowRow) => {
      const updatedShowRow = [...prevShowRow]
      const index = updatedShowRow.indexOf(element)
      if (index !== -1) {
        updatedShowRow.splice(index, 1)
      } else {
        updatedShowRow.push(element)
      }
      return updatedShowRow
    })
  }
  const {currentUser} = useAuth()
  const [leave, setLeave] = useState([
    {
      id: '',
      leaveUser: [
        {
          id: '',
          type: '',
          receiver: {
            id: '',
          },
        },
        {
          id: '',
          type: '',
          receiver: {
            id: '',
          },
        },
      ],
      sender: {
        id: '',
        firstName: '',
        lastName: '',
      },
      isLeave: true,
      recipients: {
        to: [],
        cc: [],
      },
      leaveType: '',
      status: '',
      reason: '',
      leaveDate: [
        {
          approvedAt: '',
          startDate: '',
          endDate: '',
          type: '',
          status: '',
          id: '',
          approvedBy: {
            firstName: '',
            lastName: '',
            id: '',
          },
        },
      ],
    },
  ])

  const [APIStatus, setAPIStatus] = useState({
    loading: false,
    error: null,
    submitLoading: false,
  })
  useEffect(() => {
    let mounted = true
    if (mounted) {
      const action = 'incoming'
      getLeave(currentUser, setLeave, setAPIStatus, action)
    }
    return () => {
      mounted = false
    }
  }, [])
  if (!currentUser) {
    return (
      <div>
        <Center>Loading...</Center>
      </div>
    )
  }
  return (
    <>
      <ToastContainer position={'bottom-center'} />

      <div>
        {/* modal to see the reason */}
        <Modal
          show={reasonModalOpen}
          onClose={() => setReasonModalOpen(false)}
          animation={false}
          backdrop='static'
          centered
        >
          <div className='modal-header'>
            <h2 className='fw-bolder'>Reason</h2>
            <div
              className='btn btn-icon btn-sm btn-active-icon-danger'
              data-kt-users-modal-action='close'
              style={{cursor: 'pointer'}}
              onClick={() => {
                setReasonModalOpen(false)
              }}
            >
              <i className='fa-solid fa-xmark fs-1' />
            </div>
          </div>
          <div className='card-body'>
            <div className='mt-5 ms-5' dangerouslySetInnerHTML={{__html: selectedReason}} />
          </div>
        </Modal>

        <div className='card mb-5 mb-xl-8'>
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>My Applications</span>
            </h3>
          </div>

          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted '>
                    <th className='win-w-150px'></th>
                    <th className='min-w-150px'>{changeTextCapital('Name')}</th>
                    <th className='min-w-150px'>{changeTextCapital('Subject')}</th>
                    <th className='min-w-150px'>{changeTextCapital('Reason')}</th>
                    <th className='min-w-150px'>{changeTextCapital('Status')}</th>
                    <th className='min-w-150px'>{changeTextCapital('Create Date')}</th>
                  </tr>
                </thead>
                {leave?.length > 0 ? (
                  <tbody>
                    {leave.map((obj: any, index: number) => (
                      <>
                        <tr>
                          <td>
                            <div>
                              <span
                                className={`badge badge-light-primary cursor-pointer ${showRow.includes(
                                  index
                                )}`}
                                onClick={() => {
                                  checkArrayElement(index)
                                }}
                              >
                                {showRow.includes(index) ? '↑' : '↓'}
                              </span>
                            </div>
                          </td>

                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                {obj?.sender?.firstName ?? '-'} {obj?.sender?.lastName ?? '-'}
                              </div>
                            </div>
                          </td>

                          <td>
                            {' '}
                            {`Request for ${
                              obj?.leaveType === 'Paid'
                                ? 'Earned Leave'
                                : obj?.leaveType === 'Unpaid'
                                  ? 'Unpaid Leave'
                                  : obj?.leaveType === 'Sick Leave'
                                    ? 'Sick Leave'
                                    : obj?.leaveType === 'Optional Leave'
                                      ? 'Optional Leave'
                                      : obj?.leaveType === 'Work From Home'
                                        ? 'Work from home'
                                        : '-'
                            }`}
                          </td>

                          <td
                            className='d-flex flex-column cursor-pointer mt-5'
                            onClick={() => {
                              setSelectedReason(obj?.reason ?? '-')
                              setReasonModalOpen(true)
                            }}
                            dangerouslySetInnerHTML={{
                              __html: truncateString(obj?.reason ?? '-', 20),
                            }}
                          />

                          <td>
                            <span
                              className={`badge border ${
                                obj?.status === 'approved'
                                  ? 'badge-light-success'
                                  : obj?.status === 'cancel'
                                    ? 'bg-secondary'
                                    : obj?.status === 'declined'
                                      ? 'badge-light-danger'
                                      : obj?.status === 'partially approved'
                                        ? 'badge badge-light-success'
                                        : obj?.status === 'partially declined'
                                          ? 'badge badge-light-danger'
                                          : 'badge badge-light-warning'
                              }`}
                            >
                              {obj?.status === 'approved'
                                ? 'Approved'
                                : obj?.status === 'cancel'
                                  ? 'Canceled'
                                  : obj?.status === 'declined'
                                    ? 'Declined'
                                    : obj?.status === 'partially approved'
                                      ? 'Partially Approved'
                                      : obj?.status === 'partially declined'
                                        ? 'Partially Declined'
                                        : 'Pending'}
                            </span>
                          </td>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <td>{formatCreateAt(obj?.createAt ?? '-')}</td>
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr key={`secondIndex${index}`} hidden={!showRow.includes(index)}>
                          <td colSpan={6}>
                            <>
                              <div className='table-responsive'>
                                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                  <thead>
                                    <tr className='fw-bold text-muted'>
                                      <th style={{width: '30px'}}></th>

                                      <th style={{width: '130px'}}>{changeTextCapital('Type')}</th>
                                      <th style={{width: '150px'}}>
                                        {changeTextCapital('Start Date')}
                                      </th>
                                      <th style={{width: '130px'}}>
                                        {changeTextCapital('End Date')}
                                      </th>
                                      <th style={{width: '130px'}}>
                                        {changeTextCapital('Status')}
                                      </th>
                                      <th style={{width: '130px'}}>
                                        {changeTextCapital('Action By')}
                                      </th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    <tr>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                    {obj.leaveDate.map((detail) => (
                                      <>
                                        <tr key={`${obj?.id ?? '-'}-${detail.id}`}>
                                          <td>
                                            <div>
                                              <span></span>
                                            </div>
                                          </td>
                                          <td>
                                            <div className='d-flex align-items-center'>
                                              <div className='d-flex justify-content-start flex-column'>
                                                {detail.type === 'full'
                                                  ? 'Full'
                                                  : detail.type === 'firstHalf'
                                                    ? 'First Half'
                                                    : detail.type === 'secondHalf'
                                                      ? 'Second Half'
                                                      : '-'}
                                              </div>
                                            </div>
                                          </td>

                                          <td>{formatDates(detail?.startDate ?? '-')}</td>

                                          <td>
                                            <div className='d-flex flex-column '>
                                              {formatDates(detail?.endDate ?? '-')}
                                            </div>
                                          </td>

                                          <td>
                                            {detail.status === 'pending' ? (
                                              <td>
                                                <button
                                                  className='btn btn-icon btn-light-primary btn-sm me-1'
                                                  onClick={() =>
                                                    handleLeaveApprove(
                                                      detail.id,
                                                      currentUser,
                                                      setLeave,
                                                      setAPIStatus,
                                                      'incoming',
                                                      fetchData
                                                    )
                                                  }
                                                >
                                                  <i className='fa-solid fa-check'></i>
                                                </button>
                                                <button
                                                  className='btn btn-icon btn-light-danger btn-sm me-1 '
                                                  onClick={() =>
                                                    handleLeaveReject(
                                                      detail.id,
                                                      currentUser,
                                                      setLeave,
                                                      setAPIStatus,
                                                      'incoming',
                                                      fetchData
                                                    )
                                                  }
                                                >
                                                  <i className='fa-solid fa-xmark'></i>
                                                </button>
                                              </td>
                                            ) : (
                                              <td>
                                                {detail.status ? (
                                                  <span
                                                    className={`badge ${
                                                      detail?.status === 'accept'
                                                        ? 'badge-light-success'
                                                        : detail?.status === 'cancel'
                                                          ? 'bg-secondary'
                                                          : 'badge-light-danger'
                                                    }`}
                                                  >
                                                    {detail?.status === 'accept'
                                                      ? 'Approved'
                                                      : detail?.status === 'cancel'
                                                        ? 'Canceled'
                                                        : 'Declined'}
                                                  </span>
                                                ) : (
                                                  '-'
                                                )}
                                              </td>
                                            )}
                                          </td>
                                          <td>
                                            <div className='d-flex flex-column '>
                                              <div className='mt-5'>
                                                {detail?.approvedBy?.firstName ?? '-'}{' '}
                                                {detail?.approvedBy?.lastName ?? '-'}
                                              </div>
                                              <div className='text-muted'>
                                                {detail.approvedAt
                                                  ? formatDateTime(detail.approvedAt)
                                                  : '-'}
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      </>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan={6}>
                        <Center>No Record Available.</Center>
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Applications
