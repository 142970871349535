import React, {useState} from 'react'
import {Button} from 'react-bootstrap'
import Select2 from '../../components/select2'
import {cancelSubmit, createReports, handleInputChange, validateForm} from './ReportsFunction'
import InputErrorBox from '../../components/InputErrorBox'
import {Toolbar1} from '../../../_metronic/layout/components/toolbar/Toolbar1'

const Reports = () => {
  const initialState = {
    type: '',
    startDate: '',
    endDate: '',
  }

  const [inputField, setInputField] = useState(initialState)
  const [inputError, setInputError] = useState<any>({})

  return (
    <>
      <div className='d-flex justify-content-center'>
        <div className='card w-50'>
          <div className='card-header align-items-center py-3 gap-2 gap-md-5'>
            <div>
              <h3>Reports</h3>
            </div>
          </div>

          <div className='card-body'>
            <div className='fv-row mb-5 col-md-12'>
              <label className='required fs-6 fw-semibold mb-2'> Module Name</label>
              <Select2
                name='type'
                data={[
                  {label: 'Asset', value: 'asset'},
                  {label: 'AMS', value: 'attendance'},
                  {label: 'LMS', value: 'leave'},
                ].sort((a, b) => a.label.localeCompare(b.label))}
                value={inputField.type}
                onChange={(selectedOption: any) => {
                  console.log('selected options:', selectedOption)
                  setInputField((prevInputField) => ({
                    ...prevInputField,
                    type: selectedOption,
                  }))
                }}
              />
              {<InputErrorBox Message={inputError.type} />}
            </div>

            <div className='row'>
              <div className='fv-row mb-5 col-md-6'>
                <label className='required fs-6 fw-semibold mb-2'> Start Date</label>
                <input
                  className='form-control mt-2'
                  type='date'
                  value={inputField.startDate}
                  name='startDate'
                  onChange={(event) => handleInputChange(event, setInputField, inputField)}
                />
                {<InputErrorBox Message={inputError.startDate} />}
              </div>

              <div className='fv-row mb-5 col-md-6'>
                <label className='required fs-6 fw-semibold mb-2'> End Date</label>
                <input
                  className='form-control mt-2'
                  type='date'
                  value={inputField.endDate}
                  name='endDate'
                  onChange={(event) => handleInputChange(event, setInputField, inputField)}
                />
                {<InputErrorBox Message={inputError.endDate} />}
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end'>
            <Button
              onClick={() => {
                cancelSubmit(setInputField, initialState, setInputError)
              }}
              type='button'
              className='btn btn-secondary me-2 btn-sm font-weight-bold '
            >
              Cancel
            </Button>
            <Button
              className='btn btn-primary me-2 btn-sm font-weight-bold '
              onClick={() => {
                createReports(
                  inputField,
                  setInputField,
                  initialState,
                  validateForm,
                  setInputError,
                  cancelSubmit
                )
              }}
            >
              Generate Report
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Reports
