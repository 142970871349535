import {FC} from 'react'
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom'
import {App} from '../App'
import {AuthPage, Logout, useAuth} from '../modules/auth'
import {ForgotPassword} from '../modules/auth/components/ForgotPassword'
import ResetPassword from '../modules/auth/components/ResetPassword'
import ResetPassword1 from '../modules/auth/components/ResetPassword1'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {ActionPage} from '../pages/assests/ActionPage'
import {ActionPageForAttendance} from '../pages/attendance/ActionPageForAttendance'
import HomePage from '../pages/homePage/HomePage'
import SignUp from '../pages/homePage/SignUp'
import VerifyEmail from '../pages/homePage/VerifyEmail'
import {PrivateRoutes} from './PrivateRoutes'

const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const {currentUser} = useAuth()
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          <Route path='forgotpassword' element={<ForgotPassword />} />
          <Route path='accept/asset' element={<ActionPage />} />
          <Route path='reject/asset' element={<ActionPage />} />
          <Route path='accept/attendance' element={<ActionPageForAttendance />} />
          <Route path='reject/attendance' element={<ActionPageForAttendance />} />
          <Route path='/resetPassword' element={<ResetPassword />} />
          <Route path='/reset-password' element={<ResetPassword1 />} />
          <Route path='/homePage' element={<HomePage />} />
          <Route path='/signup' element={<SignUp />} />
          <Route path='/verify' element={<VerifyEmail />} />
          <Route path='/login' element={<AuthPage />} />

          {currentUser ? (
            <>
              {console.log(currentUser)}
              <Route path='*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/dashboard' />} />
            </>
          ) : (
            <>
              {console.log('inelse')}
              <Route path='/' element={<HomePage />} />
              <Route path='/*' element={<Navigate to='/' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
