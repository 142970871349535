import {Link} from 'react-router-dom'

const HomePage = () => {
  return (
    <>
      <div style={{backgroundColor: 'white'}}>
        <div className='' style={{backgroundImage: "url('media/logos/Rectangle1.svg')"}}>
          <div className='container px-0'>
            <nav className='navbar navbar-expand-lg'>
              <div className='container-fluid'>
                <a className='navbar-brand' href='#'>
                  <img
                    src={'/media/logos/sialogo.png'}
                    alt='Your Logo'
                    width='170px'
                    height='120px'
                  />
                </a>
                <button
                  className='navbar-toggler'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#navbarSupportedContent'
                  aria-controls='navbarSupportedContent'
                  aria-expanded='false'
                  aria-label='Toggle navigation'
                >
                  <span className='navbar-toggler-icon'></span>
                </button>
                <div
                  className='collapse navbar-collapse justify-content-end'
                  id='navbarSupportedContent'
                >
                  <ul className='navbar-nav ms-auto mb-2 mb-lg-0'>
                    <li className='nav-item'>
                      <a
                        className='nav-link'
                        href='#feature'
                        style={{
                          font: 'normal normal normal 18px/44px Montserrat',
                          color: '#000070',
                        }}
                      >
                        Features
                      </a>
                    </li>
                    <li className='nav-item pe-2'>
                      <a
                        className='nav-link'
                        href='#pricing'
                        style={{
                          font: 'normal normal normal 18px/44px Montserrat',
                          color: '#000070',
                        }}
                      >
                        Pricing
                      </a>
                    </li>
                  </ul>
                  <Link to={'/signup'} className='text-dark'>
                    <button
                      className='btn btn-primary btn-sm px-4 py-2'
                      style={{
                        background: '#56ACD9 0% 0% no-repeat padding-box',
                        borderRadius: '10px;font: normal normal medium 18px/22px Montserrat',
                      }}
                      id='signupButton'
                    >
                      Sign Up
                    </button>
                  </Link>
                  <Link to={'/login'} className='text-dark'>
                    <button
                      className='btn btn-primary btn-sm py-2 ms-2 me-1'
                      style={{
                        background: '#56ACD9 0% 0% no-repeat padding-box',
                        borderRadius: '10px;font: normal normal medium 18px/22px Montserrat',
                      }}
                    >
                      Login
                    </button>
                  </Link>
                </div>
              </div>
            </nav>
          </div>

          <div
            className='container px-5 '
            style={{
              background: "transparent url('/media/logos/') 45% -25% no-repeat",
              opacity: '1',
            }}
          >
            <div className='row mb-4 pt-1 pb-5 '>
              <div className='col-md-7'>
                <h1
                  className='ms-5 mt-5 mb-3 poppins-font fw-bold'
                  style={{color: '#021291', fontSize: '50px'}}
                >
                  Welcome to OfficeSIA,
                </h1>
                <h4
                  className='mx-5 poppins-font fw-normal'
                  style={{color: '#021291', fontSize: '27px'}}
                >
                  Your Smart Interactive Assistance.
                </h4>
                <h3 className='mx-5 mt-4 poppins-font' style={{color: '#021291'}}>
                  Experience the power of modern HR management.
                </h3>
                <h3 className='mx-5 poppins-font mt-3' style={{color: '#021291'}}>
                  Streamline your HR processes, improve productivity, and unlock the full potential
                  of your workforce.
                </h3>
                <h5 className='mx-5 poppins-font mt-12' style={{color: '#021291'}}>
                  Ready to revolutionize HR? Let’s dive in!
                </h5>
                <div className='input-group mt-3 mx-5' style={{width: '55%'}}>
                  <input
                    type='text'
                    className='form-control'
                    id='userInput'
                    style={{
                      border: '1px solid #6A6AE2',
                      borderRadius: '10px 0px 0px 10px',
                      margin: '1px 0px 1px 0px',
                      height: '40px',
                    }} // Adjusted height
                    placeholder='Email or Phone'
                  />
                  <div className='input-group-append'>
                    <button
                      type='button'
                      id='bookyourdemotoday'
                      className='btn py-1'
                      style={{
                        background: '#56ACD9 0% 0% no-repeat padding-box',
                        borderRadius: '0px 10px 10px 0px',
                        font: 'normal normal medium 18px/22px Montserrat',
                        color: '#FFFFFF',
                        height: '41px', // Adjusted height to match the input field
                      }}
                    >
                      Book Your Demo Today
                    </button>
                  </div>
                </div>
              </div>
              <div className='col-md-5 align-self-center text-center'>
                <img
                  src='/media/logos/shutterstock_1138070138_Converted.png'
                  className='img-fluid mt-5'
                  alt='Responsive image'
                  style={{opacity: '3', zIndex: '1000'}}
                />
              </div>
            </div>
            <div className='row mt-15 text-center'>
              <h1
                className='text-center'
                style={{color: '#000070', font: 'normal normal 600 32px/39px Montserrat'}}
              >
                Why OfficeSIA?
              </h1>
              {/* <h4
                className='text-center mt-4'
                style={{color: '#69698D', font: 'normal normal normal 22px/30px Noto Sans'}}
              >
                FinOMS stands as a robust Financial Opportunity Management System
              </h4> */}
              <h5
                className='text-center mt-3 mb-3'
                style={{color: '#69698D', font: 'normal normal normal 18px/32px Noto Sans'}}
              >
                Our cutting-edge HRMS and Project Management application seamlessly integrates the
                power of HR and project management. Designed to streamline and optimize HR
                processes, it empowers organizations to automate essential functions, efficiently
                manage their workforce, and enhance employee engagement. With centralized data and
                real-time insights, you can allocate resources effectively, track progress, and
                ensure projects stay on schedule. Ready to revolutionize your HR experience? Let’s
                embark on this transformative journey with OfficeSIA!
              </h5>
            </div>
          </div>
        </div>
        <div className='container text-center mt-7' style={{backgroundColor: 'white'}}>
          <h3 style={{font: 'normal normal 600 32px/39px Montserrat', color: '#000070'}}>
            {' '}
            HR solutions that meet your industry needs...
          </h3>
          <div className='row'>
            <div className='col-md-6 mt-4'>
              {' '}
              <img
                src='/media/logos/itcm-mobile-device-banner-image.png'
                height='320rem'
                width='320rem'
              />
            </div>
            <div className='col-md-6' style={{maxWidth: '600px', margin: '0 auto'}}>
              <h5
                className='text-start mt-3 justify-content-evenly'
                style={{
                  color: '#69698D',
                  font: 'normal normal normal 18px/32px Noto Sans',
                  textAlign: 'justify',
                  hyphens: 'auto', // This attempts to hyphenate words at suitable hyphenation points.
                }}
              >
                In today's dynamic business landscape, organizations face numerous challenges in
                managing their human capital effectively. From recruitment and onboarding to
                performance management and payroll, HR departments juggle multiple responsibilities
                while ensuring compliance with regulations and fostering a positive employee
                experience.
                <br />
                Our HRMS application is built to address these challenges by centralizing and
                automating HR tasks, providing a unified platform for managing all aspects of the
                employee lifecycle. Whether you're a small business or a large enterprise, our
                solution offers scalability, flexibility, and robust features tailored to your
                organization's needs.
              </h5>
            </div>
          </div>
        </div>
        <div
          className='container text-center mt-8 px-4'
          id='feature'
          style={{backgroundColor: 'white'}}
        >
          <h3
            className='my-5'
            style={{font: 'normal normal 600 32px/39px Montserrat', color: '#000070'}}
          >
            {' '}
            Key Features and Benefits
          </h3>
          <div className='row justify-content-center ' style={{backgroundColor: 'white'}}>
            <div
              className='col-md-4 col-lg-3 mb-3 mx-3 border text-start'
              style={{
                boxShadow: '0px 0px 10px #00000014',
                border: '1px solid #8E8EF4 !important',
                borderRadius: '8px',
              }}
            >
              <div className='d-flex align-items-center  mt-3 mb-3'>
                <img src='/media/logos/employee DB.png' height='70px' width='70px'></img>{' '}
                <h6
                  className='ms-2 mt-2'
                  style={{font: 'normal normal 600 20px/24px Montserrat', color: '#000070'}}
                >
                  Employee Database Management
                </h6>
              </div>
              <h6 style={{font: 'normal normal normal 18px/32px Noto Sans', color: '#69698D'}}>
                Maintain a centralized database of employee information, including personal details,
                employment history, skills, and performance evaluations.
              </h6>
            </div>
            <div
              className='col-md-4 col-lg-3 mb-3 mx-3 border text-start'
              style={{
                boxShadow: '0px 0px 10px #00000014',
                border: '1px solid #8E8EF4 !important',
                borderRadius: '8px',
              }}
            >
              <div className='d-flex align-items-center  mt-3 mb-3'>
                <img src='/media/logos/attendance.png' height='70px' width='70px'></img>{' '}
                <h6
                  className='ms-2 mt-2'
                  style={{font: 'normal normal 600 20px/24px Montserrat', color: '#000070'}}
                >
                  Time and Attendance Tracking
                </h6>
              </div>
              <h6 style={{font: 'normal normal normal 18px/32px Noto Sans', color: '#69698D'}}>
                Track employee attendance, manage leave requests, and automate timekeeping processes
                to ensure accurate payroll calculations.{' '}
              </h6>
            </div>{' '}
            <div
              className='col-md-4 col-lg-3 mb-3 mx-3 border text-start'
              style={{
                boxShadow: '0px 0px 10px #00000014',
                border: '1px solid #8E8EF4 !important',
                borderRadius: '8px',
              }}
            >
              <div className='d-flex align-items-center  mt-3 mb-3'>
                <img src='/media/logos/asset management.png' height='70px' width='70px'></img>{' '}
                <h6
                  className='ms-2 mt-2'
                  style={{font: 'normal normal 600 20px/24px Montserrat', color: '#000070'}}
                >
                  Asset Management
                </h6>
              </div>
              <h6 style={{font: 'normal normal normal 18px/32px Noto Sans', color: '#69698D'}}>
                Asset listing and assigning the assets to users in ‘OfficeSIA’ helps HR and Admin to
                work together for Employee on-boarding or Exit process.
              </h6>
            </div>{' '}
            <div
              className='col-md-4 col-lg-3 mb-3 mx-3 border text-start'
              style={{
                boxShadow: '0px 0px 10px #00000014',
                border: '1px solid #8E8EF4 !important',
                borderRadius: '8px',
              }}
            >
              <div className='d-flex align-items-center  mt-3 mb-3'>
                <img src='/media/logos/project management.png' height='70px' width='70px'></img>{' '}
                <h6
                  className='ms-2 '
                  style={{font: 'normal normal 600 20px/24px Montserrat', color: '#000070'}}
                >
                  Project Management
                </h6>
              </div>
              <h6 style={{font: 'normal normal normal 18px/32px Noto Sans', color: '#69698D'}}>
                Set Project, set a group of users, assign the task/subtask, send a notification
                email, monitor productivity and project progress.
              </h6>
            </div>{' '}
            <div
              className='col-md-4 col-lg-3 mb-3 mx-3 border text-start'
              style={{
                boxShadow: '0px 0px 10px #00000014',
                border: '1px solid #8E8EF4 !important',
                borderRadius: '8px',
              }}
            >
              <div className='d-flex align-items-center  mt-3 mb-3'>
                <img src='/media/logos/training.png' height='70px' width='70px'></img>{' '}
                <h6
                  className='ms-2 '
                  style={{font: 'normal normal 600 20px/24px Montserrat', color: '#000070'}}
                >
                  Training and Development
                </h6>
              </div>
              <h6 style={{font: 'normal normal normal 18px/32px Noto Sans', color: '#69698D'}}>
                Identify training needs, create learning programs, and track employee progress to
                foster continuous learning and skill development.{' '}
              </h6>
            </div>
            <div
              className='col-md-4 col-lg-3 mb-3 mx-3 border text-start'
              style={{
                boxShadow: '0px 0px 10px #00000014',
                border: '1px solid #8E8EF4 !important',
                borderRadius: '8px',
              }}
            >
              <div className='d-flex align-items-center  mt-3 mb-3'>
                <img src='/media/logos/payroll.png' height='70px' width='70px'></img>{' '}
                <h6
                  className='ms-2 mt-2'
                  style={{font: 'normal normal 600 20px/24px Montserrat', color: '#000070'}}
                >
                  Payroll and Benefits Administration
                </h6>
              </div>
              <h6 style={{font: 'normal normal normal 18px/32px Noto Sans', color: '#69698D'}}>
                Simplify payroll processing, manage employee benefits, and ensure compliance with
                tax regulations and labor laws.
              </h6>
            </div>
            <div
              className='col-md-4 col-lg-3 mb-3 mx-3 border text-start'
              style={{
                boxShadow: '0px 0px 10px #00000014',
                border: '1px solid #8E8EF4 !important',
                borderRadius: '8px',
              }}
            >
              <div className='d-flex align-items-center  mt-3 mb-3'>
                <img src='/media/logos/selfemployee.png' height='70px' width='70px'></img>{' '}
                <h6
                  className='ms-2  '
                  style={{font: 'normal normal 600 20px/24px Montserrat', color: '#000070'}}
                >
                  Employee Self-Service
                </h6>
              </div>
              <h6 style={{font: 'normal normal normal 18px/32px Noto Sans', color: '#69698D'}}>
                Empower employees with self-service capabilities to update personal information,
                request time off or leaves, and participate in project management activity.
              </h6>
            </div>
            <div
              className='col-md-4 col-lg-3 mb-3 mx-3 border text-start'
              style={{
                boxShadow: '0px 0px 10px #00000014',
                border: '1px solid #8E8EF4 !important',
                borderRadius: '8px',
              }}
            >
              <div className='d-flex align-items-center  mt-3 mb-3'>
                <img src='/media/logos/geo_tagging.png' height='70px' width='70px'></img>{' '}
                <h6
                  className='ms-2 mt-2'
                  style={{font: 'normal normal 600 20px/24px Montserrat', color: '#000070'}}
                >
                  Geo Tagging
                </h6>
              </div>
              <h6 style={{font: 'normal normal normal 18px/32px Noto Sans', color: '#69698D'}}>
                Streamline office check-in and check-out using QR codes, eliminating the need for
                costly biometric hardware. It’s user-friendly for field engineers, sales reps,
                marketers, and remote staff.
              </h6>
            </div>{' '}
            <div
              className='col-md-4 col-lg-3 mb-3 mx-3 border text-start'
              style={{
                boxShadow: '0px 0px 10px #00000014',
                border: '1px solid #8E8EF4 !important',
                borderRadius: '8px',
              }}
            >
              <div className='d-flex align-items-center  mt-3 mb-3'>
                <img src='/media/logos/report n analytics.png' height='70px' width='70px'></img>{' '}
                <h6
                  className='ms-2 mt-2 '
                  style={{font: 'normal normal 600 20px/24px Montserrat', color: '#000070'}}
                >
                  Analytics and Reporting
                </h6>
              </div>
              <h6 style={{font: 'normal normal normal 18px/32px Noto Sans', color: '#69698D'}}>
                Our Human Resource Management System (HRMS) is designed to adapt to your
                organization’s evolving needs. It offers a user-friendly interface, customizable
                workflows, and seamless integration with other business systems.
              </h6>
            </div>
          </div>
        </div>

        <div
          id='pricing'
          className='container-fluid d-flex text-center align-items-center flex-column pb-5'
          style={{backgroundColor: '#56ACD9'}}
        >
          <h4
            className='py-4'
            style={{
              font: 'normal normal 600 20px/24px Montserrat',
              color: '#000070',
              fontSize: '30px',
            }}
          >
            Pricing
          </h4>
          <h5
            className='col-md-8 mb-5'
            style={{
              font: 'normal normal normal 28px/38px Noto Sans',
              color: '#F3F3FF',
              fontSize: '21px',
            }}
          >
            Explore our transparent and flexible pricing options designed to cater to your unique
            financial needs. With OfficeSIA, choose a plan that aligns seamlessly with your business
            goals.
          </h5>
          <div className='container'>
            <div className='row justify-content-center gap-6 mb-10 my-10'>
              <div
                className='mb-10 mb-lg-0 p-0 align-items-center d-flex flex-column '
                style={{width: '23rem', backgroundColor: '#F3F3FF', borderRadius: '18px'}}
              >
                <h1
                  className='py-2 mb-7'
                  style={{
                    backgroundColor: '#000070',
                    width: '23rem',
                    textAlign: 'center',
                    borderRadius: '18px 18px 0px 0px',
                    font: 'normal normal medium 6rem/4rem Montserrat',
                    color: 'white',
                  }}
                >
                  1-9 Users
                </h1>
                <button
                  className='btn  btn-sm col-9 mt-4 me-3 text-white mb-4 mt-auto'
                  style={{
                    background: '#007BB8 0% 0% no-repeat padding-box',
                    borderRadius: '10px',
                    font: 'normal normal medium 4rem/3rem Montserrat',
                  }}
                >
                  Subscribe
                </button>
              </div>
              <div
                className='mb-4 mb-lg-0 p-0 align-items-center d-flex flex-column  position-relative'
                style={{width: '23rem', backgroundColor: '#F3F3FF', borderRadius: '18px'}}
              >
                <span
                  className=' text-white py-1  position-absolute translate-middle  '
                  style={{
                    top: '-1rem',
                    left: '11.7rem',
                    background: '#007BB8 0% 0% no-repeat padding-box',
                    borderRadius: '10px 10px 0px 0px',
                    font: 'normal normal medium 4rem/3rem Montserrat',
                    width: '15rem',
                  }}
                >
                  Most Popular
                </span>
                <h1
                  className='py-2 mb-7'
                  style={{
                    backgroundColor: '#000070',
                    width: '23rem',
                    borderRadius: '18px 18px 0px 0px',
                    textAlign: 'center',
                    font: 'normal normal medium 6rem/4rem Montserrat',
                    color: 'white',
                  }}
                >
                  10-50 Users
                </h1>
                <button
                  className='btn  btn-sm col-9 mt-4 me-3 text-white mb-4 mt-auto'
                  style={{
                    background: '#007BB8 0% 0% no-repeat padding-box',
                    borderRadius: '10px',
                    font: 'normal normal medium 4rem/3rem Montserrat',
                  }}
                >
                  Subscribe
                </button>
              </div>
              <div
                className='mb-4 mb-lg-0 p-0 align-items-center d-flex flex-column'
                style={{width: '23rem', backgroundColor: '#F3F3FF', borderRadius: '18px'}}
              >
                {' '}
                <h1
                  className='py-2 mb-3'
                  style={{
                    backgroundColor: '#000070',
                    width: '23rem',
                    borderRadius: '18px 18px 0px 0px',
                    textAlign: 'center',
                    font: 'normal normal medium 6rem/4rem Montserrat',
                    color: 'white',
                  }}
                >
                  Enterprise Edition
                </h1>
                <h6
                  className='my-3 px-2'
                  style={{font: 'normal normal normal 18px/32px Noto Sans', color: '#69698D'}}
                >
                  Call us to know more
                </h6>
                <button
                  className='btn  btn-sm col-9  me-3 text-white mb-4 mt-auto'
                  style={{
                    background: '#007BB8 0% 0% no-repeat padding-box',
                    borderRadius: '10px',
                    font: 'normal normal medium 4rem/3rem Montserrat',
                  }}
                >
                  Subscribe
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className='container-fluid d-flex text-center align-items-center flex-column'
          // style={{backgroundColor:'#56ACD9'}}
        >
          <a href='#'>
            <h4
              className='py-3'
              style={{font: 'normal normal normal 16px/22px Noto Sans', color: '#6A6AE2'}}
            >
              OfficeSIA 2024
            </h4>
          </a>
        </div>

        <a
          id='backToTop'
          href='#'
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            display: 'none',
            zIndex: '100',
            cursor: 'pointer',
          }}
        >
          <i className='fa-solid fa-circle-arrow-up fs-2' style={{color: '#04a72d'}}></i>
        </a>
      </div>
    </>
  )
}

export default HomePage
